import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export default async function getCustomClassifierModelCategoryInfo(
  modelCategory: string
) {
  const url =
    endpoints.eventSounds.customClassifierModelCategoryInfo(modelCategory);

  try {
    const response = await axiosNeuron.get(url);
    const { data } = response;
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
