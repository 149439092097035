import EStatusName from "enums/userStatus";
import { useTranslation } from "react-i18next";

const useTranslatedUserStatus = (status: EStatusName) => {
  const { t } = useTranslation();
  switch (status) {
    case EStatusName.ACTIVE:
      return t("user.status.active");
    case EStatusName.INACTIVE:
      return t("user.status.inactive");
    case EStatusName.INVITED:
      return t("user.status.invited");
    case EStatusName.EXPIRED:
      return t("user.status.expired");
    case EStatusName.DELETED:
      return t("user.status.deleted");
  }
  return null;
};

export default useTranslatedUserStatus;
