import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import moment from "moment";
import { getNormalizeTime } from "shared/helpers";

export async function getAvailableRegions(
  machine: number,
  date: string,
  tzn: string,
  channels: [{ label: string; value: number }]
) {
  let placements = channels.length
    ? channels
        .map((item: any) => item.value)
        .toString()
        .replace(/,/g, "&placement=")
    : [];

  const url = endpoints.availability.range;
  try {
    const { data } = await axiosNeuron.get(
      `${url}?machine=${encodeURIComponent(
        machine
      )}&start_time=${encodeURIComponent(
        date + " 00:00:00.000000"
      )}&end_time=${encodeURIComponent(date + " 23:59:59.999999")}
			&tz=${encodeURIComponent(tzn)}&placement=${placements}`
    );

    Object.keys(data).forEach((set: any) => {
      Object.keys(data[set]).forEach((key: any) => {
        if (key === "start" || key === "end") {
          const date = getNormalizeTime(data[set][key]);
          data[set][key] = Math.floor(moment(date).valueOf() / 1000);
        }
      });
    });
    return data;
  } catch {
    return [];
  }
}
