import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "react-fast-compare";
import SingleActionModal from "components/singleActionModal";
import Paragraph from "components/typography/paragraph";
import Radio from "components/radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { EnvTypes } from "store/machineDetail/oneClickTraining/types";
import TextField from "@mui/material/TextField";
import { checkEnvRegex } from "shared/helpers";

const DeployModal = memo(
  ({
    machineId,
    modelId,
    onCancel,
    onConfirm,
  }: {
    machineId: number;
    modelId: number;
    onCancel: () => void;
    onConfirm: (environment: string) => void;
  }) => {
    const { t } = useTranslation();

    const [environment, setEnvironment] = useState<string>(EnvTypes.prod);
    const [otherEnvironment, setOtherEnvironment] = useState<string>("");
    const handleEnvironment = (e: any) => setEnvironment(e.target.value);
    const handleOtherEnvironment = (e: any) =>
      setOtherEnvironment(e.target.value);

    return (
      <SingleActionModal
        open={true}
        modalTitle={t("machine.detail.model.deployDialog.title")}
        finishButtonLabel={t("machine.detail.model.deploy")}
        handleFinish={() =>
          onConfirm(!!environment.length ? environment : otherEnvironment)
        }
        closeModal={onCancel}
        submitDisabled={!environment.length && !checkEnvRegex(otherEnvironment)}
      >
        <>
          <Paragraph>
            {t("machine.detail.model.deployDialog.subtitle")}
          </Paragraph>
          <Box m={2}>
            <RadioGroup value={environment} onChange={handleEnvironment}>
              <Radio
                activeValue={environment}
                value={EnvTypes.prod}
                label={t("oneClick.select.env.prod")}
              />
              <Radio
                activeValue={environment}
                value={EnvTypes.stage}
                label={t("oneClick.select.env.stage")}
              />
              <Radio
                activeValue={environment}
                value=""
                label={t("machine.detail.model.other")}
              />
            </RadioGroup>
            <TextField
              disabled={!!environment.length}
              variant="outlined"
              onChange={handleOtherEnvironment}
              value={otherEnvironment}
            />
          </Box>
        </>
      </SingleActionModal>
    );
  },
  isEqual
);

export default DeployModal;
