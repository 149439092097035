import { useRef, useEffect } from "react";

import { ICurrencyList } from "types/currency";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { getCurrency } from "store/currencies/actions";

const useListCurrencies = () => {
  const dispatch: any = useDispatch();
  const staleFinalItems = useRef<ICurrencyList[]>([]);

  const { isFetching, currencies } = useSelector(
    (state: AppState) => state.currencies
  );

  const dataItems: ICurrencyList[] = currencies ? currencies.results : [];
  let finalData = dataItems;

  if (isFetching) {
    finalData = staleFinalItems.current;
  }

  useEffect(() => {
    dispatch(getCurrency());
  }, [dispatch]);

  return {
    currencies,
    isFetching,
    finalData,
  };
};

export default useListCurrencies;
