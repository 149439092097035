import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import MultiSelect from "./multiSelect";
import actions from "store/eventSounds/actions";
import { IPlacementType } from "types/placement";

function PlacementType() {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const { placementTypes, placementTypeValues } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const onChange = useCallback(
    (values: number[] | string[]) => {
      dispatch(actions.setPlacementTypeValues(values));
    },
    [dispatch]
  );

  const data = placementTypes?.data?.map((item: IPlacementType) => ({
    ...item,
    name: t(`placementType.${item.name.toLowerCase()}`),
  }));

  return (
    <MultiSelect
      data={data}
      loading={placementTypes?.loading}
      selectedValues={placementTypeValues || []}
      onChange={onChange}
      label={t("eventSounds.dataSelection.filter.placementType")}
      placeholder={t(
        "eventSounds.dataSelection.searchPlaceholder.placementType"
      )}
      id="placement-type-select"
    />
  );
}

export default PlacementType;
