import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface ISubcategoryListResponse {
  id: number;
  company: string | null;
  category: number;
  grafana_template: string | null;
  name: string;
  description: string | null;
  attachment: string | null;
}
export default async function getMachineSubcategories(params: {
  category?: number;
}) {
  const url = endpoints.machines.subCategories;

  try {
    const response = await axiosNeuron.get(
      params.category ? `${url}?category=${params.category}` : url
    );
    return {
      ...response.data,
      results: response.data.results.map(
        (result: ISubcategoryListResponse) => ({
          id: result.id,
          company: result.company,
          category: result.category,
          grafanaTemplate: result.grafana_template,
          name: result.name,
          description: result.description,
          attachment: result.attachment,
        })
      ),
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
