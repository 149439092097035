import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ERoleCodename } from "types/role";

type Params = {
  username: string;
  email: string;
  last_login?: Date;
  is_superuser?: boolean;
  first_name?: string;
  last_name?: string;
  phone?: string;
  note?: string;
  cellphone_id?: string;
  location?: string;
  is_active?: boolean;
  is_admin?: boolean;
  is_staff?: boolean;
  groups?: [];
  user_permissions?: [];
  companies?: number[];
  projects?: number[];
  role?: ERoleCodename;
};

export default async function addUser(user: Params) {
  const url = endpoints.users.default;
  try {
    const response = await axiosNeuron.post(url, user);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
