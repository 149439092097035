import React from "react";
import { makeStyles } from "@mui/styles";
import { CellProps } from "react-table";
import Box from "@mui/material/Box";
import Label from "./label";
import ChunkList from "./chunk";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  grey: {
    color: "grey",
  },
}));

const Combined = <T extends Object>(props: CellProps<T>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gridGap: "1rem",
          marginBottom: 2,
        }}
      >
        <Label {...props} />
        <ChunkList {...props} />
      </Box>
    </div>
  );
};

export default Combined;
