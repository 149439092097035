import React, { memo, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import isEqual from "react-fast-compare";
import { fromGrafana } from "shared/postMessages";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

type TProps = {
  url: string;
  title: string;
};

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  loading: {
    position: "absolute",
    transition: "opacity 0.4s linear",
  },
  iframe: {
    position: "relative",
    transition: "opacity 0.3s linear",
    [theme.breakpoints.down("sm")]: {
      height: "67vh!important",
    },
  },
}));

const IFrame = memo(({ url, title }: TProps) => {
  const [loaded, setLoaded] = useState(false);
  const styles = useStyles();
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 300);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const readMessage = (event: MessageEvent) => {
    if (event.data.key === fromGrafana.grafanaHeight) {
      if (iframeRef.current) {
        const iframeHeight = event.data.contentHeight + event.data.headerHeight;
        iframeRef.current.style.height = iframeHeight + 50 + "px";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", readMessage);
    return () => {
      window.removeEventListener("message", readMessage);
    };
  });

  return (
    <div>
      <CircularProgress
        className={styles.loading}
        style={{
          display: !loaded ? "block" : "none",
        }}
      />
      {loaded && (
        <iframe
          src={url}
          title={title}
          id="Iframe"
          referrerPolicy="same-origin"
          ref={iframeRef}
          width="100%"
          height="10000px"
          frameBorder="0"
          className={styles.iframe}
        />
      )}
    </div>
  );
}, isEqual);

export default IFrame;
