import { EActions, TReducer, IModelsState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IModelsState = {
  models: null,
  isFetchingModels: true,
  error: false,
};

const reducers: TReducer = {
  [EActions.FETCH_MODELS_REQUEST]: () => ({
    isFetching: true,
  }),
  [EActions.FETCH_MODELS_FAIL]: ({ payload: { error } }) => ({
    models: null,
    isFetchingModels: false,
    error: error,
  }),
  [EActions.FETCH_MODELS_SUCCESS]: ({ payload: { models } }) => ({
    models: models,
    isFetchingModels: false,
    error: null,
  }),
  [EActions.setReset]: () => ({
    ...initialState,
  }),
};

export default createReducer<IModelsState>(path, initialState, reducers);
