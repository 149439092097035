import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isNumber from "lodash/isNumber";
import { fetchEventData } from "./eventActions";
import {
  IEventModalState,
  ELabelDateType,
  EModalTypes,
  EDateMessageType,
} from "./types";
import type { IFailure } from "./selector";
import type { TEventPlacementFailureExtended } from "types/failureTypes";
import type { IEventDetail, TCommentWithMappedUser } from "types/event";

const initialState: IEventModalState = {
  errors: {
    start: null,
    end: null,
  },
  [ELabelDateType.start]: undefined,
  [ELabelDateType.end]: undefined,
  [EDateMessageType.start]: "",
  [EDateMessageType.end]: "",
  modalType: EModalTypes.init,
  eventType: null,
  eventId: null,
  labelGroups: [],
  initialLabels: [],
  eventData: undefined,
  lastEventBeforeStart: undefined,
  firstEventAfterEnd: undefined,
  eventBetweenStartAndEnd: undefined,
  failures: [],
  newFailures: [],
  maintenanceType: null,
  status: null,
  statusDefault: null,
  isCritical: null,
  isCriticalDefault: null,
  presumedCauses: null,
  recommendedAction: null,
  anomalyTrend: null,
  diagDescription: null,
  relatedPairEvent: null,
  loading: false,
  validationLoading: false,
  isHidden: false,
  estimatedPreventedDamageCurrency: "",
  estimatedPreventedDamageAmount: null,
};

export const { reducer, actions } = createSlice({
  name: "events",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setValidationLoading: (state, action: PayloadAction<boolean>) => {
      state.validationLoading = action.payload;
    },
    setEventDetail: (
      state,
      action: PayloadAction<IEventDetail | undefined>
    ) => {
      state.eventDetail = action.payload;
    },
    setLastEventBeforeStart: (
      state,
      action: PayloadAction<IEventDetail | undefined>
    ) => {
      state.lastEventBeforeStart = action.payload;
    },
    setFirstEventAfterEnd: (
      state,
      action: PayloadAction<IEventDetail | undefined>
    ) => {
      state.firstEventAfterEnd = action.payload;
    },
    setEventBetweenStartAndEnd: (
      state,
      action: PayloadAction<IEventDetail | undefined>
    ) => {
      state.eventBetweenStartAndEnd = action.payload;
    },
    setError: (
      state,
      action: PayloadAction<{ type: ELabelDateType; error: string | null }>
    ) => {
      state.errors = {
        ...state.errors,
        [action.payload.type]: action.payload.error,
      };
    },
    setTime: (
      state,
      action: PayloadAction<{ type: ELabelDateType; time: string | undefined }>
    ) => {
      state[action.payload.type] = action.payload.time;
    },
    setMessage: (
      state,
      action: PayloadAction<{
        type: EDateMessageType;
        message: string;
      }>
    ) => {
      state[action.payload.type] = action.payload.message;
    },
    setModal: (state, action: PayloadAction<{ modalType: EModalTypes }>) => {
      state.modalType = action.payload.modalType;
    },
    setEventId: (state, action: PayloadAction<{ eventId: number | null }>) => {
      state.eventId = action.payload.eventId;
    },
    setEventType: (
      state,
      action: PayloadAction<{ eventType: string | null }>
    ) => {
      state.eventType = action.payload.eventType;
      state.lastEventBeforeStart = undefined;
      state.firstEventAfterEnd = undefined;
      state.eventBetweenStartAndEnd = undefined;
    },
    setAlertStatusData: (
      state,
      action: PayloadAction<{
        status: string | null;
        isCritical: boolean | null;
        presumedCauses: string | null;
        recommendedAction: string | null;
        anomalyTrend: string | null;
        diagDescription: string | null;
      }>
    ) => {
      state.status = action.payload.status;
      state.statusDefault = action.payload.status;
      state.isCritical = action.payload.isCritical;
      state.isCriticalDefault = action.payload.isCritical;
      state.presumedCauses = action.payload.presumedCauses;
      state.recommendedAction = action.payload.recommendedAction;
      state.anomalyTrend = action.payload.anomalyTrend;
      state.diagDescription = action.payload.diagDescription;
    },
    setAlertStatus: (
      state,
      action: PayloadAction<{
        status: string | null;
      }>
    ) => {
      state.status = action.payload.status;
    },
    setIsHidden: (
      state,
      action: PayloadAction<{
        isHidden: boolean;
      }>
    ) => {
      state.isHidden = action.payload.isHidden;
    },

    setIsCritical: (
      state,
      action: PayloadAction<{
        isCritical: boolean | null;
      }>
    ) => {
      state.isCritical = action.payload.isCritical;
    },
    setDiagDescription: (
      state,
      action: PayloadAction<{
        diagDescription: string | null;
      }>
    ) => {
      state.diagDescription = action.payload.diagDescription;
    },
    setPresumedCauses: (
      state,
      action: PayloadAction<{
        presumedCauses: string | null;
      }>
    ) => {
      state.presumedCauses = action.payload.presumedCauses;
    },
    setRecommendedAction: (
      state,
      action: PayloadAction<{
        recommendedAction: string | null;
      }>
    ) => {
      state.recommendedAction = action.payload.recommendedAction;
    },
    setAnomalyTrend: (
      state,
      action: PayloadAction<{
        anomalyTrend: string | null;
      }>
    ) => {
      state.anomalyTrend = action.payload.anomalyTrend;
    },
    setMaintenanceType: (
      state,
      action: PayloadAction<{ maintenanceType: number | null }>
    ) => {
      state.maintenanceType = action.payload.maintenanceType;
    },
    setEstimatedCurrency: (
      state,
      action: PayloadAction<{
        currency: string;
      }>
    ) => {
      state.estimatedPreventedDamageCurrency = action.payload.currency;
    },
    setEstimatedAmount: (
      state,
      action: PayloadAction<{
        amount: number;
      }>
    ) => {
      state.estimatedPreventedDamageAmount = action.payload.amount;
    },
    setReset: () => initialState,
    setLabels: (state, action: PayloadAction<{ labelGroups: any }>) => {
      state.labelGroups = action.payload.labelGroups;
    },
    setFailures: (
      state,
      action: PayloadAction<{ failures: TEventPlacementFailureExtended[] }>
    ) => {
      const reducedFailures = action.payload.failures?.reduce(
        (acc: IFailure[], curr: TEventPlacementFailureExtended) => {
          if (acc.find((item: any) => item.placement === curr.placement)) {
            return acc.map((item: any) => {
              if (item.placement === curr.placement) {
                if (!item.id) {
                  return item;
                }
                return {
                  ...item,
                  id: [...(item.id ?? []), curr.id],
                  note: [...(item.note ?? []), curr.note],
                  failure: [...(item.failure ?? []), curr.failure],
                  other_failure: [
                    ...(item.other_failure ?? []),
                    curr.other_failure,
                  ],
                };
              } else {
                return { ...item };
              }
            });
          } else if (curr.id) {
            return [
              ...acc,
              {
                ...curr,
                id: [curr.id],
                note: [curr.note],
                failure: [curr.failure],
                other_failure: [curr.other_failure],
              },
            ];
          } else {
            return [
              ...acc,
              {
                ...curr,
                id: [],
                note: [],
                failure: [],
                other_failure: [],
              },
            ];
          }
        },
        []
      );
      state.failures = action.payload.failures;
      state.newFailures = reducedFailures;
    },
    setFailure: (
      state,
      action: PayloadAction<{
        placement: number | string;
        failure: number | null;
        index: number;
      }>
    ) => {
      state.newFailures = [
        ...state.newFailures.map((failure: any) => {
          if (action.payload.placement === failure.placement) {
            return {
              ...failure,
              failure: failure.failure.map((item: any, index: number) => {
                if (index === action.payload.index) {
                  return action.payload.failure;
                }
                return item;
              }),
              other_failure: failure.other_failure.map(
                (item: any, index: number) => {
                  if (index === action.payload.index) {
                    return action.payload.failure !== null ? null : item;
                  }
                  return item;
                }
              ),
            };
          }
          return failure;
        }),
      ];
    },
    setOtherFailure: (
      state,
      action: PayloadAction<{
        placement: number | string;
        other_failure: string;
        index: number;
      }>
    ) => {
      state.newFailures = [
        ...state.newFailures.map((failure: any) => {
          if (action.payload.placement === failure.placement) {
            return {
              ...failure,
              other_failure: failure.other_failure.map(
                (item: any, index: number) => {
                  if (index === action.payload.index) {
                    return action.payload.other_failure;
                  }
                  return item;
                }
              ),
            };
          }
          return failure;
        }),
      ];
    },
    setNote: (
      state,
      action: PayloadAction<{
        placement: number | string;
        note: string;
        index: number;
      }>
    ) => {
      state.newFailures = [
        ...state.newFailures.map((failure: any) => {
          if (action.payload.placement === failure.placement) {
            return {
              ...failure,
              note: failure.note.map((item: any, index: number) => {
                if (index === action.payload.index) {
                  return action.payload.note;
                }
                return item;
              }),
            };
          }
          return failure;
        }),
      ];
    },
    addFailure: (
      state,
      action: PayloadAction<{
        placement: number | string;
      }>
    ) => {
      state.newFailures = [
        ...state.newFailures.map((failure: any) => {
          if (action.payload.placement === failure.placement) {
            return {
              ...failure,
              id: [...failure.id, null],
              failure: [...failure.failure, null],
              other_failure: [...failure.other_failure, null],
              note: [...failure.note, ""],
            };
          }
          return failure;
        }),
      ];
    },
    addFailurePlacement: (state, action: PayloadAction<{ event: number }>) => {
      state.newFailures = [
        ...state.newFailures,
        {
          id: [],
          event: action.payload.event,
          placement: "new_" + state.newFailures.length + 1,
          placementType: "new_type_" + state.newFailures.length + 1,
          other_failure: [],
          failure: [],
          note: [],
        },
      ];
    },
    addAllFailurePlacement: (
      state,
      action: PayloadAction<{
        event: number;
        placements: IFailure[];
        failures: IFailure[];
      }>
    ) => {
      state.newFailures = [
        ...action.payload.placements.map((item: IFailure, index: number) => {
          const findItem = action.payload.failures.find(
            (fail: IFailure) =>
              Number(fail.placement) === Number(item.placement)
          );
          if (findItem) {
            return findItem;
          } else {
            return {
              id: [],
              event: action.payload.event,
              placement: item.placement,
              placementType: item.placementType,
              other_failure: [],
              failure: [],
              note: [],
            };
          }
        }),
      ];
    },
    setFailurePlacement: (
      state,
      action: PayloadAction<{
        placement: number;
        placementType: number;
        index: string | number | null | undefined;
      }>
    ) => {
      state.newFailures = [
        ...state.newFailures.map((failure: any) => {
          if (action.payload.index === failure.placement) {
            return {
              ...failure,
              placement: action.payload.placement,
              placementType: action.payload.placementType,
              other_failure: [null],
            };
          }
          return failure;
        }),
      ];
    },
    removePlacement: (
      state,
      action: PayloadAction<{ placement: number | string | null | undefined }>
    ) => {
      state.newFailures = [
        ...state.newFailures.filter(
          (item: any) => item.placement !== action.payload.placement
        ),
      ];
    },
    removeAllPlacements: (state: any) => {
      state.newFailures = [];
    },
    removeFailure: (
      state,
      action: PayloadAction<{
        placement: number | string;
        idIndex: number;
      }>
    ) => {
      state.newFailures = [
        ...state.newFailures.map((item: any) => {
          if (item.placement === action.payload.placement) {
            return {
              ...item,
              id: item.id.filter(
                (_: any, index: number) => index !== action.payload.idIndex
              ),
              failure: item.failure.filter(
                (_: any, index: number) => index !== action.payload.idIndex
              ),
              other_failure: item.other_failure.filter(
                (_: any, index: number) => index !== action.payload.idIndex
              ),
              note: item.note.filter(
                (_: any, index: number) => index !== action.payload.idIndex
              ),
            };
          }
          return item;
        }),
      ];
    },
    tagGroupForDelete: (state, action: PayloadAction<{ index: number }>) => {
      const tagged = state.labelGroups.map((item, i) => {
        if (i === action.payload.index) {
          return {
            ...item,
            toRemoveLabels: item.labels.reduce((acc, curr) => {
              if (isNumber(curr.id)) {
                return [...acc, curr.id];
              }
              return acc;
            }, [] as number[]),
            toDelete: true,
          };
        }
        return { ...item };
      });

      state.labelGroups = tagged;
    },
    setInitialLabels: (
      state,
      action: PayloadAction<{ initialLabels: any }>
    ) => {
      state.initialLabels = action.payload.initialLabels;
      state.labelGroups = action.payload.initialLabels;
    },
    setComments: (
      state,
      action: PayloadAction<{ comments: TCommentWithMappedUser[] }>
    ) => {
      state.comments = action.payload.comments;
    },
    setInitialRelatedPairEvent: (
      state,
      action: PayloadAction<{ relatedPairEvent: number | null }>
    ) => {
      state.relatedPairEvent = action.payload.relatedPairEvent;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchEventData.fulfilled, (state: any, action: any) => {
      state.eventData = action.payload;
    });
  },
});

export * from "./labelActions";
export * from "./eventActions";
export * from "./failureActions";
export * from "./commentsActions";
