import axios from "axios";
import authHandler from "localStorage/authHandler";
import { logout } from "store/auth/actions";
import { getApiUrl } from "settings";
import queryString from "query-string";
import { store } from "store";
import { serverErrorSetStatus } from "store/serverError/actions";

const env = process.env.REACT_APP_ENVIRONMENT;
let baseURL = getApiUrl();

if (localStorage && localStorage.getItem("api") && env !== "master") {
  baseURL = localStorage.getItem("api")!;
}

const createInstance = (headers: HeadersInit) =>
  axios.create({
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    baseURL,
    transformRequest: [
      (data, headers) => {
        if (authHandler.hasToken()) {
          const tokenType = authHandler.getTokenType();
          if (tokenType === "token") {
            headers.Authorization = `Token ${authHandler.getToken()}`;
          } else if (tokenType === "temporary_access_token") {
            headers.Authorization = `temporary_access_token ${authHandler.getToken()}`;
          } else {
            throw new Error(`Invalid token type ${tokenType}`);
          }
        }
        return JSON.stringify(data);
      },
    ],
    paramsSerializer: function (params) {
      return queryString.stringify(params, { sort: false });
    },
  });

const create = (headers: HeadersInit) => {
  const inst = createInstance(headers);
  inst.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status === 401) {
        const expiredToken =
          error.response.data?.detail &&
          error.response.data.detail.indexOf("expired") !== -1;
        store.dispatch(logout(expiredToken));
      }
      if (axios.isCancel(error)) {
        return;
      }
      if (error.response !== undefined) {
        const { status, data } = error.response;
        return Promise.reject({ status, message: data.message || "" });
      }
      store.dispatch(serverErrorSetStatus(true));
      return Promise.reject(error);
    }
  );
  return inst;
};

const instance = createInstance({});

const instance_ignoring_errors = createInstance({});

instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response !== undefined && error.response.status === 401) {
      const expiredToken =
        error.response.data?.detail &&
        error.response.data.detail.indexOf("expired") !== -1;

      store.dispatch(logout(expiredToken));
    }
    if (axios.isCancel(error)) {
      return;
    }
    if (error.response !== undefined) {
      const { status, data } = error.response;
      return Promise.reject({ status, message: data.message || "" });
    }
    store.dispatch(serverErrorSetStatus(true));
    return Promise.reject(error);
  }
);

instance_ignoring_errors.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response !== undefined && error.response.status === 401) {
      const expiredToken =
        error.response.data?.detail &&
        error.response.data.detail.indexOf("expired") !== -1;
      store.dispatch(logout(expiredToken));
    }
    if (axios.isCancel(error)) {
      return;
    }
    if (error.response !== undefined) {
      const { status, data } = error.response;
      return Promise.reject({ status, message: data.message || "" });
    }
    return Promise.reject(error);
  }
);

export {
  instance_ignoring_errors as ignoreErrors,
  create as axiosNeuronCustom,
};

export default instance;
