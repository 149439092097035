import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { resolveSorting } from "shared/helpers";

export default async function getReports(params: any) {
  const url = endpoints.reports.default;

  try {
    const { data } = await axiosNeuron.get(url, {
      params: {
        project: params.id,
        page: params.page,
        ps: params.pageSize,
        order_by: resolveSorting("-created_for", params?.orderBy),
      },
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
