import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { getAvailableDates } from "api/handlers/dataLabeling/getAvailableDates";
import { getPlacements } from "api/handlers/dataLabeling/getPlacements";
import { getAvailableLabels } from "api/handlers/dataLabeling/getAvailableLabels";
import { getPlacementTypes } from "api/handlers/dataLabeling/getPlacementTypes";
import { getAudioFromChunks } from "api/handlers/dataLabeling/getAudioFromChunks";
import { getSubsampledAudio } from "api/handlers/dataLabeling/getSubsampledAudio";
import { getAudioRegions } from "api/handlers/dataLabeling/getAudioRegions";
import { getModelResults } from "api/handlers/dataLabeling/getModelResults";
import moment from "moment";
import { getDatetimeTimestamp } from "components/dataLabeling/helpers";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { getLabelCategories } from "api/handlers/dataLabeling/getLabelCategories";
import soundsActions from "../sounds/actions";

export const path = "machineDetail/analysisSounds";

const actionData = [
  [EActions.setCommonData, "data", "cb"],
  [EActions.setZoom, "zoom", "placement"],
  [EActions.setLoadingBuffer, "placement", "loadingBuffer"],
  [
    EActions.setBufferLoaded,
    "placement",
    "buffer",
    "offsets",
    "subOffsets",
    "totalChunks",
    "startDatetimes",
    "selectionDates",
    "rest",
  ],
  [EActions.setLoadingModelResults, "loadingModelResults"],
  [EActions.setModelResultsLoaded, "modelResults"],
  [EActions.changeTimezone, "data"],
  [EActions.setReset],
  [EActions.setBulkZoom, "zoom"],
  [EActions.setPlaying, "placement"],
  [EActions.setLargeDownloadAllowed, "largeDownloadAllowed"],
  [EActions.setVolume, "placement", "volume"],
  [EActions.setIsAnalysed, "isAnalysed"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const fetchStaticData =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { timezoneOffset } = getState().machineDetail.analysisSounds;
      const machine = getState().machineDetail.machine.machine;
      const [yearData, placements] = await Promise.all([
        getAvailableDates(machine.id, timezoneOffset),
        getPlacements(machine.id),
      ]);

      const allLabels = await getAvailableLabels(machine.id);
      const allLabelsCategoryIds: number[] = uniq(
        allLabels
          .map(({ category }: { category: number }) => category)
          .filter((category: number) => Boolean(category))
      );
      const labelCategories = allLabelsCategoryIds.length
        ? await getLabelCategories(allLabelsCategoryIds)
        : [];

      const placementTypesIds = placements
        ? placements.map((placement: any) => placement.type)
        : [];

      const placementTypes = await getPlacementTypes({
        ids: placementTypesIds,
      });
      dispatch(
        actions.setCommonData({
          yearData,
          placements: mapValues(keyBy(placements, "value")),
          labelPlacements: placements,
          placementTypes,
          allLabels,
          labelCategories,
          machine,
        })
      );

      dispatch(soundsActions.setCommonData({ placementTypes }));
    } catch (err) {}
  };

export const fetchCommonData =
  ({ tz, dates, count, placement, duration }: any, cb: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { machine } = getState().machineDetail.analysisSounds;
      dispatch(
        actions.setCommonData({
          dates,
          count,
          timezoneOffset: tz,
          machine,
          duration,
        })
      );

      dispatch(
        fetchSound({
          timezoneOffset: tz,
          dates,
          count,
          placement,
          duration,
        })
      );
    } catch (err) {}
  };

export const fetchSound =
  ({ timezoneOffset, dates, count, placement, duration }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        machine: { id },
        loadingCommonData,
      } = getState().machineDetail.analysisSounds;

      if (
        id &&
        dates &&
        placement &&
        duration &&
        timezoneOffset &&
        !loadingCommonData
      ) {
        dispatch(actions.setLoadingBuffer(placement, true));
        const audioFromChunks: any =
          count > 0
            ? await getAudioFromChunks(
                placement,
                dates.map((date: string) => moment(date).valueOf()),
                count,
                timezoneOffset
              )
            : await getSubsampledAudio(
                placement,
                dates.map((date: string) => moment(date).valueOf()),
                -count,
                timezoneOffset
              );
        dispatch(
          actions.setBufferLoaded(placement, {
            buffer: audioFromChunks.buffer,
            offsets: audioFromChunks.offsets,
            subOffsets: audioFromChunks.subOffsets,
            totalChunks: audioFromChunks.totalChunks,
            startDatetimes: audioFromChunks.startDatetimes,
            bufferStart: moment(dates[0]).valueOf(),
            bufferEnd: moment(dates[0]).valueOf() + duration,
            selectionDates: audioFromChunks.selectionDates,
            sensorTypeCodename: audioFromChunks.sensorTypeCodename,
            originalSampleRate: audioFromChunks.originalSampleRate,
          })
        );
        dispatch(
          fetchModelResults({
            placement,
            types: [
              "anomaly_detector",
              "loudness",
              "rms_velocity",
              "envelope_energy",
            ],
            environments: ["production"],
            dates: audioFromChunks.startDatetimes.map(
              (i: Date, index: number) => ({
                from: new Date(Math.floor(i.getTime() / 1000) * 1000),
                to: new Date(
                  Math.floor(
                    i.getTime() / 1000 +
                      (audioFromChunks.subOffsets[index + 1] -
                        audioFromChunks.subOffsets[index]) /
                        audioFromChunks.buffer.sampleRate
                  ) * 1000
                ),
              })
            ),
          })
        );
      }
    } catch (err) {
      dispatch(actions.setLoadingBuffer(placement, false));
    }
  };

export const loadBufferSilent =
  (windowStart: number, offset: number) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const {
      machine: { id },
      duration,
      timezoneOffset,
      labelPlacements,
    } = getState().machineDetail.analysisSounds;

    const start = windowStart + offset;
    const end = start + duration;
    for (let i of labelPlacements) {
      Promise.all([
        getAudioFromChunks(i.value, [start, start], 15, timezoneOffset),
        // device, start, start + 1h, channel, tz
        getAudioRegions(
          id,
          windowStart,
          windowStart + 3600,
          i.value,
          timezoneOffset
        ),
      ]).then(
        ([
          {
            buffer,
            offsets,
            subOffsets,
            totalChunks,
            startDatetimes,
            selectionDates,
            originalSampleRate,
          },
          regions,
        ]: any[]) => {
          // loading wave & labels
          dispatch(
            actions.setBufferLoaded(i.value, {
              buffer,
              offsets,
              subOffsets,
              totalChunks,
              startDatetimes,
              regions,
              bufferStart: start,
              bufferEnd: end,
              selectionDates,
              originalSampleRate,
            })
          );
        }
      );
    }
  };

export const changeTimezone =
  (newDate: string, newTime: string, tz: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { duration, labelPlacements } =
      getState().machineDetail.analysisSounds;
    const start = getDatetimeTimestamp(newDate + " " + newTime);
    const buffers: any = {};

    if (start) {
      for (let i of labelPlacements) {
        buffers[i.value] = {
          buffer: {
            bufferStart: getDatetimeTimestamp(newDate + " " + newTime),
            bufferEnd: getDatetimeTimestamp(newDate + " " + newTime) + duration,
          },
        };
      }
      dispatch(
        actions.changeTimezone({
          timezoneOffset: tz,
          date: newDate,
          time: newTime,
          buffers,
          bufferStart: getDatetimeTimestamp(newDate + " " + newTime),
        })
      );
    }
  };

export const fetchModelResults =
  ({ placement, types, environments, dates, interval }: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions.setLoadingModelResults(true));
      const modelResults: any[] = await getModelResults(
        [placement],
        types,
        environments,
        dates,
        interval
      );
      dispatch(actions.setModelResultsLoaded(modelResults));
    } catch (err) {
      dispatch(actions.setLoadingModelResults(false));
    }
  };
