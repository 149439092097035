import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store";

export const useFailureTypesSelect = createSelector(
  (state: AppState) => state.failureTypes,
  (failureTypes: any) => {
    return {
      ...failureTypes,
      data: failureTypes.data.map((failure: any) => ({
        id: failure.id,
        name: failure.codename,
      })),
    };
  }
);
