import React, { PureComponent } from "react";

class Selection extends PureComponent {
  static defaultProps = {
    start: 0,
    end: 0,
    label: null,
    color: "blue",
  };

  render() {
    let { start, end, label, color, textColor } = this.props;
    let startX = Math.min(start || 0, end || 0);
    let endX = Math.max(start || 0, end || 0);

    return endX - startX > 1 && end !== null ? (
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          left: startX,
          width: endX - startX,
          top: 0,
          bottom: 0,
          background: color,
          color: textColor,
          padding: 5,
          overflow: "hidden",
          border: "solid 1px #aaaaaa",
          borderRadius: 5,
        }}
      >
        <span style={{ whiteSpace: "pre" }} className="unselectable">
          {label ? label : null}
        </span>
      </div>
    ) : null;
  }
}

export default Selection;
