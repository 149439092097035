import { useCallback, useRef, useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortingRule } from "react-table";
import { createSelector } from "reselect";

import { IUsersState } from "store/projectDetail/users/types";
import { AppState } from "store";
import actions, { fetchUsers } from "store/projectDetail/users/actions";
import useCompareFetch from "shared/hooks/useCompareFetch";
import isEqual from "react-fast-compare";

const pageSize = 10;

const selector = createSelector(
  (state: AppState) => state.projectDetail.users,
  (state: AppState) => state.addUserModal.modalOpened,
  (users: IUsersState, modalOpened: string | null) => {
    return {
      pageIndex: users.pageIndex,
      users: users.usersData,
      isFetching: users.isFetching,
      q: users.q,
      modalOpened,
      more: users.more,
      userIds: users.userIds,
    };
  }
);

const useDataUsers = (id: number, status?: string, ps?: number) => {
  const dispatch: any = useDispatch();
  const { users, isFetching, pageIndex, q, modalOpened, more, userIds } =
    useSelector(selector, isEqual);

  const showMoreClickedTimesRef = useRef(0);
  const [sortByState, setSortByState] = useState<SortingRule<number>[]>([
    { id: "assigned_project", desc: false },
    { id: "last_name", desc: true },
    { id: "id", desc: true },
  ]);

  const initialState = useMemo(
    () => ({
      pageIndex,
      pageSize: ps ? ps : pageSize,
      pageCount: users && users.results ? Math.ceil(users.count / pageSize) : 0,
      sortBy: sortByState,
    }),
    [pageIndex, ps, sortByState, users]
  );

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (showMoreClickedTimesRef.current > 0) {
        dispatch(actions.setMore(showMoreClickedTimesRef.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  const onPageSort = useCallback((sortBy: SortingRule<number>[]) => {
    setSortByState(sortBy);
  }, []);

  const fetchData = useCallback(
    (obj: any) => {
      const { more, ...rest } = obj;
      const pageSize = !!more ? (more + 1) * 10 : 10;
      dispatch(
        fetchUsers({
          pageSize,
          ...rest,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (more !== showMoreClickedTimesRef.current) {
      showMoreClickedTimesRef.current = more;
    }
  }, [more]);

  useCompareFetch(fetchData, {
    page: pageIndex + 1,
    orderBy: sortByState,
    filterObject: {
      company: [id],
      status: status,
    },
    q,
    more,
  });

  return {
    users,
    isFetching,
    initialState,
    modalOpened,
    showMoreClickedTimesRef,
    onPageChange,
    onPageSort,
    pageIndex,
    sortByState,
    userIds,
  };
};

export default useDataUsers;
