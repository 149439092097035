import { TEventStatus } from "types/event";

export enum EActions {
  setLoading = "setLoading",
  setData = "setData",
}

export type TEventStatusesState = {
  loading: boolean;
  data?: TEventStatus[];
};

export interface TActions {
  type: typeof EActions;
  payload: TEventStatusesState;
}
