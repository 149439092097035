import { AnalysisTimeManagerData } from "components/analysisTimeManager/analysisTimeManager";

export enum EActions {
  setData = "setData",
}

export interface IATMState {
  data: AnalysisTimeManagerData | null;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IATMState;
}

export type TReducers = {
  [key: string]: (p: IActionTypes, s: IATMState) => void;
};
