import { IFacility } from "types/facility";

export enum EActions {
  setLoading = "setLoading",
  setFacility = "setFacility",
  setReset = "setReset",
}

export interface IProjectState {
  loading: boolean;
  facility: IFacility | null;
}
export interface IActionTypes {
  type: typeof EActions;
  payload: IProjectState;
}
