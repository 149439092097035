import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ResponseType } from "types/response";
import {
  IConnectableMachine,
  IConnectableMachineResponse,
} from "types/machine";

export default async function getConnectableMachines(
  id: number,
  disconnectedChannel: number | null
) {
  const url = endpoints.devices.connectable_machines(id);

  try {
    const response = await axiosNeuron.get(
      `${url}${
        disconnectedChannel
          ? `?disconnected_channel=${disconnectedChannel}`
          : ""
      }`
    );
    const data: ResponseType<IConnectableMachine> = {
      count: response.data.length,
      results: response.data.map((item: IConnectableMachineResponse) => ({
        id: item.id,
        name: item.name,
        projectId: item.project,
        companyId: item.company,
        facilityId: item.facility,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
