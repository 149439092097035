import { useEffect, useCallback, useRef, useMemo } from "react";
import actions, {
  fetchFacilities,
} from "store/projectDetail/facilities/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";
import { SortingRule } from "react-table";

import { createSelector } from "reselect";

const pageSize = 10;

const selector = createSelector(
  (state: AppState) => state.projectDetail.facilities,
  (state: any) => {
    return {
      facilities: state.facilities,
      loading: state.loading,
      pageIndex: state.pageIndex,
      reloading: state.reloading,
      modalOpened: state.modalOpened,
      sort: state.sort,
    };
  }
);

const useDataProjects = (id: number) => {
  const { loading, pageIndex, reloading, sort, facilities, modalOpened } =
    useSelector(selector, shallowEqual);

  const sm = useRef(0);

  const dispatch: any = useDispatch();

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        facilities && facilities.results
          ? Math.ceil(facilities.count / pageSize)
          : 0,
      sortBy: sort,
    };
  }, [facilities, pageIndex, sort]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      dispatch(actions.setPage(pageIndex));
    },
    [dispatch]
  );

  const onPageSort = useCallback(
    (sortBy: SortingRule<number>[]) => {
      dispatch(actions.setSort(sortBy));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(actions.setShowMore(sm.current));
    if (!sm.current) {
      dispatch(actions.setReloading(true));
    } else {
      dispatch(actions.setLoading(true));
    }

    dispatch(
      fetchFacilities({
        id,
        page: !sm.current ? pageIndex + 1 : 1,
        pageSize: sm.current ? pageSize * (1 + pageIndex) : pageSize,
        orderBy: sort,
      })
    );
  }, [dispatch, id, pageIndex, sort]);

  return {
    facilities,
    pageIndex,
    loading,
    reloading,
    initialState,
    modalOpened,
    showMoreClickedTimesRef: sm,
    onPageChange,
    onPageSort,
  };
};
export default useDataProjects;
