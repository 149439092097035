import { Column } from "react-table";
import { TFunction } from "i18next";
import ChunkList from "./cells/chunkList";

export const createColumns = (t: TFunction): Column<any>[] => [
  {
    id: "chunkList",
    Header: t("").toString(),
    accessor: ({ chunks }) => {
      return {
        chunks,
      };
    },
    Cell: ChunkList,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
];
