import { EActions, IActionTypes } from "./types";
import { prepareActions } from "store/helpers";

export const path = "machineDetail/analysisTimeManager";

const actionData = [[EActions.setData, "data"]];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;
