import { EActions, IMachineFilterAction } from "./types";
import { prepareActions } from "store/helpers";

export const path = "machineFilter";

const actionsData = [
  [EActions.setFilter, "filters"],
  [EActions.setPage, "currentPage"],
  [EActions.reset, "reset"],
];
const actions = prepareActions<IMachineFilterAction, EActions>(
  actionsData,
  path
);

export default actions;
