import React, { Dispatch, SetStateAction, useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { ICompanyList } from "types/company";
import { IProjectList } from "types/project";
import Spinner from "components/spinner";
import EPermissions from "shared/permissions";
import Group from "./group";
import AuthorizedBlock from "components/authorizedBlock";
import Grid from "@mui/material/Grid";
import { CustomFooter } from "components/customFooter";
import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";
import useData from "dataHooks/devices/activateDevice";
import actions from "store/activateDevice/actions";
const { setPage, setSelectedProject } = actions;

type Props = {
  projectId: number | null;
  closeModalMove?: () => void;
  deviceId: number;
  setProjectId: Dispatch<SetStateAction<number | null>>;
};

const useStyles = makeStyles((theme: IMuiTheme) => ({
  footer: {
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(3, 0),
    },
  },
}));

const ChooseProject = memo(({ setProjectId }: Props) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();

  const {
    loading,
    localCompanies,
    localProjects,
    page,
    selectedProject,
    pageCount,
  } = useData();

  const handleRadioChange = useCallback(
    (event: React.ChangeEvent<{}>) => {
      dispatch(
        setSelectedProject(parseInt((event.target as HTMLInputElement).value))
      );
      setProjectId(parseInt((event.target as HTMLInputElement).value));
    },
    [dispatch, setProjectId]
  );

  const goToNext = useCallback(() => {
    if (page < pageCount - 1) {
      dispatch(setPage(page + 1));
    }
  }, [dispatch, page, pageCount]);

  const goToPrevious = useCallback(() => {
    if (page !== 0) {
      dispatch(setPage(page - 1));
    }
  }, [dispatch, page]);

  const setCurrentPage = useCallback(
    (num: number) => {
      dispatch(setPage(num));
    },
    [dispatch]
  );

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <AuthorizedBlock
        authorizedTo={[
          EPermissions.CREATE_ANY_PROJECT,
          EPermissions.CREATE_OWN_PROJECT,
        ]}
      >
        {localCompanies &&
          localProjects &&
          localCompanies.map((company: ICompanyList) => {
            return (
              <Group
                key={company.id}
                companyName={company.name}
                companyId={company.id}
                handleRadioChange={handleRadioChange}
                activeProjectId={selectedProject}
                rerender={
                  !!localProjects
                    .filter(
                      (project: IProjectList) => project.company === company.id
                    )
                    .find((item: IProjectList) => item.id === selectedProject)
                }
                projects={localProjects.filter(
                  (project: IProjectList) => project.company === company.id
                )}
              />
            );
          })}
      </AuthorizedBlock>
      {pageCount > 1 && (
        <Grid className={classes.footer} container justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <CustomFooter
              pageIndex={page}
              previousPage={goToPrevious}
              nextPage={goToNext}
              canPreviousPage={page > 0}
              canNextPage={page < pageCount - 1}
              pageCount={pageCount}
              gotoPage={setCurrentPage}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default ChooseProject;
