import { TMaintenanceType } from "types/event";

export enum EActions {
  setLoading = "setLoading",
  setData = "setData",
}

export type TMaintenanceTypeState = {
  loading: boolean;
  data?: TMaintenanceType[];
};

export interface TLangsActions {
  type: typeof EActions;
  payload: TMaintenanceTypeState;
}
