import React, { memo, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Spinner from "components/spinner";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import { IUserList } from "types/user";
import usersActions, {
  fetchProjectUsers,
  handleUserCheck,
} from "store/projectDetail/users/actions";
import { useDispatch, useSelector } from "react-redux";
import { createTinyColumns } from "./tinyColumn";
import { createColumns } from "./column";
import Button from "components/button";
import { AppState } from "store";

const { setSearch } = usersActions;

type TProjectDetail = {
  projectId: number;
  companyId: number;
};

export const UsersList = memo(({ companyId }: TProjectDetail) => {
  const { projectusers, loading, userIds } = useSelector(
    (state: AppState) => state.projectDetail.users
  );

  const dispatch: any = useDispatch();

  const theme = useTheme();
  const { t } = useTranslation();
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(t, userIds),
    [t, userIds]
  );
  const columnsMemoized = useMemo(
    () => createColumns(t, userIds),
    [t, userIds]
  );
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  const dataItems = useMemo(() => {
    if (!projectusers) {
      return [];
    }
    return projectusers.results;
  }, [projectusers]);

  const userIdList = dataItems.map((user: any) => user.id);

  useEffect(() => {
    dispatch(handleUserCheck({ userIds: userIdList }));
  }, [dataItems, dispatch]);

  // To avoid the forever loading since functionality is reused
  useEffect(() => {
    dispatch(setSearch(undefined));

    dispatch(fetchProjectUsers({ is_active: true, is_internal: false }));
  }, [dispatch]);

  const handleSelectAll = useCallback(() => {
    dispatch(handleUserCheck({ userIds: userIdList }));
  }, [dispatch, userIdList]);
  const handleDeselectAll = useCallback(() => {
    dispatch(handleUserCheck({ userIds: [] }));
  }, [dispatch]);

  return (
    <>
      <div onClick={(event) => event.stopPropagation()}>
        <Button
          disabled={userIds.length >= dataItems.length}
          onClick={handleSelectAll}
        >
          {t("filter.selectAll")}
        </Button>
        <Button disabled={userIds.length === 0} onClick={handleDeselectAll}>
          {t("filter.deselectAll")}
        </Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div id="testProjectReportsUser">
          <Table<IUserList>
            isLoading={loading!}
            data={dataItems}
            rowsCount={projectusers ? projectusers.count : 0}
            columns={columns}
            RowComponent={Row}
            HeaderComponent={Header}
          />
        </div>
      )}
    </>
  );
});

export default UsersList;
