import React from "react";
import { makeStyles } from "@mui/styles";
import StatusComponent from "components/status";
import moment from "moment";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      transform: `translateY(${theme.spacing(0.5)})`,
    },
  };
});

enum EStatusName {
  ACTIVE = "active",
  EXPIRED = "expired",
}

interface Props {
  cell: {
    value: {
      expires_at: string;
    };
  };
}

const Status = <T extends Object>(props: any) => {
  const {
    cell: {
      value: { expires_at },
    },
  } = props;
  const classes = useStyles(props);
  const currentDate = moment();
  const dateToCheck = moment(expires_at);

  const status: EStatusName = dateToCheck.isBefore(currentDate)
    ? EStatusName.EXPIRED
    : EStatusName.ACTIVE;

  return (
    <div className={classes.root}>
      <StatusComponent name={status} />
    </div>
  );
};

export default Status;
