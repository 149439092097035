import React from "react";
import appUrls from "shared/appUrls";
import StaticDialog from "components/staticDialog";
import Box from "@mui/material/Box";
import Heading4 from "components/typography/heading/heading4";
import Paragraph from "components/typography/paragraph";
import { useTranslation } from "react-i18next";
import Button from "components/button";
import moment from "moment";

const Error: React.FC = () => {
  const { t } = useTranslation();
  const handleButtonClick = () => {
    window.location.href = appUrls.appRoot.index();
  };
  return (
    <StaticDialog>
      <Box mb={4}>
        <Heading4>{t("errorBoundary.title")}</Heading4>
      </Box>
      <Paragraph>{t("errorBoundary.text")}</Paragraph>
      <Box my={2}>{moment().format("HH:mm DD/MM/YYYY")}</Box>
      <Box mt={3}>
        <Button color="secondary" onClick={handleButtonClick}>
          {t("errorBoundary.goToHomepage")}
        </Button>
      </Box>
    </StaticDialog>
  );
};
export default Error;
