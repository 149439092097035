import React, { memo, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "react-fast-compare";
import SingleActionModal from "components/singleActionModal";
import Paragraph from "components/typography/paragraph";
import Radio from "components/radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { EnvTypes } from "store/machineDetail/oneClickTraining/types";
import TextField from "@mui/material/TextField";
import { DatePicker } from "./datePicker";
import moment from "moment";
import { checkEnvRegex } from "shared/helpers";
import dayjs from "dayjs";

const MAX_DAYS = 1;

const RecomputeModal = memo(
  ({
    machineId,
    modelId,
    onCancel,
    onConfirm,
  }: {
    machineId: number;
    modelId: number;
    onCancel: () => void;
    onConfirm: (from: Date, to: Date, environment: string) => void;
  }) => {
    const { t } = useTranslation();

    const [from, setFrom] = useState<Date | null>(null);
    const [to, setTo] = useState<Date | null>(null);
    const [environment, setEnvironment] = useState<string>(EnvTypes.prod);
    const [otherEnvironment, setOtherEnvironment] = useState<string>("");
    const handleEnvironment = (e: any) => setEnvironment(e.target.value);
    const handleOtherEnvironment = (e: any) =>
      setOtherEnvironment(e.target.value);

    const error = useMemo(
      () =>
        from &&
        to &&
        (from < moment(to).subtract(MAX_DAYS, "days").toDate() || from > to)
          ? t("recompute.invalidDateRange")
          : null,
      [from, to]
    );

    return (
      <SingleActionModal
        open={true}
        modalTitle={t("machine.detail.model.recomputeDialog.title")}
        finishButtonLabel={t("machine.detail.model.recompute")}
        handleFinish={() =>
          onConfirm(
            from!,
            to!,
            !!environment.length ? environment : otherEnvironment
          )
        }
        closeModal={onCancel}
        submitDisabled={
          !from ||
          !to ||
          !!error ||
          (!environment.length && !checkEnvRegex(otherEnvironment))
        }
      >
        <>
          <Paragraph>
            {t("machine.detail.model.recomputeDialog.subtitle")}
          </Paragraph>
          <Box m={2}>
            <DatePicker
              onChange={(date: Date | null) => setFrom(date)}
              date={from}
              maxDate={dayjs(to)}
              minDate={moment(to).subtract(MAX_DAYS, "day").toDate()}
              label={t("machine.detail.model.recomputeDialog.from")}
            />
          </Box>
          <Box m={2}>
            <DatePicker
              onChange={(date: Date | null) => setTo(date)}
              date={to}
              maxDate={moment(from).add(MAX_DAYS, "day").toDate()}
              minDate={dayjs(from)}
              label={t("machine.detail.model.recomputeDialog.to")}
              error={error}
            />
          </Box>
          <Box m={2}>
            <RadioGroup value={environment} onChange={handleEnvironment}>
              <Radio
                activeValue={environment}
                value={EnvTypes.prod}
                label={t("recompute.select.env.prod")}
              />
              <Radio
                activeValue={environment}
                value={EnvTypes.stage}
                label={t("recompute.select.env.stage")}
              />
              <Radio
                activeValue={environment}
                value=""
                label={t("machine.detail.model.other")}
              />
            </RadioGroup>
            <TextField
              disabled={!!environment.length}
              variant="outlined"
              onChange={handleOtherEnvironment}
              value={otherEnvironment}
            />
          </Box>
        </>
      </SingleActionModal>
    );
  },
  isEqual
);

export default RecomputeModal;
