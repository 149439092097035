import React from "react";
import ModelTable from "components/models";

const StandardModels: React.FC = () => {
  return (
    <>
      <ModelTable />
    </>
  );
};

export default StandardModels;
