import React, { memo, useCallback } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "components/iconButton";
import { useNavigate } from "react-router-dom";
import urls from "shared/appUrls";
import { makeStyles } from "@mui/styles";
import Tooltip from "components/tooltip";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import { cellCompare } from "shared/helpers";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.custom.palette.primary[100],
  },
}));

const MachineRedirect = <T extends Object>({
  cell: {
    value: { machine },
  },
}: CellProps<T>) => {
  const classes = useStyles();
  const history = useNavigate();
  const { t } = useTranslation();

  const redirect = useCallback(() => {
    if (machine) {
      history(urls.machines.detail(machine));
    }
  }, [history, machine]);
  if (!machine) return null;

  return (
    <IconButton onClick={redirect} color="primary">
      <Tooltip
        title={<span>{t("devices.detail.goToMachine")}</span>}
        placement="top"
      >
        <LaunchIcon className={classes.icon} />
      </Tooltip>
    </IconButton>
  );
};

export default memo(MachineRedirect, cellCompare) as typeof MachineRedirect;
