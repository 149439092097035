import React from "react";
import { makeStyles } from "@mui/styles";
import { AppState } from "store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      transform: `translateY(${theme.spacing(0.5)})`,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    role: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
    },
  };
});

interface Props {
  cell: {
    value: {
      user: number;
    };
  };
}

const Role = <T extends Object>(props: any) => {
  const {
    cell: {
      value: { user },
    },
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { project } = useSelector(
    (state: AppState) => state.projectDetail.project
  );

  const role =
    user === project.user_ro.id
      ? t("temporaryToken.list.table.role.readOnly")
      : t("temporaryToken.list.table.role.readAndWrite");

  return (
    <div className={classes.root}>
      <span className={classes.role}>{role}</span>
    </div>
  );
};

export default Role;
