import { useEffect, useCallback, useRef, useMemo, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";
import { SortingRule } from "react-table";

import { createSelector } from "reselect";
import actions, {
  fetchTemporaryToken,
} from "store/projectDetail/temporaryToken/actions";
import useCompareFetch from "shared/hooks/useCompareFetch";

const pageSize = 10;

const selector = createSelector(
  (state: AppState) => state.projectDetail.temporaryAccessToken,
  (state: any) => {
    return {
      temporaryTokens: state.temporaryTokenData,
      loading: state.isFetching,
      pageIndex: state.pageIndex,
      reloading: state.reloading,
      modalOpened: state.modalOpened,
      more: state.more,
      readOnly: state.readOnly,
      expiresAt: state.expiresAt,
      machines: state.machines,
    };
  }
);

const useDataProjectsTemporaryToken = ({
  id,
  isDeleted,
  isExpired,
}: {
  id: number;
  isDeleted?: boolean;
  isExpired?: boolean;
}) => {
  const {
    loading,
    pageIndex,
    reloading,
    temporaryTokens,
    modalOpened,
    readOnly,
    expiresAt,
    machines,
  } = useSelector(selector, shallowEqual);

  const [sortByState, setSortByState] = useState<SortingRule<number>[]>([
    { id: "expires_at", desc: true },
    { id: "id", desc: true },
  ]);

  const sm = useRef(0);

  const dispatch: any = useDispatch();

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        temporaryTokens && temporaryTokens.results
          ? Math.ceil(temporaryTokens.count / pageSize)
          : 0,
      sortBy: sortByState,
    };
  }, [temporaryTokens, pageIndex, sortByState]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (sm.current > 0) {
        dispatch(actions.setMore(sm.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  const onPageSort = useCallback((sortBy: SortingRule<number>[]) => {
    setSortByState(sortBy);
  }, []);

  useEffect(() => {
    dispatch(
      fetchTemporaryToken({
        page: !sm.current ? pageIndex + 1 : 1,
        pageSize: sm.current ? pageSize * (1 + pageIndex) : pageSize,
        orderBy: sortByState,
        projectIds: [id],
        isExpired,
        isDeleted,
      })
    );
    //react-hooks/exhaustive-deps
  }, [dispatch]);

  useCompareFetch((params: any) => dispatch(fetchTemporaryToken(params)), {
    page: !sm.current ? pageIndex + 1 : 1,
    pageSize: sm.current ? pageSize * (sm.current + 1) : pageSize,
    orderBy: sortByState,
    projectIds: [id],
    isExpired,
    isDeleted,
    silent: true,
  });

  return {
    temporaryTokens,
    pageIndex,
    loading,
    reloading,
    initialState,
    modalOpened,
    showMoreClickedTimesRef: sm,
    readOnly,
    expiresAt,
    machines,
    onPageChange,
    onPageSort,
  };
};
export default useDataProjectsTemporaryToken;
