import { Column } from "react-table";
import LabelAndTag from "components/users/list/cells/labelAndTag";
import LabelEmail from "components/users/list/cells/labelEmail";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import { getTranslatedRole } from "shared/roles";
import BelongsToProject from "./cells/belongsToProject";

export const createColumns = (
  t: TFunction,
  userIds: number[]
): Column<IUserList>[] => [
  {
    id: "assigned_project",
    Header: t("projects.detail.modal.reports.send.check").toString(),
    accessor: ({ id }) => ({
      id,
      userIds,
    }),
    Cell: BelongsToProject,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "last_name",
    Header: t("projects.detail.modal.reports.send.nameRole").toString(),
    accessor: ({ firstName, lastName, role }) => {
      return {
        name: [firstName, lastName].join(" "),
        role: getTranslatedRole(t, role),
      };
    },
    Cell: LabelAndTag,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "email",
    Header: t("projects.detail.modal.reports.send.email").toString(),
    accessor: ({ email }) => {
      return { label: email };
    },
    Cell: LabelEmail,
    custom: {
      hidePriority: 0,
    },
  },
];
