import { IProjectMachinesState, EActions, TReducers } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IProjectMachinesState = {
  machines: null,
  loading: true,
  error: null,
};

const reducers: TReducers = {
  [EActions.fetchProjectMachinesRequest]: (_) => ({
    loading: true,
  }),
  [EActions.fetchProjectMachinesFail]: ({ payload: { error } }) => ({
    loading: false,
    error,
  }),
  [EActions.fetchProjectMachinesSuccess]: ({ payload: { machines } }) => ({
    loading: false,
    error: null,
    machines,
  }),

  [EActions.setReset]: () => ({ ...initialState }),
};

export default createReducer<IProjectMachinesState>(
  path,
  initialState,
  reducers
);
