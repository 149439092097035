import React, { memo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import isEqual from "react-fast-compare";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import UsersList from "./components/usersList";
import { editProject } from "store/addUserModal/actions";
import { ModalWrap } from "components/events/components/modalWrap";
import { AppState } from "store";
import actions from "store/addUserModal/actions";
import TextField from "components/textField";
import { maxCharacters } from "shared/form/validators";

const { setModal, setSearch } = actions;

interface IAddUserModal {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export default memo(({ onCancel }: IAddUserModal) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const { id } = useParams<{ id?: string }>();
  const dispatch: any = useDispatch();
  const { modalOpened } = useSelector((state: AppState) => state.addUserModal);

  const onSubmit = useCallback(
    (data: any) => {
      dispatch(setSearch(data.searchUserField));
    },
    [dispatch]
  );

  const onSubmitEvent = useCallback(() => {
    dispatch(editProject(Number(id)));
  }, [dispatch, id]);

  const openModal = useCallback(() => {
    dispatch(setModal("create"));
  }, [dispatch]);

  if (!modalOpened) {
    return null;
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            id="projectDetail.ModalForm"
            ref={(el: any) => (formRef.current = el)}
            onSubmit={handleSubmit}
          >
            <ModalWrap
              open={true}
              title={t("projects.detail.modal.users.addUser")}
              cta={t("Confirm")}
              submitDisabled={false}
              onCancel={onCancel}
              // TODO remove comment after https://gitlab.com/neuronsw-infrastructure/customer-portal/-/merge_requests/412/diffs is merged
              // mandatory
              callToAction={openModal}
              callToActionTitle="projects.detail.modal.users.create"
              onSubmit={onSubmitEvent}
            >
              <>
                <Box px={3}>
                  <Field
                    name="searchUserField"
                    id="testSearchUserField"
                    component={TextField}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") handleSubmit();
                    }}
                    label={t("projects.detail.modal.users.search")}
                    validate={maxCharacters(255)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleSubmit}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <UsersList />
              </>
            </ModalWrap>
          </form>
        )}
      />
    </>
  );
}, isEqual);
