import React, { PureComponent } from "react";
import Measure from "react-measure";
import debounce from "debounce";
import Axis from "./Axis";
import FftAxis from "./FftAxis";
import FftVerticalAxis from "./FftVerticalAxis";
import Progress from "./Progress";
import Selection from "./Selection";
import ZoomSlider from "./ZoomSlider";
import WindowSlider from "./WindowSlider";
import LabelLine from "./LabelLine";
import Regions from "./Regions";
import * as utils from "./utils";
import * as draw from "./draw";
import { connect } from "react-redux";
import { dateToString } from "shared/helpers";
import getWorker from "shared/drawWorker";
import getIdent from "shared/getIdent";
import LabelSwitch from "./LabelSwitch";

const MOUSE_SELECTION_TAG = "mouse-selection-tag";

class Wave extends PureComponent {
  constructor() {
    super();
    this.worker = getWorker();
    this.ident = getIdent();
  }
  static defaultProps = {
    mouseSelection: MOUSE_SELECTION_TAG,

    buffer: null,
    offsets: [],
    subOffsets: [],
    startDatetimes: [],
    showStartDatetimes: false,
    selectionDates: [],
    bufferStart: 0,
    regions: [],
    selectedRegion: null,
    allLabels: [],

    windowStart: 0,
    windowEnd: 0,

    canvasHeight: 150,
    fftCanvasHeight: 240,
    axisHeight: 20,
    margin: 5,

    progress: 0,

    selectionStart: null,
    selectionEnd: null,

    onResize: null,
    onChangeWindow: null,
    onSelectRegion: null,
    onChangeLabel: null,
    onRemoveAllLabels: null,
    onCopyLink: null,
    onSearch: null,
    onDragRegion: null,
    onResizeRegion: null,

    duration: 180,

    modelResults: null,

    preciseRegions: {},
    onChangeProgress: null,
    onPlay: null,
  };

  __imageData = null;
  __frequenciesBufferl = null;

  state = {
    wrapperWidth: -1,
    hoverOffset: 0,
  };

  canvasRef = React.createRef();
  canvasSecondRef = React.createRef();
  canvasModelResultsRef = React.createRef();
  fftCanvasRef = React.createRef();
  eventRef = React.createRef();

  observer = null;

  render() {
    const {
      drawState,
      canvasHeight,
      fftCanvasHeight,
      axisHeight,
      bufferStart,
      windowStart,
      windowEnd,
      margin,
      progress,
      regions,
      selectedRegion,
      placement,
      labelPlacements,
      allLabels,
      onChangeLabel,
      onRemoveAllLabels,
      onCopyLink,
      onSearch,
      onSelectRegion,
      onDragRegion,
      onResizeRegion,
      buffer,
      offsets,
      subOffsets,
      startDatetimes,
      showStartDatetimes,
      renderFFT,
      aboveZoomOut,
      t,
      drawBothWaveAndSpectrogram,
      data,
      modelResults,
      selectionDates,
      preciseRegions,
      labelsFilterData,
      onChangeProgress,
      onPlay,
      updateRegion,
      rawRegions,
      setQuery,
      showLabelFilter,
      coef,
    } = this.props;

    let { wrapperWidth, hoverOffset } = this.state;
    let pxPerSec = this.getPxPerSec();
    let zoom = utils.getZoom(this.props);

    let { selectionStart, selectionEnd, selectionLabel } =
      this.calculateSelection();

    const marginLeft = 35;

    const hoverCoefficient = hoverOffset / (wrapperWidth * zoom);
    const length = offsets[offsets.length - 1];
    var hoverContent = "";

    for (var i = 0; i < subOffsets.length; ++i) {
      if (subOffsets[i + 1] / length > hoverCoefficient) {
        hoverContent = dateToString(
          new Date(
            startDatetimes[i].getTime() +
              ((hoverCoefficient * length - subOffsets[i]) /
                buffer.sampleRate) *
                1000
          )
        );
        const modelResult = modelResults
          ? modelResults[i]
              .map((i) => {
                const name = t(`modelResultType.${i.type}`);
                const score =
                  i.type === "anomaly_detector"
                    ? Math.round(i.score * 100) / 100
                    : Math.round(i.score * 1000) / 1000;
                return `${name}: ${score}`;
              })
              .join("\n")
          : null;
        if (modelResult) {
          hoverContent = `${hoverContent}\n${modelResult}\n${t(
            "analysis.sampleStart"
          )}: ${dateToString(startDatetimes[i])}`;
        } else {
          hoverContent = `${hoverContent}\n${t(
            "analysis.sampleStart"
          )}: ${dateToString(startDatetimes[i])}`;
        }
        break;
      }
    }
    if (!showStartDatetimes) {
      for (var i = 1; i < offsets.length; ++i) {
        if (offsets[i] / length >= hoverCoefficient) {
          hoverContent = `${hoverContent}\n${t(
            "analysis.forDate"
          )}: ${dateToString(selectionDates[i - 1])}`;
          break;
        }
      }
    }

    return (
      <Measure bounds onResize={this.onResize}>
        {({ measureRef }) => {
          return (
            <div className="wave-wrapper" style={{ position: "relative" }}>
              {this.state.loadingSpectogram && (
                <span
                  style={{ top: canvasHeight / 3 }}
                  className="loading-spectogram"
                >
                  {t("loadingSpectrogram")}
                </span>
              )}
              <div
                className="wave-canvas-wrapper"
                ref={measureRef}
                style={{
                  height:
                    (drawBothWaveAndSpectrogram
                      ? canvasHeight * 2
                      : canvasHeight) +
                    canvasHeight * Number(!!modelResults),
                  marginTop: margin,
                  marginLeft: margin,
                  marginRight: margin,
                }}
              >
                {wrapperWidth !== -1 ? (
                  <>
                    {drawBothWaveAndSpectrogram ? (
                      <React.Fragment>
                        {modelResults && (
                          <canvas
                            ref={this.canvasModelResultsRef}
                            style={{
                              position: "absolute",
                              top: 0,
                              left:
                                -(windowStart - bufferStart) * coef * pxPerSec,
                              height: canvasHeight,
                              width: wrapperWidth * zoom,
                            }}
                            width={wrapperWidth * zoom}
                            title={hoverContent}
                            onMouseMove={(e) => {
                              this.setState({
                                hoverOffset: `${e.nativeEvent.offsetX}`,
                              });
                            }}
                          />
                        )}
                        <canvas
                          ref={this.canvasSecondRef}
                          style={{
                            position: "absolute",
                            top: Number(!!modelResults) * canvasHeight,
                            left:
                              -(windowStart - bufferStart) * coef * pxPerSec,
                            height: canvasHeight,
                            width: wrapperWidth * zoom,
                          }}
                          width={wrapperWidth * zoom}
                          title={hoverContent}
                          onMouseMove={(e) => {
                            this.setState({
                              hoverOffset: `${e.nativeEvent.offsetX}`,
                            });
                          }}
                        />
                        <canvas
                          ref={this.canvasRef}
                          style={{
                            position: "absolute",
                            top: (Number(!!modelResults) + 1) * canvasHeight,
                            left:
                              -(windowStart - bufferStart) * coef * pxPerSec,
                            height: canvasHeight,
                            width: wrapperWidth * zoom,
                          }}
                          width={wrapperWidth * zoom}
                          title={hoverContent}
                          onMouseMove={(e) => {
                            this.setState({
                              hoverOffset: `${e.nativeEvent.offsetX}`,
                            });
                          }}
                        />
                        <Progress
                          width={(progress - windowStart) * coef * pxPerSec}
                          title={hoverContent}
                          onMouseMove={(e) => {
                            this.setState({
                              hoverOffset: `${e.nativeEvent.offsetX}`,
                            });
                          }}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <canvas
                          ref={this.canvasRef}
                          style={{
                            position: "absolute",
                            top: 0,
                            left:
                              -(windowStart - bufferStart) * coef * pxPerSec,
                            height: canvasHeight,
                            width: wrapperWidth * zoom,
                          }}
                          width={wrapperWidth * zoom}
                        />
                        <Progress width={(progress - windowStart) * pxPerSec} />
                      </React.Fragment>
                    )}
                  </>
                ) : (
                  []
                )}
              </div>
              {wrapperWidth !== -1 && (
                <Axis
                  width={wrapperWidth}
                  windowStart={windowStart}
                  windowEnd={windowEnd}
                  margin={margin}
                  hideTimeLabel={drawBothWaveAndSpectrogram}
                  coef={coef}
                />
              )}
              {wrapperWidth !== -1 && (
                <div
                  ref={this.eventRef}
                  id={`idLowerWaveCanvasWrapper${placement.value}`}
                  style={{
                    position: "absolute",
                    top: margin,
                    left: margin,
                    right: margin,
                    height: canvasHeight + axisHeight,
                    zIndex: 1000,
                    opacity: 0.7,
                    pointerEvents: drawBothWaveAndSpectrogram
                      ? "none"
                      : "initial",
                  }}
                >
                  <Regions
                    regionStyle={
                      drawState !== "spectrogram"
                        ? { background: "green", opacity: 0.4 }
                        : {
                            background: "white",
                            opacity: 0.5,
                            border: "solid 2px #333333",
                          }
                    }
                    selectedRegionStyle={
                      drawState !== "spectrogram"
                        ? { background: "green", opacity: 0.8 }
                        : {
                            background: "white",
                            opacity: 0.9,
                            border: "solid 2px #333333",
                          }
                    }
                    windowStart={windowStart}
                    regions={regions}
                    height={canvasHeight}
                    bottom={axisHeight}
                    pxPerSec={pxPerSec}
                    selectedRegion={selectedRegion}
                    onSelectRegion={onSelectRegion}
                    onRemoveAllLabels={onRemoveAllLabels}
                    onDragRegion={onDragRegion}
                    onResizeRegion={onResizeRegion}
                    coef={coef}
                  />
                  {selectionStart && selectionEnd && !aboveZoomOut && (
                    <Selection
                      start={selectionStart}
                      end={selectionEnd}
                      label={selectionLabel}
                      color={drawState !== "spectrogram" ? "green" : "white"}
                      textColor={
                        drawState !== "spectrogram" ? "white" : "#333333"
                      }
                    />
                  )}
                  {preciseRegions
                    ? Object.keys(preciseRegions).map((key) => (
                        <Selection
                          start={preciseRegions[key].start * pxPerSec}
                          end={preciseRegions[key].end * pxPerSec}
                          label=""
                          color="yellow"
                          textColor="black"
                        />
                      ))
                    : null}
                </div>
              )}
              <WindowSlider
                bufferStart={bufferStart}
                windowStart={windowStart}
                windowEnd={windowEnd}
                duration={this.getDuration()}
                margin={margin}
                t={t}
                onChangeWindow={this.props.onChangeWindow}
                coef={coef}
              />
              <ZoomSlider
                key={utils.roundToString(zoom, 2)}
                buffer={this.props.buffer}
                bufferStart={bufferStart}
                windowStart={windowStart}
                windowEnd={windowEnd}
                duration={this.getDuration()}
                margin={margin}
                t={t}
                onChangeWindow={this.props.onChangeWindow}
                coef={coef}
              />
              <div className="label-wrapper">
                {showLabelFilter && (
                  <LabelSwitch
                    labelsData={labelsFilterData}
                    allLabels={allLabels}
                    labels={selectedRegion?.labels || []}
                    onSelectRegion={onSelectRegion}
                    selectedRegion={selectedRegion}
                    onChangeProgress={onChangeProgress}
                    play={onPlay}
                    duration={this.getDuration()}
                    placement={placement}
                    windowStart={windowStart}
                    windowEnd={windowEnd}
                    regions={rawRegions}
                    updateRegion={updateRegion}
                    setQuery={setQuery}
                  />
                )}

                {selectedRegion && !renderFFT && (
                  <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <LabelLine
                      key={`${selectedRegion.start}-${selectedRegion.end}`}
                      margin={margin}
                      allLabels={allLabels}
                      labels={selectedRegion.labels}
                      placement={placement}
                      labelPlacements={labelPlacements}
                      onChangeLabel={onChangeLabel}
                      onRemoveAllLabels={onRemoveAllLabels}
                      onCopyLink={onCopyLink}
                      onSearch={onSearch}
                    />
                  </div>
                )}
              </div>
              {renderFFT && selectedRegion && (
                <div>
                  <FftVerticalAxis
                    height={fftCanvasHeight}
                    max={10}
                    margin={margin}
                    width={25}
                  />
                  <div>
                    <canvas
                      ref={this.fftCanvasRef}
                      style={{
                        height: fftCanvasHeight,
                        width: wrapperWidth - marginLeft - margin,
                        marginRight: margin,
                        marginLeft: marginLeft,
                      }}
                      height={fftCanvasHeight}
                      width={wrapperWidth - marginLeft - margin}
                    />
                    <FftAxis
                      width={wrapperWidth - marginLeft - margin}
                      max={buffer.sampleRate * 0.0005}
                      margin={margin}
                      marginLeft={marginLeft}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Measure>
    );
  }

  onResize = debounce(
    ({ bounds }) =>
      this.setState({ ...this.state, wrapperWidth: bounds.width }),
    400
  );

  calculateSelection() {
    let { selectionStart, selectionEnd } = this.props;
    let selectionLabel = null;
    if (selectionStart !== null && selectionEnd !== null) {
      let { windowStart: selectionWindowStart, windowEnd: selectionWindowEnd } =
        utils.calculateSelectionWindow(
          this.props,
          selectionStart,
          selectionEnd,
          this.getPxPerSec()
        );
      selectionLabel = `${utils.roundToString(
        Math.abs(selectionWindowEnd - selectionWindowStart),
        2
      )}s`;
    }

    return { selectionStart, selectionEnd, selectionLabel };
  }

  getDuration() {
    return this.props.duration; //default width of the window in seconds
  }

  getPxPerSec() {
    let { windowStart, windowEnd } = this.props;
    return this.state.wrapperWidth / (windowEnd - windowStart);
  }

  componentDidMount() {
    this.worker.addEventListener(
      "message",
      (e) => {
        if (this.canvasRef.current && e.data?.ident === this.ident) {
          const context = this.canvasRef.current.getContext("2d");
          context.putImageData(e.data.imageData, 0, 0);
          this.setState(() => ({
            loadingSpectogram: false,
          }));
          this.frequencies = e.data.frequencies;
          this.__frequenciesBuffer = this.props.buffer;
        }
      },
      false
    );

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
      });
      if (this.eventRef.current) {
        const bodyRect = document.body.getBoundingClientRect();
        const { left, width, height, top } =
          this.eventRef.current.getBoundingClientRect();
        this.props.onResize({
          left,
          width,
          height: this.props.canvasHeight,
          top: top - bodyRect.top,
        });
      }
    });

    resizeObserver.observe(document.body);
  }

  componentDidUpdate(prevProps, prevState) {
    // redraw if
    // - new buffer is submitted
    // - width is changed
    // - zoom is changed
    // - draw is changed

    if (
      prevState.wrapperWidth !== this.state.wrapperWidth ||
      prevProps.buffer !== this.props.buffer ||
      utils.getZoom(prevProps) !== utils.getZoom(this.props) ||
      prevProps.drawState !== this.props.drawState ||
      prevProps.modelResults !== this.props.modelResults
    ) {
      this.redraw();
    }

    if (
      (prevState.wrapperWidth !== this.state.wrapperWidth ||
        prevProps.selectedRegion !== this.props.selectedRegion ||
        prevProps.renderFFT !== this.props.renderFFT) &&
      this.props.renderFFT &&
      this.props.selectedRegion
    ) {
      this.redrawFft();
    }

    if (
      prevState.wrapperWidth !== this.state.wrapperWidth &&
      this.props.onResize &&
      this.eventRef.current instanceof HTMLElement
    ) {
      const bodyRect = document.body.getBoundingClientRect();
      const { left, width, height, top } =
        this.eventRef.current.getBoundingClientRect();
      this.props.onResize({
        left,
        width,
        height,
        top: top - bodyRect.top,
      });
    }
  }

  renderSpectogram(frequencies) {
    this.setState(() => ({
      loadingSpectogram: true,
    }));

    if (this.props.drawBothWaveAndSpectrogram) {
      setTimeout(() => {
        const channelOne = this.props.buffer.getChannelData(0);
        if (this.canvasSecondRef.current !== null) {
          this.worker.postMessage({
            channelOne,
            sampleRate: this.props.buffer.sampleRate,
            imageData: this.canvasSecondRef.current
              .getContext("2d")
              .getImageData(
                0,
                0,
                this.canvasSecondRef.current.width,
                this.canvasSecondRef.current.height
              ),
            duration: this.getDuration(),
            width: this.canvasSecondRef.current.width,
            height: this.canvasSecondRef.current.height,
            paletteRGB: draw.paletteRGB,
            cacheFrequencies: frequencies,
            offsets: this.props.offsets,
            ident: this.ident,
          });
        }
      }, 0);
    } else {
      setTimeout(() => {
        const channelOne = this.props.buffer.getChannelData(0);
        if (this.canvasRef.current !== null) {
          this.worker.postMessage({
            channelOne,
            sampleRate: this.props.buffer.sampleRate,
            imageData: this.canvasRef.current
              .getContext("2d")
              .getImageData(
                0,
                0,
                this.canvasRef.current.width,
                this.canvasRef.current.height
              ),
            duration: this.getDuration(),
            width: this.canvasRef.current.width,
            height: this.canvasRef.current.height,
            paletteRGB: draw.paletteRGB,
            cacheFrequencies: frequencies,
            ident: this.ident,
          });
        }
      }, 0);
    }
  }

  redraw() {
    draw.clear(this.canvasRef.current);
    draw.clear(this.canvasSecondRef.current);

    if (this.props.buffer === null || this.state.wrapperWidth === -1) {
      return;
    }

    if (this.props.modelResults) {
      draw.fill(this.canvasModelResultsRef.current, "black");
      const values = this.getValuesForModelResults();
      draw.drawModelResults(this.canvasModelResultsRef.current, values, 1);
    }

    if (this.props.drawBothWaveAndSpectrogram) {
      const { peaks } = this.getPeaks();
      draw.drawWave(this.canvasSecondRef.current, peaks, 2);
      if (this.frequencies) {
        if (this.props.buffer === this.__frequenciesBuffer) {
          return this.renderSpectogram(this.frequencies);
        }

        this.frequencies = null;
        this.__frequenciesBuffer = null;
      }
      this.renderSpectogram();
    } else {
      if (this.props.drawState !== "spectrogram") {
        const { peaks } = this.getPeaks();
        if (this.props.drawState === "wave") {
          draw.drawWave(this.canvasRef.current, peaks, 2);
        } else {
          draw.drawEnergy(this.canvasRef.current, peaks, 2);
        }
      } else {
        if (this.frequencies) {
          if (this.props.buffer === this.__frequenciesBuffer) {
            return this.renderSpectogram(this.frequencies);
          }
          this.frequencies = null;
          this.__frequenciesBuffer = null;
        }
        this.renderSpectogram();
      }
    }
  }

  redrawFft() {
    if (this.fftCanvasRef.current === null) return;
    draw.clear(this.fftCanvasRef.current);
    let fft = this.getFft();
    draw.drawFft(this.fftCanvasRef.current, fft, 255);
  }

  movingAverage(arr, windowSize) {
    const result = new Float32Array(arr.length);
    const halfWindow = Math.floor(windowSize / 2);

    let windowSum = 0;
    for (let i = 0; i < windowSize; ++i) {
      windowSum += Math.abs(arr[i]);
    }
    result[halfWindow] = windowSum / windowSize;

    for (let i = halfWindow + 1; i < arr.length - halfWindow; ++i) {
      windowSum +=
        Math.abs(arr[i + halfWindow]) - Math.abs(arr[i - halfWindow - 1]);
      result[i] = windowSum / windowSize;
    }

    for (let i = 0; i < halfWindow; ++i) {
      let startMean = 0;
      let endMean = 0;

      for (let j = 0; j <= i + halfWindow; ++j) {
        startMean += Math.abs(arr[j]);
      }
      result[i] = startMean / (i + halfWindow + 1);

      for (
        let j = arr.length - 1;
        j >= arr.length - (i + halfWindow + 1);
        --j
      ) {
        endMean += Math.abs(arr[j]);
      }
      result[arr.length - i - 1] = endMean / (i + halfWindow + 1);
    }

    return result;
  }

  getFft() {
    const { buffer, bufferStart, selectedRegion } = this.props;
    const sampleRate = buffer.sampleRate;
    const fft = draw.fft(sampleRate);
    const fftSamples = fft.bufferSize;
    const channelOne = buffer.getChannelData(0);
    let offset = Math.max(0, selectedRegion.start - bufferStart);
    let end = Math.max(0, selectedRegion.end - bufferStart);
    offset *= sampleRate;
    end *= sampleRate;
    const spectrums = [];
    while (offset < end) {
      const segment = channelOne.slice(offset, offset + fftSamples);
      if (segment.length !== fftSamples) break;
      offset += fftSamples;
      const spectrum = fft.calculateSpectrum(segment);
      spectrums.push(spectrum);
    }

    const array = new Uint8Array(fftSamples / 2);
    let j, k;
    const invLength = 1.0 / spectrums.length;
    for (j = 0; j < fftSamples / 2; j++) {
      let value = 0;
      for (k = 0; k < spectrums.length; k++) {
        value += spectrums[k][j];
      }
      value *= invLength;
      array[j] = Math.max(-255, Math.log10(value) * 45);
    }
    return array;
  }

  getPeaks() {
    let { buffer } = this.props;
    let width = this.getPxPerSec() * this.props.buffer.duration;

    const sampleSize = buffer.length / width;
    //const sampleStep = ~~(sampleSize / 10) || 1;
    const sampleStep = 1;
    const channels = buffer.numberOfChannels;
    let peaks = [];
    let maxPeak = 0;
    let minPeak = 0;

    for (let c = 0; c < channels; c++) {
      let channelData = buffer.getChannelData(c);

      if (this.props.drawState === "energy") {
        channelData = this.movingAverage(channelData, 3000);
      }

      for (let i = 0; i < width; i++) {
        const start = ~~(i * sampleSize);
        const end = ~~(start + sampleSize);
        let min = 0;
        let max = 0;

        for (let j = start; j < end; j += sampleStep) {
          const value = channelData[j];

          if (value > max) {
            max = value;
          }

          if (value < min) {
            min = value;
          }
        }

        if (c === 0 || max > peaks[2 * i]) {
          peaks[2 * i] = max;
        }

        if (c === 0 || min < peaks[2 * i + 1]) {
          peaks[2 * i + 1] = min;
        }

        maxPeak = Math.max(maxPeak, max);
        minPeak = Math.min(minPeak, min - 0.1);
      }
    }
    return { peaks, maxPeak, minPeak };
  }

  getValuesForModelResults() {
    const { buffer, offsets, subOffsets, modelResults } = this.props;
    const width = this.getPxPerSec() * buffer.duration;
    const length = offsets[offsets.length - 1];
    const coefficient = width / length;
    return subOffsets.slice(0, subOffsets.length - 1).map((i, index) => ({
      anomaly:
        modelResults[index]?.filter((i) => i.type === "anomaly_detector")[0]
          ?.score || null,
      loudness:
        modelResults[index]?.filter((i) => i.type === "loudness")[0]?.score ||
        null,
      rmsVelocity:
        modelResults[index]?.filter((i) => i.type === "rms_velocity")[0]
          ?.score || null,
      envelopeEnergy:
        modelResults[index]?.filter((i) => i.type === "envelope_energy")[0]
          ?.score || null,
      length: (subOffsets[index + 1] - i) * coefficient,
    }));
  }
}

const mapStateToProps = (state) => {
  return {
    drawState: state.machineDetail.sounds.draw,
  };
};

export default connect(mapStateToProps)(Wave);
