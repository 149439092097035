import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import useData from "dataHooks/eventSounds/datasets";
import Radio from "components/radio";
import SearchMenu from "components/searchMenu";
import { TextField, Typography, RadioGroup, Box } from "@mui/material";
import { useStyles } from "./styles";
import actions from "store/eventSounds/actions";
import { useDispatch } from "react-redux";
import { IDatasetUpdate } from "types/eventSounds";

const { setNewName, setExistingName, setReplacingExistingName } = actions;

const SelectionName = () => {
  const { t } = useTranslation();
  const { isFetching, datasets } = useData();
  const [existingNameValue, setExistingNameValue] = useState<any | null>(null);
  const [newNameValue, setNewNameValue] = useState<string | null>(null);
  const classes = useStyles();
  const dispatch: any = useDispatch();

  const radioOptions = useMemo(
    () => [
      {
        value: "newName",
        label: t("form.datataset.selectionName.radio.newName"),
      },
      {
        value: "existingName",
        label: t("form.datataset.selectionName.radio.existingName"),
      },
    ],
    [t]
  );

  const [activeRadioOption, setActiveRadioOption] = React.useState(
    radioOptions[0].value
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveRadioOption((event.target as HTMLInputElement).value);
  };
  const existingNamesOptions = useMemo(
    () =>
      datasets && datasets?.results.length
        ? datasets!.results.map(
            ({ name, id }: { name: string; id: number }) => ({
              text: name,
              value: id,
            })
          )
        : [],
    [datasets]
  );

  useEffect(() => {
    if (activeRadioOption === radioOptions[1].value) {
      const newVal =
        datasets &&
        datasets?.results.length &&
        datasets!.results!.find(
          (item: IDatasetUpdate) =>
            Number(item.id) === Number(existingNameValue)
        );
      dispatch(setExistingName(newVal));
      dispatch(setNewName(null));
      dispatch(setReplacingExistingName(null));
    } else if (activeRadioOption === radioOptions[0].value) {
      dispatch(setExistingName(null));
      dispatch(setReplacingExistingName(null));
      dispatch(setNewName(newNameValue));
    }
  }, [
    dispatch,
    activeRadioOption,
    existingNameValue,
    newNameValue,
    datasets,
    radioOptions,
  ]);

  return (
    <>
      <RadioGroup value={activeRadioOption} onChange={handleRadioChange}>
        <Radio
          value={radioOptions[0].value}
          activeValue={activeRadioOption}
          label={radioOptions[0].label}
        />
        <Box ml={4} mt={0.5}>
          <Box mb={4} mt={1}>
            <Field
              name="newName"
              id="newName"
              label={t("dataExplorer.dataset.modal.newName.label")}
              render={({ id, label, input, meta }) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={10}
                  sx={{
                    "& .MuiInputBase-input": {
                      paddingTop: "10.5px",
                      paddingBottom: "10.5px",
                    },
                  }}
                >
                  <Typography style={{ minWidth: "8rem" }}>
                    {t("savedDataset.modal.datasetName.placeholder")}
                  </Typography>
                  <TextField
                    label={label}
                    value={newNameValue}
                    id={id}
                    onChange={({ target }) => setNewNameValue(target.value)}
                    className={classes.inputWrapper}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{ paddingRight: 10 }}
                    required
                    disabled={activeRadioOption !== radioOptions[0].value}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
        <Radio
          value={radioOptions[1].value}
          activeValue={activeRadioOption}
          label={radioOptions[1].label}
          id="testCreateNewName"
        />
        <Box mb={3.5} mt={1.5} ml={4}>
          <Field
            name="existingName"
            id="existingName"
            label={t("eventSounds.dataset.existingName")}
            render={({ id, label, meta }) => (
              <SearchMenu
                id={id}
                label={label}
                value={existingNameValue}
                onChange={({ target }) => setExistingNameValue(target.value)}
                options={existingNamesOptions ?? []}
                disabled={radioOptions[1].value !== activeRadioOption}
                loading={isFetching}
                required
              />
            )}
          />
        </Box>
      </RadioGroup>
    </>
  );
};

export default SelectionName;
