import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface ICategoryListResponse {
  id: number;
  company: string | null;
  name: string;
  description: string | null;
  installation_manual: string | null;
}

export default async function getMachineCategories() {
  const url = endpoints.machines.categories;

  try {
    const response = await axiosNeuron.get(`${url}?ps=9999`);
    return {
      ...response.data,
      results: response.data.results.map((result: ICategoryListResponse) => ({
        id: result.id,
        company: result.company,
        name: result.name,
        description: result.description,
        installationManual: result.installation_manual,
      })),
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
