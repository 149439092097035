import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useMatch } from "react-router-dom";
import Tooltip from "components/tooltip";
import url from "shared/appUrls";
import NavLink from "components/queryNavigator/link";

interface Props extends ListItemProps {
  text: string;
  icon: React.ReactElement;
  to?: string;
  exact?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  path?: string;
  opened?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      color: theme.custom.palette.label,
      letterSpacing: "0.021875rem",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "transparent",
      cursor: "pointer",
      borderRadius: ({ opened }: Props) => (opened ? theme.spacing(10) : "50%"),
      display: "flex",
      width: ({ opened }: Props) => (opened ? "auto" : theme.spacing(6)),
      height: ({ opened }: Props) => (opened ? "auto" : theme.spacing(6)),
      justifyContent: "center",
      padding: ({ opened }: Props) => (opened ? theme.spacing(0.95, 2) : 0),
      "&:focus": {
        background: theme.custom.palette.surface,
        color: theme.custom.palette.data,
        borderWidth: 1,
        borderColor: theme.custom.palette.active.regular,
        outline: 0,
        textDecoration: "none",
      },
      "&:hover, &:active": {
        color: theme.custom.palette.data,
        borderWidth: 1,
        background: "transparent",
        borderColor: "transparent",
        boxShadow: theme.custom.boxShadow[0],
        textDecoration: "none",
      },
      "&.active": {
        color: theme.custom.palette.data,
        borderWidth: 1,
        borderColor: theme.custom.palette.data,
        boxShadow: "none",
      },
      "&[disabled]": {
        color: theme.custom.palette.placeholder,
        cursor: "default",
        opacity: 1,
        boxShadow: "none",
        padding: theme.spacing(0.95, 2),
        borderWidth: 2,
        borderColor: "transparent",
      },
    },
    icon: {
      color: "inherit",
      minWidth: "auto",
    },
    text: {
      display: ({ opened }: Props) => (opened ? "block" : "none"),
      marginLeft: ({ opened }: Props) => (opened ? theme.spacing(2) : 0),
      "& .MuiTypography-root": {
        fontFamily: theme.custom.typography.fontFamily,
        fontSize: theme.custom.typography.fontSize["14"],
        fontWeight: theme.custom.typography.fontWeight.bold,
      },
    },
  };
});

const MenuItem = ({ opened, ...props }: Props) => {
  const classes = useStyles({ opened, ...props });

  const { icon, text, to, exact, ...rest } = props;

  const isProject = useMatch(url.projects.detailPattern());
  const isFacility = useMatch(url.facilities.detailPattern());

  const hideTooltips = () =>
    Array.from(
      document.getElementsByClassName(
        "MuiTooltip-tooltip"
      ) as HTMLCollectionOf<HTMLElement>
    ).forEach((el) => {
      el.style.display = "none";
    });

  const linkAttributes = useMemo(() => {
    if (to) {
      return {
        component: NavLink,
        isActive: (match: boolean) => {
          if (!match && (isProject || isFacility) && to === "/companies/") {
            return true;
          }
          return match;
        },
        to,
        exact,
        path: props.path,
      };
    }
    return {};
  }, [exact, isFacility, isProject, props.path, to]);

  return (
    <div onClick={hideTooltips}>
      {!opened ? (
        <Tooltip title={text} placement="right">
          <ListItem
            className={classes.root}
            {...rest}
            {...linkAttributes}
            button={false}
          >
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText className={classes.text} primary={text} />
          </ListItem>
        </Tooltip>
      ) : (
        <ListItem
          className={classes.root}
          {...rest}
          {...linkAttributes}
          button={false}
        >
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText className={classes.text} primary={text} />
        </ListItem>
      )}
    </div>
  );
};

export default MenuItem;
