import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function removeMachineImage(imageId: number) {
  const url = endpoints.machineImages.detail(imageId + 1);
  try {
    const response = await axiosNeuron.delete(url);
    return response || { message: "Machine image successfully deleted" };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
