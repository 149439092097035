import React from "react";
import { makeStyles } from "@mui/styles";
import { FieldRenderProps } from "react-final-form";
import TextField from "components/textField";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "components/typography/heading/divider";
import Tag from "components/tag";
import Box from "@mui/material/Box";
import Button from "components/button";
import Tooltip from "@mui/material/Tooltip";

interface SelectComponentOption {
  value: any;
  key?: string;
  text?: string | React.ReactNode;
  color?: string | null;
  group?: string;
  tooltip?: string;
}

interface Props extends FieldRenderProps<any> {
  value?: any | any[];
  defaultValue?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  options: (string | SelectComponentOption)[];
  label: string;
  multiple?: boolean;
  helperText?: string;
  displayTags?: boolean;
  disabledValues?: string[];
  loadMore?: () => void;
  loadingMore?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    renderWrap: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: theme.spacing(-1.25),
    },
    groupHeader: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    tooltip: {
      fontSize: "1em",
    },
  };
});

const SelectComponent = (props: Props) => {
  const classes = useStyles(props);
  const {
    value,
    onChange,
    id,
    name,
    options,
    label,
    defaultValue,
    multiple,
    helperText,
    displayTags,
    disabledValues,
    loadMore,
    loadingMore,
    ...rest
  } = props;

  const convertedOptions = options.map(
    (option: string | SelectComponentOption, index) => {
      if (typeof option === "string") {
        return {
          key: option + index,
          value: option,
          text: option,
          color: null,
        } as SelectComponentOption;
      } else {
        return {
          key: option?.key ? option?.key : (option?.value as string),
          value: option?.value,
          text: option?.text ? option?.text : (option?.value as string),
          color: option?.color,
          group: option?.group,
          tooltip: option?.tooltip,
        };
      }
    }
  );

  const optionMap: Map<any, SelectComponentOption> = new Map(
    convertedOptions.map((option: SelectComponentOption) => [
      option?.value,
      option,
    ])
  );

  const renderTag = (selected: any) => {
    if (Array.isArray(selected)) {
      return (
        <div className={classes.renderWrap}>
          {(selected as any[])
            .map((value: any) => optionMap.get(value) as SelectComponentOption)
            .map((option: SelectComponentOption) => (
              <Box mr={1} mb={1} key={option?.key}>
                <Tag key={option?.key} color={option?.color || false}>
                  {option?.text as string}
                </Tag>
              </Box>
            ))}
        </div>
      );
    } else {
      const option: SelectComponentOption = optionMap.get(
        selected
      ) as SelectComponentOption;
      return (
        <div className={classes.renderWrap}>
          <Box mr={1} mb={1}>
            <Tag color={option.color || false}>{option?.text as string}</Tag>
          </Box>
        </div>
      );
    }
  };
  return (
    <TextField
      select
      // defaultValue={defaultValue}
      inputProps={{
        name,
        id,
      }}
      open={true}
      label={label}
      SelectProps={{
        renderValue: displayTags
          ? (selected: any) => renderTag(selected)
          : undefined,
        multiple,
        displayEmpty: rest.displayEmpty,
      }}
      InputLabelProps={{
        shrink: rest.displayEmpty,
      }}
      onChange={(event: any) => {
        onChange && onChange(event);
        props.input.onChange(event);
      }}
      {...rest}
    >
      {convertedOptions.map((option, i) => {
        return option.group ? (
          <ListSubheader key={option?.group}>
            <div className={classes.groupHeader}>
              <Divider line noMargin key={option?.group}>
                {option?.group}
              </Divider>
            </div>
          </ListSubheader>
        ) : (
          <MenuItem
            key={id + option?.key + i}
            value={option?.value}
            disabled={
              disabledValues ? disabledValues.includes(option?.value) : false
            }
          >
            {option.tooltip ? (
              <Tooltip
                title={option.tooltip}
                classes={{ tooltip: classes.tooltip }}
              >
                <span>{option?.text}</span>
              </Tooltip>
            ) : (
              option?.text
            )}
          </MenuItem>
        );
      })}
      {loadMore && (
        <Button
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            e.persist();
          }}
        >
          {"loadMore"}
        </Button>
      )}
    </TextField>
  );
};

export default SelectComponent;
