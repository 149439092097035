import { memo, useEffect, useCallback } from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";
import { useTheme } from "@mui/material/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import actions from "store/layout/actions";
import { AppState } from "store";

const Navigation = memo(() => {
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const drawer = useSelector(
    (state: AppState) => state.layout.drawer,
    shallowEqual
  );
  const resize = useCallback(() => {
    if (theme.breakpoints.values.sm < window.innerWidth && drawer) {
      dispatch(actions.toggleDrawer(false));
    }
  }, [theme.breakpoints, dispatch, drawer]);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  return (
    <>
      <Desktop />
      <Mobile />
    </>
  );
});

export default Navigation;
