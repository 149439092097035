import React from "react";
import EPermissions from "shared/permissions";
import usePermissions from "shared/hooks/usePermissions";

const AuthorizedBlock: React.FC<{
  authorizedTo: EPermissions[] | EPermissions;
  children?: React.ReactNode;
}> = ({ authorizedTo, children }) => {
  const authorized = usePermissions(authorizedTo);
  return authorized ? <>{children}</> : null;
};

export default AuthorizedBlock;
