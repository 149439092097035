import React from "react";
import { makeStyles } from "@mui/styles";
import PageButton from "components/table/buttons/pageButton";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type Props = {
  pageCountFrom: number;
  pageCountTo: number;
  pageCount: number;
  rowCount: number;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
  showMore: () => void;
  gotoPage: (index: number) => void;
  infinitePaging?: boolean;
  footerSpacing?: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      padding: theme.spacing(3, 0),
    },
    fontSize: theme.custom.typography.fontSize[14],
    fontFamily: theme.custom.typography.fontFamily,
    color: theme.custom.palette.label,
  },
  pager: {
    display: "flex",
    alignItems: "center",
  },
  pagerWrap: {
    margin: theme.spacing(0, 1),
  },
  footerSpacing: {
    marginBottom: "3.5rem",
  },
}));

const Footer = ({
  pageCountFrom,
  pageCountTo,
  pageCount,
  rowCount,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  showMore,
  gotoPage,
  infinitePaging,
  footerSpacing,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const breakpointSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const from = pageCountTo > 0 ? pageCountFrom + 1 : 0;
  const to = pageCountTo >= rowCount ? rowCount : pageCountTo;

  return (
    <div
      className={clsx(
        `${classes.footer} ${footerSpacing ? classes.footerSpacing : ""}`
      )}
    >
      {breakpointSmUp && (
        <div>
          {infinitePaging
            ? `${from} - ${to}`
            : t("table.pageFromTo", {
                from,
                to,
                rows: rowCount,
              })}
        </div>
      )}
      {canNextPage && (
        <Button onClick={showMore} disabled={pageCount === 1} color="secondary">
          {t("table.showMore")}
        </Button>
      )}
      {breakpointSmUp && (
        <div className={classes.pager}>
          <span>{t("table.page")}</span>
          <div className={classes.pagerWrap}>
            <PageButton
              page={pageIndex + 1}
              onPrevious={previousPage}
              onNext={nextPage}
              onGotoPage={gotoPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
            />
          </div>
          {pageCount < 1000000 ? (
            <span>{t("table.pageFrom", { num: pageCount })}</span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Footer;
