import React from "react";
import { CellProps } from "react-table";
import RowSpacer from "components/table/cellsShared/rowSpacer";
import Switch from "components/switch";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { updateMachineFacility } from "store/machineDetail/actions";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";

function NotifyCell<T extends object>(props: CellProps<T>) {
  const {
    cell: {
      value: { id },
    },
  } = props;
  const { machine, facility } = useSelector(
    (state: AppState) => state.machineDetail.machine
  );
  const disableEdit = !usePermissions([
    EPermissions.UPDATE_ANY_FACILITY,
    EPermissions.UPDATE_OWN_FACILITY,
  ]);
  const dispatch: any = useDispatch();
  const handleChange = () => {
    dispatch(
      updateMachineFacility(
        machine.id,
        facility.id,
        facility.users_to_notify.indexOf(id) === -1
          ? facility.users_to_notify.concat([id])
          : facility.users_to_notify.filter((item: number) => item !== id)
      )
    );
  };
  return (
    <span>
      <Switch
        name="notifySwitch"
        disabled={disableEdit}
        checked={facility.users_to_notify.indexOf(id) !== -1}
        onChange={handleChange}
        onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
          e.stopPropagation();
        }}
        labelOn=""
        labelOff=""
      />
      <RowSpacer />
    </span>
  );
}

export default NotifyCell;
