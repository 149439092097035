import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export enum EPasswordStrength {
  weak = "weak",
  medium = "medium",
  strong = "strong",
}

interface IPasswordStrength {
  value: string;
}

interface StylesProps {
  strength: EPasswordStrength | undefined;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      textTransform: "capitalize",
      textAlign: "right",
      fontSize: theme.typography.pxToRem(12),
      color: ({ strength }: StylesProps) => {
        if (strength === EPasswordStrength.strong) {
          return theme.custom.palette.success.regular;
        } else if (strength === EPasswordStrength.medium) {
          return theme.custom.palette.alert.regular;
        } else {
          return theme.custom.palette.error.regular;
        }
      },
    },
    outer: {
      display: "flex",
      flexDirection: "row",
      margin: `0 -${theme.spacing(0.25)}`,
      paddingBottom: theme.spacing(0.5),
    },
    item: {
      background: theme.custom.palette.shade,
      flex: 1,
      margin: `0 ${theme.spacing(0.25)}`,
      borderRadius: theme.typography.pxToRem(2),
      height: theme.spacing(0.5),
      "&:nth-child(1)": {
        background: ({ strength }: StylesProps) => {
          if (strength === EPasswordStrength.strong) {
            return theme.custom.palette.success.regular;
          } else if (strength === EPasswordStrength.medium) {
            return theme.custom.palette.warning.regular;
          } else if (strength === EPasswordStrength.weak) {
            return theme.custom.palette.error.regular;
          } else {
            return "";
          }
        },
      },
      "&:nth-child(2)": {
        background: ({ strength }: StylesProps) => {
          if (strength === EPasswordStrength.strong) {
            return theme.custom.palette.success.regular;
          } else if (strength === EPasswordStrength.medium) {
            return theme.custom.palette.warning.regular;
          } else {
            return "";
          }
        },
      },
      "&:nth-child(3)": {
        background: ({ strength }: StylesProps) => {
          if (strength === EPasswordStrength.strong) {
            return theme.custom.palette.success.regular;
          } else {
            return "";
          }
        },
      },
    },
  };
});

const mediumPasswordRegExp = new RegExp(
  "^(?=.*[0-9])(?=.*[!@#$%^&*()\\[\\]{}<>=`_\\-.:;?~+-/\\\\|\"'])"
);
const notNumberRegExp = new RegExp("[^0-9]");

const getPasswordStrength = (value: string) => {
  if (notNumberRegExp.test(value) && value.length > 7) {
    if (mediumPasswordRegExp.test(value)) {
      if (value.length > 12) {
        return EPasswordStrength.strong;
      } else {
        return EPasswordStrength.medium;
      }
    } else {
      return EPasswordStrength.weak;
    }
  }
  return undefined;
};

const PasswordStrength = ({ value }: IPasswordStrength) => {
  const { t } = useTranslation();
  const strength = getPasswordStrength(value);
  const classes = useStyles({ strength });
  return (
    <div className={classes.root}>
      <div className={classes.outer}>
        <div className={classes.item} />
        <div className={classes.item} />
        <div className={classes.item} />
      </div>
      <Box mx={1.5}>
        {strength ? t(`passwordStrength.${strength}`) : <span>&nbsp;</span>}
      </Box>
    </div>
  );
};

export default PasswordStrength;
