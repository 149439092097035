import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TPlacementImageParams = {
  placement?: number[];
  page?: number;
  ps?: number;
  id?: number[];
};

export default async function getPlacementImages(
  params: TPlacementImageParams
) {
  const url = endpoints.placements.images;
  try {
    const { data } = await axiosNeuron.get(url, { params });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
