let ctxs: { [key: number]: AudioContext } = {};
export default function getAudioContext(sampleRate?: number): AudioContext {
  const sr = sampleRate || 44100;
  let ctx = ctxs[sr];
  if (ctx) {
    return ctx;
  }
  // @ts-expect-error: expect a different approach
  ctx = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: sr,
  });
  ctxs[sr] = ctx;
  return ctx;
}
