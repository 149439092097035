import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import convertEventFromAPI from "./convertEventFromAPI";
import fillEventUsers from "./fillEventUsers";

export default async function setAsMaster({
  masterAlertId,
  alertIds,
  reset,
}: {
  masterAlertId: number;
  alertIds?: number[];
  reset?: boolean;
}) {
  const url = endpoints.events.groupAlerts(masterAlertId);
  const params = {
    alerts: alertIds,
    reset,
  };
  try {
    const { data } = await axiosNeuron.post(url, params);
    const usersIds: number[] = [];
    const groupedAlerts = data.grouped_alerts.map((i: any) =>
      convertEventFromAPI(i, usersIds)
    );
    await fillEventUsers(groupedAlerts, usersIds);
    return { groupedAlerts };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
