import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import { createColumns } from "components/projects/detail/modal/components/usersList/column";
import { createTinyColumns } from "components/projects/detail/modal/components/usersList/tinyColumn";

import { IUserList } from "types/user";
import { useDispatch } from "react-redux";
import Spinner from "components/spinner";
import useData from "dataHooks/projects/addUser";
import actions from "store/addUserModal/actions";
import { makeStyles } from "@mui/styles";
import isEqual from "react-fast-compare";

const useStyles = makeStyles((theme: any) => ({
  content: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(1, 4),
    fontFamily: theme.custom.typography.fontFamily,
    display: "flex",
    flexDirection: "column",
  },
}));

const { setSelected } = actions;

export default memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch: any = useDispatch();
  const classes = useStyles();

  const {
    loading,
    selected,
    reloading,
    data,
    showMoreClickedTimesRef,
    initialState,
    onPageChange,
    onPageSort,
  } = useData();

  const tinyColumnsMemoized = useMemo(() => createTinyColumns(t), [t]);
  const columnsMemoized = useMemo(() => createColumns(t), [t]);
  const columns = upSm ? columnsMemoized : tinyColumnsMemoized;

  return (
    <div className={classes.content} id="testProjectUserList">
      {reloading ? (
        <Spinner />
      ) : (
        <Table<IUserList>
          showMoreClickedTimesRef={showMoreClickedTimesRef}
          isLoading={loading}
          data={data?.results || []}
          rowsCount={data ? data.count : 0}
          columns={columns}
          initialState={initialState}
          onPageChange={onPageChange}
          onPageSort={onPageSort}
          RowComponent={Row}
          HeaderComponent={Header}
          RowComponentProps={{
            onClick: (id?: number) => dispatch(setSelected([id])),
            selected,
          }}
        />
      )}
    </div>
  );
}, isEqual);
