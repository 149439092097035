export enum EActions {
  setModal = "setModal",
}

export interface IModalsState {
  content?: React.ReactNode;
}

export interface IUsersAction {
  type: typeof EActions;
  payload: IModalsState;
}

export type TReducers = {
  [key: string]: (p: IUsersAction, s: IModalsState) => void;
};
