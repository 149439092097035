import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type Props = {
  color: string | boolean;
  children: string;
  ellipsis?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      backgroundColor: (props: Props) =>
        props.color === "primary"
          ? theme.custom.palette.primary["16"]
          : theme.custom.palette.shade,
      color: (props: Props) =>
        props.color === "primary"
          ? theme.custom.palette.primary["100"]
          : theme.custom.palette.label,
      borderColor: (props: Props) =>
        props.color === "primary"
          ? theme.custom.palette.primary["48"]
          : theme.custom.palette.label,
      fontFamily: theme.custom.typography.fontFamily,
      fontSize: theme.custom.typography.fontSize["11"],
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: "2px",
      fontWeight: "bold",
      maxWidth: "max-content",
      padding: ".1875rem .5rem .125rem .5rem",
      textTransform: "uppercase",
      letterSpacing: ".04rem",
      lineHeight: 1.2,
    },
    ellipsis: {
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  };
});

const Tag = (props: Props) => {
  const classes = useStyles(props);
  const { ellipsis, className } = props;
  return (
    <div
      className={clsx(classes.root, ellipsis && classes.ellipsis, className)}
    >
      {props.children}
    </div>
  );
};

export default Tag;
