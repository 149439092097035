export const LABELS_PROCESSING = "LABEL_PROCESSING";

export interface LabelState {
  processing: boolean;
}

export interface LabelProcessingAction {
  type: typeof LABELS_PROCESSING;
  payload: {
    processing: boolean;
  };
}

export type PlayerActionTypes = LabelProcessingAction;
