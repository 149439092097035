import { EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState = {
  device: undefined,
  loading: false,
  error: undefined,
  telemetric: [],
  isFetching: false,
  telemetricError: null,
};

const reducers: TReducers = {
  [EActions.setDevice]: ({ payload: { device } }) => ({
    device,
    loading: false,
  }),
  [EActions.setLoading]: ({ payload: { loading } }) => ({
    loading,
  }),
  [EActions.setError]: ({ payload: { error } }) => ({
    error,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
  [EActions.fetchTelemetricRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchTelemetricSuccess]: ({ payload: { telemetric } }) => ({
    telemetric,
    isFetching: false,
  }),
  [EActions.fetchTelemetricFail]: ({ payload: { telemetricError } }) => ({
    telemetricError,
    isFetching: false,
  }),
};

export default createReducer(path, initialState, reducers);
