import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function removeEvent({ id }: { id: number }) {
  const url = endpoints.events.pairDetail(id);

  try {
    const response = await axiosNeuron.delete(url);
    return response || { message: "Event successfully deleted" };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
