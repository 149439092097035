import { useEffect } from "react";
import { createSelector } from "reselect";
import { AppState } from "store";

import { auth } from "store/auth/actions";
import { loadLanguage } from "store/langs/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { checkLanguage } from "shared/language";

const selector = createSelector(
  (state: AppState) => state.auth,
  (state: AppState) => state.langs,
  (auth, langs) => {
    return {
      loading: auth.loading || langs.loading,
    };
  }
);

const usePreload = () => {
  const { loading } = useSelector(selector, shallowEqual);
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(loadLanguage());
    checkLanguage();
    dispatch(auth());
  }, [dispatch]);
  return {
    loading: loading,
  };
};
export default usePreload;
