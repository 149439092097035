import { EActions, IProjectState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IProjectState = {
  project: null,
  loading: false,
};

const reducers = {
  [EActions.setProject]: ({ payload: { project } }: IActionTypes) => ({
    project,
  }),

  [EActions.setLoading]: ({ payload: { loading } }: IActionTypes) => ({
    loading,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};
export default createReducer<IProjectState>(path, initialState, reducers);
