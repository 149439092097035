import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import MaterialButton, { ButtonProps } from "@mui/material/Button";

interface Props extends ButtonProps {
  dataTestId?: string;
  customcolor?: string;
  padding?: string;
  width?: string | number;
  className?: string;
}

interface IMuiTheme extends Theme {
  custom: any;
}

const useStyles = makeStyles((theme: IMuiTheme) => {
  return {
    root: {
      position: "relative",
      zIndex: 1,
      display: "inline-flex",
      textTransform: "initial",
    },
    button: {
      textTransform: "initial",
      color: (props: Props) => props.customcolor,
      background: "transparent",
      padding: 0,
      fontWeight: (props: Props) =>
        props.color === "primary"
          ? theme.custom?.typography.fontWeight.bold
          : theme.custom?.typography.fontWeight.medium,
      fontSize: theme.custom?.typography.fontSize["14"],
      letterSpacing: "0.05rem",
      borderWidth: 0,
      borderStyle: "solid",
      borderColor: "transparent",
      borderRadius: theme.spacing(0.5),
      position: "relative",
      boxSizing: "border-box",
      backgroundClip: "padding-box",
      lineHeight: "normal",
      transitionProperty: "box-shadow",
      "&:hover": {
        color: (props: Props) => {
          if (props.customcolor) return props.customcolor;
          else return theme.custom?.palette.primary["100"];
        },
        background: "transparent",
      },
      "&:disabled": {
        background: "transparent",
        boxShadow: theme.custom?.boxShadow["0"],
        color: (props: Props) => theme.custom?.palette.placeholder,
      },
      "& .MuiButton-startIcon": {
        margin: theme.spacing(-0.625, 1.25, -0.625, -0.625),
      },
      "& .MuiButton-label": {
        textAlign: "left",
        [theme.breakpoints.up("sm")]: {
          whiteSpace: "nowrap",
        },
      },
      "& .MuiButton-label > svg": {
        margin: theme.spacing(-0.4375, -1.2),
      },
      "& .MuiButton-startIcon > *:first-child": {
        fontSize: theme.custom?.typography.fontSize["24"],
      },
    },
    buttonRipple: {
      backgroundColor: theme.custom?.palette.surface,
    },
  };
});

const Button = (props: Props) => {
  const { dataTestId, customcolor: customColor, className, ...rest } = props;
  const classes = useStyles({ customcolor: customColor, ...props });
  return (
    <div className={classes.root}>
      <MaterialButton
        id={dataTestId}
        className={`${className} ${classes.button}`}
        centerRipple={true}
        focusRipple={true}
        disableRipple={false}
        TouchRippleProps={{
          classes: {
            child: classes.buttonRipple,
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default Button;
