import React from "react";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "components/button";

interface Props {
  children: React.ReactNode;
  Icon?: React.ReactNode;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick?: () => void;
  id?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      float: "right",
      display: "inline-flex",
      margin: theme.spacing(1.5, 2),
      [theme.breakpoints.up("sm")]: {
        margin: 0,
      },
    },
  };
});

const CallToAction = ({
  color,
  children,
  Icon,
  onClick,
  id,
  disabled,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div className={classes.root}>
      {breakpointSmUp ? (
        <Button
          color={color || "primary"}
          onClick={onClick}
          startIcon={Icon || <AddIcon />}
          size="large"
          id={id}
          disabled={disabled}
        >
          {children}
        </Button>
      ) : (
        <Button
          disabled={disabled}
          color={color || "primary"}
          onClick={onClick}
          style={{ padding: "3px" }}
        >
          {Icon || <AddIcon />}
        </Button>
      )}
    </div>
  );
};

export default CallToAction;
