import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "flex-start",
    },
    title: {
      color: theme.custom.palette.label,
      marginRight: theme.spacing(1),
      fontSize: theme.custom.typography.fontSize[11],
      fontWeight: theme.custom.typography.fontWeight["medium"],
      letterSpacing: theme.custom.typography.letterSpacing["medium"],
    },
    horizontal: {
      background: theme.custom.palette.shade,
      height: 1,
      width: "100%",
      border: "none",
    },
  };
});

type Props = {
  title?: string;
};

const SectionHeader = (props: Props) => {
  const title = props.title?.toUpperCase();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}>{title}</span>
      <hr className={classes.horizontal} />
    </div>
  );
};
export default SectionHeader;
