import React from "react";
import { makeStyles } from "@mui/styles";
import MUIAvatar from "@mui/material/Avatar";
import { nameToInitials } from "shared/helpers";

const useStyles = makeStyles((theme: any) => {
  return {
    avatar: {
      color: theme.custom.palette.label,
      background: theme.custom.palette.shade,
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: theme.custom.typography.fontSize[12],
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      margin: theme.spacing(0, 2),
      marginTop: (props: Props) => theme.spacing(props.marginTop || 0),
    },
  };
});

export interface Props {
  marginTop?: number;
  cell: {
    value: {
      name: string;
      image: string;
    };
  };
}

const Avatar = (props: Props) => {
  const {
    cell: {
      value: { name, image },
    },
  } = props;
  const classes = useStyles(props);
  return (
    <MUIAvatar alt={name} src={image} className={classes.avatar}>
      {nameToInitials(name)}
    </MUIAvatar>
  );
};

export default Avatar;
