import { Table, TableContainer } from "@mui/material";
import { useStyles } from "components/eventSounds/components/table/styles";
import Spinner from "components/spinner";
import useData from "dataHooks/eventSounds/groupedDatasets";
import React, { useEffect, useState } from "react";
import { IDatasetGroup } from "types/eventSounds";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions from "store/eventSounds/actions";
import { IHeaderCells } from "store/eventSounds/types";
import DatasetTableHead from "../components/table/tableHead";
import Filter from "./components/filters";
import GroupedDatasetTableBody from "./table/tableBody";

const { setHeaderCells } = actions;

const tableHeaderCells = [
  { id: "", displayName: "" },
  { id: "name", displayName: "name", sortDirection: "desc" },
  { id: "result_count", displayName: "numberOfSample" },
  { id: "created_at", displayName: "creationDate" },
  { id: "modified_at", displayName: "lastUpdateDate" },
  { id: "kebabMenu", displayName: "" },
];

const GroupedDatasetTab: React.FC = () => {
  const { isFetching, groupedDatasets, onPageSort } = useData();
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const tt = (i: string) => t(`dataset.table.${i}`);
  const { headerCells } = useSelector((state: AppState) => state.eventSounds);

  const [itemClicked, setItemClicked] = useState<number | any>(null);
  const [itemClickedIds, setItemClickedIds] = useState<number[] | any>([]);
  const [isItemClicked, setIsItemClicked] = useState<number | any>(null);

  useEffect(() => {
    dispatch(setHeaderCells(tableHeaderCells));
  }, [dispatch]);

  const handlePageSort = (event: any) => {
    const { id } = event.currentTarget.parentElement;
    const currentCell = headerCells.find(
      (item: IHeaderCells) => item.id === id
    );
    const modifiedHeaderCells = headerCells.map((cell: IHeaderCells) => {
      if (cell.id === id) {
        return {
          ...cell,
          sortDirection: cell.sortDirection === "desc" ? "asc" : "desc",
        };
      }
      return {
        id: cell.id,
        displayName: cell.displayName,
      };
    });

    dispatch(setHeaderCells(modifiedHeaderCells));
    onPageSort({
      id: currentCell?.sortDirection === "desc" ? id : `-${id}`,
      desc: currentCell?.sortDirection === "desc" ? "asc" : "desc",
    });
  };

  return (
    <>
      <Filter />
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <TableContainer>
            <Table className={classes.table}>
              <DatasetTableHead
                handlePageSort={handlePageSort}
                headerCells={headerCells}
                tt={tt}
              />
              {groupedDatasets?.results?.length ? (
                groupedDatasets!.results!.map((dataset: IDatasetGroup) => {
                  return (
                    <GroupedDatasetTableBody
                      key={dataset.id}
                      groupedDataset={dataset}
                      id={dataset.id}
                      itemClicked={itemClicked}
                      setItemClicked={setItemClicked}
                      isItemClicked={isItemClicked}
                      setIsItemClicked={setIsItemClicked}
                      itemClickedIds={itemClickedIds}
                      setItemClickedIds={setItemClickedIds}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};
export default GroupedDatasetTab;
