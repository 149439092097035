import * as moment from "moment";
import { DATETIME_FORMAT, DATE_FORMAT } from "./constants";
import JSzip from "jszip";
import values from "lodash/values";

export function between(x: string, start: string, end: string) {
  return x >= start && x <= end;
}

export function getTimestamp(s: string, format: string) {
  try {
    let ts = moment.default(s).unix();
    if (moment.unix(ts).format(format) === s) {
      return ts;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export function getDateTimestamp(s: string) {
  return getTimestamp(s, DATE_FORMAT);
}

export function getDatetimeTimestamp(s: string) {
  return getTimestamp(`${s}:00`, `${DATETIME_FORMAT}:SS`);
}

export function getDatetimeTimestamp2(s: string) {
  const formattedTime =
    s.includes(":") && s.split(":").length === 2 ? `${s}:00` : s;
  return getTimestamp(formattedTime, `${DATETIME_FORMAT}:ss`);
}

const bufferToWAV = (abuffer: any, localZoom: number) => {
  const numOfChan = abuffer.numberOfChannels;
  const length = (abuffer.length / localZoom) * numOfChan * 2 + 44;
  let buffer = new ArrayBuffer(length);
  let view = new DataView(buffer);
  let channels = [];
  let i;
  let sample;
  let offset = 0;
  let pos = 0;

  // write WAVE header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded in this demo)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++)
    channels.push(abuffer.getChannelData(i));

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // write 16-bit sample
      pos += 2;
    }
    offset++; // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: "audio/wav" });

  function setUint16(data: any) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data: any) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
};

export const handleBufferDownload = (
  machine: { id: number },
  date: string,
  time: string,
  placements: any,
  localZoom: number
) => {
  const jszip = new JSzip();
  for (let i of values(placements)) {
    if (i.buffer.buffer) {
      const fileName = `${machine.id}-${i.value}-${date}:${time}.wav`;
      const wavFile = bufferToWAV(i.buffer.amplifiedBuffer, localZoom);
      jszip.file(fileName, wavFile);
    }
  }

  jszip.generateAsync({ type: "blob" }).then(function (content) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    //@ts-expect-error: expect a different approach
    a.style = "display: none";
    const url = window.URL.createObjectURL(content);
    a.href = url;
    a.download = `${machine.id}-${date}:${time}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  });

  //creating instance of dummy HTML element for button onClick download puprose
};

export const handleSingleBufferDownload = (
  buffer: AudioBuffer,
  machine: number,
  placement: number,
  analysisDates: string
) => {
  const fileName = `analysis-${machine}-${placement}-${analysisDates}.wav`;
  const wavFile = bufferToWAV(buffer, 1);
  const a = document.createElement("a");
  document.body.appendChild(a);
  //@ts-expect-error: expect a different approach
  a.style = "display: none";
  const url = window.URL.createObjectURL(wavFile);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
