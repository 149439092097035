import { IUsersState, EActions, TReducers } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IUsersState = {
  filters: {},
  q: "",
  pageIndex: 0,
  usersData: null,
  usersFetchParams: null,
  isFetching: true,
  error: null,
  more: 0,
  userIds: [],

  projectusers: null,
  loading: false,
};

const reducers: TReducers = {
  [EActions.setUsersFilter]: ({ payload: { filters } }) => ({
    filters,
    more: 0,
    pageIndex: 0,
  }),
  [EActions.setSearch]: ({ payload: { q } }) => ({
    q,
    more: 0,
    pageIndex: 0,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }, state) => ({
    pageIndex,
    more: 0,
    usersData: {
      ...state.usersData,
      results: [],
    },
  }),
  [EActions.setMore]: ({ payload: { more } }) => ({
    more,
  }),
  [EActions.fetchUsersRequest]: (_) => ({
    isFetching: true,
  }),
  [EActions.fetchUsersFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchUsersParams]: ({ payload: { usersFetchParams } }) => ({
    usersFetchParams,
  }),
  [EActions.fetchUsersSuccess]: ({ payload: { usersData } }) => ({
    isFetching: false,
    error: null,
    usersData,
  }),
  [EActions.setUserChecked]: ({ payload: { userIds } }) => ({
    userIds,
  }),
  [EActions.setReset]: () => ({ ...initialState }),

  [EActions.fetchProjectUsersRequest]: (_) => ({
    loading: true,
  }),
  [EActions.fetchProjectUsersFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchProjectUsersSuccess]: ({ payload: { projectusers } }) => ({
    loading: false,
    error: null,
    projectusers,
  }),
};

export default createReducer<IUsersState>(path, initialState, reducers);
