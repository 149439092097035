import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "store";
import omitBy from "lodash/omitBy";
import isEqual from "react-fast-compare";
import postEventPlacementFailure from "api/handlers/failures/postEventPlacementFailure";
import patchEventPlacementFailure from "api/handlers/failures/patchEventPlacementFailure";
import deleteEventPlacementFailure from "api/handlers/failures/deleteEventPlacementFailure";
import { IFailure } from "./selector";
import { TNewEventPlacementFailure } from "types/failureTypes";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";

const normalizeFailure = (d: TNewEventPlacementFailure) => {
  return {
    event: d.event,
    placement: d.placement,
    failure: d.failure,
    other_failure: d.other_failure,
    note: d.note,
  };
};

const isValidFailure = (d: TNewEventPlacementFailure) => {
  return (d.failure !== null) !== !!d.other_failure;
};

const parseId = (d: IFailure) => {
  return d.id.map((id: any) => ({
    ...d,
    id,
  }));
};

const parseFailure = (d: IFailure) => {
  return d.failure.map((failure: any) => ({
    ...d,
    failure,
  }));
};

const parseOtherFailure = (d: IFailure) => {
  return d.other_failure.map((other_failure) => ({
    ...d,
    other_failure,
  }));
};

const parseNote = (d: IFailure) => {
  return d.note.map((note: any) => ({
    ...d,
    note,
  }));
};

const parseItem = (d: IFailure) => {
  const ids = parseId(d);
  const failures = parseFailure(d);
  const other_failures = parseOtherFailure(d);
  const notes = parseNote(d);

  return ids.map((failure, index) => ({
    ...failure,
    failure: failures?.[index]?.failure ?? null,
    other_failure: other_failures?.[index]?.other_failure ?? null,
    note: notes?.[index]?.note ?? "",
  }));
};

const parsedata = (d: IFailure[]) => {
  return d.flatMap((item: any) => parseItem(item)) ?? [];
};

export const updateFailures = createAsyncThunk(
  "events/updateFailures",
  async (
    eventId: number | undefined,
    { getState, dispatch }: { getState: any; dispatch: any }
  ) => {
    try {
      const { newFailures, failures } = (getState() as AppState).eventModal;
      const parsedNewFailures = parsedata(newFailures);

      if (parsedNewFailures) {
        for (let i in parsedNewFailures) {
          if (parsedNewFailures[i]?.event === null && eventId) {
            parsedNewFailures[i] = { ...parsedNewFailures[i], event: eventId };
          }
          //EDIT
          if (parsedNewFailures[i].id !== null) {
            const findOriginal = failures.find(
              ({ id }) => id === parsedNewFailures[i].id
            );
            if (
              !isEqual(parsedNewFailures[i], findOriginal) &&
              isValidFailure(parsedNewFailures[i])
            ) {
              const diff = omitBy(
                parsedNewFailures[i],
                //@ts-expect-error: expect a different approach
                (v, k) => findOriginal[k] === v
              );
              try {
                await patchEventPlacementFailure(
                  diff,
                  parsedNewFailures[i].id!
                );
              } catch (err) {
                enqueueSnackbar(
                  i18n.t("eventModal.failures.update.fail.update", {
                    variant: "error",
                  })
                );
              }
            }
          }
          //ADD
          if (
            !parsedNewFailures[i].id &&
            isValidFailure(parsedNewFailures[i])
          ) {
            try {
              await postEventPlacementFailure({
                ...normalizeFailure(parsedNewFailures[i]),
              });
            } catch (err) {
              enqueueSnackbar(
                i18n.t("eventModal.failures.update.fail.add", {
                  variant: "error",
                })
              );
            }
          }
        }
      }
      //REMOVE
      for (let i of failures) {
        const itemFound = parsedNewFailures.find(
          (failure: any) => failure.id === i.id
        );
        if (!itemFound && i.id) {
          try {
            await deleteEventPlacementFailure(i.id);
          } catch (err) {
            enqueueSnackbar(
              i18n.t("eventModal.failures.update.fail.remove", {
                variant: "error",
              })
            );
          }
        }
      }
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);
