import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, TableContainer } from "@mui/material";
import CustomModelHead from "components/modelTable/customModel/tableHead";
import { ICustomModel, IGroupedCustomModel } from "types/customModels";
import Heading4 from "components/typography/heading/heading4";
import CustomModelBodyDeployment from "components/modelTable/customModel/tableBodyDeployment";
import { useStyles } from "components/modelTable/styles";

const CustomModelDeployment = ({ data }: { data: ICustomModel[] }) => {
  const headerRef = React.useRef<HTMLTableSectionElement>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const deploymentData = data?.length
    ? data.filter((item: ICustomModel) => item?.deployments?.length)
    : [];

  const groupedDeployments = deploymentData?.reduce(
    (acc: any[], item: ICustomModel) => {
      const key = item.trained_model;

      const matchingGroup = acc.find((train: any) => train.label === key);
      if (matchingGroup) {
        matchingGroup.deployments.push(item);
      } else {
        acc.push({ label: key, deployments: [item] });
      }
      return acc;
    },
    []
  );

  const onScroll = (e: Event) => {
    if (!headerRef.current) return;
    const offset =
      headerRef.current.getBoundingClientRect().y -
      parseFloat(headerRef.current.style.top);
    headerRef.current.style.top = `${offset < 0 ? -offset : 0}px`;
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [headerRef]);

  return (
    <>
      <TableContainer>
        <Table className={classes.table} stickyHeader>
          <CustomModelHead isTraining={false} headerRef={headerRef} />
          {groupedDeployments ? (
            groupedDeployments.map((item: IGroupedCustomModel) => (
              <CustomModelBodyDeployment group={item} />
            ))
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={5}
            >
              <Heading4>{t("list.noData")}</Heading4>
            </Box>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomModelDeployment;
