import { Column } from "react-table";
import { ITemporaryTokenList } from "types/temporaryToken";
import Combined from "./cells/combinedCells";
import { TFunction } from "i18next";

export const createTinyColumns = (
  t: TFunction,
  expiresAt: string,
  machineIds: number[]
): Column<ITemporaryTokenList>[] => [
  {
    id: "combined",
    Header: "",
    accessor: ({ id, expires_at, last_access, user }: any) => ({
      id,
      expires_at,
      last_access,
      user,
      expiresAt,
      machineIds,
      t,
    }),
    Cell: Combined,
    custom: {
      hidePriority: 0,
    },
  },
];
