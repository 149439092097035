import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface Params {
  ids: number[];
}

export default async function getDeviceHealth(params: Params) {
  const url = endpoints.devices.health.devices;
  const ids = params.ids ? `&id=${params.ids.join("&id=")}` : "";
  try {
    const { data } = await axiosNeuron.get(`${url}?${ids}`);
    // 0 = ok, 1 = error, 2 = offline
    const level = (i: any) =>
      i.device_health === "on_ok" ? 0 : i.device_health === "on_not_ok" ? 1 : 2;
    return data.results.reduce(
      (acc: any, cur: any) => ({ ...acc, [cur.device]: level(cur) }),
      {}
    );
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
