import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IEventList } from "types/event";
import dayjs from "dayjs";

interface IEventParams {
  id: number;
}

export default async function getEvent({
  id,
}: IEventParams): Promise<IEventList | undefined> {
  const url = endpoints.events.detail(id);

  try {
    const { data } = await axiosNeuron.get(`${url}`);

    const fixDate = (d: string) => {
      const date = dayjs(d);
      if (date.second() !== 59 || date.millisecond() !== 999) {
        return d;
      }
      return date.add(1, "millisecond").format("YYYY-MM-DD HH:mm:ss.SSSZ");
    };

    if (data.type.indexOf("_end") != -1) {
      data.created_at = fixDate(data.created_at);
    }
    if (data.related_event && data.related_event.type.indexOf("_end") != -1) {
      data.related_event.created_at = fixDate(data.related_event.created_at);
    }

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
