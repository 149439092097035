import React from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import SelectComponent from "components/select";
import useListCurrencies from "dataHooks/currencies/list";
import { ICurrencyList } from "types/currency";

interface IProps {
  validate?: any;
  disabled?: boolean;
}

const CurrencySelect = React.memo(({ validate, disabled }: IProps) => {
  const { t } = useTranslation();
  const { currencies } = useListCurrencies();

  let options = currencies
    ? currencies?.results?.map((item: ICurrencyList) => {
        return {
          key: item?.codename,
          value: item?.codename,
        };
      })
    : [];

  return (
    <Field name="displayCurrency" validate={validate}>
      {({ input, meta }) => {
        return (
          <SelectComponent
            id="displayCurrency"
            label={t("users.detail.displayCurrency")}
            name="displayCurrency"
            options={options}
            defaultValue={false}
            value={input.value}
            onChange={input.onChange}
            input={input}
            meta={meta}
            disabled={disabled}
          />
        );
      }}
    </Field>
  );
});

export default CurrencySelect;
