import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  machine: number;
  version: string;
  type: string;
  image_data: string;
};

export default async function addMachineImage(imageData: Params) {
  const url = endpoints.machineImages.default;
  try {
    const response = await axiosNeuron.post(url, imageData);
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
