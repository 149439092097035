import React, { PureComponent } from "react";
import FftVerticalTick from "./FftVerticalTick";

class FftVerticalTicks extends PureComponent {
  static defaultProps = {
    height: 0,
    width: 20,
  };

  render() {
    let { height, width } = this.props;
    let ticks = [];
    let tickHeight = height / 5;
    let top = 0;
    for (let i = 0; i <= 100; i += 20) {
      ticks.push(
        <FftVerticalTick
          key={i}
          top={top}
          height={tickHeight}
          label={-i}
          last={i === 100}
          width={width}
        />
      );
      top += tickHeight;
    }
    return <div style={{ position: "relative" }}>{ticks}</div>;
  }
}
export default FftVerticalTicks;
