import { IModalsState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IModalsState = {
  content: undefined,
};

const reducers: TReducers = {
  [EActions.setModal]: ({ payload: { content } }) => ({
    content,
  }),
};

export default createReducer<IModalsState>(path, initialState, reducers);
