import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export default async function removePlacementImage(id: number) {
  const url = endpoints.placements.detailImages(id);

  try {
    const response = await axiosNeuron.delete(url);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
