import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ResponseType } from "types/response";
import type { TIsoGroup } from "types/isoGroup";

const getISOGroups = async () => {
  const { data } = await axiosNeuron.get<ResponseType<TIsoGroup>>(
    endpoints.isoGroups.default
  );

  return data;
};

export default getISOGroups;
