import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ERoleCodename } from "types/role";

import { AppState } from "store";
import EditCheckbox from "components/checkbox/editCheckbox";
import SectionHeader from "components/form/parts/sectionHeader";
import Project from "./project";
import actions from "store/companiesAndProjects/actions";
import { useStyles } from "./companyStyles";
import { ICompany, IProject } from "types/companiesAndProjects";

const { setActiveCompanies, setActiveProjects } = actions;

const Company = ({ data }: { data: ICompany }) => {
  const dispatch: any = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: loggedUserId, role } = useSelector(
    (state: AppState) => state.auth.user
  );

  const userEditingOwnProfile = useMemo(
    () =>
      parseInt(id ?? "0") === loggedUserId && role !== ERoleCodename.nswAdmin,
    [id, loggedUserId, role]
  );

  const { activeCompanies, activeProjects, CPData } = useSelector(
    (state: AppState) => state.companiesAndProjects
  );

  const onCompanyChange = useCallback(
    (active: any) => {
      const projectsIds = CPData.results
        .find((company: ICompany) => company.id === data.id)
        .projects.map((project: IProject) => project.id);
      if (active) {
        dispatch(setActiveCompanies([...activeCompanies, data.id]));
        dispatch(setActiveProjects([...activeProjects, ...projectsIds]));
      } else {
        dispatch(
          setActiveCompanies(
            activeCompanies.filter((id: number) => id !== data.id)
          )
        );
        dispatch(
          setActiveProjects(
            activeProjects.filter((id: number) => !projectsIds.includes(id))
          )
        );
      }
    },
    [CPData.results, activeCompanies, activeProjects, data.id, dispatch]
  );

  return (
    <Paper elevation={0} className={classes.cpWrapper}>
      <div className={classes.companyWrapper}>
        <EditCheckbox
          entity={t("form.companiesProjects.company")}
          label={data.name}
          fontSize="large"
          data={
            data.users &&
            t("form.companiesProjects.usersCount.users_interval", {
              postProcess: "interval",
              count: data.users.length,
            })
          }
          disableCheck={userEditingOwnProfile}
          disableEdit
          checked={activeCompanies.includes(data.id)}
          onChange={onCompanyChange}
        />
      </div>
      <div className={classes.projectsWrapper}>
        {data.projects.length > 0 && (
          <Box mt={2} mb={1}>
            <SectionHeader title={t("users.list.table.projects")} />
          </Box>
        )}
        {data.projects &&
          data.projects
            .sort((a, b) => a.id - b.id)
            .map((project: any) => {
              return <Project data={project} key={"project" + project.id} />;
            })}
      </div>
    </Paper>
  );
};
export default Company;
