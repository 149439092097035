import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: "fixed",
      bottom: 0,
      background: theme.custom.palette.surface,
      zIndex: 1,
      left: 0,
      right: 0,
      marginTop: "auto",
      overflow: "hidden",
      padding: theme.spacing(0, 3),
      transform: "translateY(100%)",
      [theme.breakpoints.up("sm")]: {
        left: ({ opened }: { opened: boolean }) =>
          opened ? theme.spacing(31) : theme.spacing(12),
        right: theme.spacing(3),
      },
      "&:before": {
        content: '""',
        position: "relative",
        display: "block",
        background: "red",
      },
    },
    inner: {
      animationDuration: ".5s",
    },
    open: {
      transform: "translateY(0)",
    },
  };
});

type Props = {
  opened: boolean;
  onSave: () => void;
  resetState: () => void;
  data: any[];
  cancel: string;
  cta: string;
};

const Footer = memo(
  ({ opened, onSave, resetState, data, cancel, cta }: Props) => {
    const { t } = useTranslation();

    const classes = useStyles({ opened });
    const footerConfirmationBar = React.useRef<HTMLDivElement>(null);

    return (
      <>
        <div
          ref={footerConfirmationBar}
          className={clsx(classes.root, data.length && classes.open)}
        >
          <div className={classes.inner}>
            <Box my={4}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Box>
                  <Button onClick={resetState} color="primary">
                    {t(cancel)}
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={onSave}
                    id="testSaveChanges"
                  >
                    {t(cta)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </>
    );
  }
);

export default Footer;
