import { useEffect, useMemo, useRef, useState } from "react";
import * as draw from "components/dataLabeling/player/draw";
import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PlayArrow from "@mui/icons-material/PlayArrow";
import { Player as AudioPlayer } from "components/dataLabeling/audio/getPlayer";
import PauseSharp from "@mui/icons-material/PauseSharp";
import getWorker from "shared/drawWorker";
import getIdent from "shared/getIdent";

const useStyles = makeStyles((theme: Theme) => ({
  waveWrapper: {
    height: theme.typography.pxToRem(48),
    position: "relative",
    paddingTop: theme.typography.pxToRem(4),
  },

  button: {
    color: theme.custom.palette.data,
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 2.5),
    },
  },
}));

export const Player = ({
  buffer,
  getPeaks,
  canvasWidth,
  canvasWaveHeight,
  canvasSpectroHeight,
  isPlaying,
  setIsPlaying,
}: any) => {
  const canvasSpectrogramRef = useRef<any>(null);
  const canvasWaveRef = useRef<any>(null);
  const [frequencies, setFrequencies] = useState();
  const [player, setPlayer] = useState<any>(null);
  const [progress, setProgress] = useState<number>(0);
  const worker = getWorker();
  const ident = useMemo(() => getIdent(), []);

  const classes = useStyles();

  const duration = useMemo(() => buffer?.duration, [buffer?.duration]);

  useEffect(() => {
    const fn = (e: any) => {
      if (canvasSpectrogramRef.current && e.data?.ident === ident) {
        const context = canvasSpectrogramRef.current.getContext("2d");
        context.putImageData(e.data.imageData, 0, 0);
        setFrequencies(e.data?.frequencies);
      }
    };
    worker.addEventListener("message", fn, false);
    return () => {
      worker.removeEventListener("message", fn, false);
    };
  }, [worker, ident]);

  useEffect(() => {
    setTimeout(() => {
      const channelOne = buffer?.getChannelData(0);
      if (canvasSpectrogramRef.current !== null) {
        worker.postMessage({
          channelOne,
          sampleRate: buffer?.sampleRate,
          imageData: canvasSpectrogramRef.current
            .getContext("2d", { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasSpectrogramRef.current.width,
              canvasSpectrogramRef.current.height
            ),
          duration,
          width: canvasSpectrogramRef.current.width,
          height: canvasSpectrogramRef.current.height,
          paletteRGB: draw.paletteRGB,
          cacheFrequencies: frequencies,
          ident,
        });
      }
    }, 0);

    if (canvasWaveRef.current !== null) {
      const { peaks } = getPeaks(canvasWidth, buffer);
      draw.drawWave(canvasWaveRef.current, peaks, 2);
    }
  }, [buffer, canvasWidth, duration, frequencies, getPeaks, ident, worker]);

  useEffect(() => {
    const _player = new AudioPlayer(buffer?.sampleRate);
    setPlayer(_player);
  }, [buffer?.sampleRate]);

  const zoom = 1;
  const bufferStart = 0;
  const bufferEnd = duration;
  const windowStart = bufferStart;
  const windowEnd = bufferStart + (bufferEnd - bufferStart) / zoom;

  const onPlay = () => {
    let progress_ = progress;
    if (windowEnd - progress_ < 0.2) {
      progress_ = windowStart;
    }

    let soundDuration = windowEnd - progress_;
    let offset = Math.max(0, progress_ - bufferStart);
    if (player && buffer) {
      player?.play(
        buffer,
        offset,
        soundDuration,
        (newProgess: number) => {
          setProgress(newProgess);
        },
        () => {
          setIsPlaying(false);
        },
        bufferStart,
        bufferEnd
      );
    }
  };

  const onPause = () => {
    if (player) {
      player?.stop();
    }
  };

  useEffect(() => {
    if (isPlaying) {
      onPlay();
    }
    return () => {
      if (isPlaying) {
        onPause();
      }
    };
  }, [isPlaying, onPause, onPlay]);

  const onTogglePlay = (event: any) => {
    event.stopPropagation();
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <IconButton
        onClick={(event) => onTogglePlay(event)}
        style={{ padding: 0 }}
      >
        {isPlaying ? <PauseSharp /> : <PlayArrow />}
      </IconButton>
      <div className={classes.waveWrapper}>
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: 0,
            bottom: 0,
            width: `${(progress / duration) * 100}%`,
            background: "#f4dde2",
            opacity: 0.2,
            zIndex: 100,
            borderRight: "solid 1px black",
          }}
        />
        <canvas
          ref={canvasWaveRef}
          width={canvasWidth}
          height={canvasWaveHeight}
        />
      </div>
      <canvas
        ref={canvasSpectrogramRef}
        width={canvasWidth}
        height={canvasSpectroHeight}
      />
    </div>
  );
};
