import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "react-fast-compare";
import SingleActionModal from "components/singleActionModal";
import Paragraph from "components/typography/paragraph";
import Radio from "components/radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { EnvTypes } from "store/machineDetail/oneClickTraining/types";
import TextField from "@mui/material/TextField";
import { checkEnvRegex } from "shared/helpers";
import deployModel from "api/handlers/machine/deployModel";
import { useSnackbar } from "notistack";
import { useAsyncReducer } from "store/selectTemplate";
import { getPlacements } from "api/handlers/placement/getPlacements";
import { IPlacementList } from "types/placement";
import PlacementSelect from "./placementSelect";

const DeployModal = memo(
  ({
    machineId,
    onCancel,
    open,
    trainModel,
    title,
    subtitle,
    cta,
    isRedeploy,
    undeployId,
  }: {
    machineId: number;
    onCancel: () => void;
    open: boolean;
    trainModel: number;
    title: string;
    subtitle: string;
    cta: string;
    isRedeploy?: boolean;
    undeployId?: number;
  }) => {
    const { t } = useTranslation();

    const { data, loading, loadMore, loadingMore } = useAsyncReducer(
      "placementsSelect",
      getPlacements,
      {
        pageSize: 100,
        machineIds: machineId ? [machineId] : undefined,
      }
    );

    const tt = useCallback(
      (i: string) => t(`machine.detail.customModelOverview.${i}`),
      [t]
    );
    const { enqueueSnackbar } = useSnackbar();

    const [environment, setEnvironment] = useState<string>(EnvTypes.prod);
    const [otherEnvironment, setOtherEnvironment] = useState<string>("");
    const [placementValues, setPlacementValues] = useState<number[]>([]);
    const handleEnvironment = (e: any) => setEnvironment(e.target.value);
    const handleOtherEnvironment = (e: any) =>
      setOtherEnvironment(e.target.value);

    const handleRedeploy = async (environment: string) => {
      try {
        await deployModel(machineId, {
          trained_model_id: trainModel,
          environment,
          placements: placementValues,
        });
        onCancel();
        enqueueSnackbar(tt("beingRedeployed"));
      } catch (e) {
        enqueueSnackbar(tt("errorRedeploying"));
      }
    };

    const handleDeploy = async (environment: string) => {
      try {
        await deployModel(machineId, {
          trained_model_id: trainModel,
          environment,
          placements: placementValues,
        });
        enqueueSnackbar(tt("beingDeployed"));
        onCancel();
      } catch (e) {
        enqueueSnackbar(tt("errorDeploying"));
      }
    };

    const placementOptions = useMemo(
      () =>
        data?.results.map(({ name, id }: IPlacementList) => ({
          text: `${name} #${id}`,
          value: id,
        })),
      [data]
    );

    const onChangePlacement = (selectedVal: any) => {
      setPlacementValues(selectedVal);
    };
    const modelEnvironment = !!environment.length
      ? environment
      : otherEnvironment;

    const disabled = !environment.length && !checkEnvRegex(otherEnvironment);

    return (
      <SingleActionModal
        open={open}
        modalTitle={tt(title)}
        finishButtonLabel={tt(cta)}
        handleFinish={() => {
          if (isRedeploy) {
            handleRedeploy(modelEnvironment);
          } else {
            handleDeploy(modelEnvironment);
          }
        }}
        closeModal={onCancel}
        submitDisabled={disabled}
      >
        <>
          <Paragraph>{tt(subtitle)}</Paragraph>
          <Box m={2}>
            <RadioGroup value={environment} onChange={handleEnvironment}>
              <Radio
                activeValue={environment}
                value={EnvTypes.prod}
                label={t("oneClick.select.env.prod")}
              />
              <Radio
                activeValue={environment}
                value={EnvTypes.stage}
                label={t("oneClick.select.env.stage")}
              />
              <Radio activeValue={environment} value="" label={tt("other")} />
            </RadioGroup>
            <TextField
              disabled={!!environment.length}
              variant="outlined"
              onChange={handleOtherEnvironment}
              defaultValue={otherEnvironment}
            />
          </Box>
        </>
        <PlacementSelect
          searchPlaceholder={tt("placement.search")}
          id="placementSelect"
          name="placement"
          label={tt("placement.label")}
          options={placementOptions}
          disabled={false}
          loading={loading}
          initialValue={placementValues}
          loadMore={loadMore}
          loadingMore={loadingMore}
          onChangeSubmit={onChangePlacement}
        />
      </SingleActionModal>
    );
  },
  isEqual
);

export default DeployModal;
