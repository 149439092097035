import { IUserList } from "types/user";
import { ResponseType } from "types/response";
import { SortingRule } from "react-table";

export enum EActions {
  setPage = "setPage",
  setData = "setData",
  setLoading = "setLoading",
  setReloading = "setReloading",
  setSort = "setSort",
  setShowMore = "setShowMore",
  setReset = "setReset",
  setSearch = "setSearch",
  setSelected = "setSelected",
  setModal = "setModal",
}

export interface IAddUserModalState {
  pageIndex: number;
  data: ResponseType<IUserList[]> | undefined;
  loading: boolean;
  reloading: boolean;
  sort: SortingRule<number>[];
  showMore: number;
  q: string;
  selected: number[];
  modalOpened: string | null;
}

export interface IAddUserActions {
  type: EActions;
  payload: IAddUserModalState;
}
