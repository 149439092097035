import { IUserList } from "types/user";
import { ResponseType } from "types/response";

export enum EActions {
  setCPPage = "setCPPage",
  fetchCPRequest = "fetchCPRequest",
  fetchCPFail = "fetchCPFail",
  fetchCPSuccess = "fetchCPSuccess",
  setCPResetList = "setCPResetList",
  setActiveCompanies = "setActiveCompanies",
  setActiveProjects = "setActiveProjects",
  setOriginalActiveCP = "setOriginalActiveCP",
  discardChanges = "discardChanges",
}
export interface ICPState {
  pageIndex: number;
  CPData?: ResponseType<IUserList> | null;
  isFetching?: boolean;
  error?: Error | null;
  activeCompanies: number[];
  activeProjects: number[];
  originalActiveCompanies: number[];
  originalActiveProjects: number[];
}

export interface ICPAction {
  type: typeof EActions;
  payload: ICPState;
}
export interface ICPParams {
  filters?: {
    company: number[];
    q: string;
  };
  pageIndex: number;
}

export type TReducers = {
  [key: string]: (p: ICPAction, s: ICPState) => void;
};
