import React from "react";
import { makeStyles } from "@mui/styles";
import { CellProps } from "react-table";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      margin: theme.spacing(2),
      transform: `translateY(${theme.spacing(0.5)})`,
      background: "transparent",
      [theme.breakpoints.down("md")]: {
        transform: "none",
      },
    },
  };
});

const Icon = <T extends object>({
  cell: {
    value: { IconComponent },
  },
}: CellProps<T>) => {
  const classes = useStyles();
  return <IconComponent className={classes.root} />;
};

export default Icon;
