import { EActions, CreateUserActionTypes } from "./types";
import { prepareActions } from "store/helpers";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";
import { Action } from "redux";
import addUserHandler from "api/handlers/user/addUser";
import appUrls from "shared/appUrls";
import { ERoleCodename } from "types/role";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import { NavigateFunction } from "react-router-dom";

export const path = "createUser";

const actionsData = [
  [EActions.prepare, "prepareObject"],
  [EActions.reset],
  [EActions.setModal, "modalOpened"],
];

const actions = prepareActions<CreateUserActionTypes, EActions>(
  actionsData,
  path
);

type TUserData = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone?: string;
  role: ERoleCodename;
};

export const createUserAction =
  (userData: TUserData, history: NavigateFunction) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any | any,
    getState: any
  ) => {
    const { activeCompanies, activeProjects } = getState().companiesAndProjects;
    try {
      const user = await addUserHandler({
        ...userData,
        companies: activeCompanies,
        projects: activeProjects,
      });
      if (user) {
        enqueueSnackbar(
          i18n.t("form.userInformation.message.create", {
            identification: `${user.first_name} ${user.last_name}`,
          })
        );
        history(appUrls.users.detail(user.id, "account"));
      }
    } catch (error: any) {
      enqueueSnackbar(
        i18n.t("form.userInformation.message.error", {
          error: error && error.message,
        })
      );
    }
  };

export default actions;
