import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  name: string;
  email: string;
  users?: number[];
};

export default async function addCompany(company: Params) {
  const url = endpoints.companies.default;
  try {
    const response = await axiosNeuron.post(url, company);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
