import React, { memo, useEffect } from "react";
import Box from "@mui/material/Box";
import IFrame from "components/iframe";
import isEqual from "react-fast-compare";
import Typography from "components/typography/heading/heading2";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchPlacements } from "store/machineDetail/placements/actions";

type TProps = {
  data: string;
};

const GrafanaList = memo(({ data }: TProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(fetchPlacements({ page: 1, pageSize: 9999 }));
  }, [dispatch]);
  return (
    //KEEP data as a key to prevent staggering on tabs
    <Box width="100%" height="100%">
      {!data ? (
        <Box p={2}>
          <Typography align="center">{t("noData")}</Typography>
        </Box>
      ) : (
        <Box height="100%" mb={4}>
          <IFrame
            key={data}
            title={"grafana" + data}
            url={data + "&kiosk=tv&theme=light"}
          />
        </Box>
      )}
    </Box>
  );
}, isEqual);

export default GrafanaList;
