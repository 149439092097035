import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import MultiSelect from "./multiSelect";
import actions, { fetchMachines } from "store/eventSounds/actions";

function Machine() {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const { machinesList, machineTypeValues, machineValues } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const onChange = useCallback(
    (values: number[] | string[]) => {
      dispatch(actions.setMachineValues(values));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchMachines(machineTypeValues));
  }, [dispatch, machineTypeValues]);

  return (
    <MultiSelect
      data={machinesList?.data}
      loading={machinesList?.loading}
      selectedValues={machineValues || []}
      onChange={onChange}
      label={t("eventSounds.dataSelection.filter.machine")}
      placeholder={t("eventSounds.dataSelection.searchPlaceholder.machine")}
      id="machine-select"
    />
  );
}

export default Machine;
