import { EActions, IReportsState } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IReportsState = {
  reportsData: null,
  isFetching: true,
  error: null,
  pageIndex: 0,
  more: 0,
  modalOpened: false,

  recommendations: "",
  createdFor: "",
  pickerError: null,

  recommendationLengthError: null,

  filteredReports: null,
  filterLoading: false,

  reportsFetchParams: null,
};

const reducers = {
  [EActions.fetchReportsRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchReportsFail]: ({ payload: { error } }: any) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchReportsSuccess]: ({ payload: { reportsData } }: any) => ({
    isFetching: false,
    error: null,
    reportsData,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }: any, state: any) => ({
    pageIndex,
    more: 0,
    reportsData: {
      ...state.reportsData,
      results: [],
    },
  }),

  [EActions.fetchFilteredReportsRequest]: () => ({
    filterLoading: true,
  }),
  [EActions.fetchFilteredReportsFail]: ({ payload: { error } }: any) => ({
    filterLoading: false,
    error,
  }),
  [EActions.fetchFilteredReportsSuccess]: ({
    payload: { filteredReports },
  }: any) => ({
    filterLoading: false,
    error: null,
    filteredReports,
  }),
  [EActions.fetchReportsParams]: ({
    payload: { reportsFetchParams },
  }: any) => ({
    reportsFetchParams,
  }),

  [EActions.setMore]: ({ payload: { more } }: any) => ({
    more,
  }),
  [EActions.setModal]: ({ payload: { modalOpened } }: any) => ({
    modalOpened,
  }),
  [EActions.setRecommendations]: ({ payload: { recommendations } }: any) => ({
    recommendations,
  }),
  [EActions.setCreatedFor]: ({ payload: { createdFor } }: any) => ({
    createdFor,
  }),
  [EActions.setError]: ({ payload: { pickerError } }: any) => ({
    pickerError,
  }),
  [EActions.setRecommendationLengthError]: ({
    payload: { recommendationLengthError },
  }: any) => ({
    recommendationLengthError,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};

export default createReducer<IReportsState>(path, initialState, reducers);
