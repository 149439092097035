export enum EActions {
  setModalOpen = "setModalOpen",
  setLoading = "setLoading",
  setEnv = "setEnv",
  setOtherEnv = "setOtherEnv",
  setTrainingJobTag = "setTrainingJobTag",
  setXTraceId = "setXTraceId",
}

export interface IOCTState {
  modalOpen: boolean;
  loading: boolean;
  environment: string;
  otherEnv: string;
  trainingJobTag?: string;
  traceId?: string | null;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IOCTState;
}

export type TReducers = {
  [key: string]: (p: IActionTypes, s: IOCTState) => void;
};

export enum EnvTypes {
  prod = "production",
  stage = "stage",
  other = "other",
  not_deployed = "not_deployed",
}
