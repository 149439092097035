import { EActions, IATMState, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IATMState = {
  data: null,
};

const reducers: TReducers = {
  [EActions.setData]: ({ payload: { data } }) => ({
    data,
  }),
};

export default createReducer<IATMState>(path, initialState, reducers);
