import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function patchUnarchiveModel(trained_model_id: number) {
  const url = endpoints.machines.unarchiveModelPartial;
  try {
    const response = await axiosNeuron.patch(url, {
      trained_model_id,
    });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
