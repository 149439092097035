import React, { memo } from "react";
import isEqual from "react-fast-compare";
import { PlacementType } from "./labelComponent";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";
import Heading from "components/typography/heading/heading2";

type TProps = {
  placement: PlacementType;
};

const selector = createSelector(
  (state: AppState) => state.machineDetail.sounds,
  (sounds: any) => sounds.placementTypes
);

const PlacementName = memo(({ placement }: TProps) => {
  const placementTypes = useSelector(selector, isEqual);

  return (
    <Heading>
      {placement.customName ||
        placementTypes.find((item: any) => item.id === placement.type)?.name ||
        ""}
    </Heading>
  );
}, isEqual);

export default PlacementName;
