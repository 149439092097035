import { IEventsState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { IEventWithType } from "types/event";
import { path, regroupEvents } from "./actions";

const initialState: IEventsState = {
  events: null,
  eventTypes: null,
  resolveIds: [],
  idsToResolve: [],
  isFetching: false,
  error: null,
  unresolvedEvents: 0,
  filters: {},
  pageIndex: 0,
  sortByState: [{ id: "created_at", desc: true }],
  reset: false,
  openMasterAlerts: new Set<number>(),
  timestamp: null,
};

const reducers: TReducers = {
  [EActions.fetchEventsRequest]: ({ payload }) => ({
    isFetching: true,
    timestamp: payload?.timestamp,
  }),
  [EActions.fetchEventsRequestSilent]: ({ payload }) => ({
    timestamp: payload?.timestamp,
  }),
  [EActions.fetchEventsFail]: ({ payload }) => {
    return {
      events: null,
      isFetching: false,
      error: payload?.error,
    };
  },
  [EActions.fetchEventsSuccess]: ({ payload }, { timestamp }) =>
    payload.timestamp && timestamp && payload.timestamp >= timestamp
      ? {
          events: payload?.events,
          eventsFetchParams: payload?.eventsFetchParams,
          eventTypes: payload?.eventTypes,
          isFetching: false,
          error: null,
          unresolvedEvents: payload?.unresolvedEvents,
        }
      : {},
  [EActions.updateEventsLocal]: (
    { payload: { event } },
    { events, eventTypes }
  ) => {
    let updatedEvents;
    if (events && eventTypes) {
      updatedEvents = {
        ...events,
        results: events.results.map((item: IEventWithType) =>
          item.id === event.id
            ? {
                ...item, //this has to be like this because API response is incomplete
                type: eventTypes.find(
                  (type: { codename: string }) => type.codename === event.type
                ),
                createdAt: new Date(event.createdAt),
              }
            : item
        ),
      };
    }
    return {
      events: updatedEvents,
    };
  },
  [EActions.setResolveIds]: ({ payload }) => ({
    resolveIds: payload?.resolveIds,
  }),
  [EActions.setIdsToResolve]: ({ payload }) => ({
    idsToResolve: payload?.idsToResolve,
  }),
  [EActions.setFilter]: ({ payload: { filters } }) => ({
    filters,
    pageIndex: 0,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }) => ({
    pageIndex,
  }),
  [EActions.setSort]: ({ payload: { sortByState } }) => ({
    sortByState,
  }),
  [EActions.setReset]: (_, { filters }) => ({
    events: null,
    reset: true,
    filters: {},
    openMasterAlerts: new Set<number>(),
  }),
  [EActions.openMasterAlert]: ({ payload: { id } }, { openMasterAlerts }) => ({
    openMasterAlerts: new Set<number>([...Array.from(openMasterAlerts!), id!]),
  }),
  [EActions.closeMasterAlert]: ({ payload: { id } }, { openMasterAlerts }) => ({
    openMasterAlerts: new Set<number>([
      ...Array.from(openMasterAlerts!).filter((i: any) => i !== id),
    ]),
  }),
  [EActions.updateMasterAlert]: (
    { payload: { alertIds, masterAlertId, groupedAlerts } },
    { events }
  ) => {
    const groupedAlertIds = groupedAlerts!.map((i: any) => i.id);
    const groupedAlertIdSet = new Set(groupedAlertIds);
    const alertIdSet = new Set(alertIds);
    const results = regroupEvents(
      events!.results
        .filter((i: any) => !groupedAlertIdSet.has(i.id))
        .map((i: any) =>
          i.id === masterAlertId
            ? { ...i, groupedAlerts: groupedAlertIds }
            : alertIdSet.has(i.id)
              ? { ...i, masterAlert: null }
              : i
        )
        .concat(groupedAlerts!)
    );
    return {
      events: {
        ...events,
        results,
      },
    };
  },
};

export default createReducer<IEventsState>(path, initialState, reducers);
