import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import actions from "store/eventSounds/actions";
import { useAsyncReducer } from "store/selectTemplate";
import getMachineCategories from "api/handlers/machine/getMachineCategories";
import { ICategoryList, ISubcategoryList } from "types/machine";
import { TOptionsGroup } from "components/select/selectGroupSearch";
import MultiSelectGroupSearch from "./multiSelectGroupSearch";

function MachineType() {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const [options, setOptions] = useState<TOptionsGroup>([]);
  const [searchPhrase, setSearchPhrase] = useState("");

  const { machineSubcategories, machineTypeValues } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const { data: categoriesRes } = useAsyncReducer(
    "categoriesSelect",
    getMachineCategories,
    {}
  );

  const mergeCategories = (
    categories: ICategoryList[],
    subCategories: ISubcategoryList[]
  ) => {
    let merged: TOptionsGroup = [];
    categories?.forEach((category) => {
      const targetSubCategories = subCategories?.filter(
        (sub) => sub.category === category.id
      );
      if (targetSubCategories.length) {
        merged.push({
          group: category.name,
          items: targetSubCategories.map((sub) => ({
            name: sub.name,
            id: sub.id,
          })),
        });
      }
    });
    return merged;
  };

  const searchMachineType: (
    value: string,
    categories: ICategoryList[],
    subCategories: ISubcategoryList[]
  ) => [ICategoryList[], ISubcategoryList[]] = (
    value: string,
    categories: ICategoryList[],
    subCategories: ISubcategoryList[]
  ) => {
    if (value === "") return [categories, subCategories];
    const targetCategoryId = categories
      .filter((category) => {
        return value
          ? category.name.toLowerCase().includes(value.toLocaleLowerCase())
          : category;
      })
      .map((company) => company.id);

    const targetSubCategories = subCategories?.filter((subCategory) => {
      const searchResult = subCategory.name
        .toLowerCase()
        .includes(value.toLowerCase());
      if (searchResult || targetCategoryId.indexOf(subCategory.category) >= 0) {
        targetCategoryId.push(subCategory.category);
      }
      return (
        searchResult ||
        targetCategoryId.indexOf(subCategory.category || -1) >= 0
      );
    });

    return [
      categories?.filter(
        (category) => targetCategoryId.indexOf(category.id) >= 0
      ),
      targetSubCategories,
    ];
  };

  useEffect(() => {
    if (categoriesRes?.results && machineSubcategories?.data) {
      const [cat, subCat] = searchMachineType(
        searchPhrase,
        categoriesRes.results,
        machineSubcategories?.data
      );
      setOptions(mergeCategories(cat, subCat));
    }
  }, [categoriesRes?.results, searchPhrase, machineSubcategories?.data]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      dispatch(actions.setMachineTypeValues(values));
    },
    [dispatch]
  );

  return (
    <>
      <MultiSelectGroupSearch
        id="machine_subcategory"
        name="machineSubcategory"
        options={options}
        selectedValues={machineTypeValues || []}
        onChange={onChange}
        search={searchPhrase}
        label={t("eventSounds.dataSelection.filter.machineType")}
        placeholder={t(
          "eventSounds.dataSelection.searchPlaceholder.machineType"
        )}
        setSearchPhrase={setSearchPhrase}
        loading={machineSubcategories?.loading}
      />
    </>
  );
}

export default MachineType;
