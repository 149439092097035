const palette = {
  background: "#E2F2E3",
  surface: "#FFFFFF",
  data: "rgba(0,0,0,.87)",
  label: "rgba(0,0,0,.54)",
  border: "#E0E0E0",
  placeholder: "rgba(0,0,0,.32)",
  shadow: "rgba(0,0,0,.24)",
  tagBorder: "rgba(0,0,0,.16)",
  shade: "rgba(0,0,0,.08)", // '#EAEAEA',
  primary: {
    100: "rgba(14, 19, 44, 1)",
    48: "rgba(14, 19, 44, .48)",
    16: "rgba(14, 19, 44, .16)",
    4: "rgba(14, 19, 44, .04)",
  },
  secondary: "#F0F0F0",
  overlay: "rgba(0,0,0,.64)",
  active: {
    regular: "rgba(64, 128, 221, 1)", //"#4080DD"
    light: "rgba(64, 128, 221, 0.16)",
  },
  error: {
    regular: "#E91E63",
    light: "#FCDBE6",
  },
  alert: {
    regular: "#FF5722",
    light: "#FFE5DC",
  },
  warning: {
    regular: "#FFC107",
    background: "#FEFAEC", // rgba(255, 193, 7, .08)
    light: "#FFF5D8",
  },
  offline: {
    regular: "#9E9E9E",
    background: "#F8F8F8",
    light: "#EFEFEF",
  },
  unknown: {
    regular: "#9E9E7E",
    background: "#F8F8D8",
    light: "#EFEFCF",
  },
  maintenance: {
    regular: "rgba(64, 128, 221, 1)", //"#4080DD"
    light: "rgba(64, 128, 221, 0.16)",
  },
  success: {
    regular: "#4CAF50",
    background: "rgb(221,240,224)",
    light: "#E2F2E3",
  },
  outOfService: {
    regular: "#8609af",
    light: "#ECDBFF",
  },
  productionNote: {
    regular: "#9f9f9f",
  },
  gradient: {
    primary: {
      color: "rgb(19, 78, 128)",
      default: "linear-gradient(180deg, #0E2F56 0%, #142145  100%)",
      hover: "linear-gradient(180deg, #0f1658 0%, #0b1632 100%)",
      active: "linear-gradient(180deg, #134E80 0%, #0E2F56 100%)",
    },
  },
  dropzone: {
    border: "rgba(64,128,221,0.08)",
    background: "rgba(64,128,221,0.08)",
  },
  machineState: {
    outOfService: {
      border: "#D91EE3",
      background: "#ECDBFF",
    },
    broken: {
      border: "#E91E63",
      background: "#FCDBE6",
    },
    maintenance: {
      border: "rgba(64, 128, 221, 1)", //"#4080DD"
      background: "rgba(64, 128, 221, 0.16)",
    },
    alerts: {
      border: "#FF5722",
      background: "#FFE5DC",
    },
    running: {
      border: "#4CAF50",
      background: "rgb(221,240,224)",
    },
    notRunning: {
      border: "#9E9E9E",
      background: "#F8F8F8",
    },
    unknown: {
      border: "#9E9E7E",
      background: "#F8F8D8",
    },
  },
  modelInfo: {
    production: "rgba(76,175,80,0.7)",
    undeployedProduction: "rgba(76,175,80,0.3)",
    test: "rgba(150,9,143,0.7)",
    undeployedTest: "rgb(150,9,143,0.3)",
    training: "rgba(64,128,221,0.16)",
    error: "#FFE5DC",
    partiallyDeployed: "#CEBD00",
    grey: "#9E9E9E",
    lightGrey: "#BFBFBF",
  },
};

export default palette;
