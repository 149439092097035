import React, { memo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import { Field } from "react-final-form";
import { useStyles } from "components/events/styles";

interface IMaintenanceTypeSelect {
  value: number;
  maintenanceTypes: any[];
  setMaintenanceType: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

export const MaintenanceTypesSelect = memo(
  ({ value, maintenanceTypes, setMaintenanceType }: IMaintenanceTypeSelect) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
      <Box className={classes.select}>
        <Field
          id="maintenanceTypesSelect"
          name="maintenanceTypesSelect"
          component={Select}
          label={t("eventModal.titles.maintenanceTypes")}
          options={maintenanceTypes.map((item: any) => {
            const ret = {
              ...item,
              text:
                item.value > 0 ? t(`maintenance_type.${item.text}`) : item.text,
            };
            return ret;
          })}
          required
          onChange={setMaintenanceType}
          initialValue={value}
        />
      </Box>
    );
  }
);
