import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import actions, {
  createGroupedDatasets,
  updateDataset,
} from "store/eventSounds/actions";
import { ModalWrap } from "../components/modal";
import { Form } from "react-final-form";
import SelectionName from "./selectionName";
import ConfirmPopup from "components/confirmPopup";

const { setOpenModal } = actions;

const Save = () => {
  const { openModal, selectedDatasets, newName, existingName } = useSelector(
    (state: AppState) => state.eventSounds
  );
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [alertPopup, setAlertPopup] = useState(false);

  const onCancel = useCallback(() => {
    dispatch(setOpenModal(false));
  }, [dispatch]);

  const modifiedDatasets = selectedDatasets.map((item: number) => ({
    dataset: item,
  }));

  const closeConfirmation = useCallback(() => {
    setAlertPopup(false);
  }, []);

  const onUpdate = useCallback(
    (datasetList: any) => {
      const datasets = [...existingName.datasets, ...datasetList];
      const data = {
        name: existingName.name,
        id: existingName.id,
        datasets,
      };

      dispatch(updateDataset(data));
    },
    [dispatch, existingName]
  );

  const onConfirm = useCallback(() => {
    const datasetList = modifiedDatasets.filter(
      (item: { dataset: number }) =>
        !existingName.datasets.some(
          (datasetItem: { dataset: number }) =>
            datasetItem.dataset === item.dataset
        )
    );

    onUpdate(datasetList);
  }, [modifiedDatasets, existingName, onUpdate]);

  const onSubmit = useCallback(() => {
    if (newName) {
      const data = {
        name: newName,
        datasets: modifiedDatasets,
      };

      dispatch(createGroupedDatasets(data));
    } else {
      const isDataAlreadyExists = modifiedDatasets.some((item: any) => {
        return existingName.datasets?.some(
          (existingItem: any) => existingItem.dataset === item.dataset
        );
      });
      if (isDataAlreadyExists) {
        setAlertPopup(true);
      } else {
        onUpdate(modifiedDatasets);
      }
    }
  }, [dispatch, newName, existingName, modifiedDatasets, onUpdate]);

  return (
    <>
      <ModalWrap
        open={openModal}
        onCancel={onCancel}
        title={t("dataset.modal.title")}
        cta={t("save")}
        onSubmit={onSubmit}
      >
        <Box m={2}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SelectionName />
              </form>
            )}
          />
        </Box>
      </ModalWrap>
      {alertPopup && (
        <ConfirmPopup
          onConfirm={onConfirm}
          title={t("dataset.titles.duplicates")}
          text={t("dataset.titles.duplicatesText")}
          confirmText={t("dataset.actions.continue")}
          noControl={true}
          onCancel={closeConfirmation}
        />
      )}
    </>
  );
};
export default Save;
