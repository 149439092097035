import {
  TokenActionTypes,
  TokenState,
  EActions,
} from "store/forgottenPassword/types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: TokenState = {
  error: [],
  loading: false,
  emailSent: false,
};

const reducer = {
  [EActions.restorePasswordRequest]: () => ({
    error: [],
    loading: true,
    emailSent: false,
  }),
  [EActions.restorePasswordSuccess]: () => ({
    loading: false,
    emailSent: true,
  }),
  [EActions.restorePasswordFail]: ({
    payload: { error },
  }: TokenActionTypes) => ({
    error,
    loading: false,
  }),
  [EActions.restorePasswordReset]: () => ({
    ...initialState,
  }),
};

export default createReducer(path, initialState, reducer);
