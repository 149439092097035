import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IFailureTypes } from "store/eventSounds/types";

type IProps = {
  placement_type?: number[];
};

export default async function getFailureTypes(paramsInput?: IProps) {
  const url = endpoints.failure.types;

  const params = {
    placement_type: paramsInput?.placement_type
      ? paramsInput?.placement_type
      : undefined,
  };

  try {
    const { data: failures } = await axiosNeuron.get(
      endpoints.failure.default,
      {
        params,
      }
    );
    const {
      data: { results },
    } = await axiosNeuron.get(url);
    const res = results.filter((failureType: IFailureTypes) =>
      failures.results.some((fail: any) => fail.failure_type === failureType.id)
    );

    const resultData =
      params?.placement_type && params?.placement_type?.length ? res : results;
    return resultData;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
