import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function getAvailableDates(device: number, timezone: string) {
  const url = endpoints.availability.default;
  try {
    const { data } = await axiosNeuron.get(
      `${url}?machine=${encodeURIComponent(device)}&tz=${encodeURIComponent(
        timezone
      )}`
    );
    return data.reduce(
      (dates: string[], date: string) => ({ ...dates, [date]: true }),
      {}
    );
  } catch {
    return;
  }
}
