import React from "react";
import { makeStyles } from "@mui/styles";
import { CellProps } from "react-table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Name from "components/table/cells/name";
import { ActionsButton } from "./actionsButton";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const Combined = <T extends Object>({
  cell: {
    value: {
      name,
      id,
      path,
      recommendations,
      recommendationValue,
      last_update_at,
      t,
      inputMaxLength,
      recommendationLengthError,
      created_for,
      link,
      companyId,
      projectId,
      userIds,
    },
  },
}: CellProps<T>) => {
  const classes = useStyles();

  const [year, month, day] = last_update_at.split("T")[0].split("-");

  const date = `${year}/${month}/${day.padStart(2, "0")}`;

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box>
            <span>{t("reports.list.table.name.title")}: </span>
            <Name cell={{ value: { label: name, id, path } }} />
          </Box>
          <Box mt={2}>
            <span>{t("reports.list.table.creation")}: </span>
            <Name cell={{ value: { label: date, id, path } }} />
          </Box>
        </Box>
        <Grid item>
          <ActionsButton
            cell={{
              value: {
                id,
                recommendations,
                recommendationValue,
                companyId,
                projectId,
                inputMaxLength,
                recommendationLengthError,
                created_for,
                link,
                userIds,
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Combined;
