import { useEffect } from "react";
import { Box, TextField, Theme } from "@mui/material";
import Checkbox from "components/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions, {
  fetchCustomClassifierModelCategoryInfo,
} from "store/customModelCategory/actions";
import Spinner from "components/spinner";
import { ICustomModelCategoryInfo } from "types/customModelCategory";
import { IDataset } from "types/eventSounds";
import { useTranslation } from "react-i18next";
import Heading2 from "components/typography/heading/heading4";
import { makeStyles } from "@mui/styles";
import { IRegressionTargets } from "store/customModelCategory/types";

const { setModelUrls, setNominalClasses, setRegressionTargets } = actions;

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: "flex",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    input: {
      fontSize: theme.custom.typography.fontSize["14"],
      letterSpacing: theme.custom.typography.letterSpacing["medium"],
      height: theme.spacing(1.5),
    },
  };
});

const CustomClassifierModelCategoryInfo = ({
  modelCategory,
  datasetClasses,
}: {
  modelCategory: string;
  datasetClasses: IDataset[] | null;
}) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    customClassifierModelCategoryInfo,
    modelUrls,
    nominalClasses,
    regressionTargets,
  } = useSelector((state: AppState) => state.customModelCategory);

  const { isFetching, data } = customClassifierModelCategoryInfo;

  useEffect(() => {
    dispatch(fetchCustomClassifierModelCategoryInfo(modelCategory));
  }, [dispatch, modelCategory]);

  return isFetching ? (
    <Spinner />
  ) : (
    <>
      <Box display="flex" flexDirection="column" gap="1rem" ml={-1}>
        {data?.results?.map((item: ICustomModelCategoryInfo) => {
          const match = item.filename.match(/^(.*\/)?([^/.]+)(\..*)?/);
          const name = match ? match[2] : item.filename;
          return (
            <Checkbox
              checked={modelUrls.includes(item.filename)}
              label={item.description ? `${name} - ${item.description}` : name}
              fontWeight="normal"
              onChange={() => {
                const newData = modelUrls
                  ? modelUrls.includes(item.filename)
                    ? modelUrls.filter((cat: string) => cat !== item.filename)
                    : [...modelUrls, item.filename]
                  : [item.filename];

                dispatch(setModelUrls(newData));
              }}
            />
          );
        })}
      </Box>
      <Box mt={1} mb={1} display="flex" flexDirection="column">
        {datasetClasses && datasetClasses.length ? (
          <>
            <Heading2>
              {modelCategory === "regressor"
                ? t("oneClick.select.modelCategory.classes.title.regressor")
                : t(
                    "oneClick.select.modelCategory.classes.title.anomaly_detector"
                  )}
            </Heading2>
            {datasetClasses.map((dataset: IDataset) => {
              const currentRegression = regressionTargets?.find(
                (regression: IRegressionTargets) =>
                  regression.class === dataset.id
              );
              return modelCategory === "regressor" ? (
                <Box mt={1.8} className={classes.root}>
                  <Box width="50%">{dataset.name}</Box>
                  <TextField
                    label={t("oneClick.select.modelCategory.classes.label")}
                    value={currentRegression?.numeric_target}
                    onChange={({ target }) => {
                      const { value } = target;

                      const newData = value
                        ? currentRegression
                          ? regressionTargets.map(
                              (regression: IRegressionTargets) => {
                                return regression.class === dataset.id
                                  ? {
                                      ...regression,
                                      numeric_target: parseFloat(value),
                                    }
                                  : regression;
                              }
                            )
                          : [
                              ...regressionTargets,
                              {
                                class: dataset.id,
                                numeric_target: parseFloat(value),
                              },
                            ]
                        : regressionTargets.filter(
                            (regresion: IRegressionTargets) =>
                              regresion.class !== dataset.id
                          );

                      dispatch(setRegressionTargets(newData));
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                  />
                </Box>
              ) : (
                <Checkbox
                  checked={nominalClasses.includes(dataset.id)}
                  label={dataset.name}
                  fontWeight="normal"
                  onChange={() => {
                    const newClasses = nominalClasses
                      ? nominalClasses.includes(dataset.id)
                        ? nominalClasses.filter(
                            (nominal: number) => nominal !== dataset.id
                          )
                        : [...nominalClasses, dataset.id]
                      : [dataset.id];

                    dispatch(setNominalClasses(newClasses));
                  }}
                />
              );
            })}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default CustomClassifierModelCategoryInfo;
