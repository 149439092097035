import { EActions, TLayoutState, TReducers } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const intialState: TLayoutState = {
  drawer: false,
  menu: false,
};

const reducers: TReducers = {
  [EActions.toggleDrawer]: (
    { payload: { drawer: drawerPayload } },
    { drawer }
  ) => ({
    drawer: drawerPayload ?? !drawer,
  }),
  [EActions.toggleMenu]: (_, { menu }) => ({
    menu: !menu,
  }),
};

export default createReducer(path, intialState, reducers);
