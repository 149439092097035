import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IFaults } from "types/fault";

export default async function getFaults(
  placement: number | number[]
): Promise<IFaults | undefined> {
  const url = endpoints.faults.default;
  try {
    const { data } = await axiosNeuron.get(`${url}?order_by`, {
      params: {
        placement,
      },
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
