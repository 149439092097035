import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { ICreateUserFormFields } from "types/user";
import {
  isRequired,
  isEmail,
  validateAll,
  isPhoneNumber,
  maxCharacters,
} from "shared/form/validators";
import FormModalFooter from "components/form/parts/formModalFooter";
import TextField from "components/textField";
import RoleSelect from "components/users/roleSelect";
import LangSelect from "components/users/langSelect";
import _defaults from "lodash/defaults";
import { LangEnum } from "localization";
import invert from "lodash/invert";
import PhoneInput from "components/phoneInput";
import CurrencySelect from "../currencySelect";

const languages = invert(LangEnum);

const useStyles = makeStyles((theme: any) => ({
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    "&>*": {
      marginTop: theme.spacing(2),
    },
    [`@media only screen and (min-width: ${theme.spacing(96)})`]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      gridColumnGap: theme.spacing(3),
      gridRowGap: theme.spacing(2),
      "&>*": {
        marginTop: "initial",
      },
    },
  },
}));

type UserModalDataType = {
  user?: ICreateUserFormFields;
};

type Props = {
  id?: string;
  onSuccess: (data?: UserModalDataType) => void;
  data?: UserModalDataType;
  setFormValid?: Dispatch<SetStateAction<boolean>>;
};

const UserInformationForm = (props: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const showDesktop = useMediaQuery(
    `only screen and (min-width: ${theme.spacing(96)})`
  );
  const classes = useStyles();
  const { onSuccess, data, id, setFormValid } = props;

  const handleSubmit = (values: ICreateUserFormFields) => {
    onSuccess({ user: values });
  };
  const options = useMemo(() => {
    let res: ICreateUserFormFields = {
      firstName: "",
      lastName: "",
      role: undefined,
      email: "",
      language: languages[i18n.language],
      displayCurrency: "EUR",
    };

    if (data?.user) {
      res = _defaults(data.user, res);
    }

    return res;
  }, [data, i18n.language]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={options}
        render={(props: any) => {
          return (
            <form
              id={id}
              onSubmit={props.handleSubmit}
              className={classes.formWrapper}
            >
              <Field
                id="firstName"
                name="firstName"
                label={t("users.detail.firstName")}
                validate={isRequired}
                component={TextField}
                required
              />
              <Field
                id="lastName"
                name="lastName"
                label={t("users.detail.lastName")}
                validate={isRequired}
                component={TextField}
                required
              />
              <Field
                id="email"
                name="email"
                label={t("users.detail.email")}
                validate={validateAll([isRequired, isEmail])}
                component={TextField}
                required
              />
              <Field
                id="phone"
                name="phone"
                label={t("users.detail.phoneNumber")}
                validate={validateAll([isPhoneNumber, maxCharacters(30)])}
                component={PhoneInput}
              />
              <LangSelect validate={isRequired} required />
              <RoleSelect validate={isRequired} required />
              <CurrencySelect />
              <FormSpy subscription={{ valid: true }}>
                {({ valid }) => {
                  setFormValid?.(valid);
                  return (
                    <button
                      id={`button-${id}`}
                      style={{
                        visibility: "hidden",
                        height: 0,
                        margin: 0,
                        padding: 0,
                        width: 0,
                      }}
                      type="submit"
                    />
                  );
                }}
              </FormSpy>
            </form>
          );
        }}
      />
      {showDesktop && <FormModalFooter>{t("mandatory")}</FormModalFooter>}
    </>
  );
};

export default UserInformationForm;
