import { ignoreErrors as axiosNeuron } from "api/axios";
import endpoints from "api/endpoints";
import moment from "moment";
import fetchLastModelActivity from "api/handlers/fetchLastModelActivity";

interface IModelsParams {
  startDatetime: Date;
  endDatetime: Date;
  trainingDestination?: string;
  deploymentEnvironment?: string;
  running?: boolean;
  archived?: boolean;
}

export default async function getModels(params: IModelsParams) {
  const url = endpoints.machines.models;
  const trainingDestination = params.trainingDestination
    ? `&training_env=${params.trainingDestination}`
    : "";
  const deploymentEnvironment = params.deploymentEnvironment
    ? `&deployment_env=${params.deploymentEnvironment}`
    : "";
  const running =
    params.running !== undefined
      ? `&is_running=${params.running ? "true" : "false"}`
      : "";
  const archived =
    params.archived !== undefined
      ? `&is_archived=${params.archived ? "true" : "false"}`
      : "";
  try {
    const { data } = await axiosNeuron.get(
      `${url}?start_datetime=${moment(params.startDatetime)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}&end_datetime=${moment(
        params.endDatetime
      )
        .utc()
        .format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        )}${trainingDestination}${deploymentEnvironment}${running}${archived}`
    );
    await fetchLastModelActivity(data.results);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw new Error(`Error fetching model information:${error}`);
    }
    throw error;
  }
}
