import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import xor from "lodash/xor";

import getUsers from "api/handlers/user/getUsers";
import { EActions, IUsersAction, IFetchUsersParams } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import patchUser from "api/handlers/user/patchUser";
import getProjectUsers from "api/handlers/user/getProjectUsers";

export const path = "projectDetail/users";

const actionsData = [
  [EActions.setUsersFilter, "filters"],
  [EActions.setSearch, "q"],
  [EActions.setPage, "pageIndex"],
  [EActions.fetchUsersRequest],
  [EActions.fetchUsersFail, "error"],
  [EActions.fetchUsersParams, "usersFetchParams"],
  [EActions.fetchUsersSuccess, "usersData"],
  [EActions.setReset],
  [EActions.setMore, "more"],
  [EActions.setUserChecked, "userIds"],
  [EActions.fetchProjectUsersRequest],
  [EActions.fetchProjectUsersFail, "error"],
  [EActions.fetchProjectUsersSuccess, "projectusers"],
];

const actions = prepareActions<IUsersAction, EActions>(actionsData, path);
export default actions;

export const fetchUsers =
  (params: IFetchUsersParams) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    dispatch(actions[EActions.fetchUsersRequest]());
    try {
      const id = getState().projectDetail.project.project.id;
      dispatch(actions[EActions.fetchUsersParams](params));
      const users = await getUsers(params, false, id);
      dispatch(actions[EActions.fetchUsersSuccess](users));
    } catch (error) {
      dispatch(actions[EActions.fetchUsersFail](error));
    }
  };

export const refetchUsersSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { usersFetchParams } = getState().projectDetail.users;
    if (!!usersFetchParams) {
      try {
        const id = getState().projectDetail.project.project.id;
        dispatch(actions[EActions.fetchUsersParams](usersFetchParams));
        const users = await getUsers(usersFetchParams, false, id);
        dispatch(actions[EActions.fetchUsersSuccess](users));
      } catch (error) {
        dispatch(actions[EActions.fetchUsersFail](error));
      }
    }
  };

export const assignUserToProject =
  ({ id: userId, projects }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const id = getState().projectDetail.project.project.id;
      await patchUser({
        userId,
        projects: xor([...projects], [id]),
      });
      dispatch(refetchUsersSilent());
    } catch (err) {}
  };

export const handleUserCheck =
  ({ userIds }: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions[EActions.setUserChecked](userIds));
    } catch (err) {}
  };

export const fetchProjectUsers =
  (filterObject?: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    dispatch(actions[EActions.fetchProjectUsersRequest]());
    try {
      const id = getState().projectDetail.project.project.id;
      const projectusers = await getProjectUsers({
        projectId: id,
        filterObject,
      });
      dispatch(actions[EActions.fetchProjectUsersSuccess](projectusers));
    } catch (error) {
      dispatch(actions[EActions.fetchProjectUsersFail](error));
    }
  };
