import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function getAvailableLabels(machine: number) {
  const url = endpoints.labels.subCategories;
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(
      `${url}?ps=9999${machine ? `&machine=${machine}` : ""}`
    );

    return results.map(
      ({
        name,
        id,
        category,
      }: {
        name: string;
        id: number;
        category: number;
      }) => ({
        name,
        subcategory: id,
        category,
      })
    );
  } catch {
    return;
  }
}
