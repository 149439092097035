import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

type TParams = {
  ids?: number[];
  service?: string[];
  company?: number[] | null;
  project?: number[] | null;
  facility?: number[] | null;
  q?: string;
};

export default async function getMachines(paramsInput: TParams) {
  const url = endpoints.serviceManager.machines;

  const params = {
    id: paramsInput.ids ? paramsInput.ids : undefined,
    service: paramsInput.service ? paramsInput.service : undefined,
    company: paramsInput.company ? paramsInput.company : undefined,
    project: paramsInput.project ? paramsInput.project : undefined,
    facility: paramsInput.facility ? paramsInput.facility : undefined,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
  };

  try {
    const { data } = await axiosNeuron.get(url, { params });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
