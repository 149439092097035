import { EActions, IProjectState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IProjectState = {
  placementImage: null,
  isFetching: false,
  error: null,
};

const reducers = {
  [EActions.setPlacementImageLoading]: () => ({
    isFetching: true,
  }),
  [EActions.setPlacementImageSuccess]: ({
    payload: { placementImage },
  }: IActionTypes) => ({
    placementImage,
    isFetching: false,
  }),
  [EActions.setPlacementImageError]: ({
    payload: { error },
  }: IActionTypes) => ({
    error,
    isFetching: false,
  }),
};
export default createReducer<IProjectState>(path, initialState, reducers);
