import React from "react";
import LogoSmallImage from "assets/images/logo-small.svg";
import { useTranslation } from "react-i18next";

const LogoSmall: React.FC = () => {
  const { t } = useTranslation();
  return <img src={LogoSmallImage} alt={t("app.name")} />;
};

export default LogoSmall;
