import { prepareActions } from "store/helpers";
import { EActions, TLayoutActions } from "./types";

export const path = "layout";

const actionsData = [[EActions.toggleMenu], [EActions.toggleDrawer, "drawer"]];

const actions = prepareActions<TLayoutActions, EActions>(actionsData, path);

export default actions;
