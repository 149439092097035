export enum fromGrafana {
  handshake = "fromGrafana:handshake",
  addAnnotation = "fromGrafana:addAnnotation",
  grafanaHeight = "fromGrafana:grafanaHeight",
  editEvent = "fromGrafana:editEvent",
  deleteEvent = "fromGrafana:deleteEvent",
  addForAnalysis = "fromGrafana:addForAnalysis",
}

export enum fromPortal {
  handshake = "fromPortal:handshake",
  addEventClosed = "fromPortal:addEventClosed",
  refreshGraphs = "fromPortal:refreshGraphs",
}
