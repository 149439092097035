import { ServerErrorSetStatusAction, SERVER_ERROR_SET_STATUS } from "./types";

export const serverErrorSetStatus = (
  status: boolean,
  statusCode?: number,
  message?: string
): ServerErrorSetStatusAction => {
  return {
    type: SERVER_ERROR_SET_STATUS,
    payload: { status, statusCode, message },
  };
};
