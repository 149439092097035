import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from "react-i18next";

class Region extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dragStart: 0,
      dragEnd: 0,
    };
  }

  componentDidMount() {
    let { id } = this.props;
    let el = ReactDOM.findDOMNode(this);
    if (el instanceof HTMLElement) {
      el.addEventListener("mouseup", this.onMouseUp);
    }
    document.addEventListener("keydown", this.handleDeletePress);

    document.getElementById(id).addEventListener("mousedown", (e) => {
      e.stopPropagation();
      e.stopImmediatePropagation();
    });
  }

  componentWillUnmount() {
    let el = ReactDOM.findDOMNode(this);
    if (el instanceof HTMLElement) {
      el.removeEventListener("mouseup", this.onMouseUp);
    }
    document.removeEventListener("keydown", this.handleDeletePress);
  }

  render() {
    let {
      style: suggestedStyle,
      selectedStyle,
      left,
      bottom,
      height,
      width,
      selected,
      region,
      id,
      onSelectRegion,
      t,
    } = this.props;
    let style = {
      left,
      bottom,
      width,
      height,
      position: "absolute",
      border: "solid 1px #777777",
      borderRadius: 3,
      ...(suggestedStyle || {}),
      ...(selected && selectedStyle ? selectedStyle : {}),
    };
    let resizePanelStyle = {
      position: "absolute",
      width: 10,
      height: height - 4,
      left: width - 8,
      background: "white",
      border: "1px solid grey",
      color: "green",
      cursor: "ew-resize",
      borderRadius: 3,
      textAlign: "center",
    };
    let { labels, meta } = region;
    let tooltip = labels
      .slice(0, 10)
      .map(({ name }) => (name && name[0] === "$" ? name.slice(1) : name));
    if (tooltip.length === 0) {
      tooltip.push(t("taggingapp.labels.noLabels"));
    } else if (labels.length > 2) {
      tooltip.push(
        t("taggingapp.labels.moreLabels_interval", {
          postProcess: "interval",
          count: labels.length - 2,
        })
      );
    }
    return (
      <Tooltip
        title={
          meta ? (
            <div>
              {tooltip.join(", ")}
              <br />
              {t("taggingapp.labels.meta")}:{" "}
              <pre>
                {JSON.stringify(meta, null, 4)
                  .replace(/"([^"]+)"/g, "$1")
                  .replace("{", "")
                  .replace("}", "")}
              </pre>
            </div>
          ) : (
            <div>{tooltip.join(", ")}</div>
          )
        }
      >
        <div
          className="wave-region"
          id={id}
          style={style}
          draggable
          onDragOver={(e) => e.preventDefault()}
          onDragStart={(e) => {
            this.onDragStart(e, id);
            onSelectRegion(region, e);
          }}
          onDragEnd={(e) => this.onDragEnd(e, 3)}
        >
          <div
            className={`${id}-resize-region`}
            style={{ ...resizePanelStyle, left: -3 }}
            draggable
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => {
              this.onDragStart(e, id);
              onSelectRegion(region, e);
            }}
            onDragEnd={(e) => this.onDragEnd(e, 0)}
          >
            ||
          </div>
          <div
            className={`${id}-resize-region`}
            style={resizePanelStyle}
            draggable
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => {
              this.onDragStart(e, id);
              onSelectRegion(region, e);
            }}
            onDragEnd={(e) => this.onDragEnd(e, 1)}
          >
            ||
          </div>
        </div>
      </Tooltip>
    );
  }

  onMouseUp = (e) => {
    let { onClick, selected, region } = this.props;
    if (onClick) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      onClick(selected ? null : region, e);
    }
  };

  onDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
    this.setState({
      ...this.state,
      dragStart: e.pageX,
    });
  };
  onDragEnd = (e, mode) => {
    e.stopPropagation();
    let { dragStart } = this.state;
    if (mode === 0 || mode === 1) {
      let { region } = this.props;
      this.props.onResizeRegion(region, mode, dragStart, e);
    } else if (mode === 3) {
      this.props.onDragRegion(this.state.dragStart, e.pageX);
    }
    e.dataTransfer.clearData();
  };

  handleDeletePress = (e) => {
    let { selected, onRemoveAllLabels } = this.props;
    if (selected && e.key === "Delete") {
      onRemoveAllLabels();
    }
  };
}

export default withTranslation()(Region);
