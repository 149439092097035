import endpoints from "api/endpoints";

import moment from "moment";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import axiosNeuron from "api/axios";
import { correctGainInBuffer } from "shared/sensors";

const maxChunksAtOnce = 64;

export async function getSubsampledAudio(
  placement: number,
  datetimes: number[],
  count: number, // number of samples
  timezone: string
) {
  return new Promise(async (resolve, reject) => {
    try {
      const sorted = datetimes.sort();
      const start_time = moment(sorted[0]).format("YYYY-MM-DD HH:mm:ss.SSS");
      const end_time = moment(sorted[sorted.length - 1]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
      const response = await axiosNeuron.get(
        `${endpoints.audioChunks.subsample}?placement=${encodeURIComponent(
          placement
        )}&from=${encodeURIComponent(start_time).replace(
          /\./g,
          "%2E"
        )}&to=${encodeURIComponent(end_time).replace(
          /\./g,
          "%2E"
        )}&count=${count}&tz=${encodeURIComponent(timezone)}`
      );
      const chunks = response.data.results
        .filter((chunk: any) => chunk.id !== null)
        .map(
          (chunk: {
            id: number;
            datetime: string;
            url: string;
            gain: number;
            sensor_type: string | null;
            end_datetime: string;
            number_of_samples: number;
          }) => ({
            id: chunk.id,
            startDatetime: new Date(chunk.datetime),
            url: chunk.url,
            gain: chunk.gain,
            sensorTypeCodename: chunk.sensor_type,
            endDatetime: new Date(chunk.end_datetime),
            numberOfSamples: chunk.number_of_samples,
          })
        );
      if (!chunks.length) {
        reject("no audio");
        return;
      }

      const sampleRate = chunks.reduce(
        (acc: any, cur: any) =>
          Math.max(
            acc,
            (cur.numberOfSamples /
              (cur.endDatetime.getTime() - cur.startDatetime.getTime())) *
              1000
          ),
        48000
      );
      const ctx = getAudioContext(sampleRate <= 192000 ? sampleRate : 48000);

      const getWithRetry = (
        url: string,
        startDatetime: Date,
        gain: number,
        sensorTypeCodename: string | null
      ) => {
        for (var i = 5; ; --i) {
          try {
            return axiosNeuron
              .get(url, {
                responseType: "arraybuffer",
              })
              .then((response: any) => ctx.decodeAudioData(response.data))
              .then((audio: any) => ({
                data: correctGainInBuffer(sensorTypeCodename, audio, gain),
                startDatetime,
              }));
          } catch (e) {
            if (!i) throw e;
          }
        }
      };
      const audios = chunks.map(
        ({
          id,
          url,
          startDatetime,
          gain,
          sensorTypeCodename,
        }: {
          id: number;
          url: string;
          startDatetime: Date;
          gain: number;
          sensorTypeCodename: string | null;
        }) =>
          () =>
            url === null
              ? { data: null, startDatetime }
              : getWithRetry(
                  `${endpoints.audioChunks.file(id)}`,
                  startDatetime,
                  gain,
                  sensorTypeCodename
                )
      );
      const sensorTypeCodename =
        chunks
          .map((i: any) => i.sensorTypeCodename)
          .filter((i: string | null) => i)[0] || null;
      var sounds: any[] = [];
      while (audios.length) {
        sounds = sounds.concat(
          await Promise.all(
            audios.splice(0, maxChunksAtOnce).map((f: any) => f())
          )
        );
      }
      var firstSampleRate = 0;
      for (var i = 0; i < sounds.length; ++i) {
        if (sounds[i].data !== null) {
          firstSampleRate = sounds[i].data.sampleRate;
          break;
        }
      }
      if (!firstSampleRate) {
        reject("no audio");
        return;
      }
      const totalLength = sounds
        .map((buffer: any) =>
          buffer.data === null ? firstSampleRate : buffer.data.length
        )
        .reduce((sum: any, i: any) => sum + i, 0);
      const buffer = ctx.createBuffer(1, totalLength, firstSampleRate);
      const offsets: number[] = [0];
      const subOffsets: number[] = [];
      const startDatetimes: Date[] = [];
      for (let i = 0, offset = 0; i < sounds.length; ++i) {
        var length = firstSampleRate;
        if (sounds[i].data !== null) {
          length = sounds[i].data.length;
          buffer
            .getChannelData(0)
            .set(sounds[i].data.getChannelData(0), offset);
        }
        subOffsets.push(offset);
        offset += length;
        startDatetimes.push(sounds[i].startDatetime);
      }
      offsets.push(totalLength);
      subOffsets.push(totalLength);
      resolve({
        buffer,
        offsets,
        subOffsets,
        startDatetimes,
        sensorTypeCodename,
        originalSampleRate: sampleRate,
      });
    } catch (e) {
      reject(e);
    }
  });
}
