import { SortingRule } from "react-table";

export enum EActions {
  fetchUsersRequest = "fetchUsersRequest",
  fetchUsersFail = "fetchUsersFail",
  fetchUsersSuccess = "fetchUsersSuccess",
  fetchProjectsRequest = "fetchProjectsRequest",
  fetchProjectsFail = "fetchProjectsFail",
  fetchProjectsSuccess = "fetchProjectsSuccess",
}

interface filterData {
  value: string;
  text: string;
}

interface filterType {
  data: filterData[];
  isFetching: boolean;
  error: null;
}

export interface IAccessTokensState {
  error?: Error | null;
  users: filterType;
  projects: filterType;
}

export interface IUsersAction {
  type: typeof EActions;
  payload: IAccessTokensState;
}

export type TReducers = {
  [key: string]: (p: IUsersAction, s: IAccessTokensState) => void;
};

export type TUsersActionTypes = IUsersAction;

export interface IFetchUsersParams {
  page: number;
  pageSize: number;
  orderBy: SortingRule<number>[];
  filterObject?: { [key: string]: number[] | string[] };
  q?: string;
}
