import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import _debounce from "lodash/debounce";
import { debounceTime } from "settings";

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  input: {
    height: theme.spacing(2.75),
    paddingTop: theme.typography.pxToRem(9.5),
    paddingBottom: theme.typography.pxToRem(9.5),
  },
}));

type Props = {
  value: string;
  onSearch?: (s: string) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClose?: () => void;
  placeholder?: string;
  fullWidth?: boolean;
  isAutoSearch?: boolean;
  hideIcon?: boolean;
  isNumber?: boolean;
};

const Search = ({
  onSearch,
  value,
  placeholder,
  fullWidth,
  isAutoSearch,
  hideIcon,
  isNumber = false,
}: Props) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);
  const classes = useStyles();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = event.currentTarget.value;

      if (isNumber) {
        const regex = /^[0-9,]*$/; // Regex to allow only numbers and commas

        if (regex.test(inputValue)) {
          setLocalValue(inputValue);
        }
      } else {
        setLocalValue(inputValue);
      }
    },
    []
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const debouncedSetSearch = React.useMemo(
    () =>
      _debounce((input: string) => {
        onSearch && onSearch(input);
      }, debounceTime),
    []
  );

  const handleSearchKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        debouncedSetSearch(localValue);
      }
    },
    [localValue]
  );

  useEffect(() => {
    if (isAutoSearch) {
      debouncedSetSearch(localValue);
    }
  }, [localValue]);

  return (
    <div className={classes.root}>
      <TextField
        id="testSearch"
        variant="outlined"
        value={localValue || ""}
        placeholder={placeholder || t("search.placeholder")}
        onChange={onChange}
        type="text"
        size="small"
        fullWidth={fullWidth}
        onKeyUp={handleSearchKeyPress}
        InputProps={
          !hideIcon
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => debouncedSetSearch(localValue)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
    </div>
  );
};

export default Search;
