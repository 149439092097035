import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down(500)]: {
      flexDirection: "column",
    },
    "& span": {
      padding: theme.spacing(1, 0),
    },
  },
  rect: {
    transition: "fill-opacity 0.2s linear",
  },
}));

type Props = {
  title?: string;
};

function NoData({ title }: Props) {
  const classes = useStyles();
  return (
    <Box p={3} alignSelf="center">
      <Box className={classes.root}>
        <svg
          style={{ height: "50px", marginRight: "1rem" }}
          id="ejyq7tc5jcqre1"
          viewBox="0 0 477 500"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
        >
          <rect
            id="ejyq7tc5jcqre2"
            width="36"
            height="70"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 0 250)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre3"
            width="36"
            height="116"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 63 204)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre4"
            width="36"
            height="180"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 126 140)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre5"
            width="36"
            height="244"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 189 76)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre6"
            width="36"
            height="180"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 252 140)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre7"
            width="36"
            height="70"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 315 250)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre8"
            width="36"
            height="116"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 378 204)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre9"
            width="36"
            height="204"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 441 116)"
            fill="rgb(0,0,0)"
            fillOpacity="0.24"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            id="ejyq7tc5jcqre9"
            width="36"
            height="447"
            rx="18"
            ry="18"
            transform="matrix(1, 1, -1, 1, 441, 0)"
            fill="rgb(250,0,0)"
            fillOpacity="0.54"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </svg>
        {title && <span style={{ textAlign: "center" }}>{title}</span>}
      </Box>
    </Box>
  );
}
export default NoData;
