import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  version: string;
  type: string;
  image_data: string;
};

export default async function updateCompanyImage(
  imageId: number,
  imageData: Params
) {
  const url = endpoints.companyImages.detail(imageId + 1);
  try {
    const response = await axiosNeuron.patch(url, imageData);
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
