import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import getPlacementImage from "api/handlers/placement/getPlacementImage";
import updatePlacementImage from "api/handlers/placement/updatePlacementImage";
import removePlacementImage from "api/handlers/placement/removePlacementImage";
import { enqueueSnackbar } from "notistack";
import i18next from "i18next";
import addPlacementImage from "api/handlers/placement/addPlacementImage";

export const path = "machineDetail/placementImages";

const actionData = [
  [EActions.setPlacementImageLoading],
  [EActions.setPlacementImageSuccess, "placementImage"],
  [EActions.setPlacementImageError, "error"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const fetchPlacementImage =
  (placement: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.setPlacementImageLoading]());
    try {
      const placementImage = await getPlacementImage(placement);
      dispatch(actions[EActions.setPlacementImageSuccess](placementImage));
    } catch (error) {
      dispatch(actions[EActions.setPlacementImageError](error));
    }
  };

export const addImage =
  ({ placement, imageData }: { placement: number; imageData?: any }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await addPlacementImage({
        placement,
        type: "FULL",
        image_data: imageData,
      });

      enqueueSnackbar(
        i18next.t(`machine.detail.placementList.image.upload.success`)
      );
    } catch (error: any) {
      enqueueSnackbar(
        i18next.t(`machine.detail.placementList.image.upload.fail`)
      );
    }
  };

export const changeImage =
  ({ imageId, imageData }: { imageId: number; imageData?: any }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      if (imageData) {
        await updatePlacementImage({
          id: imageId,
          image_data: imageData,
        });
      } else {
        await removePlacementImage(imageId);
      }
      enqueueSnackbar(
        i18next.t(
          `machine.detail.placementList.image.${imageData ? "upload" : "delete"}.success`
        )
      );
    } catch (error) {
      enqueueSnackbar(
        i18next.t(
          `machine.detail.placementList.image.${imageData ? "update" : "delete"}.fail`
        )
      );
    }
  };
