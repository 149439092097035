import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  trained_model_id: number;
  environment: string;
  placements?: number[];
};

export default async function deployModel(machine: number, data: Params) {
  const url = endpoints.machines.deployModel(machine);
  try {
    const response = await axiosNeuron.post(url, data);
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
