import { useRef } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import isEqual from "lodash/isEqual";

type Arg = {
  [key: string]: any;
};

type M = (args: Arg) => void;

const useCompareFetch = (method: M, args: Arg) => {
  const refObj = useRef<Arg>(args);
  useDeepCompareEffect(() => {
    if (!isEqual(refObj.current, args) || refObj.current.reset) {
      method(args);
      refObj.current = args;
    }
  }, [method, args]);
};
export default useCompareFetch;
