import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  id: number;
  expires_at?: string;
  user?: any;
};

export default async function addTemporaryToken(params: Params) {
  const url = endpoints.temporaryToken.default;
  try {
    const { data } = await axiosNeuron.post(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
