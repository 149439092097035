import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IEventTypesResponse {
  id: number;
  name: string;
  grafana_type: unknown | null;
  grafana_state: unknown | null;
  codename: string;
  is_alert: boolean;
  end_type: number | null;
}

export default async function getEventTypes() {
  const url = endpoints.events.types;
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(url);
    return results.map((type: IEventTypesResponse) => ({
      id: type.id,
      name: type.name,
      grafanaType: type.grafana_type,
      grafanaState: type.grafana_state,
      codename: type.codename,
      isAlert: type.is_alert,
      endType: type.end_type,
    }));
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
