import React, { PureComponent } from "react";
import FftTicks from "./FftTicks";

class FftAxis extends PureComponent {
  static defaultProps = {
    height: 30,
    margin: 5,
    marginLeft: 20,
  };
  render() {
    let { height, margin, marginLeft, ...props } = this.props;
    return (
      <div
        className="fft-axis"
        style={{
          height,
          marginLeft,
          marginRight: margin,
          overflow: "hidden",
        }}
      >
        <FftTicks {...props} />
      </div>
    );
  }
}

export default FftAxis;
