import { IModel } from "types/models";

export enum EActions {
  FETCH_MODELS_REQUEST = "FETCH_MODELS_REQUEST",
  FETCH_MODELS_SUCCESS = "FETCH_MODELS_SUCCESS",
  FETCH_MODELS_FAIL = "FETCH_MODELS_FAIL",
  setReset = "setReset",
}

export interface IModelsState {
  models: IModel[] | null;
  isFetchingModels: boolean;
  error: boolean;
}

export interface IModelsAction {
  type: EActions;
  payload: IModelsState;
}

export type TReducer = {
  [key: string]: (p: IModelsAction, s: IModelsState) => void;
};
