export enum EActions {
  getTokenRequest = "getTokenRequest",
  getTokenSuccess = "getTokenSuccess",
  getTokenFail = "getTokenFail",
}

export interface TokenState {
  error?: string[];
  loading?: boolean;
}

export interface TokenActionTypes {
  type: typeof EActions;
  payload: TokenState;
}
