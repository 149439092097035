import React, { useMemo } from "react";
import { store } from "store";
import authActions from "store/auth/actions";
import modalsActions from "store/modals/actions";
import Box from "@mui/material/Box";
import Heading1 from "components/typography/heading/heading1";
import P from "components/typography/paragraph";
import { useTranslation } from "react-i18next";
import enImage from "assets/grafanaModal/unknown-id-en_US.png";
import csImage from "assets/grafanaModal/unknown-id-cs_CZ.png";
import deImage from "assets/grafanaModal/unknown-id-de_DE.png";

enum Params {
  rd = "rd",
  oldGrafana = "oldGrafana",
  unknown = "unknown_org_id",
}

const UnknownId = () => {
  const { t, i18n } = useTranslation();

  const image = useMemo(() => {
    switch (i18n.language) {
      case "cs":
        return csImage;
      case "de":
        return deImage;
      default:
        return enImage;
    }
  }, [i18n.language]);

  return (
    <Box>
      <Box display="flex" p={3} justifyContent="center">
        <Heading1>{t("grafanaModal.title")}</Heading1>
      </Box>
      <Box display="flex" p={3} justifyContent="center" alignItems="center">
        <P style={{ maxWidth: 500 }}>{t("grafanaModal.unknown.paragraph")}</P>
      </Box>
      <Box p={3}>
        <img alt="how-to-find-grafana" src={image} width="100%" />
      </Box>
    </Box>
  );
};

const OldGrafana = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box display="flex" p={3} justifyContent="center">
        <Heading1>{t("grafanaModal.title")}</Heading1>
      </Box>
      <Box display="flex" p={3} justifyContent="center" alignItems="center">
        <P style={{ maxWidth: 500 }}>{t("grafanaModal.paragraph")}</P>
      </Box>
    </Box>
  );
};

const grafanaRedirect = () => {
  const isMatch = new URLSearchParams(window.location.search);
  const hasRd = isMatch.get(Params.rd);
  const unknownOrgId = Array.from(isMatch.keys()).some(
    (key: string) => key === Params.unknown
  );

  if (unknownOrgId) {
    store.dispatch(modalsActions.setModal(UnknownId));
    return;
  }

  if (hasRd) {
    const redirectURL = new URL(hasRd);
    const redirectURLParams = new URLSearchParams(redirectURL.search);
    if (redirectURLParams.get(Params.oldGrafana)) {
      store.dispatch(modalsActions.setModal(OldGrafana));
    }
    store.dispatch(authActions.setGrafanaUrl(decodeURIComponent(hasRd)));
    return hasRd;
  }
};

export default grafanaRedirect;
