import React from "react";

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  { fallback: any; children?: React.ReactNode },
  State
> {
  constructor(props: { fallback: any }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return this.props.fallback();
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
