import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function deactivateDevice({ id }: { id: number }) {
  const url = endpoints.devices.deactivate(id);

  try {
    const response = await axiosNeuron.post(url);
    return response || { message: "Device successfully deactivated" };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
