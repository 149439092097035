import React, { memo, useCallback } from "react";
import FooterConfirmationBar from "components/layout/footerConfirmationBar";
import isEqual from "react-fast-compare";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";
import actions, {
  submitChanges,
} from "store/deviceDetail/channelsList/actions";

const selector = createSelector(
  (state: AppState) => state.deviceDetail.channelsList,
  (state: AppState) => state.deviceDetail.detail,
  (
    { initialChannels, initialPlacements, channels, placements, recordingPlan },
    device
  ) => {
    return (
      !isEqual(initialChannels, channels) ||
      !isEqual(initialPlacements, placements) ||
      (device.device?.recordingPlan &&
        recordingPlan &&
        !isEqual(recordingPlan, device.device?.recordingPlan))
    );
  }
);

type Props = {
  loading: boolean;
};

const Footer = memo(({ loading }: Props) => {
  const dispatch: any = useDispatch();
  const differs = useSelector(selector, isEqual);

  const resetState = useCallback(() => {
    dispatch(actions.resetChanges());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(submitChanges());
  }, [dispatch]);

  return (
    <FooterConfirmationBar
      onSubmit={onSubmit}
      discard={resetState}
      open={differs && !loading}
    />
  );
});

export default Footer;
