import { getDatetimeTimestamp } from "components/dataLabeling/helpers";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import moment from "moment";

import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { prepareActions } from "store/helpers";
import { getPlacements } from "api/handlers/placement/getPlacements";
import uploadAudioChunk from "api/handlers/audio/uploadAudioChunk";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import { getAudio } from "api/handlers/dataLabeling/getAudio";
export const path = "machineDetail/audioUploader";

const actionData = [
  [EActions.setWizardOpen, "wizardOpen"],
  [EActions.setRecordingStart, "stereo"],
  [EActions.setRecordingStartDatetime, "recordingStartDatetime"],
  [EActions.setUploadingStart],
  [EActions.setUploadAgain],
  [EActions.setUploadingStartDatetime, "uploadingStartDatetime"],
  [EActions.reset],
  [EActions.setAudioReady, "audioData"],
  [EActions.setPlacementsStart],
  [EActions.setPlacementsSuccess, "placements"],
  [EActions.setPayloadPlacements, "payloadPlacements"],
  [EActions.setMetaData, "metaData"],
  [EActions.setSavingStart],
  [EActions.setSavingEnd],
  [EActions.setDeviceId, "deviceId"],
  [EActions.setUploadedAudioDuration, "uploadedAudioDuration"],
  [EActions.setAudioOverlapped, "audioOverlapped"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export const fetchPlacements =
  (machineId: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.setPlacementsStart]());
    try {
      const response = await getPlacements({ machineIds: [machineId] });
      if (response) {
        dispatch(actions[EActions.setPlacementsSuccess](response));
      }
    } catch (error: any) {
      dispatch(actions[EActions.reset]());
      enqueueSnackbar(i18n.t("audioUploader.error.fetchPlacements"));
    }
  };

let currentAbortController: any = null;

export const fetchAudio =
  (date: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { payloadPlacements, uploadedAudioDuration } =
      getState().machineDetail.audioUploader;
    const { machine } = getState().machineDetail.machine;

    const formattedDate = date ? moment(new Date(date)).format() : undefined;

    const newDate = formattedDate
      ? moment(formattedDate).format("YYYY-MM-DD")
      : undefined;

    const newTime = formattedDate
      ? moment(formattedDate).format("HH:mm")
      : undefined;
    const start = getDatetimeTimestamp(`${newDate} ${newTime}`);

    if (currentAbortController) {
      currentAbortController.abort();
    }

    currentAbortController = new AbortController();
    const { signal } = currentAbortController;

    try {
      const audio = await getAudio(
        payloadPlacements,
        start as number,
        Number(uploadedAudioDuration),
        machine.timeZone,
        48000,
        signal
      );
      dispatch(actions[EActions.setAudioOverlapped](audio));
    } catch (error) {
      dispatch(actions[EActions.setAudioOverlapped](null));
    }
  };

export const uploadAudio =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    dispatch(actions[EActions.setSavingStart]());
    const {
      audioData,
      payloadPlacements,
      recordingStartDatetime,
      uploadingStartDatetime,
      metaData,
    } = getState().machineDetail.audioUploader;
    const date = recordingStartDatetime || uploadingStartDatetime;
    const dateFormatted = date ? moment(new Date(date)).format() : undefined;
    try {
      const params = {
        placements: payloadPlacements,
        file: {
          name: audioData.name,
          data: audioData.audio.split(",")[1],
        },
        start_datetime: dateFormatted as string,
        meta: metaData,
      };
      const response = await uploadAudioChunk(params);
      if (response) {
        enqueueSnackbar(i18n.t("audioUploader.success.uploadAudio"));
        dispatch(actions[EActions.reset]());
      }
    } catch (error: any) {
      if (error.message === "channel_count_does_not_match") {
        enqueueSnackbar(i18n.t("audioUploader.error.channelCountDoesNotMatch"));
        dispatch(actions[EActions.setSavingEnd]());
        return;
      }
      if (error.status === 409) {
        enqueueSnackbar(i18n.t("audioUploader.error.audioOverlapping"));
        if (uploadingStartDatetime) {
          dispatch(actions[EActions.setSavingEnd]());
          return;
        }
      }
      dispatch(actions[EActions.reset]());
      enqueueSnackbar(i18n.t("audioUploader.error.uploadAudio"));
    }
  };

export default actions;
