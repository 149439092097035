import { SortingRule } from "react-table";

import { ResponseType } from "types/response";
import { ITemporaryTokenList } from "types/temporaryToken";

export enum EActions {
  setAccessTokenFilter = "setAccessTokenFilter",
  setSearch = "setSearch",
  setPage = "setPage",
  fetchAccessTokenRequest = "fetchAccessTokenRequest",
  fetchAccessTokenFail = "fetchAccessTokenFail",
  fetchAccessTokenSuccess = "fetchAccessTokenSuccess",
  fetchTemporaryTokenParams = "fetchTemporaryTokenParams",
  setReset = "setReset",
  setMore = "setMore",
  setModal = "setModal",

  setReadOnly = "setReadOnly",
  setExpiresAt = "setExpiresAt",
  setMachines = "setMachines",
}

export interface ITemporaryTokenState {
  filters?: { [type: string]: string[] };
  q: string;
  pageIndex: number;
  temporaryTokenData?: ResponseType<ITemporaryTokenList> | null;
  temporaryTokenFetchParams?: IFetchUsersParams | null;
  isFetching?: boolean;
  error?: Error | null;
  more: number;
  modalOpened?: boolean;
  silent?: boolean;

  readOnly?: any | null;
  expiresAt?: string | null;
  machines?: number[] | null;
}

export interface ITemporaryTokenAction {
  type: typeof EActions;
  payload: ITemporaryTokenState;
}

export interface IFetchUsersParams {
  page: number;
  pageSize: number;
  orderBy: SortingRule<string>[];
  filterObject?: { [key: string]: number[] | string[] };
  q?: string;
}

export type TReducers = {
  [key: string]: (p: ITemporaryTokenAction, s: ITemporaryTokenState) => void;
};

export type TTemporaryTokenActionTypes = ITemporaryTokenAction;

export interface ITemporaryAccessTokenParams {
  page: number;
  pageSize: number;
  orderBy: SortingRule<string>[];
  filterObject?: { [key: string]: number[] | string[] };
  q?: string;
}
