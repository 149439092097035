import { IFaults } from "types/fault";
import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function postFault(params: Partial<IFaults>) {
  const url = endpoints.faults.default;

  try {
    const {
      data: { results },
    } = await axiosNeuron.post(url, { ...params });
    return results;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
