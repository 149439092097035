import React from "react";
import MaterialUiRadio from "@mui/material/Radio";
import { makeStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles((theme: any) => ({
  radio: {
    color: theme.custom.palette.data,
    "&&:hover": {
      background: theme.custom.palette.shade,
    },
  },
  radioChecked: {
    "&&": {
      color: theme.custom.palette.data,
    },
  },
  label: {
    fontSize: theme.custom.typography.fontSize[16],
    color: theme.custom.palette.data,
    lineHeight: 1.25,
    fontWeight: theme.custom.typography.fontWeight.medium,
    position: "relative",
    top: theme.typography.pxToRem(1),
  },
}));

type Props = {
  value: string;
  activeValue?: string | null;
  label: string;
  onChange?: (event: React.ChangeEvent<{}>) => void;
  id?: string;
  disabled?: boolean;
};

const Radio = ({
  value,
  activeValue,
  label,
  onChange,
  id,
  disabled,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        checked={value.toString() === activeValue ? true : false}
        value={value}
        classes={{ label: classes.label }}
        disabled={disabled}
        control={
          <MaterialUiRadio
            classes={{ root: classes.radio, checked: classes.radioChecked }}
          />
        }
        label={label}
        onChange={onChange}
        id={id}
      />
    </>
  );
};

export default Radio;
