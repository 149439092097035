import { shallowEqual, useSelector } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";
import values from "lodash/values";
import { useEffect } from "react";
const selector = createSelector(
  (state: AppState) => state.machineDetail.sounds.placements,
  (placements: any) => {
    return values(placements).every(
      (item: any) => item.buffer && !item.loadingBuffer
    );
  }
);

function useScrollToPlacement(placement?: string | null) {
  const loaded = useSelector(selector, shallowEqual);

  useEffect(() => {
    if (loaded && placement) {
      const element = document.querySelector("#placement_" + placement);
      if (element) {
        element?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [loaded, placement]);
}

export default useScrollToPlacement;
