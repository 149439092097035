import React, { memo } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface IModelHead {
  headerRef: any;
}

const ModelHead = memo((props: IModelHead) => {
  const { headerRef } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const tt = (i: string) => t(`machine.detail.model.${i}`);

  return (
    <TableHead ref={headerRef} className={classes.tableHeader}>
      <TableRow>
        <TableCell
          className={`${classes.smallPadding} ${classes.tdTableHead}`}
        ></TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("modelType")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("trainedModelId")}
        </TableCell>
        <TableCell
          className={`${classes.smallPadding} ${classes.tdTableHead}`}
        ></TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("sanityClass")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("sanityMetric")}
        </TableCell>
        <TableCell
          className={`${classes.smallPadding} ${classes.tdTableHead}`}
          title={tt("longAugmentF1")}
        >
          {tt("augmentF1")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("status")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("environment")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("deploymentId")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("deploymentDate")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          {tt("lastActivity")}
        </TableCell>
        <TableCell className={`${classes.smallPadding} ${classes.tdTableHead}`}>
          <div className={classes.fill}>{tt("actions")}</div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
});

export default ModelHead;
