import { SortingRule } from "react-table";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { EActions, IEventSoundsAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import getMachines from "api/handlers/machine/getMachines";
import getPlacementTypes from "api/handlers/placement/getPlacementTypes";
import getFailureTypes from "api/handlers/failures/getFailureTypes";
import getMachineSubcategories from "api/handlers/machine/getMachineSubcategories";
import getEventTypes from "api/handlers/event/getEventTypes";
import getEventSounds from "api/handlers/eventSounds/getEventSounds";
import getSimilaritySounds from "api/handlers/eventSounds/getSimilaritySounds";
import { getPlacements } from "api/handlers/placement/getPlacements";
import { getPlacement } from "api/handlers/placement/getPlacement";
import getMachine from "api/handlers/machine/getMachine";
import { getAudio } from "api/handlers/eventSounds/getAudio";
import addDataset from "api/handlers/eventSounds/addDataset";
import getDatasets from "api/handlers/eventSounds/getDatasets";
import getDataset from "api/handlers/eventSounds/getDataset";
import patchDatasets from "api/handlers/eventSounds/patchDataset";
import { IAudioChunk, IDatasetDetail } from "types/eventSounds";
import getDecodedFile from "api/handlers/eventSounds/getDecodedFile";
import appUrls from "shared/appUrls";
import deleteDataset from "api/handlers/eventSounds/deleteDataset";
import { IEventTypeList } from "types/event";
import getDatasetGroups from "api/handlers/eventSounds/getDatasetGroups";
import addDatasetGroup from "api/handlers/eventSounds/addDatasetGroup";
import patchDatasetGroup from "api/handlers/eventSounds/patchDatasetGroup";
import getDatasetGroup from "api/handlers/eventSounds/getDatasetGroup";
import deleteDatasetGroup from "api/handlers/eventSounds/deleteDatasetGroup";

export const path = "eventSounds";

const actionsData = [
  [EActions.getPlacementTypesRequest],
  [EActions.getPlacementTypes, "placementTypes"],
  [EActions.getMachineSubcategoryRequest],
  [EActions.getMachineSubcategory, "machineSubcategories"],
  [EActions.getFailureTypesRequest],
  [EActions.getFailureTypes, "failureTypes"],
  [EActions.getEventTypesRequest],
  [EActions.getEventTypes, "eventTypes"],
  [EActions.setPlacementTypeValues, "placementTypeValues"],
  [EActions.setMachineTypeValues, "machineTypeValues"],
  [EActions.setFailureTypeValues, "failureTypeValues"],
  [EActions.setEventTypeValues, "eventTypeValues"],
  [EActions.setMachineValues, "machineValues"],
  [EActions.setLabelStart, "labelStart"],
  [EActions.setLabelEnd, "labelEnd"],
  [EActions.setSelectedChunks, "selectedChunks"],
  [EActions.getMachinesListRequest],
  [EActions.getMachinesList, "machinesList"],
  [EActions.getMachinesListFail, "error"],
  [EActions.getPlacementsList, "placementsList"],

  [EActions.fetchEventSoundsRequest],
  [EActions.fetchEventSoundsFail, "error"],
  [EActions.fetchEventSoundsSuccess, "eventSoundsData"],
  [EActions.setMore, "more"],
  [EActions.setPage, "pageIndex"],
  [EActions.setSearchParams, "searchParams"],

  [EActions.getSimilaritySoundsRequest],
  [EActions.getSimilaritySoundsSuccess, "similaritySearch"],
  [EActions.getSimilaritySoundsFail, "error"],
  [EActions.setPlaying, "playing"],

  [EActions.getLabelRequest],
  [EActions.getLabelFail, "labelError"],
  [EActions.getMachines, "machines"],
  [EActions.getPlacements, "placements"],

  [EActions.getSimilarityDetailRequest],
  [EActions.getSimilarityDetailFail, "error"],
  [EActions.getSimilarityDetail, "similaritySearchDetail"],
  [EActions.getOriginalSound, "originalSound"],

  [EActions.setOpenModal, "openModal"],
  [EActions.setNewName, "newName"],
  [EActions.setExistingName, "existingName"],
  [EActions.setReplacingExistingName, "replaceExistingName"],

  [EActions.fetchDatasetsRequest],
  [EActions.fetchDatasetsFail, "error"],
  [EActions.fetchDatasetsSuccess, "datasets"],

  [EActions.fetchDatasetsDetailRequest],
  [EActions.fetchDatasetsDetailFail, "error"],
  [EActions.fetchDatasetsDetailSuccess, "datasetDetails"],

  [EActions.setDatasetParams, "datasetParams"],
  [EActions.setIsSearchedClicked, "isSearchedClicked"],

  [EActions.fetchDatasetGroupRequest],
  [EActions.fetchDatasetGroupFail, "error"],
  [EActions.fetchDatasetGroupSuccess, "groupedDatasets"],

  [EActions.fetchDatasetGroupDetailRequest],
  [EActions.fetchDatasetGroupDetailFail, "error"],
  [EActions.fetchDatasetGroupDetailSuccess, "groupedDatasetDetails"],

  [EActions.setSelectedDatasets, "selectedDatasets"],
  [EActions.setGroupedDatasetParams, "groupedDatasetParams"],
  [EActions.setCreationDateFrom, "startDate"],
  [EActions.setCreationDateTo, "endDate"],
  [EActions.setQ, "q"],
  [EActions.setSort, "sort"],
  [EActions.setHeaderCells, "headerCells"],
];

const actions = prepareActions<IEventSoundsAction, EActions>(actionsData, path);
export default actions;

export const fetchChunkTypes =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.getPlacementTypesRequest]());
    dispatch(actions[EActions.getMachineSubcategoryRequest]());
    dispatch(actions[EActions.getEventTypesRequest]());
    try {
      const machineSubcategories = await getMachineSubcategories({});
      const eventTypes = await getEventTypes();

      const filteredEventType = eventTypes.filter(
        (item: IEventTypeList) =>
          item.codename === "maintenance_start" ||
          item.codename === "broken_start" ||
          item.codename === "nominal_sound_start" ||
          item.codename === "production_note_start" ||
          item.codename === "data_label_start" ||
          item.codename === "additional_training_data_start"
      );

      const modifiedEventTypes = filteredEventType.map(
        (item: IEventTypeList) => ({
          ...item,
          name: item.codename.replace("_start", ""),
        })
      );

      const placementTypes = await getPlacementTypes();
      dispatch(actions[EActions.getPlacementTypes](placementTypes?.results));
      dispatch(
        actions[EActions.getMachineSubcategory](machineSubcategories?.results)
      );
      dispatch(actions[EActions.getEventTypes](modifiedEventTypes));
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

export const fetchFailureTypes =
  ({ placement_type }: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.getFailureTypesRequest]());
    try {
      const failuresTypes = await getFailureTypes({ placement_type });
      const failureTypes = failuresTypes.map((fail: any) => ({
        name: fail.codename,
        id: fail.id,
        description: fail.description,
      }));

      dispatch(actions[EActions.getFailureTypes](failureTypes));
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

type IEventSoundsParams = {
  page?: number;
  ps?: number;
  machine?: number[];
  event_type?: string[];
  machine_subcategory?: number[];
  from?: Date | null;
  to?: Date | null;
  ids?: number[];
  placement_type?: number[];
  failure_type?: string[];
  placement?: number[];
  q?: string;
};

export const fetchEventSounds =
  ({
    page,
    ps,
    machine,
    event_type,
    machine_subcategory,
    from,
    to,
    ids,
    placement_type,
    failure_type,
    placement,
    q,
  }: IEventSoundsParams) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchEventSoundsRequest]());
    try {
      const eventSounds = await getEventSounds({
        page,
        ps,
        machine,
        event_type,
        machine_subcategory,
        from,
        to,
        ids,
        placement_type,
        failure_type,
        q,
        placement,
      });

      dispatch(actions[EActions.fetchEventSoundsSuccess](eventSounds));
    } catch (error) {
      dispatch(actions[EActions.fetchEventSoundsFail](error));
    }
  };

export const fetchSimilaritySounds =
  ({
    page,
    ps,
    from,
    to,
    placement,
  }: {
    page: number;
    ps: number;
    from: Date;
    to: Date;
    placement: number;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.getSimilaritySoundsRequest]());
    try {
      const similaritySounds = await getSimilaritySounds({
        from,
        to,
        placement,
        page,
        ps,
      });

      const originalSoundData = await getAudio(placement, from, to);

      dispatch(actions[EActions.getOriginalSound](originalSoundData));
      dispatch(actions[EActions.getSimilaritySoundsSuccess](similaritySounds));
    } catch (error) {
      dispatch(actions[EActions.getSimilaritySoundsFail](error));
    }
  };

export const fetchMachines =
  (subcategory?: number[]) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.getMachinesListRequest]());

    try {
      const machines = await getMachines({ pageSize: 99999, subcategory });

      dispatch(actions[EActions.getMachinesList](machines?.results));
    } catch (error) {
      dispatch(actions[EActions.getMachinesListFail](error));
    }
  };

export const getLabelData =
  ({
    machineIds,
    placementIds,
  }: {
    machineIds?: number[];
    placementIds?: number[];
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.getLabelRequest]());
    try {
      const machines = await getMachines({ pageSize: 99999, ids: machineIds });
      const placements = await getPlacements({ ps: 99999, id: placementIds });

      dispatch(actions[EActions.getMachines](machines?.results));
      dispatch(actions[EActions.getPlacements](placements?.results));
    } catch (error) {
      dispatch(actions[EActions.getLabelFail](error));
    }
  };

export const getDetail =
  ({ machineId, placementId }: { machineId: number; placementId: number }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.getSimilarityDetailRequest]());
    try {
      const machine = await getMachine(machineId);
      const placement = await getPlacement(placementId);

      const data = {
        placement: placement.data,
        machine,
      };

      dispatch(actions[EActions.getSimilarityDetail](data));
    } catch (error) {
      dispatch(actions[EActions.getSimilarityDetailFail](error));
    }
  };

export const fetchDatasets =
  ({
    pageSize,
    page,
    ids,
    startDate,
    endDate,
    q,
    orderBy,
  }: {
    pageSize?: number;
    page?: number;
    ids?: number[];
    startDate?: Date | null;
    endDate?: Date | null;
    q?: string | null;
    orderBy?: SortingRule<number>[];
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDatasetsRequest]());
    try {
      const datasets = await getDatasets({
        pageSize,
        page,
        ids,
        startDate,
        endDate,
        q,
        orderBy,
      });
      dispatch(
        actions[EActions.setDatasetParams]({
          pageSize,
          page,
          ids,
          startDate,
          endDate,
          q,
          orderBy,
        })
      );

      dispatch(actions[EActions.fetchDatasetsSuccess](datasets));
    } catch (error) {
      dispatch(actions[EActions.fetchDatasetsFail](error));
    }
  };

export const refetchDatasets =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        datasetParams: { page, pageSize, ids, startDate, endDate, q, orderBy },
      } = getState().eventSounds;

      const datasets = await getDatasets({
        pageSize,
        page,
        ids,
        startDate,
        endDate,
        q,
        orderBy,
      });

      dispatch(actions[EActions.fetchDatasetsSuccess](datasets));
    } catch (error) {
      dispatch(actions[EActions.fetchDatasetsFail](error));
    }
  };

export const getSingleDataset =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDatasetsDetailRequest]());
    try {
      const dataset = await getDataset(id);

      const resultPromises = dataset.results.map(
        async (item: IDatasetDetail) => {
          const promises = item.audio_chunks.map(async (chunk: IAudioChunk) => {
            const buffer = await getDecodedFile({
              url: chunk.url,
            });
            return {
              ...chunk,
              buffer,
            };
          });

          const audioChunks = promises.length
            ? await Promise.all(promises)
            : [];
          return {
            ...item,
            audio_chunks: audioChunks,
          };
        }
      );

      const newResults = resultPromises.length
        ? await Promise.all(resultPromises)
        : [];

      const newDataset = {
        ...dataset,
        results: newResults,
      };

      const machineIds = newDataset.results.map((item: any) => item.machine);
      const placementIds = newDataset.results.map(
        (item: any) => item.placement
      );
      const uniqueMachineIds = machineIds.filter(
        (id: number, idx: number, self: any) => {
          return self.indexOf(id) === idx;
        }
      );
      const uniquePlacementIds = placementIds.filter(
        (id: number, idx: number, self: any) => {
          return self.indexOf(id) === idx;
        }
      );

      const machines = await getMachines({
        ids: uniqueMachineIds,
        pageSize: 999,
      });
      const placements = await getPlacements({
        id: uniquePlacementIds,
        ps: 999,
      });

      const newDatasetList = {
        ...newDataset,
        results: newDataset.results.map((item: any) => {
          const machineName = machines?.results.find(
            (machine: any) => machine.id === item.machine
          );
          const placementName = placements?.results.find(
            (placement: any) => placement.id === item.placement
          );
          return {
            ...item,
            machineName: machineName?.name,
            placementName: placementName?.customName,
          };
        }),
      };

      dispatch(actions[EActions.fetchDatasetsDetailSuccess](newDatasetList));
    } catch (error) {
      dispatch(actions[EActions.fetchDatasetsDetailFail](error));
    }
  };

export const createDataset =
  ({
    name,
    datasetData,
  }: {
    name: string;
    datasetData: {
      placement: number;
      start: string;
      end: string;
      representation_start: string;
      representation_end: string;
    }[];
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await addDataset({
        name,
        datasetData,
      });
      dispatch(actions[EActions.setOpenModal](false));
      dispatch(actions[EActions.setSelectedChunks]([]));
      enqueueSnackbar(i18n.t("dataExplorer.collectionClass.add.success"));
      window.open(appUrls.eventSounds.detailCollectionClasses(), "_blank");
    } catch (error) {
      enqueueSnackbar(
        i18n.t("dataExplorer.collectionClass.add.fail", {
          variant: "error",
        })
      );
    }
  };

export const updateDataset =
  ({
    name,
    datasetData,
    id,
    openNewTab = true,
  }: {
    name: string;
    datasetData?: {
      placement: number;
      start: string;
      end: string;
      representation_start: string;
      representation_end: string;
    }[];
    id: number;
    openNewTab?: boolean;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchDatasets({
        name,
        datasetData,
        id,
      });
      dispatch(refetchDatasets());
      dispatch(actions[EActions.setOpenModal](false));
      dispatch(actions[EActions.setSelectedChunks]([]));
      enqueueSnackbar(i18n.t("dataExplorer.collectionClass.update.success"));
      return openNewTab
        ? window.open(appUrls.eventSounds.detailCollectionClasses(), "_blank")
        : null;
    } catch (error) {
      enqueueSnackbar(
        i18n.t("dataExplorer.collectionClass.update.fail", {
          variant: "error",
        })
      );
    }
  };

export const removeDataset =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await deleteDataset(id);
      dispatch(refetchDatasets());
      enqueueSnackbar(i18n.t("dataExplorer.collectionClass.delete.success"));
    } catch (error) {
      enqueueSnackbar(
        i18n.t("dataExplorer.collectionClass.delete.fail", {
          variant: "error",
        })
      );
    }
  };

export const fetchGroupedDatasets =
  ({
    pageSize,
    page,
    orderBy,
    startDate,
    endDate,
    q,
  }: {
    pageSize?: number;
    page?: number;
    orderBy?: SortingRule<number>[];
    startDate?: Date | null;
    endDate?: Date | null;
    q?: string | null;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDatasetGroupRequest]());
    try {
      const groupedDatasets = await getDatasetGroups({
        pageSize,
        page,
        orderBy,
        startDate,
        endDate,
        q,
      });
      dispatch(
        actions[EActions.setGroupedDatasetParams]({
          pageSize,
          page,
          orderBy,
          startDate,
          endDate,
          q,
        })
      );

      dispatch(actions[EActions.fetchDatasetGroupSuccess](groupedDatasets));
    } catch (error) {
      dispatch(actions[EActions.fetchDatasetGroupFail](error));
    }
  };

export const refetchGroupedDatasets =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        groupedDatasetParams: {
          page,
          pageSize,
          orderBy,
          startDate,
          endDate,
          q,
        },
      } = getState().eventSounds;
      const groupedDatasets = await getDatasetGroups({
        pageSize,
        page,
        orderBy,
        startDate,
        endDate,
        q,
      });

      dispatch(actions[EActions.fetchDatasetGroupSuccess](groupedDatasets));
    } catch (error) {
      dispatch(actions[EActions.fetchDatasetGroupFail](error));
    }
  };

export const getSingleGroupedDataset =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDatasetGroupDetailRequest]());
    try {
      const groupedDataset = await getDatasetGroup(id);

      dispatch(
        actions[EActions.fetchDatasetGroupDetailSuccess](groupedDataset)
      );
    } catch (error) {
      dispatch(actions[EActions.fetchDatasetGroupDetailFail](error));
    }
  };

export const updateGroupedDataset =
  ({
    id,
    name,
    datasets,
    openNewTab = true,
  }: {
    id: number;
    name: string;
    datasets: { dataset: number }[];
    openNewTab?: boolean;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchDatasetGroup({ id, name, datasets });

      dispatch(refetchGroupedDatasets());
      dispatch(actions[EActions.setOpenModal](false));
      dispatch(actions[EActions.setSelectedDatasets]([]));
      enqueueSnackbar(i18n.t("dataExplorer.dataset.update.success"));
      return openNewTab
        ? window.open(appUrls.eventSounds.detailDatasets(), "_blank")
        : null;
    } catch (error) {
      enqueueSnackbar(i18n.t("dataExplorer.dataset.update.fail"));
    }
  };

export const createGroupedDatasets =
  ({ name, datasets }: { name: string; datasets: { dataset: number }[] }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await addDatasetGroup({ name, datasets });

      dispatch(actions[EActions.setOpenModal](false));
      dispatch(actions[EActions.setSelectedDatasets]([]));
      enqueueSnackbar(i18n.t("dataExplorer.dataset.add.success"));
      window.open(appUrls.eventSounds.detailDatasets(), "_blank");
    } catch (error) {
      enqueueSnackbar(i18n.t("dataExplorer.dataset.add.fail"));
    }
  };

export const removeGroupedDataset =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await deleteDatasetGroup(id);
      dispatch(refetchGroupedDatasets());
      enqueueSnackbar(i18n.t("dataExplorer.dataset.delete.success"));
    } catch (error) {
      enqueueSnackbar(
        i18n.t("dataExplorer.dataset.delete.fail", {
          variant: "error",
        })
      );
    }
  };
