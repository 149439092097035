import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ICompanyList } from "types/company";

export default async function getCompany(id: number) {
  const url = endpoints.companies.detail(id);
  try {
    const response = await axiosNeuron.get(url);
    const data: ICompanyList = response.data;
    return data;
  } catch (error) {
    if (error !== undefined) {
      return null;
    }
  }
}
