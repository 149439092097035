import {
  ServerErrorState,
  ServerErrorSetStatusAction,
  SERVER_ERROR_SET_STATUS,
} from "./types";

const initialState: ServerErrorState = {
  status: false,
};

const reducer = (
  state = initialState,
  action: ServerErrorSetStatusAction
): ServerErrorState => {
  switch (action.type) {
    case SERVER_ERROR_SET_STATUS: {
      return {
        status: action.payload.status,
        statusCode: action.payload.statusCode,
        message: action.payload.message,
      };
    }
    default:
      return state;
  }
};

export default reducer;
