import { useEffect } from "react";
import actions, { fetchData } from "store/activateDevice/actions";
import { AppState } from "store";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
const { setReset } = actions;

const selector = createSelector(
  (state: AppState) => state.activateDevice,
  (activateDevice: any) => {
    return {
      localCompanies: activateDevice.localCompanies,
      localProjects: activateDevice.localProjects,
      loading: activateDevice.loading,
      page: activateDevice.page,
      selectedProject: activateDevice.selectedProject,
      pageCount: activateDevice.pageCount,
    };
  }
);

const useActivateDevice = () => {
  const dispatch: any = useDispatch();

  const {
    localCompanies,
    localProjects,
    loading,
    page,
    selectedProject,
    pageCount,
  } = useSelector(selector, shallowEqual);

  useEffect(() => {
    dispatch(
      fetchData({
        page: page + 1,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, [dispatch]);

  return {
    localCompanies,
    localProjects,
    loading,
    page,
    pageCount,
    selectedProject,
  };
};
export default useActivateDevice;
