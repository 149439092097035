type TField = string[];

const machineField: TField = [
  "id",
  "name",
  "subcategory",
  "station",
  "is_active",
  "facility",
  "project",
  "company",
  "status",
  "new_alerts",
  "under_investigation_alerts",
  "customer_informed_alerts",
  "is_maintenance",
  "is_broken",
  "is_nsw_maintenance",
  "is_any_maintenance",
  "is_out_of_service",
  "time_zone",
  "suppress_notifications_until",
  "has_snoozed_placements",
  "devices",
];
const companyField: TField = ["id", "name"];
const projectField: TField = ["id", "name"];
const facilityField: TField = ["id", "name"];

export { machineField, companyField, projectField, facilityField };
