import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IMaintenanceTypesResponse {
  id: number;
  codename: string;
}

export default async function getMaintenanceTypes() {
  const url = endpoints.events.maintenanceTypes;
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(url);
    return results.map((type: IMaintenanceTypesResponse) => ({
      id: type.id,
      codename: type.codename,
    }));
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
