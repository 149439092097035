import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ResponseType } from "types/response";
import { ERoleCodename } from "types/role";
import EStatusName from "enums/userStatus";

export interface UsersItemTypes {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  lastLogin: string | null;
  companies: number[];
  companiesNames: (string | null)[];
  projects: number[];
  projectsNames: (string | null)[];
  status: EStatusName;
  role: ERoleCodename | null;
  username?: string;
  is_active?: boolean;
  is_internal?: boolean;
}

type TParams = {
  filterObject?: { [key: string]: number[] | string[] | number };
  projectId?: number;
};

export default async function getProjectUsers({
  projectId,
  filterObject,
}: TParams) {
  const params = {
    ...filterObject,
  };

  const url = endpoints.users.default;

  try {
    const response = await axiosNeuron.get(
      `${url}?project=${projectId}&ps=9999`,
      {
        params,
      }
    );

    const { count } = response.data;
    const data: ResponseType<UsersItemTypes> = {
      count: count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        firstName: item.first_name,
        lastName: item.last_name,
        email: item.email,
        lastLogin: item.last_login ? new Date(item.last_login) : undefined,
        companies: item.companies,
        companiesNames: [],
        phone: item.phone,
        projects: item.projects,
        projectsNames: [],
        status: item.status,
        role: item.role,
      })),
    };

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
