import { IDevicesListState, EActions, TReducers } from "./types";
import { path } from "./actions";
import { createReducer } from "store/helpers";
import { IExtendedDevice } from "types/device";

const initialState: IDevicesListState = {
  pageIndex: 0,
  filters: {},
  orderBy: [],
  q: undefined,
  ids: [],
  devices: null,
  devicesFetchParams: null,
  isFetching: false,
  error: null,
  more: 0,
  isFiltered: false,
};

const reducers: TReducers = {
  [EActions.setDevicesPage]: ({ payload: { pageIndex } }, state) => ({
    pageIndex,
    more: 0,
    devices: {
      ...state.devices,
      results: [],
    },
  }),
  [EActions.setDevicesMore]: ({ payload: { more } }) => ({
    more,
  }),
  [EActions.setDevicesFilter]: ({ payload: { filters } }) => ({
    filters,
    pageIndex: 0,
    more: 0,
  }),
  [EActions.setDevicesOrderBy]: ({ payload: { orderBy } }, state) => ({
    pageIndex: 0,
    more: 0,
    orderBy,
    devices: {
      ...state.devices,
      results: [],
    },
  }),
  [EActions.resetDevices]: () => ({
    ...initialState,
  }),
  [EActions.fetchDataRequest]: () => ({ isFetching: true }),
  [EActions.fetchDataSuccess]: (
    { payload: { devices, devicesFetchParams, deviceStatuses, more } },
    state
  ) => {
    let dev: IExtendedDevice[] = [];
    if (more && state.devices?.results) {
      dev = state.devices.results;
    }
    return {
      devices: {
        ...devices,
        results: [...dev, ...(devices?.results || [])],
      },
      devicesFetchParams,
      deviceStatuses,
      isFetching: false,
      error: null,
    };
  },
  [EActions.fetchDataFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
    devices: null,
  }),
  [EActions.setFilter]: ({ payload: { isFiltered } }) => ({
    isFiltered,
  }),
};

export default createReducer<IDevicesListState>(path, initialState, reducers);
