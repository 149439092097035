import { ICompanyList } from "types/company";
import { IProjectList } from "types/project";

export enum EActions {
  setPage = "setPage",
  setSelectedProject = "setSelectedProject",
  setLoading = "setLoading",
  setLocalCompanies = "setLocalCompanies",
  setLocalProjects = "setLocalProjects",
  setPageCount = "setPageCount",
  setReset = "setReset",
}

export interface IActivateDeviceState {
  selectedProject: number | null;
  localCompanies: ICompanyList[];
  localProjects: IProjectList[];
  loading: boolean;
  page: number;
  pageCount: number;
}

export interface IActivateDeviceActions {
  type: EActions;
  payload: IActivateDeviceState;
}

export type TReducers = {
  [key: string]: (p: IActivateDeviceActions, s: IActivateDeviceState) => void;
};
