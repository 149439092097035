enum EPermissions {
  LIST_OWN_AUDIO_CHUNKS = "api.list_own_audio_chunks",
  LIST_ALL_AUDIO_CHUNKS = "api.list_all_audio_chunks",
  CREATE_OWN_AUDIO_CHUNK = "api.create_own_audio_chunk",
  CREATE_ANY_AUDIO_CHUNK = "api.create_any_audio_chunk",
  READ_OWN_AUDIO_CHUNK = "api.read_own_audio_chunk",
  READ_ANY_AUDIO_CHUNK = "api.read_any_audio_chunk",
  READ_OWN_AUDIO_CHUNK_FILE = "api.read_own_audio_chunk_file",
  READ_ANY_AUDIO_CHUNK_FILE = "api.read_any_audio_chunk_file",
  UPDATE_OWN_AUDIO_CHUNK = "api.update_own_audio_chunk",
  UPDATE_ANY_AUDIO_CHUNK = "api.update_any_audio_chunk",
  DELETE_OWN_AUDIO_CHUNK = "api.delete_own_audio_chunk",
  DELETE_ANY_AUDIO_CHUNK = "api.delete_any_audio_chunk",
  UPLOAD_OWN_AUDIO_CHUNK = "api.upload_own_audio_chunk",
  UPLOAD_ANY_AUDIO_CHUNK = "api.upload_any_audio_chunk",
  LIST_CHANNEL_STATES = "api.list_channel_states",
  CREATE_CHANNEL_STATE = "api.create_channel_state",
  READ_CHANNEL_STATE = "api.read_channel_state",
  UPDATE_CHANNEL_STATE = "api.update_channel_state",
  DELETE_CHANNEL_STATE = "api.delete_channel_state",
  LIST_OWN_CHANNELS = "api.list_own_channels",
  LIST_ALL_CHANNELS = "api.list_all_channels",
  CREATE_OWN_CHANNEL = "api.create_own_channel",
  CREATE_ANY_CHANNEL = "api.create_any_channel",
  READ_OWN_CHANNEL = "api.read_own_channel",
  READ_ANY_CHANNEL = "api.read_any_channel",
  UPDATE_OWN_CHANNEL = "api.update_own_channel",
  UPDATE_ANY_CHANNEL = "api.update_any_channel",
  DELETE_OWN_CHANNEL = "api.delete_own_channel",
  DELETE_ANY_CHANNEL = "api.delete_any_channel",
  LIST_OWN_COMPANIES = "api.list_own_companies",
  LIST_ALL_COMPANIES = "api.list_all_companies",
  CREATE_OWN_COMPANY = "api.create_own_company",
  CREATE_ANY_COMPANY = "api.create_any_company",
  READ_OWN_COMPANY = "api.read_own_company",
  READ_ANY_COMPANY = "api.read_any_company",
  UPDATE_OWN_COMPANY = "api.update_own_company",
  UPDATE_ANY_COMPANY = "api.update_any_company",
  DELETE_OWN_COMPANY = "api.delete_own_company",
  DELETE_ANY_COMPANY = "api.delete_any_company",
  LIST_OWN_CONTACT_SETS = "api.list_own_contact_sets",
  LIST_ALL_CONTACT_SETS = "api.list_all_contact_sets",
  CREATE_OWN_CONTACT_SET = "api.create_own_contact_set",
  CREATE_ANY_CONTACT_SET = "api.create_any_contact_set",
  READ_OWN_CONTACT_SET = "api.read_own_contact_set",
  READ_ANY_CONTACT_SET = "api.read_any_contact_set",
  UPDATE_OWN_CONTACT_SET = "api.update_own_contact_set",
  UPDATE_ANY_CONTACT_SET = "api.update_any_contact_set",
  DELETE_OWN_CONTACT_SET = "api.delete_own_contact_set",
  DELETE_ANY_CONTACT_SET = "api.delete_any_contact_set",
  LIST_CONTACT_TYPES = "api.list_contact_types",
  CREATE_CONTACT_TYPE = "api.create_contact_type",
  READ_CONTACT_TYPE = "api.read_contact_type",
  UPDATE_CONTACT_TYPE = "api.update_contact_type",
  DELETE_CONTACT_TYPE = "api.delete_contact_type",
  LIST_OWN_CONTACTS = "api.list_own_contacts",
  LIST_ALL_CONTACTS = "api.list_all_contacts",
  CREATE_OWN_CONTACT = "api.create_own_contact",
  CREATE_ANY_CONTACT = "api.create_any_contact",
  READ_OWN_CONTACT = "api.read_own_contact",
  READ_ANY_CONTACT = "api.read_any_contact",
  UPDATE_OWN_CONTACT = "api.update_own_contact",
  UPDATE_ANY_CONTACT = "api.update_any_contact",
  DELETE_OWN_CONTACT = "api.delete_own_contact",
  DELETE_ANY_CONTACT = "api.delete_any_contact",
  LIST_DEVICE_TYPES = "api.list_device_types",
  CREATE_DEVICE_TYPE = "api.create_device_type",
  READ_DEVICE_TYPE = "api.read_device_type",
  UPDATE_DEVICE_TYPE = "api.update_device_type",
  DELETE_DEVICE_TYPE = "api.delete_device_type",
  LIST_DEVICE_VERSIONS = "api.list_device_versions",
  CREATE_DEVICE_VERSION = "api.create_device_version",
  READ_DEVICE_VERSION = "api.read_device_version",
  UPDATE_DEVICE_VERSION = "api.update_device_version",
  DELETE_DEVICE_VERSION = "api.delete_device_version",
  LIST_OWN_DEVICES = "api.list_own_devices",
  LIST_ALL_DEVICES = "api.list_all_devices",
  CREATE_DEVICE = "api.create_device",
  READ_OWN_DEVICE = "api.read_own_device",
  READ_UNASSIGNED_DEVICE = "api.read_unassigned_device",
  READ_ANY_DEVICE = "api.read_any_device",
  READ_UNASSIGNED_DEVICE_BY_UID = "api.read_unassigned_device_by_uid",
  READ_ANY_DEVICE_BY_UID = "api.read_any_device_by_uid",
  ACTIVATE_UNASSIGNED_DEVICE = "api.activate_unassigned_device",
  FLASH_ANY_DEVICE = "api.flash_any_device",
  UPDATE_OWN_DEVICE = "api.update_own_device",
  UPDATE_ANY_DEVICE = "api.update_any_device",
  DELETE_DEVICE = "api.delete_device",
  LIST_OWN_DEVICE_CHANNELS = "api.list_own_device_channels",
  LIST_UNASSIGNED_DEVICE_CHANNELS = "api.list_unassigned_device_channels",
  LIST_ANY_DEVICE_CHANNELS = "api.list_any_device_channels",
  LIST_ENVIRONMENTS = "api.list_environments",
  CREATE_ENVIRONMENT = "api.create_environment",
  READ_ENVIRONMENT = "api.read_environment",
  UPDATE_ENVIRONMENT = "api.update_environment",
  DELETE_ENVIRONMENT = "api.delete_environment",
  LIST_OWN_FACILITIES = "api.list_own_facilities",
  LIST_ALL_FACILITIES = "api.list_all_facilities",
  CREATE_OWN_FACILITY = "api.create_own_facility",
  CREATE_ANY_FACILITY = "api.create_any_facility",
  READ_OWN_FACILITY = "api.read_own_facility",
  READ_ANY_FACILITY = "api.read_any_facility",
  UPDATE_OWN_FACILITY = "api.update_own_facility",
  UPDATE_ANY_FACILITY = "api.update_any_facility",
  DELETE_OWN_FACILITY = "api.delete_own_facility",
  DELETE_ANY_FACILITY = "api.delete_any_facility",
  ADD_OWN_FACILITY_TAGS = "api.add_own_facility_tags",
  ADD_ANY_FACILITY_TAGS = "api.add_any_facility_tags",
  REMOVE_OWN_FACILITY_TAGS = "api.remove_own_facility_tags",
  REMOVE_ANY_FACILITY_TAGS = "api.remove_any_facility_tags",
  LIST_OWN_FACILITY_STATIONS = "api.list_own_facility_stations",
  LIST_ANY_FACILITY_STATIONS = "api.list_any_facility_stations",
  GET_OWN_AUDIO = "api.get_own_audio",
  GET_ANY_AUDIO = "api.get_any_audio",
  GET_OWN_AVAILABILITY = "api.get_own_availability",
  GET_ANY_AVAILABILITY = "api.get_any_availability",
  GET_OWN_AVAILABILITY_RANGE = "api.get_own_availability_range",
  GET_ANY_AVAILABILITY_RANGE = "api.get_any_availability_range",
  LIST_GRAFANA_TEMPLATES = "api.list_grafana_templates",
  CREATE_GRAFANA_TEMPLATE = "api.create_grafana_template",
  READ_GRAFANA_TEMPLATE = "api.read_grafana_template",
  UPDATE_GRAFANA_TEMPLATE = "api.update_grafana_template",
  DELETE_GRAFANA_TEMPLATE = "api.delete_grafana_template",
  LIST_OWN_LABEL_CATEGORIES = "api.list_own_label_categories",
  LIST_GLOBAL_LABEL_CATEGORIES = "api.list_global_label_categories",
  LIST_ALL_LABEL_CATEGORIES = "api.list_all_label_categories",
  CREATE_OWN_LABEL_CATEGORY = "api.create_own_label_category",
  CREATE_GLOBAL_LABEL_CATEGORY = "api.create_global_label_category",
  CREATE_ANY_LABEL_CATEGORY = "api.create_any_label_category",
  READ_OWN_LABEL_CATEGORY = "api.read_own_label_category",
  READ_GLOBAL_LABEL_CATEGORY = "api.read_global_label_category",
  READ_ANY_LABEL_CATEGORY = "api.read_any_label_category",
  UPDATE_OWN_LABEL_CATEGORY = "api.update_own_label_category",
  UPDATE_GLOBAL_LABEL_CATEGORY = "api.update_global_label_category",
  UPDATE_ANY_LABEL_CATEGORY = "api.update_any_label_category",
  DELETE_OWN_LABEL_CATEGORY = "api.delete_own_label_category",
  DELETE_GLOBAL_LABEL_CATEGORY = "api.delete_global_label_category",
  DELETE_ANY_LABEL_CATEGORY = "api.delete_any_label_category",
  LIST_OWN_LABEL_SUBCATEGORIES = "api.list_own_label_subcategories",
  LIST_GLOBAL_LABEL_SUBCATEGORIES = "api.list_global_label_subcategories",
  LIST_ALL_LABEL_SUBCATEGORIES = "api.list_all_label_subcategories",
  CREATE_OWN_LABEL_SUBCATEGORY = "api.create_own_label_subcategory",
  CREATE_GLOBAL_LABEL_SUBCATEGORY = "api.create_global_label_subcategory",
  CREATE_ANY_LABEL_SUBCATEGORY = "api.create_any_label_subcategory",
  READ_OWN_LABEL_SUBCATEGORY = "api.read_own_label_subcategory",
  READ_GLOBAL_LABEL_SUBCATEGORY = "api.read_global_label_subcategory",
  READ_ANY_LABEL_SUBCATEGORY = "api.read_any_label_subcategory",
  UPDATE_OWN_LABEL_SUBCATEGORY = "api.update_own_label_subcategory",
  UPDATE_GLOBAL_LABEL_SUBCATEGORY = "api.update_global_label_subcategory",
  UPDATE_ANY_LABEL_SUBCATEGORY = "api.update_any_label_subcategory",
  DELETE_OWN_LABEL_SUBCATEGORY = "api.delete_own_label_subcategory",
  DELETE_GLOBAL_LABEL_SUBCATEGORY = "api.delete_global_label_subcategory",
  DELETE_ANY_LABEL_SUBCATEGORY = "api.delete_any_label_subcategory",
  LIST_LABEL_SOURCES = "api.list_label_sources",
  CREATE_LABEL_SOURCE = "api.create_label_source",
  READ_LABEL_SOURCE = "api.read_label_source",
  UPDATE_LABEL_SOURCE = "api.update_label_source",
  DELETE_LABEL_SOURCE = "api.delete_label_source",
  LIST_OWN_LABELS = "api.list_own_labels",
  LIST_ALL_LABELS = "api.list_all_labels",
  CREATE_OWN_LABEL = "api.create_own_label",
  CREATE_ANY_LABEL = "api.create_any_label",
  READ_OWN_LABEL = "api.read_own_label",
  READ_ANY_LABEL = "api.read_any_label",
  UPDATE_OWN_LABEL = "api.update_own_label",
  UPDATE_ANY_LABEL = "api.update_any_label",
  DELETE_OWN_LABEL = "api.delete_own_label",
  DELETE_ANY_LABEL = "api.delete_any_label",
  LIST_OWN_MACHINE_CATEGORIES = "api.list_own_machine_categories",
  LIST_GLOBAL_MACHINE_CATEGORIES = "api.list_global_machine_categories",
  LIST_ALL_MACHINE_CATEGORIES = "api.list_all_machine_categories",
  CREATE_OWN_MACHINE_CATEGORY = "api.create_own_machine_category",
  CREATE_GLOBAL_MACHINE_CATEGORY = "api.create_global_machine_category",
  CREATE_ANY_MACHINE_CATEGORY = "api.create_any_machine_category",
  READ_OWN_MACHINE_CATEGORY = "api.read_own_machine_category",
  READ_GLOBAL_MACHINE_CATEGORY = "api.read_global_machine_category",
  READ_ANY_MACHINE_CATEGORY = "api.read_any_machine_category",
  UPDATE_OWN_MACHINE_CATEGORY = "api.update_own_machine_category",
  UPDATE_GLOBAL_MACHINE_CATEGORY = "api.update_global_machine_category",
  UPDATE_ANY_MACHINE_CATEGORY = "api.update_any_machine_category",
  DELETE_OWN_MACHINE_CATEGORY = "api.delete_own_machine_category",
  DELETE_GLOBAL_MACHINE_CATEGORY = "api.delete_global_machine_category",
  DELETE_ANY_MACHINE_CATEGORY = "api.delete_any_machine_category",
  LIST_OWN_MACHINE_SUBCATEGORIES = "api.list_own_machine_subcategories",
  LIST_GLOBAL_MACHINE_SUBCATEGORIES = "api.list_global_machine_subcategories",
  LIST_ALL_MACHINE_SUBCATEGORIES = "api.list_all_machine_subcategories",
  CREATE_OWN_MACHINE_SUBCATEGORY = "api.create_own_machine_subcategory",
  CREATE_GLOBAL_MACHINE_SUBCATEGORY = "api.create_global_machine_subcategory",
  CREATE_ANY_MACHINE_SUBCATEGORY = "api.create_any_machine_subcategory",
  READ_OWN_MACHINE_SUBCATEGORY = "api.read_own_machine_subcategory",
  READ_GLOBAL_MACHINE_SUBCATEGORY = "api.read_global_machine_subcategory",
  READ_ANY_MACHINE_SUBCATEGORY = "api.read_any_machine_subcategory",
  UPDATE_OWN_MACHINE_SUBCATEGORY = "api.update_own_machine_subcategory",
  UPDATE_GLOBAL_MACHINE_SUBCATEGORY = "api.update_global_machine_subcategory",
  UPDATE_ANY_MACHINE_SUBCATEGORY = "api.update_any_machine_subcategory",
  DELETE_OWN_MACHINE_SUBCATEGORY = "api.delete_own_machine_subcategory",
  DELETE_GLOBAL_MACHINE_SUBCATEGORY = "api.delete_global_machine_subcategory",
  DELETE_ANY_MACHINE_SUBCATEGORY = "api.delete_any_machine_subcategory",
  LIST_OWN_MACHINE_SUBCATEGORY_PLACEMENT_TYPES = "api.list_own_machine_subcategory_placement_types",
  LIST_GLOBAL_MACHINE_SUBCATEGORY_PLACEMENT_TYPES = "api.list_global_machine_subcategory_placement_types",
  LIST_ANY_MACHINE_SUBCATEGORY_PLACEMENT_TYPES = "api.list_any_machine_subcategory_placement_types",
  LIST_OWN_MACHINES = "api.list_own_machines",
  LIST_ALL_MACHINES = "api.list_all_machines",
  CREATE_OWN_MACHINE = "api.create_own_machine",
  CREATE_ANY_MACHINE = "api.create_any_machine",
  READ_OWN_MACHINE = "api.read_own_machine",
  READ_ANY_MACHINE = "api.read_any_machine",
  UPDATE_OWN_MACHINE = "api.update_own_machine",
  UPDATE_ANY_MACHINE = "api.update_any_machine",
  DELETE_OWN_MACHINE = "api.delete_own_machine",
  DELETE_ANY_MACHINE = "api.delete_any_machine",
  ONBOARD_OWN_MACHINE = "api.onboard_own_machine",
  ONBOARD_ANY_MACHINE = "api.onboard_any_machine",
  LIST_OWN_MACHINE_DEVICES = "api.list_own_machine_devices",
  LIST_ANY_MACHINE_DEVICES = "api.list_any_machine_devices",
  LIST_OWN_MACHINE_LABELS = "api.list_own_machine_labels",
  LIST_ANY_MACHINE_LABELS = "api.list_any_machine_labels",
  LIST_OWN_MACHINE_PLACEMENTS = "api.list_own_machine_placements",
  LIST_ANY_MACHINE_PLACEMENTS = "api.list_any_machine_placements",
  LIST_MODEL_DEPLOYMENTS = "api.list_model_deployments",
  CREATE_MODEL_DEPLOYMENT = "api.create_model_deployment",
  READ_MODEL_DEPLOYMENT = "api.read_model_deployment",
  UPDATE_MODEL_DEPLOYMENT = "api.update_model_deployment",
  DELETE_MODEL_DEPLOYMENT = "api.delete_model_deployment",
  CREATE_MODEL_DEPLOYMENT_RESULTS = "api.create_model_deployment_results",
  LIST_MODEL_RESULT_TYPES = "api.list_model_result_types",
  CREATE_MODEL_RESULT_TYPE = "api.create_model_result_type",
  READ_MODEL_RESULT_TYPE = "api.read_model_result_type",
  UPDATE_MODEL_RESULT_TYPE = "api.update_model_result_type",
  DELETE_MODEL_RESULT_TYPE = "api.delete_model_result_type",
  LIST_OWN_MODEL_RESULTS_MACHINES = "api.list_own_model_results_machines",
  LIST_ALL_MODEL_RESULT_MACHINES = "api.list_all_model_result_machines",
  CREATE_OWN_MODEL_RESULT_MACHINE = "api.create_own_model_result_machine",
  CREATE_ANY_MODEL_RESULT_MACHINE = "api.create_any_model_result_machine",
  READ_OWN_MODEL_RESULT_MACHINE = "api.read_own_model_result_machine",
  READ_ANY_MODEL_RESULT_MACHINE = "api.read_any_model_result_machine",
  UPDATE_OWN_MODEL_RESULT_MACHINE = "api.update_own_model_result_machine",
  UPDATE_ANY_MODEL_RESULT_MACHINE = "api.update_any_model_result_machine",
  DELETE_OWN_MODEL_RESULT_MACHINE = "api.delete_own_model_result_machine",
  DELETE_ANY_MODEL_RESULT_MACHINE = "api.delete_any_model_result_machine",
  LIST_OWN_MODEL_RESULTS_PLACEMENTS = "api.list_own_model_results_placements",
  LIST_ALL_MODEL_RESULTS_PLACEMENTS = "api.list_all_model_results_placements",
  CREATE_OWN_MODEL_RESULT_PLACEMENT = "api.create_own_model_result_placement",
  CREATE_ANY_MODEL_RESULT_PLACEMENT = "api.create_any_model_result_placement",
  READ_OWN_MODEL_RESULT_PLACEMENT = "api.read_own_model_result_placement",
  READ_ANY_MODEL_RESULT_PLACEMENT = "api.read_any_model_result_placement",
  UPDATE_OWN_MODEL_RESULT_PLACEMENT = "api.update_own_model_result_placement",
  UPDATE_ANY_MODEL_RESULT_PLACEMENT = "api.update_any_model_result_placement",
  DELETE_OWN_MODEL_RESULT_PLACEMENT = "api.delete_own_model_result_placement",
  DELETE_ANY_MODEL_RESULT_PLACEMENT = "api.delete_any_model_result_placement",
  LIST_OWN_PLACEMENT_TYPES = "api.list_own_placement_types",
  LIST_GLOBAL_PLACEMENT_TYPES = "api.list_global_placement_types",
  LIST_ALL_PLACEMENT_TYPES = "api.list_all_placement_types",
  CREATE_OWN_PLACEMENT_TYPE = "api.create_own_placement_type",
  CREATE_GLOBAL_PLACEMENT_TYPE = "api.create_global_placement_type",
  CREATE_ANY_PLACEMENT_TYPE = "api.create_any_placement_type",
  READ_OWN_PLACEMENT_TYPE = "api.read_own_placement_type",
  READ_GLOBAL_PLACEMENT_TYPE = "api.read_global_placement_type",
  READ_ANY_PLACEMENT_TYPE = "api.read_any_placement_type",
  UPDATE_OWN_PLACEMENT_TYPE = "api.update_own_placement_type",
  UPDATE_GLOBAL_PLACEMENT_TYPE = "api.update_global_placement_type",
  UPDATE_ANY_PLACEMENT_TYPE = "api.update_any_placement_type",
  DELETE_OWN_PLACEMENT_TYPE = "api.delete_own_placement_type",
  DELETE_GLOBAL_PLACEMENT_TYPE = "api.delete_global_placement_type",
  DELETE_ANY_PLACEMENT_TYPE = "api.delete_any_placement_type",
  LIST_OWN_PLACEMENTS = "api.list_own_placements",
  LIST_ALL_PLACEMENTS = "api.list_all_placements",
  CREATE_OWN_PLACEMENT = "api.create_own_placement",
  CREATE_ANY_PLACEMENT = "api.create_any_placement",
  READ_OWN_PLACEMENT = "api.read_own_placement",
  READ_ANY_PLACEMENT = "api.read_any_placement",
  UPDATE_OWN_PLACEMENT = "api.update_own_placement",
  UPDATE_ANY_PLACEMENT = "api.update_any_placement",
  DELETE_OWN_PLACEMENT = "api.delete_own_placement",
  DELETE_ANY_PLACEMENT = "api.delete_any_placement",
  LIST_PROJECT_TYPES = "api.list_project_types",
  CREATE_PROJECT_TYPE = "api.create_project_type",
  READ_PROJECT_TYPE = "api.read_project_type",
  UPDATE_PROJECT_TYPE = "api.update_project_type",
  DELETE_PROJECT_TYPE = "api.delete_project_type",
  LIST_OWN_PROJECTS = "api.list_own_projects",
  LIST_ALL_PROJECTS = "api.list_all_projects",
  CREATE_OWN_PROJECT = "api.create_own_project",
  CREATE_ANY_PROJECT = "api.create_any_project",
  READ_OWN_PROJECT = "api.read_own_project",
  READ_ANY_PROJECT = "api.read_any_project",
  UPDATE_OWN_PROJECT = "api.update_own_project",
  UPDATE_ANY_PROJECT = "api.update_any_project",
  DELETE_OWN_PROJECT = "api.delete_own_project",
  DELETE_ANY_PROJECT = "api.delete_any_project",
  LIST_OWN_PROJECT_FACILITIES = "api.list_own_project_facilities",
  LIST_ANY_PROJECT_FACILITIES = "api.list_any_project_facilities",
  LIST_OWN_PROJECT_MACHINES = "api.list_own_project_machines",
  LIST_ANY_PROJECT_MACHINES = "api.list_any_project_machines",
  LIST_SENSOR_TYPES = "api.list_sensor_types",
  CREATE_SENSOR_TYPE = "api.create_sensor_type",
  READ_SENSOR_TYPE = "api.read_sensor_type",
  UPDATE_SENSOR_TYPE = "api.update_sensor_type",
  DELETE_SENSOR_TYPE = "api.delete_sensor_type",
  LIST_OWN_STATIONS = "api.list_own_stations",
  LIST_ALL_STATIONS = "api.list_all_stations",
  CREATE_OWN_STATION = "api.create_own_station",
  CREATE_ANY_STATION = "api.create_any_station",
  READ_OWN_STATION = "api.read_own_station",
  READ_ANY_STATION = "api.read_any_station",
  UPDATE_OWN_STATION = "api.update_own_station",
  UPDATE_ANY_STATION = "api.update_any_station",
  DELETE_OWN_STATION = "api.delete_own_station",
  DELETE_ANY_STATION = "api.delete_any_station",
  LIST_OWN_STATION_MACHINES = "api.list_own_station_machines",
  LIST_ANY_STATION_MACHINES = "api.list_any_station_machines",
  LIST_OWN_USERS = "api.list_own_users",
  LIST_ALL_USERS = "api.list_all_users",
  CREATE_OWN_USER = "api.create_own_user",
  CREATE_ANY_USER = "api.create_any_user",
  READ_OWN_USER = "api.read_own_user",
  READ_ANY_USER = "api.read_any_user",
  UPDATE_OWN_USER = "api.update_own_user",
  UPDATE_ANY_USER = "api.update_any_user",
  LIST_OWN_USER_PROJECTS = "api.list_own_user_projects",
  LIST_ANY_USER_PROJECTS = "api.list_any_user_projects",
  RESEND_OWN_USER_INVITATION = "api.resend_own_user_invitation",
  RESEND_ANY_USER_INVITATION = "api.resend_any_user_invitation",
  LIST_OWN_MACHINE_CATEGORY_SUBCATEGORIES = "api.list_own_machine_category_subcategories",
  LIST_GLOBAL_MACHINE_CATEGORY_SUBCATEGORIES = "api.list_global_machine_category_subcategories",
  LIST_ANY_MACHINE_CATEGORY_SUBCATEGORIES = "api.list_any_machine_category_subcategories",
  READ_OWN_MACHINE_SUBCATEGORY_CATEGORY = "api.read_own_machine_subcategory_category",
  READ_GLOBAL_MACHINE_SUBCATEGORY_CATEGORY = "api.read_global_machine_subcategory_category",
  READ_ANY_MACHINE_SUBCATEGORY_CATEGORY = "api.read_any_machine_subcategory_category",
  LIST_OWN_ALERTS = "api.list_own_alerts",
  LIST_ALL_ALERTS = "api.list_all_alerts",
  CREATE_OWN_ALERT = "api.create_own_alert",
  CREATE_ANY_ALERT = "api.create_any_alert",
  READ_OWN_ALERT = "api.read_own_alert",
  READ_ANY_ALERT = "api.read_any_alert",
  UPDATE_OWN_ALERT = "api.update_own_alert",
  UPDATE_ANY_ALERT = "api.update_any_alert",
  DELETE_OWN_ALERT = "api.delete_own_alert",
  DELETE_ANY_ALERT = "api.delete_any_alert",
  LIST_SERVICES = "api.list_services",
  CREATE_SERVICE = "api.create_service",
  READ_SERVICE = "api.read_service",
  UPDATE_SERVICE = "api.update_service",
  DELETE_SERVICE = "api.delete_service",
  MOVE_OWN_DEVICE = "api.move_own_device",
  MOVE_ANY_DEVICE = "api.move_any_device",
  DEACTIVATE_ANY_DEVICE = "api.deactivate_any_device",
  DEACTIVATE_OWN_DEVICE = "api.deactivate_own_device",
  EDIT_ANY_EVENT_PAIR = "api.edit_any_event_pair",
  EDIT_OWN_EVENT_PAIR = "api.edit_own_event_pair",
  VIEW_MACHINE_STATUS = "api.view_viewmachinestatus",
  TRAIN_OWN_MACHINES = "api.train_own_machines",
  TRAIN_ANY_MACHINES = "api.train_any_machines",
  DEPLOY_OWN_CUSTOM_MODELS = "api.deploy_own_custom_models",
  DEPLOY_ANY_CUSTOM_MODELS = "api.deploy_any_custom_models",
  CREATE_ANY_EVENT = "api.create_any_event",
  CREATE_OWN_EVENT = "api.create_own_event",
  CREATE_ANY_EVENT_COMMENT = "api.create_any_event_comment",
  CREATE_OWN_EVENT_COMMENT = "api.create_own_event_comment",
  UPDATE_OWN_EVENT_COMMENT = "api.update_own_event_comment",
  UPDATE_ANY_EVENT_COMMENT = "api.update_any_event_comment",
  LIST_OWN_MONTHLY_REPORTS = "api.list_own_monthly_reports",
  LIST_ALL_MONTHLY_REPORTS = "api.list_all_monthly_reports",
  CREATE_OWN_MONTHLY_REPORT = "api.create_own_monthly_report",
  CREATE_ANY_MONTHLY_REPORT = "api.create_any_monthly_report",
  READ_OWN_MONTHLY_REPORT = "api.read_own_monthly_report",
  READ_ANY_MONTHLY_REPORT = "api.read_any_monthly_report",
  UPDATE_OWN_MONTHLY_REPORT = "api.update_own_monthly_report",
  UPDATE_ANY_MONTHLY_REPORT = "api.update_any_monthly_report",
  DELETE_OWN_MONTHLY_REPORT = "api.delete_own_monthly_report",
  DELETE_ANY_MONTHLY_REPORT = "api.delete_any_monthly_report",
  LIST_OWN_TEMPORARY_TOKENS = "api.list_own_temporary_tokens",
  LIST_ALL_TEMPORARY_TOKENS = "api.list_all_temporary_tokens",
  CREATE_OWN_TEMPORARY_TOKEN = "api.create_own_temporary_token",
  CREATE_ANY_TEMPORARY_TOKEN = "api.create_any_temporary_token",
  READ_OWN_TEMPORARY_TOKEN = "api.read_own_temporary_token",
  READ_ANY_TEMPORARY_TOKEN = "api.read_any_temporary_token",
  UPDATE_OWN_TEMPORARY_TOKEN = "api.update_own_temporary_token",
  UPDATE_ANY_TEMPORARY_TOKEN = "api.update_any_temporary_token",
  DELETE_OWN_TEMPORARY_TOKEN = "api.delete_own_temporary_token",
  DELETE_ANY_TEMPORARY_TOKEN = "api.delete_any_temporary_token",
  MASS_ACKNOWLEDGE_OWN_MACHINE_EVENTS = "api.mass_acknowledge_own_machine_events",
  MASS_ACKNOWLEDGE_ANY_MACHINE_EVENTS = "api.mass_acknowledge_any_machine_events",
  UPDATE_OWN_EVENT = "api.update_own_event",
  UPDATE_ANY_EVENT = "api.update_any_event",
  CREATE_OWN_EVENT_PAIR = "api.create_own_event_pair",
  CREATE_ANY_EVENT_PAIR = "api.create_any_event_pair",
  DELETE_OWN_EVENT = "api.delete_own_event",
  DELETE_ANY_EVENT = "api.delete_any_event",
}
export default EPermissions;
