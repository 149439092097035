import { IMachineList } from "types/machine";
import { IPlacementList } from "types/placement";
import { IConnectableMachine } from "types/machine";
import { ISensorType } from "types/sensorType";
import { TRecordingPlans } from "types/device";
import { IChannelList } from "types/channels";

export enum EActions {
  setMachine = "setMachine",
  setPlacement = "setPlacement",
  setChannels = "setChannels",
  setReset = "setReset",
  setDisconnected = "setDisconnected",
  setInitMachines = "setInitMachines",
  placements = "placements",
  connectableMachines = "connectableMachines",
  connectableLoading = "connectableLoading",
  setMachines = "setMachines",
  setLoading = "setLoading",
  setData = "setData",
  setSensorTypes = "setSensorTypes",
  setInitialData = "setInitialData",
  setSensorType = "setSensorType",
  setEnabled = "setEnabled",
  disconnectPlacement = "disconnectPlacement",
  resetChanges = "resetChanges",
  setRecordingPlans = "setRecordingPlans",
  setRecordingPlan = "setRecordingPlan",
  setThreshold = "setThreshold",
  setGain = "setGain",
  setISOGroup = "setISOGroup",
  setRPM = "setRPM",
  setRPMAuto = "setRPMAuto",
  setBandAlerting = "setBandAlerting",
  setPlacementRecordingPlan = "setPlacementRecordingPlan",
}

export type OPlacementsList = Omit<
  IPlacementList,
  "name" | "type" | "channel" | "panelId" | "qrCode" | "gain" | "customName"
>;

export type OChannelsList = Omit<IChannelList, "device" | "state" | "note">;

export interface DeviceDetailState {
  disconnected?: number | null;
  initMachines?: number[] | null;
  placements?: OPlacementsList[];
  connectableMachines?: IConnectableMachine[];
  machinesLoading?: string;
  machines?: IMachineList[];
  loading?: boolean;
  channels?: OChannelsList[];
  sensorTypes?: ISensorType[];
  sensor_type?: number;
  controlObject?: { [key: number]: TData };
  recordingPlans?: TRecordingPlans[];
  recordingPlan?: string;
  initialPlacements?: OPlacementsList[];
  initialChannels?: OChannelsList[];
}

export type TData = {
  id: number;
  placement: number | null;
  number: number;
  machine: number | null;
  sensor_type: number;
  running_threshold: number;
  gain: number;
  is_enabled: boolean;
  rpm: number | null;
  is_rpm_automatically_detected: boolean;
  band_alerting: string;
  recording_plan: string | null;
};

export interface DevicesDetailAction {
  type: typeof EActions;
  payload: {
    id: number | null;
    placementId: number | null;
    channelId: number;
    connectableMachines: IConnectableMachine[];
    machineId: number;
    initMachines: number[] | null;
    machines: IMachineList[];
    placements: IPlacementList[];
    machinesLoading: string;
    loading: boolean;
    data: TData[];
    sensorTypes: ISensorType[];
    sensor_type: number;
    is_enabled: boolean;
    initialData: {
      channels: IChannelList[];
      placements: IPlacementList[];
      machines: IMachineList[];
      sensors: ISensorType[];
    };
    recordingPlans: TRecordingPlans[];
    recordingPlan: string;
    running_threshold: number;
    gain: number;
    iso_group: number | null;
    rpm: number | null;
    is_rpm_automatically_detected: boolean;
    band_alerting: string;
    recording_plan: string | null;
  };
}

export type TReducers = {
  [key: string]: (
    p: DevicesDetailAction,
    s: DeviceDetailState
  ) => DeviceDetailState;
};
