import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store";
import { IPlacementList } from "types/placement";

export type IFailure = {
  id: (number | null)[];
  toRemove?: boolean;
  event: number;
  placement: number | string;
  placementType?: number | string;
  other_failure: (string | null)[];
  failure: (number | null)[];
  note: (string | null)[];
};

export const useFailureTypesSelect = createSelector(
  (state: AppState) => state.machineDetail.placements,
  (state: AppState) => state.eventModal.newFailures,
  (placements, newFailures) => {
    const placementOptions =
      placements.placements?.results?.map((placement: IPlacementList) => ({
        value: placement.id,
        text: placement.customName ?? placement.name,
      })) ?? [];

    const placementTypes: {
      placement: IPlacementList["id"];
      placementType: IPlacementList["type"];
    }[] =
      placements.placements?.results?.map((placement: IPlacementList) => ({
        placement: placement.id,
        placementType: placement.type,
      })) ?? [];

    return {
      placements: placementOptions,
      failures: newFailures,
      placementTypes,
    };
  }
);
