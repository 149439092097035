import ChannelName from "./channelName";
import MachineTableSelect from "./machineTableSelect";
import PlacementTableSelect from "./placementTableSelect";
import Box from "@mui/material/Box";
import { CellProps } from "react-table";
import MachineRedirect from "./redirect";
import ResolveSwitch from "./resolveSwitch";
import Gain from "./gain";
import RecordingPlanSelect from "./recordingPlanSelect";
import DisconnectButton from "./disconnectButton";

export const MobileCell = <T extends Object>(props: CellProps<T>) => (
  <>
    <Box mt={2} mb={2}>
      <ChannelName {...props} />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      mt={2}
      mb={2}
      textOverflow="ellipsis"
    >
      <MachineRedirect {...props} /> <MachineTableSelect {...props} />
    </Box>
    <Box mt={2} mb={2}>
      <PlacementTableSelect {...props} />
    </Box>
    <Box mt={2} mb={2}>
      <RecordingPlanSelect
        cell={{ value: { fullWidth: true, ...props.cell.value } }}
      />
    </Box>
    {!!props.cell.value.allowSettingGain && (
      <Box display="flex" mt={2} mb={2}>
        <Gain {...props} />
      </Box>
    )}
    <Box display="flex" mt={2} mb={2}>
      <span style={{ paddingRight: 10, position: "relative", top: 4 }}>
        {props.cell.value.t("machine.detail.placementsList.table.availability")}
      </span>
      <ResolveSwitch {...props} />
    </Box>
    <Box mt={2} mb={2}>
      <DisconnectButton
        cell={{ value: { alignLeft: true, ...props.cell.value } }}
      />
    </Box>
  </>
);
