import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { EActions, ICPAction, ICPParams } from "./types";
import { AppState } from "store";
import { prepareActions } from "store/helpers";
import getCompanies from "api/handlers/company/getCompanies";
import getProjects from "api/handlers/project/getProjects";
import patchUserHandler from "api/handlers/user/patchUser";
import i18n from "localization";
import { queryCache } from "react-query";
import { userKey } from "shared/uniqueQueryKeys";
import { enqueueSnackbar } from "notistack";

export const path = "companiesAndProjects";

const actionsData = [
  [EActions.setCPPage, "pageIndex"],
  [EActions.fetchCPRequest],
  [EActions.fetchCPFail, "error"],
  [EActions.fetchCPSuccess, "CPData"],
  [EActions.setCPResetList],
  [EActions.setActiveCompanies, "activeCompanies"],
  [EActions.setActiveProjects, "activeProjects"],
  [
    EActions.setOriginalActiveCP,
    "originalActiveCompanies",
    "originalActiveProjects",
  ],
  [EActions.discardChanges, "discardChanges"],
];

const actions = prepareActions<ICPAction, EActions>(actionsData, path);
export default actions;

export const enqueError =
  (err: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    enqueueSnackbar(
      i18n.t("form.companiesProjects.message.error", {
        error: err && err.message,
      })
    );
  };

export const fetchCP =
  ({ filters, pageIndex }: ICPParams) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCPRequest]());
    const { company, q } = filters || {};
    try {
      const companies = await getCompanies({
        pageSize: 10,
        page: pageIndex + 1,
        orderBy: undefined,
        q,
        ids: company?.length ? company : undefined,
      });
      const projects = await getProjects({
        companyIds: companies?.results.map((item: any) => item.id),
        pageSize: 9999,
      });
      const CPResults = companies?.results.map((company: any) => {
        return {
          id: company.id,
          name: company.name,
          users: company.users,
          projects: projects?.results
            .filter((project: any) => project.company === company.id)
            .map((project: any) => {
              return {
                id: project.id,
                name: project.name,
                users: project.users,
              };
            }),
        };
      });
      dispatch(
        actions[EActions.fetchCPSuccess]({
          count: companies?.count,
          results: CPResults,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchCPFail](error));
    }
  };

export const refetchCPSilent =
  (userId: number) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { q, filters } = getState().userDetailFilter;
    const { pageIndex } = getState().companiesAndProjects;
    dispatch(actions[EActions.fetchCPRequest]());
    try {
      queryCache.refetchQueries([userKey, userId.toString()]);
      const companies = await getCompanies({
        pageSize: 10,
        page: pageIndex + 1,
        orderBy: undefined,
        q,
        ids: filters?.length ? filters : undefined,
      });
      const projects = await getProjects({
        companyIds: companies?.results.map((item: any) => item.id),
        pageSize: 9999,
      });
      const CPResults = companies?.results.map((company: any) => {
        return {
          id: company.id,
          name: company.name,
          users: company.users,
          projects: projects?.results
            .filter((project: any) => project.company === company.id)
            .map((project: any) => {
              return {
                id: project.id,
                name: project.name,
                users: project.users,
              };
            }),
        };
      });
      dispatch(
        actions[EActions.fetchCPSuccess]({
          count: companies?.count,
          results: CPResults,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchCPFail](error));
    }
  };

export const patchUserAction =
  (userId: number, companies?: number[], projects?: number[]) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { activeCompanies, activeProjects } = getState().companiesAndProjects;
    try {
      const patchedUser = await patchUserHandler({
        userId,
        companies,
        projects,
      });
      if (patchedUser) {
        dispatch(
          actions[EActions.setOriginalActiveCP](activeCompanies, activeProjects)
        );
      }
      enqueueSnackbar(i18n.t("users.detail.message.successUpdate"));
      dispatch(refetchCPSilent(patchedUser?.id));
    } catch (error) {
      dispatch(enqueError(error));
    }
  };
