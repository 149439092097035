import React from "react";
import { Column, CellProps } from "react-table";
import TinyCompoundCell from "./tinyCompoundCell";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import ActionsCell from "components/users/list/actions/cell";
import BelongsToProject from "components/users/list/cells/belongsToProject";
import { getTranslatedRole } from "shared/roles";

export const createTinyColumns = (
  t: TFunction,
  projectId: number
): Column<IUserList>[] => [
  {
    id: "is_project",
    Header: "",
    accessor: ({ projects }) => ({
      projects,
    }),
    Cell: BelongsToProject,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    Header: "",
    id: "compoundCell",
    accessor: (originalRow: any) => {
      return {
        name: [originalRow.firstName, originalRow.lastName].join(" "),
        role: getTranslatedRole(t, originalRow.role),
        label: originalRow.email,
        status: originalRow.status,
      };
    },
    Cell: TinyCompoundCell,
    custom: {
      hidePriority: 0,
    },
  },
  {
    Header: "",
    id: "actions",
    Cell: (props: CellProps<IUserList>) => {
      return <ActionsCell {...props} marginTop={2.5} projectId={projectId} />;
    },
    custom: {
      hidePriority: 0,
      width: 72,
      verticalAlignTop: true,
      margin: "1rem",
    },
  },
];
