export enum EActions {
  restorePasswordRequest = "restorePasswordRequest",
  restorePasswordSuccess = "restorePasswordSuccess",
  restorePasswordFail = "restorePasswordFail",
  restorePasswordReset = "restorePasswordReset",
}

export interface TokenState {
  error: string[];
  loading: boolean;
  emailSent: boolean;
}

export interface TokenActionTypes {
  type: typeof EActions;
  payload: TokenState;
}
