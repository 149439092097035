import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface Payload {
  status?: string;
  edge_discard_audio?: boolean;
  is_recording_enabled?: boolean;
}

export default async function patchDevice({
  id,
  payload,
}: {
  id: number;
  payload: Payload;
}) {
  const url = endpoints.devices.detail(id);

  try {
    const { data } = await axiosNeuron.patch(url, payload);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
