import React from "react";

import locale from "dayjs/locale/*";
import deLocale from "dayjs/locale/de";
import csLocale from "dayjs/locale/cs";
import enLocale from "dayjs/locale/en";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";

const localeMap: {
  [key: string]: locale.Locale;
} = {
  en: enLocale,
  cs: csLocale,
  de: deLocale,
};

type Props = {
  Component: React.ElementType;
  clearable?: boolean;
  value: any;
  onChange: any;
  id?: string;
  inputProps?: any;
  inputVariant?: string;
  disabled?: boolean;
  label?: string;
};

const PickerLocale = <T extends object>({
  Component,
  id,
  ...props
}: T & Props) => {
  const { t, i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={localeMap[i18n.language]?.name}
      localeText={{
        clearButtonLabel: t("picker.clear"),
        okButtonLabel: t("picker.ok"),
        cancelButtonLabel: t("picker.cancel"),
      }}
    >
      <Component
        id={id}
        cancelLabel={t("picker.cancel")}
        clearLabel={t("picker.clear")}
        okLabel={t("picker.ok")}
        ampm={false}
        format="DD. MMM YYYY HH:mm"
        {...props}
      />
    </LocalizationProvider>
  );
};
export default PickerLocale;
