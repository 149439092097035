import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import moment from "moment";

export async function updateLabelPosition(
  id: number,
  start: number,
  end: number,
  timezoneOffset: number,
  placements: number[]
) {
  const url = endpoints.labels.default;
  const newData = {
    start_datetime: moment.unix(start).format("YYYY-MM-DD HH:mm:ss.SSS"),
    end_datetime: moment.unix(end).format("YYYY-MM-DD HH:mm:ss.SSS"),
    placements,
  };

  const data = await axiosNeuron.patch(
    `${url}${id}/?tz=${encodeURIComponent(timezoneOffset)}`,
    newData
  );
  return data;
}
