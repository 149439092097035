import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ISimilaritySearch } from "types/eventSounds";

type IParams = {
  placement?: number;
  from?: Date | null;
  to?: Date | null;
  ps?: number;
  page?: number;
};

export default async function getSimilaritySounds(params: IParams) {
  const url = endpoints.eventSounds.similaritySounds;
  try {
    const response = await axiosNeuron.get(url, { params });
    const { data } = response;

    data.results = data.results.map((item: ISimilaritySearch) => {
      return {
        ...item,
        isPlaying: false,
      };
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
