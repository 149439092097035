import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import getFacilities from "api/handlers/facility/getFacilities";
import addFacility from "api/handlers/facility/addFacility";
import updateFacility from "api/handlers/facility/updateFacility";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";

export const path = "projectDetail/facilities";

const actionData = [
  [EActions.setPage, "pageIndex"],
  [EActions.setFacilities, "facilities"],
  [EActions.setLoading, "loading"],
  [EActions.setReloading, "reloading"],
  [EActions.setEditing, "editing"],
  [EActions.setSort, "sort"],
  [EActions.setShowMore, "showMore"],
  [EActions.setReset],
  [EActions.setModalLoading, "modalLoading"],
  [EActions.setModal, "modalOpened"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const fetchFacilities =
  ({ id, page, pageSize, orderBy }: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      const facilities = await getFacilities({
        projectIds: [id],
        page,
        pageSize,
        orderBy,
      });

      dispatch(actions[EActions.setFacilities](facilities));
    } catch (error) {
      dispatch(actions[EActions.setLoading](false));
    }
  };

export const rename =
  (id: number, name: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      await updateFacility({ id, name });
      enqueueSnackbar(i18n.t("facilities.list.table.success"));

      const { pageIndex, sort, showMore } = getState().projectDetail.facilities;

      const { project } = getState().projectDetail.project;

      dispatch(
        fetchFacilities({
          id: project.id,
          page: !showMore ? pageIndex + 1 : 1,
          pageSize: showMore ? 10 * (1 + pageIndex) : 10,
          orderBy: sort,
        })
      );
    } catch (error) {
      enqueueSnackbar(i18n.t("facilities.list.table.error"));
    }
  };

export const addFacilityAction =
  (data: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      dispatch(actions[EActions.setModalLoading](true));
      const { pageIndex, sort, showMore } = getState().projectDetail.facilities;
      const { project } = getState().projectDetail.project;

      await addFacility({ project: project.id, ...data });

      enqueueSnackbar(i18n.t("projects.detail.modal.facilities.success"));

      dispatch(actions[EActions.setModal](false));

      dispatch(
        fetchFacilities({
          id: project.id,
          page: !showMore ? pageIndex + 1 : 1,
          pageSize: showMore ? 10 * (1 + pageIndex) : 10,
          orderBy: sort,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.setModalLoading](false));
      enqueueSnackbar(i18n.t("projects.detail.modal.facilities.error"));
    }
  };
