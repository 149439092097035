import { TReducers, IActivateDeviceState, EActions } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IActivateDeviceState = {
  selectedProject: null,
  localCompanies: [],
  localProjects: [],
  loading: false,
  page: 0,
  pageCount: 0,
};

const reducer: TReducers = {
  [EActions.setPage]: ({ payload: { page } }) => ({
    page,
  }),
  [EActions.setSelectedProject]: ({ payload: { selectedProject } }) => ({
    selectedProject,
  }),
  [EActions.setLoading]: ({ payload: { loading } }) => ({ loading }),
  [EActions.setLocalCompanies]: (
    { payload: { localCompanies, pageCount } },
    state
  ) => ({
    pageCount: Math.ceil(pageCount / 10) || state.pageCount,
    localCompanies,
  }),
  [EActions.setLocalProjects]: ({ payload: { localProjects } }) => ({
    localProjects,
  }),
  [EActions.setReset]: () => ({
    ...initialState,
  }),
};

export default createReducer(path, initialState, reducer);
