import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "auto",
  },
}));

type Props = {
  children?: string | JSX.Element;
};

const FormModalFooter = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default FormModalFooter;
