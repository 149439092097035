import * as scale from "d3-scale-chromatic";

export function clear(el) {
  if (el) {
    const context = el.getContext("2d");
    context.clearRect(0, 0, el.width, el.height);
  }
}

export function fill(el, color) {
  if (el) {
    const context = el.getContext("2d");
    context.fillStyle = color;
    context.fillRect(0, 0, el.width, el.height);
  }
}

export function drawWave(el, peaks, range) {
  if (el) {
    const context = el.getContext("2d");
    context.fillStyle = "#a61d3c";
    const multiplier = el.height / range;
    const half = el.height / 2;
    for (let i = 0, l = peaks.length; i < l; i += 2) {
      let max = peaks[i];
      let min = peaks[i + 1];
      context.fillRect(i / 2, half - half * max, 1, (max - min) * multiplier);
    }
  }
}

export function drawEnergy(el, peaks, range) {
  if (el) {
    const context = el.getContext("2d");
    context.beginPath();
    context.clearRect(0, 0, el.width, el.height);
    context.strokeStyle = "#a61d3c";
    const multiplier = el.height / range;
    const half = el.height / 2;
    context.moveTo(0, half * 2 - half * 2 * peaks[0]);
    for (let i = 2, l = peaks.length; i < l; i += 2) {
      let max = peaks[i];
      context.lineTo(i / 2, half * 2 - half * 2 * max);
      context.moveTo(i / 2, half * 2 - half * 2 * max);
    }
    context.stroke();
  }
}

export function drawModelResults(el, values, range) {
  if (el) {
    const context = el.getContext("2d");
    const offset = 0.05;
    const multiplier = (el.height / range) * 0.9;
    const rmsValues = values
      .map((i) => i.rmsVelocity)
      .filter((i) => i !== undefined);
    const envelopeEnergyValues = values
      .map((i) => i.envelopeEnergy)
      .filter((i) => i !== undefined);
    const maxRms = rmsValues.length ? Math.max(...rmsValues) : null;
    const maxEnvelopeEnergy = envelopeEnergyValues.length
      ? Math.max(...envelopeEnergyValues)
      : null;
    const rmsMultiplier = maxRms ? multiplier / maxRms : multiplier;
    const envelopeEnergyMultiplier = maxEnvelopeEnergy
      ? multiplier / maxEnvelopeEnergy
      : multiplier;
    for (let i = 0, l = values.length, x = 0; i < l; ++i) {
      if (values[i].loudness !== null) {
        context.fillStyle = "#00ff00";
        context.fillRect(
          x,
          el.height - values[i].loudness * multiplier + offset,
          values[i].length,
          1.5
        );
      }
      if (values[i].anomaly !== null) {
        context.fillStyle = "#ffff00";
        context.fillRect(
          x,
          el.height - values[i].anomaly * multiplier + offset,
          values[i].length,
          1.5
        );
      }
      if (values[i].envelopeEnergy !== null) {
        context.fillStyle = "#ff0000";
        context.fillRect(
          x,
          el.height -
            values[i].envelopeEnergy * envelopeEnergyMultiplier +
            offset,
          values[i].length,
          1.5
        );
      }
      if (values[i].rmsVelocity !== null) {
        context.fillStyle = "#6666ff";
        context.fillRect(
          x,
          el.height - values[i].rmsVelocity * rmsMultiplier + offset,
          values[i].length,
          1.5
        );
      }
      x += values[i].length;
    }
  }
}

export function drawFft(el, fft, range) {
  if (el) {
    const context = el.getContext("2d");
    context.fillStyle = "#a61d3c";
    const width = el.width;
    const height = el.height;
    const multiplierX = width / fft.length;
    const multiplierY = height / range;
    context.beginPath();
    for (let i = 0, l = fft.length; i < l; ++i) {
      let value = height - fft[i] * multiplierY;
      const x = i * multiplierX;
      if (i) context.lineTo(x, value);
      context.moveTo(x, value);
    }
    context.stroke();
  }
}

export const FFT = function (bufferSize, sampleRate, windowFunc, alpha) {
  this.bufferSize = bufferSize;
  this.sampleRate = sampleRate;
  this.bandwidth = (2 / bufferSize) * (sampleRate / 2);

  this.sinTable = new Float32Array(bufferSize);
  this.cosTable = new Float32Array(bufferSize);
  this.windowValues = new Float32Array(bufferSize);
  this.reverseTable = new Uint32Array(bufferSize);

  this.peakBand = 0;
  this.peak = 0;

  switch (windowFunc) {
    case "bartlett":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          (2 / (bufferSize - 1)) *
          ((bufferSize - 1) / 2 - Math.abs(i - (bufferSize - 1) / 2));
      }
      break;
    case "bartlettHann":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          0.62 -
          0.48 * Math.abs(i / (bufferSize - 1) - 0.5) -
          0.38 * Math.cos((Math.PI * 2 * i) / (bufferSize - 1));
      }
      break;
    case "blackman":
      alpha = alpha || 0.16;
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          (1 - alpha) / 2 -
          0.5 * Math.cos((Math.PI * 2 * i) / (bufferSize - 1)) +
          (alpha / 2) * Math.cos((4 * Math.PI * i) / (bufferSize - 1));
      }
      break;
    case "cosine":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] = Math.cos(
          (Math.PI * i) / (bufferSize - 1) - Math.PI / 2
        );
      }
      break;
    case "gauss":
      alpha = alpha || 0.25;
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] = Math.pow(
          Math.E,
          -0.5 *
            Math.pow(
              (i - (bufferSize - 1) / 2) / ((alpha * (bufferSize - 1)) / 2),
              2
            )
        );
      }
      break;
    case "hamming":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          (0.54 - 0.46) * Math.cos((Math.PI * 2 * i) / (bufferSize - 1));
      }
      break;
    case "hann":
    case undefined:
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          0.5 * (1 - Math.cos((Math.PI * 2 * i) / (bufferSize - 1)));
      }
      break;
    case "lanczoz":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          Math.sin(Math.PI * ((2 * i) / (bufferSize - 1) - 1)) /
          (Math.PI * ((2 * i) / (bufferSize - 1) - 1));
      }
      break;
    case "rectangular":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] = 1;
      }
      break;
    case "triangular":
      for (let i = 0; i < bufferSize; i++) {
        this.windowValues[i] =
          (2 / bufferSize) *
          (bufferSize / 2 - Math.abs(i - (bufferSize - 1) / 2));
      }
      break;
    default:
      throw Error("No such window function '" + windowFunc + "'");
  }

  var limit = 1;
  var bit = bufferSize >> 1;

  var i;

  while (limit < bufferSize) {
    for (i = 0; i < limit; i++) {
      this.reverseTable[i + limit] = this.reverseTable[i] + bit;
    }

    limit = limit << 1;
    bit = bit >> 1;
  }

  for (i = 0; i < bufferSize; i++) {
    this.sinTable[i] = Math.sin(-Math.PI / i);
    this.cosTable[i] = Math.cos(-Math.PI / i);
  }

  this.calculateSpectrumComplex = function (buffer, ibuffer) {
    // Locally scope variables for speed up
    var bufferSize = this.bufferSize,
      cosTable = this.cosTable,
      sinTable = this.sinTable,
      reverseTable = this.reverseTable,
      real = new Float32Array(bufferSize),
      imag = new Float32Array(bufferSize),
      bSi = 2 / this.bufferSize,
      sqrt = Math.sqrt,
      rval,
      ival,
      mag,
      spectrum = new Float32Array(bufferSize / 2);

    var k = Math.floor(Math.log(bufferSize) / Math.LN2);

    if (Math.pow(2, k) !== bufferSize) {
      throw new Error("Invalid buffer size, must be a power of 2.");
    }
    if (bufferSize !== buffer.length) {
      throw new Error(
        "Supplied buffer is not the same size as defined FFT. FFT Size: " +
          bufferSize +
          " Buffer Size: " +
          buffer.length
      );
    }

    var halfSize = 1,
      phaseShiftStepReal,
      phaseShiftStepImag,
      currentPhaseShiftReal,
      currentPhaseShiftImag,
      off,
      tr,
      ti,
      tmpReal;

    for (let i = 0; i < bufferSize; i++) {
      real[i] = buffer[reverseTable[i]] * this.windowValues[reverseTable[i]];
      imag[i] =
        ibuffer === undefined
          ? 0
          : ibuffer[reverseTable[i]] * this.windowValues[reverseTable[i]];
    }

    while (halfSize < bufferSize) {
      phaseShiftStepReal = cosTable[halfSize];
      phaseShiftStepImag = sinTable[halfSize];

      currentPhaseShiftReal = 1;
      currentPhaseShiftImag = 0;

      for (let fftStep = 0; fftStep < halfSize; fftStep++) {
        var i = fftStep;

        while (i < bufferSize) {
          off = i + halfSize;
          tr =
            currentPhaseShiftReal * real[off] -
            currentPhaseShiftImag * imag[off];
          ti =
            currentPhaseShiftReal * imag[off] +
            currentPhaseShiftImag * real[off];

          real[off] = real[i] - tr;
          imag[off] = imag[i] - ti;
          real[i] += tr;
          imag[i] += ti;

          i += halfSize << 1;
        }

        tmpReal = currentPhaseShiftReal;
        currentPhaseShiftReal =
          tmpReal * phaseShiftStepReal -
          currentPhaseShiftImag * phaseShiftStepImag;
        currentPhaseShiftImag =
          tmpReal * phaseShiftStepImag +
          currentPhaseShiftImag * phaseShiftStepReal;
      }

      halfSize = halfSize << 1;
    }

    for (let i = 0, N = bufferSize / 2; i < N; i++) {
      rval = real[i];
      ival = imag[i];
      mag = bSi * sqrt(rval * rval + ival * ival);

      if (mag > this.peak) {
        this.peakBand = i;
        this.peak = mag;
      }
      spectrum[i] = mag;
    }
    return { spectrum, real, imag };
  };

  this.calculateSpectrum = function (buffer) {
    return this.calculateSpectrumComplex(buffer).spectrum;
  };

  this.getEnvelopeSpectrum = function (buffer, startFrequency, endFrequency) {
    const { real, imag } = this.calculateSpectrumComplex(buffer);

    // hilbert transform + band filter
    const middle = Math.floor(this.sampleRate);
    const realBandHilbert = real.map((i, idx) =>
      idx === 0 || idx === middle
        ? i
        : idx < middle
          ? idx >= startFrequency && idx <= endFrequency
            ? i * 2
            : 0
          : 0
    );
    const imagBandHilbert = imag.map((i, idx) =>
      idx === 0 || idx === middle
        ? i
        : idx < middle
          ? idx >= startFrequency && idx <= endFrequency
            ? i * 2
            : 0
          : 0
    );

    // inverse fft
    const signal = this.calculateSpectrum(imagBandHilbert, realBandHilbert);

    // absolute values
    const absSignal = signal.map(Math.abs);
    // fft again - signal is only half so duplicate it with mirror values
    return this.calculateSpectrum([...absSignal, ...[...absSignal].reverse()]);
  };
};

export function fft(sampleRate) {
  return new FFT(1024, sampleRate);
}

export function resampleFrequencies(oldMatrix, width) {
  const columnsNumber = width;
  const newMatrix = [];

  const oldPiece = 1 / oldMatrix.length;
  const newPiece = 1 / columnsNumber;

  for (let i = 0; i < columnsNumber; i++) {
    const column = new Array(oldMatrix[0].length);
    column.fill(0);

    for (let j = 0; j < oldMatrix.length; j++) {
      const oldStart = j * oldPiece;
      const oldEnd = oldStart + oldPiece;
      const newStart = i * newPiece;
      const newEnd = newStart + newPiece;

      const overlap =
        oldEnd <= newStart || newEnd <= oldStart
          ? 0
          : Math.min(Math.max(oldEnd, newStart), Math.max(newEnd, oldStart)) -
            Math.max(Math.min(oldEnd, newStart), Math.min(newEnd, oldStart));
      if (overlap > 0) {
        for (let k = 0; k < oldMatrix[0].length; k++) {
          column[k] += (overlap / newPiece) * oldMatrix[j][k];
        }
      }
    }

    newMatrix.push(Uint8Array.from(column));
  }

  return newMatrix;
}

export const palette = [];
export const paletteRGB = [];

// grayscale
for (let i = 0; i < 256; i++) {
  let color = 255 - i;
  palette[i] = `rgb(${color},${color},${color})`;
}

// magma
for (let i = 0; i < 255; i++) {
  let color = i / 255;
  palette[i] = scale.interpolateMagma(color);
  let hex = palette[i].replace("#", "0x") - 0;
  paletteRGB[i] = [hex >> 16, (hex >> 8) & 0xff, hex & 0xff];
  // palette[i] = scale.interpolateInferno(color);
  // palette[i] = scale.interpolateWarm(color);
  // palette[i] = scale.interpolateOrRd(color);
  // palette[i] = scale.interpolateRdPu(color);
}
