import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

i18next
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: ["en", "cs", "de", "zh"],
    ns: ["resource"],
    defaultNS: "app",
    fallbackNS: "app",
    returnEmptyString: false,
    react: {
      useSuspense: false,
    },
  });

export const LangEnum: { [key: string]: string } = {
  en_US: "en",
  cs_CZ: "cs",
  de_DE: "de",
  zh_CN: "zh",
};

export const languagesUntranslatable = [
  { lang: "en_US", text: "English" },
  { lang: "cs_CZ", text: "Čeština" },
  { lang: "de_DE", text: "Deutsch" },
  { lang: "zh_CN", text: "中文" },
];

export default i18next;
