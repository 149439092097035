import { useSelector, shallowEqual } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";
import { DashboardTypes } from "enums/grafana";

const selector = createSelector(
  (state: AppState) => state.machineDetail.machine,
  (state: any) => {
    return {
      data: state.machine.dashboards,
      loading: state.loading,
    };
  }
);

const useGrafanaDashboard = () => {
  const { data, loading } = useSelector(selector, shallowEqual);

  return {
    data: data as { [key in DashboardTypes]: string },
    loading,
  };
};
export default useGrafanaDashboard;
