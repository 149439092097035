import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function removeRegion(id: number, timezoneOffset: string) {
  const url = endpoints.labels.default;

  const data = await axiosNeuron.delete(
    `${url}${id}/?tz=${encodeURIComponent(timezoneOffset)}`
  );
  return data;
}
