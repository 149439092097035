import { IEventSoundsState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IEventSoundsState = {
  eventSoundsData: null,
  isFetching: false,
  error: null,
  pageIndex: 0,
  more: 0,

  placementTypes: {
    data: [],
    loading: false,
  },
  machineSubcategories: {
    data: [],
    loading: false,
  },
  failureTypes: {
    data: [],
    loading: false,
  },
  eventTypes: {
    data: [],
    loading: false,
  },

  audioChunk: {
    data: null,
    loading: false,
  },

  placementTypeValues: null,
  machineTypeValues: null,
  machineValues: null,
  failureTypeValues: null,
  eventTypeValues: null,
  labelStart: null,
  labelEnd: null,

  selectedChunks: [],

  machinesList: {
    data: [],
    loading: false,
    error: null,
  },
  placementsList: null,
  isFetchingEventDetails: false,
  errorEventDetails: null,

  searchParams: {
    from: null,
    to: null,
    placement: null,
  },

  similaritySearch: {
    data: null,
    isFetching: false,
    error: null,
  },
  playing: null,
  machines: [],
  placements: [],
  labelFetching: false,
  labelError: null,

  similaritySearchDetail: {
    data: {
      placement: null,
      machine: null,
    },
    loading: false,
    error: null,
  },
  originalSound: null,
  openModal: false,
  newName: null,
  existingName: null,
  replaceExistingName: null,
  datasets: {
    data: null,
    error: null,
    isFetching: false,
  },
  datasetDetails: {
    data: null,
    error: null,
    isFetching: false,
  },
  datasetParams: {
    page: null,
    pageSize: null,
    ids: null,
  },
  groupedDatasets: {
    data: null,
    error: null,
    isFetching: false,
  },
  selectedDatasets: [],
  groupedDatasetDetails: {
    data: null,
    error: null,
    isFetching: false,
  },
  groupedDatasetParams: {
    page: null,
    pageSize: null,
  },
  isSearchedClicked: false,

  startDate: null,
  endDate: null,
  q: null,
  sort: [],
  headerCells: [],
};

const reducers: TReducers = {
  [EActions.setPage]: ({ payload: { pageIndex } }, state) => ({
    pageIndex,
    more: 0,
    eventSoundsData: {
      ...state.eventSoundsData,
      results: [],
    },
  }),
  [EActions.setMore]: ({ payload: { more } }) => ({
    more,
  }),
  [EActions.fetchEventSoundsRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchEventSoundsFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchEventSoundsSuccess]: ({ payload: { eventSoundsData } }) => ({
    isFetching: false,
    error: null,
    eventSoundsData,
  }),

  [EActions.getPlacementTypesRequest]: (_, { placementTypes }) => ({
    placementTypes: {
      ...placementTypes,
      loading: true,
    },
  }),
  [EActions.getPlacementTypes]: ({ payload: { placementTypes } }) => ({
    placementTypes: {
      data: placementTypes,
      loading: false,
    },
  }),
  [EActions.getMachineSubcategoryRequest]: (_, { machineSubcategories }) => ({
    machineSubcategories: {
      ...machineSubcategories,
      loading: true,
    },
  }),
  [EActions.getMachineSubcategory]: ({
    payload: { machineSubcategories },
  }) => ({
    machineSubcategories: {
      data: machineSubcategories,
      loading: false,
    },
  }),
  [EActions.getFailureTypesRequest]: (_, { failureTypes }) => ({
    failureTypes: {
      ...failureTypes,
      loading: true,
    },
  }),
  [EActions.getFailureTypes]: ({ payload: { failureTypes } }) => ({
    failureTypes: {
      data: failureTypes,
      loading: false,
    },
  }),
  [EActions.getEventTypesRequest]: (_, { eventTypes }) => ({
    eventTypes: {
      ...eventTypes,
      loading: true,
    },
  }),
  [EActions.getEventTypes]: ({ payload: { eventTypes } }) => ({
    eventTypes: {
      data: eventTypes,
      loading: false,
    },
  }),
  [EActions.setLabelStart]: ({ payload: { labelStart } }) => ({
    labelStart,
  }),
  [EActions.setLabelEnd]: ({ payload: { labelEnd } }) => ({
    labelEnd,
  }),

  [EActions.setPlacementTypeValues]: ({
    payload: { placementTypeValues },
  }) => ({
    placementTypeValues,
  }),
  [EActions.setMachineTypeValues]: ({ payload: { machineTypeValues } }) => ({
    machineTypeValues,
  }),
  [EActions.setMachineValues]: ({ payload: { machineValues } }) => ({
    machineValues,
  }),
  [EActions.setFailureTypeValues]: ({ payload: { failureTypeValues } }) => ({
    failureTypeValues,
  }),
  [EActions.setEventTypeValues]: ({ payload: { eventTypeValues } }) => ({
    eventTypeValues,
  }),

  [EActions.setSelectedChunks]: ({ payload: { selectedChunks } }) => ({
    selectedChunks,
  }),
  [EActions.getMachinesListRequest]: (_, { machinesList }) => ({
    machinesList: {
      ...machinesList,
      loading: true,
    },
  }),
  [EActions.getMachinesListFail]: (
    { payload: { error } },
    { machinesList }
  ) => ({
    machinesList: {
      data: machinesList,
      loading: false,
      error,
    },
  }),
  [EActions.getMachinesList]: ({ payload: { machinesList } }) => ({
    machinesList: {
      data: machinesList,
      loading: false,
      error: null,
    },
  }),

  [EActions.getPlacementsList]: ({ payload: { placementsList } }) => ({
    placementsList,
  }),
  [EActions.setSearchParams]: ({ payload: { searchParams } }) => ({
    searchParams,
  }),

  [EActions.getSimilaritySoundsRequest]: (_, { similaritySearch }) => ({
    similaritySearch: {
      ...similaritySearch,
      isFetching: true,
    },
  }),
  [EActions.getSimilaritySoundsFail]: ({ payload: { error } }) => ({
    similaritySearch: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.getSimilaritySoundsSuccess]: ({
    payload: { similaritySearch },
  }) => ({
    similaritySearch: {
      data: similaritySearch,
      isFetching: false,
      error: null,
    },
  }),

  [EActions.setPlaying]: ({ payload: { playing } }) => ({
    playing,
  }),

  [EActions.getLabelRequest]: () => ({
    labelFetching: true,
  }),
  [EActions.getLabelFail]: ({ payload: { labelError } }) => ({
    labelFetching: false,
    labelError,
  }),
  [EActions.getMachines]: ({ payload: { machines } }) => ({
    labelFetching: false,
    labelError: null,
    machines,
  }),
  [EActions.getPlacements]: ({ payload: { placements } }) => ({
    labelFetching: false,
    labelError: null,
    placements,
  }),

  [EActions.getSimilarityDetailRequest]: (_, { similaritySearchDetail }) => ({
    similaritySearchDetail: {
      ...similaritySearchDetail,
      isFetching: true,
    },
  }),
  [EActions.getSimilarityDetailFail]: ({ payload: { error } }) => ({
    similaritySearchDetail: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.getSimilarityDetail]: ({
    payload: { similaritySearchDetail },
  }) => ({
    similaritySearchDetail: {
      data: similaritySearchDetail,
      isFetching: false,
      error: null,
    },
  }),
  [EActions.getOriginalSound]: ({ payload: { originalSound } }) => ({
    originalSound,
  }),
  [EActions.setOpenModal]: ({ payload: { openModal } }) => ({
    openModal,
  }),
  [EActions.setNewName]: ({ payload: { newName } }) => ({
    newName,
  }),
  [EActions.setExistingName]: ({ payload: { existingName } }) => ({
    existingName,
  }),
  [EActions.setReplacingExistingName]: ({
    payload: { replaceExistingName },
  }) => ({
    replaceExistingName,
  }),

  [EActions.fetchDatasetsRequest]: (_, { datasets }) => ({
    datasets: {
      ...datasets,
      isFetching: true,
    },
  }),
  [EActions.fetchDatasetsFail]: ({ payload: { error } }) => ({
    datasets: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchDatasetsSuccess]: ({ payload: { datasets } }) => ({
    datasets: {
      data: datasets,
      isFetching: false,
      error: null,
    },
  }),

  [EActions.fetchDatasetsDetailRequest]: (_, { datasetDetails }) => ({
    datasetDetails: {
      ...datasetDetails,
      isFetching: true,
    },
  }),
  [EActions.fetchDatasetsDetailFail]: ({ payload: { error } }) => ({
    datasetDetails: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchDatasetsDetailSuccess]: ({ payload: { datasetDetails } }) => ({
    datasetDetails: {
      data: datasetDetails,
      isFetching: false,
      error: null,
    },
  }),
  [EActions.setDatasetParams]: ({ payload: { datasetParams } }) => ({
    datasetParams,
  }),
  [EActions.setIsSearchedClicked]: ({ payload: { isSearchedClicked } }) => ({
    isSearchedClicked,
  }),
  [EActions.fetchDatasetGroupRequest]: (_, { groupedDatasets }) => ({
    groupedDatasets: {
      ...groupedDatasets,
      isFetching: true,
    },
  }),
  [EActions.fetchDatasetGroupFail]: ({ payload: { error } }) => ({
    groupedDatasets: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchDatasetGroupSuccess]: ({ payload: { groupedDatasets } }) => ({
    groupedDatasets: {
      data: groupedDatasets,
      isFetching: false,
      error: null,
    },
  }),
  [EActions.fetchDatasetGroupDetailRequest]: (
    _,
    { groupedDatasetDetails }
  ) => ({
    groupedDatasetDetails: {
      ...groupedDatasetDetails,
      isFetching: true,
    },
  }),
  [EActions.fetchDatasetGroupDetailFail]: ({ payload: { error } }) => ({
    groupedDatasetDetails: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchDatasetGroupDetailSuccess]: ({
    payload: { groupedDatasetDetails },
  }) => ({
    groupedDatasetDetails: {
      data: groupedDatasetDetails,
      isFetching: false,
      error: null,
    },
  }),
  [EActions.setSelectedDatasets]: ({ payload: { selectedDatasets } }) => ({
    selectedDatasets,
  }),
  [EActions.setGroupedDatasetParams]: ({
    payload: { groupedDatasetParams },
  }) => ({
    groupedDatasetParams,
  }),
  [EActions.setCreationDateFrom]: ({ payload: { startDate } }) => ({
    startDate,
  }),
  [EActions.setCreationDateTo]: ({ payload: { endDate } }) => ({
    endDate,
  }),
  [EActions.setQ]: ({ payload: { q } }) => ({
    q,
  }),
  [EActions.setSort]: ({ payload: { sort } }) => ({ sort }),
  [EActions.setHeaderCells]: ({ payload: { headerCells } }) => ({
    headerCells,
  }),
};

export default createReducer<IEventSoundsState>(path, initialState, reducers);
