import React, { memo, useCallback, useEffect, useMemo } from "react";
import useData from "dataHooks/projects/temporaryTokenData";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import { useTheme } from "@mui/material/styles";
import { ITemporaryTokenList } from "types/temporaryToken";
import { useTranslation } from "react-i18next";
import { createColumns } from "./columns";
import { createTinyColumns } from "components/projects/detail/temporaryTokenList/tinyColumns";
import { AddTemporaryToken } from "components/projects/detail/modal/addTemporaryToken";
import { useDispatch, useSelector } from "react-redux";

import actions from "store/projectDetail/temporaryToken/actions";
import { AppState } from "store";
import { fetchProjectMachines } from "store/projectDetail/machines/actions";
import { useParams } from "react-router-dom";

const { setModal } = actions;

export const TemporaryTokenTab = memo(() => {
  const { id } = useParams<{ [key: string]: string | undefined }>();
  const {
    temporaryTokens,
    loading,
    initialState,
    showMoreClickedTimesRef,
    readOnly,
    expiresAt,
    machines: machineIds,
    modalOpened,
    onPageChange,
  } = useData({
    id: Number(id),
    isDeleted: false,
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(t, expiresAt, machineIds),
    [expiresAt, machineIds, t]
  );
  const columnsMemoized = useMemo(
    () => createColumns(t, expiresAt, machineIds),
    [t, expiresAt, machineIds]
  );
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  const {
    auth: { user },
  } = useSelector((state: AppState) => state);

  const isAdmin = user?.role === "nsw_admin";

  const dataItems = useMemo(() => {
    if (!temporaryTokens) {
      return [];
    }
    return temporaryTokens.results;
  }, [temporaryTokens]);

  const onCancel = useCallback(() => {
    dispatch(setModal(false));
    dispatch(actions.setMachines([]));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProjectMachines(Number(id)));
  }, [dispatch]);

  return (
    <>
      <Box>
        <Table<ITemporaryTokenList>
          showMoreClickedTimesRef={showMoreClickedTimesRef}
          isLoading={!!loading}
          data={dataItems}
          rowsCount={temporaryTokens ? temporaryTokens.count : 0}
          columns={columns}
          initialState={initialState}
          onPageChange={onPageChange}
          RowComponent={Row}
          HeaderComponent={Header}
          RowComponentProps={{
            rowHeight: theme.spacing(7),
            rowWidth: "100%",
          }}
          hideNoData={!!loading}
        />
      </Box>
      {modalOpened ? (
        <AddTemporaryToken
          onCancel={onCancel}
          expiresAt={expiresAt}
          open={modalOpened}
          readOnly={readOnly}
          machineIds={machineIds}
          projectId={Number(id)}
        />
      ) : null}
    </>
  );
});
