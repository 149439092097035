import { useEffect, useCallback, useRef, useMemo } from "react";
import moment from "moment";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";

import { createSelector } from "reselect";
import actions, { fetchSimilaritySounds } from "store/eventSounds/actions";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

const pageSize = 100;

const { setSearchParams } = actions;
const selector = createSelector(
  (state: AppState) => state.eventSounds,
  (state: any) => {
    return {
      isFetching: state.similaritySearch.isFetching,
      similaritySounds: state.similaritySearch.data,

      pageIndex: state.pageIndex,
      originalSound: state.originalSound,
      more: state.more,
    };
  }
);

const useData = ({ searchParams }: any) => {
  const { pageIndex, more, isFetching, similaritySounds, originalSound } =
    useSelector(selector, shallowEqual);

  const showMoreClickedTimesRef = useRef(0);
  const sm = useRef(0);

  const dispatch: any = useDispatch();

  const { from, to, placement } = searchParams;

  const [query, setQuery] = useQueryParams({
    from: withDefault(StringParam, ""),
    to: withDefault(StringParam, ""),
    placement: withDefault(StringParam, ""),
  });

  useEffect(() => {
    setQuery(
      {
        from: from ? from?.toISOString() : undefined,
        to: to ? to?.toISOString() : undefined,
        placement: placement || undefined,
      },
      "pushIn"
    );
  }, [from, to, placement, setQuery]);

  useEffect(() => {
    dispatch(
      setSearchParams({
        from: from || query?.from ? moment(query?.from).toDate() : null,
        to: to || query?.to ? moment(query?.to).toDate() : null,
        placement: placement || query?.placement,
      })
    );
  }, [dispatch, query?.from, query?.placement, query?.to]);

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount: 1,
    };
  }, [similaritySounds, pageIndex]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (sm.current > 0) {
        dispatch(actions.setMore(sm.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (more !== showMoreClickedTimesRef.current) {
      showMoreClickedTimesRef.current = more;
    }
  }, [more]);

  useEffect(() => {
    if (from && to && placement) {
      dispatch(
        fetchSimilaritySounds({
          page: !sm.current ? pageIndex + 1 : 1,
          ps: sm.current ? pageSize * (1 + pageIndex) : pageSize,
          from: from.toISOString(),
          to: to.toISOString(),
          placement,
        })
      );
    }
  }, [dispatch, to, from, placement, pageSize, pageIndex]);

  return {
    pageIndex,
    isFetching,
    similaritySounds,
    initialState,
    showMoreClickedTimesRef,
    originalSound,
    onPageChange,
  };
};
export default useData;
