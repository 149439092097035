import React, { memo, useCallback } from "react";
import { SelectPopover } from "components/selectPopover";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions, {
  fetchPlacements,
} from "store/deviceDetail/channelsList/actions";
import { AppState } from "store";
import { IMachineList } from "types/machine";
import { createSelector } from "reselect";
import isEqual from "react-fast-compare";
import { cellCompare } from "shared/helpers";
import { CellProps } from "react-table";

const { setMachine, setDisconnected, setMachines } = actions;

type PropsAndState = {
  state: AppState;
  id: number;
};

const selector = createSelector(
  ({ id }: PropsAndState) => id,
  ({ state }: PropsAndState) => state.deviceDetail.channelsList,
  (id, props) => {
    const { machine } =
      props.channels.find(
        (item: IMachineList) => id && Number(item.id) === id
      ) || {};
    return {
      machine: props.machines.find(
        (item: IMachineList) => id && Number(item.id) === machine
      ),
      connectableMachines: props.connectableMachines,
      machinesLoading: props.machinesLoading,
    };
  }
);

const MachineTableSelect = <T extends Object>({
  cell: {
    value: { disabled, id },
  },
}: CellProps<T>) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const { connectableMachines, machinesLoading, machine } = useSelector(
    (state: AppState) => selector({ state, id }),
    isEqual
  );
  const {
    custom: { palette },
  } = useTheme();

  const openMenu = useCallback(
    (machineId: any) => {
      dispatch(setMachine(id, Number(machineId)));
      dispatch(fetchPlacements(Number(machineId)));
      dispatch(
        setMachines([
          connectableMachines.find(
            (item: IMachineList) => item.id === Number(machineId)
          ),
        ])
      );
    },
    [connectableMachines, dispatch, id]
  );

  const fetchData = useCallback(
    () => dispatch(setDisconnected(id)),
    [dispatch, id]
  );

  return (
    <>
      <SelectPopover
        title={t(`devices.detail.list.cell.machine`)}
        selected={machine ?? ""}
        fetchData={fetchData}
        loading={machinesLoading}
        onChange={openMenu}
        disabled={disabled}
        data={connectableMachines}
        customcolor={palette.primary["100"]}
      />
    </>
  );
};

export default memo(
  MachineTableSelect,
  cellCompare
) as typeof MachineTableSelect;
