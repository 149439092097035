import Combined from "./cells/combinedCells";
import { Column } from "react-table";
import { IFacility } from "types/facility";
import { TFunction } from "i18next";

export const createTinyColumns = (t: TFunction): Column<IFacility>[] => [
  {
    id: "combined",
    Header: "",
    accessor: ({ id, name }: any) => ({
      id,
      name,
      path: "projectDetail.facilities",
    }),
    Cell: Combined,
    custom: {
      hidePriority: 0,
    },
  },
];
