import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export type Params = {
  email: string;
};

export default async function resendInvitation({ email }: Params) {
  const url = endpoints.forgotPassword.default;
  try {
    const response = await axiosNeuron.post(url, { email });
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
