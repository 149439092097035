import { useCallback, useEffect, useState } from "react";
import { Box, Button, IconButton, MenuItem, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import { dateToString } from "shared/helpers";
import moment from "moment";
import appUrls from "shared/appUrls";
import { DashboardTypes } from "enums/grafana";

export const CardDetail = ({
  id,
  classes,
  result,
  chunk,
  machine,
  placement,
  isFetching,
}: any) => {
  const { t } = useTranslation();

  const [open, setMenuOpen] = useState<any>(null);

  const startDate = dateToString(new Date(result.start));
  const endDate = dateToString(new Date(result.end));

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuOpen(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback((event?: any) => {
    event.stopPropagation();
    setMenuOpen(null);
  }, []);

  useEffect(() => {
    const kebab = document.querySelector(`#card-${id}`);
    if (kebab) {
      kebab.addEventListener("hideKebab", handleClose);
    }
    return () => {
      if (kebab) {
        kebab.removeEventListener("hideKebab", handleClose);
      }
    };
  }, [handleClose, id]);

  const stopPropagation = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const handleRedirectGrafana = useCallback(() => {
    const start = moment(chunk.start_datetime).valueOf();
    const end = moment(chunk.end_datetime).valueOf();
    if (chunk?.machine && chunk?.placement && start && end) {
      const url =
        appUrls.machines.detail(chunk?.machine) +
        `?tab=${DashboardTypes.combined}&from=${start}&to=${end}&placement=${chunk?.placement}`;
      window.open(url, "_blank");
    }
  }, [
    chunk.start_datetime,
    chunk.machine,
    chunk?.placement,
    chunk.end_datetime,
  ]);

  return (
    <div id={`card-${id}`}>
      <IconButton onClick={handleClick} style={{ padding: 0 }}>
        <InfoIcon />
      </IconButton>
      <Popover
        id={open ? "simple-popover" : undefined}
        open={!!open}
        anchorEl={open}
        onClick={stopPropagation}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        {isFetching ? (
          <Spinner />
        ) : (
          <>
            <Box className={classes.contentWrapper}>
              <div>
                <strong>
                  {t("dataset.detail.machine")}
                  {": "}
                </strong>
                <span>
                  {chunk.machine} - {machine}
                </span>
              </div>
              <div>
                <strong>
                  {t("dataset.detail.placement")}
                  {": "}
                </strong>
                <span>
                  {chunk.placement} - {placement}
                </span>
              </div>
              <div>
                <strong>
                  {t("dataset.detail.startAndEndEvent")}
                  {": "}
                </strong>
                <span>
                  {startDate} - {endDate}
                </span>
              </div>
              <div>
                <strong>
                  {t("dataset.detail.duration")}
                  {": "}
                </strong>
                <span>{chunk.buffer.duration}s</span>
              </div>
              <MenuItem style={{ paddingLeft: "unset" }}>
                <Button
                  size="small"
                  fullWidth
                  style={{ paddingLeft: "unset" }}
                  className={classes.actionButton}
                  onClick={handleRedirectGrafana}
                >
                  {t("eventSounds.similaritySearch.checkSound")}
                </Button>
              </MenuItem>
            </Box>
          </>
        )}
      </Popover>
    </div>
  );
};
