import React from "react";
import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";
import MaterialButton, { ButtonProps } from "@mui/material/Button";

interface Props extends ButtonProps {
  dataTestId?: string;
}

const useStyles = makeStyles((theme: IMuiTheme) => {
  return {
    root: {
      position: "relative",
      zIndex: 1,
      display: "inline-flex",
    },
    button: {
      minWidth: theme.spacing(4.125),
      // display: "flex",
      // flexWrap: "wrap",
      color: (props: Props) => {
        if (props.color === "primary") return theme.custom?.palette.surface;
        if (props.color === "secondary")
          return theme.custom?.palette.primary["100"];
        else return theme.custom?.palette.primary["100"];
      },
      boxShadow: (props: Props) => {
        if (props.color === "primary") return theme.custom?.boxShadow["2"];
        if (props.color === "secondary") return theme.custom?.boxShadow["1"];
      },
      background: (props: Props) => {
        if (props.color === "primary")
          return theme.custom?.palette.gradient.primary.default;
        if (props.color === "secondary") return theme.custom?.palette.secondary;
        else return "transparent";
      },
      padding: (props: Props) =>
        props.size === "large"
          ? theme.spacing(1.2, 1.75)
          : theme.spacing(0.7, 1),
      fontWeight: (props: Props) =>
        props.color === "primary"
          ? theme.custom?.typography.fontWeight.bold
          : theme.custom?.typography.fontWeight.medium,
      fontSize: theme.custom?.typography.fontSize["14"],
      letterSpacing: "0.05rem",
      borderWidth: 0,
      borderStyle: "solid",
      borderColor: "transparent",
      borderRadius: theme.spacing(0.5),
      position: "relative",
      boxSizing: "border-box",
      backgroundClip: "padding-box",
      lineHeight: "normal",
      transitionProperty: "box-shadow",
      "&:hover": {
        boxShadow: (props: Props) => {
          if (props.color === "primary") return theme.custom?.boxShadow["4"];
          if (props.color === "secondary") return theme.custom?.boxShadow["2"];
          else return theme.custom?.boxShadow["0"];
        },
        background: (props: Props) => {
          if (props.color === "primary")
            return theme.custom?.palette.gradient.primary.hover;
          if (props.color === "secondary")
            return theme.custom?.palette.secondary;
          else return theme.custom?.palette.surface;
        },
        color: (props: Props) => {
          if (props.color === "primary") return theme.custom?.palette.surface;
          else return theme.custom?.palette.primary["100"];
        },
      },
      "&:focus": {
        boxShadow: (props: Props) =>
          props.color === "secondary" ? theme.custom?.boxShadow[1] : "inherit",
        background: (props: Props) => {
          if (props.color === "primary")
            return theme.custom?.palette.gradient.primary.default;
          else return theme.custom?.palette.shade;
        },
        backgroundClip: "padding-box",
        color: (props: Props) =>
          props.color !== "primary"
            ? theme.custom?.palette.data
            : theme.custom?.palette.surface,
      },
      "&:active, &:active:focus": {
        boxShadow: (props: Props) => {
          if (props.color === "primary") return theme.custom?.boxShadow["6"];
          if (props.color === "secondary") return theme.custom?.boxShadow["3"];
          else return theme.custom?.boxShadow["0"];
        },
        color: (props: Props) =>
          props.color === "primary"
            ? theme.custom?.palette.surface
            : theme.custom?.palette.primary["100"],
        background: (props: Props) => {
          if (props.color === "primary")
            return theme.custom?.palette.gradient.primary.active;
          if (props.color === "secondary") return theme.custom?.palette.surface;
          else return "transparent";
        },
      },
      "&:disabled": {
        background: (props: Props) => {
          if (props.color === "primary")
            return theme.custom?.palette.placeholder;
          if (props.color === "secondary") return theme.custom?.palette.shade;
          else return "transparent";
        },
        boxShadow: theme.custom?.boxShadow["0"],
        color: (props: Props) =>
          props.color === "primary"
            ? theme.custom?.palette.surface
            : theme.custom?.palette.placeholder,
      },
      "& .MuiButton-startIcon": {
        margin: theme.spacing(-0.625, 1.25, -0.625, -0.625),
      },
      "& .MuiButton-label > svg": {
        margin: theme.spacing(-0.4375, -1.2),
      },
      "& .MuiButton-startIcon > *:first-child": {
        fontSize: theme.custom?.typography.fontSize["24"],
      },
    },
    buttonRipple: {
      backgroundColor: theme.custom?.palette.surface,
    },
  };
});

const Button = (props: Props) => {
  const classes = useStyles(props);
  const { dataTestId, ...rest } = props;
  return (
    <div className={classes.root}>
      <MaterialButton
        id={dataTestId}
        className={classes.button}
        centerRipple={true}
        focusRipple={true}
        disableRipple={false}
        TouchRippleProps={{
          classes: {
            child: classes.buttonRipple,
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default Button;
