import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ResponseType } from "types/response";
import { IDeviceTypeList } from "types/device";

type Params = {
  ids?: number[];
};

export default async function getDevicesType(params: Params) {
  if (params.ids && !params.ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IDeviceTypeList>;
  }
  const url = endpoints.devices.types;
  const idsFilter = params.ids ? `&id=${params.ids.join("&id=")}` : "";
  try {
    const response = await axiosNeuron.get(`${url}?${idsFilter}`);
    const data: ResponseType<IDeviceTypeList> = {
      ...response.data,
      results: response.data.results.map((type: any) => ({
        id: type.id,
        deviceVersions: type.device_versions,
        name: type.name,
        description: type.description,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
