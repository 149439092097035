import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IMachineSubcategoryResponse {
  id: number;
  company: number;
  category: number;
  grafanaTemplate: number;
  name: string;
  description: string;
  attachment: number;
}

export default async function getMachineSubcategory(id: number) {
  const url = endpoints.machines.subCategory(id);
  try {
    const response = await axiosNeuron.get(url);
    const data: IMachineSubcategoryResponse = {
      id: response.data.id,
      company: response.data.company,
      category: response.data.category,
      grafanaTemplate: response.data.grafana_template,
      name: response.data.name,
      description: response.data.description,
      attachment: response.data.attachment,
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
