import React from "react";

export enum EActions {
  fetchAttachmentsRequest = "fetchAttachmentsRequest",
  fetchAttachmentsSuccess = "fetchAttachmentsSuccess",
  fetchAttachmentsFail = "fetchAttachmentsFail",
}

export interface IAttachmentsState {
  event?: number;
  machine?: number;
  attachments: any[];
  loading: boolean;
  error: Error | null;
  showMoreClickedTimesRef: React.RefObject<number>;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export interface IAttachmentsAction {
  type: EActions;
  payload: {
    event?: number;
    machine?: number;
    attachments?: any[];
    loading?: boolean;
    error?: Error | null;
    pageSize?: number;
    pageIndex?: number;
    count?: number;
    clear?: boolean;
  };
}

export type TReducers = {
  [key: string]: (p: IAttachmentsAction, s: IAttachmentsState) => void;
};
