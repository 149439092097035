import React from "react";
import { withStyles, makeStyles } from "@mui/styles";
import MUISwitch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import IMuiTheme from "types/styles";
import { Theme } from "@mui/material";

const SwitchComponent = withStyles((theme: IMuiTheme | Theme) => ({
  thumb: {
    boxShadow: theme.custom.boxShadow[2],
  },
  switchBase: {
    color: theme.custom.palette.surface,
    "& + $track": {
      backgroundColor: theme.custom.palette.label,
    },
    "&$checked": {
      color: theme.custom.palette.success.regular,
    },
    "&$checked + $track": {
      backgroundColor: theme.custom.palette.success.regular,
    },
  },
  checked: {},
  track: {},
}))(MUISwitch);

const SwitchComponentNeutral = withStyles((theme: IMuiTheme | Theme) => ({
  thumb: {
    boxShadow: theme.custom.boxShadow[2],
  },
  switchBase: {
    color: theme.custom.palette.surface,
    "& + $track": {
      backgroundColor: theme.custom.palette.label,
    },
    "&$checked": {
      color: theme.custom.palette.surface,
    },
    "&$checked + $track": {
      backgroundColor: theme.custom.palette.label,
    },
  },
  checked: {},
  track: {},
}))(MUISwitch);

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
    fontSize: theme.custom.typography.fontSize[12],
    letterSpacing: theme.custom.typography.letterSpacing.medium,

    "& .MuiGrid-item": {
      paddingTop: 0,
    },
  },
  left: {
    marginRight: "-0.5rem",
    marginLeft: "0.5rem",
  },
  right: {
    marginLeft: "-0.5rem",
    marginRight: "0.5rem",
  },
  bold: {
    fontWeight: theme.custom.typography.fontWeight.bold,
  },
}));

interface Props {
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelOn: string;
  labelOff: string;
  onClick?: (event: React.MouseEvent<HTMLLabelElement>) => void;
  neutral?: boolean;
  disabled?: boolean;
  fixedSizeLeftLabel?: boolean;
}

const Switch = (props: Props) => {
  const {
    name,
    checked,
    onChange,
    labelOn,
    labelOff,
    onClick,
    neutral,
    disabled,
    fixedSizeLeftLabel,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      spacing={1}
      wrap="nowrap"
      className={classes.root}
      onClick={onClick}
    >
      <Grid
        item
        xs={fixedSizeLeftLabel ? 2 : "auto"}
        md={fixedSizeLeftLabel ? 3 : "auto"}
        className={
          checked !== neutral ? `${classes.left} ${classes.bold}` : classes.left
        }
      >
        {labelOff.toUpperCase()}
      </Grid>
      <Grid item>
        {neutral ? (
          <SwitchComponentNeutral
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <SwitchComponent
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      </Grid>
      <Grid
        xs={fixedSizeLeftLabel ? 2 : "auto"}
        md={fixedSizeLeftLabel ? 3 : "auto"}
        item
        className={checked ? `${classes.right} ${classes.bold}` : classes.right}
      >
        {labelOn.toUpperCase()}
      </Grid>
    </Grid>
  );
};

export default Switch;
