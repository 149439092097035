import { createAsyncThunk } from "@reduxjs/toolkit";
import addEventComment from "api/handlers/event/addEventComment";
import getEventComments from "api/handlers/event/getEventComments";
import patchEventComment from "api/handlers/event/patchEventComment";
import { actions } from "./slice";
import { AppState } from "store";
import { ELabelDateType } from "./types";
import getEvent from "api/handlers/event/getEvent";

export const fetchComments = createAsyncThunk(
  "events/fetchComments",
  async (
    isSingle: boolean,
    { getState, dispatch }: { getState: any; dispatch: any }
  ) => {
    try {
      const eventDetail = (getState() as AppState).eventModal.eventDetail;
      let eventId = null;

      if (eventDetail) {
        if (
          String(eventDetail.type).indexOf(ELabelDateType.end) === -1 ||
          isSingle
        ) {
          eventId = eventDetail.id;
        } else if (
          eventDetail.related_event?.type.indexOf(ELabelDateType.start) > -1
        ) {
          eventId = eventDetail.related_event?.id ?? null;
        }
        if (eventId !== null) {
          const comments = await getEventComments(eventId);

          dispatch(
            actions.setComments({
              comments: comments ? comments.reverse() : [],
            })
          );
        }
      }
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const addComment = createAsyncThunk(
  "events/addComment",
  async (
    {
      note,
      eventId,
      event,
      type,
      isSingle,
    }: {
      note: string;
      eventId: number;
      event: string;
      type: ELabelDateType;
      isSingle?: boolean;
    },
    { dispatch }: { dispatch: any }
  ) => {
    try {
      if (eventId) {
        const eventData = (await getEvent({
          id: eventId!,
        })) as any;
        if (eventData) {
          if (eventData.type === event + "_" + type || isSingle) {
            await addEventComment({
              event: eventData.id,
              note,
              created_at: new Date().toISOString(),
            });
          }
          if (eventData.related_event?.type === event + "_" + type) {
            await addEventComment({
              event: eventData.related_event.id,
              note,
              created_at: new Date().toISOString(),
            });
          }
          if (isSingle !== undefined) {
            dispatch(fetchComments(isSingle));
          }
        }
      }
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const patchComment = createAsyncThunk(
  "events/patchComment",
  async (
    {
      id,
      note,
    }: {
      id: number;
      note: string;
    },
    { getState, dispatch }: { getState: any; dispatch: any }
  ) => {
    try {
      await patchEventComment({ id, note });
      dispatch(fetchComments(false));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);
