import { ICustomModel } from "types/customModels";

export enum EActions {
  fetchCustomModelsOverviewSuccess = "fetchCustomModelsOverviewSuccess",
  fetchCustomModelsOverviewFail = "fetchCustomModelsOverviewFail",
  fetchCustomModelsOverviewRequest = "fetchCustomModelsOverviewRequest",

  fetchCustomModelsRequest = "fetchCustomModelsRequest",
  fetchCustomModelsSuccess = "fetchCustomModelsSuccess",
  fetchCustomModelsFail = "fetchCustomModelsFail",
}

export interface ICustomModelsState {
  error: boolean;
  customModels: ICustomModel[] | null;
  isFetching: boolean;

  globalCustomModels: {
    isFetching: boolean;
    data: ICustomModel[] | null;
    error: Error | null;
  };
}

export interface ICustomModelsAction {
  type: EActions;
  payload: ICustomModelsState;
}

export type TReducer = {
  [key: string]: (p: ICustomModelsAction, s: ICustomModelsState) => void;
};
