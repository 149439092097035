import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

type Props = {
  sorted?: "asc" | "desc" | "";
  className?: string;
  onClick?: (event?: any) => void;
  alignRight?: boolean;
  alignCenter?: boolean;
  margin?: string | number;
  children?: React.ReactNode;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    height: theme.spacing(2),
    alignItems: "center",
    justifyContent: ({ alignRight, alignCenter }: Props) =>
      alignRight ? "flex-end" : alignCenter ? "center" : "flex-start",
    position: "relative",
    fontSize: theme.custom.typography.fontSize["12"],
    fontWeight: theme.custom.typography.fontWeight.medium,
    fontFamily: theme.custom.typography.fontFamily,
    color: ({ sorted }: Props) => {
      if (!!sorted) return theme.custom.palette.data;
      else return theme.custom.palette.overlay;
    },
    cursor: (props: Props) => (!!props.onClick ? "pointer" : "default"),
    textTransform: "uppercase",
    userSelect: "none",
    margin: ({ margin }: Props) => margin || "initial",
    textAlign: "left",
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    position: ({ alignRight, margin }: Props) =>
      alignRight || margin ? "initial" : "absolute",
    left: ({ alignRight, margin }: Props) =>
      alignRight || margin ? "initial" : theme.spacing(-2.5),
    top: 0,
  },
}));

const Label: React.FC<Props> = (props: any) => {
  const { sorted, children, onClick, className } = props;

  const classes = useStyles(props);

  return (
    <div onClick={onClick} className={clsx(classes.root, className)}>
      {sorted && sorted === "asc" && (
        <ArrowUpwardIcon className={classes.icon} />
      )}
      {sorted && sorted === "desc" && (
        <ArrowDownwardIcon className={classes.icon} />
      )}
      {children}
    </div>
  );
};

export default Label;
