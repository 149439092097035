import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ERoleCodename } from "types/role";

import { AppState } from "store";
import actions from "store/companiesAndProjects/actions";
import { IProject } from "types/companiesAndProjects";
import EditCheckbox from "components/checkbox/editCheckbox";

const { setActiveProjects } = actions;

const Project = ({ data }: { data: IProject }) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const { id: loggedUserId, role } = useSelector(
    (state: AppState) => state.auth.user
  );

  const userEditingOwnProfile = useMemo(
    () =>
      parseInt(id ?? "0") === loggedUserId && role !== ERoleCodename.nswAdmin,
    [id, loggedUserId, role]
  );

  const { activeProjects } = useSelector(
    (state: AppState) => state.companiesAndProjects
  );

  const onProjectChange = useCallback(
    (active: any) => {
      if (active) {
        dispatch(setActiveProjects([...activeProjects, data.id]));
      } else {
        dispatch(
          setActiveProjects(
            activeProjects.filter((id: number) => id !== data.id)
          )
        );
      }
    },
    [activeProjects, data.id, dispatch]
  );

  return (
    <EditCheckbox
      entity={t("form.companiesProjects.project")}
      label={data.name}
      data={
        data.users &&
        t("form.companiesProjects.usersCount.users_interval", {
          postProcess: "interval",
          count: data.users.length,
        })
      }
      disableCheck={userEditingOwnProfile}
      disableEdit
      checked={activeProjects.includes(data.id)}
      onChange={onProjectChange}
    />
  );
};

export default Project;
