import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface IProps {
  service: string;
  machine: number;
  location: string;
}

export default async function patchMachine(update: IProps) {
  const url = endpoints.serviceManager.machines;

  try {
    const { data } = await axiosNeuron.patch(url, {
      updates: [update],
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
