import React, { memo, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { KebabMenu } from "components/kebab";
import actions from "store/projectDetail/facilities/actions";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import { useNavigate } from "react-router-dom";
import urls from "shared/appUrls";

const useStyles = makeStyles((theme: any) => {
  return {
    item: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

export const ActionsButton = memo(
  ({
    cell: {
      value: { id },
    },
  }: any) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useNavigate();

    const disableEdit = usePermissions([
      EPermissions.UPDATE_ANY_FACILITY,
      EPermissions.UPDATE_OWN_FACILITY,
    ]);

    const hideKebabMenu = useCallback(() => {
      const hideKebab = new CustomEvent("hideKebab");
      const kebab = document.querySelector("#kebab-devices-list-" + id);
      kebab?.dispatchEvent(hideKebab);
    }, [id]);

    const setEditing = useCallback(() => {
      hideKebabMenu();
      dispatch(actions.setEditing(id));
    }, [dispatch, hideKebabMenu, id]);

    const goDetail = useCallback(() => {
      hideKebabMenu();
      history(urls.facilities.detail(id));
    }, [hideKebabMenu, history, id]);

    const generateItems = useMemo(() => {
      return (
        <>
          <MenuItem
            disabled={!disableEdit}
            className={classes.item}
            onClick={goDetail}
          >
            {t("facilities.list.table.detail")}
          </MenuItem>
          <MenuItem
            disabled={!disableEdit}
            className={classes.item}
            onClick={setEditing}
          >
            {t("companies.list.table.edit")}
          </MenuItem>
        </>
      );
    }, [classes.item, disableEdit, goDetail, setEditing, t]);
    return (
      <>
        <KebabMenu id={"kebab-devices-list-" + id} data={generateItems} />
      </>
    );
  }
);
