import { Box, TextField, Typography } from "@mui/material";
import { useStyles } from "components/eventSounds/components/styles";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { ModalWrap } from "../../components/modal";

type IProps = {
  groupedDatasetName: string;
  setGroupedDatasetName: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: () => void;
};

const EditModal = ({
  groupedDatasetName,
  setGroupedDatasetName,
  open,
  onCancel,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <ModalWrap
        open={open}
        onCancel={onCancel}
        title={t("groupedDataset.modal.title")}
        cta={t("save")}
        onSubmit={onSubmit}
      >
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box ml={4} mt={0.5}>
                <Box mb={4} mt={1}>
                  <Field
                    name="project"
                    id="groupedDatasetName"
                    label={t("groupedDataset.modal.groupedDatasetName.label")}
                    render={({ id, label, input, meta }) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={10}
                      >
                        <Typography style={{ minWidth: "8rem" }}>
                          {t(
                            "groupedDataset.modal.groupedDatasetName.placeholder"
                          )}
                        </Typography>
                        <TextField
                          label={label}
                          value={groupedDatasetName}
                          id={id}
                          onChange={({ target }) =>
                            setGroupedDatasetName(target.value)
                          }
                          className={classes.inputWrapper}
                          variant="outlined"
                          size="small"
                          inputProps={{
                            className: classes.input,
                          }}
                          style={{ paddingRight: 10 }}
                          required
                        />
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            </form>
          )}
        />
      </ModalWrap>
    </>
  );
};

export default EditModal;
