import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Button from "components/button";

interface Props {
  handleFinish: () => void;
  onCancel: () => void;
  finishButtonLabel: string;
  submitDisabled?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  footerButtons: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
    position: "relative",
    zIndex: 10,
  },
}));

const Footer: React.FC<Props> = ({
  onCancel,
  handleFinish,
  finishButtonLabel,
  submitDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.footerButtons}>
      <Button onClick={onCancel}>{t("Cancel")}</Button>
      <Button
        id="testSingleActionModalSubmit"
        onClick={handleFinish}
        disabled={submitDisabled ? submitDisabled : false}
      >
        {finishButtonLabel ? finishButtonLabel : t("Confirm")}
      </Button>
    </div>
  );
};

export default Footer;
