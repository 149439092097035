import React from "react";
import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";
import LabelAndTag from "components/users/list/cells/labelAndTag";
import Label from "components/users/list/cells/label";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2, 0),
    },
    label: {
      fontSize: theme.custom.typography.fontSize[12],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.label,
    },
    middleSection: {
      margin: theme.spacing(2, 0),
    },
  };
});

const TinyCompoundCell = <T extends Object>(props: CellProps<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <LabelAndTag {...props} />
      <div className={classes.middleSection}>
        <Label
          {...props}
          cell={{
            ...props.cell,
            value: {
              ...props.cell.value,
              wrap: true,
            },
          }}
        />
        <div className={classes.label}>
          {t("users.list.table.email").toLocaleLowerCase()}
        </div>
      </div>
      <div className={classes.middleSection}>
        <Label
          {...props}
          cell={{
            ...props.cell,
            value: {
              ...props.cell.value,
              label: props.cell.value.phone,
              wrap: true,
            },
          }}
        />
        <div className={classes.label}>
          {t("users.list.table.phone").toLocaleLowerCase()}
        </div>
      </div>
    </div>
  );
};

export default TinyCompoundCell;
