import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";

import { createSelector } from "reselect";
import actions, { fetchGroupedDatasets } from "store/eventSounds/actions";
import moment from "moment";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

const selector = createSelector(
  (state: AppState) => state.eventSounds,
  (state: any) => {
    return {
      isFetching: state.groupedDatasets.isFetching,
      groupedDatasets: state.groupedDatasets.data,
      pageIndex: state.pageIndex,
      more: state.more,
      sort: state.sort,
      startDate: state.startDate,
      endDate: state.endDate,
      q: state.q,
    };
  }
);

const pageSize = 100;

const useData = () => {
  const {
    isFetching,
    groupedDatasets,
    pageIndex,
    more,
    sort,
    startDate,
    endDate,
    q,
  } = useSelector(selector, shallowEqual);
  const dispatch: any = useDispatch();

  const showMoreClickedTimesRef = useRef(0);
  const sm = useRef(0);

  const [query, setQuery] = useQueryParams({
    from: withDefault(StringParam, ""),
    to: withDefault(StringParam, ""),
    q: withDefault(StringParam, ""),
  });

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount: 1,
      sortBy: sort,
    };
  }, [groupedDatasets, pageIndex]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (sm.current > 0) {
        dispatch(actions.setMore(sm.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  const onPageSort = useCallback(
    (sortBy: any) => {
      dispatch(actions.setSort(sortBy));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      actions.setCreationDateFrom(
        query.from ? moment(query?.from).toDate() : null
      )
    );
    dispatch(
      actions.setCreationDateTo(query.to ? moment(query?.to).toDate() : null)
    );
    dispatch(actions.setQ(query.q));
  }, []);

  useEffect(() => {
    setQuery(
      {
        from: startDate ? startDate?.toISOString() : null,
        to: endDate ? endDate?.toISOString() : null,
        q: q || null,
      },
      "pushIn"
    );
  }, [startDate, endDate, q, setQuery]);

  useEffect(() => {
    dispatch(
      fetchGroupedDatasets({
        page: !sm.current ? pageIndex + 1 : 1,
        pageSize: sm.current ? pageSize * (1 + pageIndex) : pageSize,
        orderBy: sort,
        startDate,
        endDate,
        q,
      })
    );
  }, [dispatch, pageIndex, pageSize, sort, startDate, endDate, q]);

  return {
    isFetching,
    groupedDatasets,
    pageIndex,
    initialState,
    showMoreClickedTimesRef,
    onPageChange,
    onPageSort,
  };
};
export default useData;
