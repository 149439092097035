import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ResponseType } from "types/response";
import { IDeviceVersionList } from "types/device";

type Params = {
  ids?: number[];
};

export default async function getDevicesVersion(params: Params) {
  if (params.ids && !params.ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IDeviceVersionList>;
  }
  const url = endpoints.devices.versions;
  const idsFilter = params.ids ? `&id=${params.ids.join("&id=")}` : "";
  try {
    const response = await axiosNeuron.get(`${url}?${idsFilter}`);
    const data: ResponseType<IDeviceVersionList> = {
      ...response.data,
      results: response.data.results.map((version: any) => ({
        id: version.id,
        deviceType: version.device_type,
        name: version.name,
        description: version.description,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
