import { Column } from "react-table";
import { TFunction } from "i18next";
import { ITemporaryTokenList } from "types/temporaryToken";
import DateAndTime from "./cells/dateAndTime";
import { ActionsButton } from "./cells/actionsButton";
import Role from "./cells/role";
import Status from "./cells/status";

export const createColumns = (
  t: TFunction,
  expiresAt: string,
  machineIds: number[]
): Column<ITemporaryTokenList>[] => [
  {
    id: "expiration",
    Header: t("temporaryToken.list.table.expiration").toString(),
    accessor: ({ expires_at }) => {
      return {
        date: expires_at,
      };
    },
    Cell: DateAndTime,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "lastAccess",
    Header: t("temporaryToken.list.table.lastAccess").toString(),
    accessor: ({ last_access }) => {
      return {
        date: last_access,
      };
    },
    Cell: DateAndTime,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "role",
    Header: t("temporaryToken.list.table.role.title").toString(),
    accessor: ({ user }) => {
      return {
        user,
      };
    },
    Cell: Role,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "status",
    Header: t("temporaryToken.list.table.status").toString(),
    accessor: ({ expires_at }) => {
      return {
        expires_at,
      };
    },
    Cell: Status,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "kebab",
    Header: "",
    accessor: ({ id, expires_at, machines, key, project }) => ({
      id,
      key,
      expires_at,
      expiresAt,
      machineIds,
      machines,
      project,
    }),
    Cell: ActionsButton,
    custom: {
      hidePriority: 0,
      width: 64,
    },
  },
];
