import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export interface IUploadAudioChunkParams {
  placements: number[];
  file: {
    name: string;
    data: string;
  };
  start_datetime: string;
  meta: {
    [key: string]: string;
  };
}

export default async function uploadAudioChunk(
  params: IUploadAudioChunkParams
) {
  const url = endpoints.audio.upload;
  try {
    const response = await axiosNeuron.post(url, params);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
