import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import isEqual from "react-fast-compare";

import { addTemporaryTokenAction } from "store/projectDetail/temporaryToken/actions";
import { TokenModal } from "./components/temporaryToken";

interface IAddUserModal {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  expiresAt: string;
  open: boolean;
  readOnly?: boolean;
  machineIds: number[];
  projectId: number;
}

export const AddTemporaryToken = memo(
  ({
    onCancel,
    expiresAt,
    open,
    readOnly,
    machineIds,
    projectId,
  }: IAddUserModal) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();

    const onSubmit = useCallback(() => {
      const newData = {
        expires_at: expiresAt,
        readOnly: readOnly!,
        machines: machineIds,
      };
      dispatch(addTemporaryTokenAction(newData));
    }, [dispatch, expiresAt, machineIds, readOnly]);

    const disabled = !!!expiresAt || !machineIds.length;

    if (!open) {
      return null;
    }

    return (
      <>
        <TokenModal
          open={open}
          onCancel={onCancel}
          onSubmitEvent={onSubmit}
          date={expiresAt}
          type={"createExpires"}
          label={t("temporaryToken.list.table.editDialog.expiresAtLabel")}
          readOnly={readOnly}
          isCreate={true}
          submitDisabled={disabled}
          machineIds={machineIds}
          projectId={projectId}
        />
      </>
    );
  },
  isEqual
);
