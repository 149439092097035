import { pxToRem } from "shared/helpers";

const typography = {
  fontFamily: '"Fira Sans", sans-serif',
  fontSize: {
    11: ".6875rem",
    12: ".75rem",
    14: ".875rem",
    16: "1rem",
    18: "1.125rem",
    24: "1.5rem",
    32: "2rem",
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  letterSpacing: {
    small: pxToRem(0.24),
    medium: pxToRem(0.4),
    big: pxToRem(0.8),
  },
};

export default typography;
