import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IFacilityDetailResponse {
  id: number;
  name: string;
  description: string;
  project: number;
  address: string;
  users_to_notify: number[];
}

export default async function getFacility(id: number) {
  const url = endpoints.facilities.detail(id);
  try {
    const response = await axiosNeuron.get(url);
    const data: IFacilityDetailResponse = {
      id: response.data.id,
      name: response.data.name,
      description: response.data.description,
      project: response.data.project,
      address: response.data.address,
      users_to_notify: response.data.users_to_notify,
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
