import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { MachineCondition, MachineStatus } from "components/machine/types";

interface Params {
  company?: number[];
  project?: number[];
  facility?: number[];
  show_archived?: boolean;
  q?: string;
  any_maintenance?: boolean;
  broken?: boolean;
  any_alert?: boolean;
  running?: boolean;
  not_running?: boolean;
  unknown?: boolean;
  out_of_service?: boolean;
}

export default async function getMachineUtilizationTotals(paramsInput: Params) {
  const url = endpoints.machines.utilizationTotals;
  try {
    const params = {
      status: [
        paramsInput.running ? MachineStatus.running : undefined,
        paramsInput.not_running ? MachineStatus.notRunning : undefined,
        paramsInput.unknown ? MachineStatus.unknown : undefined,
      ],
      facility: paramsInput.facility,
      company: paramsInput.company,
      project: paramsInput.project,
      condition: [
        paramsInput.any_maintenance
          ? MachineCondition.anyMaintenance
          : undefined,
        paramsInput.broken ? MachineCondition.broken : undefined,
        paramsInput.any_alert ? MachineCondition.anyAlert : undefined,
        paramsInput.out_of_service ? MachineCondition.outOfService : undefined,
      ],
      q: paramsInput.q?.length ? paramsInput.q : undefined,
      is_archived: paramsInput.show_archived ? undefined : [false],
    };
    const { data } = (await axiosNeuron.get(url, { params })) as any;
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
