import { EActions, TReducer, ICustomModelsState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: ICustomModelsState = {
  error: false,
  customModels: [],
  isFetching: false,

  globalCustomModels: {
    data: [],
    isFetching: false,
    error: null,
  },
};

const reducers: TReducer = {
  [EActions.fetchCustomModelsOverviewRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchCustomModelsOverviewFail]: ({ payload: { error } }) => ({
    customModels: null,
    error: error,
    isFetching: false,
  }),
  [EActions.fetchCustomModelsOverviewSuccess]: ({
    payload: { customModels },
  }) => ({
    customModels,
    error: null,
    isFetching: false,
  }),

  [EActions.fetchCustomModelsRequest]: (_, state) => ({
    globalCustomModels: {
      ...state.globalCustomModels,
      isFetching: true,
    },
  }),
  [EActions.fetchCustomModelsSuccess]: ({
    payload: { globalCustomModels },
  }) => ({
    globalCustomModels: {
      isFetching: false,
      data: globalCustomModels,
      error: null,
    },
  }),
  [EActions.fetchCustomModelsFail]: ({ payload: { error } }) => ({
    globalCustomModels: {
      isFetching: false,
      data: null,
      error,
    },
  }),
};

export default createReducer<ICustomModelsState>(path, initialState, reducers);
