import { IAttachmentsState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import React from "react";

const initialState: IAttachmentsState = {
  attachments: [],
  event: undefined,
  machine: undefined,
  loading: true,
  error: null,
  showMoreClickedTimesRef: React.createRef(),
  pageIndex: 0,
  pageSize: 10000,
  count: 0,
};

const reducers: TReducers = {
  [EActions.fetchAttachmentsRequest]: ({ payload }) => ({
    loading: true,
    event: payload?.event,
    machine: payload?.machine,
    pageSize: payload?.pageSize,
    pageIndex: payload?.pageIndex,
    ...(payload?.clear ? { attachments: [] } : {}),
  }),
  [EActions.fetchAttachmentsFail]: ({ payload }) => ({
    attachments: [],
    loading: false,
    error: payload?.error,
  }),
  [EActions.fetchAttachmentsSuccess]: ({ payload }) => ({
    attachments: payload?.attachments,
    count: payload?.count,
    loading: false,
    error: null,
  }),
};

export default createReducer<IAttachmentsState>(path, initialState, reducers);
