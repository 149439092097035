import { IUsersState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IUsersState = {
  filters: {
    q: [],
    company: [],
    project: [],
    status: [],
    role: [],
  },
  pageIndex: 0,
  usersData: null,
  usersFetchParams: null,
  isFetching: false,
  error: null,
  modalOpened: false,
  more: 0,
  sort: [],
  roles: {
    data: [],
    isFetching: false,
    error: null,
  },
  companies: {
    data: [],
    isFetching: false,
    error: null,
  },
  projects: {
    data: [],
    isFetching: false,
    error: null,
  },
  statuses: {
    data: [],
    isFetching: false,
    error: null,
  },
};

const reducers: TReducers = {
  [EActions.setUsersFilter]: ({ payload: { filters } }) => ({
    filters,
    pageIndex: 0,
    more: 0,
    usersData: {
      results: [],
    },
  }),
  [EActions.setResetList]: () => ({
    ...initialState,
    usersData: {
      results: [],
    },
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }, state) => ({
    pageIndex,
    more: 0,
    usersData: {
      ...state.usersData,
      results: [],
    },
  }),
  [EActions.setSort]: ({ payload: { sort } }, state) => ({
    pageIndex: 0,
    more: 0,
    sort,
    usersData: {
      ...state.usersData,
      results: [],
    },
  }),
  [EActions.fetchUsersRequest]: () => ({
    isFetching: true,
  }),
  [EActions.setModal]: ({ payload: { modalOpened } }) => ({
    modalOpened,
  }),
  [EActions.setMore]: ({ payload: { more } }) => ({
    more,
  }),
  [EActions.fetchUsersFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchUsersSuccess]: (
    { payload: { usersFetchParams, usersData } },
    state
  ) => ({
    isFetching: false,
    error: null,
    usersFetchParams,
    usersData: {
      ...usersData,
      results: [...(usersData?.results || [])],
    },
  }),
  [EActions.fetchRolesRequest]: (_, { roles }) => ({
    roles: {
      ...roles,
      isFetching: true,
    },
  }),
  [EActions.fetchRolesFail]: (error, { roles }) => ({
    roles: {
      ...roles,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchRolesSuccess]: ({ payload: { roles } }) => ({
    roles: {
      data: roles,
      isFetching: false,
    },
  }),
  [EActions.fetchCompaniesRequest]: (_, { companies }) => ({
    companies: {
      ...companies,
      isFetching: true,
    },
  }),
  [EActions.fetchCompaniesFail]: (error, { companies }) => ({
    companies: {
      ...companies,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchCompaniesSuccess]: ({ payload: { companies } }) => ({
    companies: {
      data: companies,
      isFetching: false,
    },
  }),
  [EActions.fetchProjectsRequest]: (_, { projects }) => ({
    projects: {
      ...projects,
      isFetching: true,
    },
  }),
  [EActions.fetchProjectsFail]: (error, { projects }) => ({
    projects: {
      ...projects,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchProjectsSuccess]: ({ payload: { projects } }) => ({
    projects: {
      data: projects,
      isFetching: false,
    },
  }),
};

export default createReducer<IUsersState>(path, initialState, reducers);
