import { memo, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import { DatePicker } from "./datePicker";
import { useStyles } from "components/events/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "store/eventModal/slice";
import { EModalTypes, ELabelDateType } from "store/eventModal/types";
import type { AppState } from "store";
import { DATETIME_FORMAT } from "components/dataLabeling/constants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

type TProps = {
  eventId: number | null;
  modal: string;
  start: string | undefined;
  end: string | undefined;
  isSingle?: boolean;
  editableSingle?: boolean;
};

export const Pickers = memo(
  ({
    eventId,
    modal,
    start: start_,
    end: end_,
    isSingle,
    editableSingle,
  }: TProps) => {
    const dispatch: any = useDispatch();
    const {
      errors,
      eventType,
      lastEventBeforeStart,
      firstEventAfterEnd,
      eventBetweenStartAndEnd,
    }: any = useSelector((state: AppState) => state.eventModal);
    const start = useMemo(() => (start_ ? dayjs(start_) : undefined), [start_]);
    const end = useMemo(() => (end_ ? dayjs(end_) : undefined), [end_]);
    const classes = useStyles();
    const { t } = useTranslation();

    const validateRange = useCallback(
      (date: any, type: any) => {
        let errorsLocal: any = { start: null, end: null };
        const invalidateDateFrom =
          lastEventBeforeStart !== undefined &&
          lastEventBeforeStart?.type.includes("_start");
        const invalidateDateTo =
          firstEventAfterEnd !== undefined &&
          firstEventAfterEnd?.type.includes("_end");

        const getEventDateWithId = (createdAt: string, eventId: number) => {
          return `${moment(createdAt).format(DATETIME_FORMAT)} (#${eventId})`;
        };

        const getErrorMessage = (from: any | null, to: any | null) => {
          const startAt = from
            ? `${t("pickersError.from")} ${getEventDateWithId(
                from.createdAt,
                from.id
              )}`
            : "";
          const endAt = to
            ? `${t("pickersError.to")} ${getEventDateWithId(
                to.createdAt,
                to.id
              )}`
            : "";
          return `${t("pickersError.overlaps")} ${startAt} ${endAt}`;
        };

        if (invalidateDateFrom || invalidateDateTo) {
          errorsLocal = {
            start: invalidateDateFrom
              ? getErrorMessage(
                  lastEventBeforeStart,
                  lastEventBeforeStart?.relatedEvent
                )
              : null,
            end: invalidateDateTo
              ? getErrorMessage(
                  lastEventBeforeStart?.relatedEvent,
                  lastEventBeforeStart
                )
              : null,
          };
        } else {
          if (invalidateDateFrom && lastEventBeforeStart) {
            errorsLocal = {
              start: getErrorMessage(
                lastEventBeforeStart,
                lastEventBeforeStart?.relatedEvent
              ),
              end: null,
            };
          } else if (invalidateDateTo) {
            errorsLocal = {
              start: null,
              end: getErrorMessage(
                lastEventBeforeStart?.relatedEvent,
                lastEventBeforeStart
              ),
            };
          } else if (
            eventBetweenStartAndEnd &&
            eventBetweenStartAndEnd.id !== eventId
          ) {
            errorsLocal = {
              start: getErrorMessage(
                eventBetweenStartAndEnd,
                eventBetweenStartAndEnd?.relatedEvent
              ),
              end: end
                ? getErrorMessage(
                    eventBetweenStartAndEnd,
                    eventBetweenStartAndEnd?.relatedEvent
                  )
                : null,
            };
          }
        }
        if (end && start) {
          if (end <= start) {
            errorsLocal = {
              start: t("eventModal.errors.startAt"),
              end: null,
            };
          }
        }
        dispatch(
          actions.setError({
            type: ELabelDateType.start,
            error: errorsLocal.start,
          })
        );
        dispatch(
          actions.setError({
            type: ELabelDateType.end,
            error: errorsLocal.end,
          })
        );
        return false;
      },
      [
        start,
        end,
        lastEventBeforeStart,
        firstEventAfterEnd,
        eventBetweenStartAndEnd,
      ]
    );

    return (
      <>
        <Box className={classes.inputTimePicker}>
          <DatePicker
            type={ELabelDateType.start}
            validateRange={validateRange}
            disabled={
              modal === EModalTypes.init ||
              modal === EModalTypes.finish ||
              (isSingle && !editableSingle)
            }
            date={start ? start.format("YYYY-MM-DD HH:mm:ss") : undefined}
            maxDate={end ? dayjs(end) : undefined}
            error={errors.start}
            eventType={eventType}
            label={t(
              editableSingle
                ? "taggingapp.datePicker.label"
                : "taggingapp.datePicker.labelStart"
            )}
          />
        </Box>
        {!isSingle && (
          <Box className={classes.inputTimePicker}>
            <DatePicker
              type={ELabelDateType.end}
              modal={modal}
              validateRange={validateRange}
              disabled={!modal || !start || modal === EModalTypes.init}
              date={end ? end.format("YYYY-MM-DD HH:mm:ss") : undefined}
              minDate={start ? dayjs(start) : undefined}
              error={errors.end}
              end={true}
              eventType={eventType}
              label={t("taggingapp.datePicker.labelEnd")}
            />
          </Box>
        )}
      </>
    );
  }
);
