import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import clsx from "clsx";
import Input from "@mui/material/Input";

type Props = {
  page: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  onPrevious: () => void;
  onNext: () => void;
  onGotoPage: (index: number) => void;
  pageCount: number;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: theme.spacing(8.875),
    height: theme.spacing(4),
    border: `1px solid ${theme.custom.palette.placeholder}`,
    borderRadius: theme.spacing(0.5),
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.spacing(4),
    minWidth: theme.spacing(2.75),
    cursor: "pointer",
  },
  iconWrapperFirst: {
    borderTopLeftRadius: theme.spacing(0.375),
    borderBottomLeftRadius: theme.spacing(0.375),
  },
  iconWrapperSecond: {
    borderTopRightRadius: theme.spacing(0.375),
    borderBottomRightRadius: theme.spacing(0.375),
  },
  iconWrapperDisabled: {
    cursor: "default",
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.custom.palette.data,
  },
  iconDisabled: {
    opacity: 0.3,
  },
  numberField: {
    flexGrow: 1,
    "&:before, &:after": {
      display: "none",
    },
  },
  numberInput: {
    textAlign: "center",
    fontSize: theme.custom.typography.fontSize["14"],
    color: theme.custom.palette.data,
    fontWeight: theme.custom.typography.fontWeight.medium,
    fontFamily: theme.custom.typography.fontFamily,
  },
}));

const PageButton = ({
  page,
  onPrevious,
  onNext,
  canPreviousPage,
  canNextPage,
  onGotoPage,
  pageCount,
}: Props) => {
  const classes = useStyles();

  const [inputPageValue, setInputPageValue] = useState<string>(page.toString());
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const TIMER_DELAY = 750;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regExp = /^[1-9][0-9]*$/;
    const newValue = event.target.value;
    if (newValue === "" || regExp.test(newValue)) {
      setInputPageValue(newValue);
      const pageIndex = parseInt(newValue) - 1;
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          if (
            Number.isInteger(pageIndex) &&
            pageIndex >= 0 &&
            pageIndex < pageCount
          ) {
            onGotoPage(pageIndex);
          } else {
            setInputPageValue(page.toString());
          }
        }, TIMER_DELAY)
      );
    }
  };

  React.useEffect(() => {
    setInputPageValue(page.toString());
  }, [page]);

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.iconWrapper,
          classes.iconWrapperFirst,
          !canPreviousPage && classes.iconWrapperDisabled,
          "testPaginationPreviousButton"
        )}
        onClick={onPrevious}
      >
        <ArrowLeftIcon
          className={clsx(
            classes.icon,
            !canPreviousPage && classes.iconDisabled
          )}
        />
      </div>
      <Input
        className={classes.numberField}
        value={inputPageValue}
        onChange={handleChange}
        classes={{
          input: clsx(classes.numberInput, "testPaginationCurrentPage"),
        }}
      />
      <div
        className={clsx(
          classes.iconWrapper,
          classes.iconWrapperSecond,
          !canNextPage && classes.iconWrapperDisabled,
          "testPaginationNextButton"
        )}
        onClick={onNext}
      >
        <ArrowRightIcon
          className={clsx(classes.icon, !canNextPage && classes.iconDisabled)}
        />
      </div>
    </div>
  );
};
export default PageButton;
