import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => {
  return {
    rowSpacer: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  };
});

const RowSpacer = () => {
  const classes = useStyles();
  return <div className={classes.rowSpacer}>&nbsp;</div>;
};

export default RowSpacer;
