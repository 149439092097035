import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalWrap } from "components/events/components/modalWrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { Form } from "react-final-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Spinner from "components/spinner";
import isEqual from "react-fast-compare";
import { DateAndTimePicker } from "./datePicker";
import Switch from "components/switch";
import actions from "store/projectDetail/temporaryToken/actions";
import MultiSelect from "./MultiSelect";

interface IAddFacilityModal {
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmitEvent: any;
  date: string;
  type: string;
  label: string;
  readOnly?: boolean;
  isCreate?: boolean;
  id?: number;
  expires_at?: string;
  submitDisabled?: boolean;
  machineIds: number[];
  projectId: number;
}

export const TokenModal = memo(
  ({
    open,
    onCancel,
    onSubmitEvent,
    type,
    date,
    label,
    readOnly,
    isCreate,
    id,
    expires_at,
    submitDisabled,
    machineIds,
    projectId,
  }: IAddFacilityModal) => {
    const { t } = useTranslation();
    const [disabledSwitch, setDisabledSwitch] = useState(false);
    const { loading } = useSelector(
      (state: AppState) => state.projectDetail.temporaryAccessToken
    );
    const {
      auth: { user },
    } = useSelector((state: AppState) => state);

    const dispatch: any = useDispatch();
    const handleUserSwitch = useCallback(() => {
      dispatch(actions.setReadOnly(!readOnly));
    }, [dispatch, readOnly]);

    useEffect(() => {
      if (
        user?.role === "nsw_admin" ||
        user?.role === "partner_admin" ||
        user?.role === "customer_admin"
      ) {
        setDisabledSwitch(false);
      } else {
        dispatch(actions.setReadOnly(true));
        setDisabledSwitch(true);
      }
    }, [dispatch, readOnly, user?.role]);

    useEffect(() => {
      dispatch(actions.setExpiresAt(expires_at));
      dispatch(actions.setMachines(machineIds));
    }, [id]);

    return (
      <Form
        onSubmit={onSubmitEvent}
        render={({ handleSubmit, values }) => (
          <form id="projectDetail.ModalForm" onSubmit={handleSubmit}>
            <ModalWrap
              open={open}
              title={
                isCreate
                  ? t("projects.detail.temporaryToken.create")
                  : t("projects.detail.temporaryToken.edit")
              }
              cta={t("confirm")}
              submitDisabled={loading || submitDisabled!}
              onCancel={onCancel}
              loading={loading}
              onSubmit={handleSubmit}
            >
              <>
                <Box flexDirection="column" m={3} flex={1}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <DateAndTimePicker
                          date={date}
                          type={type}
                          label={label}
                        />
                      </Grid>
                      <Grid item>
                        <MultiSelect
                          machineIds={machineIds}
                          projectId={projectId}
                        />
                      </Grid>
                      {isCreate ? (
                        <Grid item>
                          <Box display="inline-flex" mb={2} mt={2}>
                            <Switch
                              name="switch"
                              checked={readOnly!}
                              onChange={handleUserSwitch}
                              disabled={disabledSwitch}
                              labelOn={t(
                                "temporaryToken.list.table.add.user.readOnly"
                              )}
                              labelOff={t(
                                "temporaryToken.list.table.add.user.readAndWrite"
                              )}
                              neutral
                            />
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  )}
                </Box>
              </>
            </ModalWrap>
          </form>
        )}
      />
    );
  },
  isEqual
);
