import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ResponseType } from "types/response";
import { UsersItemTypes } from "api/handlers/user/getUsers";

const urlUsers = endpoints.users.default;

const fillEventUsers = async (eventsData: any, usersIds: number[]) => {
  const uniqueUserIds = Array.from(new Set(usersIds));
  if (uniqueUserIds.length > 0) {
    const usersResponse = await axiosNeuron.get(
      `${urlUsers}?id=${uniqueUserIds.join("&id=")}`
    );
    const usersData: ResponseType<UsersItemTypes> = {
      count: usersResponse.data.count,
      next: usersResponse.data.next,
      previous: usersResponse.data.previous,
      results: usersResponse.data.results.map((item: any) => ({
        id: item.id,
        firstName: item.first_name,
        lastName: item.last_name,
        email: item.email,
        lastLogin: item.last_login ? new Date(item.last_login) : undefined,
        companies: item.companies,
        companiesNames: [],
        projects: item.projects,
        projectsNames: [],
        status: item.status,
        role: item.role,
        username: item.username,
      })),
    };
    eventsData.forEach((event: any) => {
      const filteredCreatedByUser = usersData.results.filter(
        (user: any) => user.id === event.createdByUser
      );
      const filteredModifiedByUser = usersData.results.filter(
        (user: any) => user.id === event.modifiedByUser
      );
      if (event.createdByUser !== null && filteredCreatedByUser.length) {
        event.createdByUser = filteredCreatedByUser[0];
      }
      if (event.modifiedByUser !== null && filteredModifiedByUser.length) {
        event.modifiedByUser = filteredModifiedByUser[0];
      }
      if (event.meta && event.meta.user) {
        const user = usersData.results.find(
          (user: any) => event.meta.user === user.id
        );
        event.meta.user = user || event.meta.user;
      }
      return event;
    });
  }
};

export default fillEventUsers;
