import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialTypography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface Props extends TypographyProps {
  italic?: boolean;
  error?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      fontSize: theme.custom.typography.fontSize[12],
      letterSpacing: theme.typography.pxToRem(0.24),
      lineHeight: 1.5,
      color: ({ error }: Props) => {
        if (error) {
          return theme.palette.error.main;
        } else {
          return theme.custom.palette.label;
        }
      },
    },
  };
});

const Paragraph = (props: Props) => {
  const classes = useStyles(props);
  const { italic, error, ...rest } = props;
  return (
    <Box fontStyle={italic ? "italic" : "normal"}>
      <MaterialTypography className={classes.root} {...rest} variant="body1">
        {props.children}
      </MaterialTypography>
    </Box>
  );
};

export default Paragraph;
