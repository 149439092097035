import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { cellCompare } from "shared/helpers";
import { CellProps } from "react-table";
import actions from "store/deviceDetail/channelsList/actions";
import { Field, Form } from "react-final-form";
import Select from "components/select";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      height: theme.spacing(4),
    },
    select: {
      "& .MuiSelect-selectMenu": {
        minHeight: "auto",
      },
    },
    input: {
      fontSize: theme.custom.typography.fontSize["14"],
      letterSpacing: theme.custom.typography.letterSpacing["medium"],
      minHeight: "auto",
      height: "0.75rem",
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: 2.3,
    },
  };
});

const Gain = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { id, gain, disabled },
    },
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (target.value) {
        dispatch(actions.setGain(id, Number(target.value)));
      }
    },
    [dispatch, id]
  );

  return (
    <div className={classes.root}>
      <Form
        onSubmit={() => {}}
        initialValues={{ gain: String(gain) }}
        render={() => (
          <form>
            <Field
              name="gain"
              render={({ input, meta }) => (
                <Select
                  id={"gain" + id}
                  label={t("devices.detail.gain")}
                  className={classes.select}
                  disabled={disabled}
                  input={input}
                  onChange={handleChange}
                  meta={meta}
                  size="small"
                  name="gain"
                  inputProps={{
                    className: classes.input,
                  }}
                  options={["20", "30", "40", "50"]}
                />
              )}
            />
          </form>
        )}
      />
    </div>
  );
};

export default memo(Gain, cellCompare) as typeof Gain;
