import React, { useCallback, useMemo, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarsIcon from "@mui/icons-material/Stars";
import { useStyles } from "../styles";
import TooltipComponent from "components/tooltip";
import { KebabMenu } from "components/kebab";
import { MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { ICustomModel } from "types/customModels";
import ConfirmPopup from "components/confirmPopup";
import DeployModal from "components/machine/detail/customModelOverview/deployModal";
import patchArchiveModel from "api/handlers/machine/patchArchiveModel";
import patchUnarchiveModel from "api/handlers/machine/patchUnarchiveModel";

const CustomModelDetailBodyTrainings = ({ training }: any) => {
  const { t } = useTranslation();
  const tt = (i: string) => t(`machine.detail.model.${i}`);
  const classes = useStyles();
  const [openStatusTooltip, setOpenStatusTooltip] = useState(false);

  const [archive, setArchive] = useState(false);
  const [unarchive, setUnarchive] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickUnarchive = async () => {
    try {
      await patchUnarchiveModel(training.trained_model);
      enqueueSnackbar(tt("beingUnarchive"));
    } catch (e) {
      enqueueSnackbar(tt("errorUnarchive"));
    }
    setUnarchive(false);
  };

  const hideKebabMenu = useCallback((id: string) => {
    const hideKebab = new CustomEvent("hideKebab");
    const kebab = document.querySelector("#kebab-custom-model-trainings-" + id);
    kebab?.dispatchEvent(hideKebab);
  }, []);

  const handleClickArchive = async () => {
    try {
      await patchArchiveModel(training.trained_model);
      enqueueSnackbar(tt("beingArchived"));
    } catch (e) {
      enqueueSnackbar(tt("errorArchived"));
    }
    setArchive(false);
  };

  const generateItems = useMemo(() => {
    return (deploy: ICustomModel) => (
      <>
        {deploy.is_archived ? (
          <MenuItem
            onClick={() => {
              setUnarchive(true);
            }}
          >
            {tt("unarchive")}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setArchive(true);
              hideKebabMenu(deploy.task_id.toString());
            }}
          >
            {tt("archive")}
          </MenuItem>
        )}
      </>
    );
  }, [t]);

  const status = training?.status;
  const tooltipInnerStatus = (
    <span>
      {status === "not the best"
        ? tt("trainingSuccessful")
        : status === "selected"
          ? tt("modelSelected")
          : status === "training failed"
            ? tt("trainingFailed")
            : tt("metricsFailed")}
    </span>
  );

  return (
    <>
      <thead className={classes.break} />
      <TableBody className={classes.tableContent}>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            {training.model_type_name}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.trained_model}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {status === "training" ? (
              <CircularProgress size={19} />
            ) : (
              <TooltipComponent
                title={tooltipInnerStatus}
                onOpen={() => {
                  setOpenStatusTooltip(!openStatusTooltip);
                }}
              >
                {status === "not the best" ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : status === "selected" ? (
                  <StarsIcon style={{ color: "green" }} />
                ) : (
                  <ErrorIcon style={{ color: "red" }} />
                )}
              </TooltipComponent>
            )}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.metrics
              ? training.metrics?.metrics?.one_class_mean?.toFixed(2)
              : null}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.metrics
              ? training.metrics?.metrics?.one_class_std?.toFixed(2)
              : null}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.metrics
              ? training.metrics?.metrics?.one_class_min?.toFixed(2)
              : null}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.metrics
              ? training.metrics?.metrics?.one_class_max?.toFixed(2)
              : null}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.metrics
              ? training.metrics?.metrics?.one_class_metric?.toFixed(2)
              : null}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            <KebabMenu
              id={"kebab-custom-model-trainings-" + training.task_id}
              data={generateItems(training)}
            />
          </TableCell>
        </TableRow>
      </TableBody>

      {archive && (
        <ConfirmPopup
          onConfirm={handleClickArchive}
          title={tt("archiveDialog.title")}
          text={tt("archiveDialog.text")}
          confirmText={tt("archive")}
          noControl={true}
          onCancel={() => {
            setArchive(false);
          }}
        />
      )}
      {unarchive && (
        <ConfirmPopup
          onConfirm={handleClickUnarchive}
          title={tt("unarchiveDialog.title")}
          text={tt("unarchiveDialog.text")}
          confirmText={tt("unarchive")}
          noControl={true}
          onCancel={() => {
            setUnarchive(false);
          }}
        />
      )}
    </>
  );
};

export default CustomModelDetailBodyTrainings;
