import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IUserDetailFormFieldsPatch } from "types/user";
import { ERoleCodename } from "types/role";

interface Props extends IUserDetailFormFieldsPatch {
  userId?: number;
  isEmailNotified?: boolean;
  isPhoneNotified?: boolean;
  isAlertAssistant?: boolean;
}

interface Payload {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  is_active?: boolean;
  role?: ERoleCodename;
  language?: string;
  companies?: number[];
  projects?: number[];
  is_email_notified?: boolean;
  is_phone_notified?: boolean;
  is_alert_assistant?: boolean;
  display_currency?: string | null;
}

export default async function patchUser({
  userId,
  firstName,
  lastName,
  email,
  phone,
  isActive,
  role,
  language,
  companies,
  projects,
  isEmailNotified,
  isPhoneNotified,
  isAlertAssistant,
  displayCurrency,
}: Props) {
  const payload: Payload = {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    is_active: isActive,
    role: role ? role : undefined,
    language,
    companies,
    projects,
    is_email_notified: isEmailNotified,
    is_phone_notified: isPhoneNotified,
    is_alert_assistant: isAlertAssistant,
    display_currency: displayCurrency,
  };
  const url = userId ? endpoints.users.detail(userId) : endpoints.users.me;
  try {
    const response = await axiosNeuron.patch(url, payload);
    const { data } = response;
    return {
      id: data.id,
      lastLogin: data.last_login,
      isSuperUser: data.is_superuser,
      username: data.username,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      phone: data.phone,
      note: data.note,
      dateJoined: data.date_joined,
      isActive: data.is_active,
      isAdmin: data.is_admin,
      isStaff: data.is_staff,
      groups: data.groups,
      permissions: data.permissions,
      companies: data.companies,
      projects: data.projects,
      invitationStatus: data.invitation_status,
      role: data.role,
      displayCurrency: data.display_currency,
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
