import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import urls from "shared/appUrls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorScreen from "screens/error";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions from "store/layout/actions";
import Navigation from "./navigation";
import isEqual from "react-fast-compare";
import ErrorBoundary from "components/errorBoundary";
import IMuiTheme from "types/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    root: {
      display: "flex",
      minHeight: "calc(100vh)",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3, 3, 0, 3),
      },
    },
    menuButton: {
      float: "left",
      margin: theme.spacing(0.5, 2, 0, 0.5),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    content: {
      position: "relative",
      overflow: "hidden",
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: {
        borderRadius: theme.spacing(1, 1, 0, 0),
        background: theme.custom.palette.surface,
        boxShadow: theme.custom.boxShadow[2],
        padding: theme.spacing(3, 3, 0, 3),
        position: "relative",
        display: "flex",
        flexDirection: "column",
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(7, 4, 7, 4),
      },
      [theme.breakpoints.up("xl")]: {
        padding: theme.spacing(7, 7, 0, 7),
      },
    },
  };
});

type Props = {
  children?: React.ReactNode;
};

const ButtonBack = ({ location }: { location: string }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <IconButton
      color="inherit"
      aria-label={t("menu.openMenu")}
      edge="start"
      onClick={() => navigate(location)}
      className={classes.menuButton}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

const ButtonMenu = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <IconButton
      color="inherit"
      aria-label={t("menu.openMenu")}
      edge="start"
      style={{ float: "unset" }}
      onClick={toggleDrawer}
      className={classes.menuButton}
    >
      <MenuIcon />
    </IconButton>
  );
};

const Layout: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const user = useSelector((state: AppState) => state.auth.user, isEqual);

  const toggleDrawer = useCallback(
    () => dispatch(actions.toggleDrawer()),
    [dispatch]
  );

  return (
    <ErrorBoundary
      fallback={() => {
        return <ErrorScreen />;
      }}
    >
      <div className={classes.root}>
        {user && <Navigation />}
        <main className={classes.content}>
          <Routes>
            <Route
              path={urls.users.detailPattern()}
              element={<ButtonBack location={urls.users.index()} />}
            />
            <Route
              path={urls.machines.detailPattern()}
              element={<ButtonBack location={urls.users.index()} />}
            />
            <Route
              path={urls.appRoot.all()}
              element={<ButtonMenu toggleDrawer={toggleDrawer} />}
            />
          </Routes>
          <Outlet />
        </main>
      </div>
    </ErrorBoundary>
  );
};

export default Layout;
