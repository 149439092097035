import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialTypography, { TypographyProps } from "@mui/material/Typography";

type Props = TypographyProps;

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      fontSize: theme.custom.typography.fontSize["24"],
      fontWeight: theme.custom.typography.fontWeight.bold,
      color: theme.custom.palette.data,
      letterSpacing: "0.025rem",
      lineHeight: "1.33",
      margin: 0,
    },
  };
});

const Heading2 = (props: Props) => {
  const classes = useStyles(props);
  return (
    <MaterialTypography className={classes.root} {...props} variant="h2" />
  );
};

export default Heading2;
