import React, { useEffect } from "react";
import LabelComponent from "components/dataLabeling/labelComponent";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import isEqual from "react-fast-compare";
import Spinner from "components/spinner";
import actions, {
  fetchStaticData,
  fetchDays,
} from "store/machineDetail/sounds/actions";
import { createSelector } from "reselect";

const selector = createSelector(
  (state: AppState) => state.machineDetail?.machine,
  (state: AppState) => state.machineDetail.sounds,
  ({ machine }, sounds) => {
    return {
      ready: !!(
        machine?.id &&
        machine?.project &&
        sounds.placements &&
        sounds.yearData
      ),
      machine,
      timezoneOffset: sounds.timezoneOffset,
    };
  }
);
const DataLabeling: React.FC = (props: any) => {
  const dispatch: any = useDispatch();
  const { ready, machine, timezoneOffset } = useSelector(selector, isEqual);

  useEffect(() => {
    dispatch(fetchStaticData());
    return () => {
      dispatch(actions.setReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDays());
  }, [dispatch, timezoneOffset]);

  if (!ready) {
    return <Spinner />;
  }

  return (
    <LabelComponent machine={machine.id} project={machine.project} {...props} />
  );
};
export default DataLabeling;
