import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialTypography, { TypographyProps } from "@mui/material/Typography";

type Props = TypographyProps;

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      fontSize: theme.custom.typography.fontSize["16"],
      fontWeight: theme.custom.typography.fontWeight.bold,
      color: theme.custom.palette.data,
      letterSpacing: "0.025rem",
      lineHeight: "1.25",
      margin: 0,
    },
  };
});

const Heading2 = (props: Props) => {
  const { className, ...otherProps } = props;
  const classes = useStyles(otherProps);
  const classNames = className ? `${classes.root} ${className}` : classes.root;
  return (
    <MaterialTypography className={classNames} {...otherProps} variant="h2" />
  );
};

export default Heading2;
