import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
interface IUpdatePlacementimageParams {
  id: number;
  image_data: string;
  timezone?: string;
}

export default async function updatePlacementImage({
  id,
  image_data,
  timezone,
}: IUpdatePlacementimageParams) {
  const url = endpoints.placements.detailImages(id);

  try {
    const response = await axiosNeuron.patch(url, {
      id,
      image_data,
      tz: timezone,
    });
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
