import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IAttachmentParams {
  id: number;
}

export default async function removeAttachment({
  id,
}: IAttachmentParams): Promise<void> {
  const url = endpoints.events.attachment(id);
  try {
    await axiosNeuron.delete(url);
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
