import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getFailures from "api/handlers/failures/getFailures";
import type { TEventPlacementFailure } from "types/failureTypes";

type TInitialState = {
  data: TEventPlacementFailure[];
  loading: boolean;
  error: string | null;
};

const initialState: TInitialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchFailures = createAsyncThunk(
  "failures/fetchFailureTypes",
  async (eventId: number) => {
    const failures = await getFailures(eventId);
    return failures;
  }
);

export const { reducer } = createSlice({
  name: "failures",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchFailures.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(fetchFailures.fulfilled, (state: any, action: any) => {
      if (action.payload) {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      }
    });
    builder.addCase(fetchFailures.rejected, (state: any) => {
      state.data = [];
      state.loading = false;
      state.error = "Error while getting the data";
    });
  },
});
