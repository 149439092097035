import React from "react";
import { FieldRenderProps } from "react-final-form";
import { TextField } from "mui-rff";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import PasswordStrength from "components/passwordStrength";
import { Box } from "@mui/material";

interface Props extends FieldRenderProps<React.ReactText> {
  id?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  absolutePositionedMessage?: boolean;
  password?: boolean;
  showPasswordStrength?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    absolutePositionedMessage: {
      position: "absolute",
      bottom: theme.typography.pxToRem(-2),
      transform: "translateY(100%)",
    },
  };
});

const TextFieldComponent = (props: Props) => {
  const classes = useStyles();
  const {
    absolutePositionedMessage,
    meta,
    password,
    input,
    InputProps,
    showPasswordStrength,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { name } = input;
  return (
    <>
      <TextField
        {...rest}
        name={name}
        type={password && !showPassword ? "password" : "text"}
        variant="outlined"
        fullWidth
        FormHelperTextProps={{
          classes: {
            root: clsx(
              absolutePositionedMessage && classes.absolutePositionedMessage
            ),
          },
        }}
        InputProps={
          InputProps
            ? InputProps
            : {
                endAdornment: password ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }
        }
      />
      {showPasswordStrength && (
        <>
          <Box mt={3} />
          <PasswordStrength value={props.input.value.toString()} />
        </>
      )}
    </>
  );
};

export default TextFieldComponent;
