import { useMemo } from "react";
import { useAsyncReducer } from "store/selectTemplate";
import getEvents from "api/handlers/event/getEvents";
import { EventType } from "components/machine/types";
import type { IEventList } from "types/event";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import { useTranslation } from "react-i18next";
import order from "lodash/orderBy";

const useRelatedEvents = (value?: number | null, machine?: number) => {
  const { t } = useTranslation();
  const { data, loading, loadMore, loadingMore } = useAsyncReducer(
    machine ? "relatedEvents" : undefined,
    getEvents,
    {
      pageSize: 10,
      machine,
      eventTypes: [EventType.broken_start, EventType.maintenance_start],
      orderBy: [{ desc: true, id: "created_at" }] as any,
      ids: value ? [value] : undefined,
    }
  );

  const normalizedData = useMemo(() => {
    if (data?.results?.length) {
      const ordered = order(
        data.results,
        (item: any) => item.createdAt,
        "desc"
      );
      const result = ordered.map(({ id, createdAt, type }: IEventList) => ({
        value: id,
        text:
          t(`eventTypeNames.${type}`) +
          " " +
          dateToDDMMYYYY(createdAt) +
          " " +
          dateToHHMM(createdAt),
      }));

      return [
        { value: "", text: t("devices.detail.list.cell.placement") },
        ...result,
      ];
    }
    return [];
  }, [data, t]);

  return { data: normalizedData, loading, loadMore, loadingMore };
};
export default useRelatedEvents;
