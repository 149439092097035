import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  name: string;
  datasetData: {
    placement: number;
    start: string;
    end: string;
    representation_start: string;
    representation_end: string;
  }[];
};

export default async function addDataset(paramsInput: TParams) {
  const url = endpoints.eventSounds.dataset;

  const newDatasets = paramsInput.datasetData.map((item) => ({
    ...item,
    start: new Date(item.start).toISOString(),
    end: new Date(item.end).toISOString(),
    representation_start: new Date(item.representation_start).toISOString(),
    representation_end: new Date(item.representation_end).toISOString(),
  }));
  const params = {
    name: paramsInput.name,
    results: newDatasets,
  };
  try {
    const { data } = await axiosNeuron.post(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
