import { EActions, UsersFilterState, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: UsersFilterState = {
  filters: {},
};

const reducers: TReducers = {
  [EActions.setFilter]: ({ payload: { filters } }) => ({
    filters,
  }),
};

export default createReducer<UsersFilterState>(path, initialState, reducers);
