import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialTooltip, { TooltipProps } from "@mui/material/Tooltip";

const useStyles = makeStyles((theme: any) => {
  return {
    tooltip: {
      backgroundColor: theme.custom.palette.overlay,
      color: theme.custom.palette.surface,
      fontSize: theme.custom.typography.fontSize["12"],
      letterSpacing: ".4px",
      borderRadius: "2px",
      border: "none",
      width: "auto",
      maxWidth: "none",
    },
  };
});

const Tooltip = (props: TooltipProps) => {
  const classes = useStyles(props);
  return (
    <MaterialTooltip classes={classes} {...props}>
      {props.children}
    </MaterialTooltip>
  );
};

export default Tooltip;
