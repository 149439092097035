import { PageActionTypes, PageState, PAGE_LOADING } from "./types";

const initialState: PageState = {
  loading: true,
};

const reducer = (state = initialState, action: PageActionTypes): PageState => {
  switch (action.type) {
    case PAGE_LOADING: {
      return {
        loading: action.payload.loading,
      };
    }
    default:
      return state;
  }
};
export default reducer;
