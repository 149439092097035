import { EActions, IProjectState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IProjectState = {
  facilities: undefined,
  pageIndex: 0,
  loading: false,
  reloading: true,
  editing: null,
  sort: [],
  showMore: 0,
  modalLoading: false,
  modalOpened: false,
};

const reducers = {
  [EActions.setFacilities]: ({ payload: { facilities } }: IActionTypes) => ({
    facilities,
    loading: false,
    reloading: false,
    editing: null,
  }),
  [EActions.setLoading]: ({ payload: { loading } }: IActionTypes) => ({
    loading,
  }),
  [EActions.setReloading]: ({ payload: { reloading } }: IActionTypes) => ({
    reloading,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }: IActionTypes) => ({
    pageIndex,
  }),
  [EActions.setEditing]: ({ payload: { editing } }: IActionTypes) => ({
    editing,
  }),
  [EActions.setSort]: ({ payload: { sort } }: IActionTypes) => ({ sort }),
  [EActions.setShowMore]: ({ payload: { showMore } }: IActionTypes) => ({
    showMore,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
  [EActions.setModalLoading]: ({
    payload: { modalLoading },
  }: IActionTypes) => ({ modalLoading }),
  [EActions.setModal]: ({ payload: { modalOpened } }: IActionTypes) => ({
    modalLoading: false,
    modalOpened,
  }),
};
export default createReducer<IProjectState>(path, initialState, reducers);
