import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IChannelList } from "types/channels";

interface IChannelListResponse {
  results: IChannelList[];
}

type Props = {
  machine?: number;
};

export default async function getChannels(params: Props) {
  const url = endpoints.channels.default;
  try {
    const { data } = await axiosNeuron.get(url, { params });
    const result: IChannelListResponse = {
      results: data.results,
    };
    return result;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
