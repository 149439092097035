import React from "react";
import { UseTableInstanceProps } from "react-table";
import HeaderLabel from "components/table/header/label";

interface Props<T extends object>
  extends Pick<UseTableInstanceProps<T>, "flatHeaders"> {
  hide?: boolean;
}

const Header = <T extends object>(props: Props<T>) => {
  const { flatHeaders, hide } = props;

  if (hide) {
    return <></>;
  }

  return (
    <tr>
      {flatHeaders.map((column, index) => {
        if (!column.isVisible) {
          return null;
        }

        const { key: headerKey, ...headerProps } = column.getHeaderProps(
          column.getSortByToggleProps()
        );

        return (
          <th key={index} style={{ paddingBottom: 5 }}>
            <HeaderLabel
              key={headerKey}
              {...headerProps}
              sorted={
                column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : ""
              }
              margin={column.custom.margin}
              alignRight={column.custom.alignRight ? true : false}
            >
              <div>{column.render("Header")?.toString()}</div>
            </HeaderLabel>
          </th>
        );
      })}
    </tr>
  );
};

export default Header;
