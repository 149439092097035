import { CircularProgress } from "@mui/material";
import { IPlacementList } from "types/placement";
import { IMachineList } from "types/machine";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { CellProps } from "react-table";

const Label = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { label },
    },
  } = props;

  const { machines, placements, labelFetching } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const splitted = label.split("/").map((item: any) => item.trim());
  const machine = splitted[0];
  const placement = splitted[1];

  const placementValue = placements.find(
    (item: IPlacementList) => Number(item.id) === Number(placement)
  );
  const machineValue = machines.find(
    (item: IMachineList) => Number(item.id) === Number(machine)
  );

  return (
    <div>
      {labelFetching ? (
        <CircularProgress />
      ) : (
        `${machineValue?.name} / ${placementValue?.customName}`
      )}
    </div>
  );
};

export default Label;
