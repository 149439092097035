import { memo, useCallback, useState, useEffect } from "react";
import Divider from "components/typography/heading/divider";
import { Field, Form } from "react-final-form";
import Select from "components/select";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { AppState } from "store";
import { useSelector, useDispatch } from "react-redux";
import isEqual from "react-fast-compare";
import Spinner from "components/spinner";
import actions from "store/deviceDetail/channelsList/actions";
import { makeStyles } from "@mui/styles";
import { TRecordingPlans } from "types/device";
import i18n from "localization";
import Button from "components/button";
import { useParams } from "react-router-dom";
import patchDevice from "api/handlers/device/patchDevice";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  form: {
    maxWidth: 326,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
}));

const selector = createSelector(
  (state: AppState) => state.deviceDetail.detail,
  (state: AppState) => state.deviceDetail.channelsList,
  ({ device }, { recordingPlans, recordingPlan }) => {
    return {
      data: recordingPlans?.map((item: TRecordingPlans) => ({
        text: i18n.t(`recordingPlan.${item.codename}`),
        value: item.codename,
      })),
      statePlan: recordingPlan,
      picked:
        recordingPlans?.find(
          ({ codename }: TRecordingPlans) => codename === device?.recordingPlan
        ) ?? "",
      isRecordingEnabled: device.isRecordingEnabled,
    };
  }
);

type TProps = {
  disabled: boolean;
};

const RecordingPlans = memo(({ disabled }: TProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { data, picked, statePlan, isRecordingEnabled } = useSelector(
    selector,
    isEqual
  );
  const { id } = useParams<{ id?: string }>();
  const deviceId = Number(id);
  const [isEnableLoading, setIsEnableLoading] = useState(false);
  const [isDisableLoading, setIsDisableLoading] = useState(false);
  const [recordingEnabled, setRecordingEnabled] = useState(false);

  useEffect(
    () => setRecordingEnabled(isRecordingEnabled),
    [isRecordingEnabled]
  );

  const handleEnableRecording = useCallback(async () => {
    setIsEnableLoading(true);
    try {
      await patchDevice({
        id: deviceId,
        payload: {
          is_recording_enabled: true,
        },
      });
      setRecordingEnabled(true);
    } finally {
      setIsEnableLoading(false);
    }
  }, []);

  const handleDisableRecording = useCallback(async () => {
    setIsDisableLoading(true);
    try {
      await patchDevice({
        id: deviceId,
        payload: {
          is_recording_enabled: false,
        },
      });
      setRecordingEnabled(false);
    } finally {
      setIsDisableLoading(false);
    }
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      dispatch(actions.setRecordingPlan(e.target.value));
    },
    [dispatch]
  );

  if (!data || !data.length) {
    return <Spinner />;
  }
  return (
    <>
      <Divider line>{t("devices.detail.recordingPlans")}</Divider>
      <Box className={classes.root}>
        <Form
          className={classes.form}
          onSubmit={() => {}}
          initialValues={{ recordingPlan: statePlan || picked?.codename }}
          render={() => (
            <form>
              <Field
                name="recordingPlan"
                render={({ input, meta }) => (
                  <Select
                    id="recordingPlan"
                    label={t("devices.detail.recordingPlans")}
                    disabled={disabled}
                    displayTags
                    input={input}
                    onChange={handleChange}
                    meta={meta}
                    name="recordingPlan"
                    options={data}
                  />
                )}
              />
            </form>
          )}
        />
        <Button
          disabled={isEnableLoading || isDisableLoading || recordingEnabled}
          color="secondary"
          onClick={handleEnableRecording}
        >
          {t("devices.detail.enableRecording")}
        </Button>
        <Button
          disabled={isDisableLoading || isEnableLoading || !recordingEnabled}
          color="secondary"
          onClick={handleDisableRecording}
        >
          {t("devices.detail.disableRecording")}
        </Button>
      </Box>
    </>
  );
});

export default RecordingPlans;
