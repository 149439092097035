import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import patchMachine from "api/handlers/machine/patchMachine";
import { useDispatch } from "react-redux";
import { fetchMachineDetail } from "store/machineDetail/actions";
import Box from "@mui/material/Box";
import Divider from "components/typography/heading/divider";
import Spinner from "components/spinner";
import SearchMenu from "components/searchMenu";
import Grid from "@mui/material/Grid";
import timeZones from "shared/timezones.json";
import { SelectChangeEvent } from "@mui/material";

interface Props {
  timeZone: string;
  disabled?: boolean;
  id: number;
}

const SectionTimezone: React.FC<Props> = memo(({ timeZone, id, disabled }) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [patchMachineMutation, { status }] = useMutation(patchMachine);

  const handleTZChange = (e: SelectChangeEvent<{ value: unknown }>) => {
    if (typeof e.target.value !== "string") {
      return false;
    }
    patchMachineMutation(
      {
        id,
        payload: {
          time_zone: e.target.value,
        },
      },
      {
        onSuccess: () => {
          dispatch(fetchMachineDetail(id));
        },
      }
    );
  };

  const options = timeZones.map((item: any) => ({ text: item, value: item }));

  return (
    <>
      <Divider line>{t("machine.detail.timezone")}</Divider>
      {status === "loading" ? (
        <Spinner />
      ) : (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} md={2}>
            <Box mb={1} display="flex" flexDirection="column">
              <SearchMenu
                id="id"
                label={t("machine.detail.timezone")}
                options={options}
                value={timeZone}
                onChange={handleTZChange}
                disabled={disabled}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default SectionTimezone;
