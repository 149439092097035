let worker: any;
if (typeof Worker !== "undefined") {
  worker = new Worker("/drawWorker.js");
} else {
  // Provide a fallback if needed, or leave it as null
  worker = null;
}

const getWorker = () => worker;
export default getWorker;
