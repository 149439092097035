import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function getPlacementImage(placement: number) {
  const url = endpoints.placements.detail(placement);
  try {
    const { data } = await axiosNeuron.get(`${url}image/`);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
