export enum ERoleCodename {
  nswAdmin = "nsw_admin",
  partnerAdmin = "partner_admin",
  customerAdmin = "customer_admin",
  customer = "customer",
}

export interface IRole {
  id?: number;
  groups?: number[];
  name?: string;
  codename: string;
  description?: string;
  order: number;
}
