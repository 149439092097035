import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  name: string;
  company: number;
  type?: number;
  description?: string;
  bucket?: string;
  database?: string;
  users?: number[];
};

export default async function addProject(project: Params) {
  const url = endpoints.projects.default;
  try {
    const response = await axiosNeuron.post(url, project);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
