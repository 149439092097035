import { useCallback, useEffect, useMemo } from "react";
import actions, { fetchCP } from "store/companiesAndProjects/actions";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import isEqual from "react-fast-compare";
import { createSelector } from "reselect";

const { setActiveCompanies, setActiveProjects, setCPPage } = actions;

const selector = createSelector(
  (state: AppState) => state.companiesAndProjects,
  (cp: any) => ({
    ...cp,
  })
);

const useDetailData = () => {
  const dispatch: any = useDispatch();
  const {
    pageIndex,
    activeCompanies,
    activeProjects,
    originalActiveCompanies,
    originalActiveProjects,
    CPData,
    isFetching,
  } = useSelector(selector, isEqual);

  useEffect(() => {
    dispatch(fetchCP({ pageIndex }));
  }, [dispatch, pageIndex]);

  const onChangePage = useCallback(
    (num: any) => {
      dispatch(setCPPage(num));
    },
    [dispatch]
  );

  const pageCount = useMemo(() => Math.ceil(CPData?.count / 10), [CPData]);

  const onDiscardChanges = useCallback(() => {
    dispatch(setActiveCompanies(originalActiveCompanies));
    dispatch(setActiveProjects(originalActiveProjects));
  }, [dispatch, originalActiveCompanies, originalActiveProjects]);

  const goToNext = useCallback(() => {
    if (pageIndex < pageCount - 1) {
      onChangePage(pageIndex + 1);
    }
  }, [pageIndex, pageCount, onChangePage]);

  const goToPrevious = useCallback(() => {
    if (pageIndex !== 0) {
      onChangePage(pageIndex - 1);
    }
  }, [pageIndex, onChangePage]);

  return {
    CPData,
    pageIndex,
    pageCount,
    originalActiveCompanies,
    originalActiveProjects,
    activeCompanies,
    activeProjects,
    isFetching,
    onChangePage,
    onDiscardChanges,
    goToNext,
    goToPrevious,
  };
};
export default useDetailData;
