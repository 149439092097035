import React, { memo } from "react";
import ScreenHeading from "screens/sharedParts/screenHeading";
import { Modal } from "components/modal";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import Centered from "components/centered";
import Heading1 from "components/typography/heading/heading1";
import { useStyles } from "./styles";

interface IAddEventModal {
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  callToAction?: () => void;
  callToActionTitle?: string;
  children: JSX.Element;
  title: string;
  cta: string;
  submitDisabled?: boolean;
  loading?: boolean;
  className?: string;
  onSubmit: () => void;
}
export const ModalWrap = memo(
  ({
    children,
    open,
    title,
    loading,
    cta,
    submitDisabled,
    className,
    onCancel,
    onSubmit,
  }: IAddEventModal) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Modal
        onCancel={onCancel}
        className={`${classes.modalWrapper} ${className}`}
        open={open}
      >
        {loading ? (
          <Centered>
            <Spinner />
          </Centered>
        ) : (
          <Grid
            container
            justifyContent="space-between"
            direction="column"
            className={classes.fullHeight}
            wrap="nowrap"
          >
            <Grid
              container
              className={classes.root}
              direction="column"
              wrap="nowrap"
            >
              <Grid item xs={12}>
                <ScreenHeading>
                  <Heading1 id="testMachineDetailHeading">{title}</Heading1>
                </ScreenHeading>
              </Grid>
            </Grid>
            {children}
            <Grid
              container
              justifyContent="space-between"
              direction="column"
              className={classes.footer}
            >
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Button onClick={onCancel} data-cy="cancle">
                      {t("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={submitDisabled}
                      onClick={onSubmit}
                      id="testModalSubmit"
                      data-cy="cta"
                    >
                      {cta}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Modal>
    );
  }
);
