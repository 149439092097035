import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IFacility } from "types/facility";
import { ResponseType } from "types/response";
import { SortingRule } from "react-table";

type Params = {
  ids?: number[];
  orderBy?: SortingRule<number>[];
  projectIds?: number[];
  pageSize?: number;
  page?: number;
  q?: string;
  nextUrl?: string;
  field?: string[];
};

export default async function getFacilities(paramsInput: Params) {
  if (paramsInput.ids && !paramsInput.ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IFacility>;
  }
  const params = {
    id: paramsInput.ids,
    project: paramsInput.projectIds,
    ps: paramsInput.pageSize,
    page: paramsInput.page,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
    field: paramsInput.field,
  };
  const orderByString = paramsInput.orderBy?.length
    ? `&order_by=${paramsInput.orderBy
        .map((item: any) => `${!item.desc ? "-" : ""}${item.id}`)
        .join("&order_by=")}`
    : "";
  try {
    const response = await axiosNeuron.get(
      paramsInput.nextUrl || `${endpoints.facilities.default}?${orderByString}`,
      paramsInput.nextUrl
        ? {}
        : {
            params,
          }
    );

    const data: ResponseType<IFacility> = {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results.map((item: any) => ({
        ...item,
      })),
    };

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
