import { LangEnum } from "localization";
import values from "lodash/values";
import { branches, environment } from "settings";

function parseDotNotation(str: string, val: string, obj: any) {
  try {
    const keys = str.split(".");
    const l = Math.max(1, keys.length - 1);
    let currentObj = obj,
      i,
      key;
    for (i = 0; i < l; ++i) {
      key = keys[i];
      currentObj[key] = currentObj[key] || {};
      currentObj = currentObj[key];
    }
    currentObj[keys[i]] = val;
    delete obj[str];
  } catch (err) {}
}

const mapFunc = (obj: any) => {
  for (const key in obj) {
    if (key.indexOf(".") !== -1) {
      parseDotNotation(key, obj[key], obj);
    }
  }
  return obj;
};

const parser = (data: string) => {
  const obj: { [keys: string]: any } = {};
  for (const j in values(LangEnum)) {
    obj[values(LangEnum)[j]] = {};
    for (const i of data.split("\n")) {
      const key = i.split(",")[0];
      let value = i
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        [Number(j) + 1]?.replace(/\r?\n|"|\r/g, "");
      if (
        value &&
        values(branches).every((i: any) => value.indexOf(i) !== -1)
      ) {
        const splitted = value.split(";") as string[];
        if (splitted && splitted?.length && environment) {
          const env = environment as string;
          value =
            splitted
              .find((item: string) => item.indexOf(env) !== -1)
              ?.split("=")[1] || value;
        }
      }
      obj[values(LangEnum)[j]][key] = value;
    }
    obj[values(LangEnum)[j]] = mapFunc(obj[values(LangEnum)[j]]);
  }
  return obj;
};

export default parser;
