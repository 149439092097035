import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import MemoryIcon from "@mui/icons-material/Memory";

import Tag from "components/tag";
import Heading4 from "components/typography/heading/heading4";
import Paragraph from "components/typography/paragraph";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "inline-block",
    background: theme.custom.palette.surface,
    border: "solid",
    borderRadius: theme.spacing(1),
    borderWidth: theme.spacing(0.125),
    borderColor: theme.custom.palette.shade,
    cursor: ({ onClick }: Props) => (onClick ? "pointer" : "auto"),
  },
}));

interface Props {
  name: string;
  deviceType?: string;
  id?: number;
  onClick?: (id: number) => void;
}

const DeviceCard = (props: Props) => {
  const { name, deviceType, id, onClick } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const onClickEvent = useCallback(() => {
    onClick && id && onClick(id);
  }, [id, onClick]);

  return (
    <div className={classes.root} onClick={onClickEvent}>
      <Box
        py={1.5}
        px={2.5}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box mr={2.5} mt={0.75}>
          <MemoryIcon />
        </Box>
        {deviceType && (
          <Box mr={2.5}>
            <Tag color="primary">{deviceType}</Tag>
          </Box>
        )}
        <Box>
          <Heading4>
            <span className="testDeviceCardUID">{name}</span>
          </Heading4>
          <Paragraph>{t("machine.detail.deviceName")}</Paragraph>
        </Box>
      </Box>
    </div>
  );
};

export default DeviceCard;
