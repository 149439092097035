import { SortingRule } from "react-table";

import { IUserList } from "types/user";
import { ResponseType } from "types/response";

export enum EActions {
  setUsersFilter = "setUsersFilter",
  setSearch = "setSearch",
  setPage = "setPage",
  fetchUsersRequest = "fetchUsersRequest",
  fetchUsersFail = "fetchUsersFail",
  fetchUsersParams = "fetchUsersParams",
  fetchUsersSuccess = "fetchUsersSuccess",
  setReset = "setReset",
  setMore = "setMore",
  setUserChecked = "setUserChecked",

  fetchProjectUsersRequest = "fetchProjectUsersRequest",
  fetchProjectUsersFail = "fetchProjectUsersFail",
  fetchProjectUsersSuccess = "fetchProjectUsersSuccess",
}
export interface IUsersState {
  filters?: { [type: string]: string[] };
  q: string;
  pageIndex: number;
  usersData?: ResponseType<IUserList> | null;
  usersFetchParams?: IFetchUsersParams | null;
  isFetching?: boolean;
  error?: Error | null;
  more: number;
  userIds?: number[];
  projectusers: ResponseType<IUserList> | null;
  loading?: boolean;
}

export interface IUsersAction {
  type: typeof EActions;
  payload: IUsersState;
}

export type TReducers = {
  [key: string]: (p: IUsersAction, s: IUsersState) => void;
};

export type TUsersActionTypes = IUsersAction;

export interface IFetchUsersParams {
  page: number;
  pageSize: number;
  orderBy: SortingRule<string>[];
  filterObject?: { [key: string]: number[] | string[] };
  q?: string;
}
