import { createReducer } from "store/helpers";
import { path } from "./actions";
import { EActions, TReducers, Istate } from "./types";

const initialState: Istate = {
  filters: {},
  currentPage: 0,
};

const reducers: TReducers = {
  [EActions.setFilter]: ({ payload: { filters } }, state) => ({
    filters: { ...state.filters, ...filters },
    currentPage: 0,
  }),
  [EActions.setPage]: ({ payload }) => ({
    currentPage: payload.currentPage,
  }),
  [EActions.reset]: () => ({
    ...initialState,
  }),
};

export default createReducer<Istate>(path, initialState, reducers);
