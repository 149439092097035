import React, { memo, useMemo, useEffect } from "react";
import useData from "dataHooks/projects/reports/dataHook";
import useUserData from "dataHooks/projects/users/dataHook";
import Box from "@mui/material/Box";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import { createColumns } from "./column";
import { createTinyColumns } from "components/projects/detail/reportsList/tinyColumns";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material//useMediaQuery";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import actions from "store/projectDetail/reports/actions";
import { IReportsList } from "types/report";
import { AddReportsModal } from "../modal/addReport";
import { useParams } from "react-router-dom";
import useProjectsData from "dataHooks/projects/detailData";

const { setReset } = actions;

export const ReportsTab = memo(() => {
  const { id } = useParams<{ [key: string]: string | undefined }>();
  const { project } = useProjectsData(Number(id));
  const {
    reports,
    isFetching,
    initialState,
    modalOpened,
    showMoreClickedTimesRef,
    onPageChange,
    recommendations: recommendation,
    inputMaxLength,
    recommendationLengthError,
  } = useData(Number(id));

  const { userIds } = useUserData(project.company, "active", 9999);

  const theme = useTheme();
  const { t } = useTranslation();
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const dispatch: any = useDispatch();
  const tinyColumnsMemoized = useMemo(
    () =>
      createTinyColumns(
        t,
        inputMaxLength,
        recommendationLengthError,
        project.company,
        Number(id),
        userIds,
        recommendation
      ),
    [
      t,
      inputMaxLength,
      recommendationLengthError,
      recommendation,
      project.company,
      Number(id),
      userIds,
    ]
  );
  const columnsMemoized = useMemo(
    () =>
      createColumns(
        t,
        inputMaxLength,
        recommendationLengthError,
        project.company,
        Number(id),
        userIds,
        recommendation
      ),
    [
      t,
      inputMaxLength,
      recommendationLengthError,
      recommendation,
      project.company,
      Number(id),
      userIds,
    ]
  );
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, [dispatch]);

  return (
    <>
      <Box>
        <Table<IReportsList>
          showMoreClickedTimesRef={showMoreClickedTimesRef}
          isLoading={isFetching}
          data={reports?.results || []}
          rowsCount={reports?.count || 0}
          columns={columns}
          initialState={initialState}
          onPageChange={onPageChange}
          RowComponent={Row}
          RowComponentProps={{
            rowHeight: theme.spacing(7),
            rowWidth: "100%",
          }}
          HeaderComponent={Header}
          hideNoData={isFetching}
        />
      </Box>
      <AddReportsModal open={modalOpened} projectId={Number(id)} />
    </>
  );
});
