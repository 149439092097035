import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Checkbox from "./index";
import EditIcon from "@mui/icons-material/Edit";
import EditBar from "components/editBar";

const useStyles = makeStyles((theme: any) => ({
  checkboxWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: theme.spacing(4),
  },
  data: {
    display: "flex",
    alignItems: "center",
  },
  dataSpan: {
    margin: theme.spacing(1, 1, 0, 0),
    height: theme.spacing(3),
    color: theme.custom?.palette.label,
    fontSize: theme.custom?.typography.fontSize[12],
  },
  dataEdit: {
    marginRight: theme.spacing(1),
    height: theme.spacing(3),
  },
  editIcon: {
    transition: ".3s",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
}));

interface Props {
  label: string;
  fontSize?: string;
  onSetChange?: (name: string) => void;
  disableCheck?: boolean;
  data?: string;
  entity?: string;
  disableEdit?: boolean;
  checked: boolean;
  onChange: (newValue: boolean) => void;
  onDelete?: any;
  deleteTitle?: any;
  deleteText?: any;
}

const EditCheckbox = (props: Props) => {
  const {
    label,
    fontSize,
    data,
    onSetChange,
    disableCheck,
    disableEdit,
    checked,
    onChange,
  } = props;
  const classes = useStyles(props);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState(label);

  const handleEditOnClick = (value: string) => {
    if (value) {
      if (onSetChange) onSetChange(value);
      setValue("");
      setPlaceholder(value);
      setEdit(false);
    } else {
      setEdit(false);
      setValue("");
    }
  };

  const handleChange = async () => {
    if (!!onChange) {
      onChange(!checked);
    }
  };

  return (
    <>
      {!edit && (
        <div className={classes.checkboxWrapper}>
          <Checkbox
            label={label}
            disabled={disableCheck}
            fontSize={fontSize}
            checked={checked}
            onChange={handleChange}
          />
          <div className={classes.data}>
            <>
              <div className={classes.dataSpan}>{data}</div>
              <div className={classes.dataEdit}>
                {!disableEdit && (
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => setEdit(true)}
                  />
                )}
              </div>
            </>
          </div>
        </div>
      )}
      {edit && (
        <EditBar
          entity={props.entity}
          value={value}
          placeholder={placeholder}
          onClick={handleEditOnClick}
          onChange={(e: any) => setValue(e.target.value)}
          isEditation={true}
        />
      )}
    </>
  );
};
export default EditCheckbox;
