import { EActions, IEventsExporterState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IEventsExporterState = {
  modalOpen: false,
  exporting: false,
};

const reducers = {
  [EActions.setModalOpen]: ({ payload: { modalOpen } }: IActionTypes) => ({
    modalOpen,
  }),
  [EActions.reset]: () => ({
    ...initialState,
  }),
  [EActions.setExportStart]: () => ({
    exporting: true,
  }),
  [EActions.setExportEnd]: () => ({
    exporting: false,
  }),
};

export default createReducer<IEventsExporterState>(
  path,
  initialState,
  reducers
);
