import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import EPermissions from "shared/permissions";
import { verifyPermission } from "shared/helpers";

const usePermissions = (test: EPermissions[] | EPermissions) => {
  const userPermissions = useSelector(
    (state: AppState) => state.auth.user?.permissions
  );

  return useMemo(
    () => !!verifyPermission(test, userPermissions),
    [test, userPermissions]
  );
};

export default usePermissions;
