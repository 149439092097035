import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import Divider from "components/typography/heading/divider";
import Paragraph from "components/typography/paragraph";
import Button from "components/button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";

const useStyles = makeStyles((theme: any) => ({
  root: {
    maxWidth: 326,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
}));

type Props = {
  disabledDelete: boolean;
  openModal: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const selector = createSelector(
  (state: AppState) => state.deviceDetail.detail,
  (detail: any) => {
    return detail.device?.project || undefined;
  }
);

export default memo(({ disabledDelete, openModal }: Props) => {
  const project = useSelector(selector);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Divider line>{t("actions")}</Divider>
      <Grid container>
        <Grid item className={classes.root}>
          <Box mb={2}>
            <Button
              id="deleteButton"
              disabled={!project || !disabledDelete}
              onClick={openModal}
              color="secondary"
            >
              {t("devices.list.actions.delete")}
            </Button>
          </Box>
          <Box pb={2}>
            <Paragraph italic>{t("devices.list.actions.hintDelete")}</Paragraph>
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2}>
            <Button
              id="moveButton"
              disabled={!project || !disabledDelete}
              onClick={openModal}
              color="secondary"
            >
              {t("devices.list.actions.move")}
            </Button>
          </Box>
          <Box pb={2}>
            <Paragraph italic>{t("devices.list.actions.hintMove")}</Paragraph>
          </Box>
        </Grid>
      </Grid>
    </>
  );
});
