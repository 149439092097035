import React from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { roles } from "shared/roles";

import SelectComponent from "components/select";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { IRole } from "types/role";
import { getTranslatedRole } from "shared/roles";

interface IProps {
  editMode?: boolean;
  required?: boolean;
  validate?: any;
  disabled?: boolean;
}

const RoleSelect = React.memo(
  ({ editMode, required, validate, disabled }: IProps) => {
    const { t } = useTranslation();

    const role = useSelector((state: AppState) => state.auth.user?.role);

    const currentUserRole = roles.find((item: IRole) => item.codename === role);

    const currentUserOrder =
      currentUserRole !== undefined && currentUserRole.order;

    let options =
      currentUserRole !== undefined
        ? roles
            .filter((item: IRole) => item.order >= currentUserOrder)
            .map((item: IRole) => {
              const text = getTranslatedRole(t, item.codename);
              return {
                key: item.codename,
                value: item.codename,
                text,
                color: /^NSW/.test(text) ? "primary" : undefined,
              };
            })
        : [];

    return (
      <Field name="role" required={required} validate={validate}>
        {({ input, meta }) => {
          let higherRoleOrder = false;
          const selectedRole = roles.find(
            (item: IRole) => item.codename === input?.value
          );
          const selectedRoleOrder =
            selectedRole !== undefined && selectedRole.order;
          if (editMode && typeof selectedRoleOrder === "number") {
            higherRoleOrder = currentUserOrder > selectedRoleOrder;
            if (higherRoleOrder) {
              options =
                currentUserOrder !== undefined
                  ? roles
                      .filter((item: IRole) => item.order === selectedRoleOrder)
                      .map((item: IRole) => {
                        const text = getTranslatedRole(t, item.codename);
                        return {
                          key: item.codename,
                          value: item.codename,
                          text,
                          color: /^NSW/.test(text) ? "primary" : undefined,
                        };
                      })
                  : [];
            }
          }
          return (
            <SelectComponent
              id="role"
              label={t("users.detail.role")}
              name="role"
              displayTags
              options={options}
              defaultValue={false}
              required
              value={input.value}
              onChange={input.onChange}
              input={input}
              meta={meta}
              disabled={disabled || higherRoleOrder}
            />
          );
        }}
      </Field>
    );
  }
);

export default RoleSelect;
