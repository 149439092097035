import { Column } from "react-table";
import CombinedCell from "./cells/combined";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import { getTranslatedRole } from "shared/roles";

export const createTinyColumns = (t: TFunction): Column<IUserList>[] => [
  {
    id: "last_name",
    Header: t("users.list.table.name").toString(),
    accessor: ({ firstName, lastName, role, email }) => {
      return {
        name: [firstName, lastName].join(" "),
        role: getTranslatedRole(t, role),
        label: email,
      };
    },
    Cell: CombinedCell,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
];
