import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import getDevicesExtended, {
  IFetchDevicesParams,
} from "api/handlers/device/getDevicesExtended";
import { ResponseType } from "types/response";
import { IExtendedDevice, IDeviceStatus } from "types/device";
import { IProjectList } from "types/project";
import { IFacility } from "types/facility";
import { ICompanyList } from "types/company";
import getProjects from "api/handlers/project/getProjects";
import getFacilities from "api/handlers/facility/getFacilities";
import getCompanies from "api/handlers/company/getCompanies";
import getDeviceStatuses from "api/handlers/device/getDeviceStatuses";
import getDeviceHealth from "api/handlers/device/getDeviceHealth";
import { EActions, IDevicesAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";

export const path = "devicesList";

const actionsData = [
  [EActions.setDevicesPage, "pageIndex"],
  [EActions.setDevicesFilter, "filters"],
  [EActions.setDevicesOrderBy, "orderBy"],
  [EActions.setDevicesMore, "more"],
  [EActions.resetDevices],
  [EActions.fetchDataRequest],
  [EActions.fetchDataFail, "error"],
  [
    EActions.fetchDataSuccess,
    "devices",
    "devicesFetchParams",
    "more",
    "deviceStatuses",
  ],
  [EActions.setFilter, "isFiltered"],
];

const actions = prepareActions<IDevicesAction, EActions>(actionsData, path);
export default actions;

export const fetchDevices =
  (
    { page, pageSize, orderBy, filterObject, q, ids }: IFetchDevicesParams,
    more?: boolean
  ) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDataRequest]());
    try {
      const response = (await getDevicesExtended({
        page,
        pageSize: pageSize || 500,
        orderBy,
        filterObject,
        q,
        ids,
      })) as ResponseType<IExtendedDevice>;
      const { results: devicesData } = response;

      const allProjectsIds: number[] = devicesData.reduce(
        (acc: number[], cur: IExtendedDevice) => {
          if (cur.project !== null && acc.indexOf(cur.project) < 0) {
            acc.push(cur.project);
          }
          return acc;
        },
        []
      );
      const allFacilitiesIds: number[] = devicesData.reduce(
        (acc: number[], cur: IExtendedDevice) => {
          if (cur.facility !== null && acc.indexOf(cur.facility) < 0) {
            acc.push(cur.facility);
          }
          return acc;
        },
        []
      );
      const allCompaniesIds: number[] = devicesData.reduce(
        (acc: number[], cur: IExtendedDevice) => {
          if (cur.company !== null && acc.indexOf(cur.company) < 0) {
            acc.push(cur.company);
          }
          return acc;
        },
        []
      );

      const { results: projectsData } = (await getProjects({
        ids: allProjectsIds,
      })) as ResponseType<IProjectList>;
      const { results: facilitiesData } = (await getFacilities({
        ids: allFacilitiesIds,
      })) as ResponseType<IFacility>;
      const { results: companiesData } = (await getCompanies({
        ids: allCompaniesIds,
      })) as ResponseType<ICompanyList>;
      const deviceStatuses = await getDeviceStatuses();
      const deviceHealth: { [key: number]: number } = await getDeviceHealth({
        ids: devicesData.map((device: any) => device.id),
      });
      dispatch(
        actions.fetchDataSuccess(
          {
            ...response,
            results: devicesData.map((device: any) => {
              return {
                ...device,
                projectName: projectsData.find(
                  (project: IProjectList) => project.id === device.project
                )?.name,
                facilityName: facilitiesData.find(
                  (facility: IFacility) => facility.id === device.facility
                )?.name,
                companyName: companiesData.find(
                  (company: ICompanyList) => company.id === device.company
                )?.name,
                status: deviceStatuses.find(
                  (item: IDeviceStatus) => item.codename === device.status
                )?.name,
                health: deviceHealth[device.id],
              };
            }),
          },
          { page, pageSize: pageSize || 500, orderBy, filterObject, q, ids },
          more,
          deviceStatuses
        )
      );
    } catch (err) {
      dispatch(actions[EActions.fetchDataFail](err));
    }
  };

export const refetchDevicesSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { pageIndex, devicesFetchParams, orderBy, filters, q } =
      getState().devicesList;
    const pageSize = devicesFetchParams?.pageSize;
    try {
      const response = (await getDevicesExtended({
        page: pageIndex ? pageIndex + 1 : 1,
        pageSize: pageSize || 10,
        orderBy,
        filterObject: filters,
        q,
      })) as ResponseType<IExtendedDevice>;
      const { results: devicesData } = response;

      const allProjectsIds: number[] = devicesData.reduce(
        (acc: number[], cur: IExtendedDevice) => {
          if (cur.project !== null && acc.indexOf(cur.project) < 0) {
            acc.push(cur.project);
          }
          return acc;
        },
        []
      );
      const allFacilitiesIds: number[] = devicesData.reduce(
        (acc: number[], cur: IExtendedDevice) => {
          if (cur.facility !== null && acc.indexOf(cur.facility) < 0) {
            acc.push(cur.facility);
          }
          return acc;
        },
        []
      );
      const allCompaniesIds: number[] = devicesData.reduce(
        (acc: number[], cur: IExtendedDevice) => {
          if (cur.company !== null && acc.indexOf(cur.company) < 0) {
            acc.push(cur.company);
          }
          return acc;
        },
        []
      );

      const { results: projectsData } = (await getProjects({
        ids: allProjectsIds,
      })) as ResponseType<IProjectList>;
      const { results: facilitiesData } = (await getFacilities({
        ids: allFacilitiesIds,
      })) as ResponseType<IFacility>;
      const { results: companiesData } = (await getCompanies({
        ids: allCompaniesIds,
      })) as ResponseType<ICompanyList>;
      const deviceStatuses = await getDeviceStatuses();
      const deviceHealth: { [key: number]: number } = await getDeviceHealth({
        ids: devicesData.map((device: any) => device.id),
      });
      dispatch(actions.resetDevices());
      dispatch(
        actions.fetchDataSuccess({
          ...response,
          results: devicesData.map((device: any) => {
            return {
              ...device,
              projectName: projectsData.find(
                (project: IProjectList) => project.id === device.project
              )?.name,
              facilityName: facilitiesData.find(
                (facility: IFacility) => facility.id === device.facility
              )?.name,
              companyName: companiesData.find(
                (company: ICompanyList) => company.id === device.company
              )?.name,
              status: deviceStatuses.find(
                (item: IDeviceStatus) => item.codename === device.status
              )?.name,
              health: deviceHealth[device.id],
            };
          }),
          devicesFetchParams,
        })
      );
    } catch (err) {
      dispatch(actions[EActions.fetchDataFail](err));
    }
  };
