import { useEffect, useCallback, useRef, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";

import { createSelector } from "reselect";
import useCompareFetch from "shared/hooks/useCompareFetch";
import actions, { fetchEventSounds } from "store/eventSounds/actions";

const pageSize = 100;

const selector = createSelector(
  (state: AppState) => state.eventSounds,
  (state: any) => {
    return {
      loading: state.isFetching,
      eventSoundsData: state.eventSoundsData,

      pageIndex: state.pageIndex,
      more: state.more,
      machines: state.machines,
      placements: state.placements,
      isSearchedClicked: state.isSearchedClicked,
    };
  }
);

const useData = ({ searchValues }: { searchValues?: any }) => {
  const {
    pageIndex,
    more,
    loading,
    eventSoundsData,
    machines,
    placements,
    isSearchedClicked,
  } = useSelector(selector, shallowEqual);

  const {
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    placement,
    machine,
    q,
  } = searchValues;

  const showMoreClickedTimesRef = useRef(0);
  const sm = useRef(0);

  const dispatch: any = useDispatch();

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        eventSoundsData && eventSoundsData.results
          ? Math.ceil(eventSoundsData.count / pageSize)
          : 0,
    };
  }, [eventSoundsData, pageIndex]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (sm.current > 0) {
        dispatch(actions.setMore(sm.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (more !== showMoreClickedTimesRef.current) {
      showMoreClickedTimesRef.current = more;
    }
  }, [more]);

  useEffect(() => {
    if (isSearchedClicked) {
      dispatch(
        fetchEventSounds({
          page: !sm.current ? pageIndex + 1 : 1,
          ps: sm.current ? pageSize * (1 + pageIndex) : pageSize,
          from: from ? from.toISOString() : undefined,
          to: to ? to.toISOString() : null,
          event_type,
          failure_type,
          placement_type,
          machine_subcategory,
          placement,
          machine,
          q,
        })
      );
    }
  }, [dispatch, searchValues, isSearchedClicked]);

  useCompareFetch((params: any) => dispatch(fetchEventSounds(params)), {
    page: !sm.current ? pageIndex + 1 : 1,
    ps: sm.current ? pageSize * (1 + pageIndex) : pageSize,
  });

  return {
    pageIndex,
    loading,
    eventSoundsData,
    initialState,
    showMoreClickedTimesRef,
    machines,
    placements,
    isSearchedClicked,
    onPageChange,
  };
};
export default useData;
