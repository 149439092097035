import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import MuiStepLabel from "@mui/material/StepLabel";
import NeuronStepIcon from "./stepIcon";

type Props = {
  label: string;
  index: number;
  activeStep: number;
  handleActive: (id: number) => void;
};

const useStyles = makeStyles((theme: any) => {
  return {
    label: {
      color: theme.custom.palette.placeholder,
      fontSize: theme.custom.typography.fontSize[16],
      fontWeight: theme.custom.typography.fontWeight.normal,
      letterSpacing: theme.typography.pxToRem(0.4),
      wordBreak: "break-word",
      hyphens: "auto",
    },
    labelVisited: {
      color: theme.custom.palette.data,
      cursor: "pointer",
    },
    labelCurrent: {
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.bold,
    },
  };
});

const StepLabel = ({
  label,
  index,
  activeStep,
  handleActive,
  ...props
}: Props) => {
  const classes = useStyles();

  const visited = activeStep >= index;
  const current = activeStep === index;

  return (
    <MuiStepLabel
      onClick={() => visited && handleActive(index)}
      StepIconComponent={NeuronStepIcon}
      {...props}
    >
      <span
        className={clsx(
          classes.label,
          visited && !current && classes.labelVisited,
          current && classes.labelCurrent
        )}
      >
        {label}
      </span>
    </MuiStepLabel>
  );
};

export default StepLabel;
