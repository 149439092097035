import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

type Params = {
  ids?: number[];
};

export async function getPlacementTypes(params: Params) {
  const ids = params.ids ? `&id=${params.ids.join("&id=")}` : "";
  const url = endpoints.placementTypes.default;
  try {
    const { data }: any = await axiosNeuron.get(`${url}?${ids}`);
    return data.results.map(({ id, name }: any) => ({
      id,
      name,
    }));
  } catch {
    return;
  }
}
