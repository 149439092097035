import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import deactivateDevice from "api/handlers/device/deactivateDevice";
import ConfirmPopup from "components/confirmPopup";
import { refetchDevicesSilent } from "store/devicesList/actions";
import { useSelector } from "react-redux";
import { AppState } from "store";

type TDeleteDeviceModal = {
  id: number;
  closeModal: () => void;
  onSuccess?: () => void;
};

export const DeleteDeviceModal = memo(
  ({ id, closeModal, onSuccess }: TDeleteDeviceModal) => {
    const { serialUid } = useSelector(
      (state: AppState) => state.deviceDetail.detail.device
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch: any = useDispatch();
    const { t } = useTranslation();

    const [deactivateDeviceMutation] = useMutation(deactivateDevice, {
      onSuccess: () => {
        enqueueSnackbar(t("devices.list.actions.success.delete"));
        dispatch(refetchDevicesSilent());
        onSuccess && onSuccess();
      },
      onError: () => {
        enqueueSnackbar(t("devices.list.actions.errors.delete"));
      },
    });

    const deleteDevice = useCallback(() => {
      try {
        deactivateDeviceMutation({ id });
      } catch {}
    }, [id, deactivateDeviceMutation]);

    return (
      <ConfirmPopup
        onConfirm={deleteDevice}
        title={t("devices.list.titles.delete") + " " + (serialUid || "")}
        text={t("devices.list.text.delete")}
        confirmText={t("devices.list.actions.delete")}
        noControl={true}
        onCancel={closeModal}
      />
    );
  }
);
