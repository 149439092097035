import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";
import RowSpacer from "components/table/cellsShared/rowSpacer";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "inline-block",
      fontWeight: theme.custom.typography.fontWeight.medium,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    labelTop: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
      width: "inherit",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    nowrap: {
      whiteSpace: "nowrap",
    },
    wrap: {
      whiteSpace: "initial",
      wordBreak: "break-all",
    },
  };
});

const Label = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { label, wrap },
    },
  } = props;
  const classes = useStyles();
  return (
    <span className={`${classes.root} ${wrap ? classes.wrap : classes.nowrap}`}>
      <span className={`${classes.labelTop} ${classes.wrap}`}>{label}</span>
      <RowSpacer />
    </span>
  );
};

export default Label;
