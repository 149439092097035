import { TFunction } from "i18next";
import { CellAction } from "components/users/list/cells/actions";

const rowActions = (
  t: TFunction,
  canResend: boolean,
  canDeactivate: boolean,
  canActivate: boolean,
  handleActivate: () => void,
  handleResend: () => void,
  handleDisable: () => void,
  handleDetail: () => void,
  displayableName: string
) => {
  const actions: CellAction[] = [
    {
      text: t("users.list.table.actions.detail.text"),
      onClick: () => handleDetail(),
    },
  ];
  if (canResend) {
    actions.unshift({
      text: t("users.list.table.actions.resend.text"),
      confirmText: t("users.list.table.actions.resend.confirmText"),
      confirmTitle: t("users.list.table.actions.resend.confirmTitle"),
      confirmDesc: t("users.list.table.actions.resend.confirmDesc"),
      onClick: () => handleResend(),
    });
  }
  if (canDeactivate) {
    actions.push({
      text: t("users.list.table.actions.disable.text"),
      confirmText: t("users.list.table.actions.disable.confirmText"),
      confirmTitle: t("users.list.table.actions.disable.confirmTitle"),
      confirmDesc: t("users.list.table.actions.disable.confirmDesc", {
        identification: displayableName,
      }),
      onClick: () => handleDisable(),
    });
  }
  if (canActivate) {
    actions.push({
      text: t("users.list.table.actions.activated.text"),
      confirmText: t("users.list.table.actions.activated.confirmText"),
      confirmTitle: t("users.list.table.actions.activated.confirmTitle"),
      confirmDesc: t("users.list.table.actions.activated.confirmDesc", {
        identification: displayableName,
      }),
      onClick: () => handleActivate(),
    });
  }
  return actions;
};
export default rowActions;
