import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IChannelList } from "types/channels";

interface IChannelListResponse {
  results: IChannelList[];
}

type TResponse = {
  id: number;
  device: number;
  state: string;
  sensor_type: number;
  placement: number;
  number: number;
  note: string;
  gain: number;
  type: string;
};

export default async function getChannelsById(id: number) {
  const url = `${endpoints.devices.getChannelsById(id)}?order_by=number`;
  try {
    const { data } = await axiosNeuron.get(url);

    const { data: audioChunkTypeData } = await axiosNeuron.get(
      `${endpoints.audioChunkTypes.default}?ps=9999`
    );

    const result: IChannelListResponse = {
      results: data.map((item: TResponse) => ({
        id: item.id,
        device: item.device,
        state: item.state,
        sensor_type: item.sensor_type,
        placement: item.placement,
        number: item.number,
        note: item.number,
        gain: item.gain,
        type: item.type,
        audioChunkTypes: audioChunkTypeData.results
          .filter(
            (audioChunkType: any) =>
              audioChunkType.channel_type ===
              (item.type === "modbus" ? "general" : item.type)
          )
          .map((audioChunkType: any) => audioChunkType.codename),
      })),
    };
    return result;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
