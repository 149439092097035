import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IFailure, useFailureTypesSelect } from "store/eventModal/selector";
import { BlackCheckbox } from "components/checkbox";
import Divider from "components/typography/heading/divider";
import { actions } from "store/eventModal/slice";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { fetchFailures } from "store/failures/slice";
import isNumber from "lodash/isNumber";
import FailureItem from "./failures";
import ConfirmPopup from "components/confirmPopup";
import Button from "components/button";
import { EModalTypes } from "store/eventModal/types";
import { useStyles } from "components/events/styles";

type TFailuresProps = {
  event: number;
  onlyPlacements?: boolean;
  modalType: string;
  modelTrainingType?: boolean;
};

type IPlacement = {
  value: string | number;
  text: string;
};

const Failures = ({
  event,
  onlyPlacements,
  modalType,
  modelTrainingType,
}: TFailuresProps) => {
  const dispatch: any = useDispatch();
  const { placements, failures, placementTypes } = useSelector(
    useFailureTypesSelect
  );
  const { t } = useTranslation();
  const classes = useStyles();

  const placementValues = placements.filter((placement: IPlacement) =>
    failures.some((fail: any) => fail.placement === placement.value)
  );

  const initialValues =
    modalType === EModalTypes.create ||
    (modalType === EModalTypes.edit &&
      modelTrainingType &&
      !placementValues.length)
      ? placements
      : placementValues;

  const [selectedValues, setSelectedValues] = useState(initialValues || []);
  const [isUnchecked, setIsUnchecked] = useState(false);
  const [isDeselectedAll, setIsDeselectedAll] = useState(false);
  const [selectedPlacement, setSelectedPlacement] = useState({
    value: 0,
    text: "",
    id: [],
  });

  useEffect(() => {
    if (event && event !== null) {
      dispatch(fetchFailures(event));
    }
  }, [event, dispatch]);

  const newPlacements = selectedValues.map((item: IPlacement) => item.value);

  const handleUnSelectAction = useCallback(
    (placementID: any) => {
      const newValues = selectedValues.filter(
        (selectedItem: IPlacement) => selectedItem.value !== placementID
      );
      setSelectedValues(newValues);
      dispatch(
        actions.removePlacement({
          placement: placementID,
        })
      );
    },
    [dispatch, selectedValues]
  );

  const handleChange = useCallback(
    (placement: any) => {
      if (newPlacements.includes(placement.value)) {
        const failureItem = failures.find(
          (fail: any) => fail.placement === placement.value
        );

        if (!onlyPlacements && failureItem?.id?.length !== 0) {
          setIsUnchecked(true);
          setIsDeselectedAll(false);
          setSelectedPlacement(placement);
        } else {
          handleUnSelectAction(placement.value);
        }
      } else {
        setSelectedValues([...selectedValues, placement]);
        dispatch(actions.addFailurePlacement({ event }));

        const type = placementTypes.find(
          (placementType: any) => placementType.placement === placement.value
        )?.placementType;

        !!type &&
          dispatch(
            actions.setFailurePlacement({
              index: `new_${selectedValues.length}1`,
              placement: placement.value,
              placementType: type,
            })
          );
      }
    },
    [
      newPlacements,
      failures,
      onlyPlacements,
      handleUnSelectAction,
      selectedValues,
      dispatch,
      event,
      placementTypes,
    ]
  );

  const handleUncheckPlacement = useCallback(() => {
    try {
      setIsUnchecked(false);
      if (isDeselectedAll) {
        setSelectedValues([]);
        dispatch(actions.removeAllPlacements());
        setIsDeselectedAll(false);
      } else {
        handleUnSelectAction(selectedPlacement.value);
      }
    } catch {
      return;
    }
  }, [dispatch, handleUnSelectAction, isDeselectedAll, selectedPlacement]);

  const handleDeselectAll = useCallback(
    (e: any) => {
      e.stopPropagation();

      const failureItems = failures.filter(
        (fail: any) => fail?.id?.length !== 0
      );
      const failureItemDetail = failureItems.map((fail: any) => fail?.id);

      if (!onlyPlacements && failureItemDetail?.length !== 0) {
        setIsUnchecked(true);
        setIsDeselectedAll(true);
      } else {
        setSelectedValues([]);
        setIsDeselectedAll(false);
        dispatch(actions.removeAllPlacements());
      }
    },
    [dispatch, failures, onlyPlacements]
  );

  const handleSelectAll = useCallback(
    (e: any) => {
      e.stopPropagation();
      setSelectedValues(placements);

      const newPlacements = placements.map(
        (item: IPlacement, index: number) => {
          const type = placementTypes.find(
            (placementType: any) => placementType.placement === item.value
          )?.placementType;

          return (
            !!type && {
              index: `new_${index + 1}1`,
              placement: item.value,
              placementType: type,
            }
          );
        }
      );

      dispatch(
        actions.addAllFailurePlacement({
          event,
          placements: newPlacements,
          failures,
        })
      );
    },
    [dispatch, event, failures, placementTypes, placements]
  );

  useEffect(() => {
    setSelectedValues([...selectedValues]);

    const newPlacements = placements.map((item: IPlacement, index: number) => {
      const type = placementTypes.find(
        (placementType) => placementType.placement === item.value
      )?.placementType;

      return (
        !!type && {
          index: `new_${index + 1}1`,
          placement: item.value,
          placementType: type,
        }
      );
    });

    dispatch(
      actions.addAllFailurePlacement({
        event,
        placements: newPlacements,
        failures,
      })
    );
  }, []);

  return placements.length !== 0 ? (
    <Box>
      <Divider line>{t("machineDetail.editEvent.placementsTitle")}</Divider>
      <Button
        disabled={selectedValues.length >= placements.length}
        onClick={handleSelectAll}
      >
        {t("filter.selectAll")}
      </Button>
      <Button
        disabled={selectedValues.length === 0}
        onClick={handleDeselectAll}
      >
        {t("filter.deselectAll")}
      </Button>
      {placements.map((item: IPlacement) => {
        const failureItem = failures.find(
          (failure: IFailure) => failure.placement === item.value
        );
        const checkedPlacement = newPlacements.includes(item.value);

        return (
          <Box key={item.value}>
            <MenuItem
              data-value={item.value}
              onClick={() => handleChange(item)}
            >
              <BlackCheckbox checked={checkedPlacement} />
              <ListItemText primary={<>{item.text}</>} />
            </MenuItem>
            {!onlyPlacements && checkedPlacement && (
              <>
                <Box pl={4}>
                  {isNumber(failureItem?.placement) &&
                    isNumber(failureItem?.placementType) && (
                      <FailureItem item={failureItem as IFailure} />
                    )}
                </Box>
                <Divider line />
              </>
            )}
          </Box>
        );
      })}
      {isUnchecked ? (
        <ConfirmPopup
          onConfirm={handleUncheckPlacement}
          title={t("machineDetail.editEvent.unCheckPlacementTitle")}
          text={t("machineDetail.editEvent.unCheckPlacementText")}
          confirmText={t("machineDetail.editEvent.unCheckPlacementAction")}
          noControl={true}
          onCancel={() => setIsUnchecked(false)}
          descriptionClassName={classes.description}
        />
      ) : null}
    </Box>
  ) : null;
};

export default Failures;
