import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import NoData from "components/noData";
import RowSpacer from "components/table/cellsShared/rowSpacer";
import Tooltip from "components/tooltip";
import { CellProps } from "react-table";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: 130,
    },
    labelTop: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
      width: "inherit",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    labelBottom: {
      textDecoration: "underline",
      fontWeight: theme.custom.typography.fontWeight.normal,
      fontSize: theme.custom.typography.fontSize[14],
    },
  };
});

const LabelArray = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { labels },
    },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  if (!labels || labels.length === 0) {
    return (
      <div className={classes.root}>
        <NoData />
        <RowSpacer />
      </div>
    );
  }

  const localComponent = (
    <div className={classes.root}>
      <span className={classes.labelTop}>{labels[0]}</span>
      {labels.length > 1 && (
        <span className={classes.labelBottom}>
          {t("table.andNumberMore", { num: labels.slice(1).length })}
        </span>
      )}
      {labels.length === 1 && <RowSpacer />}
    </div>
  );

  return labels.length <= 1 ? (
    localComponent
  ) : (
    <Tooltip
      title={labels.slice(1).map((item: any, index: number) => (
        <span key={index}>
          {item} <br />
        </span>
      ))}
      open={labels.length <= 1 ? false : undefined}
    >
      {localComponent}
    </Tooltip>
  );
};

export default LabelArray;
