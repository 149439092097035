export enum EActions {
  enqueueSnackbar = "enqueueSnackbar",
  removeSnackbar = "removeSnackbar",
  closeSnackbar = "closeSnackbar",
}

export interface INotifierState {
  notifications: any[];
}

export interface INotifierActions {
  type: typeof EActions;
  payload: {
    message: string;
    options: { [key: string]: any };
    key: string;
  };
}
