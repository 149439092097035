import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import rowActions from "./rowActions";
import Actions from "components/users/list/cells/actions";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import { IUserList } from "types/user";
import { useMutation } from "react-query";
import patchUser from "api/handlers/user/patchUser";
import resendInvitation from "api/handlers/resendInvitation";
import urls from "shared/appUrls";
import { refetchUsersSilent } from "store/users/actions";

interface Props extends CellProps<IUserList> {
  marginTop?: number;
  projectId?: number;
}

const Cell = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [resendInvitationMutation, { status: resendInvitationStatus }] =
    useMutation(resendInvitation, {
      onSuccess: () =>
        enqueueSnackbar(t("users.list.table.actions.resend.successful")),
      onError: () =>
        enqueueSnackbar(t("users.list.table.actions.resend.unsuccessful")),
    });

  const [patchUserMutation, { status: patchUserStatus }] = useMutation(
    patchUser,
    {
      onSuccess: async ({ isActive }: { isActive: boolean }) => {
        dispatch(refetchUsersSilent());
        isActive
          ? enqueueSnackbar(t("users.list.table.actions.activated.successful"))
          : enqueueSnackbar(t("users.list.table.actions.disable.successful"));
      },
      onError: () => enqueueSnackbar(t("users.detail.actions.messageError")),
    }
  );

  const id = props.row.original.id;
  const email = props.row.original.email;
  const firstName = props.row.original.firstName;
  const lastName = props.row.original.lastName;
  const displayableName =
    firstName && lastName ? `${firstName} ${lastName}` : email;
  const canResend = false; // wait until statuses are implemented on BE
  const canDeactivate = props.row.original.status === "Active";
  const canActivate = props.row.original.status === "Inactive";
  const handleResend =
    resendInvitationStatus !== "loading"
      ? () => resendInvitationMutation({ id })
      : () => {};

  const handleDisable =
    patchUserStatus !== "loading"
      ? () => patchUserMutation({ userId: id, isActive: false })
      : () => {};

  const handleActivate =
    patchUserStatus !== "loading"
      ? () => patchUserMutation({ userId: id, isActive: true })
      : () => {};

  const handleDetail = () =>
    history(urls.users.detail(id, "account", props.projectId));

  return (
    <Actions
      {...props}
      items={rowActions(
        t,
        canResend,
        canDeactivate,
        canActivate,
        handleActivate,
        handleResend,
        handleDisable,
        handleDetail,
        displayableName
      )}
    />
  );
};

export default Cell;
