import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { EActions, IProjectMachinesAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import getMachines from "api/handlers/machine/getMachines";

export const path = "projectDetail/machines";

const actionsData = [
  [EActions.fetchProjectMachinesRequest],
  [EActions.fetchProjectMachinesFail, "error"],
  [EActions.fetchProjectMachinesSuccess, "machines"],
  [EActions.setReset],
];

const actions = prepareActions<IProjectMachinesAction, EActions>(
  actionsData,
  path
);
export default actions;

export const fetchProjectMachines =
  (projectId: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchProjectMachinesRequest]());
    try {
      const machines = await getMachines({
        project: projectId,
        pageSize: 99999,
        order_by: [{ id: "name" }, { id: "id" }],
      });

      dispatch(actions[EActions.fetchProjectMachinesSuccess](machines));
    } catch (error) {
      dispatch(actions[EActions.fetchProjectMachinesFail](error));
    }
  };
