import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import getCompanies from "api/handlers/company/getCompanies";
import updateCompany from "api/handlers/company/updateCompany";
import { prepareActions } from "store/helpers";
import { EActions, ICompaniesList } from "./types";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import addCompanyHandler from "api/handlers/company/addCompany";

export const path = "companiesList";

const actionData = [
  [EActions.setPage, "pageIndex"],
  [EActions.setData, "data"],
  [EActions.setLoading, "loading"],
  [EActions.setReloading, "reloading"],
  [EActions.setSearch, "q"],
  [EActions.setEditing, "editing"],
  [EActions.setSort, "sort"],
  [EActions.setShowMore, "showMore"],
];

const actions = prepareActions<ICompaniesList, EActions>(actionData, path);

export default actions;

export const fetchCompanies =
  ({ page, pageSize, orderBy, q }: any) =>
  async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    try {
      const companies = await getCompanies({
        page,
        pageSize,
        orderBy,
        q,
      });

      dispatch(actions[EActions.setData](companies));
    } catch (error) {
      dispatch(actions[EActions.setLoading](false));
    }
  };

export const rename =
  (id: number, name: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any | any,
    getState: any
  ) => {
    try {
      await updateCompany({ id, name });
      enqueueSnackbar(i18n.t("companies.list.table.success"));

      const { pageIndex, q, sort, showMore } = getState().companiesList;

      dispatch(
        fetchCompanies({
          page: !showMore ? pageIndex + 1 : 1,
          pageSize: showMore ? 10 * (1 + pageIndex) : 10,
          orderBy: sort,
          q,
        })
      );
    } catch (error) {
      enqueueSnackbar(i18n.t("companies.list.table.fail"));
    }
  };

export const addCompanyAction =
  (
    name: string,
    email: string,
    users: number[],
    redirect?: (id: number) => void
  ) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    try {
      const addedCompany = await addCompanyHandler({ name, email, users });
      enqueueSnackbar(
        i18n.t("companies.addCompany.message.success", {
          name,
        })
      );
      redirect && redirect(addedCompany.id);
    } catch (error: any) {
      enqueueSnackbar(
        i18n.t("companies.addCompany.message.fail", {
          error: error.message,
        })
      );
    }
  };
