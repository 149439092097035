import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { TRecordingPlans } from "types/device";
import { ResponseType } from "types/response";

export default async function getRecordingPlans() {
  const url = endpoints.recordingPlans.default;
  try {
    const { data } = await axiosNeuron.get(url);
    const result: ResponseType<TRecordingPlans> = {
      results: data.results,
      count: data.count,
    };
    return result;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
