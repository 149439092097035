import React from "react";
import { makeStyles } from "@mui/styles";

interface CenteredProps {
  children?: React.ReactNode;
  vertical?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    fill: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    horizontalCentered: {
      textAlign: "center",
    },
    bothCentered: {
      position: "relative",
      textAlign: "center",
      top: "50%",
      transform: "translate(0, -50%)",
    },
  };
});

const Centered: React.FC<CenteredProps> = (props: any) => {
  const classes = useStyles();
  if (props.vertical === false) {
    return <div className={classes.horizontalCentered}>{props.children}</div>;
  } else {
    return (
      <div className={classes.fill}>
        <div className={classes.bothCentered}>{props.children}</div>
      </div>
    );
  }
};

export default Centered;
