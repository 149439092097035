import React, { memo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { ModalWrap } from "components/events/components/modalWrap";
import { Field, Form } from "react-final-form";
import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";
import Select from "components/select";

export const useStyles = makeStyles((theme: IMuiTheme) => ({
  form: {
    padding: theme.spacing(3, 3, 0, 3),
    height: "100%",
    overflowY: "auto",
  },
}));

const SnoozeModal = memo(
  ({
    event,
    placements,
    onSubmit,
    onCancel,
    open,
  }: {
    event: number;
    placements: number[];
    onSubmit: any;
    onCancel: any;
    open: boolean;
  }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const data = [0, 1, 2, 3, 4, 8, 24, 48, 72, 120, 876000].map(
      (hourOption: any) => ({
        text:
          hourOption > 0
            ? hourOption === 876000
              ? t("machine.detail.alertingDelay.forever")
              : t("machine.detail.alertingDelay.hours", { hourOption })
            : t("machine.detail.alertingDelay.none"),
        value: hourOption,
      })
    );
    return open ? (
      <Form
        onSubmit={(values: any) => onSubmit(values.hours)}
        initialValues={{ hours: null }}
        render={({ handleSubmit, values }) => {
          return (
            <form id={`snoozePlacements-event${event}`} onSubmit={handleSubmit}>
              <ModalWrap
                open={open}
                submitDisabled={values.hours === null}
                title={t("event.snoozePlacements.title")}
                cta={
                  values.hours === 0
                    ? t("event.snoozePlacements.unsnooze")
                    : t("event.snoozePlacements.snooze")
                }
                onCancel={onCancel}
                onSubmit={handleSubmit}
              >
                <Box className={classes.form} flex={1} p={3}>
                  <Field
                    name="hours"
                    render={({ input, meta }) => (
                      <Select
                        id="hours"
                        label={t("machine.detail.alertingDelay.snoozeFor")}
                        disabled={false}
                        input={input}
                        meta={meta}
                        name="hours"
                        options={data}
                      />
                    )}
                  />
                </Box>
              </ModalWrap>
            </form>
          );
        }}
      />
    ) : null;
  }
);

export default SnoozeModal;
