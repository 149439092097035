import moment from "moment";

export function normalizeWheel(/*object*/ event) /*object*/ {
  const PIXEL_STEP = 10;
  const LINE_HEIGHT = 40;
  const PAGE_HEIGHT = 800;
  let sX = 0,
    sY = 0, // spinX, spinY
    pX = 0,
    pY = 0; // pixelX, pixelY

  // Legacy
  if ("detail" in event) {
    sY = event.detail;
  }
  if ("wheelDelta" in event) {
    sY = -event.wheelDelta / 120;
  }
  if ("wheelDeltaY" in event) {
    sY = -event.wheelDeltaY / 120;
  }
  if ("wheelDeltaX" in event) {
    sX = -event.wheelDeltaX / 120;
  }

  // side scrolling on FF with DOMMouseScroll
  if ("axis" in event && event.axis === event.HORIZONTAL_AXIS) {
    sX = sY;
    sY = 0;
  }

  pX = sX * PIXEL_STEP;
  pY = sY * PIXEL_STEP;

  if ("deltaY" in event) {
    pY = event.deltaY;
  }
  if ("deltaX" in event) {
    pX = event.deltaX;
  }

  if ((pX || pY) && event.deltaMode) {
    if (event.deltaMode === 1) {
      // delta in LINE units
      pX *= LINE_HEIGHT;
      pY *= LINE_HEIGHT;
    } else {
      // delta in PAGE units
      pX *= PAGE_HEIGHT;
      pY *= PAGE_HEIGHT;
    }
  }

  // Fall-back if spin cannot be determined
  if (pX && !sX) {
    sX = pX < 1 ? -1 : 1;
  }
  if (pY && !sY) {
    sY = pY < 1 ? -1 : 1;
  }

  return {
    spinX: sX,
    spinY: sY,
    pixelX: pX,
    pixelY: pY,
  };
}

export function between(x, start, end) {
  return x >= start && x <= end;
}

export function timestampToString(timestamp) {
  return moment.unix(timestamp).format("HH:mm:ss");
}
export function timestampToMinutes(timestamp) {
  return moment.unix(timestamp).format("mm");
}

export function roundToString(n, precision) {
  let s = Math.round(n * Math.pow(10, precision)) + "";
  return (
    s.substr(0, s.length - precision) +
    "." +
    s.substr(s.length - precision, precision)
  );
}

export function getPxPerSec(props, width) {
  let { windowStart, windowEnd } = props;
  return width / (windowEnd - windowStart);
}

export function calculateSelectionWindow(props, start, end, pxPerSec) {
  let windowStart = props.windowStart + start / pxPerSec;
  let windowEnd = props.windowStart + end / pxPerSec;
  return { windowStart, windowEnd };
}

export function calculateSelectionPoint(props, point, pxPerSec) {
  return props.windowStart + point / pxPerSec;
}

export function calculatePositionPoint(props, point, pxPerSec) {
  return point * pxPerSec - props.windowStart;
}

export function getZoom(props) {
  let { windowStart, windowEnd } = props;
  const coef = props.coef ? props.coef : 1.0;
  return Math.max(
    1,
    (props.duration / Math.abs(windowEnd - windowStart)) * coef
  );
}
