import { EActions, IProjectState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IProjectState = {
  facility: null,
  loading: false,
};

const reducers = {
  [EActions.setFacility]: ({ payload: { facility } }: IActionTypes) => ({
    facility,
  }),

  [EActions.setLoading]: ({ payload: { loading } }: IActionTypes) => ({
    loading,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};
export default createReducer<IProjectState>(path, initialState, reducers);
