import React, { useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { assignUserToProject } from "store/projectDetail/users/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import Checkbox from "components/checkbox";
import { ERoleCodename } from "types/role";

interface Props {
  cell: {
    value: {
      projects: number[];
      id: number;
    };
  };
}

const BelongsToProject = ({
  cell: {
    value: { id, projects },
  },
}: Props) => {
  const { id: targetProject } = useParams<{ id?: string }>();
  const dispatch: any = useDispatch();
  const { id: userId, role } = useSelector(
    (state: AppState) => state.auth?.user
  );

  const onChange = useCallback(
    (event: any) => {
      event.stopPropagation();
      dispatch(assignUserToProject({ id, projects }));
    },
    [dispatch, id, projects]
  );

  const isProject = useMemo(() => {
    if (projects.find((project: any) => project.toString() === targetProject))
      return true;
    return false;
  }, [projects, targetProject]);

  const stopPropagation = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const disabled = useMemo(() => {
    if (userId && userId === id && role !== ERoleCodename.nswAdmin) {
      return true;
    }
    return false;
  }, [id, role, userId]);

  return (
    <div onClick={stopPropagation}>
      <Checkbox disabled={disabled} onChange={onChange} checked={isProject} />
    </div>
  );
};

export default BelongsToProject;
