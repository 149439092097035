import EPermissions from "shared/permissions";
import { TFunction } from "i18next";
import { SortingRule } from "react-table";
import i18n from "localization";
import map from "lodash/map";
import find from "lodash/find";
import isObject from "lodash/isObject";
import isNumber from "lodash/isNumber";
import { Local } from "components/dataLabeling/constants";
import { EventType } from "components/machine/types";
import urls from "./appUrls";
import { ICompanyList } from "types/company";
import { ResponseType } from "types/response";
import { CellProps } from "react-table";
import isEqual from "react-fast-compare";
import { Location } from "history";
import moment from "moment";

export const pxToRem = (value: number) => {
  return `${value / 16}rem`;
};

export const appendLeadingZeroes = (n: number) => (n < 10 ? `0${n}` : `${n}`);

export const dateToDDMMYYYY = (date: Date) => moment(date).format("DD/MM/YYYY");

export const dateToHHMM = (date: Date) => moment(date).format("HH:mm");

export const dateToString = (date: Date) =>
  moment(date).format("YYYY/MM/DD HH:mm:ss");

export const dateToStringShort = (date: Date) =>
  moment(date).format("YYYY/MM/DD HH:mm");

export const nameToInitials = (name: string) =>
  name
    .split("")
    .filter((letter, i, src) => i === 0 || src[i - 1] === " ")
    .join("");

export const setPageTitle = (t: TFunction, title: string) => {
  document.title = `${title} | ${t("app.name")}`;
};

export const verifyPermission = (
  tested: EPermissions[] | EPermissions,
  userPermissions?: string[]
) => {
  if (Array.isArray(tested)) {
    return tested.find((permission: EPermissions) =>
      userPermissions?.includes(permission)
    );
  } else {
    return userPermissions?.includes(tested);
  }
};
export const ensure = <T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};

export const underscoreToCamel = (string: string) => {
  return string.replace(/_./g, (match: any) => match.slice(1).toUpperCase());
};

export const getNormalizeTime = (time: string) => {
  return time.replace(/Z/, "").replace(/T/, " ");
};

export const resolveSorting = (
  fallback?: string,
  data?: SortingRule<string>[]
) => {
  if (data?.length) {
    return data
      .map((item: any) => `${item.desc ? "-" : ""}${item.id}`)
      .join("&order_by=");
  }
  if (!fallback?.length) {
    return undefined;
  }
  return fallback;
};

export const getDeviceNameWithPrefix = (name: string) => {
  const prefix = "dev-";
  if (!name || !name.length) {
    return i18n.t("device.placeholder");
  }
  if (name.startsWith(prefix)) {
    return name;
  }
  return prefix + name;
};

export const urlFriendlyTZ = (toURL: boolean, tz: string) => {
  if (!tz) {
    return Local;
  }
  if (toURL) {
    return tz.replace(/\//g, "--");
  } else {
    return tz.replace(/--/g, "/");
  }
};

export const getDateWithSubtractedDays = (days: number) => {
  const now = new Date();
  return new Date(now.setDate(now.getDate() - days));
};

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
  });

export const mergeByCommonKey = (
  arrays: { [key: string]: any }[],
  keys: string[],
  connector: string
) => {
  for (const i in keys) {
    const mergeFunc = (j: { [key: string]: any }) => {
      return {
        ...j,
        [keys[i]]: { ...find(arrays[i], [connector, j[keys[i]]]) },
      };
    };
    arrays[Number(i) + 1] = map(arrays[Number(i) + 1], mergeFunc);
  }
  return arrays[keys.length];
};

export const validateTZ = (tz?: string) => {
  try {
    if (!tz) {
      return Local;
    }
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return tz;
  } catch (ex) {
    return Local;
  }
};

export const prepareLinkForCheckSound = (
  machine: number,
  placement: number,
  decrementedDate: string,
  decrementedTime: string
) => {
  return `${urls.machines.detailSounds(machine)}?timezoneOffset=${urlFriendlyTZ(
    true,
    Local
  )}&date=${decrementedDate}&time=${decrementedTime}&channel=${placement}&duration=180`;
};

type TUser = {
  firstName: string;
  lastName: string;
  username: string;
};

const getModifiedByUser = (user: TUser | number | null) => {
  if (isObject(user)) {
    return `${user?.firstName} ${user?.lastName} (${user?.username})`;
  }
  if (isNumber(user)) {
    return i18n.t("who.createdBy.userWithHashtag", { id: user });
  }
  return i18n.t("machine.detail.eventsList.message.unknownUser");
};

type TPrepare = {
  type: string;
  meta: {
    device?: number;
    deviceSerialUid?: string;
    deviceName: string;
    channel?: number;
    channelNo?: number;
    placement?: number;
    placementTypeName?: string;
    user: TUser | number | null;
    customPlacementName?: string | null;
    newValue?: string;
    oldValue?: string;
    device_recording_plan?: string;
    new_production_mode_name?: string | null;
    message?: string | null;
  };
};

export const prepareMessage = ({ type, meta }: TPrepare) => {
  if (EventType.hasOwnProperty(type)) {
    const {
      deviceName,
      placementTypeName,
      customPlacementName,
      user,
      newValue,
      oldValue,
      channelNo,
      device_recording_plan,
      new_production_mode_name,
      message,
    } = meta || {};

    if (message) {
      try {
        const messageData = JSON.parse(message);
        const messageHeader = messageData?.context?.message_header;
        if (messageHeader) {
          return messageHeader;
        }
      } catch (e) {}
    }

    return i18n.t(
      "machine.detail.eventsList.message." + underscoreToCamel(type),
      {
        placementName:
          customPlacementName ||
          placementTypeName ||
          i18n.t("machine.detail.eventsList.message.unknownPlacement"),
        serialUid: deviceName || i18n.t("unknown"),
        user: getModifiedByUser(user),
        device_recording_plan: i18n.t(`recordingPlan.${device_recording_plan}`),
        newValue,
        oldValue,
        channelNo,
        mode: new_production_mode_name,
      }
    );
  } else {
    return i18n.t("machine.detail.eventsList.message.unknown");
  }
};

export async function getActions(path: string) {
  const actions = await import(`store/${path.replace(".", "/")}/actions`);
  return {
    setEditing: actions.default.setEditing,
    rename: actions.rename,
  };
}

export const getUsers = (createdByUser: any, modifiedByUser: any) => {
  let created = i18n.t("who.system"),
    modified = created;

  if (typeof createdByUser === "number") {
    created = i18n.t("who.createdBy.userWithHashtag", { id: createdByUser });
  }

  if (typeof createdByUser === "object" && createdByUser !== null) {
    created = `${createdByUser?.firstName} ${createdByUser?.lastName} (${createdByUser?.username})`;
  }

  if (typeof createdByUser === "string") {
    created = createdByUser;
  }

  if (typeof modifiedByUser === "number") {
    modified = i18n.t("who.createdBy.userWithHashtag", { id: modifiedByUser });
  }

  if (typeof modifiedByUser === "object" && modifiedByUser !== null) {
    modified = `${modifiedByUser?.firstName} ${modifiedByUser?.lastName} (${modifiedByUser?.username})`;
  }

  if (typeof modifiedByUser === "string") {
    modified = modifiedByUser;
  }

  return `${created} / ${modified}`;
};

type Params = {
  params: {
    [key: string]: string | undefined;
  };
  url?: string;
};

export const dataLabelingRedirect = ({ params, url }: Params) => {
  const { machine, timezoneOffset, date, time, placement, duration } =
    params || {};

  if (machine) {
    return `${urls.machines.detailSounds(
      Number(machine)
    )}?timezoneOffset=${timezoneOffset}&date=${date}&time=${time}&placement=${placement}&duration=${duration}`;
  }
  return url || "";
};

export const fetchCSV = async (url: string) => {
  const data = await fetch(url, {
    headers: {
      "Content-Type": "text/csv",
    },
  });

  if (data.status !== 200) {
    throw new Error("error");
  }
  return await data.text();
};

export const getDashboardHeaders = (
  filters: { company: number[] },
  companies: { data: ResponseType<ICompanyList> }
) => {
  return filters?.company
    ? filters?.company
        .map((id: number) => {
          const company: ICompanyList | undefined =
            companies?.data?.results.find(
              (company: ICompanyList) => company.id === id
            );
          return company ? company.name : "";
        })
        .join(" & ")
    : "";
};

export const cellCompare = <T extends object>(
  prev: CellProps<T>,
  next: CellProps<T>
) => {
  const {
    cell: { value: pValue },
  } = prev;
  const {
    cell: { value: nValue },
  } = next;
  return isEqual(pValue, nValue);
};

export const fromGrafanaRedirect = (location: Location | any) => {
  if (location.search) {
    const search = new URLSearchParams(location.search);
    const allowedParams = ["placement_id", "machine_id", "timestamp"];
    const params = Array.from(search.keys());
    if (
      params.every((parameter: any) => allowedParams.indexOf(parameter) !== -1)
    ) {
      const machine = Number(search.get("machine_id"));
      const placement = Number(search.get("placement_id"));
      const timestamp = Number(search.get("timestamp"));
      if (machine) {
        let baseurl = `${urls.machines.detailSounds(
          machine
        )}?timezoneOffset=${urlFriendlyTZ(true, Local)}&duration=180`;
        const date = moment.utc(timestamp).local().format("YYYY-MM-DD");
        const time = moment.utc(timestamp).local().format("HH:mm");
        if (date) baseurl += `&date=${date}`;
        if (date) baseurl += `&time=${time}`;
        if (placement) baseurl += `&placement=${placement}`;
        return baseurl;
      }
    }
  }
  return "/";
};

export const getParsedJson = (input: string) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return undefined;
  }
};

export const cmp = (key: any) => (a: any, b: any) =>
  Number(key(a) > key(b)) - Number(key(b) > key(a));

export const uniqueArray = <T>(input: Array<T>): Array<T> =>
  Array.from(new Set<T>(input));

export const checkEnvRegex = (env: string) => !!env.match(/^[a-z0-9\.\-]+$/);

const cache: any = {};

export const toQueryString = (params: {
  [key: string]: string | Array<string | number> | undefined;
}) => {
  const cacheKey = JSON.stringify(params);
  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  const queryParts = [];

  for (const key in params) {
    if (params[key]?.length && params[key] !== undefined) {
      const value = Array.isArray(params[key])
        ? (params[key] as Array<string | number>)?.join(",")
        : params[key];
      queryParts.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
      );
    }
  }

  const result = queryParts.length > 0 ? "?" + queryParts.join("&") : "";
  cache[cacheKey] = result;

  return result;
};
