import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ResponseType } from "types/response";
import { IDeviceList } from "types/device";
import { SortingRule } from "react-table";
import { resolveSorting } from "shared/helpers";
import { getDeviceNameWithPrefix } from "shared/helpers";

export default async function getDevices(
  page: number,
  pageSize: number,
  orderBy: SortingRule<number>[] | undefined,
  filterObject?: { [key: string]: number[] | string[] },
  ids?: number[]
) {
  if (ids && !ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IDeviceList>;
  }
  const params = {
    ps: pageSize,
    page,
    order_by: resolveSorting("", orderBy),
    ids,
  };
  const url = endpoints.devices.default;
  const idsFilter = ids ? `&id=${ids.join("&id=")}` : "";

  try {
    const response = await axiosNeuron.get(`${url}?${idsFilter}`, { params });
    const data: ResponseType<IDeviceList> = {
      ...response.data,
      results: response.data.results.map((device: any) => ({
        id: device.id,
        name: getDeviceNameWithPrefix(device.name),
        note: device.note,
        internetConnection: device.internet_connection,
        version: device.version,
        telephoneNumber: device.telephone_number,
        carrier: device.carrier,
        hasSdcard: device.has_sdcard,
        macAddress: device.mac_address,
        hasHdd: device.has_hdd,
        serialUid: device.serial_uid,
        status: device.status,
        project: device.project,
        channels: device.channels,
        channelCount: device.channel_count,
        assignedChannelCount: device.assigned_channel_count,
        assignedPlacements: device.assigned_placements,
        facility: device.facility,
        company: device.company,
        machines: device.machines,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
