import React, { memo, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import isEqual from "react-fast-compare";
import Spinner from "components/spinner";
import { makeStyles } from "@mui/styles";
import { fetchModels } from "store/models/actions";
import ConfirmPopup from "components/confirmPopup";
import archiveModel from "api/handlers/machine/archiveModel";
import unarchiveModel from "api/handlers/machine/unarchiveModel";
import { useSnackbar } from "notistack";
import moment from "moment";
import Picker from "localization/pickerLocale";
import ModelTable from "components/modelTable";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import clsx from "clsx";

const INTERVAL_15_SECONDS = 15000;

const trainingDestinations = ["all", "production", "stage", "other"];
const deploymentEnvironments = [
  "all",
  "production",
  "stage",
  "other",
  "not_deployed",
];
const runningOptions = ["all", "running", "not_running"];
const archivedOptions = ["not_archived", "archived", "all"];

const useStyles = makeStyles((theme: any) => ({
  filters: {
    gap: "1rem",

    "& .MuiInputBase-root": {
      paddingRight: "15px",
    },
    "& .MuiSelect-select": {
      paddingTop: "11.5px",
      paddingBottom: "11.5px",
    },
  },
  filter: {
    minWidth: "16rem",
  },
  picker: {
    "& .MuiInputBase-input": {
      paddingTop: theme.typography.pxToRem(17),
      paddingBottom: theme.typography.pxToRem(17),
    },
  },
}));

const ModelList = memo(() => {
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tt = (i: string) => t(`machine.detail.model.${i}`);
  const { models: results, isFetchingModels } = useSelector(
    (state: AppState) => state.models,
    isEqual
  );

  const [query, setQuery] = useQueryParams({
    from: withDefault(StringParam, ""),
    to: withDefault(StringParam, ""),
    trainingDestination: withDefault(StringParam, ""),
    deploymentEnvironment: withDefault(StringParam, ""),
    currentStatus: withDefault(StringParam, ""),
    archived: withDefault(StringParam, ""),
  });

  const [startDatetime, setStartDatetime] = useState<Date>(
    query.from ? new Date(query.from) : moment().subtract(1, "month").toDate()
  );

  const [endDatetime, setEndDatetime] = useState<Date>(
    query.to ? new Date(query.to) : new Date()
  );

  const [hasErrorStart, setHasErrorStart] = useState<boolean>(false);
  const [hasErrorEnd, setHasErrorEnd] = useState<boolean>(false);

  const [trainingDestination, setTrainingDestination] = useState<string>(
    query.trainingDestination || "all"
  );
  const [deploymentEnvironment, setDeploymentEnvironment] = useState<string>(
    query.deploymentEnvironment || "all"
  );
  const [running, setRunning] = useState<string>(query.currentStatus || "all");
  const [archived, setArchived] = useState<string>(
    query.archived || "not_archived"
  );

  React.useEffect(() => {
    dispatch(
      fetchModels(
        startDatetime,
        endDatetime,
        trainingDestination,
        deploymentEnvironment,
        running,
        archived,
        true
      )
    );
    const interval = setInterval(() => {
      dispatch(
        fetchModels(
          startDatetime,
          endDatetime,
          trainingDestination,
          deploymentEnvironment,
          running,
          archived,
          true
        )
      );
    }, INTERVAL_15_SECONDS);
    return () => {
      clearInterval(interval);
    };
  }, [
    startDatetime,
    endDatetime,
    trainingDestination,
    deploymentEnvironment,
    running,
    archived,
    dispatch,
  ]);

  const [machineId, setMachineId] = useState<number | null>(null);
  const [undeployId, setUndeployId] = useState<number | null>(null);
  const [deployId, setDeployId] = useState<number | null>(null);
  const [recomputeId, setRecomputeId] = useState<number | null>(null);
  const [archiveId, setArchiveId] = useState<number | null>(null);
  const [unarchiveId, setUnarchiveId] = useState<number | null>(null);
  const [stopTrainingJob, setStopTrainingJob] = useState<string | null>(null);

  const handleArchive = async () => {
    if (!machineId) return;
    const archive = !!archiveId;
    try {
      if (archive) {
        await archiveModel(machineId, { trained_model_id: archiveId! });
        enqueueSnackbar(tt("archived"));
        setArchiveId(null);
      } else {
        await unarchiveModel(machineId, { trained_model_id: unarchiveId! });
        enqueueSnackbar(tt("unarchived"));
        setUnarchiveId(null);
      }
    } catch (e) {
      enqueueSnackbar(tt(archive ? "errorArchiving" : "errorUnarchiving"));
    }
    setMachineId(null);
  };

  const headerRef = React.useRef<HTMLTableSectionElement>(null);

  const onScroll = (e: Event) => {
    if (!headerRef.current) return;
    const offset =
      headerRef.current.getBoundingClientRect().y -
      parseFloat(headerRef.current.style.top);
    headerRef.current.style.top = `${offset < 0 ? -offset : 0}px`;
  };

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [headerRef]);

  React.useEffect(() => {
    setQuery(
      {
        from: startDatetime.toISOString(),
        to: endDatetime.toISOString(),
        trainingDestination,
        deploymentEnvironment,
        currentStatus: running,
        archived,
      },
      "pushIn"
    );
  }, [
    archived,
    deploymentEnvironment,
    endDatetime,
    query,
    running,
    setQuery,
    startDatetime,
    trainingDestination,
  ]);

  return (
    <>
      <Box
        mt={4}
        mb={4}
        display="flex"
        flexWrap="wrap"
        alignSelf="baseline"
        className={classes.filters}
      >
        <Picker
          Component={DateTimePicker}
          className={clsx(classes.filter, classes.picker)}
          label={t("models.startDatetime")}
          value={startDatetime ? dayjs(startDatetime) : null}
          onChange={(date: any) => {
            if (!date || isNaN(date as any)) {
              setHasErrorStart(true);
              return false;
            }
            setHasErrorStart(false);
            setStartDatetime(date);
          }}
          format="YYYY/MM/DD HH:mm:ss"
          disableFuture
          slotProps={{
            textField: {
              variant: "outlined",
              error: hasErrorStart,
            },
          }}
        />
        <Picker
          Component={DateTimePicker}
          className={clsx(classes.filter, classes.picker)}
          label={t("models.endDatetime")}
          value={endDatetime ? dayjs(endDatetime) : null}
          onChange={(date: any) => {
            if (!date || isNaN(date as any)) {
              setHasErrorEnd(true);
              return false;
            }
            setHasErrorEnd(false);
            setEndDatetime(date);
          }}
          format="YYYY/MM/DD HH:mm:ss"
          disableFuture
          slotProps={{
            textField: {
              variant: "outlined",
              error:
                hasErrorEnd ||
                (startDatetime && endDatetime && startDatetime >= endDatetime),
            },
          }}
        />
        <FormControl variant="outlined" className={classes.filter}>
          <InputLabel id="trainingDestination">
            {t("models.trainingDestination")}
          </InputLabel>
          <Select
            id="trainingDestination"
            labelId="trainingDestination"
            label={t("models.trainingDestination")}
            value={trainingDestination}
            onChange={(e: any) =>
              setTrainingDestination(e.target.value as string)
            }
          >
            {trainingDestinations.map((option: string) => {
              return (
                <MenuItem value={option} key={option}>
                  {t(`models.trainingDestinations.${option}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.filter}>
          <InputLabel id="deploymentEnvironment">
            {t("models.deploymentEnvironment")}
          </InputLabel>
          <Select
            id="deploymentEnvironment"
            labelId="deploymentEnvironment"
            label={t("models.deploymentEnvironment")}
            value={deploymentEnvironment}
            onChange={(e: any) =>
              setDeploymentEnvironment(e.target.value as string)
            }
          >
            {deploymentEnvironments.map((option: string) => {
              return (
                <MenuItem value={option} key={option}>
                  {t(`models.deploymentEnvironments.${option}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.filter}>
          <InputLabel id="running">{t("models.running")}</InputLabel>
          <Select
            id="running"
            labelId="running"
            label={t("models.running")}
            value={running}
            onChange={(e: any) => setRunning(e.target.value as string)}
          >
            {runningOptions.map((option: string) => {
              return (
                <MenuItem value={option} key={option}>
                  {t(`models.runningOptions.${option}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.filter}>
          <InputLabel id="archived">{t("models.archived")}</InputLabel>
          <Select
            id="archived"
            labelId="archived"
            label={t("models.archived")}
            value={archived}
            onChange={(e: any) => setArchived(e.target.value as string)}
          >
            {archivedOptions.map((option: string) => {
              return (
                <MenuItem value={option} key={option}>
                  {t(`models.archivedOptions.${option}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {isFetchingModels ? (
        <Spinner />
      ) : (
        <ModelTable
          {...{
            results,
            setArchiveId,
            setUnarchiveId,
            setMachineId,
            machineId,
            undeployId,
            deployId,
            recomputeId,
            stopTrainingJob,
            setUndeployId,
            setDeployId,
            setRecomputeId,
            setStopTrainingJob,
          }}
          id={machineId!}
          isModel={true}
        />
      )}
      {archiveId && machineId && (
        <ConfirmPopup
          onConfirm={handleArchive}
          title={tt("archiveDialog.title")}
          text={tt("archiveDialog.text")}
          confirmText={tt("archive")}
          noControl={true}
          onCancel={() => {
            setArchiveId(null);
            setMachineId(null);
          }}
        />
      )}
      {unarchiveId && machineId && (
        <ConfirmPopup
          onConfirm={handleArchive}
          title={tt("unarchiveDialog.title")}
          text={tt("unarchiveDialog.text")}
          confirmText={tt("unarchive")}
          noControl={true}
          onCancel={() => {
            setUnarchiveId(null);
            setMachineId(null);
          }}
        />
      )}
    </>
  );
});

export default ModelList;
