import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { useEffect, useMemo } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import { SnackbarProvider } from "notistack";
import createTheme from "theming/createTheme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "localization";

import Preloader from "components/preloader";
import App from "app";
import Notifier from "notifier";
import Modals from "components/modals";
import { install } from "resize-observer";

const AppRoot = (): JSX.Element => {
  const theme = useMemo(() => createTheme({}), []);

  useEffect(() => {
    /*
    initApm({
      serviceName: "portal-frontend",
      serverUrl:
      "https://ae4f1a8d83c34099a7085ca35fcd254f.apm.europe-west4.gcp.elastic-cloud.com:443",
      serviceVersion: "1.0.0",
      environment,
      distributedTracingOrigins: [getApiUrlRegex()],
      ignoreTransactions: [
        "https://cdn.pendo.io/*",
        "https://pendo-io-static.storage.googleapis.com/*",
      ],
    });
    */
    if (!(window as any).ResizeObserver) {
      install();
    }
  }, []);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Preloader>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </Preloader>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<AppRoot />);
} else {
  console.error("Root element not found");
}
serviceWorker.unregister();
