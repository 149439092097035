import { memo } from "react";
import { useTranslation } from "react-i18next";
import SingleSelect from "components/singleSelect";
import useRelatedEvent from "dataHooks/events/useRelatedEvent";
import Divider from "components/typography/heading/divider";
import Box from "@mui/material/Box";
import Spinner from "components/spinner";
import { useStyles } from "components/events/styles";

type Props = {
  onChange: (a: any) => void;
  value: number | string;
  relatedPairEvent?: number | null;
  machine?: number;
  className?: string;
};

export default memo(
  ({ onChange, machine, value, relatedPairEvent, className }: Props) => {
    const { t } = useTranslation();

    const { data, loadMore, loadingMore, loading } = useRelatedEvent(
      relatedPairEvent,
      machine
    );
    const classes = useStyles();

    if (!loading && !data.length) {
      return null;
    }

    return (
      <Box mt={2}>
        <Divider line noMargin>
          {t("machine.card.resolve.related_event")}
        </Divider>
        <Box mt={2} className={`${className} ${classes.relatedEventSelect}`}>
          {loading ? (
            <Spinner />
          ) : (
            <SingleSelect
              id="related_event"
              name={t("machine.card.resolve.related_event")}
              label={t("machine.card.resolve.related_event")}
              onChange={onChange}
              value={value}
              options={data}
              loadMore={loadMore}
              loading={loading}
              loadingMore={loadingMore}
            />
          )}
        </Box>
      </Box>
    );
  }
);
