import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialTypography, { TypographyProps } from "@mui/material/Typography";
import clsx from "clsx";

interface Props extends TypographyProps {
  line?: boolean;
  noMargin?: boolean;
  noMarginBottom?: boolean;
  centered?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      margin: theme.spacing(2, 0),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(4, 0),
      },
    },
    title: {
      fontSize: theme.custom.typography.fontSize[12],
      fontWeight: theme.custom.typography.fontWeight.medium,
      letterSpacing: "0.025rem",
      lineHeight: "1",
      textTransform: "uppercase",
      color: theme.custom.palette.label,
      margin: 0,
      display: "block",
    },
    line: {
      height: 1,
      flex: 1,
      background: theme.custom.palette.shade,
      margin: theme.spacing(0.625, 0, 0, 1.25),
    },
    lineBefore: {
      margin: theme.spacing(0.625, 1.25, 0, 0),
    },
    noMargin: {
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        margin: 0,
      },
    },
    noMarginBottom: {
      marginBottom: 0,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
    },
  };
});

const Divider = (props: Props) => {
  const classes = useStyles(props);
  const { line, noMargin, noMarginBottom, centered, ...rest } = props;
  return (
    <div
      className={clsx(
        classes.root,
        noMargin && classes.noMargin,
        noMarginBottom && classes.noMarginBottom
      )}
    >
      {centered && line && (
        <span className={clsx(classes.line, classes.lineBefore)}></span>
      )}
      <MaterialTypography
        className={classes.title}
        {...rest}
        variant="overline"
      />
      {line && <span className={classes.line}></span>}
    </div>
  );
};

export default Divider;
