import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectComponent from "components/select";

import { Field } from "react-final-form";
import { LangEnum, languagesUntranslatable } from "localization";
import keys from "lodash/keys";
type Props = {
  required?: boolean;
  validate?: (val: string) => string | false;
};

export default memo(({ required, validate }: Props) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return keys(LangEnum)
      .map((item: any) => ({
        key: item,
        value: item,
        text: languagesUntranslatable.find(({ lang }) => lang === item)?.text,
      }))
      .sort();
  }, [t]);

  return (
    <Field name="language" required={required} validate={validate}>
      {({ input, meta }) => {
        return (
          <SelectComponent
            id="language"
            label={t("users.detail.lang")}
            name="language"
            options={options}
            required={!!required}
            value={input.value}
            onChange={input.onChange}
            input={input}
            meta={meta}
            disabled={false}
          />
        );
      }}
    </Field>
  );
});
