import { Column } from "react-table";
import Name from "components/table/cells/name";
import { TFunction } from "i18next";
import { ActionsButton } from "./cells/actionsButton";
import { maxCharacters } from "shared/form/validators";
import { IReportsList } from "types/report";
import moment from "moment";

export const createColumns = (
  t: TFunction,
  inputMaxLength: number,
  recommendationLengthError: string | null,
  companyId: number,
  projectId: number,
  userIds: any,
  recommendation?: string
): Column<IReportsList>[] => [
  {
    id: "monthly_list",
    Header: t("reports.list.table.name.title").toString(),
    accessor: ({ id, created_for }) => {
      const yearAndMonth = moment(created_for).format("YYYY/MM");
      const name = `${t("reports.list.table.name.value")} - ${yearAndMonth}`;
      return {
        id,
        label: name,
        path: "projectDetail.reports",
        validate: maxCharacters(50),
      };
    },
    Cell: Name,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "creation",
    Header: t("reports.list.table.creation").toString(),
    accessor: ({ last_update_at, id }) => {
      const date = moment(last_update_at).format("YYYY/MM/DD");
      return {
        id,
        label: date,
        path: "projectDetail.reports",
        validate: maxCharacters(50),
      };
    },
    Cell: Name,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "kebab",
    Header: "",
    accessor: ({ id, recommendations, created_for, link }) => {
      return {
        id,
        recommendations,
        recommendationValue: recommendation,
        inputMaxLength,
        recommendationLengthError,
        companyId,
        projectId,
        created_for,
        link,
        userIds,
      };
    },
    Cell: ActionsButton,
    custom: {
      hidePriority: 0,
      width: 64,
    },
  },
];
