import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import Heading1 from "components/typography/heading/heading2";
import Button from "components/button";
import IconButtonCTA from "components/iconButtonCTA";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      flexShrink: 0,
      minHeight: theme.spacing(11),
      boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
      position: "relative",
      zIndex: 10,
      [`@media only screen and (min-width: ${theme.spacing(96)})`]: {
        minHeight: "auto",
        height: theme.spacing(11),
      },
    },
    circleButton: {
      position: "absolute",
      left: "50%",
      bottom: 0,
      transform: "translate(-50%, 50%)",
    },
  };
});

interface Props {
  modalTitle: string;
  buttonText?: string;
  buttonClick?: () => void;
  circleButtonIcon?: React.ReactElement;
  circleButtonClick?: () => void;
  buttonDisplay?: boolean;
  circleButtonDisplay?: boolean;
}

const Header: React.FC<Props> = ({
  modalTitle,
  buttonText,
  buttonClick,
  buttonDisplay,
  circleButtonIcon,
  circleButtonClick,
  circleButtonDisplay,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box p={4} pt={4} display="flex">
        <Heading1>{modalTitle}</Heading1>
        <Box ml="auto" pl={2}>
          {buttonDisplay && buttonText && buttonClick && (
            <Button color="secondary" onClick={buttonClick}>
              {buttonText}
            </Button>
          )}
        </Box>
      </Box>
      {circleButtonDisplay && circleButtonClick && circleButtonIcon && (
        <div className={classes.circleButton}>
          <IconButtonCTA onClick={circleButtonClick}>
            {circleButtonIcon}
          </IconButtonCTA>
        </div>
      )}
    </div>
  );
};

export default Header;
