import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface IProps {
  id: number;
  users: number[];
}

export default async function sendReport({ id, users }: IProps) {
  const url = endpoints.reports.send(id);

  try {
    const { data } = await axiosNeuron.post(url, {
      users,
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
