import { useEffect, useRef, useMemo, useCallback } from "react";
import { SortingRule } from "react-table";
import actions, { fetchUsers } from "store/addUserModal/actions";
import { AppState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import isEqual from "react-fast-compare";

const { setPage, setShowMore, setSort, setSearch, setReloading, setLoading } =
  actions;

const selector = createSelector(
  (state: AppState) => state.addUserModal,
  (addUserModal: any) => {
    return {
      data: addUserModal.data,
      loading: addUserModal.loading,
      pageIndex: addUserModal.pageIndex,
      reloading: addUserModal.reloading,
      q: addUserModal.q,
      sort: addUserModal.sort,
      selected: addUserModal.selected,
    };
  }
);

const pageSize = 10;

const useAddUser = () => {
  const sm = useRef(0);

  const dispatch: any = useDispatch();

  const { data, loading, pageIndex, reloading, q, sort, selected } =
    useSelector(selector, isEqual);

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount: data && data.results ? Math.ceil(data.count / pageSize) : 0,
      sortBy: sort,
    };
  }, [data, pageIndex, sort]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      dispatch(setPage(pageIndex));
    },
    [dispatch]
  );

  const onPageSort = useCallback(
    (sortBy: SortingRule<number>[]) => {
      dispatch(setSort(sortBy));
    },
    [dispatch]
  );

  const onSearch = useCallback(
    (event: any) => {
      const value = event?.currentTarget?.value || event;
      if (value !== q) {
        dispatch(setSearch(value));
        sm.current = 0;
        dispatch(setPage(0));
        dispatch(setReloading(true));
      }
    },
    [dispatch, q]
  );

  useEffect(() => {
    dispatch(setShowMore(sm.current));
    if (!sm.current) {
      dispatch(setReloading(true));
    } else {
      dispatch(setLoading(true));
    }
    dispatch(
      fetchUsers({
        page: !sm.current ? pageIndex + 1 : 1,
        pageSize: sm.current ? (sm.current + 1) * pageSize : pageSize,
        orderBy: sort,
        q,
      })
    );
  }, [dispatch, pageIndex, q, sort]);

  return {
    pageIndex,
    loading,
    reloading,
    initialState,
    data,
    selected,
    showMoreClickedTimesRef: sm,
    onPageChange,
    onPageSort,
    onSearch,
  };
};
export default useAddUser;
