import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonGroup: {
    boxShadow: theme.custom.boxShadow["2"],
  },
  button: {
    backgroundColor: theme.custom.palette.secondary,
    color: theme.custom.palette.primary["100"],
    borderColor: theme.custom.palette.primary["100"],
  },
}));

const changeBuffer = (fn, val) => () => fn(val);

const MinuteNavigationBar = (props) => {
  const { onChangeBuffer } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup
        className={classes.buttonGroup}
        variant="text"
        aria-label="outlined primary button group"
      >
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, -600)}
        >
          -10 min
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, -180)}
        >
          -3 min
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, -60)}
        >
          -1 min
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, -10)}
        >
          -10 sec
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, 10)}
        >
          +10 sec
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, 60)}
        >
          +1 min
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, 180)}
        >
          +3 min
        </Button>
        <Button
          className={classes.button}
          onClick={changeBuffer(onChangeBuffer, 600)}
        >
          +10 min
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default MinuteNavigationBar;
