import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions from "store/projectDetail/users/actions";
import { useQueryParams, StringParam } from "use-query-params";

const { setSearch } = actions;

const useListUsers = () => {
  const dispatch: any = useDispatch();

  const { q } = useSelector(
    (state: AppState) => state.projectDetail.users,
    isEqual
  );

  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });

  const onSearch = useCallback(
    (event: any) => {
      const value = event?.currentTarget?.value || event;
      if (value !== q) {
        setQuery({ q: value.length ? value : undefined }, "pushIn");
        dispatch(setSearch({ q: value.length ? value : undefined }));
      }
    },
    [q, setQuery]
  );

  useEffect(() => {
    if (!isEqual(query.q, q)) {
      dispatch(setSearch(query.q));
    }
  }, [dispatch, query, q]);

  return {
    q,
    onSearch,
  };
};

export default useListUsers;
