import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import getUsers from "api/handlers/user/getUsers";
import { EActions, IUsersAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import getProjects from "api/handlers/project/getProjects";
import { IProjectList } from "types/project";

export const path = "accessTokens";

const actionsData = [
  [EActions.fetchUsersRequest],
  [EActions.fetchUsersFail, "error"],
  [EActions.fetchUsersSuccess, "users"],
  [EActions.fetchProjectsRequest],
  [EActions.fetchProjectsFail, "error"],
  [EActions.fetchProjectsSuccess, "projects"],
];

const actions = prepareActions<IUsersAction, EActions>(actionsData, path);
export default actions;

export const fetchProjects =
  (ids: number[]) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchProjectsRequest]());
    try {
      const projects = await getProjects({ ids, pageSize: 99999 });

      const projectsData = projects?.results.map((item: IProjectList) => ({
        value: item.id,
        text: item.name,
      }));
      dispatch(actions[EActions.fetchProjectsSuccess](projectsData));
    } catch (error) {
      dispatch(actions[EActions.fetchProjectsFail](error));
    }
  };

export const fetchUsers =
  (ids: number[]) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchUsersRequest]());
    try {
      const users = await getUsers({
        ids,
        pageSize: 99999,
      });
      const usersData = users?.results.map((item: any) => ({
        value: item.id,
        text: `${item.firstName} ${item.lastName}`,
      }));
      dispatch(actions[EActions.fetchUsersSuccess](usersData));
    } catch (error) {
      dispatch(actions[EActions.fetchUsersFail](error));
    }
  };
