export enum EActions {
  setFilter = "setFilter",
}

export interface UsersFilterState {
  filters?: {
    company?: number[];
    q?: string;
  };
}

export interface UsersFilterAction {
  type: typeof EActions;
  payload: {
    filters?: {
      company: number[];
      q?: string;
    };
  };
}

export type TReducers = {
  [key: string]: (p: UsersFilterAction, s: UsersFilterState) => void;
};

export type UsersFilterActionTypes = UsersFilterAction;
