import React from "react";
import { Column, CellProps } from "react-table";
import LabelAndTag from "components/users/list/cells/labelAndTag";
import LabelEmail from "components/users/list/cells/labelEmail";
import DateAndTime from "components/users/list/cells/dateAndTime";
import BelongsToProject from "components/users/list/cells/belongsToProject";
import Status from "components/users/list/cells/status";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import ActionsCell from "components/users/list/actions/cell";
import { getTranslatedRole } from "shared/roles";

export const createColumns = (
  t: TFunction,
  projectId: number
): Column<IUserList>[] => [
  {
    id: "assigned_project",
    Header: t("users.list.table.inProject").toString(),
    accessor: ({ id, projects }) => ({
      id,
      projects,
    }),
    Cell: BelongsToProject,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    id: "last_name",
    Header: t("users.list.table.nameRole").toString(),
    accessor: ({ firstName, lastName, role }) => {
      return {
        name: [firstName, lastName].join(" "),
        role: getTranslatedRole(t, role),
      };
    },
    Cell: LabelAndTag,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "email",
    Header: t("users.list.table.email").toString(),
    accessor: ({ email }) => {
      return { label: email };
    },
    Cell: LabelEmail,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "last_login",
    Header: t("users.list.table.lastLogin").toString(),
    accessor: (originalRow: any) => {
      return { date: originalRow.lastLogin };
    },
    Cell: DateAndTime,
    custom: {
      hidePriority: 3,
    },
  },
  {
    id: "status",
    Header: t("users.list.table.status").toString(),
    accessor: ({ status }) => {
      return { status: status };
    },
    disableSortBy: true,
    Cell: Status,
    custom: {
      hidePriority: 0,
    },
  },
  {
    Header: "",
    id: "actions",
    Cell: (props: CellProps<IUserList>) => (
      <ActionsCell {...props} projectId={projectId} />
    ),
    custom: {
      hidePriority: 0,
      width: 72,
    },
  },
];
