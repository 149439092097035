import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";

const Phone: React.FC = (props: any) => {
  const { t } = useTranslation();
  return (
    <PhoneInput
      value={props.input.value}
      onChange={props.input.onChange}
      inputProps={{
        style: { width: "100%", height: 48 },
      }}
      specialLabel={t("users.detail.phone")}
      country="cz"
      countryCodeEditable={false}
    />
  );
};

export default Phone;
