import React from "react";
import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Confirm from "components/confirmPopup";
import { KebabMenu } from "components/kebab";

export type CellAction = {
  text: string;
  onClick: () => void;
  confirmTitle?: string;
  confirmText?: string;
  confirmDesc?: string;
};

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: (props: Props<any>) => theme.spacing(props.marginTop || 0),
    },
    button: {
      margin: theme.spacing(0, 2.5),
      color: theme.custom.palette.data,
    },
    paper: {
      borderRadius: theme.spacing(1),
    },
    itemRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      height: theme.spacing(4),
      "&:hover": {
        background: theme.custom.palette.shade,
      },
    },
    itemPrimary: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

interface Props<T extends Object> extends CellProps<T> {
  items: CellAction[];
  marginTop?: number;
}

const Actions = <T extends Object>(props: Props<T>) => {
  const { items } = props;
  const classes = useStyles(props);

  return (
    <KebabMenu
      data={
        <List component="nav">
          {items.map(
            (item, index) =>
              item.text &&
              item.onClick &&
              (item.confirmTitle && item.confirmText && item.confirmDesc ? (
                <Confirm
                  key={index}
                  onConfirm={() => {
                    item.onClick();
                  }}
                  text={item.confirmDesc}
                  title={item.confirmTitle}
                  confirmText={item.confirmText}
                  ToggleButton={
                    <ListItem
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                      button
                      classes={{ root: classes.itemRoot }}
                    >
                      <ListItemText
                        primary={item.text}
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      />
                    </ListItem>
                  }
                />
              ) : (
                <ListItem
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    item.onClick();
                  }}
                  button
                  key={index}
                  classes={{ root: classes.itemRoot }}
                >
                  <ListItemText
                    primary={item.text}
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              ))
          )}
        </List>
      }
    />
  );
};

export default Actions;
