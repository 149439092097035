import { EActions, TReducer, IMachineDetailState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IMachineDetailState = {
  machine: null,
  facility: null,
  users: [],
  audioUploader: null,
  isFetching: true,
  error: null,
  machineImage: null,
  machineFullImage: null,
  isFetchingImage: true,
  isFetchingFullImage: false,
  models: null,
  isFetchingModels: true,
  productionModes: [],
  isFetchingProductionModes: false,
};

const reducers: TReducer = {
  [EActions.FETCH_MACHINE_REQUEST]: () => ({
    isFetching: true,
  }),
  [EActions.FETCH_MACHINE_FAIL]: ({ payload: { error } }) => ({
    machine: null,
    isFetching: false,
    error: error,
  }),
  [EActions.FETCH_MACHINE_SUCCESS]: ({
    payload: { machine, facility, users },
  }) => ({
    machine: machine,
    facility: facility,
    users: users,
    isFetching: false,
    error: null,
  }),
  [EActions.FETCH_FACILITY_SUCCESS]: ({ payload: { facility } }) => ({
    facility,
  }),
  [EActions.FETCH_MACHINE_IMAGE_REQUEST]: () => ({
    isFetchingImage: true,
    machineFullImage: null,
  }),
  [EActions.FETCH_MACHINE_IMAGE_FAIL]: ({ payload: { error } }) => ({
    machineImage: null,
    isFetchingImage: false,
    error: error,
  }),
  [EActions.FETCH_MACHINE_IMAGE_SUCCESS]: ({ payload: { machineImage } }) => ({
    machineImage: machineImage,
    isFetchingImage: false,
    error: null,
  }),
  [EActions.FETCH_MACHINE_FULL_IMAGE_REQUEST]: () => ({
    isFetchingFullImage: true,
  }),
  [EActions.FETCH_MACHINE_FULL_IMAGE_FAIL]: ({ payload: { error } }) => ({
    machineFullImage: null,
    isFetchingFullImage: false,
    error: error,
  }),
  [EActions.FETCH_MACHINE_FULL_IMAGE_SUCCESS]: ({
    payload: { machineFullImage },
  }) => ({
    machineFullImage,
    isFetchingFullImage: false,
    error: null,
  }),
  [EActions.FETCH_MACHINE_MODELS_REQUEST]: () => ({
    isFetchingModels: true,
  }),
  [EActions.FETCH_MACHINE_MODELS_FAIL]: ({ payload: { error } }) => ({
    models: null,
    isFetchingModels: false,
    error: error,
  }),
  [EActions.FETCH_MACHINE_MODELS_SUCCESS]: ({ payload: { models } }) => ({
    models,
    isFetchingModels: false,
    error: null,
  }),
  [EActions.FETCH_MACHINE_PRODUCTION_MODES_REQUEST]: () => ({
    isFetchingProductionModes: true,
  }),
  [EActions.FETCH_MACHINE_PRODUCTION_MODES_FAIL]: ({ payload: { error } }) => ({
    productionModes: [],
    isFetchingProductionModes: false,
    error,
  }),
  [EActions.FETCH_MACHINE_PRODUCTION_MODES_SUCCESS]: ({
    payload: { productionModes },
  }) => ({
    productionModes,
    isFetchingProductionModes: false,
    error: null,
  }),
  [EActions.setReset]: () => ({
    ...initialState,
  }),
};

export default createReducer<IMachineDetailState>(path, initialState, reducers);
