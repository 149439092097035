import React, { PureComponent } from "react";
import FftTick from "./FftTick";

class FftTicks extends PureComponent {
  static defaultProps = {
    width: 0,
    max: 0,
  };

  render() {
    let { width, max } = this.props;
    let ticks = [];
    let left = 0;
    const increment = Math.round(Math.round(max) * 0.1);
    const tickWidth = (width / max) * increment;
    for (let i = 0; i <= max; i += increment) {
      ticks.push(
        <FftTick
          key={i}
          left={left}
          width={tickWidth}
          label={i + "k"}
          widthLeft={width - left}
          last={i + increment > max}
        />
      );
      left += tickWidth;
    }
    return <div style={{ position: "relative" }}>{ticks}</div>;
  }
}

export default FftTicks;
