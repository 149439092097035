import React from "react";
import Grid from "@mui/material/Grid";
import isEqual from "lodash/isEqual";

import Company from "./company";
import { ICompany } from "types/companiesAndProjects";
import Spinner from "components/spinner";
import FooterConfirmationBar from "components/layout/footerConfirmationBar";
import { CustomFooter } from "components/customFooter";
import useDetailData from "dataHooks/users/create/detailData";

type Props = {
  needsConfirmation: boolean;
  onChangeCP: () => void;
};

const CompaniesProjectsForm = ({ needsConfirmation, onChangeCP }: Props) => {
  const {
    CPData,
    pageIndex,
    pageCount,
    originalActiveCompanies,
    originalActiveProjects,
    activeCompanies,
    activeProjects,
    isFetching,
    onChangePage,
    onDiscardChanges,
    goToNext,
    goToPrevious,
  } = useDetailData();

  if (CPData === null || isFetching) {
    return <Spinner />;
  }

  return (
    <>
      {CPData &&
        CPData.results.map((company: ICompany) => {
          return <Company data={company} key={`company${company.id}`} />;
        })}
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomFooter
              pageIndex={pageIndex}
              previousPage={goToPrevious}
              nextPage={goToNext}
              canPreviousPage={pageIndex >= 1}
              canNextPage={pageIndex < pageCount - 1}
              pageCount={pageCount}
              gotoPage={onChangePage}
            />
          </Grid>
        </Grid>
      </div>
      {needsConfirmation && (
        <FooterConfirmationBar
          onSubmit={onChangeCP}
          discard={onDiscardChanges}
          open={
            !isEqual(activeCompanies, originalActiveCompanies) ||
            !isEqual(activeProjects, originalActiveProjects)
          }
        />
      )}
    </>
  );
};

export default CompaniesProjectsForm;
