import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { AppState } from "store";
import Picker from "localization/pickerLocale";
import actions, { fetchChunkTypes } from "store/eventSounds/actions";
import Search from "components/search";
import PlacementType from "./placementType";
import MachineType from "./machineType";
import FailureType from "./failureType";
import EventType from "./eventType";
import Machine from "./machine";
import { useTranslation } from "react-i18next";
import IMuiTheme from "types/styles";
import dayjs from "dayjs";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  wrapper: {
    padding: theme.spacing(1.5, 0),
  },
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  picker: {
    width: "100%",
  },
  input: {
    paddingTop: theme.typography.pxToRem(10.5),
    paddingBottom: theme.typography.pxToRem(10.5),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

type IProps = {
  setSearchValues: React.Dispatch<
    React.SetStateAction<{
      from: Date | null;
      to: Date | null;
      event_type: string[] | null;
      failure_type: string[] | null;
      placement_type: number[] | null;
      machine_subcategory: number[] | null;
      machine: number[] | null;
      q: string | null;
    }>
  >;
  searchValues: {
    from: Date | null;
    to: Date | null;
    event_type: string[] | null;
    failure_type: string[] | null;
    placement_type: number[] | null;
    machine_subcategory: number[] | null;
    machine: number[] | null;
    q: string | null;
  };
};

const SoundFilters = ({ setSearchValues, searchValues }: IProps) => {
  const dispatch: any = useDispatch();
  const {
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    machine,
    q: qValue,
  } = searchValues;
  const {
    placementTypeValues,
    machineTypeValues,
    failureTypeValues,
    eventTypeValues,
    machineValues,
    labelStart,
    labelEnd,
  } = useSelector((state: AppState) => state.eventSounds);
  const classes = useStyles();
  const { t } = useTranslation();
  const [q, setQ] = useState<string | null>(qValue ? qValue : "");

  const onChangeLabelStart = useCallback(
    (date: any) => {
      dispatch(actions.setLabelStart(date));
    },
    [dispatch]
  );
  const onChangeLabelEnd = useCallback(
    (date: any) => {
      dispatch(actions.setLabelEnd(date));
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(actions.setLabelStart(from));
    dispatch(actions.setLabelEnd(to));
    dispatch(actions.setEventTypeValues(event_type));
    dispatch(actions.setFailureTypeValues(failure_type));
    dispatch(actions.setMachineValues(machine));
    dispatch(actions.setMachineTypeValues(machine_subcategory));
    dispatch(actions.setPlacementTypeValues(placement_type));
  }, [
    dispatch,
    event_type,
    failure_type,
    from,
    machine,
    machine_subcategory,
    placement_type,
    to,
  ]);

  useEffect(() => {
    dispatch(fetchChunkTypes());
  }, [dispatch]);

  const onSearch = useCallback(() => {
    setSearchValues({
      from: labelStart,
      to: labelEnd,
      event_type: eventTypeValues,
      failure_type: failureTypeValues,
      placement_type: placementTypeValues,
      machine_subcategory: machineTypeValues,
      machine: machineValues,
      q,
    });
    dispatch(actions.setIsSearchedClicked(true));
  }, [
    eventTypeValues,
    failureTypeValues,
    labelEnd,
    labelStart,
    machineTypeValues,
    placementTypeValues,
    machineValues,
    q,
    setSearchValues,
    dispatch,
  ]);

  const onClearSearch = useCallback(() => {
    setSearchValues({
      from: null,
      to: null,
      event_type: null,
      failure_type: null,
      placement_type: null,
      machine_subcategory: null,
      machine: null,
      q: null,
    });

    dispatch(actions.setEventTypeValues(null));
    dispatch(actions.setFailureTypeValues(null));
    dispatch(actions.setMachineValues(null));
    dispatch(actions.setMachineTypeValues(null));
    dispatch(actions.setPlacementTypeValues(null));

    dispatch(actions.setLabelStart(null));
    dispatch(actions.setLabelEnd(null));
    setQ("");
  }, [dispatch, setSearchValues]);

  const onQSearch = useCallback((event: any) => {
    setQ(event);
  }, []);

  return (
    <Box>
      <div className={classes.wrapper}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12} sm={3} lg={2}>
            <MachineType />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <Machine />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <PlacementType />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <FailureType />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <EventType />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
            sx={{
              "& .MuiInputBase-root": {
                paddingRight: "15px",
              },
            }}
          >
            <Picker
              Component={DatePicker}
              className={classes.picker}
              label={t("eventSounds.dataSelection.filter.startDate")}
              inputVariant="outlined"
              value={labelStart ? dayjs(labelStart) : null}
              onChange={onChangeLabelStart}
              clearable
              format="YYYY/MM/DD"
              id={`testDatePicker-start`}
              inputProps={{ className: classes.input }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            lg={2}
            sx={{
              "& .MuiInputBase-root": {
                paddingRight: "15px",
              },
            }}
          >
            <Picker
              Component={DatePicker}
              minDate={labelStart}
              className={classes.picker}
              label={t("eventSounds.dataSelection.filter.endDate")}
              inputVariant="outlined"
              value={labelEnd ? dayjs(labelEnd) : null}
              onChange={onChangeLabelEnd}
              clearable
              id={`testDatePicker-start`}
              format="YYYY/MM/DD"
              inputProps={{ className: classes.input }}
            />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <Search
              value={q as string}
              onSearch={onQSearch}
              placeholder={t("search.searchEventSound")}
              isAutoSearch={true}
              hideIcon={true}
            />
          </Grid>
          <Grid item className={classes.buttons}>
            <Button onClick={onClearSearch} variant="contained">
              {t("filter.clear")}
            </Button>
            <Button onClick={onSearch} variant="contained">
              {t("eventSounds.dataSelection.filter.searchButton")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default SoundFilters;
