import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialIconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    root: {
      color: theme.custom.palette.data,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "transparent",
      transitionProperty: "box-shadow",
      padding: 2,
      "&:hover": {
        background: theme.custom.palette.secondary,
      },
      "&:focus": {
        background: theme.custom.palette.active.light,
      },
      "&:active, &:focus:active": {
        borderColor: "transparent",
        background: theme.custom.palette.shade,
        boxShadow: theme.custom.boxShadow["0"],
      },
      "&:disabled": {
        color: theme.custom.palette.placeholder,
      },
    },
  };
});

const IconButton = (props: IconButtonProps) => {
  const classes = useStyles(props);
  return <MaterialIconButton className={classes.root} {...props} />;
};

export default IconButton;
