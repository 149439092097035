import getAudioContext from "./getAudioContext";

export class Player {
  ctx = null;
  script = null;
  source = null;
  buffer = null;
  released = false;
  scriptBufferSize = 256;

  constructor(sampleRate) {
    this.ctx = getAudioContext(sampleRate);
  }

  init(file) {
    return window._loader.load(file);
  }

  disconnectSource() {
    this.source && this.source.disconnect();
    this.source = null;
    this.buffer = null;
  }

  release() {
    this.released = true;
  }

  createScript() {
    if (this.ctx.createScriptProcessor) {
      this.script = this.ctx.createScriptProcessor(
        Player.scriptBufferSize,
        1,
        1
      );
    } else {
      this.script = this.ctx.createJavaScriptNode(Player.scriptBufferSize);
    }
    this.script.onaudioprocess = () => {};
    this.script.connect(this.ctx.destination);
  }

  createSource(buffer) {
    this.disconnectSource();
    this.released = false;
    this.buffer = buffer;
    this.source = this.ctx.createBufferSource();

    // adjust for old browsers
    this.source.start = this.source.start || this.source.noteGrainOn;
    this.source.stop = this.source.stop || this.source.noteOff;

    this.source.buffer = this.buffer;
    this.source.loop = true;
    this.source.connect(this.ctx.destination);
  }

  update = (offset, onProgress, onStop) => {
    return () => {
      if (!this.released) {
        if (this.ctx.currentTime < this.end) {
          if (this.source && this.source.loopStart && this.source.loopEnd) {
            // displaying progress when loop
            const { loopStart, loopEnd } = this.source;

            // reset progress when reaching loopEnd
            if (loopStart + (this.ctx.currentTime - this.start) > loopEnd) {
              this.start = this.ctx.currentTime;
            }

            onProgress(loopStart + (this.ctx.currentTime - this.start));
            window.requestAnimationFrame(
              this.update(offset, onProgress, onStop)
            );
          } else {
            // regular progress display
            onProgress(offset + this.ctx.currentTime - this.start);
            window.requestAnimationFrame(
              this.update(offset, onProgress, onStop)
            );
          }
        } else {
          onStop();
        }
      }
    };
  };

  stop() {
    this.end = this.ctx.currentTime;
    this.disconnectSource();
    if (window && window.navigator.mediaSession) {
      window.navigator.mediaSession.playbackState = "none";
    }
  }

  play(buffer, offset, duration, onProgress, onStop, loopStart, loopEnd) {
    this.stop();
    this.createSource(buffer);
    this.start = this.ctx.currentTime;
    this.end = this.ctx.currentTime + duration;
    // loop start/end when loop true
    this.source.loopStart = loopStart || 0;
    this.source.loopEnd = loopEnd || 0;

    const fixOffset = Math.max(0, offset);
    const fixDuration = Math.min(duration, this.buffer.duration - fixOffset);
    this.source.start(0, fixOffset, fixDuration);
    window.requestAnimationFrame(this.update(offset, onProgress, onStop));
    if (window && window.navigator.mediaSession) {
      window.navigator.mediaSession.playbackState = "playing";
    }
  }

  playFake(duration, callback) {
    this.start = this.ctx.currentTime;
    this.end = this.ctx.currentTime + 10;
    window.requestAnimationFrame(this.update(callback));
  }
}

let player = null;
export default function getPlayer(sampleRate) {
  if (player === null) {
    player = new Player(sampleRate);
  }
  return player;
}
