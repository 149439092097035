import { EActions, TokenActionTypes } from "store/token/types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import getTokenApiHandler, { ICredentials } from "api/handlers/token/getToken";
import { prepareActions } from "store/helpers";
import { setToken } from "store/auth/actions";
import { AppState } from "store";

export const path = "token";

const actionsData = [
  [EActions.getTokenRequest],
  [EActions.getTokenSuccess],
  [EActions.getTokenFail, "error"],
];

const actions = prepareActions<TokenActionTypes, EActions>(actionsData, path);

export default actions;

export const getToken =
  (credentials: ICredentials, errorCallback: () => void) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions.getTokenRequest(true));
      const token: string = await getTokenApiHandler(credentials);
      dispatch(actions.getTokenSuccess(token));
      dispatch(setToken(token, "token"));
    } catch (error) {
      dispatch(actions.getTokenFail(error));
      errorCallback();
    }
  };
