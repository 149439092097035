import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  filters: {
    gap: "1rem",

    "& .MuiInputBase-root": {
      paddingRight: "15px",
    },
    "& .MuiSelect-select": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  filter: {
    minWidth: "16rem",
  },
  picker: {
    "& .MuiInputBase-input": {
      paddingTop: theme.typography.pxToRem(17),
      paddingBottom: theme.typography.pxToRem(17),
    },
  },
  search: {
    "& .MuiInputBase-input": {
      paddingTop: theme.typography.pxToRem(17),
      paddingBottom: theme.typography.pxToRem(17),
    },
  },
}));

export default useStyles;
