import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  machine: number;
  start: {
    type: string;
    created_at: string;
    note: string;
    meta?: {
      purpose: string;
    };
  };
  end?: {
    type: string;
    created_at: string;
    note: string;
  };
};

export default async function addSingleEvent(event: TParams) {
  const url = endpoints.events.default;
  try {
    const response = await axiosNeuron.post(url, {
      ...event.start,
      type: event.start.type.substring(0, event.start.type.length - 6),
      machine: event.machine,
    });
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
