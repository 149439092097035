const statuses: {
  [key: string]: {
    filter: { [key: string]: string };
    indent?: boolean;
  };
} = {
  new: {
    filter: {
      status: "new",
    },
  },
  under_investigation: {
    filter: {
      status: "under_investigation",
    },
  },
  customer_informed: {
    filter: {
      status: "customer_informed",
    },
  },
  no_email: {
    filter: {
      status: "customer_informed",
      is_critical: "null",
    },
    indent: true,
  },
  noncritical_email: {
    filter: {
      status: "customer_informed",
      is_critical: "false",
    },
    indent: true,
  },
  critical_email: {
    filter: {
      status: "customer_informed",
      is_critical: "true",
    },
    indent: true,
  },
  resolved: {
    filter: {
      status: "resolved",
    },
  },
  no_failure: {
    filter: {
      status: "resolved",
      alert_severity: "no_failure",
    },
    indent: true,
  },
  confirmed_failure: {
    filter: {
      status: "resolved",
      alert_severity: "confirmed_failure",
    },
    indent: true,
  },
  sensor_issue: {
    filter: {
      status: "resolved",
      alert_severity: "sensor_issue",
    },
    indent: true,
  },
  reason_unknown: {
    filter: {
      status: "resolved",
      alert_severity: "reason_unknown",
    },
    indent: true,
  },
};

export { statuses };
