import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function getLabelCategories(ids?: number[]) {
  const idsFilter = ids ? `&id=${ids.join("&id=")}` : "";
  const url = `${endpoints.labels.categories}?${idsFilter}`;
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(url);
    return results;
  } catch {
    return;
  }
}
