import { IMachineList, IServiceList } from "types/serviceManager";

export enum EActions {
  fetchServicesRequest = "fetchServicesRequest",
  fetchServicesFail = "fetchServicesFail",
  fetchServicesSuccess = "fetchServicesSuccess",

  fetchMachinesRequest = "fetchMachinesRequest",
  fetchMachinesFail = "fetchMachinesFail",
  fetchMachinesSuccess = "fetchMachinesSuccess",

  setMachineParams = "setMachineParams",
  setSupports = "setSupports",
  setFilter = "setFilter",
}

export interface IServiceManagerState {
  error?: Error | null;
  services: IServiceList[] | null;
  isFetching?: boolean;

  machines: {
    data: IMachineList[] | null;
    isFetching?: boolean;
    error: Error | null;
  };

  machineParams: {
    ids?: number[] | null;
    service?: string[] | null;
    company?: number[] | null;
    projectId?: number[] | null;
    facilityId?: number[] | null;
    q?: string;
  };

  supports: {
    disabled: boolean;
    edge: boolean;
  };
  filters?: {
    company?: number[] | null;
    projectId?: number[] | null;
    facilityId?: number[] | null;
    q?: string;
  };
}

export interface IServiceManagerAction {
  type: typeof EActions;
  payload: IServiceManagerState;
}

export type TReducers = {
  [key: string]: (p: IServiceManagerAction, s: IServiceManagerState) => void;
};

export type TUsersActionTypes = IServiceManagerAction;
