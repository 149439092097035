import React, { memo, useState, useCallback } from "react";
import Button from "./button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Spinner from "components/spinner";

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      margin: theme.spacing(0, 2.5),
      color: theme.custom.palette.data,
    },
    paper: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 0, 1, 0),
      minWidth: 100,
      maxHeight: 300,
    },
    item: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

type TSelectPopover = {
  title: string;
  selected: Partial<{
    name: string;
    customName: string;
    id: number | string;
    channel: number | null;
    codename: string;
  }>;
  customcolor?: string;
  onChange: (id: number) => void;
  disabled?: boolean;
  data: { name: string; id: string | number; disabled?: boolean }[];
  width?: number | string;
  applyDisableRule?: (item: { [key: string]: number }) => boolean;
  fetchData?: () => void;
  loading?: boolean;
  titleClassName?: string;
};

export const SelectPopover = memo(
  ({
    title,
    data,
    selected,
    customcolor: customColor,
    onChange,
    fetchData,
    loading,
    disabled,
    applyDisableRule,
    titleClassName,
  }: TSelectPopover) => {
    const [open, setMenuOpen] = useState<any>(false);
    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    const openMenu = useCallback(
      (event: any) => {
        event.stopPropagation();
        fetchData && fetchData();
        setMenuOpen(event!.currentTarget);
      },
      [fetchData]
    );

    const closeMenu = useCallback((event?: any) => {
      event?.stopPropagation();
      setMenuOpen(null);
    }, []);

    const onSelect = useCallback(
      (event: any) => {
        event.stopPropagation();
        if (event.currentTarget.dataset.itemId) {
          onChange(event.currentTarget.dataset.itemId);
          closeMenu();
        }
      },
      [closeMenu, onChange]
    );

    return (
      <Box>
        <Button
          customcolor={!!selected ? customColor : "grey"}
          disabled={disabled}
          padding="0"
          onClick={openMenu}
          endIcon={<ArrowDropDownIcon />}
          width={xs ? 100 : "auto"}
        >
          <span className={titleClassName}>
            {selected?.customName || selected?.name || title}
          </span>
        </Button>
        <Popover
          id={open ? "simple-popover" : undefined}
          open={!!open}
          anchorEl={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            data.map((item: any, index: number) => (
              <MenuItem
                key={item.name + index}
                tabIndex={0}
                data-item-id={item.id}
                disabled={applyDisableRule ? applyDisableRule(item) : false}
                onClick={onSelect}
                id={`testEditDevice-${item.id}`}
                className={classes.item}
              >
                {item.customName || item.name}
              </MenuItem>
            ))
          )}
        </Popover>
      </Box>
    );
  }
);
