import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import Button from "components/button";
import { Field, Form } from "react-final-form";
import Tooltip from "components/tooltip";
import clsx from "clsx";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: ({ height }: Props) => height || theme.spacing(4),
    background: theme.custom.palette.secondary,
    borderRadius: theme.spacing(0.5),
    marginTop: "inherit",
    marginBottom: "inherit",
  },
  input: {
    flex: 1,
    minWidth: theme.spacing(10),
    background: theme.custom.palette.secondary,
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.custom.typography.fontSize[14],
    letterSpacing: theme.custom.typography.letterSpacing["medium"],
    color: theme.custom.palette.data,
  },
  clearIcon: {
    margin: theme.spacing(0.75),
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    color: theme.custom.palette.secondary,
    background: theme.custom.palette.placeholder,
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
  button: {
    background: "transparent",
    color: theme.custom.palette.primary[100],
    letterSpacing: theme.custom.typography.letterSpacing["big"],
    fontSize: theme.custom.typography.fontSize[14],
    fontWeight: theme.custom.typography.fontWeight["medium"],
  },
  form: {
    width: "100%",
  },
}));

type Props = {
  value?: string;
  placeholder?: string;
  validate?: (
    value: string,
    allValues: Object,
    meta?: Object
  ) => string | boolean;
  onCancel?: (e: any) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClick: (value: string) => void;
  isEditation?: boolean;
  entity?: string;
  height?: string | number;
  disabled?: boolean;
};

const EditBar = (props: Props) => {
  const { t } = useTranslation();
  const {
    value,
    onClick,
    onCancel,
    isEditation,
    placeholder,
    validate,
    disabled,
  } = props;

  const classes = useStyles(props);
  const buttonLabel = isEditation
    ? t("editBar.save", { entity: props.entity })
    : t("editBar.add", { entity: props.entity });

  const onClickEdit = useCallback(
    ({ name }: { name: any }) => {
      onClick(name || "");
    },
    [onClick]
  );

  const onCancelEdit = useCallback(
    (event: any) => {
      event.stopPropagation();
      if (onCancel) {
        onCancel(event);
      } else {
        onClick("");
      }
    },
    [onCancel, onClick]
  );

  return (
    <Form
      initialValues={{ name: value }}
      onSubmit={onClickEdit}
      render={({ handleSubmit, errors }) => (
        <form
          id="eventTypesModalForm"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <div className={classes.root}>
            <>
              <ClearIcon
                aria-label={t("editBar.hideBar")}
                onClick={onCancelEdit}
                className={classes.clearIcon}
              />
              <Field name="name" validate={validate}>
                {({ input, meta }) => (
                  <Tooltip open={!!meta.error} title={meta.error || ""} arrow>
                    <InputBase
                      className={clsx(classes.input, "testEditField")}
                      value={input.value.toString()}
                      placeholder={placeholder}
                      onFocus={(e: any) => {
                        e.stopPropagation();
                      }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                      id={`edit-${new Date().valueOf()}`}
                      onChange={input.onChange}
                      type="text"
                    />
                  </Tooltip>
                )}
              </Field>
            </>
            <Button
              disabled={(errors && !!Object.keys(errors).length) || disabled}
              onClick={(e: any) => e.stopPropagation()}
              className={classes.button}
              id="testEditSubmit"
              type="submit"
            >
              {buttonLabel}
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default EditBar;
