import React, { memo, useCallback } from "react";
import IMuiTheme from "types/styles";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import isEqual from "react-fast-compare";

type TTabNav = {
  tabs: Array<{ label: string; value: string }>;
  onChange: (arg: string) => void;
  value: string;
};

const CustomTab = withStyles((theme: IMuiTheme) => ({
  root: {
    position: "relative",
    zIndex: 1111,
    textTransform: "uppercase",
    minWidth: 72,
    lineHeight: 1.3,
    minHeight: "inherit",
    fontWeight: theme.custom?.typography.fontWeight.bold,
    color: "black",
    opacity: 1,
    padding: theme.spacing(0.8, 1.2, 0.8, 1.2),
  },
  selected: {
    color: "white !important",
  },
}))((props: any) => <Tab disableRipple {...props} />);

const CustomTabs = withStyles((theme: IMuiTheme) => ({
  root: {
    width: "auto",
    borderBottom: "none",
    padding: "0.25rem",
    height: "auto",
    minHeight: "inherit",
    backgroundColor: theme.custom.palette.secondary,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down(600)]: {
      marginTop: "10px",
    },
  },
  indicator: {
    top: "50%",
    transform: "translateY(-50%)",
    height: `calc(100%)`,
    background: theme.custom?.palette.gradient.primary.default,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.custom?.boxShadow["3"],
  },
}))(Tabs);

const ButtonTabs = memo(({ tabs, value, onChange }: TTabNav) => {
  const change = useCallback(
    (e: any, val: any) => {
      onChange(val);
    },
    [onChange]
  );
  return (
    <CustomTabs value={value} onChange={change}>
      {tabs.map(({ label, value }: { label: string; value: string }) => (
        <CustomTab id={label} key={label} label={label} value={value} />
      ))}
    </CustomTabs>
  );
}, isEqual);

export default ButtonTabs;
