import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  event: number;
  name: string;
  data: string;
};

export default async function addAttachment(attachment: TParams) {
  const url = endpoints.events.attachment();
  try {
    const response = await axiosNeuron.post(url, {
      event: attachment.event,
      attachment: {
        name: attachment.name,
        data: attachment.data,
      },
    });
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
