import { IAccessTokensState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IAccessTokensState = {
  users: {
    data: [],
    isFetching: false,
    error: null,
  },
  projects: {
    data: [],
    isFetching: false,
    error: null,
  },
  error: null,
};

const reducers: TReducers = {
  [EActions.fetchProjectsRequest]: (_, { projects }) => ({
    projects: {
      ...projects,
      isFetching: true,
    },
  }),
  [EActions.fetchProjectsFail]: (error, { projects }) => ({
    projects: {
      ...projects,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchProjectsSuccess]: ({ payload: { projects } }) => ({
    projects: {
      data: projects,
      isFetching: false,
    },
  }),
  [EActions.fetchUsersRequest]: (_, { users }) => ({
    users: {
      ...users,
      isFetching: true,
    },
  }),
  [EActions.fetchUsersFail]: (error, { users }) => ({
    users: {
      ...users,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchUsersSuccess]: ({ payload: { users } }) => ({
    users: {
      data: users,
      isFetching: false,
    },
  }),
};

export default createReducer<IAccessTokensState>(path, initialState, reducers);
