import {
  encodeDelimitedArray,
  decodeDelimitedArray,
  encodeString,
  decodeString,
  encodeDelimitedNumericArray,
  decodeDelimitedNumericArray,
} from "use-query-params";

export const CommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ","),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ","),
};

export const StringParam = {
  encode: (input: string) => encodeString(input),
  decode: (input: string) => decodeString(input),
};

export const CommaArrayNumericParam = {
  encode: (array: number[] | null | undefined) =>
    encodeDelimitedNumericArray(array, ","),

  decode: (arrayNum: string | (string | null)[] | null | undefined) => {
    return decodeDelimitedNumericArray(arrayNum, ",");
  },
};
