import { EActions } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState = {
  error: [],
  isFetching: false,
  currencies: null,
};

const reducer = {
  [EActions.getCurrenciesRequest]: () => ({
    isFetching: true,
  }),
  [EActions.getCurrenciesSuccess]: ({ payload }: any) => ({
    currencies: payload.currencies,
    isFetching: false,
  }),
  [EActions.getCurrenciesFail]: ({ payload: { error } }: any) => ({
    error,
    isFetching: false,
  }),
};

export default createReducer(path, initialState, reducer);
