import React from "react";
import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";
import RowSpacer from "components/table/cellsShared/rowSpacer";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "inline-block",
      fontWeight: theme.custom.typography.fontWeight.medium,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 260,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    labelTop: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
      width: "inherit",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };
});

const LabelEmal = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { label },
    },
  } = props;
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <span
        className={clsx(classes.labelTop, "testEmailCell", "cellUserEmail")}
      >
        {label}
      </span>
      <RowSpacer />
    </span>
  );
};

export default LabelEmal;
