import { TIsoGroup } from "types/isoGroup";

export enum EActions {
  setLoading = "setLoading",
  setData = "setData",
}

export type TIsoGroupState = {
  loading: boolean;
  data?: TIsoGroup[];
};

export interface TLangsActions {
  type: typeof EActions;
  payload: TIsoGroupState;
}
