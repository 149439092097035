import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ICompanyImage } from "types/company";

export default async function getCompanyImage(id: number, full?: boolean) {
  const url = full
    ? endpoints.companyImages.getFull(id)
    : endpoints.companyImages.getThumbnail(id);
  try {
    const { data } = await axiosNeuron.get(url);
    const result: ICompanyImage = {
      id: data.id,
      version: data.version,
      type: data.type,
      image_data: data.image_data,
    };
    return result;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
