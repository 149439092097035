import { useEffect, useRef, useMemo, useCallback } from "react";
import { SortingRule } from "react-table";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { AppState } from "store";
import actions, {
  fetchPlacements,
} from "store/machineDetail/placements/actions";
import { createSelector } from "reselect";

const { setShowMore, setReloading, setLoading, setSort, setPage } = actions;

const selector = createSelector(
  (state: AppState) => state.machineDetail.placements,
  (state: any) => {
    return {
      placements: state.placements,
      loading: state.loading,
      reloading: state.reloading,
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      sort: state.sort,
      modalOpened: state.modalOpened,
    };
  }
);

const useDataPlacementsDetail = () => {
  const dispatch: any = useDispatch();
  const showMore = useRef(0);
  const {
    placements,
    loading,
    reloading,
    pageIndex,
    pageSize,
    sort,
    modalOpened,
  } = useSelector(selector, shallowEqual);

  const initialState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        placements && placements.results
          ? Math.ceil(placements.count / pageSize)
          : 0,
      sortBy: sort,
    };
  }, [pageIndex, pageSize, placements, sort]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      dispatch(setPage(pageIndex));
    },
    [dispatch]
  );

  const onPageSort = useCallback(
    (sortBy: SortingRule<number>[]) => {
      dispatch(setSort(sortBy));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setShowMore(showMore.current));
    if (!showMore.current) {
      dispatch(setReloading(true));
    } else {
      dispatch(setLoading(true));
    }

    dispatch(
      fetchPlacements({
        page: !showMore.current ? pageIndex + 1 : 1,
        pageSize: showMore.current ? pageSize * (1 + pageIndex) : pageSize,
        orderBy: sort,
      })
    );
  }, [dispatch, pageIndex, pageSize, sort]);

  return {
    placements,
    loading,
    reloading,
    modalOpened,
    initialState,
    showMoreClickedTimesRef: showMore,
    onPageChange,
    onPageSort,
  };
};

export default useDataPlacementsDetail;
