import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { AppState } from "store";
import Divider from "components/typography/heading/divider";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createColumns } from "components/machine/detail/general/users/columns";
import { createTinyColumns } from "components/machine/detail/general/users/tinyColumns";
import { IUserList } from "types/user";

const UsersToNotify = memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { users } = useSelector(
    (state: AppState) => state.machineDetail.machine
  );
  const upSm = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const tinyColumnsMemoized = useMemo(() => createTinyColumns(t), [t]);
  const columnsMemoized = useMemo(() => createColumns(t), [t]);
  const columns = upSm ? columnsMemoized : tinyColumnsMemoized;

  return (
    <>
      <Divider line noMarginBottom={!!users.length}>
        {t("machine.usersToNotify.heading")}
      </Divider>
      <Table<IUserList>
        isLoading={false}
        data={users}
        rowsCount={users ? users.length : 0}
        columns={columns}
        RowComponent={Row}
        HeaderComponent={Header}
      />
    </>
  );
});

export default UsersToNotify;
