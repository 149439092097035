import { TReducers, CreateUserState, EActions } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: CreateUserState = {
  userData: null,
  modalOpened: false,
};

const reducers: TReducers = {
  [EActions.prepare]: ({ payload: { prepareObject } }) => ({
    userData: prepareObject.userData,
  }),
  [EActions.reset]: () => ({
    ...initialState,
  }),
  [EActions.setModal]: ({ payload: { modalOpened } }) => ({
    modalOpened,
  }),
};

export default createReducer<CreateUserState>(path, initialState, reducers);
