import { SortingRule } from "react-table";

import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import getDevicesVersion from "api/handlers/device/getDevicesVersion";
import getDevicesType from "api/handlers/device/getDevicesType";
import { ResponseType } from "types/response";
import {
  IDeviceList,
  IDeviceVersionList,
  IDeviceTypeList,
  IExtendedDevice,
} from "types/device";
import { resolveSorting } from "shared/helpers";
import { getDeviceNameWithPrefix } from "shared/helpers";

export interface IFetchDevicesParams {
  page?: number;
  pageSize?: number;
  orderBy?: SortingRule<number>[];
  filterObject?: { [key: string]: number[] | string[] };
  q?: string;
  ids?: number[];
}

export default async function getDevices({
  page,
  pageSize,
  orderBy,
  filterObject,
  ids,
}: IFetchDevicesParams) {
  if (ids && !ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IExtendedDevice>;
  }

  if (filterObject?.connected?.length! > 1) {
    filterObject = { ...filterObject, connected: [] };
  }

  const params = {
    ps: pageSize,
    page,
    ids,
    order_by: resolveSorting("", orderBy),
    ...filterObject,
  };
  const url = endpoints.devices.default;
  const idsFilter = ids ? `&id=${ids.join("&id=")}` : "";

  try {
    let devicesVersion: ResponseType<IDeviceVersionList> | undefined;
    let devicesType: ResponseType<IDeviceTypeList> | undefined;
    const response = await axiosNeuron.get(`${url}?${idsFilter}`, { params });
    const data: ResponseType<IDeviceList> = {
      ...response.data,
      results: response.data.results.map((device: any) => ({
        id: device.id,
        name: getDeviceNameWithPrefix(device.name),
        note: device.note,
        internetConnection: device.internet_connection,
        version: device.version,
        telephoneNumber: device.telephone_number,
        carrier: device.carrier,
        hasSdcard: device.has_sdcard,
        macAddress: device.mac_address,
        hasHdd: device.has_hdd,
        serialUid: device.serial_uid,
        status: device.status,
        project: device.project,
        channels: device.channels,
        channelCount: device.channel_count,
        assignedChannelCount: device.assigned_channel_count,
        assignedPlacements: device.assigned_placements,
        facility: device.facility,
        company: device.company,
        machines: device.machines,
      })),
    };
    devicesVersion = await getDevicesVersion({
      ids: data.results
        .filter((device: IDeviceList) => device.version !== null)
        .map((device: IDeviceList) => {
          return device.version;
        }),
    });
    devicesType = await getDevicesType({
      ids: !!devicesVersion
        ? devicesVersion.results
            .map((item: IDeviceVersionList) => item.deviceType)
            .reduce((acc: number[], cur: number | null) => {
              if (cur !== null && acc.indexOf(cur) < 0) {
                acc.push(cur);
              }
              return acc;
            }, [])
        : [],
    });
    return {
      ...data,
      results: data.results.map((device: any) => {
        return {
          ...device,
          type:
            device.version === null
              ? null
              : devicesType?.results.find(
                  (type: IDeviceTypeList) =>
                    type.id ===
                    devicesVersion?.results.find(
                      (version: IDeviceVersionList) =>
                        version.id === device.version
                    )?.deviceType
                )?.name,
        };
      }),
    } as ResponseType<IExtendedDevice>;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
