import { ICreateUserRequest } from "types/user";

export enum EActions {
  prepare = "prepare",
  reset = "reset",
  setModal = "setModal",
}

export interface CreateUserState {
  userData?: ICreateUserRequest | null;
  modalOpened?: boolean;
}

export interface CreateUserActionTypes {
  type: EActions;
  payload: {
    prepareObject: {
      userData: ICreateUserRequest | null;
    };
    modalOpened: boolean;
  };
}

export type TReducers = {
  [key: string]: (
    p: CreateUserActionTypes,
    s: CreateUserState
  ) => CreateUserState;
};
