import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export type Params = { id: number };

export default async function removeTemporaryToken({ id }: Params) {
  const url = endpoints.temporaryToken.detail(id);
  try {
    const { data } = await axiosNeuron.delete(url);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
