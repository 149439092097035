import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { EActions, IUsersAction, IFetchMachineParams } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import getMachines, {
  IResponseTypeMachine,
} from "api/handlers/machine/getMachines";
import getCompanies from "api/handlers/company/getCompanies";
import getProjects from "api/handlers/project/getProjects";
import getFacilities from "api/handlers/facility/getFacilities";
import { IMachineList } from "types/machine";
import { companyField, facilityField, projectField } from "shared/fieldParams";
import getMachineUtilization from "api/handlers/machine/getMachineUtilization";
import getMachineUtilizationTotals from "api/handlers/machine/getMachineUtilizationTotals";
import getMachineStatus from "api/handlers/machine/getMachineStatus";
import getMachineDeviceHealth from "api/handlers/machine/getMachineDeviceHealth";
import getDevices from "api/handlers/device/getDevices";

export const path = "machines";

const actionsData = [
  [EActions.refetchMachinesRequest],
  [EActions.refetchMachinesFail, "error"],
  [EActions.refetchMachinesSuccess, "data"],
  [EActions.fetchMachinesRequest],
  [EActions.fetchMachinesFail, "error"],
  [EActions.fetchMachinesSuccess, "data"],
  [EActions.fetchCardsRequest],
  [EActions.fetchCardsFail, "error"],
  [EActions.fetchCardsSuccess, "data"],
  [EActions.fetchLast30DaysRequest],
  [EActions.fetchLast30DaysFail, "error"],
  [EActions.fetchLast30DaysSuccess, "utilization", "utilizationTotals"],
  [EActions.fetchDeviceHealthRequest],
  [EActions.fetchDeviceHealthFail, "error"],
  [EActions.fetchDeviceHealthSuccess, "data"],
  [EActions.fetchDevicesRequest],
  [EActions.fetchDevicesFail, "error"],
  [EActions.fetchDevicesSuccess, "data"],
  [EActions.setResetList],
];

const actions = prepareActions<IUsersAction, EActions>(actionsData, path);
export default actions;

const fetchCardsData =
  (machines: IResponseTypeMachine<IMachineList>) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCardsRequest]());
    try {
      const allCompanyIds: number[] = machines
        ? Array.from(
            new Set(machines.results.map((item: any) => item.companyId))
          )
        : [];
      const allProjectsIds: number[] = machines
        ? Array.from(
            new Set(machines.results.map((item: any) => item.projectId))
          )
        : [];
      const allFacilitiesIds: number[] = machines
        ? Array.from(
            new Set(machines.results.map((item: any) => item.facilityId))
          )
        : [];
      const allMachineIds: number[] = machines
        ? Array.from(new Set(machines.results.map((item: any) => item.id)))
        : [];
      const [companies, projects, facilities, machineStatuses] =
        await Promise.all([
          getCompanies({ ids: allCompanyIds, field: companyField }),
          getProjects({ ids: allProjectsIds, field: projectField }),
          getFacilities({ ids: allFacilitiesIds, field: facilityField }),
          getMachineStatus({ ids: allMachineIds }),
        ]);
      dispatch(
        actions[EActions.fetchCardsSuccess]({
          companies,
          projects,
          facilities,
          machineStatuses,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchCardsFail](error));
    }
  };

export const fetchLast30DaysData =
  (obj: IFetchMachineParams) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    const { ...rest } = obj;
    dispatch(actions[EActions.fetchLast30DaysRequest]());
    try {
      const filterParams = {
        ...rest,
      };
      const machines = await getMachines(filterParams);
      const allMachineIds: number[] = machines
        ? Array.from(new Set(machines.results.map((item: any) => item.id)))
        : [];
      const utilization = await getMachineUtilization({ ids: allMachineIds });
      const utilizationTotals = await getMachineUtilizationTotals(filterParams);
      dispatch(
        actions[EActions.fetchLast30DaysSuccess](utilization, utilizationTotals)
      );
    } catch (error) {
      dispatch(actions[EActions.fetchLast30DaysFail](error));
    }
  };

const fetchDeviceHealth =
  (machines: IResponseTypeMachine<IMachineList>) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDeviceHealthRequest]());
    try {
      const allMachineIds: number[] = machines
        ? Array.from(new Set(machines.results.map((item: any) => item.id)))
        : [];
      const deviceHealth = await getMachineDeviceHealth({ ids: allMachineIds });
      dispatch(
        actions[EActions.fetchDeviceHealthSuccess]({
          deviceHealth,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchDeviceHealthFail](error));
    }
  };

const fetchDevices =
  (machines: IResponseTypeMachine<IMachineList>) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchDevicesRequest]());
    try {
      const allDeviceIds: number[] = machines
        ? Array.from(
            new Set(machines.results.map((item: any) => item.devices))
          ).reduce((acc, cur) => [...acc, ...cur], [])
        : [];
      const response = await getDevices(
        1,
        9999,
        undefined,
        undefined,
        allDeviceIds
      );
      const devices = response
        ? response.results.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: cur.name }),
            {}
          )
        : {};
      dispatch(
        actions[EActions.fetchDevicesSuccess]({
          devices: devices,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchDevicesFail](error));
    }
  };

export const fetchMachines =
  (obj: IFetchMachineParams) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    const { status, q, field, ...rest } = obj;

    dispatch(actions[EActions.fetchMachinesRequest]());
    try {
      const filterParams = {
        ...rest,
        [status]: true,
        q,
        field,
      };
      const machines = await getMachines(filterParams);
      if (machines) {
        dispatch(fetchCardsData(machines));
        dispatch(fetchDeviceHealth(machines));
        dispatch(fetchDevices(machines));
      }
      dispatch(
        actions[EActions.fetchMachinesSuccess]({
          machines,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchMachinesFail](error));
    }
  };

export const refetchMachinesSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    dispatch(actions[EActions.refetchMachinesRequest]());
    const {
      filters: { status, company, project, facility, q },
      currentPage,
    } = getState().machineFilter;

    try {
      const getMachinesParams = {
        [status]: true,
        pageSize: 20,
        company: company || undefined,
        project: project || undefined,
        facility: facility || undefined,
        page: currentPage + 1,
        q,
      };
      const machines = await getMachines({ ...getMachinesParams });
      dispatch(
        actions[EActions.refetchMachinesSuccess]({
          machines,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.refetchMachinesFail](error));
    }
  };
