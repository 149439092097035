import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface Params {
  ids: number[];
}

export default async function getMachineUtilization(params: Params) {
  const url = endpoints.machines.utilization;
  if (params.ids && !params.ids.length) {
    return [];
  }
  const ids = params.ids ? `&id=${params.ids.join("&id=")}` : "";
  try {
    const { data } = await axiosNeuron.get(`${url}?${ids}`);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
