import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IPatchPlacement } from "types/placement";
import { IPatchChannel } from "types/channels";
import { IPatchFault } from "types/fault";

export type TDeviceCongif = {
  devices?: {
    id: number;
    recording_plan?: string;
  }[];
  channels?: IPatchChannel[];
  placements?: IPatchPlacement[];
  faults?: IPatchFault[];
};

export default async function patchDeviceConfig(params: TDeviceCongif) {
  const url = endpoints.devices.configuration;

  try {
    const { data } = await axiosNeuron.patch(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
