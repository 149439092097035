import React from "react";
import { makeStyles } from "@mui/styles";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import List from "@mui/material/List";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import appUrls from "shared/appUrls";

interface Props extends ListItemProps {
  fullName: string;
  role: string;
  id: any;
  disabled?: boolean;
  opened?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  const paddingDefault = `calc(${theme.spacing(
    1.125
  )} + 1px) calc(${theme.spacing(1.25)} + 1px) `;
  const paddingDefaultSmMd = `calc(${theme.spacing(0.25)} + 1px)`;
  return {
    root: {
      padding: 0,
    },
    item: {
      color: theme.custom.palette.label,
      letterSpacing: "0.021875rem",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: ({ opened }: Props) =>
        opened ? theme.custom.palette.placeholder : "transparent",
      borderRadius: ({ opened }: Props) => (opened ? "5rem" : "50%"),
      justifyContent: "center",
      alignItems: "center",
      display: ({ opened }: Props) => (opened ? "flex" : "inline-flex"),
      width: "auto",
      height: "auto",
      padding: ({ opened }: Props) =>
        opened ? paddingDefault : paddingDefaultSmMd,
      "& + .MuiListItemSecondaryAction-root": {
        color: theme.custom.palette.placeholder,
      },
      "& .MuiListItemText-primary": {
        fontSize: theme.custom.typography.fontSize[14],
        letterSpacing: "0.021875rem",
        color: theme.custom.palette.data,
        fontWeight: theme.custom.typography.fontWeight.bold,
        userSelect: "none",
        display: "block",
        lineHeight: 1.3,
        whiteSpace: "nowrap",
        paddingRight: (props: Props) =>
          props.disabled ? theme.spacing(1) : theme.spacing(4.5),
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& .MuiListItemText-secondary": {
        fontSize: theme.custom.typography.fontSize[12],
        letterSpacing: "0.01875rem",
        color: theme.custom.palette.label,
        fontWeight: theme.custom.typography.fontWeight.medium,
        userSelect: "none",
        display: "block",
        lineHeight: 1,
        whiteSpace: "nowrap",
        paddingRight: (props: Props) =>
          props.disabled ? theme.spacing(1) : theme.spacing(4.5),
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingBottom: theme.spacing(0.25),
        marginBottom: theme.spacing(-0.25),
      },
      "&:hover, &:focus": {
        borderColor: theme.custom.palette.placeholder,
        "& + .MuiListItemSecondaryAction-root": {
          color: theme.custom.palette.data,
        },
      },
      "&:focus": {
        background: theme.custom.palette.surface,
        color: theme.custom.palette.data,
        borderColor: theme.custom.palette.active.regular,
        outline: 0,
      },
      "&.active": {
        color: theme.custom.palette.data,
        borderColor: theme.custom.palette.data,
        boxShadow: "none",
      },
      "&[disabled], &[disabled]:hover": {
        color: theme.custom.palette.shade,
        cursor: "default",
        opacity: 1,
        boxShadow: "none",
        borderColor: theme.custom.palette.shade,
        "& .MuiListItemAvatar": {
          opacity: ".56",
        },
        "& .MuiListItemText-primary": {
          color: theme.custom.palette.label,
        },
        "& .MuiListItemText-secondary": {
          color: theme.custom.palette.placeholder,
        },
        "& + .MuiListItemSecondaryAction-root": {
          color: theme.custom.palette.shade,
        },
      },
    },
    avatar: {
      minWidth: "auto",
    },
    text: {
      margin: ({ opened }: Props) => (opened ? theme.spacing(0, 0, 0, 1.5) : 0),
      display: ({ opened }: Props) => (opened ? "block" : "none"),
    },
    icon: {
      pointerEvents: "none",
      right: theme.spacing(2.25),
      "& svg": {
        display: "block",
      },
      marginLeft: ({ opened }: Props) => (opened ? theme.spacing(1.5) : 0),
      display: ({ opened }: Props) => (opened ? "block" : "none"),
    },
  };
});

const UserBox = (props: Props) => {
  const classes = useStyles(props);

  const { fullName, role, id, disabled } = props;
  let linkAttributes = {};
  if (!disabled) {
    linkAttributes = {
      component: Link,
      to: appUrls.users.detail(id, "account"),
      id: "testLinkToOwnProfile",
    };
  }
  return (
    <List className={classes.root}>
      <ListItem className={classes.item} {...linkAttributes}>
        <ListItemAvatar className={classes.avatar}>
          <Avatar></Avatar>
        </ListItemAvatar>
        <ListItemText
          className={classes.text}
          primary={fullName}
          secondary={role}
        />
        {!disabled && (
          <ListItemSecondaryAction className={classes.icon}>
            <SettingsIcon />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </List>
  );
};

export default UserBox;
