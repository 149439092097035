import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { AppState } from "store";
import isEqual from "react-fast-compare";
import { IReportsState } from "store/projectDetail/reports/types";
import { fetchFilteredReports } from "store/projectDetail/reports/actions";

const selector = createSelector(
  (state: AppState) => state.projectDetail.reports,
  (reports: IReportsState) => {
    return {
      filteredReports: reports.filteredReports,
      filterLoading: reports.filterLoading,
    };
  }
);

interface Params {
  createdFor: string;
}

const useDataFilteredReports = ({ createdFor }: Params) => {
  const dispatch: any = useDispatch();

  const { filteredReports, filterLoading } = useSelector(selector, isEqual);

  useEffect(() => {
    dispatch(fetchFilteredReports({ createdFor }));
  }, [dispatch, createdFor]);

  return {
    filteredReports,
    filterLoading,
  };
};

export default useDataFilteredReports;
