import { Column } from "react-table";
import { TFunction } from "i18next";
import ChannelName from "./components/channelName";
import MachineTableSelect from "./components/machineTableSelect";
import PlacementTableSelect from "./components/placementTableSelect";
import DisconnectButton from "./components/disconnectButton";
import MachineRedirect from "./components/redirect";
import ResolveSwitch from "./components/resolveSwitch";
import Gain from "./components/gain";
import RecordingPlanSelect from "./components/recordingPlanSelect";

export const createColumns = (
  t: TFunction,
  permission: boolean,
  allowSettingGain: boolean // ignored, but needed in tinyColumns.tsx
): Column<any>[] => [
  {
    Header: t("devices.detail.list.cell.channel").toString(),
    id: "channel",
    disableSortBy: true,
    accessor: ({ number }) => ({ number }),
    Cell: ChannelName,
    custom: {
      hidePriority: 0,
      margin: "0 1rem",
      width: 100,
    },
  },
  {
    Header: "",
    id: "machineRedirect",
    disableSortBy: true,
    accessor: ({ machine }) => ({ machine }),
    Cell: MachineRedirect,
    custom: {
      hidePriority: 0,
      alignRight: true,
    },
  },
  {
    id: "machine",
    Header: t("devices.detail.list.headers.machine").toString(),
    accessor: ({ id, placement }) => ({
      id,
      placement,
      disabled: !permission,
    }),
    Cell: MachineTableSelect,
    custom: {
      hidePriority: 0,
      margin: "0 1rem 0 0.3rem",
      width: 250,
    },
  },
  {
    id: "placement",
    Header: t("devices.detail.list.headers.placement").toString(),
    accessor: ({ id, placement, audioChunkTypes }) => ({
      id,
      placement,
      disabled: !permission,
      audioChunkTypes,
    }),
    Cell: PlacementTableSelect,
    custom: {
      hidePriority: 0,
      width: 250,
      contentWidth: "100%",
    },
  },
  {
    id: "recording",
    Header: t(
      "machine.detail.placementsList.table.connectedDeviceRecordingPlan"
    ).toString(),
    accessor: ({ id, placement }) => ({
      id,
      placement,
      disabled: !permission,
    }),
    Cell: RecordingPlanSelect,
    custom: {
      hidePriority: 0,
      margin: "1rem",
      width: 250,
    },
  },
  {
    id: "gain",
    Header: t("devices.detail.gain").toString(),
    accessor: ({ gain, id }) => ({
      gain,
      id,
      disabled: !permission,
    }),
    Cell: Gain,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "is_enabled",
    Header: t("machine.detail.placementsList.table.availability").toString(),
    accessor: ({ is_enabled, placement, id }) => ({
      placement,
      is_enabled,
      id,
      disabled: !permission,
    }),
    Cell: ResolveSwitch,
    custom: {
      margin: "0 0 0 1rem",
      hidePriority: 0,
      alignRight: true,
    },
  },
  {
    id: "disconnectButton",
    Header: "",
    accessor: ({ id, placement }) => ({
      id,
      placement,
      disabled: !permission,
    }),
    Cell: DisconnectButton,
    custom: {
      width: 0,
      hidePriority: 0,
      margin: "0 1rem",
      alignRight: true,
    },
  },
];
