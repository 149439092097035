import { SortingRule } from "react-table";
import { IEventWithType, IEventTypeList, IEventList } from "types/event";
import { ResponseType } from "types/response";

export enum EActions {
  fetchEventsRequest = "fetchEventsRequest",
  fetchEventsRequestSilent = "fetchEventsRequestSilent",
  fetchEventsSuccess = "fetchEventsSuccess",
  fetchEventsFail = "fetchEventsFail",
  updateEventsLocal = "updateEventsLocal",
  setResolveIds = "setResolveIds",
  setIdsToResolve = "setIdsToResolve",
  setFilter = "setFilter",
  setPage = "setPage",
  setSort = "setSort",
  setReset = "setReset",
  openMasterAlert = "openMasterAlert",
  closeMasterAlert = "closeMasterAlert",
  updateMasterAlert = "updateMasterAlert",
}

export interface IEventsState {
  events?: ResponseType<IEventWithType> | null;
  eventsFetchParams?: IFetchEventsParams;
  eventTypes?: IEventTypeList[] | null;
  resolveIds?: number[] | [];
  idsToResolve?: number[];
  isFetching?: boolean;
  error?: Error | null;
  unresolvedEvents?: number;
  filters?: {
    type?: (string | null)[];
    status?: (string | null)[];
    from?: string;
    to?: string | null;
    id?: string;
    communicationStatus?: (string | null)[];
    q?: string;
  };
  pageIndex: number;
  sortByState: SortingRule<number>[];
  reset?: boolean;
  openMasterAlerts?: Set<number>;
  timestamp: Date | null;
}

export interface IEventsAction {
  type: EActions;
  payload: {
    events?: ResponseType<IEventWithType> | null;
    event: IEventList;
    eventsFetchParams?: IFetchEventsParams;
    eventTypes?: IEventTypeList[] | null;
    resolveIds?: number[] | [];
    idsToResolve?: number[];
    isFetching?: boolean;
    error?: Error | null;
    unresolvedEvents: number;
    time: string;
    type: string;
    filters?: {
      type: (string | null)[];
      status: (string | null)[];
      from?: string;
      to?: string | null;
      id?: string;
      communicationStatus: (string | null)[];
      q?: string;
    };
    pageIndex: number;
    sortByState: SortingRule<number>[];
    id?: number;
    alertIds?: number[];
    masterAlertId?: number | null;
    timestamp?: Date;
    groupedAlerts?: IEventList[];
  };
}

export interface IFetchEventsParams {
  page?: number;
  pageSize: number;
  sortByState?: SortingRule<number>[];
  machine?: number;
  ids?: number[];
  filters?: {
    type: (string | null)[];
    notType: (string | null)[];
    status: (string | null)[];
    from?: string | null;
    to?: string | null;
    id?: string;
    communicationStatus: (string | null)[];
    q?: string;
  };
}

export type TReducers = {
  [key: string]: (p: IEventsAction, s: IEventsState) => void;
};
