import i18n, { LangEnum } from "localization";

export const changeLanguage = (lang: string) => {
  if (localStorage) {
    if (lang !== "cimode") {
      localStorage.setItem("lang", lang);
      i18n.changeLanguage(LangEnum[lang]);
    } else {
      i18n.changeLanguage(lang);
    }
  }
};

export const checkLanguage = () => {
  if (localStorage) {
    const local = localStorage.getItem("lang") || "en_US";
    changeLanguage(local);
  }
};
