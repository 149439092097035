import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface Payload {
  name: string;
  email?: string;
  industry?: number | null;
  users?: number[] | [];
}

interface TUpdateCompanyParams extends Payload {
  id: number;
}

export default async function updateCompany(company: TUpdateCompanyParams) {
  const payload: Payload = {
    name: company.name,
    email: company.email,
    industry: company.industry,
    users: company.users,
  };
  const url = endpoints.companies.detail(company.id);

  try {
    const response = await axiosNeuron.patch(url, payload);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
