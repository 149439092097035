import React from "react";
import { makeStyles } from "@mui/styles";
import HorizontalLine from "components/horizontalLine";
import Box from "@mui/material/Box";
import Button from "components/button";
import Paragraph from "components/typography/paragraph";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useSelector, shallowEqual } from "react-redux";
import { AppState } from "store";

interface Props {
  discard: () => void;
  open: boolean;
  onSubmit?: () => void;
  disabled?: boolean;
  hideEditedInfo?: boolean;
  cta?: string;
}
interface CombinedProps extends Props {
  opened: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      position: "fixed",
      bottom: 0,
      background: theme.custom.palette.surface,
      zIndex: 1,
      left: 0,
      right: 0,
      marginTop: "auto",
      overflow: "hidden",
      padding: theme.spacing(0, 3),
      transform: "translateY(100%)",
      [theme.breakpoints.up("sm")]: {
        left: ({ opened }: CombinedProps) =>
          opened ? theme.spacing(31) : theme.spacing(12),
        right: theme.spacing(3),
      },
      "&:before": {
        content: '""',
        position: "relative",
        display: "block",
        background: "red",
      },
    },
    inner: {
      animationDuration: ".5s",
    },
    open: {
      transform: "translateY(0)",
    },
  };
});

const FooterConfirmationBar = (props: Props) => {
  const { t } = useTranslation();
  const { discard, open, disabled, onSubmit, cta } = props;
  const menuOpened = useSelector(
    (state: AppState) => state.layout.menu,
    shallowEqual
  );
  const classes = useStyles({ opened: menuOpened, ...props });
  const footerConfirmationBar = React.useRef<HTMLDivElement>(null);
  const [barHeight, setBarHeight] = React.useState(0);

  React.useEffect(() => {
    setBarHeight(footerConfirmationBar.current!.offsetHeight);
  }, []);
  return (
    <>
      <div style={{ height: barHeight }}></div>
      <div
        ref={footerConfirmationBar}
        className={clsx(classes.root, open && classes.open)}
      >
        <div className={classes.inner}>
          <HorizontalLine />
          <Box my={4}>
            <Box display="flex" mb={1}>
              <Box mr={1}>
                <Button
                  color="primary"
                  type="submit"
                  onClick={onSubmit}
                  disabled={disabled}
                  id="testSaveChanges"
                >
                  {cta ? cta : t("confirmationBar.saveChanges")}
                </Button>
              </Box>
              <Box>
                <Button onClick={discard}>
                  {t("confirmationBar.discard")}
                </Button>
              </Box>
            </Box>
            {!props.hideEditedInfo && (
              <Paragraph italic>{t("confirmationBar.editedInfo")}</Paragraph>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default FooterConfirmationBar;
