import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

interface CustomHeadingProps {
  icon?: React.ReactElement;
  editable?: boolean;
  position?: string;
  top?: number | string;
  left?: number | string;
  id?: number | string;
  children?: string | any;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2.125, 0),
      [theme.breakpoints.up("sm")]: {
        margin: ({ editable }: CustomHeadingProps) =>
          !editable ? theme.spacing(0, 0, 1.5) : 0,
      },
      [theme.breakpoints.down("sm")]: {
        margin: ({ editable }: CustomHeadingProps) =>
          !editable ? theme.spacing(0, 0, 1.5) : 0,
        position: ({ position }: CustomHeadingProps) => position || "initial",
        left: ({ left }: CustomHeadingProps) => left || "initial",
        top: ({ top }: CustomHeadingProps) => top,
      },
    },
    heading: {
      fontWeight: theme.custom.typography.fontWeight.bold,
      color: theme.custom.palette.data,
      letterSpacing: "0.05rem",
      lineHeight: "1.25",
      margin: 0,
      padding: 0,
      fontSize: theme.custom.typography.fontSize["18"],
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.custom.typography.fontSize["32"],
      },
    },
    iconArea: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      "& > svg": {
        display: "block",
      },
    },
  };
});

const Heading1: React.FC<CustomHeadingProps> = (props) => {
  const classes = useStyles(props);
  const { icon, editable, id, children, ...rest } = props;
  let iconArea = null;
  if (icon) {
    iconArea = (
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className={classes.iconArea}>{icon}</div>
      </Box>
    );
  }
  const stringId = id !== undefined ? String(id) : undefined;

  return (
    <div className={classes.root}>
      {iconArea}
      <h1 className={classes.heading} id={stringId} {...rest}>
        {children}
      </h1>
    </div>
  );
};

export default Heading1;
