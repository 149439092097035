import { TFunction } from "i18next";
import values from "lodash/values";
import isNull from "lodash/isNull";
import { v4 as uuidv4 } from "uuid";
import type { TLabel } from "types/labels";
import { LabelGroupModalState, ELabelDateType } from "store/eventModal/types";

export const hardCodedEvents = (
  t: TFunction
): {
  value?: string;
  text?: string;
  requireAdmin?: boolean;
  group?: string;
  tooltip?: string;
}[] => [
  { group: t("eventTypeGroups.machine_operation") },
  {
    value: "maintenance",
    text: t("eventTypeNames.maintenance"),
    tooltip: t("eventTypeDescriptions.maintenance"),
  },
  {
    value: "broken",
    text: t("eventTypeNames.broken"),
    tooltip: t("eventTypeDescriptions.broken"),
  },
  {
    value: "out_of_service",
    text: t("eventTypeNames.out_of_service"),
    tooltip: t("eventTypeDescriptions.out_of_service"),
  },
  {
    value: "production_note",
    text: t("eventTypeNames.production_note"),
    tooltip: t("eventTypeDescriptions.production_note"),
  },
  { group: t("eventTypeGroups.model_training") },
  {
    value: "nominal_sound",
    text: t("eventTypeNames.nominal_sound"),
    tooltip: t("eventTypeDescriptions.nominal_sound"),
  },
  {
    value: "additional_training_data",
    text: t("eventTypeNames.additional_training_data"),
    tooltip: t("eventTypeDescriptions.additional_training_data"),
  },
  {
    value: "significant_nominal_state_change",
    text: t("eventTypeNames.significant_nominal_state_change"),
    tooltip: t("eventTypeDescriptions.significant_nominal_state_change"),
  },
  {
    value: "data_label",
    text: t("eventTypeNames.data_label"),
    tooltip: t("eventTypeDescriptions.data_label"),
    requireAdmin: true,
  },
];

export const singleEvents = (t: TFunction): { [key: string]: string }[] => [
  { value: "anomaly", text: t("eventTypeNames.anomaly") },
  { value: "anomaly_warning", text: t("eventTypeNames.warning") },
  { value: "anomaly_ok", text: t("eventTypeNames.ok") },
  { value: "error", text: t("eventTypeNames.error") },
  { value: "machine_on", text: t("eventTypeNames.machine_on") },
  { value: "machine_off", text: t("eventTypeNames.machine_off") },
  {
    value: "placement_connected",
    text: t("eventTypeNames.placement_connected"),
  },
  {
    value: "placement_disconnected",
    text: t("eventTypeNames.placement_disconnected"),
  },
  {
    value: "nominal_sound_start",
    text: t("eventTypeNames.nominal_sound_start"),
  },
  {
    value: "nominal_sound_end",
    text: t("eventTypeNames.nominal_sound_end"),
  },
  {
    value: "additional_training_data_start",
    text: t("eventTypeNames.additional_training_data_start"),
  },
  {
    value: "additional_training_data_end",
    text: t("eventTypeNames.additional_training_data_end"),
  },
  {
    value: "device_recording_plan_changed",
    text: t("eventTypeNames.device_recording_plan_changed"),
  },
  {
    value: "channel_gain_changed",
    text: t("eventTypeNames.channel_gain_changed"),
  },
  {
    value: "channel_gain_changed",
    text: t("eventTypeNames.channel_gain_changed"),
  },
  {
    value: "production_mode_updated",
    text: t("eventTypeNames.production_mode_updated"),
  },
];

export const findPairEvent = (event: string) => {
  if (event === "significant_nominal_state_change") {
    return event;
  }
  if (event.indexOf("nsw_maintenance_") !== -1) {
    return "nsw_maintenance";
  }
  if (event.indexOf("maintenance_") !== -1) {
    return "maintenance";
  }
  if (event.indexOf("broken_") !== -1) {
    return "broken";
  }
  if (event.indexOf("out_of_service_") !== -1) {
    return "out_of_service";
  }
  if (event.indexOf("nominal_") !== -1) {
    return "nominal_sound";
  }
  if (event.indexOf("additional_training_data_") !== -1) {
    return "additional_training_data";
  }
  if (event.indexOf("production_note_") !== -1) {
    return "production_note";
  }
  if (event.indexOf("data_label_") !== -1) {
    return "data_label";
  }
  return event;
};

export const resolveFilter = (event: string) => {
  return [`${event}_start`, `${event}_end`];
};

export const prepareLabelGroup = (
  item: any,
  eventId: number | null
): LabelGroupModalState => ({
  ...item,
  groupId: uuidv4(),
  labels: [
    {
      id: item.id,
      subcategory: item.subcategory,
      description: item.description,
    },
  ],
  start: new Date(item.start_datetime).toISOString(),
  end: new Date(item.end_datetime).toISOString(),
  toRemoveLabels: [],
  eventId,
});

export const mapLabelGroups = (
  labels: TLabel[],
  eventId: number | null
): LabelGroupModalState[] => {
  const lg: { [key: string]: LabelGroupModalState } = {};
  for (let i of labels) {
    if (lg[i.id]) {
      const item = lg[i.id];
      lg[i.id] = {
        ...item,
        labels: [
          ...item.labels,
          {
            id: i.id,
            subcategory: i.subcategory,
            description: i.description,
          },
        ],
      };
    } else {
      lg[i.id] = prepareLabelGroup(i, eventId);
    }
  }
  return values(lg);
};

export const validateLabelPickers = (
  value: Date | null,
  group: LabelGroupModalState,
  type: ELabelDateType
): boolean => {
  const comparatorType =
    type === ELabelDateType.start ? ELabelDateType.end : ELabelDateType.start;
  const controlValue = group[comparatorType];
  if (!isNull(value) && !isNull(controlValue)) {
    if (
      type === ELabelDateType.start &&
      new Date(controlValue).getTime() < new Date(value).getTime()
    ) {
      return false;
    } else if (
      type === ELabelDateType.end &&
      new Date(controlValue).getTime() > new Date(value).getTime()
    ) {
      return false;
    }
  }
  return true;
};
