import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type IDataset = {
  placement: number;
  start: string;
  end: string;
  representation_start: string;
  representation_end: string;
};

type TParams = {
  name: string;
  datasetData?: IDataset[];
  id: number;
};

export default async function patchDataset(paramsInput: TParams) {
  const url = endpoints.eventSounds.datasetDetail(paramsInput.id);

  const newDatasets = paramsInput?.datasetData?.map((dataset: IDataset) => ({
    ...dataset,
    start: new Date(dataset.start).toISOString(),
    end: new Date(dataset.end).toISOString(),
    representation_start: new Date(dataset.representation_start).toISOString(),
    representation_end: new Date(dataset.representation_end).toISOString(),
  }));
  const params = {
    name: paramsInput.name,
    results: newDatasets,
  };
  try {
    const { data } = await axiosNeuron.patch(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
