import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { SortingRule } from "react-table";
import { ResponseType } from "types/response";
import { ITemporaryTokenList } from "types/temporaryToken";
import { resolveSorting } from "shared/helpers";

type Params = {
  ids?: number[];
  orderBy?: SortingRule<number>[];
  projectIds?: number[];
  pageSize?: number;
  page?: number;
  q?: string;
  is_read_only?: boolean;
  is_deleted?: boolean;
  is_expired?: boolean;
  from?: string;
  to?: string;
};

export default async function getTemporaryTokens(paramsInput: Params) {
  const url = endpoints.temporaryToken.default;

  if (paramsInput.ids && !paramsInput.ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<ITemporaryTokenList>;
  }

  const params = {
    id: paramsInput.ids,
    project: paramsInput.projectIds,
    ps: paramsInput.pageSize,
    page: paramsInput.page,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
    is_read_only: paramsInput.is_read_only,
    is_deleted: paramsInput.is_deleted,
    is_expired: paramsInput.is_expired,
    from: paramsInput.from,
    to: paramsInput.to,
  };

  const orderBy = resolveSorting(
    "-expires_at&order_by=-id",
    paramsInput?.orderBy
  );

  try {
    const { data } = await axiosNeuron.get(`${url}?order_by=${orderBy}`, {
      params,
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
