import { memo } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import Picker from "localization/pickerLocale";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useStyles } from "components/events/styles";
import dayjs from "dayjs";
import { Box } from "@mui/material";

interface IStartDatePicker {
  onChange: (date: Date | null) => void;
  date: Date | null;
  maxDate?: Date | any | null;
  minDate?: Date | any | null;
  error?: string | null;
  label: any;
}

export const DatePicker = memo(
  ({ onChange, maxDate, error, minDate, date, label }: IStartDatePicker) => {
    const classes = useStyles();

    return (
      <Box
        sx={{
          "& .MuiInputBase-root": {
            paddingRight: "15px",
          },
        }}
      >
        <Picker
          Component={DateTimePicker}
          className={classes.input}
          maxDate={maxDate ? dayjs(maxDate) : dayjs(new Date())}
          slotProps={{
            textField: {
              variant: "outlined",
              error: !!error,
              helperText: !!error ? error : null,
            },
          }}
          slots={{
            openPickerIcon: AccessTimeIcon,
          }}
          minDate={minDate ? dayjs(minDate) : new Date("1970-01-01 00:00:00")}
          label={label}
          value={date ? dayjs(date) : null}
          onChange={onChange}
        />
      </Box>
    );
  }
);
