import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ERoleCodename } from "types/role";
import EStatusName from "enums/userStatus";
import { changeLanguage } from "shared/language";
import { writeCookie } from "shared/cookies";
export interface IUserDetailResponseTypes {
  id: number;
  lastLogin: Date | undefined;
  isSuperuser: boolean;
  username: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phone: string | null;
  note: string | null;
  dateJoined: string;
  isActive: boolean;
  isAdmin: boolean;
  isStaff: boolean;
  isInternal: boolean;
  permissions: string[];
  companies: number[];
  projects: number[];
  invitationStatus: string;
  role: ERoleCodename | null;
  language?: string;
  status: EStatusName;
  isEmailNotified: boolean;
  isPhoneNotified: boolean;
  isAlertAssistant: boolean;
  displayCurrency: string | null;
}

export default async function getUser(id?: number) {
  const url = id ? endpoints.users.detail(id) : endpoints.users.me;
  try {
    const { data } = await axiosNeuron.get(url);
    if (!id) {
      changeLanguage(data.language);
      pendo.initialize({
        visitor: {
          id: data.id,
          lng: data.language,
        },

        account: {
          id: data.id,
        },
      });
    }

    if (data.auth_cookie) {
      writeCookie(data.auth_cookie);
    }

    return {
      id: data.id,
      lastLogin: data.last_login ? new Date(data.last_login) : undefined,
      isSuperuser: data.is_superuser,
      username: data.username,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      phone: data.phone,
      note: data.note,
      dateJoined: data.date_joined,
      isActive: data.is_active,
      isAdmin: data.is_admin,
      isStaff: data.is_staff,
      isInternal: data.is_internal,
      permissions: data.permissions,
      companies: data.companies,
      projects: data.projects,
      invitationStatus: data.invitation_status,
      role: data.role,
      language: data.language,
      status: data.status,
      isEmailNotified: data.is_email_notified,
      isPhoneNotified: data.is_phone_notified,
      isAlertAssistant: data.is_alert_assistant,
      displayCurrency: data.display_currency,
    };
  } catch (error: any) {
    if (error !== undefined) {
      throw new Error(error?.status);
    }
  }
}
