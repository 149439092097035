export const API = {
  development: "https://new-test-api.neuronsw.TLD",
  stage: "https://new-stage-api.neuronsw.TLD",
  production: "https://new-api.neuronsw.TLD",
};

export const optimizelyKey = {
  development: "TCX7cyCYqQ8f7SBJwfcB4M",
  stage: "KJSxNeRCFSRJjCpihSd7W",
  production: "NaS4rpSf99uLGfe9JVA6D9",
};

export const branches = {
  development: "development",
  stage: "stage",
  master: "master",
};

export const apiUrlRegex = {
  development: /https:\/\/env-sensor-info-api.neuronsw.com/,
  stage: /https:\/\/new-stage-api.neuronsw.com/,
  production: /https:\/\/new-api.neuronsw.com/,
};

export const environment = process.env.REACT_APP_ENVIRONMENT;

export function getApiUrlRegex() {
  if (environment && apiUrlRegex.hasOwnProperty(environment)) {
    return apiUrlRegex[environment as keyof typeof apiUrlRegex];
  }
  return apiUrlRegex.development;
}

export const getOrigApiUrl = () => {
  switch (environment) {
    case branches.stage: {
      return API.stage;
    }
    case branches.master: {
      return API.production;
    }
    default: {
      return API.development;
    }
  }
};

export const getApiUrl = () => {
  const origUrl = getOrigApiUrl();
  const match = window.location.href.match(
    "http(?:s)?://[^.]+\\.neuronsw\\.([a-z]+)(:?/.*)?"
  );
  const tld = match ? match[1] : "com";
  return origUrl.replace(".TLD", `.${tld}`);
};

export const getOptimizelyKey = () => {
  switch (environment) {
    case branches.stage: {
      return optimizelyKey.stage;
    }
    case branches.master: {
      return optimizelyKey.production;
    }
    default: {
      return optimizelyKey.development;
    }
  }
};

export const debounceTime = 400;
