import { Column } from "react-table";
import Icon from "components/table/cells/icon";
import Home from "@mui/icons-material/Home";
import Name from "components/table/cells/name";
import { IFacility } from "types/facility";
import { TFunction } from "i18next";
import { ActionsButton } from "./cells/actionsButton";
import { maxCharacters } from "shared/form/validators";

export const createColumns = (t: TFunction): Column<IFacility>[] => [
  {
    Header: "",
    id: "icon",
    disableSortBy: true,
    Cell: Icon,
    accessor: () => ({ IconComponent: Home }),
    custom: {
      hidePriority: 0,
      width: 64,
    },
  },
  {
    id: "name",
    Header: t("facilities.list.table.name").toString(),
    accessor: ({ name, id }) => {
      return {
        id,
        label: name,
        path: "projectDetail.facilities",
        validate: maxCharacters(50),
      };
    },
    Cell: Name,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "kebab",
    Header: "",
    accessor: ({ id }) => ({
      id,
    }),
    Cell: ActionsButton,
    custom: {
      hidePriority: 0,
      width: 64,
    },
  },
];
