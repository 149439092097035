import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export type Params = {
  id: number;
};

export default async function resendInvitation({ id }: Params) {
  const url = endpoints.users.resendInvitation(id);
  try {
    const response = await axiosNeuron.post(url);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
