import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { TEventPlacementFailure } from "types/failureTypes";

export default async function patchEventPlacementFailure(
  params: Partial<TEventPlacementFailure>,
  id: number
) {
  const url = endpoints.failure.editEventPlacement(id);

  try {
    const {
      data: { results },
    } = await axiosNeuron.patch(url, { ...params });
    return results;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
