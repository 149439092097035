import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { EActions, DevicesDetailAction } from "./types";

import { prepareActions } from "store/helpers";
import { AppState } from "store";

import channelsActions from "./channelsList/actions";
import getDevice from "api/handlers/device/getDevice";
import getDevicesVersion from "api/handlers/device/getDevicesVersion";
import getDevicesType from "api/handlers/device/getDevicesType";
import { fetchChannels } from "./channelsList/actions";
import listActions from "store/deviceDetail/channelsList/actions";
import getDeviceTelemetric from "api/handlers/device/getDeviceTelemetric";

export const path = "deviceDetail";

const actionsData = [
  [EActions.setDevice, "device"],
  [EActions.setLoading, "loading"],
  [EActions.setError, "error"],
  [EActions.setReset],
  [EActions.fetchTelemetricRequest],
  [EActions.fetchTelemetricSuccess, "telemetric"],
  [EActions.fetchTelemetricFail, "telemetricError"],
];

const actions = prepareActions<DevicesDetailAction, EActions>(
  actionsData,
  path
);

export default actions;

export const fetchDevice =
  (id: number, noloading = false, refetchChannels = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    try {
      !noloading && dispatch(actions.setLoading(true));
      const device = await getDevice(id);
      device?.machines &&
        dispatch(channelsActions.setInitMachines(device?.machines));
      if (device?.version) {
        const version = await getDevicesVersion({ ids: [device?.version!] });
        const type = await getDevicesType({
          ids: version?.results.map(
            (versionType: any) => versionType.deviceType!
          ),
        });
        dispatch(actions.setDevice({ ...device, type: type?.results[0].name }));
      } else {
        dispatch(actions.setDevice({ ...device, type: undefined }));
      }
      refetchChannels && dispatch(fetchChannels(true));
      device &&
        device.recordingPlan &&
        dispatch(listActions.setRecordingPlan(device.recordingPlan));
    } catch (error) {
      dispatch(actions.setError(error));
      dispatch(actions.setLoading(false));
    }
  };

export const fetchTelemetric =
  (id?: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    dispatch(actions[EActions.fetchTelemetricRequest]());
    try {
      const telemetric = await getDeviceTelemetric(id);
      dispatch(actions[EActions.fetchTelemetricSuccess](telemetric.results));
    } catch (error) {
      dispatch(actions[EActions.fetchTelemetricFail](error));
    }
  };
