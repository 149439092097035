import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IMachineCategoryResponse {
  id: number;
  company: number;
  name: string;
  description: string;
  installationManual: string;
}

export default async function getMachineCategory(id: number) {
  const url = endpoints.machines.category(id);
  try {
    const response = await axiosNeuron.get(url);
    const data: IMachineCategoryResponse = {
      id: response.data.id,
      company: response.data.company,
      name: response.data.name,
      description: response.data.description,
      installationManual: response.data.installation_manual,
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
