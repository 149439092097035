import { ColumnInstance, Row } from "react-table";
import { RowRefs } from "./types";

export const showAllHiddenColumns = <T extends object>(
  allColumns: Array<ColumnInstance<T>>
) => {
  allColumns.forEach((column: any) => {
    if (column.custom.hidePriority === 0) {
      return;
    }
    if (!column.isVisible) {
      column.toggleHidden();
    }
  });
};

export const someColumnNeedsToBeHided = <T extends object>(
  rows: Row<T>[],
  allColumns: Array<ColumnInstance<T>>,
  rowRefsContainer: RowRefs[]
) => {
  let someColumnNeedsToBeHided = false;

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    for (let columnIndex = 0; columnIndex < allColumns.length; columnIndex++) {
      const column = allColumns[columnIndex];
      if (column.custom.hidePriority === 0) {
        continue;
      }
      const rowRefs = rowRefsContainer[rowIndex];
      const td = rowRefs.tdRefs[column.id];
      const div = rowRefs.divRefs[column.id];
      if (td && div) {
        const tdWidth = td.getBoundingClientRect().width;
        const divWidth = div.getBoundingClientRect().width;
        const widthDiff = tdWidth - divWidth;
        if (widthDiff < 16 && column.isVisible) {
          someColumnNeedsToBeHided = true;
          break;
        }
      }
    }
    if (someColumnNeedsToBeHided) {
      break;
    }
  }
  return someColumnNeedsToBeHided;
};

export const getColumnToHide = <T extends object>(
  allColumns: Array<ColumnInstance<T>>
) => {
  let topHidePriority = 0;
  let columnWithTopHidePriority: ColumnInstance<T> | undefined;
  allColumns.forEach((column: any) => {
    if (column.custom.hidePriority > topHidePriority && column.isVisible) {
      topHidePriority = column.custom.hidePriority;
      columnWithTopHidePriority = column;
    }
  });
  return columnWithTopHidePriority;
};
