import endpoints from "api/endpoints";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import axiosNeuron from "api/axios";
import { UTC } from "components/dataLabeling/constants";

export async function getAudio(
  placement: number,
  start: Date | string,
  end: Date | string
) {
  const ctx = getAudioContext();

  const from = new Date(start);
  const to = new Date(end);

  const url = endpoints.audio.default;
  let data: any;

  try {
    const { data } = await axiosNeuron.get(
      `${url}?placement=${encodeURIComponent(
        placement
      )}&start_time=${from.toISOString()}&end_time=${to.toISOString()}&tz=${encodeURIComponent(
        UTC
      )}`,
      { responseType: "arraybuffer" }
    );
    const audio = await ctx.decodeAudioData(data);
    return audio;
  } catch (err) {
    if (
      data?.message &&
      data?.message.match(
        /System error: index .* is out of bounds for axis .* with size .*/
      )
    ) {
      return null;
    }
  }
}
