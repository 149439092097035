import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function removeSingleEvent({ id }: { id: number }) {
  const url = endpoints.events.detail(id);

  try {
    const response = await axiosNeuron.delete(url);
    return response || { message: "Event successfully deleted" };
  } catch (error: any) {
    if (error.status === 409) {
      return null;
    }
    if (error !== undefined) {
      throw error;
    }
  }
}
