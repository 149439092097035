import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  InputLabel,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import Tag from "components/tag";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import { BlackCheckbox } from "components/checkbox";
import Divider from "components/typography/heading/divider";
import { useStyles } from "./style";
import { TOptionsGroup } from "components/select/selectGroupSearch";

interface IProps {
  selectedValues: any[];
  onChange: (event: any) => void;
  search?: string;
  placeholder?: string;
  id: string;
  name: string;
  options: TOptionsGroup;
  label: string;
  setSearchPhrase: React.Dispatch<any>;
  loading: boolean;
}

function MultiSelectGroupSearch(props: IProps) {
  const {
    options,
    loading,
    selectedValues,
    onChange,
    label,
    placeholder,
    setSearchPhrase,
    id,
    search,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const inputLabel = useRef<HTMLLabelElement>(null);

  const handleChange = (event: any) => {
    const value = event.target.value;

    onChange(value);
  };

  const handleBlur = useCallback(() => {
    setIsOpen(false);
    setSearchPhrase("");
  }, [setSearchPhrase]);

  const renderTags = useCallback(
    (selected: any) => {
      const MAX_ITEM_COUNT = 2;

      const usedOptions: any = (selected as string[])
        .map((selectedValue) => {
          for (let i = 0; i < options?.length; i++) {
            const option = options[i];
            const value = option.items.map((item) => {
              return Number(item.id) === Number(selectedValue) && item.name;
            });

            if (value.some(Boolean)) {
              return value.find(Boolean);
            }
          }
          return null;
        })
        .filter((e) => e);

      const tagsToFit = usedOptions.reduce(
        (array: string[], option: string) => {
          if (array?.length < MAX_ITEM_COUNT) {
            array.push(option);
          }
          return array;
        },
        [] as string[]
      );

      return (
        <div className={classes.renderWrap}>
          {(tagsToFit as string[]).map((option, index) => {
            return (
              <Box
                mr={1}
                mb={1}
                key={index}
                className={clsx(
                  classes.renderWrapBox,
                  tagsToFit?.length - 1 === index &&
                    tagsToFit?.length >= MAX_ITEM_COUNT &&
                    classes.last
                )}
              >
                <Tag color={false} ellipsis>
                  {option}
                </Tag>
              </Box>
            );
          })}
          {usedOptions > tagsToFit ? (
            <Box mr={1} mb={1.5} className={classes.andMoreText}>
              {t("multiselect.andMore", {
                postProcess: "interval",
                count: usedOptions?.length - tagsToFit?.length,
              })}
            </Box>
          ) : null}
        </div>
      );
    },
    [classes, options, t]
  );

  const renderOptions = useMemo(() => {
    return (
      isOpen &&
      (!options.length ? (
        <Box textAlign="center">{t("eventSounds.select.noItemFound")}</Box>
      ) : (
        options.map((option: any) => {
          return [
            <div
              className={classes.groupHeader}
              onClick={(e) => e.stopPropagation()}
            >
              <Divider line noMargin key={option?.group}>
                {option.group}
              </Divider>
            </div>,
            option.items.map((item: any) => (
              <MenuItem
                style={{ paddingLeft: 0 }}
                key={item?.id}
                value={item?.id}
              >
                <ListItemIcon>
                  <BlackCheckbox
                    checked={selectedValues.indexOf(item.id) > -1}
                  />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </MenuItem>
            )),
          ];
        })
      ))
    );
  }, [classes.groupHeader, isOpen, options, selectedValues, t]);

  return (
    <FormControl variant="outlined" size="small" className={classes.root}>
      <InputLabel ref={inputLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        labelId={id}
        label={label}
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={renderTags}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          // getContentAnchorEl: 'top',
          onClose: handleBlur,
        }}
      >
        <Box p={2} pt={1} mb={2} className={classes.searchBox}>
          <TextField
            placeholder={placeholder}
            id={"machine-type-select-SearchInput"}
            className={classes.textField}
            fullWidth
            variant="outlined"
            onClick={(e) => e.stopPropagation()}
            value={search}
            onChange={(e) => setSearchPhrase(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            InputProps={{
              classes: {
                input: classes.searchInput,
              },
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => setSearchPhrase(search)}
                >
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {loading ? <Spinner /> : renderOptions}
      </Select>
    </FormControl>
  );
}

export default MultiSelectGroupSearch;
