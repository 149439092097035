import React from "react";
import { IStatus } from "types/status";
import EStatusName from "enums/userStatus";
import Heading4 from "components/typography/heading/heading4";
import { makeStyles } from "@mui/styles";
import useTranslatedUserStatus from "shared/hooks/useTranslatedUserStatus";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      color: (props: IStatus) => {
        switch (props.name) {
          case EStatusName.INACTIVE:
            return theme.custom.palette.warning.regular;
          case EStatusName.INVITED:
            return theme.custom.palette.warning.regular;
          case EStatusName.EXPIRED:
            return theme.custom.palette.error.regular;
          case EStatusName.DELETED:
            return theme.custom.palette.alert.regular;
          default:
            return theme.custom.palette.data;
        }
      },
    },
  };
});

const Status = (props: IStatus) => {
  const { name } = props;
  const classes = useStyles(props);
  const translatedStatus = useTranslatedUserStatus(name);
  return (
    <div>
      <Heading4>
        <span className={clsx(classes.root, "cellUserStatus")}>
          {translatedStatus}
        </span>
      </Heading4>
    </div>
  );
};

export default Status;
