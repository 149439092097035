import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import moment from "moment";

interface DateRange {
  from: Date;
  to: Date;
}

export function getModelResults(
  placements: number[],
  types: string[],
  environments: string[],
  dates: DateRange[],
  interval: number | undefined
) {
  const url = endpoints.modelResults.placements;
  return Promise.all(
    dates.map(({ from, to }) => {
      const from_ = moment(from).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      const to_ = moment(to).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      const intervalString = interval ? `&interval=${interval}` : "";
      return new Promise(async (resolve, reject) => {
        try {
          const result = await axiosNeuron.get(
            `${url}?placement=${placements.join(
              "&placement="
            )}&type=${types.join("&type=")}&environment=${environments.join(
              "&environment="
            )}&from=${from_}&to=${to_}${intervalString}`
          );
          resolve(result.data.results || null);
        } catch (e) {
          reject(e);
        }
      });
    })
  );
}
