import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IProjectList } from "types/project";

export default async function getProject(id: number) {
  const url = endpoints.projects.detail(id);
  try {
    const response = await axiosNeuron.get(url);
    const item = response.data;

    const data: IProjectList = {
      projectName: item.project_name,
      createdAt: item.created_at,
      isTemporaryTokenEnabled: item.is_temporary_access_token_auth_enabled,
      ...item,
    };

    return data;
  } catch (error) {
    if (error !== undefined) {
      return null;
    }
  }
}
