import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "components/textField";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useStyles } from "components/events/styles";
import { ELabelDateType, EModalTypes } from "store/eventModal/types";
import { Field } from "react-final-form";
import { maxCharacters } from "shared/form/validators";
import { AppState } from "store";
import { addComment, fetchComments } from "store/eventModal/commentsActions";
import Spinner from "components/spinner";
import CommentItem from "./commentItem";
import Button from "@mui/material/Button";
import Divider from "components/typography/heading/divider";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";

interface IMessagesModal {
  modal: string;
  isSingle?: boolean;
  modalType: EModalTypes;
  modalState?: string | null;
}

export const Comments = memo(
  ({ modal, isSingle, modalType, modalState }: IMessagesModal) => {
    const messageRef = React.useRef<any>(null);
    const [addingPostActive, setAddingPostActive] = React.useState<boolean>(
      modalState === "note"
    );

    const toggleAddingPostActive = () => {
      setAddingPostActive((prev: any) => !prev);
    };

    const canAddComment = usePermissions([
      EPermissions.CREATE_ANY_EVENT_COMMENT,
      EPermissions.CREATE_OWN_EVENT_COMMENT,
    ]);
    const canEditSystemComment = usePermissions([
      EPermissions.CREATE_ANY_EVENT_COMMENT,
    ]);
    const dispatch: any = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const comments = useSelector(
      (state: AppState) => state.eventModal.comments
    );
    const eventDetail = useSelector(
      (state: AppState) => state.eventModal.eventDetail
    );

    useEffect(() => {
      if (eventDetail) {
        dispatch(fetchComments(!!isSingle));
      }
    }, [dispatch, isSingle, eventDetail]);

    const {
      eventModal: { eventType: event, eventId },
      auth: {
        user: { id: loggedUserId },
      },
    } = useSelector((state: AppState) => state);

    const handleAddNote = () => {
      const note = messageRef.current.value;
      if (note.length && eventId !== null && event !== null) {
        dispatch(
          addComment({
            note: note,
            eventId,
            type: ELabelDateType.start,
            event,
            isSingle,
          })
        );
      }
      toggleAddingPostActive();
    };

    const [noteValid, setNoteValid] = React.useState<boolean>(false);

    if (modal === EModalTypes.init || modal === EModalTypes.create) {
      return null;
    }

    return (
      <>
        <Divider line>{t("eventModal.notes.title")}</Divider>
        {canAddComment && (
          <Box>
            {addingPostActive ? (
              <>
                <Box
                  mt={2}
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: 0,
                    },
                  }}
                >
                  <Field
                    className={classes.input}
                    autoFocus
                    name="startMessageField"
                    inputRef={messageRef}
                    id="testStartMessageField"
                    component={TextField}
                    defaultValue={""}
                    multiline
                    label={t("eventModal.notes.note")}
                    validate={(value: any) => {
                      const res = maxCharacters(1000)(value);
                      setNoteValid(!res && !!value);
                      return res;
                    }}
                  />
                </Box>
                <Box mt={2} display="flex">
                  <Button
                    variant="contained"
                    onClick={handleAddNote}
                    disabled={!noteValid}
                  >
                    {t("eventModal.notes.save")}
                  </Button>
                  <Box ml={2}>
                    <Button onClick={toggleAddingPostActive}>
                      {t("eventModal.notes.cancel")}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <Button
                style={{ backgroundColor: "#e0e0e0", color: "#000" }}
                variant="contained"
                onClick={toggleAddingPostActive}
              >
                {t("eventModal.notes.addNote")}
              </Button>
            )}
          </Box>
        )}
        {modalType !== EModalTypes.init && modalType !== EModalTypes.create && (
          <Box>
            {!comments ? (
              <Spinner />
            ) : (
              comments.map((comment: any) => (
                <CommentItem
                  key={comment.id}
                  id={comment.id}
                  loggedUserIsAuthor={
                    comment.created_by_user === loggedUserId ||
                    (comment.created_by_user === null && canEditSystemComment)
                  }
                  createdBy={comment.created_by_user_mapped}
                  modifiedBy={comment.created_by_user_mapped}
                  note={comment.note}
                  created_at={comment.created_at}
                />
              ))
            )}
          </Box>
        )}
      </>
    );
  }
);
