import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IFailure } from "store/eventModal/selector";
import { actions } from "store/eventModal/slice";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Divider from "components/typography/heading/divider";
import IconButton from "components/iconButton";
import AddIcon from "@mui/icons-material/Add";
import Failure from "./failure";

type TFailuresProps = {
  item: IFailure;
};

const Failures = ({ item }: TFailuresProps) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const onAddFailure = useCallback(
    (placement: any) => {
      dispatch(
        actions.addFailure({
          placement,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <Box pt={2} pb={1}>
        <Divider line noMargin>
          {t("machineDetail.editEvent.failuresLabel")}
        </Divider>
      </Box>
      {item.id?.map((itemFailure, index) => (
        <Failure
          key={itemFailure + "failure" + item.placement + index}
          failures={item.failure}
          other_failures={item.other_failure}
          notes={item.note}
          placement={Number(item.placement)}
          placementType={Number(item.placementType)}
          index={index}
        />
      ))}
      <Box m={1} mt={2.5}>
        <IconButton size="small" onClick={() => onAddFailure(item.placement)}>
          <AddIcon />
        </IconButton>
        <span>{t("eventModal.failures.addFailure")}</span>
      </Box>
    </>
  );
};

export default Failures;
