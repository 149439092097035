import { IProjectList } from "types/project";

export enum EActions {
  setLoading = "setLoading",
  setProject = "setProject",
  setReset = "setReset",
}

export interface IProjectState {
  loading: boolean;
  project: IProjectList | null;
}
export interface IActionTypes {
  type: typeof EActions;
  payload: IProjectState;
}
