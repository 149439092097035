import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Spinner from "components/spinner";
import { ModalWrap } from "./modal";
import UsersList from "./userList.tsx";
import { useStyles } from "./styles";

interface ISendReportModal {
  companyId: number;
  projectId: number;
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  onSubmit: () => void;
  userIds: number[];
}
export const SendReport = memo(
  ({
    open,
    loading,
    companyId,
    projectId,
    onCancel,
    onSubmit,
    userIds,
  }: ISendReportModal) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
      <ModalWrap
        title={t("projects.detail.modal.reports.send.title")}
        onSubmit={onSubmit}
        open={open}
        onCancel={onCancel}
        cta={t("confirm")}
        submitDisabled={userIds.length === 0}
        className={classes.modalContainer}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Box mr={3} ml={3}>
            <UsersList companyId={companyId} projectId={projectId} />
          </Box>
        )}
      </ModalWrap>
    );
  }
);
