import { EActions, ICompanyDetailList, ICompanyDetailState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: ICompanyDetailState = {
  company: null,
  projects: undefined,
  pageIndex: 0,
  loading: false,
  modalLoading: false,
  reloading: false,
  notFound: false,
  editing: null,
  sort: [],
  showMore: 0,
  modalOpened: false,
  companyImage: null,
  companyFullImage: null,
  isFetchingImage: true,
  isFetchingFullImage: false,
  error: null,
};

const reducers = {
  [EActions.setReset]: () => ({
    ...initialState,
  }),

  [EActions.setCompany]: (
    { payload: { company } }: ICompanyDetailList,
    state: ICompanyDetailState
  ) => ({ ...state, company }),
  [EActions.setProjects]: (
    { payload: { projects } }: ICompanyDetailList,
    state: ICompanyDetailState
  ) => ({
    ...state,
    projects,
    loading: false,
    reloading: false,
    editing: null,
  }),
  [EActions.setLoading]: ({ payload: { loading } }: ICompanyDetailList) => ({
    loading,
  }),
  [EActions.setReloading]: ({
    payload: { reloading },
  }: ICompanyDetailList) => ({ reloading }),
  [EActions.setNotFound]: ({ payload: { notFound } }: ICompanyDetailList) => ({
    notFound,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }: ICompanyDetailList) => ({
    pageIndex,
  }),
  [EActions.setEditing]: ({ payload: { editing } }: ICompanyDetailList) => ({
    editing,
  }),
  [EActions.setSort]: ({ payload: { sort } }: ICompanyDetailList) => ({ sort }),
  [EActions.setShowMore]: ({ payload: { showMore } }: ICompanyDetailList) => ({
    showMore,
  }),
  [EActions.setModalLoading]: ({
    payload: { modalLoading },
  }: ICompanyDetailList) => ({ modalLoading }),
  [EActions.setModal]: ({ payload: { modalOpened } }: ICompanyDetailList) => ({
    modalLoading: false,
    modalOpened,
  }),
  [EActions.fetchCompanyImageRequest]: () => ({
    isFetchingImage: true,
    companyFullImage: null,
  }),
  [EActions.fetchCompanyImageFail]: ({
    payload: { error },
  }: ICompanyDetailList) => ({
    companyImage: null,
    isFetchingImage: false,
    error: error,
  }),
  [EActions.fetchCompanyImageSuccess]: ({
    payload: { companyImage },
  }: ICompanyDetailList) => ({
    companyImage: companyImage,
    isFetchingImage: false,
    error: null,
  }),
  [EActions.fetchCompanyFullImageRequest]: () => ({
    isFetchingFullImage: true,
  }),
  [EActions.fetchCompanyFullImageFail]: ({
    payload: { error },
  }: ICompanyDetailList) => ({
    companyFullImage: null,
    isFetchingFullImage: false,
    error: error,
  }),
  [EActions.fetchCompanyFullImageSuccess]: ({
    payload: { companyFullImage },
  }: ICompanyDetailList) => ({
    companyFullImage,
    isFetchingFullImage: false,
    error: null,
  }),
};
export default createReducer<ICompanyDetailState>(path, initialState, reducers);
