const AUTH_TOKEN = "authToken";
const AUTH_TOKEN_TYPE = "authTokenType";

class AuthHandler {
  static setToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };
  static setTokenType = (tokenType: string) =>
    localStorage.setItem(AUTH_TOKEN_TYPE, tokenType);
  static hasToken = () =>
    !!localStorage.getItem(AUTH_TOKEN) &&
    !!localStorage.getItem(AUTH_TOKEN_TYPE);
  static getToken = () => localStorage.getItem(AUTH_TOKEN);
  static getTokenType = () => localStorage.getItem(AUTH_TOKEN_TYPE);
  static clearToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TOKEN_TYPE);
  };
}

export default AuthHandler;
