import { EActions } from "./types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import getFaults from "api/handlers/faults/getFaults";
import omitBy from "lodash/omitBy";
import { IFaults, INewFaults } from "types/fault";
import patchFault from "api/handlers/faults/patchFault";
import deleteFault from "api/handlers/faults/deleteFault";
import postFault from "api/handlers/faults/postFault";

export const path = "faults";

const actionsData = [
  [EActions.getFaultsRequest],
  [EActions.getFaultsSuccess, "faults"],
  [EActions.getFaultsFail, "error"],

  [EActions.setFaults, "faults"],
  [EActions.setDiscard],

  [EActions.setType, "fault", "type"],
  [EActions.setSignalProcessing, "fault", "signal_processing"],
  [EActions.setDescription, "fault", "description"],
  [EActions.setRPM, "fault", "rpm"],
  [EActions.setRPMAuto, "fault", "is_rpm_automatically_detected"],
  [EActions.setBPFI, "fault", "bpfi"],
  [EActions.setBPFO, "fault", "bpfo"],
  [EActions.setBFS, "fault", "bfs"],
  [EActions.setFTF, "fault", "ftf"],
  [EActions.setThreshold, "fault", "threshold"],
  [EActions.setCoefficient, "fault", "coefficient"],
  [EActions.setNotification, "fault", "band_alerting"],
  [EActions.removeFault, "fault"],
  [EActions.addFault, "placement"],
  [
    EActions.setFrequencyEnvelopingStart,
    "fault",
    "frequency_for_enveloping_start",
  ],
  [EActions.setFrequencyEnvelopingEnd, "fault", "frequency_for_enveloping_end"],
  [EActions.setFrequencyEnvelopingEnd, "fault", "frequency_for_enveloping_end"],
  [EActions.updateSuccessfull, "isSuccessfull"],
  [EActions.setIsSaving, "isSaving"],
];

const normalizeFault = (d: INewFaults) => {
  return {
    placement: d.placement,
    frequency_for_enveloping_start: d.frequency_for_enveloping_start,
    frequency_for_enveloping_end: d.frequency_for_enveloping_end,
    rpm: d.rpm,
    coefficient: d.coefficient,
    is_rpm_automatically_detected: d.is_rpm_automatically_detected,
    band_alerting: d.band_alerting,
    type: d.type,
    description: d.description,
    ftf: d.ftf,
    bfs: d.bfs,
    bpfi: d.bpfi,
    bpfo: d.bpfo,
    threshold: d.threshold,
    signal_processing: d.signal_processing,
  };
};

const actions = prepareActions<any, EActions>(actionsData, path);

export default actions;

const parsedata = (d: IFaults[]) => {
  return d.flatMap((item: any) => item) ?? [];
};
export const getFault =
  (placement: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions.getFaultsRequest(true));
      const faults = await getFaults(placement);
      dispatch(actions.getFaultsSuccess(faults));
      dispatch(actions.setFaults(faults));
    } catch (error) {
      dispatch(actions.getFaultsFail(error));
    }
  };

export const update =
  ({ placementId }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { faults, initialFaults } = getState().faults;

      const parsedNewFaults = parsedata(faults.results);

      if (parsedNewFaults) {
        for (let i in parsedNewFaults) {
          if (parsedNewFaults[i]?.placement === null && placementId) {
            parsedNewFaults[i] = {
              ...parsedNewFaults[i],
              placement: placementId,
            };
          }
          // UPDATE
          if (parsedNewFaults[i].id !== null) {
            const fault = parsedNewFaults.find(
              ({ id }: IFaults) => id === parsedNewFaults[i].id
            );

            if (!String(parsedNewFaults[i].id).includes("new_id_")) {
              let diff = omitBy(parsedNewFaults[i], (v, k) => fault[k] === v);

              const result = {
                ...parsedNewFaults[i],
                id: parsedNewFaults[i].id,
                ...diff,
              };
              await patchFault(result, parsedNewFaults[i].id);
            }
          }

          // ADD
          if (String(parsedNewFaults[i].id).includes("new_id_")) {
            await postFault({
              ...normalizeFault(parsedNewFaults[i]),
            });
          }
        }
      }

      for (let i of initialFaults.results) {
        //REMOVE
        const itemFound = parsedNewFaults.find(
          (fault: IFaults) => fault.id === i.id
        );

        if (!itemFound && i.id) {
          await deleteFault(i.id);
        }
      }

      dispatch(getFault(placementId));

      dispatch(actions[EActions.updateSuccessfull](true));

      enqueueSnackbar(
        i18n.t("machine.detail.actions.placements.fault.update.success")
      );
    } catch (err) {
      dispatch(actions[EActions.updateSuccessfull](false));
      enqueueSnackbar(
        i18n.t("machine.detail.actions.placements.fault.update.fail", {
          variant: "error",
        })
      );
    }
  };
