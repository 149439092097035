export enum MachineStatus {
  outOfService = "outOfService",
  maintenance = "maintenance",
  broken = "broken",
  alert = "alert",
  running = "running",
  notRunning = "not_running",
  unknown = "unknown",
}

export enum MachineCondition {
  anyAlert = "any_alert",
  broken = "broken",
  anyMaintenance = "any_maintenance",
  outOfService = "out_of_service",
}

export enum EventType {
  machine_on = "machine_on",
  machine_off = "machine_off",
  error = "error",
  anomaly = "anomaly",
  anomaly_warning = "anomaly_warning",
  anomaly_ok = "anomaly_ok",
  maintenance_start = "maintenance_start",
  maintenance_end = "maintenance_end",
  nsw_maintenance_start = "nsw_maintenance_start",
  nsw_maintenance_end = "nsw_maintenance_end",
  broken_start = "broken_start",
  broken_end = "broken_end",
  out_of_service_start = "out_of_service_start",
  out_of_service_end = "out_of_service_end",
  placement_connected = "placement_connected",
  placement_disconnected = "placement_disconnected",
  nominal_sound_start = "nominal_sound_start",
  nominal_sound_end = "nominal_sound_end",
  additional_training_data_start = "additional_training_data_start",
  additional_training_data_end = "additional_training_data_end",
  placement_enabled = "placement_enabled",
  placement_disabled = "placement_disabled",
  placement_running_threshold_changed = "placement_running_threshold_changed",
  device_recording_plan_changed = "device_recording_plan_changed",
  channel_gain_changed = "channel_gain_changed",
  loudness_dashboard_updated = "loudness_dashboard_updated",
  anomaly_dashboard_updated = "anomaly_dashboard_updated",
  combined_dashboard_updated = "combined_dashboard_updated",
  model_training_failed = "model_training_failed",
  model_training_completed = "model_training_completed",
  model_training_started = "model_training_started",
  production_note_start = "production_note_start",
  production_note_end = "production_note_end",
  significant_nominal_state_change = "significant_nominal_state_change",
  production_mode_updated = "production_mode_updated",
  data_label_start = "data_label_start",
  data_label_end = "data_label_end",
}
