import createMuiTheme, { ThemeOptions } from "@mui/material/styles/createTheme";
import { pxToRem } from "shared/helpers";
import _merge from "lodash/merge";
import customPalette from "./palette";
import customTypography from "./typography";
import customBoxShadow from "./boxShadow";
import typography from "./typography";

const spacing = (
  top: number | string,
  right?: number | string,
  bottom?: number | string,
  left?: number | string
) => {
  const factor = 0.5;
  const unit = "rem";

  let marginValue = top === "auto" ? " auto" : `${factor * Number(top)}${unit}`;

  if (right !== undefined) {
    marginValue +=
      right === "auto" ? " auto" : ` ${factor * Number(right)}${unit}`;
  }
  if (bottom !== undefined) {
    marginValue +=
      bottom === "auto" ? " auto" : ` ${factor * Number(bottom)}${unit}`;
  }
  if (left !== undefined) {
    marginValue +=
      left === "auto" ? " auto" : ` ${factor * Number(left)}${unit}`;
  }
  return marginValue;
};

export default function createTheme(options: ThemeOptions) {
  const themeOptions = _merge(
    {
      custom: {
        palette: customPalette,
        boxShadow: customBoxShadow,
        typography: customTypography,
      },
      spacing,
      breakpoints: {
        values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1680 },
      },
      typography: {
        fontFamily: customTypography.fontFamily,
      },
      components: {
        MuiBackdrop: {
          styleOverrides: {
            root: {
              backgroundColor: customPalette.overlay,
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
          styleOverrides: {
            root: {
              fontSize: "0.8rem",
              fontWeight: "bold",
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            contained: {
              marginTop: spacing(0.5),
              marginRight: spacing(1.5),
              marginLeft: spacing(1.5),
              color: customPalette.placeholder,
              fontSize: customTypography.fontSize[12],
            },
          },
        },
        PrivateNotchedOutline: {
          styleOverrides: {
            root: {
              paddingLeft: spacing(0.75),
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              color: customPalette.data,
              fontSize: "0.8rem",
              fontWeight: typography.fontWeight.bold,
            },
            contained: {
              backgroundColor: "#d5d5d5",
              ":hover": {
                backgroundColor: "#d5d5d5",
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: customPalette.data,
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              "&:not(.MuiInputLabel-shrink)": {
                fontSize: "inherit",
                top: spacing(-0.5),
              },
              "&.MuiInputLabel-sizeSmall": {
                top: spacing(-0.2),
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              height: spacing(1.5),
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            multiline: {
              padding: spacing(1.8),
            },
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: customPalette.placeholder,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: customPalette.label,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: customPalette.active.regular,
                borderWidth: 1,
                boxShadow: " 0 4px 8px 0 rgba(64,128,221,0.32)",
                letterSpacing: pxToRem(0.4),
                lineHeight: 1.33,
                background: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-input": {
                background: "transparent",
              },
              "&.Mui-disabled .MuiOutlinedInput-input": {
                color: customPalette.label,
              },
              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: customPalette.shade,
              },
              "&.Mui-disabled .MuiSelect-icon": {
                color: customPalette.placeholder,
              },
            },
            input: {
              color: customPalette.data,
              padding: spacing(1.8, 1.5),
            },
            adornedEnd: {
              paddingRight: 0,
              "& .MuiIconButton-root": {
                color: customPalette.data,
              },
            },
          },
        },
        MuiSnackbarContent: {
          styleOverrides: {
            root: {
              backgroundColor: customPalette.data,
              paddingLeft: spacing(1.75),
              boxShadow: customBoxShadow[16],
              paddingRight: spacing(1.75),
              borderRadius: spacing(1),
              "&&&": {
                minWidth: "auto",
              },
            },
          },
        },
      },
    },
    options
  );
  return createMuiTheme(themeOptions);
}
