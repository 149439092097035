import { SortingRule } from "react-table";
import { ResponseType } from "types/response";
import { IModifiedPlacementList, IRulesConfig } from "types/placement";
import { ISensorType } from "../../../types/sensorType";
import { IPlacementType } from "../../../types/placement";
import { IRecordingPlan } from "../../../types/recordingPlan";
import { ICustomModel } from "types/customModels";

export enum EActions {
  setLoading = "setLoading",
  setPlacements = "setPlacements",
  setPage = "setPage",
  setReloading = "setReloading",
  setEditing = "setEditing",
  setSort = "setSort",
  setShowMore = "setShowMore",
  setReset = "setReset",
  setSensorTypes = "setSensorTypes",
  setRecordingPlans = "setRecordingPlans",
  setPlacementTypes = "setPlacementTypes",
  setPlacementStatus = "setPlacementStatus",
  setRuleStatus = "setRuleStatus",
  setPlacementThreshold = "setPlacementThreshold",
  setPlacementCable = "setPlacementCable",
  setPlacementRPM = "setPlacementRPM",
  setPlacementRPMAuto = "setPlacementRPMAuto",
  setPlacementBandAlerting = "setPlacementBandAlerting",
  rename = "rename",
  setSensorType = "setSensorType",
  setPlacementType = "setPlacementType",
  setDiscard = "setDiscard",
  setIsoGroup = "setIsoGroup",
  setRecordingPlan = "setRecordingPlan",
  setSuppressNotificationsUntil = "setSuppressNotificationsUntil",
  setPlacementEnvelopeSad = "setPlacementEnvelopeSad",
  setPlacementPrediction = "setPlacementPrediction",
  setInterval = "setInterval",
  setMinCount = "setMinCount",
  setModelResultType = "setModelResultType",
  setSelectRule = "setSelectRule",
  setAlertingThreshold = "setAlertingThreshold",
  setIntervalToWatch = "setIntervalToWatch",
  setRules = "setRules",
  addRules = "addRules",
  removeRule = "removeRule",
  updateRules = "updateRules",
  updateSuccessfullPlacement = "updateSuccessfullPlacement",
  setIsSaving = "setIsSaving",
  setMaxValue = "setMaxValue",
  setMinValue = "setMinValue",
  setChangeValueGradientAggRule = "setChangeValueGradientAggRule",

  setCustomModelFetching = "setCustomModelFetching",
  setCustomModelSuccess = "setCustomModelSuccess",
  setCustomModelFail = "setCustomModelFail",
}

export interface IProjectState {
  loading: boolean;
  placements: ResponseType<IModifiedPlacementList> | undefined;
  initialPlacements: ResponseType<IModifiedPlacementList[]> | undefined;
  pageIndex: number;
  reloading: boolean;
  editing: number | null;
  sort: SortingRule<number>[];
  showMore: number;
  pageSize: number;
  sensorTypes?: ISensorType[];
  placementTypes?: IPlacementType[];
  recordingPlans?: IRecordingPlan[];
  is_enabled?: boolean;
  customName?: string | null;
  running_threshold?: number | null;
  sensor_cable_length?: number | null;
  sensor_type?: number | null;
  type?: number;
  placement?: number;
  iso_group?: number | null;
  rpm?: number | null;
  is_rpm_automatically_detected?: boolean;
  band_alerting?: string;
  recording_plan?: string | null;
  suppress_notifications_until?: string | null;
  envelope_sad?: boolean;
  is_used_for_machine_running_prediction?: boolean;
  interval?: number;
  min_count: number;
  min_value?: number;
  max_value?: number;
  change_value?: number;
  prediction_type?: number;
  threshold?: number;
  rules: IRulesConfig[];
  rule?: number;
  index?: number;
  currentRules?: IRulesConfig[];
  isSaving: boolean;
  isSuccessfull: boolean;
  optionsRules: string;
  enabled?: boolean;
  customModels?: {
    data?: ICustomModel[];
    isFetching?: boolean;
    error?: Error | null;
  };
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IProjectState;
}
