import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  name: string;
  project: number;
  address: string;
  description: string;
};

export default async function addFacility(facility: Params) {
  const url = endpoints.facilities.default;
  try {
    const response = await axiosNeuron.post(url, facility);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
