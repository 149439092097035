import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  InputLabel,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import Tag from "components/tag";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import { BlackCheckbox } from "components/checkbox";
import { useStyles } from "./style";

interface IProps {
  data: any[];
  loading: boolean;
  selectedValues: any[];
  onChange: (value: any) => void;
  label: string;
  placeholder: string;
  id: string;
}

function MultiSelect(props: IProps) {
  const { data, loading, selectedValues, onChange, label, placeholder, id } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const options = data.map((item: any) => ({
    value: item.id,
    text: item.name,
  }));

  const [searchPhrase, setSearchPhrase] = useState("");

  const filterOptions = (options: any, searchPhrase: string) => {
    const outputOptions: any = [];
    options?.forEach((option: any) => {
      if (option?.text.toLowerCase().includes(searchPhrase.toLowerCase())) {
        outputOptions.push(option);
      }
    });
    return outputOptions;
  };

  const handleSearch = () => {
    setSearchPhrase(searchPhrase);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;

    onChange(value);
  };

  const handleBlur = useCallback(() => {
    setIsOpen(false);
    setSearchPhrase("");
  }, []);

  const renderTags = useCallback(
    (selected: any) => {
      const MAX_ITEM_COUNT = 2;
      const usedOptions: any = (selected as string[])
        .map((selectedValue) => {
          for (let i = 0; i < options?.length; i++) {
            if (options[i].value === selectedValue) {
              return options[i].text;
            }
          }
          return null;
        })
        .filter((e) => e);

      const tagsToFit = usedOptions.reduce(
        (array: string[], option: string) => {
          if (array?.length < MAX_ITEM_COUNT) {
            array.push(option);
          }
          return array;
        },
        [] as string[]
      );

      return (
        <div className={classes.renderWrap}>
          {(tagsToFit as string[]).map((option, index) => {
            return (
              <Box
                mr={1}
                mb={1}
                key={index}
                className={clsx(
                  classes.renderWrapBox,
                  tagsToFit?.length - 1 === index &&
                    tagsToFit?.length >= MAX_ITEM_COUNT &&
                    classes.last
                )}
              >
                <Tag color={false} ellipsis>
                  {option}
                </Tag>
              </Box>
            );
          })}
          {usedOptions > tagsToFit ? (
            <Box mr={1} mb={1.5} className={classes.andMoreText}>
              {t("multiselect.andMore", {
                postProcess: "interval",
                count: usedOptions?.length - tagsToFit?.length,
              })}
            </Box>
          ) : null}
        </div>
      );
    },
    [classes, options, t]
  );

  const renderOptions = useMemo(() => {
    return (
      isOpen &&
      (!options.length ? (
        <Box textAlign="center">{t("eventSounds.select.noItemFound")}</Box>
      ) : (
        filterOptions(options, searchPhrase).map((option: any) => (
          <MenuItem key={option?.value} value={option?.value}>
            <ListItemIcon>
              <BlackCheckbox
                checked={selectedValues.indexOf(option?.value) > -1}
              />
            </ListItemIcon>
            <ListItemText primary={option?.text} />
          </MenuItem>
        ))
      ))
    );
  }, [isOpen, options, searchPhrase, selectedValues, t]);

  return (
    <FormControl variant="outlined" size="small" className={classes.root}>
      <InputLabel ref={inputLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        labelId={id}
        label={label}
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={renderTags}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          // getContentAnchorEl: null,
          onClose: handleBlur,
        }}
      >
        <Box p={2} pt={1} mb={2} className={classes.searchBox}>
          <TextField
            placeholder={placeholder}
            id={"machine-select-SearchInput"}
            className={classes.textField}
            fullWidth
            variant="outlined"
            onClick={(e) => e.stopPropagation()}
            value={searchPhrase}
            onChange={(e) => {
              setSearchPhrase(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            InputProps={{
              classes: {
                input: classes.searchInput,
              },
              startAdornment: (
                <InputAdornment position="start" onClick={handleSearch}>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {loading ? <Spinner /> : renderOptions}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
