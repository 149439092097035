import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IProjectList } from "types/project";
import { ResponseType } from "types/response";
import { SortingRule } from "react-table";
import { resolveSorting } from "shared/helpers";

type Params = {
  ids?: number[];
  userIds?: number[];
  page?: number;
  pageSize?: number;
  orderBy?: SortingRule<string>[];
  companyIds?: number[];
  q?: string;
  nextUrl?: string;
  field?: string[];
};

export default async function getProjects(paramsInput: Params) {
  if (paramsInput.ids && !paramsInput.ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IProjectList>;
  }
  const params = {
    id: paramsInput.ids,
    page: paramsInput.page,
    ps: paramsInput.pageSize,
    order_by: resolveSorting("name", paramsInput?.orderBy),
    company: paramsInput.companyIds,
    user: paramsInput.userIds,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
    field: paramsInput.field,
    is_archived: "false",
  };
  try {
    const response = await axiosNeuron.get(
      paramsInput.nextUrl || endpoints.projects.default,
      paramsInput.nextUrl
        ? {}
        : {
            params,
          }
    );
    const data: ResponseType<IProjectList> = {
      ...response.data,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        projectName: item.project_name,
        name: item.name,
        createdAt: item.created_at,
        description: item.description,
        type: item.type,
        bucket: item.bucket,
        company: item.company,
        users: item.users,
        user_ro: item.user_ro,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      return null;
    }
  }
}
