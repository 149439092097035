export const deleteCookie = () => {
  document.cookie = "AUTHCOOKIE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const writeCookie = (cookie: string) => {
  deleteCookie();
  const exDate = new Date();
  exDate.setDate(exDate.getDate() + 60);

  document.cookie = `AUTHCOOKIE="${cookie}"; expires=${exDate}; path=/; SameSite=None; Secure`;
};
