import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IFaults } from "types/fault";

export default async function patchFault(params: Partial<IFaults>, id: number) {
  const url = endpoints.faults.editFaults(id);

  try {
    const { data } = await axiosNeuron.patch(url, {
      placement: params.placement,
      type: params.type,
      frequency_for_enveloping_start: params.frequency_for_enveloping_start,
      frequency_for_enveloping_end: params.frequency_for_enveloping_end,
      rpm: params.rpm,
      coefficient: params.coefficient,
      is_rpm_automatically_detected: params.is_rpm_automatically_detected,
      band_alerting: params.band_alerting,
      description: params.description,
      ftf: params.ftf,
      bfs: params.bfs,
      bpfi: params.bpfi,
      bpfo: params.bpfo,
      threshold: params.threshold,
      signal_processing: params.signal_processing,
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
