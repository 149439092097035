import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner: React.FC = () => {
  return (
    <Box className="testSpinner" display="flex" justifyContent="center" my={2}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
