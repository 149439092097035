import { IDeviceList, ITelemetric } from "types/device";

export enum EActions {
  setDevice = "setDevice",
  setLoading = "setLoading",
  setReset = "setReset",
  setError = "setError",

  fetchTelemetricRequest = "fetchTelemetricRequest",
  fetchTelemetricSuccess = "fetchTelemetricSuccess",
  fetchTelemetricFail = "fetchTelemetricFail",
}

export interface DeviceDetailState {
  device?: IDeviceList;
  loading?: boolean;
  error?: Error;

  telemetric?: ITelemetric[];
  isFetching?: boolean;
  telemetricError?: Error | null;
}

export interface DevicesDetailAction {
  type: typeof EActions;
  payload: DeviceDetailState;
}

export type TReducers = {
  [key: string]: (
    p: DevicesDetailAction,
    s: DeviceDetailState
  ) => DeviceDetailState;
};
