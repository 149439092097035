import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { SortingRule } from "react-table";

import getUsers from "api/handlers/user/getUsers";
import { EActions, IUsersAction } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import getVisibleRoles from "api/handlers/roles/getVisibleRoles";
import getCompanies from "api/handlers/company/getCompanies";
import getProjects from "api/handlers/project/getProjects";
import { IRole } from "types/role";
import { ICompanyList } from "types/company";
import { IProjectList } from "types/project";

export const path = "users";

const actionsData = [
  [EActions.setPage, "pageIndex"],
  [EActions.fetchUsersRequest],
  [EActions.fetchUsersFail, "error"],
  [EActions.fetchUsersSuccess, "usersData", "usersFetchParams"],
  [EActions.setUsersFilter, "filters"],
  [EActions.setResetList],
  [EActions.setModal, "modalOpened"],
  [EActions.setMore, "more"],
  [EActions.setSort, "sort"],
  [EActions.fetchRolesRequest],
  [EActions.fetchRolesFail, "error"],
  [EActions.fetchRolesSuccess, "roles"],
  [EActions.fetchCompaniesRequest],
  [EActions.fetchCompaniesFail, "error"],
  [EActions.fetchCompaniesSuccess, "companies"],
  [EActions.fetchProjectsRequest],
  [EActions.fetchProjectsFail, "error"],
  [EActions.fetchProjectsSuccess, "projects"],
  [EActions.fetchStatusesRequest],
  [EActions.fetchStatusesFail, "error"],
  [EActions.fetchStatusesSuccess, "roles"],
];

const actions = prepareActions<IUsersAction, EActions>(actionsData, path);
export default actions;

export const fetchProjects =
  (companyIds: number[] = []) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchProjectsRequest]());
    try {
      const projects = await getProjects({ companyIds, pageSize: 999 });
      const projectsData = projects?.results.map((item: IProjectList) => ({
        value: item.id,
        text: item.name,
      }));
      dispatch(actions[EActions.fetchProjectsSuccess](projectsData));
    } catch (error) {
      dispatch(actions[EActions.fetchProjectsFail](error));
    }
  };

export const fetchCompanies =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCompaniesRequest]());
    try {
      const companies = await getCompanies({ pageSize: 999 });
      const companiesData = companies?.results.map((item: ICompanyList) => ({
        value: item.id,
        text: item.name,
      }));
      dispatch(actions[EActions.fetchCompaniesSuccess](companiesData));
    } catch (error) {
      dispatch(actions[EActions.fetchCompaniesFail](error));
    }
  };

export const fetchRoles =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchRolesRequest]());
    try {
      const roles = await getVisibleRoles();
      const rolesData = roles?.results.map((item: IRole) => ({
        value: item.codename,
        text: item.name!,
      }));
      dispatch(actions[EActions.fetchRolesSuccess](rolesData));
    } catch (error) {
      dispatch(actions[EActions.fetchRolesFail](error));
    }
  };

type Type = {
  page: number;
  pageSize: number;
  orderBy: SortingRule<number>[];
  filters?: { [key: string]: number[] | string[] };
  q?: string;
  from?: string;
};

export const fetchUsers =
  ({ page, pageSize, orderBy, filters, q }: Type) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchUsersRequest]());
    try {
      const users = await getUsers(
        { page, pageSize, orderBy, filterObject: filters, q },
        true
      );
      dispatch(
        actions[EActions.fetchUsersSuccess](users, {
          page,
          pageSize,
          orderBy,
          filters,
          q,
        })
      );
    } catch (error) {
      dispatch(actions[EActions.fetchUsersFail](error));
    }
  };

export const refetchUsersSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { usersFetchParams } = getState().users;
    if (!!usersFetchParams) {
      const { page, pageSize, orderBy, filterObject, q } = usersFetchParams;
      try {
        const users = await getUsers(
          {
            page,
            pageSize,
            orderBy,
            filterObject,
            q,
          },
          true
        );
        dispatch(
          actions.fetchUsersSuccess(users, {
            page,
            pageSize,
            orderBy,
            filterObject,
            q,
          })
        );
      } catch (error) {
        dispatch(actions[EActions.fetchUsersFail](error));
      }
    }
  };
