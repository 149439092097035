type TUrls = {
  [key: string]: {
    [key: string]: (
      id?: number | string,
      tab?: string,
      projectId?: number | string
    ) => string;
  };
};

export default {
  appRoot: {
    index: () => "/",
    all: () => "*",
    login: () => "/login/",
    temporaryToken: () => "/temporary-access/",
  },
  forgotPassword: {
    index: () => "/forgot-password",
  },
  newPassword: {
    pattern: () => "/new-password/:uuid/:token",
  },
  companies: {
    index: () => "/companies/",
    detail: (id: number) => `/companies/detail/${id}/`,
    detailPattern: () => "/companies/detail/:id",
  },
  facilities: {
    detail: (id: number) => `/facilities/detail/${id}/`,
    detailPattern: () => "/facilities/detail/:id",
  },
  projects: {
    detail: (id: number, tab?: string, companyId?: number) =>
      `/projects/detail/${id}/${tab || "facilities"}${
        companyId ? `/${companyId}` : ""
      }`,
    detailPattern: () => "/projects/detail/:id",
    detailGeneralPattern: () => "/projects/detail/:id/facilities",
    detailUsersPattern: () => `/projects/detail/:id/users`,
    detailReportsPattern: () => `/projects/detail/:id/reports`,
    detailTemporaryTokenPattern: () => `/projects/detail/:id/temporary-token`,
  },
  dataLabeling: {
    index: () => "/data-labeling/",
    pattern: () =>
      "/data-labeling/:project?/:machine?/:timezoneOffset?/:date?/:time?/:placement?/:duration?",
    fromGrafana: () => "/sounds/",
  },
  users: {
    index: () => "/users",
    detail: (id: number, tab: string, projectId?: number) =>
      `/users/detail/${id}/${tab}${projectId ? `/${projectId}` : ""}`,
    detailPattern: () => "/users/detail/:id/:tab/:projectId?",
  },
  machines: {
    detail: (id: number, tab: string) =>
      `/machines/detail/${id}${tab ? `?tab=${tab}` : ""}`,
    detailPattern: () => "/machines/detail/:id",
    detailConfig: (id: number) => `/machines/detail/${id}/config`,
    detailConfigPattern: () => "/machines/detail/:id/config",
    detailModel: (id: number) => `/machines/detail/${id}/model`,
    detailModelPattern: () => "/machines/detail/:id/model",
    detailEvents: (id: number) => `/machines/detail/${id}/events`,
    detailEventsPattern: () => "/machines/detail/:id/events",
    detailSounds: (id: number) => `/machines/detail/${id}/sounds`,
    detailSoundsPattern: () => "/machines/detail/:id/sounds",
    detailPlacements: (id: number) => `/machines/detail/${id}/placements`,
    detailPlacementsPattern: () => "/machines/detail/:id/placements",
    detailAnalysis: (id: number) => `/machines/detail/${id}/analysis`,
    detailAnalysisPattern: () => "/machines/detail/:id/analysis",
    detailCustomModel: (id: number) => `/machines/detail/${id}/customModel`,
    detailCustomModelPattern: () => "/machines/detail/:id/customModel",
  },
  devices: {
    index: () => "/devices/",
    detail: (id: number) => `/devices/detail/${id}`,
    detailPattern: () => "/devices/detail/:id",
    detailModel: (id: number) => `/devices/detail/${id}/model`,
    detailTelemetric: (id: number) => `/devices/detail/${id}/telemetric`,
    detailModelPattern: () => "/devices/detail/:id/model",
    detaildetailTelemetricPattern: () => "/devices/detail/:id/telemetric",
  },
  helpdesk: {
    index: () => "/helpdesk/",
  },
  translations: {
    path: () => "/static/translations/translations.csv",
  },
  models: {
    index: () => "/models/",
    detail: (tab?: string) => `/models/${tab || "standard-models"}`,
    detailStandardModels: () => "/models/standard-models",
    detailCustomModels: () => "/models/custom-models",
  },
  accessTokens: {
    index: () => "/access-tokens/",
  },
  eventSounds: {
    index: () => "/data-explorer/",
    detail: (tab?: string) => `/data-explorer/${tab || "event-selection"}`,
    detailEventSelection: () => "/data-explorer/event-selection",
    detailCollectionClasses: () => "/data-explorer/collection-classes",
    detailSimilaritySearch: () => "/data-explorer/similarity-search",
    detailDatasets: () => "/data-explorer/datasets",
  },
  serviceManager: {
    index: () => "/service-manager/",
    detail: (id: string) => `/service-manager/detail/${id}`,
    detailPattern: () => "/service-manager/detail/:id",
  },
  internalGrafana: {
    deviceDetailDashboard: () =>
      "https://grafana.neuronsw.com/d/py4Is0UMz/device-status-detail?orgId=1",
  },
} as TUrls;
