import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function stopModelTraining(
  machine: number,
  job_name: string
) {
  const url = endpoints.machines.stopModelTraining(machine);
  try {
    const response = await axiosNeuron.post(url, { job_name });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
