import { ResponseType } from "types/response";
import { IMachineList } from "types/machine";

export enum EActions {
  fetchProjectMachinesRequest = "fetchProjectMachinesRequest",
  fetchProjectMachinesFail = "fetchProjectMachinesFail",
  fetchProjectMachinesSuccess = "fetchProjectMachinesSuccess",
  setReset = "setReset",
}

export interface IProjectMachinesState {
  machines?: ResponseType<IMachineList> | null;
  loading?: boolean;
  error?: Error | null;
}

export interface IProjectMachinesAction {
  type: typeof EActions;
  payload: IProjectMachinesState;
}

export type TReducers = {
  [key: string]: (p: IProjectMachinesAction, s: IProjectMachinesState) => void;
};
