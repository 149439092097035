import { IPlacementImage } from "types/placement";

export enum EActions {
  setPlacementImageLoading = "setPlacementImageLoading",
  setPlacementImageSuccess = "setPlacementImageSuccess",
  setPlacementImageError = "setPlacementImageError",
}

export interface IProjectState {
  placementImage: IPlacementImage | null;
  isFetching: boolean;
  error: Error | null;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IProjectState;
}
