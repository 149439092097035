import { EActions, IAddUserActions, IAddUserModalState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import xor from "lodash/xor";

const initialState: IAddUserModalState = {
  data: undefined,
  pageIndex: 0,
  loading: false,
  reloading: false,
  sort: [],
  showMore: 0,
  q: "",
  selected: [],
  modalOpened: null,
};

const reducers = {
  [EActions.setReset]: () => ({
    ...initialState,
  }),
  [EActions.setData]: ({ payload: { data } }: IAddUserActions) => ({
    data,
    loading: false,
    reloading: false,
  }),
  [EActions.setLoading]: ({ payload: { loading } }: IAddUserActions) => ({
    loading,
  }),
  [EActions.setReloading]: ({ payload: { reloading } }: IAddUserActions) => ({
    reloading,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }: IAddUserActions) => ({
    pageIndex,
  }),
  [EActions.setSort]: ({ payload: { sort } }: IAddUserActions) => ({ sort }),
  [EActions.setShowMore]: ({ payload: { showMore } }: IAddUserActions) => ({
    showMore,
  }),
  [EActions.setSearch]: ({ payload: { q } }: IAddUserActions) => ({
    q,
    pageIndex: 0,
  }),
  [EActions.setSelected]: (
    { payload: { selected } }: IAddUserActions,
    state: IAddUserModalState
  ) => ({ selected: [...xor([...state.selected], selected)] }),
  [EActions.setModal]: ({ payload: { modalOpened } }: IAddUserActions) => ({
    modalOpened,
    reloading: false,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};
export default createReducer<IAddUserModalState>(path, initialState, reducers);
