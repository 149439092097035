import { memo, useState, useCallback } from "react";
import Select from "components/select";
import { useTranslation } from "react-i18next";
import ConfirmPopup from "components/confirmPopup";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/deviceDetail/channelsList/actions";
import {
  OChannelsList,
  OPlacementsList,
} from "store/deviceDetail/channelsList/types";
import { AppState } from "store";
import isEqual from "react-fast-compare";
import { cellCompare } from "shared/helpers";
import { CellProps } from "react-table";
import { createSelector } from "reselect";
import { Field, Form } from "react-final-form";
import { makeStyles } from "@mui/styles";

const { setPlacement } = actions;

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      height: theme.spacing(4),
      minWidth: theme.spacing(8),
      color: "red",
    },
    select: {
      "& .MuiSelect-selectMenu": {
        minHeight: "auto",
      },
    },
    input: {
      fontSize: theme.custom.typography.fontSize["14"],
      letterSpacing: theme.custom.typography.letterSpacing["medium"],
      minHeight: "auto",
      height: "0.75rem",
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: 2.3,
    },
  };
});

type PropsAndState = {
  state: AppState;
  id: number;
  placement: number;
};

const selector = createSelector(
  ({ id, placement }: PropsAndState) => ({ id, placement }),
  ({ state }: PropsAndState) => state.deviceDetail.channelsList,
  ({ id, placement }, { channels, placements }) => {
    const machine =
      channels.find((item: OChannelsList) => id && Number(item.id) === id)
        ?.machine || null;
    return {
      machine,
      channels,
      placementData: placements.filter(
        (item: OPlacementsList) => item.machine === machine
      ),
      selectedPlacement: placements.find(
        (item: OPlacementsList) => item.id === placement
      ),
    };
  }
);

const PlacementTableSelect = <T extends Object>({
  cell: {
    value: { disabled, id, placement, audioChunkTypes },
  },
}: CellProps<T>) => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const [modal, setModal] = useState<number | undefined>(undefined);
  const classes = useStyles();

  const openMenu = useCallback(
    (e: any) => setModal(Number(e.target.value)),
    []
  );

  const closeMenu = useCallback(() => {
    setModal(undefined);
  }, []);

  const { channels, selectedPlacement, placementData, machine } = useSelector(
    (state: AppState) => selector({ state, id, placement }),
    isEqual
  );

  const disabledItem = useCallback(
    (item: { [key: string]: number | null }) => {
      const machines = channels
        .filter(({ machine: machineId }: any) => machine === machineId)
        .map(({ placement }: OChannelsList) => placement);
      return machines.indexOf(item.id) !== -1;
    },
    [channels, machine]
  );

  const filteredPlacementData = placementData.filter(
    (placement: OPlacementsList) =>
      audioChunkTypes.indexOf(placement.audio_chunk_type) !== -1
  );

  const disabledValues = filteredPlacementData
    .filter(disabledItem)
    .map((i: any) => i.id);

  const onConnectPlacement = useCallback(() => {
    if (modal) {
      dispatch(setPlacement(id, modal));
      closeMenu();
    }
  }, [closeMenu, dispatch, id, modal]);

  if (!placementData || filteredPlacementData.length === 0) {
    return null;
  }

  const getName = (i: any) =>
    i
      ? i.customName
        ? i.customName
        : i18n.exists(`placementType.${i.name}`)
          ? t(`placementType.${i.name}`)
          : i.name
      : "";

  return (
    <>
      <div className={classes.root} title={getName(selectedPlacement)}>
        <Form
          onSubmit={() => {}}
          initialValues={{ placement: selectedPlacement?.id }}
          render={() => (
            <form>
              <Field
                name="placement"
                render={({ input, meta }) => (
                  <Select
                    id={`placement_type_select_${id}`}
                    className={classes.select}
                    name="placement"
                    label={t("devices.detail.list.headers.placement")}
                    input={input}
                    meta={meta}
                    inputProps={{
                      className: classes.input,
                    }}
                    selected={selectedPlacement}
                    onChange={openMenu}
                    disabled={disabled || machine === null}
                    options={filteredPlacementData.map((i: any) => ({
                      value: i.id,
                      text: getName(i),
                    }))}
                    size="small"
                    disabledValues={disabledValues}
                    fullWidth
                  />
                )}
              />
            </form>
          )}
        />
      </div>
      {modal && (
        <ConfirmPopup
          onConfirm={onConnectPlacement}
          title={t("devices.detail.placement.modal.title")}
          text={t("devices.detail.placement.modal.text")}
          confirmText={t("devices.detail.placement.modal.confirm")}
          noControl={true}
          onCancel={closeMenu}
        />
      )}
    </>
  );
};
export default memo(
  PlacementTableSelect,
  cellCompare
) as typeof PlacementTableSelect;
