import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { useMutation } from "react-query";
import patchMachine from "api/handlers/machine/patchMachine";
import { useDispatch } from "react-redux";
import { fetchMachineDetail } from "store/machineDetail/actions";

import Box from "@mui/material/Box";
import Divider from "components/typography/heading/divider";
import Checkbox from "components/checkbox";
import Spinner from "components/spinner";

interface Props {
  isSmsNotifying: boolean;
  isEmailNotifying: boolean;
  isAssistedAlerting: boolean;
  disabled?: boolean;
  id: number;
}

const SectionNotifications: React.FC<Props> = memo(
  ({ isSmsNotifying, isEmailNotifying, isAssistedAlerting, id, disabled }) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();

    const [patchMachineMutation, { status }] = useMutation(patchMachine);

    const handleSms = () =>
      patchMachineMutation(
        {
          id,
          payload: {
            is_sms_notifying: !isSmsNotifying,
          },
        },
        {
          onSuccess: () => {
            dispatch(fetchMachineDetail(id));
          },
        }
      );
    const handleEmail = () =>
      patchMachineMutation(
        {
          id,
          payload: {
            is_email_notifying: !isEmailNotifying,
          },
        },

        {
          onSuccess: () => {
            dispatch(fetchMachineDetail(id));
          },
        }
      );
    const handleAssistedAlerting = () =>
      patchMachineMutation(
        {
          id,
          payload: {
            is_assisted_alerting: !isAssistedAlerting,
          },
        },

        {
          onSuccess: () => {
            dispatch(fetchMachineDetail(id));
          },
        }
      );
    return (
      <>
        <Divider line>{t("users.detail.notifications.title")}</Divider>
        {status === "loading" ? (
          <Spinner />
        ) : (
          <Box mb={1} display="flex" flexDirection="column">
            <Checkbox
              disabled={disabled}
              label={t("users.detail.notifications.sms")}
              checked={isSmsNotifying}
              onChange={handleSms}
            />
            <Checkbox
              disabled={disabled}
              label={t("users.detail.notifications.email")}
              checked={isEmailNotifying}
              onChange={handleEmail}
            />
            <Checkbox
              disabled={disabled}
              label={t("users.detail.notifications.assisted")}
              checked={isAssistedAlerting}
              onChange={handleAssistedAlerting}
            />
          </Box>
        )}
      </>
    );
  }
);

export default SectionNotifications;
