import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
interface IUpdatePlacementimageParams {
  placement: number;
  image_data: string;
  type: "FULL" | "THUMBNAIL";
  timezone?: string;
}

export default async function addPlacementImage({
  placement,
  image_data,
  type,
  timezone,
}: IUpdatePlacementimageParams) {
  const url = endpoints.placements.images;

  try {
    const response = await axiosNeuron.post(url, {
      placement,
      image_data,
      type,
      tz: timezone,
    });
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
