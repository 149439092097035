import React from "react";
import { makeStyles } from "@mui/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import EditIcon from "@mui/icons-material/Edit";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) => {
  const sharedStyles = {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  };

  return {
    root: {
      zIndex: 1,
      color: theme.custom.palette.surface,
      backgroundColor: theme.custom.palette.placeholder,
      ...sharedStyles,
    },
    circle: {
      fontFamily: theme.custom.typography.fontFamily,
      fontWeight: theme.custom.typography.fontWeight.bold,
      fontSize: theme.custom.typography.fontSize[14],
      ...sharedStyles,
    },
    editIcon: {
      fontFamily: theme.custom.typography.fontFamily,
      fontWeight: theme.custom.typography.fontWeight.bold,
      fontSize: theme.custom.typography.fontSize[16],
      ...sharedStyles,
    },
    active: {
      backgroundColor: theme.custom.palette.primary[100],
    },
    completed: {
      backgroundColor: theme.custom.palette.primary[100],
      cursor: "pointer",
    },
  };
});

const StepIcon = ({ active, completed, icon }: StepIconProps) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? (
        <div className={classes.editIcon}>
          <EditIcon fontSize="inherit" />
        </div>
      ) : (
        <div className={classes.circle}>{icon}</div>
      )}
    </div>
  );
};

export default StepIcon;
