export const SERVER_ERROR_SET_STATUS = "SERVER_ERROR_SET_STATUS";

export interface ServerErrorState {
  status: boolean;
  statusCode?: number;
  message?: string;
}
export interface ServerErrorSetStatusAction {
  type: typeof SERVER_ERROR_SET_STATUS;
  payload: {
    status: boolean;
    statusCode?: number;
    message?: string;
  };
}
