import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

type TParams = {
  id: number;
  name: string;
  datasets: { dataset: number }[];
};

export default async function patchDatasetGroup(params: TParams) {
  try {
    const url = endpoints.eventSounds.datasetGroupDetail(params.id);

    const { data } = await axiosNeuron.patch(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
