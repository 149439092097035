import { EActions } from "./types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import getCurrencies from "api/handlers/currencies/getCurrencies";
import { prepareActions } from "store/helpers";
import { AppState } from "store";

export const path = "currency";

const actionsData = [
  [EActions.getCurrenciesRequest],
  [EActions.getCurrenciesSuccess, "currencies"],
  [EActions.getCurrenciesFail, "error"],
];

const actions = prepareActions<any, EActions>(actionsData, path);

export default actions;

export const getCurrency =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    try {
      dispatch(actions.getCurrenciesRequest(true));
      const currencies = await getCurrencies();
      dispatch(actions.getCurrenciesSuccess(currencies));
    } catch (error) {
      dispatch(actions.getCurrenciesFail(error));
    }
  };
