//@ts-nocheck
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import { appReducer } from "./reducers";

const combined = combineReducers(appReducer);
export type AppState = ReturnType<typeof combined>;

const composer =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? composeWithDevTools(applyMiddleware(thunk))
    : compose(applyMiddleware(thunk));

export function createReducerManager(initialReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  return {
    getReducerMap: () => reducers,
    reduce: (state, action) => {
      if (keysToRemove.length > 0) {
        state = { ...state };
        for (const key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }
      if (action.type === "USER_LOGOUT") {
        state = { langs: { loading: false } };
      }
      return combinedReducer(state, action);
    },
    add: (key, reducer) => {
      if (!key) {
        return;
      }
      reducers[key] = reducer;
      combinedReducer = combineReducers(reducers);
    },
    remove: (key: any) => {
      if (!key || !reducers[key]) {
        return;
      }
      delete reducers[key];
      keysToRemove.push(key);

      combinedReducer = combineReducers(reducers);
    },
  };
}

export default function configureStore(initialState) {
  const reducerManager = createReducerManager(appReducer);

  const store = createStore(reducerManager.reduce, initialState, composer);

  store.reducerManager = reducerManager;
  return store;
}

export const store = configureStore();
