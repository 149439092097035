import { IMachineList, IMachineImage } from "types/machine";
import { IFacility } from "types/facility";
import { IUserList } from "types/user";
import { IModel } from "types/models";

export enum EActions {
  FETCH_MACHINE_REQUEST = "FETCH_MACHINE_REQUEST",
  FETCH_MACHINE_SUCCESS = "FETCH_MACHINE_SUCCESS",
  FETCH_FACILITY_SUCCESS = "FETCH_FACILITY_SUCCESS",
  FETCH_MACHINE_FAIL = "FETCH_MACHINE_FAIL",
  FETCH_MACHINE_MODELS_REQUEST = "FETCH_MACHINE_MODELS_REQUEST",
  FETCH_MACHINE_MODELS_SUCCESS = "FETCH_MACHINE_MODELS_SUCCESS",
  FETCH_MACHINE_MODELS_FAIL = "FETCH_MACHINE_MODELS_FAIL",
  FETCH_MACHINE_IMAGE_REQUEST = "FETCH_MACHINE_IMAGE_REQUEST",
  FETCH_MACHINE_IMAGE_SUCCESS = "FETCH_MACHINE_IMAGE_SUCCESS",
  FETCH_MACHINE_IMAGE_FAIL = "FETCH_MACHINE_IMAGE_FAIL",
  FETCH_MACHINE_FULL_IMAGE_REQUEST = "FETCH_MACHINE_FULL_IMAGE_REQUEST",
  FETCH_MACHINE_FULL_IMAGE_SUCCESS = "FETCH_MACHINE_FULL_IMAGE_SUCCESS",
  FETCH_MACHINE_FULL_IMAGE_FAIL = "FETCH_MACHINE_FULL_IMAGE_FAIL",
  FETCH_MACHINE_PRODUCTION_MODES_REQUEST = "FETCH_MACHINE_PRODUCTION_MODES_REQUEST",
  FETCH_MACHINE_PRODUCTION_MODES_SUCCESS = "FETCH_MACHINE_PRODUCTION_MODES_SUCCESS",
  FETCH_MACHINE_PRODUCTION_MODES_FAIL = "FETCH_MACHINE_PRODUCTION_MODES_FAIL",
  setReset = "setReset",
}

export interface IMachineDetailState {
  machine: IMachineList | null;
  facility: IFacility | null;
  users: IUserList[];
  audioUploader: any;
  machineImage: IMachineImage | null;
  machineFullImage: IMachineImage | null;
  isFetching: boolean;
  error: Error | null;
  isFetchingImage: boolean;
  isFetchingFullImage: boolean;
  models: IModel[] | null;
  isFetchingModels: boolean;
  productionModes: any[];
  isFetchingProductionModes: boolean;
}

export interface IMachineDetailAction {
  type: EActions;
  payload: IMachineDetailState;
}

export type TReducer = {
  [key: string]: (p: IMachineDetailAction, s: IMachineDetailState) => void;
};
