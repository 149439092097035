import React, { PureComponent } from "react";

const AXIS_COLOR = "rgba(0, 0, 0, 0.65)";

class FftTick extends PureComponent {
  static defaultProps = {
    left: 0,
    width: 0,
    widthLeft: undefined,
    height: 10,
    color: AXIS_COLOR,
    lineWidth: 1,
    label: undefined,
    last: false,
  };

  render() {
    let { label, left, width, widthLeft, height, color, lineWidth, last } =
      this.props;

    return (
      <div
        className="wave-tick"
        style={{
          position: "absolute",
          left,
          width,
          height,
          borderLeft: `solid ${lineWidth}px ${color}`,
          borderTop:
            widthLeft === null || widthLeft === undefined || widthLeft < width
              ? "none"
              : `solid ${lineWidth}px ${color}`,
        }}
      >
        {!last && (
          <div
            style={{
              position: "absolute",
              left: 2,
              top: 2,
              fontWeight: "normal",
            }}
          >
            {label}
          </div>
        )}
      </div>
    );
  }
}

export default FftTick;
