import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import moment from "moment";

export interface IEventsParams {
  companies?: number[];
  projects?: number[];
  facilities?: number[];
  machines?: number[];
  q?: string;
  show_archived?: boolean;
  running?: boolean;
  not_running?: boolean;
  unknown?: boolean;
  any_maintenance?: boolean;
  broken?: boolean;
  any_alert?: boolean;
  out_of_service?: boolean;
  types?: string[];
  from?: Date;
  to?: Date;
}

export default async function getExportedEvents({
  companies,
  projects,
  facilities,
  machines,
  q,
  show_archived,
  running,
  not_running,
  unknown,
  any_maintenance,
  broken,
  any_alert,
  out_of_service,
  types,
  from,
  to,
}: IEventsParams) {
  const url = endpoints.eventExport.default;
  const params = {
    company: companies,
    project: projects,
    facility: facilities,
    machine: machines,
    q,
    show_archived,
    running,
    not_running,
    unknown,
    any_maintenance,
    broken,
    any_alert,
    out_of_service,
    type: types,
    from: moment(from).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
    to: moment(to).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
  };
  const {
    data: { results },
  } = await axiosNeuron.get(url, { params });
  return results;
}
