import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import getProjects from "api/handlers/project/getProjects";
import getCompany from "api/handlers/company/getCompany";
import addProject from "api/handlers/project/addProject";
import patchProject from "api/handlers/project/patchProject";
import updateCompany from "api/handlers/company/updateCompany";
import { prepareActions } from "store/helpers";
import { EActions, ICompanyDetailList } from "./types";
import { AppState } from "store";
import getCompanyImage from "api/handlers/company/getCompanyImage";
import addCompanyImage from "api/handlers/company/addCompanyImage";
import updateCompanyImage from "api/handlers/company/updateCompanyImage";
import removeCompanyImage from "api/handlers/company/removeCompanyImage";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";

export const path = "projectsList";

const actionData = [
  [EActions.setPage, "pageIndex"],
  [EActions.setCompany, "company"],
  [EActions.setProjects, "projects"],
  [EActions.setLoading, "loading"],
  [EActions.setReloading, "reloading"],
  [EActions.setNotFound, "notFound"],
  [EActions.setEditing, "editing"],
  [EActions.setSort, "sort"],
  [EActions.setShowMore, "showMore"],
  [EActions.setReset, "setReset"],
  [EActions.setModalLoading, "modalLoading"],
  [EActions.setModal, "modalOpened"],
  [EActions.fetchCompanyImageRequest],
  [EActions.fetchCompanyImageFail, "error"],
  [EActions.fetchCompanyImageSuccess, "companyImage"],
  [EActions.fetchCompanyFullImageRequest],
  [EActions.fetchCompanyFullImageFail, "error"],
  [EActions.fetchCompanyFullImageSuccess, "companyFullImage"],
];

const actions = prepareActions<ICompanyDetailList, EActions>(actionData, path);

export default actions;

export const fetchCompanyDetail =
  ({ page, pageSize, orderBy, companyIds }: any) =>
  async (dispatch: ThunkDispatch<{}, {}, Action<EActions>>) => {
    try {
      dispatch(actions[EActions.setLoading](true));
      const projects = await getProjects({
        page,
        pageSize,
        orderBy,
        companyIds,
      });
      const company = await getCompany(companyIds);
      dispatch(actions[EActions.setProjects](projects));
      company === null
        ? dispatch(actions[EActions.setNotFound](true))
        : dispatch(actions[EActions.setCompany](company));
    } catch (error) {
      dispatch(actions[EActions.setLoading](false));
    }
  };

export const renameCompany =
  (id: number, name: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any | any,
    getState: any
  ) => {
    try {
      await updateCompany({ id, name });

      enqueueSnackbar(i18n.t("companies.list.table.success"));

      const { company, pageIndex, sort, showMore } = getState().companyDetail;

      dispatch(
        fetchCompanyDetail({
          page: !showMore ? pageIndex + 1 : 1,
          pageSize: showMore ? 10 * (1 + pageIndex) : 10,
          orderBy: sort,
          companyIds: company.id,
        })
      );
    } catch (error) {
      enqueueSnackbar(i18n.t("companies.list.table.fail"));
    }
  };

export const rename =
  (id: number, name: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any | any,
    getState: any
  ) => {
    try {
      await patchProject({ id, name });

      enqueueSnackbar(i18n.t("projects.list.table.success"));

      const { company, pageIndex, sort, showMore } = getState().companyDetail;

      dispatch(
        fetchCompanyDetail({
          page: !showMore ? pageIndex + 1 : 1,
          pageSize: showMore ? 10 * (1 + pageIndex) : 10,
          orderBy: sort,
          companyIds: company.id,
        })
      );
    } catch (error) {
      enqueueSnackbar(i18n.t("projects.list.table.fail"));
    }
  };

export const addProjectAction =
  (company: number, name: string, cb?: (id: number) => void) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any | any,
    getState: any
  ) => {
    try {
      dispatch(actions[EActions.setModalLoading](true));
      const project = await addProject({ company, name });

      enqueueSnackbar(i18n.t("companies.detail.modal.success"));

      dispatch(actions[EActions.setModal](false));
      const { pageIndex, sort, showMore } = getState().companyDetail;

      dispatch(
        fetchCompanyDetail({
          page: !showMore ? pageIndex + 1 : 1,
          pageSize: showMore ? 10 * (1 + pageIndex) : 10,
          orderBy: sort,
          companyIds: company,
        })
      );
      cb && cb(project.id);
    } catch (error) {
      dispatch(actions[EActions.setModalLoading](false));
      enqueueSnackbar(i18n.t("companies.detail.modal.error"));
    }
  };

export const fetchCompanyImage =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    !silent && dispatch(actions[EActions.fetchCompanyImageRequest]());
    try {
      const response = await getCompanyImage(id);
      if (response) {
        dispatch(actions[EActions.fetchCompanyImageSuccess](response));
      }
    } catch (err) {
      dispatch(actions[EActions.fetchCompanyImageFail](err));
    }
  };

export const fetchCompanyFullImage =
  (id: number, silent = false) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    !silent && dispatch(actions[EActions.fetchCompanyFullImageRequest]());
    try {
      const response = await getCompanyImage(id, true);
      if (response) {
        dispatch(actions[EActions.fetchCompanyFullImageSuccess](response));
      }
    } catch (err) {
      dispatch(actions[EActions.fetchCompanyFullImageFail](err));
    }
  };

export const addImage =
  (companyId: number, imageData: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    try {
      await dispatch(actions[EActions.fetchCompanyImageRequest]());
      await addCompanyImage({
        company: companyId,
        version: Math.random().toString().substr(2, 10),
        type: "FULL",
        image_data: imageData,
      });

      enqueueSnackbar(i18n.t(`companies.detail.image.upload.success`));
      dispatch(fetchCompanyImage(companyId));
    } catch (error) {
      enqueueSnackbar(i18n.t(`companies.detail.image.upload.fail`));
    }
  };

export const changeImage =
  (companyId: number, imageId: number, imageData?: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    try {
      await dispatch(actions[EActions.fetchCompanyImageRequest]());
      if (imageData) {
        await updateCompanyImage(imageId, {
          version: Math.random().toString().substr(2, 10),
          type: "FULL",
          image_data: imageData,
        });
      } else {
        await removeCompanyImage(imageId);
      }
      enqueueSnackbar(
        i18n.t(
          `companies.detail.image.${imageData ? "upload" : "delete"}.success`
        )
      );
      dispatch(fetchCompanyImage(companyId));
    } catch (error) {
      enqueueSnackbar(
        i18n.t(`companies.detail.image.${imageData ? "upload" : "delete"}.fail`)
      );
    }
  };
