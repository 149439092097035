import React, { memo, useCallback, useState, useEffect } from "react";
import Search from "components/search";

type TProps = {
  searchValue?: string;
  onSearch: (e: any) => any;
};

export const UsersSearch = memo(({ searchValue, onSearch }: TProps) => {
  const [search, setSearch] = useState(searchValue);
  const onChange = useCallback((event: any) => {
    setSearch(event.currentTarget.value);
  }, []);

  const onClose = useCallback(() => {
    onSearch("");
    setSearch("");
  }, [onSearch]);

  useEffect(() => {
    setSearch(searchValue);
  }, [searchValue]);

  return (
    <Search
      value={search || ""}
      onSearch={onSearch}
      onChange={onChange}
      onClose={onClose}
    />
  );
});
