import React from "react";
import { makeStyles } from "@mui/styles";
import MaterialIconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    root: {
      color: theme.custom.palette.data,
      background: theme.custom.palette.surface,
      border: "none",
      transitionProperty: "box-shadow",
      width: theme.spacing(7),
      height: theme.spacing(7),
      boxShadow: theme.custom?.boxShadow[3],
      padding: 2,
      "&:hover": {
        background: theme.custom.palette.surface,
      },
      "&:focus": {
        background: theme.custom.palette.surface,
      },
      "&:active, &:focus:active": {
        borderColor: "transparent",
        background: theme.custom.palette.surface,
        boxShadow: theme.custom.boxShadow[1],
      },
      "& img": {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
      },
    },
  };
});

const IconButton = (props: IconButtonProps) => {
  const classes = useStyles(props);
  return <MaterialIconButton className={classes.root} {...props} />;
};

export default IconButton;
