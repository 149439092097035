import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import useData from "dataHooks/eventSounds/groupedDatasets";
import Radio from "components/radio";
import { RadioGroup, Box, TextField, Typography } from "@mui/material";
import { useStyles } from "../components/styles";
import actions from "store/eventSounds/actions";
import { useDispatch } from "react-redux";
import SearchMenu from "components/searchMenu";
import { IDatasetGroup } from "types/eventSounds";

const { setNewName, setExistingName } = actions;

const SelectionName = () => {
  const { t } = useTranslation();
  const { isFetching, groupedDatasets } = useData();
  const [existingNameValue, setExistingNameValue] = useState<any | null>(null);
  const [newNameValue, setNewNameValue] = useState<string | null>(null);
  const classes = useStyles();
  const dispatch: any = useDispatch();

  const radioOptions = useMemo(
    () => [
      {
        value: "newName",
        label: t("form.selectionName.radio.newName.label"),
      },
      {
        value: "existingName",
        label: t("form.selectionName.radio.existingName.label"),
      },
    ],
    [t]
  );

  const [activeRadioOption, setActiveRadioOption] = React.useState(
    radioOptions[0].value
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveRadioOption((event.target as HTMLInputElement).value);
  };
  const existingNameOptions = useMemo(
    () =>
      groupedDatasets && groupedDatasets?.results.length
        ? groupedDatasets!.results.map(
            ({ name, id }: { name: string; id: number }) => ({
              text: name,
              value: id,
            })
          )
        : [],
    [groupedDatasets]
  );

  useEffect(() => {
    if (activeRadioOption === radioOptions[0].value) {
      dispatch(setExistingName(null));
      dispatch(setNewName(newNameValue));
    } else {
      const newValue =
        groupedDatasets &&
        groupedDatasets?.results.length &&
        groupedDatasets!.results.find(
          (dataset: IDatasetGroup) =>
            Number(dataset.id) === Number(existingNameValue)
        );

      dispatch(setExistingName(newValue));
      dispatch(setNewName(null));
    }
  }, [
    dispatch,
    activeRadioOption,
    newNameValue,
    groupedDatasets,
    existingNameValue,
    radioOptions,
  ]);

  return (
    <>
      <RadioGroup value={activeRadioOption} onChange={handleRadioChange}>
        <Radio
          value={radioOptions[0].value}
          activeValue={activeRadioOption}
          label={radioOptions[0].label}
        />
        <Box ml={4} mt={0.5}>
          <Box mb={4} mt={1}>
            <Field
              name="project"
              id="datasetName"
              label={t("form.selectionName.radio.newName.placeholder")}
              render={({ id, label }) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={10}
                  sx={{
                    "& .MuiInputBase-input": {
                      paddingTop: "10.5px",
                      paddingBottom: "10.5px",
                    },
                  }}
                >
                  <Typography style={{ minWidth: "8rem" }}>
                    {t("groupedDataset.modal.groupedDatasetName.placeholder")}:
                  </Typography>
                  <TextField
                    label={label}
                    value={newNameValue}
                    id={id}
                    onChange={({ target }) => setNewNameValue(target.value)}
                    className={classes.inputWrapper}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      className: classes.input,
                    }}
                    style={{ paddingRight: 10 }}
                    required
                    disabled={activeRadioOption !== radioOptions[0].value}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
        <Radio
          value={radioOptions[1].value}
          activeValue={activeRadioOption}
          label={radioOptions[1].label}
          id="testCreateNewName"
        />
        <Box mb={3.5} mt={1.5} ml={4}>
          <Field
            name="existingName"
            id="existingName"
            label={t("form.selectionName.radio.existingName.placeholder")}
            render={({ id, label, meta }) => (
              <SearchMenu
                id={id}
                label={label}
                value={existingNameValue}
                onChange={({ target }) => setExistingNameValue(target.value)}
                options={existingNameOptions ?? []}
                disabled={radioOptions[1].value !== activeRadioOption}
                loading={isFetching}
                required
              />
            )}
          />
        </Box>
      </RadioGroup>
    </>
  );
};

export default SelectionName;
