import * as yup from "yup";
import i18next from "i18next";

type ValueType = string;
type ValueReturnType = string | boolean;
type ValidationFunction = (v: ValueType) => ValueReturnType;

export const validateAll = (val: ValidationFunction[]) => (v: ValueType) =>
  val
    .map((val: ValidationFunction) => val(v))
    .filter((v: ValueReturnType) => v)[0];

export const isRequired = (value: string | number) => {
  try {
    // Check for empty values explicitly
    if (!value) {
      throw new Error(i18next.t("validators.required"));
    }
    yup.mixed().required().validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.required");
  }
};

export const minCharacters = (num: number) => (value: ValueType) => {
  try {
    yup.string().min(num).validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.minCharacters", { num });
  }
};

export const maxCharacters = (num: number) => (value: ValueType) => {
  try {
    yup.string().max(num).validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.maxCharacters", { num });
  }
};

export const isNumber = (value: ValueType) => {
  const v = parseInt(value);
  if (value && (isNaN(v) || v <= 0)) {
    return i18next.t("validators.mustBeNumber");
  }
  return false;
};

export const positiveNumber = (value: ValueType) => {
  try {
    yup.number().positive().validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.positiveInteger");
  }
};

export const minNumber = (num: number) => (value: ValueType) => {
  try {
    yup.number().min(num).validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.minNumber", { num });
  }
};

export const maxNumber = (num: number) => (value: ValueType) => {
  try {
    yup.number().max(num).validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.maxNumber", { num });
  }
};

export const isPhoneNumber = (value: ValueType) => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/;
  try {
    yup.string().matches(regex).validateSync(value);
    return false;
  } catch {
    if (!value?.length) {
      return false;
    }
    return i18next.t("validators.isPhoneNumber");
  }
};

export const isEmail = (value: ValueType) => {
  try {
    yup
      .string()
      .matches(/^((?![À-ž]).)*$/)
      .email()
      .validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.mustBeEmail");
  }
};

export const notNumber = (value: ValueType) => {
  const regex = /[^0-9]/;
  try {
    yup.string().matches(regex).validateSync(value);
    return false;
  } catch {
    return i18next.t("validators.cantBeEntirelyNumeric");
  }
};
