import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IMachineList } from "types/machine";

export default async function getMachine(id: number) {
  const url = endpoints.machines.detail(id);
  try {
    const response = await axiosNeuron.get(url);
    const data: IMachineList = {
      id: response.data.id,
      name: response.data.name,
      subcategory: response.data.subcategory,
      station: response.data.station,
      additional_info: response.data.additional_info,
      qrCode: response.data.qr_code,
      recordLength: response.data.record_length,
      recordingPeriod: response.data.recording_period,
      isActive: response.data.is_active,
      samplingFrequency: response.data.sampling_frequency,
      devices: response.data.devices,
      contactSet: response.data.contact_set,
      recordingStartDatetime: response.data.recording_start_datetime,
      recordingDaysRange: response.data.recording_days_range,
      recordingGateLevel: response.data.recording_gate_level,
      facility: response.data.facility,
      project: response.data.project,
      company: response.data.company,
      isSmsNotifying: response.data.is_sms_notifying,
      isEmailNotifying: response.data.is_email_notifying,
      isAssistedAlerting: response.data.is_assisted_alerting,
      status: response.data.status,
      alerts: response.data.alerts,
      isMaintenance: response.data.is_maintenance,
      isBroken: response.data.is_broken,
      isNswMaintenance: response.data.is_nsw_maintenance,
      isAnyMaintenance: response.data.is_any_maintenance,
      isNominalSound: response.data.is_nominal_sound,
      dashboards: response.data.dashboards,
      manufacturer: response.data.manufacturer,
      product_name: response.data.product_name,
      commissioning: response.data.commissioning,
      power: response.data.power,
      machine_parts: response.data.machine_parts,
      operational_information: response.data.operational_information,
      suppressNotificationsUntil: response.data.suppress_notifications_until,
      isGeneratingDashboards: response.data.is_generating_dashboards,
      isUsingEdgeLoudnessModel: response.data.is_using_edge_loudness_model,
      isUsingEdgeModels: response.data.is_using_edge_models,
      trainNonConnectedPlacements: response.data.train_non_connected_placements,
      timeZone: response.data.time_zone,
      productionMode: response.data.production_mode,
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
