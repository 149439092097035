import { AuthActionTypes, AuthState, EActions } from "store/auth/types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import authHandler from "localStorage/authHandler";

const initialState: AuthState = {
  user: authHandler.hasToken() ? undefined : null,
  errors: [],
  loading: false,
  expiredToken: false,
};

const reducer = {
  [EActions.setAuth]: (
    { payload: { user } }: AuthActionTypes,
    { grafanaUrl }: AuthState
  ) => ({
    user: user,
    errors: [],
    loading: false,
    grafanaUrl: !user ? undefined : grafanaUrl,
  }),
  [EActions.setAuthFail]: ({ payload: { errors } }: AuthActionTypes) => ({
    user: null,
    errors: errors,
    loading: false,
  }),
  [EActions.setExpiredToken]: ({
    payload: { expiredToken },
  }: AuthActionTypes) => ({
    expiredToken,
  }),
  [EActions.setLoading]: ({ payload: { loading } }: AuthActionTypes) => ({
    loading,
  }),
  [EActions.setGrafanaUrl]: ({ payload: { grafanaUrl } }: AuthActionTypes) => ({
    grafanaUrl,
  }),
  [EActions.closeGrafanaModal]: () => ({
    isOldGrafana: false,
  }),
};

export default createReducer(path, initialState, reducer);
