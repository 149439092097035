import React from "react";
import { makeStyles } from "@mui/styles";
import ModalHeader from "./desktopHeader";
import { StepType } from "components/stepModal";
import Footer from "./desktopFooter";

const useStyles = makeStyles((theme: any) => ({
  content: {
    flexGrow: 1,
    overflowY: "scroll",
    padding: theme.spacing(4),
    fontFamily: theme.custom.typography.fontFamily,
    display: "flex",
    flexDirection: "column",
  },
}));

type Props<T> = {
  steps: StepType[];
  activeStep: number;
  finishButtonDataTestId?: string;
  finishButtonLabel: string;
  onNext: () => void;
  goToNextStep: () => void;
  handleActive: (id: number) => void;
  onCancel: () => void;
  handleFinish?: () => void;
  setData: (data?: T) => void;
  data?: T;
  isFormValid?: boolean;
};

const DesktopView = <T extends {}>({
  steps,
  activeStep,
  handleActive,
  onCancel,
  onNext,
  goToNextStep,
  finishButtonDataTestId,
  finishButtonLabel,
  handleFinish,
  setData,
  data,
  isFormValid,
}: Props<T>) => {
  const classes = useStyles();

  const Content = steps[activeStep].Component;

  const onSuccessHandle = (data: T) => {
    setData(data);
  };
  return (
    <>
      <ModalHeader
        steps={steps}
        activeStep={activeStep}
        handleActive={handleActive}
      />
      <div className={classes.content}>
        <Content
          onSuccess={onSuccessHandle}
          id={steps[activeStep].formId}
          data={data}
        />
      </div>
      <Footer
        steps={steps}
        activeStep={activeStep}
        onCancel={onCancel}
        handleNext={onNext}
        finishButtonDataTestId={finishButtonDataTestId}
        finishButtonLabel={finishButtonLabel}
        handleFinish={handleFinish}
        isFormValid={isFormValid}
      />
    </>
  );
};

export default DesktopView;
