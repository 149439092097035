import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import dayjs from "dayjs";

type TParams = {
  id: number;
  machine: number;
  start?: {
    type: string;
    created_at: string;
    note: string;
    meta?: {
      purpose: string;
    };
    placements?: number[];
  };
  end?: {
    type: string;
    created_at: string;
    note: string;
  };
};

export default async function patchPairEvent(event: TParams) {
  const { id, ...rest } = event;
  const url = endpoints.events.detail(id);

  if (event.end?.created_at) {
    const date = dayjs(event.end.created_at);
    if (date.second() === 0 && date.millisecond() === 0) {
      event.end.created_at = date
        .add(-1, "millisecond")
        .format("YYYY-MM-DD HH:mm:ss.SSSZ");
    }
  }

  try {
    const response = await axiosNeuron.patch(`${url}pair/`, rest);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
