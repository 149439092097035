import { memo, useMemo, useRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Content from "components/layout/content";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import Spinner from "components/spinner";
import { createColumns } from "./columns";
import { createTinyColumns } from "./tinyColumns";
import Heading from "components/typography/heading/heading2";
import useData from "dataHooks/devices/detail/detailList";
import { DeleteDeviceModal } from "components/devices/modal/delete";
import appUrls from "shared/appUrls";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import ChooseProjectModal from "components/devices/list/cells/chooseProjectModal";
import Buttons from "./buttons";
import Footer from "./footer";
import RecordingPlans from "./RecordingPlans";
import { useSelector } from "react-redux";
import { AppState } from "store";
import isEqual from "react-fast-compare";

export const ChannelsList = memo(
  () => {
    const theme = useTheme();
    const { id } = useParams<{ id?: string }>();
    const deviceId = Number(id);
    const { t } = useTranslation();
    const history = useNavigate();
    const showMoreClickedTimesRef = useRef(0);
    const [modal, setModal] = useState<string | null>(null);
    const upMd = useMediaQuery(theme.breakpoints.up("lg"), { noSsr: true });

    const { loading, channels } = useData(deviceId);

    const disabledDelete = usePermissions([
      EPermissions.DEACTIVATE_ANY_DEVICE,
      EPermissions.DEACTIVATE_OWN_DEVICE,
    ]);

    const canUpdate = usePermissions([
      EPermissions.UPDATE_ANY_CHANNEL,
      EPermissions.UPDATE_OWN_CHANNEL,
    ]);

    const { device } = useSelector(
      (state: AppState) => state.deviceDetail.detail,
      isEqual
    );

    const allowSettingGain =
      device.type && device.type.split(" ")[0] === "nBox";

    const columns = useMemo(() => {
      const column = upMd ? createColumns : createTinyColumns;
      return column(t, canUpdate, allowSettingGain);
    }, [canUpdate, t, upMd, allowSettingGain]);

    const closeModal = useCallback(() => {
      setModal(null);
    }, []);

    const openModal = useCallback((event: any) => {
      if (event.currentTarget.id) {
        setModal(event.currentTarget.id);
      }
    }, []);

    const redirectToList = useCallback(() => {
      history(appUrls.devices.index());
    }, [history]);

    return (
      <>
        <Content>
          <Box mx={-1} pb={2}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {!channels.length ? (
                  <Box p={5} display="flex" justifyContent="center">
                    <Heading>{t("devices.detail.noChannels")}</Heading>
                  </Box>
                ) : (
                  <Table
                    showMoreClickedTimesRef={showMoreClickedTimesRef}
                    hidePagination
                    data={channels}
                    rowsCount={channels?.length}
                    columns={columns}
                    hiddenColumns={allowSettingGain || !upMd ? [] : ["gain"]}
                    isLoading={loading}
                    RowComponent={Row}
                    RowComponentProps={{
                      rowHeight: upMd ? theme.spacing(7) : undefined,
                    }}
                    HeaderComponent={Header}
                    HeaderComponentProps={{ hide: upMd ? false : true }}
                  />
                )}
              </>
            )}
          </Box>
          <RecordingPlans disabled={!canUpdate} />
          <Buttons disabledDelete={disabledDelete} openModal={openModal} />
        </Content>
        {modal === "moveButton" && (
          <ChooseProjectModal
            deviceId={deviceId}
            closeModalMove={closeModal}
            modalMoveTo={true}
            detail={true}
          />
        )}
        {modal === "deleteButton" && (
          <DeleteDeviceModal
            id={deviceId}
            closeModal={closeModal}
            onSuccess={redirectToList}
          />
        )}
        <Footer loading={loading} />
      </>
    );
  }
  // (prev, next) => prev.deviceId === next.deviceId
);
