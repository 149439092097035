import {
  EActions,
  TReducers,
  DeviceDetailState,
  OChannelsList,
  OPlacementsList,
} from "./types";
import { createReducer } from "store/helpers";

import uniqBy from "lodash/uniqBy";
import { path } from "./actions";

const initialState: DeviceDetailState = {
  channels: [],
  controlObject: {},
  disconnected: null,
  initMachines: [],
  placements: [],
  connectableMachines: [],
  machinesLoading: "",
  machines: [],
  loading: false,
  sensorTypes: [],
};

const reducers: TReducers = {
  [EActions.setInitialData]: ({
    payload: {
      initialData: { placements, channels, machines, sensors },
    },
  }) => ({
    placements,
    channels,
    initialPlacements: placements,
    initialChannels: channels,
    machines,
    sensorTypes: sensors,
    loading: false,
  }),
  [EActions.setThreshold]: (
    { payload: { placementId, running_threshold } },
    { placements }
  ) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, running_threshold };
      }
      return placement;
    }),
  }),
  [EActions.setRPM]: ({ payload: { placementId, rpm } }, { placements }) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, rpm };
      }
      return placement;
    }),
  }),
  [EActions.setRPMAuto]: (
    { payload: { placementId, is_rpm_automatically_detected } },
    { placements }
  ) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, is_rpm_automatically_detected };
      }
      return placement;
    }),
  }),
  [EActions.setBandAlerting]: (
    { payload: { placementId, band_alerting } },
    { placements }
  ) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, band_alerting };
      }
      return placement;
    }),
  }),
  [EActions.setPlacementRecordingPlan]: (
    { payload: { placementId, recording_plan } },
    { placements }
  ) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, recording_plan };
      }
      return placement;
    }),
  }),
  [EActions.setGain]: ({ payload: { channelId, gain } }, { channels }) => ({
    channels: channels?.map((channel: OChannelsList) => {
      if (channel.id === channelId) {
        return { ...channel, gain };
      }
      return channel;
    }),
  }),
  [EActions.setPlacement]: (
    { payload: { channelId, placementId } },
    { channels }
  ) => {
    return {
      channels: channels?.map((channel: OChannelsList) => {
        if (channel.id === channelId) {
          return {
            ...channel,
            placement: placementId,
          };
        }
        return channel;
      }),
    };
  },
  [EActions.setMachine]: (
    { payload: { channelId, machineId } },
    { channels }
  ) => ({
    channels: channels?.map((channel: OChannelsList) => {
      if (channel.id === channelId) {
        return { ...channel, machine: machineId, placement: null };
      }
      return channel;
    }),
  }),
  [EActions.setSensorType]: (
    { payload: { channelId, sensor_type } },
    { channels }
  ) => ({
    channels: channels?.map((channel: OChannelsList) => {
      if (channel.id === channelId) {
        return { ...channel, sensor_type };
      }
      return channel;
    }),
  }),
  [EActions.setEnabled]: (
    { payload: { placementId, is_enabled } },
    { placements }
  ) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, is_enabled };
      }
      return placement;
    }),
  }),
  [EActions.setISOGroup]: (
    { payload: { placementId, iso_group } },
    { placements }
  ) => ({
    placements: placements?.map((placement: OPlacementsList) => {
      if (placement.id === placementId) {
        return { ...placement, iso_group };
      }
      return placement;
    }),
  }),
  [EActions.disconnectPlacement]: (
    { payload: { channelId } },
    { channels }
  ) => ({
    channels: channels?.map((channel: OChannelsList) => {
      if (channel.id === channelId) {
        return {
          ...channel,
          machine: null,
          placement: null,
        };
      }
      return channel;
    }),
  }),
  [EActions.setLoading]: ({ payload: { loading } }) => ({
    loading,
  }),
  [EActions.resetChanges]: (_, { initialChannels, initialPlacements }) => ({
    channels: initialChannels,
    placements: initialPlacements,
    recordingPlan: undefined,
  }),
  [EActions.setDisconnected]: ({ payload: { id } }) => ({
    disconnected: id,
  }),
  [EActions.setSensorTypes]: ({ payload: { sensorTypes } }) => ({
    sensorTypes,
  }),
  [EActions.setInitMachines]: ({ payload: { initMachines } }) => ({
    initMachines,
  }),
  [EActions.placements]: ({ payload: { placements } }, state) => ({
    placements: uniqBy(
      [...(state.placements || []), ...placements],
      (i: any) => i.id
    ),
  }),
  [EActions.connectableMachines]: ({ payload: { connectableMachines } }) => ({
    connectableMachines,
  }),
  [EActions.connectableLoading]: ({ payload: { machinesLoading } }) => ({
    machinesLoading,
  }),
  [EActions.setMachines]: ({ payload: { machines } }, state) => ({
    machines: uniqBy([...state?.machines!, ...machines], (i: any) => i.id),
  }),
  [EActions.setRecordingPlans]: ({ payload: { recordingPlans } }) => ({
    recordingPlans,
  }),
  [EActions.setRecordingPlan]: ({ payload: { recordingPlan } }) => ({
    recordingPlan,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};

export default createReducer(path, initialState, reducers);
