import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import MaterialCheckbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles((theme: any) => ({
  label: {
    fontSize: (props: Props) =>
      props.fontSize === "large"
        ? theme.custom?.typography.fontSize[24]
        : theme.custom?.typography.fontSize[16],
    letterSpacing: (props: Props) =>
      theme.custom?.typography.letterSpacing[
        props.letterSpacing ? props.letterSpacing : "medium"
      ],
    fontWeight: (props: Props) =>
      theme.custom?.typography.fontWeight[
        props.fontWeight ? props.fontWeight : "medium"
      ],
    color: (props: Props) =>
      props.customcolor ? props.customcolor : theme.custom?.palette.data,
  },
}));

export const BlackCheckbox = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    color: (props: Props) =>
      props.customcolor ? props.customcolor : theme.custom?.palette.data,
    "&:hover": {
      background: "initial",
    },
  },
  checked: {
    color: (props: Props) => (props.customcolor ? props.customcolor : "black"),
  },
}))((props: CheckboxProps) => <MaterialCheckbox color="default" {...props} />);

interface Props {
  label?: string;
  icon?: any;
  fontSize?: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  customcolor?: string;
  fontWeight?: "normal" | "medium" | "semiBold" | "bold";
  letterSpacing?: "small" | "medium" | "big";
}

const Checkbox = (props: Props) => {
  const {
    label,
    icon,
    checked,
    onChange,
    disabled,
    customcolor: customColor,
  } = props;
  const classes = useStyles(props);

  return (
    <FormControlLabel
      control={
        <BlackCheckbox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          customcolor={customColor}
        />
      }
      label={
        label && (
          <span className={classes.label}>
            {icon && icon}
            {label}
          </span>
        )
      }
    />
  );
};

export default Checkbox;
