import { useCallback } from "react";
import Button from "components/button";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import actions from "store/deviceDetail/channelsList/actions";

type TProps = {
  cell: {
    value: {
      id: number;
      placement: number;
      disabled: boolean;
      alignLeft?: boolean;
    };
  };
};

const DisconnectButton = (props: TProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const { disabled, placement, id, alignLeft } = props.cell.value;

  const onDisconnectPlacement = useCallback(() => {
    dispatch(actions.disconnectPlacement(id));
  }, [dispatch, id]);

  return (
    <>
      <Box
        display="flex"
        justifyContent={alignLeft ? "flex-start" : "flex-end"}
      >
        {placement ? (
          <Button
            onClick={onDisconnectPlacement}
            disabled={disabled}
            color="secondary"
          >
            {t("devices.detail.list.cell.disconnect")}
          </Button>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default DisconnectButton;
