import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function getDeviceStatuses() {
  const url = endpoints.devices.statuses;
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(url);
    return results;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
