import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { useMutation } from "react-query";
import patchMachine from "api/handlers/machine/patchMachine";
import { useDispatch } from "react-redux";
import { fetchMachineDetail } from "store/machineDetail/actions";

import Box from "@mui/material/Box";
import Divider from "components/typography/heading/divider";
import Button from "components/button/index";
import { Field, Form } from "react-final-form";
import Select from "components/select";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Spinner from "components/spinner";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import { refetchMachinesSilent } from "store/machinesList/actions";

interface Props {
  disabled: boolean;
  id: number;
  suppressNotificationsUntil: string | null;
}

const AlertingDelay: React.FC<Props> = memo(
  ({ disabled, suppressNotificationsUntil, id }) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();

    const [formActive, setFormActive] = React.useState<boolean>(false);

    const toggleFormActive = () => {
      setFormActive((prev: any) => !prev);
    };

    const [patchMachineMutation, { status }] = useMutation(patchMachine);

    const data = [1, 2, 3, 4, 8, 24, 48, 72, 120, 876000].map(
      (hourOption: any) => ({
        text:
          hourOption === 876000
            ? t("machine.detail.alertingDelay.forever")
            : t("machine.detail.alertingDelay.hours", { hourOption }),
        value: hourOption,
      })
    );

    const onSubmit = ({ hours }: { hours: number }) => {
      const suppressNotificationsUntil = moment(new Date()).add(hours, "hours");
      patchMachineMutation(
        {
          id,
          payload: {
            suppress_notifications_until: suppressNotificationsUntil.format(),
          },
        },
        {
          onSuccess: () => {
            dispatch(fetchMachineDetail(id));
            dispatch(refetchMachinesSilent());
            toggleFormActive();
          },
        }
      );
    };

    const suppressNotificationsUntilAsDate = suppressNotificationsUntil
      ? new Date(suppressNotificationsUntil)
      : undefined;

    return (
      <>
        <Divider line>{t("machine.detail.alertingDelay.title")}</Divider>
        {status === "loading" ? (
          <Spinner />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              {suppressNotificationsUntilAsDate &&
              moment(suppressNotificationsUntilAsDate).isAfter(moment()) ? (
                moment(suppressNotificationsUntilAsDate).isAfter(
                  moment("2120-01-01")
                ) ? (
                  <Box mb={2}>
                    {t("machine.detail.alertingDelay.snoozedForever")}
                  </Box>
                ) : (
                  <Box mb={2}>
                    {t("machine.detail.alertingDelay.snoozedUntil", {
                      time: `${dateToDDMMYYYY(
                        suppressNotificationsUntilAsDate
                      )} ${dateToHHMM(suppressNotificationsUntilAsDate)}`,
                      interpolation: { escapeValue: false },
                    })}
                  </Box>
                )
              ) : null}
              {!formActive ? (
                <Button
                  color="secondary"
                  onClick={toggleFormActive}
                  disabled={disabled}
                >
                  {t("machine.detail.alertingDelay.setSnooze")}
                </Button>
              ) : (
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="hours"
                        render={({ input, meta }) => (
                          <Select
                            id="hours"
                            label={t("machine.detail.alertingDelay.snoozeFor")}
                            disabled={disabled}
                            input={input}
                            meta={meta}
                            name="hours"
                            options={data}
                          />
                        )}
                      />
                      <Box mt={2} display="flex">
                        <Button color="primary" onClick={handleSubmit}>
                          {t("save")}
                        </Button>
                        <Box ml={2}>
                          <Button color="secondary" onClick={toggleFormActive}>
                            {t("cancel")}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                />
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);

export default AlertingDelay;
