import React from "react";
import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";
import LabelAndTag from "components/users/list/cells/labelAndTag";
import Label from "components/users/list/cells/label";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: IMuiTheme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2, 0),
    },
    label: {
      fontSize: theme.custom.typography.fontSize[12],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.label,
    },
    middleSection: {
      margin: theme.spacing(2, 0),
    },
  };
});
const CombinedCell = <T extends Object>(props: CellProps<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <LabelAndTag {...props} />
      <div className={classes.middleSection}>
        <Label {...props} />
        <div className={classes.label}>
          {t("projects.detail.modal.reports.send.email").toLocaleLowerCase()}
        </div>
      </div>
    </div>
  );
};

export default CombinedCell;
