import { TokenActionTypes, TokenState, EActions } from "store/token/types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: TokenState = {
  error: [],
  loading: false,
};

const reducer = {
  [EActions.getTokenRequest]: () => ({
    errors: [],
    loading: true,
  }),
  [EActions.getTokenSuccess]: () => ({
    loading: false,
  }),
  [EActions.getTokenFail]: ({ payload: { error } }: TokenActionTypes) => ({
    error,
    loading: false,
  }),
};

export default createReducer(path, initialState, reducer);
