import React, { memo } from "react";
import isEqual from "react-fast-compare";
import { PlacementType } from "./labelComponent";
import Loading from "components/loading";
import NoAudio from "./noAudio";
import actions from "store/machineDetail/analysisSounds/actions";
import { useDispatch } from "react-redux";
import Header from "./placementName";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

type TProps = {
  placement: PlacementType;
  loadingBuffer: boolean;
  binBuffer: any;
};

const PlacementLoading = memo(
  ({ placement, loadingBuffer, binBuffer }: TProps) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();

    if (loadingBuffer) {
      dispatch(actions.setIsAnalysed(false));
    } else {
      dispatch(actions.setIsAnalysed(true));
    }

    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Header placement={placement} />
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="center"
        >
          {loadingBuffer && (
            <Loading data-cy="audioLoading" title={t("loadingData")} />
          )}
          {!loadingBuffer && !binBuffer && (
            <NoAudio title={t("taggingapp.audio.notFound")} />
          )}
        </Box>
      </Box>
    );
  },
  isEqual
);

export default PlacementLoading;
