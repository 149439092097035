import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import getFacility from "api/handlers/facility/getFacility";
import updateFacility from "api/handlers/facility/updateFacility";
import { TFacilityPayload } from "types/facility";
import getProject from "api/handlers/project/getProject";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";

export const path = "facilityDetail/facility";

const actionData = [
  [EActions.setLoading, "loading"],
  [EActions.setFacility, "facility"],
  [EActions.setReset],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const fetchFacility =
  (id: number) => async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    try {
      const facility = await getFacility(id);
      const project = await getProject(facility?.project!);
      dispatch(
        actions[EActions.setFacility]({
          ...facility,
          company: project?.company,
        })
      );
    } catch (err) {
      return;
    }
  };

export const patchFacility =
  (id: number, payload: TFacilityPayload) =>
  async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    try {
      const facility = await updateFacility({ id, ...payload });
      dispatch(actions[EActions.setFacility](facility));
      enqueueSnackbar(i18n.t("facilities.detail.success"));
    } catch (err) {
      enqueueSnackbar(i18n.t("facilities.detail.error"));
      return;
    }
  };
