import { SortingRule } from "react-table";
import { IReportsList } from "types/report";
import { ResponseType } from "types/response";
export enum EActions {
  fetchReportsRequest = "fetchReportsRequest",
  fetchReportsFail = "fetchReportsFail",
  fetchReportsSuccess = "fetchReportsSuccess",
  fetchReportsParams = "fetchReportsParams",
  setReset = "setReset",
  setPage = "setPage",
  setMore = "setMore",
  setModal = "setModal",
  setRecommendations = "setRecommendations",

  setCreatedFor = "setCreatedFor",
  setRecommendationLengthError = "setRecommendationLengthError",
  setError = "setError",

  fetchFilteredReportsRequest = "fetchFilteredReportsRequest",
  fetchFilteredReportsFail = "fetchFilteredReportsFail",
  fetchFilteredReportsSuccess = "fetchFilteredReportsSuccess",
}

export interface IReportsState {
  reportsData?: ResponseType<IReportsList> | null;
  isFetching: boolean;
  error?: Error | null;
  pageIndex: number;
  more: number;
  modalOpened: boolean;
  recommendations?: string;
  createdFor?: string;
  pickerError: string | null;
  recommendationLengthError: string | null;
  filteredReports: ResponseType<IReportsList> | null;
  filterLoading: boolean;
  reportsFetchParams: IFetchReportsParams | null;
}

export interface IReportsAction {
  type: typeof EActions;
  payload: IReportsState;
}

export interface IFetchReportsParams {
  project: number;

  page: number;
  ps: number;
  order_by: SortingRule<string>[];
}
