import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface IProps {
  id: number;
  name?: string;
  users?: number[];
  service_subject?: string;
  is_temporary_access_token_auth_enabled?: boolean;
}

export default async function patchProject({
  id,
  name,
  users,
  service_subject,
  is_temporary_access_token_auth_enabled,
}: IProps) {
  const payload = {
    name,
    users,
    service_subject,
    is_temporary_access_token_auth_enabled,
  };
  const url = endpoints.projects.detail(id);

  try {
    const response = await axiosNeuron.patch(url, payload);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
