import { EActions, IProjectState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  error: [],
  isFetching: false,
  faults: null,
  initialFaults: null,
  isSuccessfull: false,
  isSaving: false,
};

const updateFault = (
  state: IProjectState,
  id: number | undefined,
  update: any
) => ({
  ...state.faults,
  results: state.faults?.results.map((item: any) => {
    if (item.id === id) {
      return { ...item, ...update };
    }
    return item;
  }),
});

const reducer = {
  [EActions.getFaultsRequest]: () => ({
    isFetching: true,
  }),
  [EActions.getFaultsSuccess]: ({ payload }: any) => ({
    faults: payload.faults,
    initialFaults: payload.faults,
    isFetching: false,
  }),
  [EActions.getFaultsFail]: ({ payload: { error } }: any) => ({
    error,
    isFetching: false,
  }),

  [EActions.setFaults]: ({ payload }: IActionTypes) => ({
    faults: payload.faults,
    initialFaults: payload.faults,
    isFetching: false,
    reloading: false,
    editing: null,
  }),
  [EActions.setDiscard]: (_: IActionTypes, state: IProjectState) => ({
    faults: { ...state.initialFaults },
  }),

  [EActions.setType]: (
    { payload: { fault, type } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { type }),
  }),
  [EActions.setSignalProcessing]: (
    { payload: { fault, signal_processing } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { signal_processing }),
  }),
  [EActions.setDescription]: (
    { payload: { fault, description } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { description }),
  }),
  [EActions.setRPM]: (
    { payload: { fault, rpm } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { rpm }),
  }),
  [EActions.setRPMAuto]: (
    { payload: { fault, is_rpm_automatically_detected } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, {
      is_rpm_automatically_detected,
    }),
  }),
  [EActions.setBPFI]: (
    { payload: { fault, bpfi } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { bpfi }),
  }),
  [EActions.setBPFO]: (
    { payload: { fault, bpfo } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { bpfo }),
  }),
  [EActions.setBFS]: (
    { payload: { fault, bfs } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { bfs }),
  }),
  [EActions.setFTF]: (
    { payload: { fault, ftf } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { ftf }),
  }),
  [EActions.setThreshold]: (
    { payload: { fault, threshold } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { threshold }),
  }),
  [EActions.setCoefficient]: (
    { payload: { fault, coefficient } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { coefficient }),
  }),
  [EActions.setNotification]: (
    { payload: { fault, band_alerting } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, { band_alerting }),
  }),
  [EActions.setFrequencyEnvelopingStart]: (
    { payload: { fault, frequency_for_enveloping_start } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, {
      frequency_for_enveloping_start,
    }),
  }),
  [EActions.setFrequencyEnvelopingEnd]: (
    { payload: { fault, frequency_for_enveloping_end } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: updateFault(state, fault, {
      frequency_for_enveloping_end,
    }),
  }),
  [EActions.removeFault]: (
    { payload: { fault } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: {
      ...state.faults,
      results: state.faults?.results.filter((item: any) => item.id !== fault),
    },
  }),
  [EActions.addFault]: (
    { payload: { placement } }: IActionTypes,
    state: IProjectState
  ) => ({
    faults: {
      ...state.faults,
      results: [
        ...state.faults?.results,
        {
          id: `new_id_${uuidv4()}`,
          placement: placement,
          type: null,
          frequency_for_enveloping_start: "500",
          frequency_for_enveloping_end: "12000",
          rpm: null,
          coefficient: null,
          is_rpm_automatically_detected: false,
          band_alerting: "off",
          description: null,
          ftf: null,
          bfs: null,
          bpfi: null,
          bpfo: null,
          signal_processing: null,
          threshold: null,
        },
      ],
    },
  }),
  [EActions.updateSuccessfull]: ({ payload: { isSuccessfull } }: any) => ({
    isSuccessfull: isSuccessfull,
  }),
  [EActions.setIsSaving]: ({ payload: { isSaving } }: any) => ({
    isSaving: isSaving,
  }),
};

export default createReducer(path, initialState, reducer);
