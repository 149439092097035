import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    root: {
      background: theme.custom.palette.shade,
      height: 0,
      margin: theme.spacing(0, -20),

      [theme.breakpoints.up("sm")]: {
        height: 1,
      },
    },
  };
});

const HorizontalLine: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.root} />;
};

export default HorizontalLine;
