import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ICompanyList } from "types/company";
import { ResponseType } from "types/response";
import { SortingRule } from "react-table";
import { resolveSorting } from "shared/helpers";

type Params = {
  ids?: number[];
  userIds?: number[];
  page?: number;
  pageSize?: number;
  orderBy?: SortingRule<number>[];
  q?: string;
  ref?: string[];
  nextUrl?: string;
  field?: string[];
};
interface Response {
  id: number;
  users: number[] | [];
  projects: number[] | [];
  all_users: number[] | [];
  name: string;
  email: string;
  hubspot_id: number | null;
  loading?: boolean;
  ts?: boolean;
  error?: boolean;
}

export default async function getCompanies({
  ids,
  page,
  pageSize,
  orderBy,
  q,
  ref,
  userIds,
  nextUrl,
  field,
}: Params) {
  const queryParams = {
    ps: pageSize,
    order_by: resolveSorting("name", orderBy),
    page,
    q: q?.length ? q : undefined,
    ref: ref && q ? ref.join(",") : undefined,
    user: userIds,
    field,
    is_archived: "false",
  };

  if (ids && !ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<ICompanyList>;
  }

  const url = endpoints.companies.default;
  const idsFilter = ids ? `&id=${ids.join("&id=")}` : "";

  try {
    const response = await axiosNeuron.get(
      nextUrl || `${url}?${idsFilter}`,

      {
        params: queryParams,
      }
    );
    const data: ResponseType<Response> = response.data;
    return {
      ...data,
      results: data.results.map((result: any) => ({
        id: result.id,
        users: result.users,
        projects: result.projects,
        allUsers: result.all_users,
        name: result.name,
        email: result.email,
        hubspotId: result.hubspot_id,
      })),
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
