import { ICustomModel } from "types/customModels";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, ICustomModelsAction } from "./types";
import { AppState } from "store";
import getCustomModelOverview from "api/handlers/customModels/getCustomModelOverview";
import getDatasetGroups from "api/handlers/eventSounds/getDatasetGroups";
import getCustomModels from "api/handlers/customModels/getCustomModels";
import getMachines from "api/handlers/machine/getMachines";
import { getPlacements } from "api/handlers/placement/getPlacements";
import i18n from "localization";
export const path = "customModels";

const pairs = [
  [EActions.fetchCustomModelsOverviewRequest],
  [EActions.fetchCustomModelsOverviewSuccess, "customModels"],
  [EActions.fetchCustomModelsOverviewFail, "error"],
  [EActions.fetchCustomModelsRequest],
  [EActions.fetchCustomModelsSuccess, "globalCustomModels"],
  [EActions.fetchCustomModelsFail, "error"],
];

const actions = prepareActions<ICustomModelsAction, EActions>(pairs, path);

export default actions;

export const fetchCustomModelOverview =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCustomModelsOverviewRequest]());
    try {
      const result = await getCustomModelOverview(id);
      const datasetIds = result.map((item: ICustomModel) => item.dataset_group);
      const datasets = await getDatasetGroups({ ids: datasetIds, page: 1 });

      const customModelResult = result.map((item: any) => {
        const datasetValue = datasets?.results.find(
          (dataset: any) => dataset.id === item.dataset_group
        );
        return {
          ...item,
          datasetName: datasetValue ? datasetValue.name : null,
        };
      });

      if (customModelResult.length) {
        dispatch(
          actions[EActions.fetchCustomModelsOverviewSuccess](customModelResult)
        );
      }
    } catch (error) {
      dispatch(actions[EActions.fetchCustomModelsOverviewFail](error));
    }
  };

export const fetchCustomModels =
  ({
    from,
    to,
    customModelCategory,
    status,
    modelArchived,
    datasetName,
    datasetId,
    machineName,
    deploymentEnvironment,
  }: {
    from?: Date | null;
    to?: Date | null;
    customModelCategory?: string | null;
    status?: string | null;
    modelArchived?: string | null;
    datasetName?: string | null;
    datasetId?: string | number[] | null;
    machineName?: string | null;
    deploymentEnvironment?: string | null;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCustomModelsRequest]());
    try {
      const datasetGroupIds =
        typeof datasetId === "string" && datasetId
          ? datasetId.split(",").map((item: string) => Number(item))
          : datasetId;

      const customModels = await getCustomModels({
        from,
        to,
        customModelCategory,
        status,
        modelArchived,
        datasetName,
        datasetId: datasetGroupIds,
        machineName,
        deploymentEnvironment,
      });
      const datasetIds = customModels?.results
        .map((item: ICustomModel) => item.dataset_group)
        .filter(Boolean);

      const consumers = customModels?.results
        .flatMap((item: ICustomModel) =>
          item.deployments?.length
            ? item.deployments.map((deployment) => deployment.consumer)
            : []
        )
        .filter(Boolean);

      const machineIds = consumers.map((item: any) => item?.machine);
      const placementIds = consumers.flatMap((item: any) => item.placements);
      const machines = await getMachines({ ids: machineIds });
      const placements = placementIds.length
        ? await getPlacements({
            id: placementIds,
          })
        : null;

      const datasets = datasetIds.length
        ? await getDatasetGroups({
            ids: datasetIds,
            page: 1,
            pageSize: 999,
          })
        : null;

      const customModelResult = {
        ...customModels,
        results: customModels.results.map((item: any) => {
          const datasetValue =
            datasets &&
            datasets?.results.find(
              (dataset: any) =>
                Number(dataset.id) === Number(item.dataset_group)
            );
          return {
            ...item,
            datasetName: datasetValue ? datasetValue.name : null,
            datasetModifiedAt: datasetValue ? datasetValue.modified_at : null,
          };
        }),
      };

      const modifedCustomModels = {
        ...customModelResult,
        results: customModelResult.results.map((item: ICustomModel) => {
          return {
            ...item,
            deployments: item?.deployments?.length
              ? item.deployments.map((deployment: any) => {
                  const machine =
                    deployment.consumer &&
                    machines?.results.find(
                      (machineItem: any) =>
                        Number(machineItem.id) ===
                        Number(deployment.consumer.machine)
                    );
                  const placementList =
                    deployment.consumer &&
                    deployment.consumer.placements?.length &&
                    placements
                      ? deployment.consumer.placements.map(
                          (placement: number) => {
                            const findPlacement = placements?.results?.find(
                              (pl: any) => Number(pl.id) === Number(placement)
                            );
                            return {
                              name: i18n.t(
                                `placementType.${findPlacement.name}`
                              ),
                              id: findPlacement.id,
                            };
                          }
                        )
                      : [];

                  return {
                    ...deployment,
                    consumer: deployment.consumer && {
                      ...deployment.consumer,
                      machineName: machine?.name,
                      placementList,
                    },
                  };
                })
              : [],
          };
        }),
      };

      dispatch(actions[EActions.fetchCustomModelsSuccess](modifedCustomModels));
    } catch (error) {
      dispatch(actions[EActions.fetchCustomModelsFail](error));
    }
  };
