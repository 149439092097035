import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import getReports from "api/handlers/reports/getReports";
import { EActions, IReportsAction } from "./types";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import addReport from "api/handlers/reports/addReports";
import patchReport from "api/handlers/reports/patchReport";
import getFilteredReports from "api/handlers/reports/getFilteredReports";
import generateReport from "api/handlers/reports/generateReport";
import sendReport from "api/handlers/reports/sendReport";

export const path = "projectDetail/reports";

const actionData = [
  [EActions.fetchReportsRequest],
  [EActions.fetchReportsFail, "error"],
  [EActions.fetchReportsSuccess, "reportsData"],
  [EActions.setPage, "pageIndex"],
  [EActions.setMore, "more"],
  [EActions.setModal, "modalOpened"],
  [EActions.setReset],
  [EActions.setRecommendations, "recommendations"],
  [EActions.setCreatedFor, "createdFor"],
  [EActions.setRecommendationLengthError, "recommendationLengthError"],
  [EActions.setError, "pickerError"],

  [EActions.fetchFilteredReportsRequest],
  [EActions.fetchFilteredReportsFail, "error"],
  [EActions.fetchFilteredReportsSuccess, "filteredReports"],
  [EActions.fetchReportsParams, "reportsFetchParams"],
];

const actions = prepareActions<IReportsAction, EActions>(actionData, path);
export default actions;

export const fetchReports =
  (params: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchReportsRequest]());
    try {
      const reports = await getReports(params);

      dispatch(actions[EActions.fetchReportsParams](params));
      dispatch(actions[EActions.fetchReportsSuccess](reports));
    } catch (error) {
      dispatch(actions[EActions.fetchReportsFail](error));
    }
  };

export const refetchReportsSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { reportsFetchParams } = getState().projectDetail.reports;

      const reports = await getReports(reportsFetchParams);

      dispatch(actions[EActions.fetchReportsSuccess](reports));
    } catch (error) {
      dispatch(actions[EActions.fetchReportsFail](error));
    }
  };

export const fetchFilteredReports =
  ({ createdFor }: { createdFor: string }) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { project } = getState().projectDetail.project;

    dispatch(actions[EActions.fetchFilteredReportsRequest]());
    try {
      const reports = await getFilteredReports({ id: project.id, createdFor });

      dispatch(actions[EActions.fetchFilteredReportsSuccess](reports));
    } catch (error) {
      dispatch(actions[EActions.fetchFilteredReportsFail](error));
    }
  };

export const updateReport =
  (id: number, data: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchReport({ id, ...data });

      dispatch(refetchReportsSilent());
      dispatch(actions[EActions.setRecommendations](""));

      enqueueSnackbar(i18n.t("projects.detail.modal.reports.update.success"));
    } catch (error) {
      enqueueSnackbar(i18n.t("projects.detail.modal.reports.update.error"));
    }
  };

export const addReportsAction =
  (data: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { project } = getState().projectDetail.project;

      await addReport({ project: project.id, ...data });

      enqueueSnackbar(i18n.t("projects.detail.modal.reports.create.success"));

      dispatch(actions[EActions.setModal](false));
      dispatch(actions[EActions.setRecommendations](""));
      dispatch(actions[EActions.setCreatedFor](""));

      dispatch(refetchReportsSilent());
    } catch (error) {
      enqueueSnackbar(i18n.t("projects.detail.modal.reports.update.error"));
    }
  };

interface IProps {
  id: number;
}

export const generateReportAction =
  ({ id }: IProps) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      enqueueSnackbar(
        i18n.t("projects.detail.modal.reports.generate.requested")
      );
      await generateReport({ id });
      dispatch(refetchReportsSilent());

      enqueueSnackbar(i18n.t("projects.detail.modal.reports.generate.success"));
    } catch (error) {
      enqueueSnackbar(i18n.t("projects.detail.modal.reports.generate.error"));
    }
  };

export const sendReportAction =
  ({ id, users }: any) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await sendReport({ id, users });
      dispatch(refetchReportsSilent());

      enqueueSnackbar(i18n.t("projects.detail.modal.reports.send.success"));
    } catch (error) {
      enqueueSnackbar(i18n.t("projects.detail.modal.reports.send.error"));
    }
  };
