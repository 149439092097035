import { memo, useCallback } from "react";
import Menu from "./menu";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";
import LogoHorizontal from "components/logotypes/horizontal";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { AppState } from "store";
import actions from "store/layout/actions";
import { Box, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => {
  return {
    drawer: {
      flexShrink: 0,
      width: theme.spacing(9),
    },
    drawerPaperMobile: {
      width: theme.spacing(32),
      backgroundColor: theme.custom.palette.surface,
      borderRight: "none",
      padding: theme.spacing(4, 2, 2),
    },
    sidebarInner: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.between("sm", "md")]: {
        margin: 0,
        textAlign: "center",
      },
    },
  };
});

const Mobile = memo(() => {
  const classes = useStyles();
  const dispatch: any = useDispatch();

  const drawerOpened = useSelector(
    (state: AppState) => state.layout.drawer,
    shallowEqual
  );

  const toggleDrawer = useCallback(
    () => dispatch(actions.toggleDrawer()),
    [dispatch]
  );

  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpened}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaperMobile,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.sidebarInner}>
          <LogoHorizontal />
        </div>
        <Menu
          opened={true}
          innerStyle={classes.sidebarInner}
          onDrawerToggle={toggleDrawer}
        />
      </Drawer>
    </Box>
  );
});

export default Mobile;
