import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";

import { prepareActions } from "store/helpers";
import { EActions, TLangsActions } from "./types";
import getMaintenanceTypes from "api/handlers/event/getMaintenanceTypes";

export const path = "maintenanceType";

const actionsData = [
  [EActions.setLoading, "loading"],
  [EActions.setData, "data"],
];

const actions = prepareActions<TLangsActions, EActions>(actionsData, path);

export default actions;

export const loadMaintenanceType =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions.setLoading(true));
      const data = await getMaintenanceTypes();
      dispatch(actions.setData(data));
    } catch (error) {
      dispatch(actions.setLoading(false));
    }
  };
