import React from "react";
import { makeStyles } from "@mui/styles";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import NoData from "components/noData";
import RowSpacer from "components/table/cellsShared/rowSpacer";
import { CellProps } from "react-table";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 100,
    },
    date: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
    },
    hours: {
      fontSize: theme.custom.typography.fontSize[14],
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

const DateAndTime = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { date },
    },
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!date && (
        <>
          <NoData />
          <RowSpacer />
        </>
      )}
      {date && (
        <>
          <span className={classes.date}>{dateToDDMMYYYY(date)}</span>
          <span className={classes.hours}>{dateToHHMM(date)}</span>
        </>
      )}
    </div>
  );
};

export default DateAndTime;
