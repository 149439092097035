import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function patchArchiveModel(trained_model_id: number) {
  const url = endpoints.machines.archiveModelPartial;
  try {
    const response = await axiosNeuron.patch(url, {
      trained_model_id,
    });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
