import axiosNeuron from "api/axios";

import getAudioContext from "components/dataLabeling/audio/getAudioContext";

type IParams = {
  url: string;
};

export default async function getDecodedFile({ url }: IParams) {
  try {
    const ctx = getAudioContext();

    const res = await axiosNeuron.get(url, {
      responseType: "arraybuffer",
    });
    const audio = await ctx.decodeAudioData(res.data);
    return audio;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
