export const PAGE_LOADING = "PAGE_LOADING";

export interface PageState {
  loading: boolean;
}

export interface PageLoadingAction {
  type: typeof PAGE_LOADING;
  payload: {
    loading: boolean;
  };
}

export type PageActionTypes = PageLoadingAction;
