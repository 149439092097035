import React, { PureComponent } from "react";
import ControlTemplate from "./ControlTemplate";
import { ThemedSlider } from "./Slider";
import * as utils from "./utils";

class WindowSlider extends PureComponent {
  static defaultProps = {
    windowStart: 0,
    windowEnd: 0,
    bufferStart: 0,
    duration: 0,
    margin: 5,
    onChangeWindow: null,
    coef: 1.0,
  };

  render() {
    let { bufferStart, windowStart, windowEnd, duration, margin, t, coef } =
      this.props;

    let windowDuration = (windowEnd - windowStart) / coef;
    let max = bufferStart + duration - windowDuration;
    let min = bufferStart;
    let value = windowStart;
    let disabled = max - min < 1;
    return (
      <ControlTemplate
        margin={margin}
        label={`${t("taggingapp.slider.window")} (${utils.timestampToString(
          windowStart
        )} - ${utils.timestampToString(windowStart + windowDuration)})`}
        control={
          <ThemedSlider
            key={Math.round(windowDuration)}
            value={value}
            min={min}
            max={max}
            disabled={disabled}
            onChange={this.onChangeSlider}
          />
        }
      />
    );
  }

  onChangeSlider = (e, value) => {
    let { windowStart, windowEnd } = this.props;
    let windowDuration = windowEnd - windowStart;
    this.props.onChangeWindow &&
      this.props.onChangeWindow(value, value + windowDuration);
  };
}

export default WindowSlider;
