import { Column } from "react-table";
import LabelAndTag from "components/users/list/cells/labelAndTag";
import LabelEmail from "components/users/list/cells/labelEmail";
import LabelArray from "components/users/list/cells/labelArray";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import { getTranslatedRole } from "shared/roles";

export const createColumns = (t: TFunction): Column<IUserList>[] => [
  {
    id: "last_name",
    Header: t("users.list.table.name").toString(),
    accessor: (originalRow: any) => {
      return {
        name: [originalRow.firstName, originalRow.lastName].join(" "),
        role: getTranslatedRole(t, originalRow.role),
      };
    },
    Cell: LabelAndTag,
    custom: {
      hidePriority: 0,
      width: 64,
      margin: "0 0.5rem 0 1rem",
    },
  },
  {
    id: "email",
    Header: t("users.list.table.email").toString(),
    accessor: (originalRow: any) => {
      return { label: originalRow.email };
    },
    Cell: LabelEmail,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "companies",
    Header: t("users.list.table.companies").toString(),
    accessor: (originalRow: any) => {
      return { labels: originalRow.companiesNames };
    },
    Cell: LabelArray,
    custom: {
      hidePriority: 2,
    },
  },
];
