import React from "react";
import { useTranslation } from "react-i18next";
import Heading1 from "components/typography/heading/heading1";
import Paragraph from "components/typography/paragraph";

const AccessDenied: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading1 position="absolute" top="1.1rem" left="4.3rem">
        {t("accessDenied.title")}
      </Heading1>
      <Paragraph>{t("accessDenied.text")}</Paragraph>
    </>
  );
};
export default AccessDenied;
