import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { ResponseType } from "types/response";

export default async function getCurrencies() {
  const url = endpoints.currencies.default;

  try {
    const response = await axiosNeuron.get(url);
    const data: ResponseType<Response> = response.data;
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
