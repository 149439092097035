import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import SectionHeader from "components/form/parts/sectionHeader";
import Heading3 from "components/typography/heading/heading3";
import ProjectRow from "./projectRow";
import { IProjectList } from "types/project";

const useStyles = makeStyles((theme: any) => ({
  cpWrapper: {
    background: theme.custom.palette.surface,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(2),
  },
  companyWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  company: {
    margin: theme.spacing(2),
  },
  projectsWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
}));

type Props = {
  companyName: string;
  companyId: number;
  projects?: IProjectList[];
  handleRadioChange?: (event: React.ChangeEvent<{}>) => void;
  activeProjectId: number | null;
  rerender?: boolean;
};

const areEqual = (prev: Props, next: Props) => {
  if (!prev.rerender && !next.rerender) {
    if (prev.projects?.length === next.projects?.length) {
      return true;
    }
  }
  return false;
};

const Group = memo(
  ({ companyName, projects, activeProjectId, handleRadioChange }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
      <Paper elevation={0} className={classes.cpWrapper}>
        <div className={classes.companyWrapper}>
          <Heading3>{companyName}</Heading3>
        </div>
        <div className={classes.projectsWrapper}>
          <Box mt={2} mb={1}>
            <SectionHeader title={t("users.list.table.projects")} />
          </Box>
          <div>
            {projects &&
              projects.map((project: any) => {
                return (
                  <ProjectRow
                    activeProjectId={activeProjectId}
                    id={project.id}
                    key={project.id}
                    label={project.name}
                    handleRadioChange={handleRadioChange}
                  />
                );
              })}
          </div>
        </div>
      </Paper>
    );
  },
  areEqual
);

export default Group;
