import { IFacility } from "types/facility";
import { SortingRule } from "react-table";
import { ResponseType } from "types/response";

export enum EActions {
  setLoading = "setLoading",
  setFacilities = "setFacilities",
  setPage = "setPage",
  setReloading = "setReloading",
  setEditing = "setEditing",
  setSort = "setSort",
  setShowMore = "setShowMore",
  setReset = "setReset",
  setModalLoading = "ModalLoading",
  setModal = "setModal",
}

export interface IProjectState {
  loading: boolean;
  facilities: ResponseType<IFacility[]> | undefined;
  pageIndex: number;
  reloading: boolean;
  editing: number | null;
  sort: SortingRule<string>[];
  showMore: number;
  modalLoading: boolean;
  modalOpened: boolean;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IProjectState;
}

export type TFetchDataParams = {
  id: number;
  page: number;
  pageSize: number;
  orderBy: SortingRule<string>[];
};
