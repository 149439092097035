import { memo, useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AppState } from "store";
import { createTinyColumns } from "components/attachments/tinyColumns";
import { createColumns } from "components/attachments/columns";
import {
  fetchAttachments,
  createAttachment,
  deleteAttachment,
} from "store/attachments/actions";
import Button from "@mui/material/Button";
import Spinner from "components/spinner";
import { enqueueSnackbar } from "notistack";
import Divider from "../typography/heading/divider";
import ConfirmPopup from "components/confirmPopup";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";

const defaultPageSize = 10;
const pageSizeNoPaging = 10000;

export const Attachments = memo(
  ({
    machine,
    event,
    details,
    hidePagination,
  }: {
    machine?: number;
    event?: number;
    details?: boolean;
    hidePagination?: boolean;
  }) => {
    const { t } = useTranslation();
    const { attachments, loading, showMoreClickedTimesRef, pageIndex, count } =
      useSelector((state: AppState) => state.attachments);
    const dispatch: any = useDispatch();
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up("md"));

    const [deleteId, setDeleteId] = useState<number | null>(null);

    useEffect(() => {
      if (showMoreClickedTimesRef.current === null) {
        showMoreClickedTimesRef.current = 0;
      }
    }, [showMoreClickedTimesRef]);

    const handleUpload = (changeEvent: any) => {
      const fileReader = new FileReader();
      const file = changeEvent.currentTarget.files[0];
      if (!file) {
        return;
      }
      const name = file.name;
      if (file.size > 5242880) {
        enqueueSnackbar(t("machine.event.attachment.tooLarge"));
        return;
      }
      fileReader.readAsDataURL(file);
      changeEvent.currentTarget.value = null;
      fileReader.onload = (loadEvent: any) => {
        const target = loadEvent!.target!;
        const data = target.result!.toString().split(",")[1];
        dispatch(createAttachment({ event: event!, name, data }));
      };
      fileReader.onerror = (errorEvent: any) => {
        enqueueSnackbar(t("machine.event.attachment.readError"));
      };
    };

    const handleDelete = useCallback((id: number) => {
      setDeleteId(id);
    }, []);

    const tinyColumnsMemoized = useMemo(
      () => createTinyColumns(t, handleDelete),
      [t]
    );
    const columnsMemoized = useMemo(
      () => createColumns(t, handleDelete, details),
      [t, details]
    );
    const columns = upMd || !details ? columnsMemoized : tinyColumnsMemoized;
    const hideHeader = columns === tinyColumnsMemoized || !attachments.length;

    const pageSize = useMemo(
      () => (hidePagination ? pageSizeNoPaging : defaultPageSize),
      [hidePagination]
    );

    const initialState = useMemo(() => {
      return {
        pageIndex,
        pageSize,
        pageCount:
          attachments && attachments.length ? Math.ceil(count / pageSize) : 0,
      };
    }, [attachments, count, pageSize, pageIndex]);

    useEffect(() => {
      dispatch(
        fetchAttachments({
          machine,
          event,
          pageSize,
          pageIndex: 0,
          clear: true,
        })
      );
    }, [event, machine, dispatch, pageSize]);

    const handleConfirm = () => {
      if (deleteId) {
        dispatch(deleteAttachment({ id: deleteId }));
        setDeleteId(null);
      }
    };

    const onPageChange = (page: number) => {
      dispatch(
        fetchAttachments({
          machine,
          event,
          pageSize,
          pageIndex: page,
        })
      );
    };

    return (
      <>
        <Divider line noMarginBottom={!!attachments.length}>
          {t("machine.event.attachment.heading")}
        </Divider>
        {!!attachments.length ? (
          <Table<any>
            isLoading={false}
            data={attachments}
            hideNoData={true}
            rowsCount={attachments ? count : 0}
            columns={columns}
            RowComponent={Row}
            HeaderComponent={Header}
            HeaderComponentProps={{ hide: hideHeader }}
            hidePagination={hidePagination || loading}
            onPageChange={onPageChange}
            showMoreClickedTimesRef={showMoreClickedTimesRef}
            initialState={initialState}
          />
        ) : null}
        {loading ? <Spinner /> : null}
        {event && (
          <Button
            variant="contained"
            component="label"
            style={{ backgroundColor: "#e0e0e0", color: "#000" }}
          >
            {t("machine.event.attachment.create")}
            <input
              style={{ display: "none" }}
              id="upload-attachment"
              name="upload-attachment"
              type="file"
              onChange={handleUpload}
              accept=".jpeg,.jpg,image/jpeg,.png,image/png,.pdf,application/pdf,.txt,.text,.doc,application/msword,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation,.tiff,image/tiff"
            />
          </Button>
        )}
        {deleteId && (
          <ConfirmPopup
            onConfirm={handleConfirm}
            title={t("machine.event.attachment.delete.confirm.title")}
            text={t("machine.event.attachment.delete.confirm.text")}
            confirmText={t("machine.event.attachment.delete.confirm.button")}
            noControl={true}
            onCancel={() => setDeleteId(null)}
          />
        )}
      </>
    );
  }
);
