//@ts-nocheck
import { useEffect, useRef, useState } from "react";
import { prepareActions, createReducer } from "store/helpers";
import { useSelector } from "react-redux";
import { store, AppState } from "store";
import isEqual from "lodash/isEqual";
import useDeepCompareEffect from "use-deep-compare-effect";
import uniqBy from "lodash/uniqBy";

import { EActions, Action, TReducers } from "store/selectTemplate";

export const create = async ({ key, method, args }: any) => {
  const initialState = {
    data: undefined,
    loading: false,
    loadingMore: false,
  };

  const reducerData: TReducers = {
    setData: ({ payload: { data } }: any) => {
      return { data, loading: false, loadingMore: false };
    },
    setMoreData: ({ payload: { data } }, state: any) => {
      return {
        ...state,
        data: {
          ...data,
          results: uniqBy(
            [...state.data.results, ...(data?.results || [])],
            "id"
          ),
        },
      };
    },
    loading: ({ payload: { loading } }: any) => ({
      loading,
    }),
    loadingMore: ({ payload: { loadingMore } }: any) => ({
      loadingMore,
    }),
  };

  const reducer = createReducer(key, initialState, reducerData);

  store.reducerManager.add(key, reducer);

  const actionsData = [
    ["setData", "data"],
    ["setMoreData", "data"],
    ["loading", "loading"],
    ["loadingMore", "loadingMore"],
  ];

  const actions = prepareActions<Action, EActions>(actionsData, key);
  store.dispatch(actions.loading(true));
  try {
    const response = await method({ ...args });
    if (response) {
      store.dispatch(actions.setData(response));
    }
  } catch (err) {
    store.dispatch(actions.setData(undefined));
  }

  return {
    actions: { ...actions },
    values: store.getState()[key],
  };
};

export const useAsyncReducer = (key, method, args) => {
  const data = useSelector((state: AppState) => state[key], isEqual);
  const [actions, setActions] = useState<any>({});
  const [ready, setReady] = useState(key ? "ready" : "initial");
  const argsRef = useRef({ key, args });

  useEffect(() => {
    if (ready === "ready" && argsRef.current.key) {
      const fetchData = async () => {
        setReady("done");
        const response = await create({
          ...argsRef.current,
          method,
        });
        if (response) {
          setActions(response.actions);
        }
      };
      fetchData();
    }
  }, [key, method, ready]);

  useEffect(() => {
    if (!isEqual(argsRef.current.key, key) && !key) {
      argsRef.current.key = key;
      setReady("initial");
    }
  }, [key]);

  useDeepCompareEffect(() => {
    if (!isEqual(argsRef.current, { key, args }) && key && ready !== "ready") {
      argsRef.current = { key, args };
      setReady("ready");
    }
  }, [key, args]);

  useEffect(() => {
    return () => {
      if (argsRef.current.key) {
        store.reducerManager.remove(argsRef.current.key);
      }
    };
  }, []);

  if (ready !== "done") {
    return {
      loading: true,
      loadingMore: false,
    };
  }
  return {
    ...data,
    loadMore: !!data?.data?.next
      ? async () => {
          store.dispatch(actions.loadingMore(true));
          const response = await method({ ...args, nextUrl: data.data.next });
          store.dispatch(actions.setMoreData(response));
          store.dispatch(actions.loadingMore(false));
        }
      : undefined,
  };
};
