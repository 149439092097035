import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

class RegionSimplified extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dragStart: 0,
      dragEnd: 0,
    };
  }

  componentDidMount() {
    let { id } = this.props;
    let el = ReactDOM.findDOMNode(this);
    if (el instanceof HTMLElement) {
      el.addEventListener("mouseup", this.onMouseUp);
    }
    document.addEventListener("keydown", this.handleDeletePress);

    document.getElementById(id).addEventListener("mousedown", (e) => {
      e.stopPropagation();
      e.stopImmediatePropagation();
    });
  }

  componentWillUnmount() {
    let { id } = this.props;
    let el = ReactDOM.findDOMNode(this);
    if (el instanceof HTMLElement) {
      el.removeEventListener("mouseup", this.onMouseUp);
    }
    document.removeEventListener("keydown", this.handleDeletePress);

    document.getElementById(id).removeEventListener("mousedown", (e) => {
      e.stopPropagation();
      e.stopImmediatePropagation();
    });
  }

  render() {
    let {
      style: suggestedStyle,
      selectedStyle,
      left,
      bottom,
      height,
      width,
      selected,
      region,
      id,
      onSelectRegion,
    } = this.props;
    let style = {
      left,
      bottom,
      width,
      height,
      position: "absolute",
      border: "solid 1px #777777",
      borderRadius: 3,
      ...(suggestedStyle || {}),
      ...(selected && selectedStyle ? selectedStyle : {}),
    };

    let { labels } = region;
    let tooltip = labels.slice(0, 2).map(({ name }) => name);
    // displaying time in tooltip
    tooltip.unshift(moment.unix(region.start).format("HH:mm"));

    if (tooltip.length === 0) {
      tooltip.push("No Labels");
    } else if (labels.length > 2) {
      tooltip.push(`and ${labels.length - 2} more`);
    }

    return (
      <Tooltip title={tooltip.join(", ")}>
        <div
          className="wave-region"
          id={id}
          style={style}
          draggable
          onDragOver={(e) => e.preventDefault()}
          onDragStart={(e) => {
            this.onDragStart(e, id);
            onSelectRegion(region, e);
          }}
          onDragEnd={(e) => this.onDragEnd(e)}
        ></div>
      </Tooltip>
    );
  }

  onMouseUp = (e) => {
    const { onClick, selected, region, onSelectRegion } = this.props;

    e.stopPropagation();
    e.stopImmediatePropagation();
    onSelectRegion(region, e);

    if (onClick) {
      onClick(selected ? null : region, e);
    }
  };

  onDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
    this.setState({
      ...this.state,
      dragStart: e.pageX,
    });
  };
  onDragEnd = (e) => {
    e.stopPropagation();
    const { dragStart } = this.state;
    // (start, end, zoomOut)
    this.props.onDragRegion(dragStart, e.pageX, true);
    e.dataTransfer.clearData();
  };

  handleDeletePress = (e) => {
    let { selected, onRemoveAllLabels } = this.props;
    if (selected && e.key === "Delete") {
      onRemoveAllLabels();
    }
  };
}

export default RegionSimplified;
