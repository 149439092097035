//@ts-nocheck
interface IBaseObject {
  [index: string]: any;
}

export const makeActionCreator =
  (path: string) =>
  (type: string, ...argNames: string[]) => {
    return function (...args: any) {
      const action = {
        type: path + "_" + type,
        payload: {},
      };
      argNames.forEach((arg, index) => {
        action.payload[argNames[index]] = args[index];
      });
      return action;
    };
  };

export const prepareActions = <P, A>(
  pairs: Array<Array<string>>,
  path: string
): { [key in A]: (...args: any) => P } => {
  const res = {};
  const creatorWithType = makeActionCreator(path);
  for (const action of pairs) {
    res[action[0]] = creatorWithType(...action);
  }
  return res;
};

export const prehandledReducers = (
  path: string,
  handlers: IBaseObject
): IBaseObject => {
  const res: IBaseObject = {};
  for (const handler of Object.keys(handlers)) {
    res[path + "_" + handler] = handlers[handler];
  }
  return res;
};

export const createReducer = <S>(
  path: string,
  initialState: S,
  handlers: IBaseObject
) => {
  const prefixHandlers = prehandledReducers(path, handlers);

  return function reducer(
    state = initialState,
    action: { type: string; payload?: any }
  ) {
    if (prefixHandlers.hasOwnProperty(action.type)) {
      return { ...state, ...prefixHandlers[action.type](action, state) };
    } else {
      return state;
    }
  };
};
