import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function getPlacements(machineId: number) {
  const url = endpoints.machines.placements(machineId);
  try {
    const { data }: any = await axiosNeuron.get(`${url}?order_by=id`);

    return data.map(
      ({
        id,
        channel,
        type,
        custom_name,
        rpm,
        is_rpm_automatically_detected,
      }: any) => ({
        label: `Channel ${channel}`,
        value: id,
        type,
        customName: custom_name,
        rpm: is_rpm_automatically_detected ? null : rpm,
      })
    );
  } catch {
    return;
  }
}
