import { EActions, UsersFilterAction } from "./types";
import { prepareActions } from "store/helpers";

export const path = "userDetailFilter";

const actionsData = [[EActions.setFilter, "filters"]];

const actions = prepareActions<UsersFilterAction, EActions>(actionsData, path);

export default actions;
