import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function massResolveEvents({
  machineId,
  resolveIds,
  note,
  alert_severity,
  related_pair_event,
}: {
  machineId: number;
  resolveIds?: number[];
  note?: string;
  alert_severity?: string;
  related_pair_event?: number;
}) {
  const url = endpoints.machines.massAcknowledgeEvents(machineId);
  const params = {
    types: ["anomaly", "anomaly_warning"],
    events: resolveIds,
    note,
    alert_severity,
    related_pair_event,
  };
  try {
    const { data } = await axiosNeuron.post(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
