import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ModalWrap } from "components/events/components/modalWrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { Form } from "react-final-form";
import TextField from "components/textField";
import { Field } from "react-final-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Spinner from "components/spinner";
import { maxCharacters } from "shared/form/validators";
import { addFacilityAction } from "store/projectDetail/facilities/actions";
import isEqual from "react-fast-compare";

interface IAddFacilityModal {
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  input: {
    width: "100%",
  },
}));

export const AddFacilityModal = memo(
  ({ open, onCancel }: IAddFacilityModal) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const { loading } = useSelector((state: AppState) => state.companyDetail);

    const onSubmitEvent = useCallback(
      (data: any) => {
        dispatch(addFacilityAction(data));
      },
      [dispatch]
    );

    return (
      <Form
        onSubmit={onSubmitEvent}
        render={({ handleSubmit, values: { name, address } }) => (
          <form id="projectDetail.ModalForm" onSubmit={handleSubmit}>
            <ModalWrap
              open={open}
              title={t("projects.detail.addFacility")}
              cta={t("projects.detail.addFacility")}
              submitDisabled={loading || !!!name || !!!address}
              onCancel={onCancel}
              loading={loading}
              // TODO remove comment after https://gitlab.com/neuronsw-infrastructure/customer-portal/-/merge_requests/412/diffs is merged
              // mandatory
              onSubmit={handleSubmit}
            >
              <>
                <Box flexDirection="column" m={3} flex={1}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Field
                          className={classes.input}
                          name="name"
                          id="testFacilityNameField"
                          component={TextField}
                          label={t(
                            "projects.detail.modal.facilities.name.placeholder"
                          )}
                          validate={maxCharacters(255)}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          className={classes.input}
                          name="address"
                          id="testFacilitytAddressField"
                          component={TextField}
                          label={t(
                            "projects.detail.modal.facilities.address.placeholder"
                          )}
                          validate={maxCharacters(255)}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          className={classes.input}
                          name="description"
                          id="testFacilityDescField"
                          component={TextField}
                          label={t(
                            "projects.detail.modal.facilities.description.placeholder"
                          )}
                          validate={maxCharacters(255)}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </>
            </ModalWrap>
          </form>
        )}
      />
    );
  },
  isEqual
);
