import React, { PureComponent } from "react";
import { withStyles, createStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      lineHeight: "27px",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    flex: { flexDirection: "row" },
  });
class LabelChannels extends PureComponent {
  static defaultProps = {
    labelPlacements: [],
    handleLabelPlacements: null,
  };

  handeCheckboxChange = (checkedValues) => {
    this.props.handleLabelChannels &&
      this.props.handleLabelChannels(checkedValues);
  };

  render() {
    const { labelPlacements, placement, handleLabelPlacements, classes } =
      this.props;
    return (
      <div className={classes.root}>
        {labelPlacements && (
          <FormControl
            component="fieldset"
            className={classes.formControl}
            disabled={true}
          >
            <FormGroup className={classes.flex}>
              {labelPlacements.map((item, index) => (
                <FormControlLabel
                  key={`${index}-labelChannel-item`}
                  control={
                    <Checkbox
                      checked={item.value === parseInt(placement)}
                      onChange={handleLabelPlacements}
                      name={item.label}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(LabelChannels);
