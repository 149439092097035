import { EActions, TIsoGroupState, TLangsActions } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const intialState: TIsoGroupState = {
  loading: false,
  data: undefined,
};

const reducers = {
  [EActions.setLoading]: ({ payload: { loading } }: TLangsActions) => ({
    loading,
  }),
  [EActions.setData]: ({ payload: { data } }: TLangsActions) => ({
    loading: false,
    data,
  }),
};

export default createReducer(path, intialState, reducers);
