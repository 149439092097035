import { IMachinesListState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: any = {
  data: {},
  error: null,
  isFetchingMachines: false,
  isFetchingCards: false,
  isFetchingDeviceHealth: false,
  isFetchingDevices: false,
  utilization: null,
  utilizationTotals: null,
};

const reducers: TReducers = {
  [EActions.refetchMachinesSuccess]: ({ payload: { data } }, state) => ({
    data: {
      ...state.data,
      ...data,
    },
  }),
  [EActions.refetchMachinesFail]: ({ payload: { error } }) => ({
    error,
  }),
  [EActions.setResetList]: () => ({
    usersData: {
      results: [],
    },
  }),
  [EActions.fetchMachinesRequest]: () => ({
    isFetchingMachines: true,
    isFetchingCards: true,
  }),
  [EActions.fetchMachinesFail]: ({ payload: { error } }) => ({
    isFetchingMachines: false,
    error,
  }),
  [EActions.fetchMachinesSuccess]: ({ payload: { data } }) => ({
    isFetchingMachines: false,
    data: data,
  }),
  [EActions.fetchCardsRequest]: () => ({
    isFetchingCards: true,
  }),
  [EActions.fetchCardsFail]: ({ payload: { error } }) => ({
    isFetchingCards: false,
    error,
  }),
  [EActions.fetchCardsSuccess]: ({ payload: { data } }, state) => ({
    isFetchingCards: false,
    data: {
      ...state.data,
      ...data,
    },
  }),
  [EActions.fetchLast30DaysRequest]: () => ({
    isFetchingLast30Days: true,
  }),
  [EActions.fetchLast30DaysFail]: ({ payload: { error } }) => ({
    isFetchingLast30Days: false,
    error,
  }),
  [EActions.fetchLast30DaysSuccess]: (
    { payload: { utilization, utilizationTotals } },
    state
  ) => {
    return {
      isFetchingLast30Days: false,
      data: { ...state.data, utilization, utilizationTotals },
    };
  },
  [EActions.fetchDeviceHealthRequest]: () => ({
    isFetchingDeviceHealth: true,
  }),
  [EActions.fetchDeviceHealthFail]: ({ payload: { error } }) => ({
    isFetchingDeviceHealth: false,
    error,
  }),
  [EActions.fetchDeviceHealthSuccess]: ({ payload: { data } }, state) => ({
    isFetchingDeviceHealth: false,
    data: {
      ...state.data,
      ...data,
    },
  }),
  [EActions.fetchDevicesRequest]: () => ({
    isFetchingDevices: true,
  }),
  [EActions.fetchDevicesFail]: ({ payload: { error } }) => ({
    isFetchingDevices: false,
    error,
  }),
  [EActions.fetchDevicesSuccess]: ({ payload: { data } }, state) => ({
    isFetchingDevices: false,
    data: {
      ...state.data,
      ...data,
    },
  }),
};

export default createReducer<IMachinesListState>(path, initialState, reducers);
