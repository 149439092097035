const PAGE_FORCE_REFRESHED_STATE = "pageForceRefreshedState";

class ForceRefreshHandler {
  static setPageRefreshedState = (state: boolean) => {
    localStorage.setItem(PAGE_FORCE_REFRESHED_STATE, state.toString());
  };
  static hasPageRefreshedState = () =>
    !!localStorage.getItem(PAGE_FORCE_REFRESHED_STATE);
  static getPageRefreshedState = () =>
    localStorage.getItem(PAGE_FORCE_REFRESHED_STATE);
  static clearPageRefreshedState = () => {
    localStorage.removeItem(PAGE_FORCE_REFRESHED_STATE);
  };
}

export default ForceRefreshHandler;
