import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export default async function getDeviceTelemetric(id?: number) {
  const url = endpoints.devices.telemetric;

  try {
    const { data } = await axiosNeuron.get(url, {
      params: {
        id,
      },
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
