import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getFailureTypes from "api/handlers/failures/getFailureTypes";
import type { TFailureType } from "types/failureTypes";

type TInitialState = {
  data: TFailureType[];
  loading: boolean;
  error: string | null;
};

const initialState: TInitialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchFailureTypes = createAsyncThunk(
  "failureTypes/fetchFailureTypes",
  async () => {
    const response = await getFailureTypes();
    return response;
  }
);

export const { reducer } = createSlice({
  name: "failureTypes",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchFailureTypes.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(fetchFailureTypes.fulfilled, (state: any, action: any) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchFailureTypes.rejected, (state: any) => {
      state.data = [];
      state.loading = false;
      state.error = "Error while getting the data";
    });
  },
});
