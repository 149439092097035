import React, { memo, useCallback, useMemo } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { cmp } from "shared/helpers";
import ConfirmPopup from "components/confirmPopup";
import Deploy from "components/machine/detail/model/deploy";
import deployModel from "api/handlers/machine/deployModel";
import undeployModel from "api/handlers/machine/undeployModel";
import stopModelTraining from "api/handlers/machine/stopModelTraining";
import { useSnackbar } from "notistack";
import Recompute from "components/machine/detail/model/recompute";
import recompute from "api/handlers/machine/recomputeResults";
import { Box, MenuItem } from "@mui/material";
import Divider from "components/typography/heading/divider";
import { useStyles } from "./styles";
import ModelHead from "components/modelTable/tableHead";
import ModelBody from "./tableBody";
import Heading4 from "components/typography/heading/heading4";

const INTERVAL_24_HOURS = 86400 * 1000;

interface IModelTable {
  results: any;
  id: number;
  isModel?: boolean;
  setArchiveId?: React.Dispatch<React.SetStateAction<number | null>>;
  setUnarchiveId?: React.Dispatch<React.SetStateAction<number | null>>;
  setMachineId?: React.Dispatch<React.SetStateAction<number | null>>;

  machineId?: number | null;
  undeployId: number | null;
  deployId: number | null;
  recomputeId: number | null;
  stopTrainingJob: string | null;

  setUndeployId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeployId: React.Dispatch<React.SetStateAction<number | null>>;
  setRecomputeId: React.Dispatch<React.SetStateAction<number | null>>;
  setStopTrainingJob: React.Dispatch<React.SetStateAction<string | null>>;
}

const ModelTable = memo((props: IModelTable) => {
  const {
    results,
    id,
    isModel,
    setArchiveId,
    setUnarchiveId,
    setMachineId,
    machineId,
    undeployId,
    deployId,
    recomputeId,
    stopTrainingJob,
    setUndeployId,
    setDeployId,
    setRecomputeId,
    setStopTrainingJob,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tt = useCallback((i: string) => t(`machine.detail.model.${i}`), [t]);

  const allModels = results ? results.results : [];

  const headerRef = React.useRef<HTMLTableSectionElement>(null);

  const onScroll = (e: Event) => {
    if (!headerRef.current) return;
    const offset =
      headerRef.current.getBoundingClientRect().y -
      parseFloat(headerRef.current.style.top);
    headerRef.current.style.top = `${offset < 0 ? -offset : 0}px`;
  };

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [headerRef]);

  const readyDeployment = (i: any) =>
    i &&
    ((i.deployment_type === "cloud" &&
      i.is_ready &&
      !i.is_deleted &&
      i.consumer &&
      i.consumer.is_ready &&
      !i.consumer.is_deleted) ||
      (i.deployment_type === "edge" && i.is_ready && !i.is_deleted));

  const readyModel = useCallback((i: any) => readyDeployment(i.deployment), []);

  const partiallyDeployedModel = (i: any) =>
    i.deployment &&
    ((i.deployment.deployment_type === "cloud" &&
      !i.deployment.is_deleted &&
      i.deployment.consumer &&
      !i.deployment.consumer.is_deleted) ||
      (i.deployment.deployment_type === "edge" && !i.deployment.is_deleted));

  const models = useMemo(() => {
    const result: any[] = [];
    if (!allModels) {
      return result;
    }
    var lastCreatedAt = null;
    const now = new Date().getTime();
    for (var i = 0; i < allModels.length; ++i) {
      if (
        allModels[i].status === "training" &&
        allModels[i].training_finished_at === null &&
        now - new Date(allModels[i].training_invoked_at).getTime() >
          INTERVAL_24_HOURS
      ) {
        allModels[i].status = "training failed";
        allModels[i].finished = true;
      }
      if (lastCreatedAt !== allModels[i].task_created_at) {
        lastCreatedAt = allModels[i].task_created_at;
        result.push([]);
      }
      const { deployments: unsortedDeployments, ...rest } = allModels[i];
      const deployments = unsortedDeployments.sort((a: any, b: any) =>
        cmp((i: any) => i.created_at)(b, a)
      );
      const deployed = !!deployments.filter(readyDeployment).length;
      for (var j = 0; j < deployments.length; ++j) {
        if (deployed && !readyDeployment(deployments[j])) {
          continue;
        }
        result[result.length - 1].push({
          ...rest,
          deployment: deployments[j],
        });
        if (!deployed) {
          break;
        }
      }
      if (!deployments.length) {
        result[result.length - 1].push({ ...rest, deployment: null });
      }
    }
    return result;
  }, [allModels]);

  const getBoldClass = useCallback(
    (model: any, className?: string) => {
      if (model.status === "selected") {
        if (className) {
          return `${className} ${classes.bold}`;
        } else {
          return classes.bold;
        }
      } else {
        return className;
      }
    },
    [classes]
  );

  const handleDeploy = async (environment: string) => {
    try {
      await deployModel(id, { trained_model_id: deployId!, environment });
      enqueueSnackbar(tt("beingDeployed"));
    } catch (e) {
      enqueueSnackbar(tt("errorDeploying"));
    }
    setDeployId(null);
    setMachineId?.(null);
  };

  const handleUndeploy = async () => {
    try {
      await undeployModel(id, { deployment_id: undeployId! });
      enqueueSnackbar(tt("beingUndeployed"));
    } catch (e) {
      enqueueSnackbar(tt("errorUndeploying"));
    }
    setUndeployId(null);
    setMachineId?.(null);
  };

  const handleRecompute = async (from: Date, to: Date, environment: string) => {
    try {
      await recompute(id, {
        trained_model_id: recomputeId!,
        from,
        to,
        environment,
      });
      enqueueSnackbar(tt("scheduledRecomputing"));
    } catch (e) {
      enqueueSnackbar(tt("errorRecomputing"));
    }
    setRecomputeId(null);
    setMachineId?.(null);
  };

  const handleStopTraining = async () => {
    if (!stopTrainingJob) return;
    try {
      await stopModelTraining(id, stopTrainingJob);
      enqueueSnackbar(tt("requestedStopTraining"));
      setStopTrainingJob(null);
      setMachineId?.(null);
    } catch (e) {
      enqueueSnackbar(tt("errorRequestingStopTraining"));
    }
  };

  const hideKebabMenu = useCallback((id: string) => {
    const hideKebab = new CustomEvent("hideKebab");
    const kebab = document.querySelector("#kebab-devices-list-" + id);
    kebab?.dispatchEvent(hideKebab);
  }, []);

  const generateItems = useMemo(() => {
    return (model: any) => {
      return (
        <>
          {model.status === "training failed" ? null : (
            <MenuItem
              className={getBoldClass(
                model,
                `${classes.smallPadding} ${classes.alignCenter}`
              )}
            >
              {readyModel(model) || partiallyDeployedModel(model) ? (
                <Button
                  size="small"
                  className={classes.actionButton}
                  color="inherit"
                  onClick={() => {
                    setUndeployId(model.deployment?.id);
                    setMachineId?.(model.machine);
                    hideKebabMenu(model.task_id);
                  }}
                >
                  {tt("undeploy")}
                </Button>
              ) : !model.deployment && model.status === "training" ? (
                <>
                  {!!model.job_name && (
                    <Button
                      size="small"
                      className={classes.actionButton}
                      color="inherit"
                      onClick={() => {
                        setStopTrainingJob(model.job_name);
                        setMachineId?.(model.machine);
                        hideKebabMenu(model.task_id);
                      }}
                    >
                      {tt("stopTraining")}
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  size="small"
                  className={classes.actionButton}
                  color="inherit"
                  onClick={() => {
                    setDeployId(model.trained_model);
                    setMachineId?.(model.machine);
                    hideKebabMenu(model.task_id);
                  }}
                >
                  {tt("deploy")}
                </Button>
              )}
            </MenuItem>
          )}
          {model.status !== "training" &&
            model.status !== "training failed" && (
              <MenuItem className={getBoldClass(model, classes.smallPadding)}>
                <Button
                  size="small"
                  className={classes.actionButton}
                  color="inherit"
                  onClick={() => {
                    setRecomputeId(model.trained_model);
                    setMachineId?.(model.machine);
                    hideKebabMenu(model.task_id);
                  }}
                >
                  {tt("recompute")}
                </Button>
              </MenuItem>
            )}

          {isModel ? (
            model.is_archived ? (
              <MenuItem className={getBoldClass(model, classes.smallPadding)}>
                <Button
                  size="small"
                  className={classes.actionButton}
                  color="inherit"
                  onClick={() => {
                    setUnarchiveId?.(model.trained_model);
                    setMachineId?.(model.machine);
                    hideKebabMenu(model.task_id);
                  }}
                >
                  {tt("unarchive")}
                </Button>
              </MenuItem>
            ) : (
              !readyModel(model) &&
              !partiallyDeployedModel(model) && (
                <MenuItem className={getBoldClass(model, classes.smallPadding)}>
                  <Button
                    size="small"
                    className={classes.actionButton}
                    color="inherit"
                    onClick={() => {
                      setArchiveId?.(model.trained_model);
                      setMachineId?.(model.machine);
                      hideKebabMenu(model.task_id);
                    }}
                  >
                    {tt("archive")}
                  </Button>
                </MenuItem>
              )
            )
          ) : null}
        </>
      );
    };
  }, [
    classes,
    getBoldClass,
    isModel,
    readyModel,
    setArchiveId,
    setDeployId,
    setMachineId,
    setRecomputeId,
    setStopTrainingJob,
    setUnarchiveId,
    setUndeployId,
    tt,
  ]);

  return (
    <>
      <TableContainer className={classes.container}>
        {!isModel && (
          <Divider line>{t("machine.detail.model.historyTitle")}</Divider>
        )}
        {models.length !== 0 ? (
          <Table className={classes.table} stickyHeader>
            <ModelHead headerRef={headerRef} />
            {models.map((group: any, idx: number) => {
              return (
                <ModelBody
                  key={`${group[0].task_id} ${idx}`}
                  {...{
                    group,
                    id,
                    getBoldClass,
                    readyModel,
                    partiallyDeployedModel,
                    generateItems,
                    isModel,
                  }}
                />
              );
            })}
          </Table>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
            p={10}
          >
            <Heading4>{t("list.noData")}</Heading4>
          </Box>
        )}
      </TableContainer>
      {(isModel ? undeployId && machineId : undeployId) && (
        <ConfirmPopup
          onConfirm={handleUndeploy}
          title={tt("undeployDialog.title")}
          text={tt("undeployDialog.text")}
          confirmText={tt("undeploy")}
          noControl={true}
          onCancel={() => {
            setUndeployId(null);
            setMachineId?.(null);
          }}
        />
      )}
      {(isModel ? deployId && machineId : deployId) && (
        <Deploy
          machineId={id!}
          modelId={deployId!}
          onCancel={() => {
            setDeployId(null);
            setMachineId?.(null);
          }}
          onConfirm={handleDeploy}
        />
      )}
      {(isModel ? recomputeId && machineId : recomputeId) && (
        <Recompute
          machineId={id!}
          modelId={recomputeId!}
          onCancel={() => {
            setRecomputeId(null);
            setMachineId?.(null);
          }}
          onConfirm={handleRecompute}
        />
      )}
      {(isModel ? stopTrainingJob && id : stopTrainingJob) && (
        <ConfirmPopup
          onConfirm={handleStopTraining}
          title={tt("stopTrainingDialog.title")}
          text={tt("stopTrainingDialog.text")}
          confirmText={tt("stopTrainingDialog.confirm")}
          noControl={true}
          onCancel={() => {
            setStopTrainingJob(null);
            setMachineId?.(null);
          }}
        />
      )}
    </>
  );
});

export default ModelTable;
