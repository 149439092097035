import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "store";

import { prepareActions } from "store/helpers";
import { EActions, TLangsActions } from "./types";
import i18next, { LangEnum } from "localization";
import values from "lodash/values";
import urls from "shared/appUrls";
import parser from "shared/CSVToJSON";
import { fetchCSV } from "shared/helpers";

export const path = "langs";

const actionsData = [[EActions.setLoading, "loading"]];

const actions = prepareActions<TLangsActions, EActions>(actionsData, path);

export default actions;

const fetchData = async () => {
  let data = "";

  data = await fetchCSV(window.origin + urls.translations.path());
  return parser(data);
};

export const loadLanguage =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions.setLoading(true));
      const data = await fetchData();
      for (let i of values(LangEnum)) {
        i18next.addResourceBundle(i, "app", data[i]);
      }
      dispatch(actions.setLoading(false));
    } catch (error) {
      dispatch(actions.setLoading(false));
    }
  };
