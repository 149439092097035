import React, { useCallback, useEffect, useRef, memo, useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import EditBar from "components/editBar";
import { AppState } from "store";
import { createSelector } from "reselect";
import get from "lodash/get";
import { getActions } from "shared/helpers";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "inline-block",
      fontWeight: theme.custom.typography.fontWeight.medium,
      fontSize: theme.custom.typography.fontSize[16],
      wordBreak: "break-word",
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        wordBreak: "normal",
      },
    },
    editWrap: {
      width: "100%",
      paddingRight: theme.spacing(3),
    },
  };
});

type TRegularProps = {
  cell: {
    value: {
      label: string;
      id: number;
      path: string;
      validate?: (
        value: string,
        allValues: Object,
        meta?: Object
      ) => string | boolean;
    };
  };
};

type PropsAndState = {
  state: AppState;
  id: number;
  path: string[] | string;
};

// @ts-expect-error: expect a different approach
const selector = createSelector(
  ({ state, id, path }: PropsAndState) => get(state, path).editing === id,
  (result: AppState) => {
    return { isEditing: result };
  }
);

type ActionsReference = {
  setEditing: (editing: number | null) => any;
  rename: (id: number, name: string) => any;
};

const Name = memo(
  ({
    cell: {
      value: { label, id, path, validate },
    },
  }: TRegularProps) => {
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const [enabled, enable] = useState(false);
    const actionsRef = useRef<ActionsReference | null>(null);

    const { isEditing } = useSelector(
      (state: AppState) => selector({ state, id, path }),
      shallowEqual
    );

    const onCancel = useCallback(() => {
      dispatch(actionsRef.current?.setEditing(null));
    }, [dispatch]);

    const onSubmit = useCallback(
      (name: string) => {
        dispatch(actionsRef.current?.rename(id, name));
      },
      [dispatch, id]
    );

    useEffect(() => {
      const getReduxActions = async () => {
        actionsRef.current = await getActions(path);
        enable(true);
      };
      getReduxActions();
    }, [path]);

    return (
      <>
        {isEditing ? (
          <div className={classes.editWrap}>
            <EditBar
              disabled={!enabled}
              placeholder={label}
              value={label}
              onCancel={onCancel}
              onClick={onSubmit}
              validate={validate}
              isEditation
            />
          </div>
        ) : (
          <span className={classes.root}>{label}</span>
        )}
      </>
    );
  }
);

export default Name;
