import React from "react";
import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";
import RowSpacer from "components/table/cellsShared/rowSpacer";
import StatusComponent from "components/status";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "inline-block",
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
      width: 80,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };
});

function Status<T extends object>(props: CellProps<T>) {
  const {
    cell: {
      value: { status },
    },
  } = props;
  const classes = useStyles(props);
  return (
    <span className={classes.root}>
      <StatusComponent name={status} />
      <RowSpacer />
    </span>
  );
}

export default Status;
