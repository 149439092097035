import uniqBy from "lodash/uniqBy";
import { EventType as ET } from "components/machine/types";

export enum EGroupedTypes {
  anomaly = "anomaly",
  unresolved = "unresolved",
  running = "running",
  broken = "broken",
  out_of_service = "out_of_service",
  maintenance = "maintenance",
  nsw_maintenance = "nsw_maintenance",
  placement_connection = "placement_connection",
  nominal_sound = "nominal_sound",
  additional_training_data = "additional_training_data",
  placement_availibility = "placement_availibility",
  placement_running_threshold_changed = "placement_running_threshold_changed",
  device_recording_plan_changed = "device_recording_plan_changed",
  channel_gain_changed = "channel_gain_changed",
  dashboard_updated = "dashboard_updated",
  model_training = "model_training",
  production_note = "production_note",
  significant_nominal_state_change = "significant_nominal_state_change",
  production_mode_updated = "production_mode_updated",
  data_label = "data_label",
}

export const groupedTypes: { [key: string]: string[] } = {
  [EGroupedTypes.anomaly]: [ET.anomaly, ET.anomaly_warning, ET.anomaly_ok],
  [EGroupedTypes.running]: [ET.machine_on, ET.machine_off, ET.error],
  [EGroupedTypes.broken]: [ET.broken_start, ET.broken_end],
  [EGroupedTypes.out_of_service]: [
    ET.out_of_service_start,
    ET.out_of_service_end,
  ],
  [EGroupedTypes.maintenance]: [ET.maintenance_start, ET.maintenance_end],
  [EGroupedTypes.nsw_maintenance]: [
    ET.nsw_maintenance_start,
    ET.nsw_maintenance_end,
  ],
  [EGroupedTypes.placement_connection]: [
    ET.placement_connected,
    ET.placement_disconnected,
  ],
  [EGroupedTypes.nominal_sound]: [ET.nominal_sound_start, ET.nominal_sound_end],
  [EGroupedTypes.additional_training_data]: [
    ET.additional_training_data_start,
    ET.additional_training_data_end,
  ],
  [EGroupedTypes.placement_availibility]: [
    ET.placement_enabled,
    ET.placement_disabled,
  ],
  [EGroupedTypes.placement_running_threshold_changed]: [
    ET.placement_running_threshold_changed,
  ],
  [EGroupedTypes.device_recording_plan_changed]: [
    ET.device_recording_plan_changed,
  ],
  [EGroupedTypes.channel_gain_changed]: [ET.channel_gain_changed],
  [EGroupedTypes.dashboard_updated]: [
    ET.loudness_dashboard_updated,
    ET.anomaly_dashboard_updated,
    ET.combined_dashboard_updated,
  ],
  [EGroupedTypes.model_training]: [
    ET.model_training_failed,
    ET.model_training_completed,
    ET.model_training_started,
  ],
  [EGroupedTypes.production_note]: [
    ET.production_note_start,
    ET.production_note_end,
  ],
  [EGroupedTypes.significant_nominal_state_change]: [
    ET.significant_nominal_state_change,
  ],
  [EGroupedTypes.production_mode_updated]: [ET.production_mode_updated],
  [EGroupedTypes.data_label]: [ET.data_label_start, ET.data_label_end],
};

export const endTypes: string[] = [
  ET.broken_end,
  ET.maintenance_end,
  ET.nsw_maintenance_end,
  ET.nominal_sound_end,
  ET.additional_training_data_end,
  ET.out_of_service_end,
  ET.production_note_end,
  ET.data_label_end,
];

const getPairEvents = () => {
  const obj: { [key: string]: string } = {};
  Object.entries(groupedTypes).map((item: any) =>
    item[1].map((subitem: any) => (obj[subitem] = item[0]))
  );
  return obj;
};

const pairEvents = getPairEvents();

const allowedTypes = [ET.anomaly, ...Object.keys(pairEvents)];

export const groupEventTypes = (types: any) => {
  const res = [];
  for (const i of types) {
    if (allowedTypes.indexOf(i.codename) !== -1) {
      if (Object.keys(pairEvents).indexOf(i.codename) !== -1) {
        res.push({ codename: pairEvents[i.codename] });
      } else {
        res.push({ codename: i.codename });
      }
    }
  }
  return uniqBy(res, "codename");
};

export const groupEventTypesWithDetails = (types: any) => {
  const res = [];
  for (const i of types) {
    if (allowedTypes.indexOf(i.codename) !== -1) {
      if (Object.keys(pairEvents).indexOf(i.codename) !== -1) {
        res.push({ ...i, codename: pairEvents[i.codename] });
      } else {
        res.push({ ...i, codename: i.codename });
      }
    }
  }
  return uniqBy(res, "codename");
};

export const separateEventTypes = (types: any) => {
  const res = [];
  if (types) {
    for (const i of types) {
      if (groupedTypes[i]) {
        for (const j of groupedTypes[i]) {
          res.push(j);
        }
      } else {
        res.push(i);
      }
    }
  }

  return res;
};
