import React, { useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { IconButton, Table } from "@mui/material";
import {
  InfoRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarsIcon from "@mui/icons-material/Stars";
import { useStyles } from "../styles";
import moment from "moment-timezone";
import TooltipComponent from "components/tooltip";
import CustomModelDetailHead from "./tableHeadDetail";
import CustomModelDetailBodyTrainings from "./tableBodyDetailTrainings";
import { ICustomModel } from "types/customModels";

const CustomModelBodyTraining = ({ group }: any) => {
  const { t } = useTranslation();
  const tt = (i: string) => t(`machine.detail.model.${i}`);
  const classes = useStyles();

  const [isTaskId, setIsTaskId] = useState(false);
  const [openStatusTooltip, setOpenStatusTooltip] = useState(false);
  const [openOtherInfoTooltip, setOpenOtherInfoTooltip] = useState(false);

  const training = group.trainings[0];

  const status = training?.status;
  const tooltipInnerStatus = (
    <span>
      {status === "not the best"
        ? tt("trainingSuccessful")
        : status === "selected"
          ? tt("modelSelected")
          : status === "training failed"
            ? tt("trainingFailed")
            : tt("metricsFailed")}
    </span>
  );

  const toolTipInnerInfo = (
    <>
      <div>
        <strong>{t("models.detail.customModels.trainedModel")}</strong>:{" "}
        <span>{training.trained_model}</span>
      </div>
      <div>
        <strong>{t("models.detail.customModels.modelTypeName")}</strong>:{" "}
        <span>{training.model_type_name}</span>
      </div>
      <div>
        <strong>{t("models.detail.customModels.taskId")}</strong>:{" "}
        <span>{training.task_id}</span>
      </div>
      <div>
        <strong>{t("models.detail.customModels.taskName")}</strong>:{" "}
        <span>{training.task_name}</span>
      </div>
      <div>
        <strong>{t("models.detail.customModels.job")}</strong>:{" "}
        <span>{training.job_name}</span>
      </div>
    </>
  );

  return (
    <>
      <thead className={classes.break} />
      <TableBody className={classes.tableContent}>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setIsTaskId(!isTaskId);
              }}
            >
              {isTaskId ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.custom_model_category}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {training.datasetName}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {training.dataset_group}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {training.datasetModifiedAt
              ? moment(training.datasetModifiedAt).format("YYYY-MM-DD HH:mm")
              : null}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {moment(training.training_invoked_at).format("YYYY-MM-DD HH:mm")}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {moment(training.training_finished_at).format("YYYY-MM-DD HH:mm")}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {status === "training" ? (
              <CircularProgress size={19} />
            ) : (
              <TooltipComponent
                title={tooltipInnerStatus}
                onOpen={() => {
                  setOpenStatusTooltip(!openStatusTooltip);
                }}
              >
                {status === "not the best" ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : status === "selected" ? (
                  <StarsIcon style={{ color: "green" }} />
                ) : (
                  <ErrorIcon style={{ color: "red" }} />
                )}
              </TooltipComponent>
            )}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {group.label}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            <TooltipComponent
              title={toolTipInnerInfo}
              onOpen={() => {
                setOpenOtherInfoTooltip(!openOtherInfoTooltip);
              }}
            >
              <InfoRounded />
            </TooltipComponent>
          </TableCell>
        </TableRow>
        {isTaskId ? (
          <TableRow>
            <TableCell
              className={`${classes.smallPadding} ${classes.td}`}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              colSpan={10}
            >
              <Table className={classes.insdeTable}>
                <CustomModelDetailHead isTraining={true} />
                {group.trainings.map((item: ICustomModel) => (
                  <CustomModelDetailBodyTrainings training={item} />
                ))}
              </Table>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </TableBody>
    </>
  );
};

export default CustomModelBodyTraining;
