import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  id: number;
  note: string;
};

export default async function patchEventComment({ id, note }: TParams) {
  const url = endpoints.events.editComment(id);
  try {
    const response = await axiosNeuron.patch(url, { note });
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
