import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { EActions, IActivateDeviceActions } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";
import getCompanies from "api/handlers/company/getCompanies";
import getProjects from "api/handlers/project/getProjects";
import { ICompanyList } from "types/company";

export const path = "activateDevice";

const actionsData = [
  [EActions.setSelectedProject, "selectedProject"],
  [EActions.setLoading, "loading"],
  [EActions.setLocalCompanies, "localCompanies", "pageCount"],
  [EActions.setLocalProjects, "localProjects"],
  [EActions.setPage, "page"],
  [EActions.setPageCount, "pageCount"],
  [EActions.setReset],
];

const actions = prepareActions<IActivateDeviceActions, EActions>(
  actionsData,
  path
);

export default actions;

export const fetchData =
  ({ page }: { page: number }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions[EActions.setLoading](true));
      const companies = await getCompanies({ pageSize: 10, page });
      if (companies?.results) {
        dispatch(actions.setLocalCompanies(companies.results, companies.count));
        const companyIds = companies.results.map(
          (item: ICompanyList) => item.id
        );
        const projects = await getProjects({
          companyIds: [...companyIds],
        });
        if (projects?.results) {
          dispatch(actions.setLocalProjects(projects.results));
          dispatch(actions.setLoading(false));
        }
      }
    } catch (error) {
      dispatch(actions[EActions.setLoading](false));
    }
  };
