import { getDeviceNameWithPrefix } from "shared/helpers";

const convertEventFromAPI = (result: any, usersIds: number[]) => {
  if (result.created_by_user) {
    usersIds.push(result.created_by_user);
  }
  if (result.modified_by_user) {
    usersIds.push(result.modified_by_user);
  }
  if (result.meta && result.meta.user) {
    usersIds.push(result.meta.user);
  }
  return {
    id: result.id,
    type: result.type,
    machine: result.machine,
    meta: result.meta
      ? {
          ...result.meta,
          deviceSerialUid: result.meta.device_serial_uid || null,
          deviceName: getDeviceNameWithPrefix(result.meta.device_name) || null,
          channelNo: result.meta.channel_no || null,
          placementTypeName: result.meta.placement_name || null,
          customPlacementName: result.meta.custom_placement_name || null,
          newValue: result.meta.new_value,
          oldValue: result.meta.old_value,
        }
      : null,
    relatedEvent: result.related_event
      ? {
          id: result.related_event.id,
          createdAt: result.related_event.created_at,
          meta: result.related_event.meta
            ? {
                ...result.related_event.meta,
                deviceSerialUid: result.related_event.meta.device_serial_uid,
                deviceName:
                  getDeviceNameWithPrefix(result.related_event.meta.name) ||
                  null,
                channelNo: result.related_event.meta.channel_no,
                placementTypeName: result.related_event.meta.placement_name,
              }
            : null,
          type: result.related_event.type,
          note: result.related_event.note,
          machine: result.related_event.machine,
          createdByUser: result.created_by_user,
          modifiedByUser: result.modified_by_user,
        }
      : null,
    alert_severity: result.alert_severity,
    maintenance_type: result.maintenance_type,
    createdAt: result.created_at,
    note: result.note,
    createdByUser: result.created_by_user,
    modifiedByUser: result.modified_by_user,
    placements: result.placements,
    relatedPairEvent: result.related_pair_event,
    status: result.status,
    isCritical: result.is_critical,
    presumedCauses: result.presumed_causes,
    recommendAction: result.recommended_action,
    anomalyTrend: result.anomaly_trend,
    diagDescription: result.diag_description,
    isHidden: result.is_hidden,
    hasAttachment: result.has_attachment,
    mailSent: result.mail_sent,
    mailLastSentOn: result.mail_last_sent_on,
    groupedAlerts: result.grouped_alerts,
    masterAlert: result.master_alert,
    estimated_prevented_damage_amount: result.estimated_prevented_damage_amount,
    estimated_prevented_damage_currency:
      result.estimated_prevented_damage_currency,
  };
};

export default convertEventFromAPI;
