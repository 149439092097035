import React from "react";
import WorkIcon from "@mui/icons-material/Work";
import { makeStyles } from "@mui/styles";
import { CellProps } from "react-table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Name from "components/table/cells/name";
import { ActionsButton } from "./actionsButton";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const Combined = <T extends Object>({
  cell: {
    value: { name, id, path },
  },
}: CellProps<T>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Box display="flex" alignItems="center" flex={1}>
          <WorkIcon className={classes.icon} />
          <Name cell={{ value: { label: name, id, path } }} />
        </Box>
        <Grid item>
          <ActionsButton cell={{ value: { id } }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Combined;
