import React from "react";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";
import { Theme } from "@mui/material";

interface Props {
  children: React.ReactNode;
  clear?: boolean | string;
  style?: {};
}

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    root: {
      height: "100%",
      borderRadius: theme.spacing(1, 1, 0, 0),
      background: theme.custom.palette.surface,
      boxShadow: theme.custom.boxShadow[2],
      padding: theme.spacing(0, 2),
      marginTop: "1rem",
      [theme.breakpoints.up("sm")]: {
        borderRadius: "0",
        background: "transparent",
        boxShadow: theme.custom.boxShadow[0],
        padding: 0,
        flexDirection: "column",
        display: "flex",
        flex: 1,
      },
    },
    clear: {
      height: 1,
    },
  };
});

const Content = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root} {...props}>
      {props.clear === "true" && <div className={classes.clear}></div>}
      {props.children}
      {props.clear === "true" && <div className={classes.clear}></div>}
    </div>
  );
};

export default Content;
