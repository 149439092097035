import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface Params {
  ids: number[];
}

export default async function getMachineDeviceHealth(params: Params) {
  const url = endpoints.devices.health.machines;
  if (params.ids && !params.ids.length) {
    return {};
  }
  const ids = params.ids ? `&id=${params.ids.join("&id=")}` : "";
  try {
    const { data } = await axiosNeuron.get(`${url}?${ids}`);
    // 0 = ok, 1 = error, 2 = offline
    const level = (i: any) =>
      i.device_health === "on_ok" ? 0 : i.device_health === "on_not_ok" ? 1 : 2;
    const aggregate = (acc: number | undefined, cur: any) =>
      acc === undefined ? level(cur) : Math.max(acc, level(cur));
    const health = data.results.reduce(
      (acc: any, cur: any) => ({
        ...acc,
        [cur.machine]: aggregate(acc[cur.machine], cur),
      }),
      {}
    );
    return health;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
