import React, { PureComponent } from "react";

const AXIS_COLOR = "rgba(0, 0, 0, 0.65)";

class FftVerticalTick extends PureComponent {
  static defaultProps = {
    top: 0,
    height: 0,
    width: 20,
    color: AXIS_COLOR,
    lineWidth: 1,
    label: undefined,
    last: false,
  };

  render() {
    let { label, top, height, width, color, lineWidth, last } = this.props;

    return (
      <div
        className="vertical-tick"
        style={{
          position: "absolute",
          top,
          height,
          width,
          borderTop: `solid ${lineWidth}px ${color}`,
          borderRight: last ? "none" : `solid ${lineWidth}px ${color}`,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 4,
            top: 2,
            fontWeight: "normal",
          }}
        >
          {label}
        </div>
      </div>
    );
  }
}

export default FftVerticalTick;
