import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Spinner from "components/spinner";
import moment from "moment";
import HorizontalLine from "components/horizontalLine";
import Filter from "../components/filters";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import useData from "dataHooks/eventSounds";
import { createColumns } from "./columns";
import { createTinyColumns } from "./tinyColumns";
import { IDataSelection, IGroupedEventSoundList } from "types/eventSounds";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { CommaArrayNumericParam, CommaArrayParam } from "shared/customQueries";
import getDecodedFile from "api/handlers/eventSounds/getDecodedFile";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Footer from "../components/footer";
import SaveModal from "../components/saveDatasetModal";
import actions, { getLabelData } from "store/eventSounds/actions";
import { AppState } from "store";
import Button from "components/button";

const DataSelectionTab: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch: any = useDispatch();

  const [query, setQuery] = useQueryParams({
    from: withDefault(StringParam, ""),
    to: withDefault(StringParam, ""),
    event_type: withDefault(CommaArrayParam, []),
    failure_type: withDefault(CommaArrayParam, []),
    placement_type: withDefault(CommaArrayNumericParam, []),
    machine_subcategory: withDefault(CommaArrayNumericParam, []),
    machine: withDefault(CommaArrayNumericParam, []),
    q: withDefault(StringParam, ""),
  });

  const [searchValues, setSearchValues] = useState<{
    from: Date | null;
    to: Date | null;
    event_type: string[] | null | any;
    failure_type: string[] | null | any;
    placement_type: number[] | null | any;
    machine_subcategory: number[] | null | any;
    machine: number[] | null | any;
    q: string | null | any;
  }>({
    from: query.from ? moment(query?.from).toDate() : null,
    to: query.to ? moment(query?.to).toDate() : null,
    event_type: query.event_type.length ? query.event_type : null,
    failure_type: query.failure_type.length ? query.failure_type : null,
    placement_type: query.placement_type.length ? query.placement_type : null,
    machine_subcategory: query.machine_subcategory.length
      ? query.machine_subcategory
      : null,
    machine: query.machine.length ? query.machine : null,
    q: query.q ? query.q : null,
  });

  const {
    loading,
    eventSoundsData,
    initialState,
    showMoreClickedTimesRef,
    isSearchedClicked,
    onPageChange,
  } = useData({ searchValues });

  const { selectedChunks } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const menuOpened = useSelector(
    (state: AppState) => state.layout.menu,
    shallowEqual
  );

  const ctx = useMemo(() => getAudioContext(), []);
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const [loadedEvents, setLoadedEvents] = useState<any>([]);
  const [groupedEvents, setGroupedEvents] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const defaultChunks = upMd ? 50 : 10;
  const [chunksPerPage, setChunksPerPage] = useState(defaultChunks);

  const [audioChunks, setAudioChunks] = useState<any>(groupedEvents);

  const {
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    machine,
    q,
  } = searchValues;

  useEffect(() => {
    setQuery(
      {
        from: from?.toISOString(),
        to: to?.toISOString(),
        event_type: event_type,
        failure_type: failure_type,
        placement_type: placement_type,
        machine_subcategory: machine_subcategory,
        machine: machine,
        q: q,
      },
      "pushIn"
    );
  }, [
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    machine,
    q,
    setQuery,
  ]);

  useEffect(() => {
    if (eventSoundsData && eventSoundsData.results.length) {
      const machineIds = eventSoundsData.results.map(
        (item: IDataSelection) => item.machine
      );
      const placementIds = eventSoundsData.results.map(
        (item: IDataSelection) => item.placement
      );
      const uniqueMachineIds = machineIds.filter(
        (id: number, idx: number, self: any) => {
          return self.indexOf(id) === idx;
        }
      );
      const uniquePlacementIds = placementIds.filter(
        (id: number, idx: number, self: any) => {
          return self.indexOf(id) === idx;
        }
      );

      if (uniqueMachineIds.length && uniquePlacementIds.length) {
        dispatch(
          getLabelData({
            machineIds: uniqueMachineIds,
            placementIds: uniquePlacementIds,
          })
        );
      }
    }
  }, [dispatch, eventSoundsData]);

  useEffect(() => {
    setAudioChunks(groupedEvents);
  }, [groupedEvents]);

  useEffect(() => {
    const fetchData = async () => {
      const initialData = eventSoundsData?.results?.slice(0, defaultChunks);

      if (initialData) {
        const promises = initialData.map(async (chunk: IDataSelection) => {
          const buffer = await getDecodedFile({ url: chunk.audio_chunk.url });
          const res = {
            ...chunk,
            audio_chunk: { ...chunk.audio_chunk, buffer },
          };
          return res;
        });

        const updatedData = promises.length ? await Promise.all(promises) : [];
        setLoadedEvents(updatedData);
      }
    };

    fetchData();
  }, [eventSoundsData, ctx, defaultChunks]);

  const loadMoreCards = () => {
    const startIndex = currentPage * chunksPerPage;
    const endIndex =
      chunksPerPage === 50 ? defaultChunks + 10 : startIndex + chunksPerPage;
    const newCards = eventSoundsData?.results?.slice(startIndex, endIndex);
    const fetchData = async () => {
      if (newCards) {
        const promises = newCards.map(async (chunk: IDataSelection) => {
          try {
            const buffer = await getDecodedFile({
              url: chunk?.audio_chunk?.url,
            });
            const res = {
              ...chunk,
              audio_chunk: { ...chunk.audio_chunk, buffer },
            };
            return res;
          } catch (error) {
            throw new Error(`${error}`);
          }
        });
        const updatedData = await Promise.all(promises);

        setLoadedEvents((prevLoadedChunks: any) => {
          const uniqueIds = new Set(
            prevLoadedChunks.map((item: any) => item?.audio_chunk?.id)
          );

          const filteredUpdatedData = updatedData?.filter(
            (item: any) => !uniqueIds.has(item?.audio_chunk?.id)
          );
          return [...prevLoadedChunks, ...filteredUpdatedData];
        });
      }
    };

    fetchData();

    setCurrentPage(currentPage + 1);
    setChunksPerPage(10);
  };

  const handlePageChange = (pageIndex: number) => {
    setChunksPerPage(50);
    setCurrentPage(1);
    onPageChange(pageIndex);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 &&
      loadedEvents.length < eventSoundsData?.results.length
    ) {
      loadMoreCards();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadedEvents, handleScroll]);

  useEffect(() => {
    const filteredData = loadedEvents.filter(
      (item: IDataSelection) => item !== undefined
    );
    const result = filteredData?.reduce(
      (acc: IGroupedEventSoundList[], chunk: IDataSelection) => {
        const key = `${chunk.machine} / ${chunk.placement}`;

        const matchingGroup = acc.find(
          (group: IGroupedEventSoundList) => group.label === key
        );

        if (matchingGroup) {
          matchingGroup.chunks.push(chunk);
        } else {
          acc.push({ label: key, chunks: [chunk] });
        }

        return acc;
      },
      []
    );
    setGroupedEvents(result);
  }, [loadedEvents, onPageChange, showMoreClickedTimesRef]);

  const setIsPlaying =
    ({ id, key }: any) =>
    (value: boolean) =>
      setAudioChunks((currentAudioChunks: any) => {
        return currentAudioChunks.map((item: any) => {
          return {
            ...item,
            chunks: item.chunks.map((chunk: any) => {
              if (chunk.id === id) {
                return {
                  ...chunk,
                  isPlaying: value,
                };
              } else if (value) {
                return {
                  ...chunk,
                  isPlaying: false,
                };
              } else {
                return chunk;
              }
            }),
          };
        });
      });

  const setPlayingAll = useCallback(() => {
    setAudioChunks((currentAudioChunks: any) => {
      return currentAudioChunks.map((item: any) => {
        return {
          ...item,
          chunks: item.chunks.map((chunk: any) => ({
            ...chunk,
            isPlaying: false,
          })),
        };
      });
    });
  }, []);

  const columnsMemoized = useMemo(
    () => createColumns(t, setIsPlaying, setPlayingAll),
    [t, setIsPlaying]
  );
  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(t, setIsPlaying, setPlayingAll),
    [t, setIsPlaying, setPlayingAll]
  );
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  const hideNoData = !loading && eventSoundsData?.results.length;

  const resetState = useCallback(() => {
    dispatch(actions.setSelectedChunks([]));
  }, [dispatch]);

  const onSave = useCallback(() => {
    dispatch(actions.setOpenModal(true));
  }, [dispatch]);

  const handleSelectAll = useCallback(() => {
    const newSelectedData = eventSoundsData.results
      .filter(
        (item: IDataSelection) =>
          !selectedChunks.some(
            (chunk: any) =>
              chunk.placement === item.placement &&
              chunk.machine === item.machine &&
              new Date(chunk.representation_start).toISOString() ===
                new Date(item.audio_chunk.start_datetime).toISOString() &&
              new Date(chunk.representation_end).toISOString() ===
                new Date(item.audio_chunk.end_datetime).toISOString()
          )
      )
      .map((item: IDataSelection) => ({
        placement: item.placement,
        start: item.start_event.created_at,
        end: item.end_event.created_at,
        representation_start: item.audio_chunk.start_datetime,
        representation_end: item.audio_chunk.end_datetime,
        machine: item.machine,
      }));

    dispatch(
      actions.setSelectedChunks([...selectedChunks, ...newSelectedData])
    );
  }, [dispatch, eventSoundsData, selectedChunks]);

  const handleDeselectAll = useCallback(() => {
    const newData = selectedChunks.filter(
      (chunk: any) =>
        !eventSoundsData.results.some(
          (item: IDataSelection) =>
            chunk.placement === item.placement &&
            chunk.machine === item.machine &&
            new Date(chunk.representation_start).toISOString() ===
              new Date(item.audio_chunk.start_datetime).toISOString() &&
            new Date(chunk.representation_end).toISOString() ===
              new Date(item.audio_chunk.end_datetime).toISOString()
        )
    );
    dispatch(actions.setSelectedChunks(newData));
  }, [selectedChunks, eventSoundsData]);

  return (
    <>
      <Filter searchValues={searchValues} setSearchValues={setSearchValues} />
      <HorizontalLine />

      {loading ? (
        <Spinner />
      ) : (
        <Box
          style={{
            paddingBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          {isSearchedClicked && (
            <div
              style={{ display: "flex", gap: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <Button color="primary" onClick={handleSelectAll}>
                {t("dataExplorer.selectAll")}
              </Button>
              <Button
                color="primary"
                disabled={!selectedChunks.length}
                onClick={handleDeselectAll}
              >
                {t("dataExplorer.deselectAll")}
              </Button>
            </div>
          )}
          {eventSoundsData?.results.length && !groupedEvents.length ? (
            <Spinner />
          ) : null}
          {isSearchedClicked && (
            <Table<IGroupedEventSoundList | any>
              showMoreClickedTimesRef={showMoreClickedTimesRef}
              isLoading={
                loading ||
                (eventSoundsData?.results.length && !groupedEvents.length)
              }
              data={audioChunks}
              rowsCount={eventSoundsData ? eventSoundsData.count : 0}
              columns={columns}
              initialState={initialState}
              onPageChange={handlePageChange}
              RowComponent={Row}
              HeaderComponent={Header}
              RowComponentProps={{
                rowWidth: "100%",
              }}
              hideNoData={hideNoData}
              lazyLoad={
                !loading &&
                eventSoundsData?.results.length > loadedEvents.length
              }
              footerSpacing={!!selectedChunks.length}
            />
          )}
        </Box>
      )}
      <Footer
        opened={menuOpened}
        onSave={onSave}
        resetState={resetState}
        data={selectedChunks}
        cancel="cancel"
        cta="confirmationBar.saveDataset"
      />
      <SaveModal />
    </>
  );
};
export default DataSelectionTab;
