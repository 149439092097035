import { SortingRule } from "react-table";
import { IExtendedDevice, IDeviceStatus } from "types/device";
import { ResponseType } from "types/response";
import { IFetchDevicesParams } from "api/handlers/device/getDevicesExtended";

export enum EActions {
  setDevicesPage = "setDevicesPage",
  setDevicesFilter = "setDevicesFilter",
  setDevicesOrderBy = "setDevicesOrderBy",
  setDevicesMore = "setDevicesMore",
  resetDevices = "resetDevices",
  fetchDataRequest = "fetchDataRequest",
  fetchDataSuccess = "fetchDataSuccess",
  fetchDataFail = "fetchDataFail",
  setFilter = "setFilter",
}

export interface IDevicesListState {
  pageIndex: number;
  filters?: { [type: string]: string[] };
  orderBy?: SortingRule<number>[] | null;
  q?: string;
  ids?: number[];
  devices?: ResponseType<IExtendedDevice> | null;
  devicesFetchParams?: IFetchDevicesParams | null;
  deviceStatuses?: IDeviceStatus[];
  isFetching?: boolean;
  error?: Error | null;
  more?: number;
  isFiltered?: boolean;
}

export interface IDevicesAction {
  type: typeof EActions;
  payload: IDevicesListState;
}

export type TReducers = {
  [key: string]: (p: IDevicesAction, s: IDevicesListState) => void;
};
