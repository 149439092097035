import React, { PureComponent } from "react";
import * as utils from "./utils";

const AXIS_COLOR = "rgba(0, 0, 0, 0.65)";

class Tick extends PureComponent {
  static defaultProps = {
    draw: "wave",
    left: 0,
    width: 0,
    widthLeft: undefined,
    height: 10,
    color: AXIS_COLOR,
    lineWidth: 1,
    timestamp: undefined,
    hideTimeLabel: false,
  };

  render() {
    let {
      timestamp,
      left,
      width,
      widthLeft,
      height,
      color,
      lineWidth,
      hideTimeLabel,
    } = this.props;

    let tickLineWidth = 0,
      label,
      labelStyle;
    if (timestamp !== null && timestamp !== undefined) {
      tickLineWidth = lineWidth;
      label = timestamp % 60;
      labelStyle = {
        position: "absolute",
        left: 2,
        top: 2,
        fontWeight: "normal",
      };
      if (label === 0) {
        if (!hideTimeLabel) {
          label = utils.timestampToString(timestamp);
          height = height * 2;
          tickLineWidth += 1;
        } else {
          label = `${timestamp / 60}:00`;
        }
        labelStyle.top = height + 2;
        labelStyle.fontWeight = "bold";
      } else {
        label = "" + label;
      }
    }

    return (
      <div
        className="wave-tick"
        style={{
          position: "absolute",
          left,
          width,
          height,
          borderLeft:
            timestamp === undefined
              ? "none"
              : `solid ${tickLineWidth}px ${color}`,
          borderTop:
            widthLeft === null || widthLeft === undefined || widthLeft < width
              ? "none"
              : `solid ${lineWidth}px ${color}`,
        }}
      >
        {timestamp !== undefined && <div style={labelStyle}>{label}</div>}
      </div>
    );
  }
}

export default Tick;
