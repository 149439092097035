import React, { memo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import { Field } from "react-final-form";
import { useStyles } from "components/events/styles";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface IEventTypeSelect {
  event: string;
  eventTypes: any[];
  disabled: boolean;
  setEvent: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

export const EventTypesSelect = memo(
  ({ event, eventTypes, setEvent, disabled }: IEventTypeSelect) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
      auth: { user },
    } = useSelector((state: AppState) => state);
    const isAdmin = user?.role === "nsw_admin";

    return (
      <Box className={classes.select}>
        <Field
          id="eventTypesSelect"
          name="eventTypesSelect"
          component={Select}
          label={t("eventModal.titles.eventTypes")}
          options={eventTypes.filter((i: any) => !i.requireAdmin || isAdmin)}
          disabled={disabled}
          required
          onChange={setEvent}
          initialValue={event}
        />
      </Box>
    );
  }
);
