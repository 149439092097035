import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IAttachmentList } from "types/event";

interface IAttachmentParams {
  event?: number;
  machine?: number;
  pageSize?: number;
  pageIndex?: number;
}

async function getUserMap(userIds: number[]) {
  if (!userIds || !userIds.length) {
    return {};
  }
  const { data: userData } = await axiosNeuron.get(
    `${endpoints.users.default}?id=${userIds.join("&id=")}&ps=10000`
  );
  return userData.results.reduce(
    (
      out: any,
      user: {
        id: number;
        username: string;
        first_name: string;
        last_name: string;
      }
    ) => ({
      ...out,
      [user.id]:
        user.first_name || user.last_name
          ? `${user?.first_name} ${user?.last_name}`
          : user.username,
    }),
    {}
  );
}

async function getEventMap(eventIds: number[]) {
  if (!eventIds || !eventIds.length) {
    return {};
  }
  const { data: eventData } = await axiosNeuron.get(
    `${endpoints.events.default}?id=${eventIds.join("&id=")}&ps=10000`
  );
  return eventData.results.reduce(
    (
      out: any,
      event: {
        id: number;
        type: string;
        machine: number;
      }
    ) => ({
      ...out,
      [event.id]: {
        type: event.type,
        machine: event.machine,
      },
    }),
    {}
  );
}

export default async function getAttachments({
  event,
  machine,
  pageSize,
  pageIndex,
}: IAttachmentParams): Promise<{
  count: number;
  attachments: IAttachmentList;
}> {
  const url = event
    ? endpoints.events.attachments(event)
    : endpoints.machines.attachments(machine!);

  try {
    const { data } = await axiosNeuron.get(
      `${url}&ps=${pageSize}&page=${
        pageIndex! + 1
      }&order_by=-created_at&order_by=-id`
    );
    const results = data.results.map((i: any) => ({
      ...i,
      userId: i.updated_by_user ? i.updated_by_user : i.created_by_user,
    }));
    const userMap = await getUserMap(
      Array.from(
        new Set<number>(
          results.map((i: any) => i.userId).filter((i: any) => i !== null)
        )
      )
    );
    const eventMap = await getEventMap(
      Array.from(
        new Set<number>(
          results.map((i: any) => i.event).filter((i: any) => i !== null)
        )
      )
    );
    return {
      attachments: results.map((i: any) => ({
        ...i,
        user: userMap[i.userId] ? userMap[i.userId] : null,
        eventType: eventMap[i.event] ? eventMap[i.event].type : null,
        machine: eventMap[i.event] ? eventMap[i.event].machine : null,
      })),
      count: data.count,
    };
  } catch (error) {
    throw error;
  }
}
