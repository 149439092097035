import { ignoreErrors as axiosNeuron } from "api/axios";
import endpoints from "api/endpoints";

export default async function fetchLastModelActivity(results: any) {
  const url = endpoints.modelResults.lastActivity;
  const deploymentIds = results
    .map((model: any) => model.deployments)
    .reduce((cur: any, acc: any) => [...cur, ...acc], [])
    .map((deployment: any) => deployment.id);
  const lastActivity: { [key: number]: string } = {};
  try {
    while (deploymentIds.length) {
      const { data } = await axiosNeuron.get(
        `${url}?id=${deploymentIds.splice(0, 100).join("&id=")}`
      );
      data.results.forEach((item: any) => {
        lastActivity[item.id] = item.last_result;
      });
    }
    results.forEach((model: any) =>
      model.deployments.forEach((deployment: any) => {
        deployment.lastActivity = lastActivity[deployment.id];
      })
    );
  } catch (error) {
    if (error !== undefined) {
      throw new Error(`Error fetching model last activity: ${error}`);
    }
    throw error;
  }
}
