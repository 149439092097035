import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  trained_model_id: number;
};

export default async function archiveModel(machine: number, data: Params) {
  const url = endpoints.machines.archiveModel(machine);
  try {
    const response = await axiosNeuron.patch(url, data);
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
