import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Spinner from "components/spinner";
import appUrls from "shared/appUrls";
import Divider from "components/typography/heading/divider";
import Device from "components/device";

import { IExtendedDevice } from "types/device";
import actions, { fetchDevices } from "store/devicesList/actions";
import { AppState } from "store";
const { resetDevices } = actions;

type TProps = {
  devices: number[];
};

const ConnectedDevices = ({ devices }: TProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const history = useNavigate();

  const { devices: devicesData, isFetching } = useSelector(
    (state: AppState) => state.devicesList
  );

  const user = useSelector((state: AppState) => state.auth.user);

  const navigateToDetail = useCallback(
    (id: any) => {
      history(appUrls.devices.detail(id));
    },
    [history]
  );

  useEffect(() => {
    dispatch(
      fetchDevices({
        page: 1,
        pageSize: 500,
        orderBy: [],
        filterObject: {},
        ids: devices,
      })
    );
  }, [devices, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetDevices());
    };
  }, [dispatch]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <Divider line>{t("machine.detail.connectedDevices")}</Divider>
      <Box mb={1} display="flex" flexWrap="wrap" marginRight={-3}>
        {devicesData &&
          devicesData.results.map((device: IExtendedDevice) => {
            return (
              <Box
                mr={3}
                mb={3}
                key={device.serialUid}
                className="testConnectedDevice"
              >
                <Device
                  id={device.id}
                  name={device.name}
                  deviceType={device.type}
                  onClick={
                    user && !user.isInternal ? navigateToDetail : undefined
                  }
                />
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default ConnectedDevices;
