import { IPlacementList } from "types/placement";

export enum EActions {
  setWizardOpen = "setWizardOpen",
  setRecordingStart = "setRecordingStart",
  setUploadingStart = "setUploadingStart",
  setRecordingStartDatetime = "setRecordingStartDatetime",
  setUploadingStartDatetime = "setUploadingStartDatetime",
  reset = "reset",
  setAudioReady = "setAudioReady",
  setUploadAgain = "setUploadAgain",
  setPlacementsStart = "setPlacementsStart",
  setPlacementsSuccess = "setPlacementsSuccess",
  setPayloadPlacements = "setPayloadPlacements",
  setMetaData = "setMetaData",
  setSavingStart = "setSavingStart",
  setSavingEnd = "setSavingEnd",
  setDeviceId = "setDeviceId",
  setUploadedAudioDuration = "setUploadedAudioDuration",
  setAudioOverlapped = "setAudioOverlapped",
}

export interface IAudioUploaderState {
  wizardOpen: boolean;
  recordingStart: boolean;
  recordingStartDatetime: string | null;
  uploadingStartDatetime: string | null;
  uploadingStart: boolean;
  uploading: false;
  error?: Error | null;
  introPage: boolean;
  audioReady: boolean;
  audioData: string;
  placements: IPlacementList | undefined;
  placementsLoading: false;
  payloadPlacements: number[];
  metaData: {
    [key: string]: string;
  };
  saving: boolean;
  stereo: boolean;
  deviceId?: string;
  uploadedAudioDuration?: number;
  audioOverlapped?: any | null;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IAudioUploaderState;
}
