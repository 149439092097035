import React, { useCallback, useMemo, useState } from "react";
import { Box, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import moment from "moment";
import Picker from "localization/pickerLocale";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import clsx from "clsx";
import Search from "components/search";

type TProps = {
  from: Date | null;
  to: Date | null;
  status: string | null;
  archived: string | null;
  setFrom: React.Dispatch<React.SetStateAction<Date>>;
  setTo: React.Dispatch<React.SetStateAction<Date>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setArchived: React.Dispatch<React.SetStateAction<string>>;
  datasetName: string;
  datasetId: string;
  machineName: string;
  customModelCategory: string;
  deploymentEnvironment: string;
  setCustomModelCategory: React.Dispatch<React.SetStateAction<string>>;
  setDatasetName: React.Dispatch<React.SetStateAction<string>>;
  setDatasetId: React.Dispatch<React.SetStateAction<string>>;
  setMachineName: React.Dispatch<React.SetStateAction<string>>;
  setDeploymentEnvironment: React.Dispatch<React.SetStateAction<string>>;
  isDeployment: boolean;
};

const CustomModelFilter = ({
  from,
  to,
  status,
  archived,
  datasetName,
  datasetId,
  machineName,
  customModelCategory,
  deploymentEnvironment,
  isDeployment,
  setFrom,
  setTo,
  setStatus,
  setArchived,
  setDatasetName,
  setDatasetId,
  setMachineName,
  setCustomModelCategory,
  setDeploymentEnvironment,
}: TProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const statuses = ["all", "deployed", "not_deployed"];
  const archivedOptions = ["all", "archived", "not_archived"];
  const customModelCategories = [
    { text: "all", value: "all" },
    { text: "regressor", value: "regressor" },
    { text: "multi_output_classifier", value: "multi_output_classifier" },
    { text: "single_output_classifier", value: "single_output_classifier" },
    { text: "anomaly_detector", value: "anomaly_detector" },
  ];
  const environments = ["all", "production", "stage", "other", "not_deployed"];

  return (
    <Box
      mt={4}
      mb={4}
      display="flex"
      flexWrap="wrap"
      alignSelf="baseline"
      className={classes.filters}
    >
      <Picker
        Component={DateTimePicker}
        className={clsx(classes.filter, classes.picker)}
        label={t("customModels.from")}
        value={from ? dayjs(from) : null}
        onChange={(date: any) => {
          if (!date || isNaN(date as any)) {
            return false;
          }
          setFrom(date);
        }}
        format="YYYY/MM/DD HH:mm:ss"
        disableFuture
        slotProps={{
          textField: {
            variant: "outlined",
          },
        }}
      />
      <Picker
        Component={DateTimePicker}
        className={clsx(classes.filter, classes.picker)}
        label={t("customModels.to")}
        value={to ? dayjs(to) : null}
        onChange={(date: any) => {
          if (!date || isNaN(date as any)) {
            return false;
          }
          setTo(date);
        }}
        format="YYYY/MM/DD HH:mm:ss"
        disableFuture
        minDate={dayjs(from) || undefined}
        slotProps={{
          textField: {
            variant: "outlined",
            error: from && to && from >= to,
          },
        }}
      />
      <FormControl variant="outlined" className={classes.filter}>
        <InputLabel id="trainingDestination">
          {t("customModels.deploymentEnvironment")}
        </InputLabel>
        <Select
          id="deploymentEnvironment"
          labelId="deploymentEnvironment"
          label={t("customModels.deploymentEnvironment")}
          value={deploymentEnvironment}
          onChange={(e: any) => {
            setDeploymentEnvironment(e.target.value);
          }}
        >
          {environments.map((option: string) => {
            return (
              <MenuItem value={option} key={option}>
                {t(`customModels.deploymentEnvironments.${option}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.filter}>
        <InputLabel id="trainingDestination">
          {t("customModels.customModelCategory")}
        </InputLabel>
        <Select
          id="customModelCategory"
          labelId="customModelCategory"
          label={t("customModels.customModelCategory")}
          value={customModelCategory}
          onChange={(e: any) => {
            setCustomModelCategory(e.target.value);
          }}
        >
          {customModelCategories.map(
            (option: { text: string; value: string }) => {
              return (
                <MenuItem value={option.value} key={option.text}>
                  {t(`customModels.customModelCategories.${option.text}`)}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.filter}>
        <InputLabel id="trainingDestination">
          {t("customModels.status")}
        </InputLabel>
        <Select
          id="status"
          labelId="status"
          label={t("customModels.status")}
          value={status}
          onChange={(e: any) => {
            setStatus(e.target.value);
          }}
        >
          {statuses.map((option: string) => {
            return (
              <MenuItem value={option} key={option}>
                {t(`customModels.statuses.${option}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.filter}>
        <InputLabel id="trainingDestination">
          {t("customModels.archived")}
        </InputLabel>
        <Select
          id="archived"
          labelId="archived"
          label={t("customModels.archived")}
          value={archived}
          onChange={(e: any) => {
            setArchived(e.target.value);
          }}
        >
          {archivedOptions.map((option: string) => {
            return (
              <MenuItem value={option} key={option}>
                {t(`customModels.archivedOptions.${option}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box className={clsx(classes.filter, classes.search)}>
        <Search
          placeholder={t("customModel.searchDatasetName")}
          hideIcon
          value={datasetName}
          isAutoSearch
          onSearch={(e) => {
            setDatasetName(e);
          }}
        />
      </Box>
      <Box className={clsx(classes.filter, classes.search)}>
        <Search
          placeholder={t("customModel.searchDatasetId")}
          hideIcon
          value={datasetId}
          isAutoSearch
          isNumber
          onSearch={(e) => {
            setDatasetId(e);
          }}
        />
      </Box>
      {isDeployment && (
        <Box className={clsx(classes.filter, classes.search)}>
          <Search
            placeholder={t("customModel.searchMachineName")}
            hideIcon
            value={machineName}
            isAutoSearch
            onSearch={(e) => {
              setMachineName(e);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomModelFilter;
