import { ignoreErrors as axiosNeuron } from "api/axios";
import endpoints from "api/endpoints";
import fetchLastModelActivity from "api/handlers/fetchLastModelActivity";

interface IEventParams {
  id: number;
}

export default async function getMachineModels(params: IEventParams) {
  const url = endpoints.machines.models;
  try {
    const { data } = await axiosNeuron.get(
      `${url}?machine=${params.id}&start_datetime=1970-01-01T00:00:00.000Z&end_datetime=2100-01-01T00:00:00.000Z&is_archived=false`
    );
    await fetchLastModelActivity(data.results);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw new Error(`Error fetching model information: ${error}`);
    }
    throw error;
  }
}
