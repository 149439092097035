import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
  const borderStyle = `${theme.spacing(0.125)} solid ${
    theme.custom.palette.border
  }`;
  return {
    container: {
      marginBottom: 20,
    },
    pointer: {
      cursor: "pointer",
    },
    bold: {
      fontWeight: "bold",
    },
    smallPadding: {
      padding: 6,
    },
    grey: {
      background: theme.custom.palette.modelInfo.grey,
    },
    lightGrey: {
      color: theme.custom.palette.modelInfo.lightGrey,
    },
    production: {
      color: "green",
    },
    test: {
      color: theme.custom.palette.modelInfo.test,
    },
    undeployedProduction: {
      color: "green",
    },
    undeployedTest: {
      color: theme.custom.palette.modelInfo.test,
    },
    training: {
      background: theme.custom.palette.modelInfo.training,
    },
    error: {
      background: theme.custom.palette.modelInfo.error,
    },
    partiallyDeployed: {
      color: theme.custom.palette.modelInfo.partiallyDeployed,
    },
    default: {
      background: theme.custom.palette.surface,
    },
    metricTooLow: {
      color: theme.custom.palette.alert.regular,
    },
    alignRight: {
      textAlign: "right",
      paddingRight: 20,
    },
    alignCenter: {
      textAlign: "center",
    },
    fill: {
      width: "100%",
    },
    smallSpinner: {
      marginLeft: 5,
      display: "inline-block",
      position: "relative",
      top: 2,
    },
    smallSpinnerTraining: {
      margin: 3,
      marginRight: 8,
      display: "inline-block",
      position: "relative",
      top: 2,
    },
    sectionHeading: {
      textTransform: "uppercase",
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(149, 37, 62, 1)",
      marginBottom: ".5rem",
      marginLeft: ".4rem",
    },
    actionButton: {
      fontSize: ".875rem",
      fontWeight: 400,
      textTransform: "unset",
      justifyContent: "unset",

      "&:hover": {
        backgroundColor: "unset",
      },
    },

    tdTableHead: {
      border: "none",
      backgroundColor: "white",
      fontSize: theme.custom?.typography.fontSize["12"],
      fontFamily: theme.custom?.typography.fontFamily,
      fontWeight: 500,
      userSelect: "none",
      textTransform: "uppercase",
    },

    td: {
      border: "none",

      "& .testKebabButton": {
        margin: 0,
      },
    },
    tdCollapsed: {
      border: "none",
      borderTop: borderStyle,
    },
    table: {
      borderSpacing: 0,
      borderCollapse: "separate",
      "& tr td:first-child": {
        borderLeft: borderStyle,
      },
      "& tr td:last-child": {
        borderRight: borderStyle,
      },
      "& tr:first-child td": {
        borderTop: borderStyle,
      },
      "& tr:last-child td": {
        borderBottom: borderStyle,
      },
      "& tr:first-child td:first-child": {
        borderTopLeftRadius: 10,
      },
      "& tr:first-child td:last-child": {
        borderTopRightRadius: 10,
      },
      "& tr:last-child td:first-child": {
        borderBottomLeftRadius: 10,
      },
      "& tr:last-child td:last-child": {
        borderBottomRightRadius: 10,
      },
    },
    insdeTable: {
      borderSpacing: 0,
      "& thead th": {
        borderTop: borderStyle,
      },
      "& tr td:first-child": {
        borderLeft: "none",
      },
      "& tr td:last-child": {
        borderRight: "none",
      },
      "& tr:first-child td": {
        borderTop: borderStyle,
      },
      "& tr:last-child td": {
        borderBottom: "none",
      },
    },
    tableHeader: {
      position: "relative",
      top: 0,
    },
    tableContent: {},
    tr: {
      "&:hover": {
        background: theme.custom.palette.primary[4],
      },
    },

    break: {
      height: theme.spacing(1),
    },

    statusPadding: {
      paddingLeft: ".3rem",
    },

    metricSanity: {
      display: "flex",
      alignItems: "flex-end",
      gap: ".4rem",
    },
  };
});
