import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface Params {
  id: number;
  createdFor?: string;
}

export default async function getFilteredReports(params: Params) {
  const url = endpoints.reports.default;

  try {
    if (!params.createdFor || params.createdFor === undefined) {
      return null;
    }
    const { data } = await axiosNeuron.get(
      `${url}?order_by=&project=${params.id}&created_for=${params.createdFor}`
    );
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
