import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import type { TEventPlacementFailure } from "types/failureTypes";
export default async function getFailures(
  eventId: number
): Promise<TEventPlacementFailure[] | undefined> {
  const url = endpoints.failure.eventFailures(eventId);

  try {
    const { data } = await axiosNeuron.get(url);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
