import { Column } from "react-table";
import { TFunction } from "i18next";
import { MobileCell } from "./components/mobileCell";

export const createTinyColumns = (
  t: TFunction,
  permission: boolean,
  allowSettingGain: boolean
): Column<any>[] => [
  {
    Header: "Channel",
    id: "channel",
    disableSortBy: true,
    accessor: (props: any) => ({
      ...props,
      allowSettingGain,
      t,
    }),
    Cell: MobileCell,
    custom: {
      hidePriority: 0,
      margin: "0 1rem",
      width: "50%",
    },
  },
];
