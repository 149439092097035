import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import { StepType } from "components/stepModal";
import StepLabel from "./stepLabel";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      flexShrink: 0,
      boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
    },
    stepper: {
      background: "transparent",
      padding: "2rem 2rem 1rem",
    },
  };
});

const Connector = withStyles((theme: any) => ({
  line: {
    borderColor: theme.custom.palette.placeholder,
  },
}))(StepConnector);

type Props = {
  steps: StepType[];
  activeStep: number;
  handleActive: (id: number) => void;
};

const DesktopHeader = ({ steps, activeStep, handleActive }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<Connector />}
        className={classes.stepper}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              label={step.label}
              handleActive={handleActive}
              activeStep={activeStep}
              index={index}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default DesktopHeader;
