import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  cpWrapper: {
    background: theme.custom.palette.surface,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(2),
  },
  companyWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    color: theme.custom.palette.data,
    "&:hover": {
      background: "initial",
    },
  },
  company: {
    margin: theme.spacing(2),
  },
  projectsWrapper: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(4),
  },
}));
