import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IModelsAction } from "./types";
import { AppState } from "store";
import getModels from "api/handlers/getModels";
import moment from "moment";
export const path = "models";

const pairs = [
  [EActions.FETCH_MODELS_REQUEST],
  [EActions.FETCH_MODELS_FAIL, "error"],
  [EActions.FETCH_MODELS_SUCCESS, "models"],
  [EActions.setReset],
];

const actions = prepareActions<IModelsAction, EActions>(pairs, path);

export default actions;

export const fetchModels =
  (
    startDatetime = moment().subtract(1, "month").toDate(),
    endDatetime = new Date(),
    trainingDestination = "all",
    deploymentEnvironment = "all",
    running = "all",
    archived = "not_archived",
    silent = false
  ) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    !silent && dispatch(actions[EActions.FETCH_MODELS_REQUEST]());
    try {
      const response = await getModels({
        startDatetime,
        endDatetime,
        trainingDestination:
          trainingDestination === "all" ? undefined : trainingDestination,
        deploymentEnvironment:
          deploymentEnvironment === "all" ? undefined : deploymentEnvironment,
        running: running === "all" ? undefined : running === "running",
        archived: archived === "all" ? undefined : archived === "archived",
      });
      if (response) {
        dispatch(actions[EActions.FETCH_MODELS_SUCCESS](response));
      }
    } catch (error) {
      dispatch(actions[EActions.FETCH_MODELS_FAIL](error));
    }
  };
