import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import uniq from "lodash/uniq";
import { TEventComment, TCommentWithMappedUser } from "types/event";
import getUsers from "../user/getUsers";

export default async function getEventComments(
  id: number
): Promise<TCommentWithMappedUser[] | undefined> {
  const url = endpoints.events.eventComments(id);

  try {
    let { data } = await axiosNeuron.get(`${url}`);
    const users = data.reduce((acc: number[], curr: TEventComment) => {
      let cAcc = [...acc];
      if (curr.created_by_user) {
        cAcc = [...cAcc, curr.created_by_user];
      }
      if (curr.modified_by_user) {
        cAcc = [...cAcc, curr.modified_by_user];
      }

      return cAcc;
    }, [] as number[]);

    if (users.length && data) {
      const userData = await getUsers({
        page: 1,
        pageSize: 100,
        orderBy: [],
        filterObject: { id: uniq<number>(users) },
      });

      data = data.map((item: TEventComment) => {
        const created = userData?.results.find(
          (user: any) => user.id === item.created_by_user
        );
        const modified = userData?.results.find(
          (user: any) => user.id === item.modified_by_user
        );
        let newItem: TCommentWithMappedUser = { ...item };
        if (created) {
          newItem = {
            ...newItem,
            created_by_user_mapped: created.firstName + " " + created.lastName,
          };
        }
        if (modified) {
          newItem = {
            ...newItem,
            modified_by_user_mapped:
              modified.firstName + " " + modified.lastName,
          };
        }

        return newItem;
      });
    }

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
