import React, { PureComponent } from "react";
import FftVerticalTicks from "./FftVerticalTicks";

class FftVerticalAxis extends PureComponent {
  static defaultProps = {
    width: 20,
    margin: 5,
  };
  render() {
    let { margin, ...props } = this.props;
    return (
      <div
        className="fft-vertical-axis"
        style={{
          marginLeft: margin,
          marginRight: margin,
          position: "relative",
        }}
      >
        <FftVerticalTicks {...props} />
      </div>
    );
  }
}

export default FftVerticalAxis;
