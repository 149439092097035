import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface Payload {
  type?: number;
  machine?: number;
  createdAt?: Date;
  note?: string;
  status?: string | null;
  is_critical?: boolean | null;
  presumed_causes?: string | null;
  recommended_action?: string | null;
  anomaly_trend?: string | null;
  diag_description?: string | null;
  alert_severity?: string | null;
  related_pair_event?: string | number | null;
  is_hidden?: boolean;
  estimated_prevented_damage_amount?: string | number | null;
  estimated_prevented_damage_currency?: string | null;
  placements?: number[];
}

export default async function patchEvent({
  id,
  payload,
}: {
  id: number;
  payload: Payload;
}) {
  const url = endpoints.events.detail(id);
  if (payload.status !== "resolved") {
    payload.alert_severity = null;
  }
  let targetPayload: { [key: string]: any } = { ...payload };
  for (let propName in targetPayload) {
    if (targetPayload[propName] === undefined) {
      delete targetPayload[propName];
    }
  }
  try {
    const response = await axiosNeuron.patch(url, {
      ...targetPayload,
      created_at: targetPayload.createdAt,
    });
    const { data } = response;
    return {
      id: data.id,
      type: data.type,
      machine: data.machine,
      createdAt: data.created_at,
      alert_severity: data.alert_severity,
      maintenance_type: data.maintenance_type,
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
