import React, { memo } from "react";
import PageButton from "components/table/buttons/pageButton";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  pager: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  pagerWrap: {
    margin: theme.spacing(0, 1),
  },
}));

export const CustomFooter = memo(
  ({
    pageIndex,
    previousPage,
    nextPage,
    gotoPage,
    pageCount,
    canPreviousPage,
    canNextPage,
  }: any) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <div className={classes.pager}>
        <span>{t("table.page")}</span>
        <div className={classes.pagerWrap}>
          <PageButton
            page={pageIndex + 1}
            onPrevious={previousPage}
            onNext={nextPage}
            onGotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </div>
        <span>
          {t("table.pageFrom", {
            num: pageCount,
          })}
        </span>
      </div>
    );
  }
);
