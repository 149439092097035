import { IProjectList } from "types/project";
import { ResponseType } from "types/response";
import { SortingRule } from "react-table";
import { ICompanyList, ICompanyImage } from "types/company";

export enum EActions {
  setPage = "setPage",
  setLoading = "setLoading",
  setReloading = "setReloading",
  setNotFound = "setNotFound",
  setCompany = "setCompany",
  setProjects = "setProjects",
  setSort = "setSort",
  setEditing = "setEditing",
  setShowMore = "setShowMore",
  setReset = "setReset",
  setModalLoading = "setModalLoading",
  setModal = "setModal",
  fetchCompanyImageRequest = "fetchCompanyImageRequest",
  fetchCompanyImageSuccess = "fetchCompanyImageSuccess",
  fetchCompanyImageFail = "fetchCompanyImageFail",
  fetchCompanyFullImageRequest = "fetchCompanyFullImageRequest",
  fetchCompanyFullImageSuccess = "fetchCompanyFullImageSuccess",
  fetchCompanyFullImageFail = "fetchCompanyFullImageFail",
}

export interface ICompanyDetailList {
  type: EActions;
  payload: ICompanyDetailState;
}

export interface ICompanyDetailState {
  company: ICompanyList | null;
  pageIndex: number;
  projects: ResponseType<IProjectList[]> | undefined;
  loading: boolean;
  reloading: boolean;
  notFound: boolean;
  error: Error | null;
  editing: number | null;
  sort: SortingRule<number>[];
  showMore: number;
  modalLoading: boolean;
  modalOpened: boolean;
  companyImage: ICompanyImage | null;
  companyFullImage: ICompanyImage | null;
  isFetchingImage: boolean;
  isFetchingFullImage: boolean;
}

export interface IFetchParams {
  page: number;
  pageSize: number;
  orderBy: SortingRule<number>[];
  companyIds: number[];
}
