import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      fontSize: theme.custom.typography.fontSize[16],
      fontWeight: theme.custom.typography.fontWeight.semiBold,
    },
  };
});

const NoData: React.FC = () => {
  const classes = useStyles();
  return <span className={classes.root}>—</span>;
};

export default NoData;
