import Box from "@mui/material/Box";
import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Heading4 from "components/typography/heading/heading4";
import Paragraph from "components/typography/paragraph";
import { DatePicker as DP } from "@mui/x-date-pickers";
import DatePicker from "localization/pickerLocale";
import moment from "moment";
import Select from "components/select";
import { Field, Form } from "react-final-form";

const useStyles = makeStyles((theme: IMuiTheme) => ({
  pre: {
    whiteSpace: "pre-line",
  },
  nowrap: {
    wordBreak: "break-word",
  },
}));

type TProps = {
  id: string;
  title: string;
  value: string | null;
  unit?: string | null;
  onChange?: any;
  multiline?: boolean;
  date?: boolean;
  options?: any[];
  loading?: boolean;
};

const InfoBox = ({
  id,
  title,
  value,
  unit,
  onChange,
  multiline,
  date,
  options,
  loading,
  ...props
}: TProps) => {
  const classes = useStyles(props);
  return onChange || value ? (
    <Box width="100%" pb={4} className={classes.pre}>
      {onChange ? (
        <>
          {unit ? (
            <Paragraph>
              {title} [{unit}]
            </Paragraph>
          ) : (
            <Paragraph>{title}</Paragraph>
          )}
          {date ? (
            <DatePicker
              Component={DP}
              inputVariant="outlined"
              format="yyyy-MM-dd"
              value={value ? moment(value) : null}
              onChange={(value: number) =>
                onChange(value ? moment(value) : null)
              }
              clearable
            />
          ) : options ? (
            <Form
              onSubmit={() => {}}
              initialValues={{ machineCategory: value }}
              render={() => (
                <form>
                  <Field
                    name="machineCategory"
                    render={({ input, meta }) => (
                      <Select
                        id="machineCategory"
                        name="machineCategory"
                        label=""
                        input={input}
                        meta={meta}
                        onChange={(i: any) => onChange(i.target.value)}
                        options={options}
                        loading={loading}
                      />
                    )}
                  />
                </form>
              )}
            />
          ) : (
            <TextField
              error={false}
              variant="outlined"
              onChange={(e: any) =>
                onChange(e.target.value ? e.target.value : null)
              }
              value={value}
              multiline={multiline}
              fullWidth
            />
          )}
        </>
      ) : (
        <>
          <Paragraph>{title}</Paragraph>
          <Heading4 className={classes.nowrap} id={id}>
            {value}
            {!!value && !!unit && ` ${unit}`}
          </Heading4>
        </>
      )}
    </Box>
  ) : null;
};

export default InfoBox;
