export enum EActions {
  toggleDrawer = "toggleDrawer",
  toggleMenu = "toggleMenu",
}

export type TLayoutState = {
  menu: boolean;
  drawer: boolean;
};

export interface TLayoutActions {
  type: typeof EActions;
  payload: TLayoutState;
}

export type TReducers = {
  [key: string]: (p: TLayoutActions, s: TLayoutState) => void;
};
