import { EActions, TEventStatusesState, TActions } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const intialState: TEventStatusesState = {
  loading: false,
  data: undefined,
};

const reducers = {
  [EActions.setLoading]: ({ payload: { loading } }: TActions) => ({
    loading,
  }),
  [EActions.setData]: ({ payload: { data } }: TActions) => ({
    loading: false,
    data,
  }),
};

export default createReducer(path, intialState, reducers);
