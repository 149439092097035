import { EActions, IServiceManagerState, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IServiceManagerState = {
  error: null,
  services: null,
  isFetching: false,

  machines: {
    data: null,
    isFetching: false,
    error: null,
  },

  machineParams: {
    ids: null,
    service: null,
  },
  supports: {
    disabled: false,
    edge: false,
  },
  filters: {},
};

const reducers: TReducers = {
  [EActions.fetchServicesRequest]: (_, { services }) => ({
    services,
    isFetching: true,
  }),
  [EActions.fetchServicesFail]: (error, { services }) => ({
    services,
    isFetching: false,
    error,
  }),
  [EActions.fetchServicesSuccess]: ({ payload: { services } }) => ({
    services,
    isFetching: false,
  }),

  [EActions.fetchMachinesRequest]: (_, { machines }) => ({
    machines: {
      ...machines,
      isFetching: true,
    },
  }),
  [EActions.fetchMachinesFail]: ({ payload: { error } }) => ({
    machines: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchMachinesSuccess]: ({ payload: { machines } }) => ({
    machines: {
      data: machines,
      isFetching: false,
      error: null,
    },
  }),
  [EActions.setMachineParams]: ({ payload: { machineParams } }) => ({
    machineParams,
  }),
  [EActions.setSupports]: ({ payload: { supports } }) => ({
    supports,
  }),
  [EActions.setFilter]: ({ payload: { filters } }, state) => ({
    filters: { ...state.filters, ...filters },
  }),
};

export default createReducer<IServiceManagerState>(
  path,
  initialState,
  reducers
);
