import React, {
  memo,
  forwardRef,
  useState,
  useCallback,
  RefObject,
} from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import _debounce from "lodash/debounce";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import isEqual from "react-fast-compare";
import { debounceTime } from "settings";

interface Props {
  searchPlaceholder: string;
  id: string;
  onSearch?: (val: string) => void;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  textFieldRef?: RefObject<HTMLInputElement>;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: { width: "100%" },
    searchIcon: {
      color: theme.custom.palette.placeholder,
      cursor: "pointer",
    },
    textField: {
      height: theme.spacing(4),
    },
    searchInput: {
      fontSize: theme.custom.typography.fontSize[14],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.data,
      letterSpacing: theme.custom.typography.letterSpacing.small,
      height: theme.spacing(2),
      "&::placeholder": {
        fontSize: theme.custom.typography.fontSize[14],
        fontWeight: theme.custom.typography.fontWeight.normal,
        color: theme.custom.palette.placeholder,
        letterSpacing: theme.custom.typography.letterSpacing.small,
      },
    },
    searchBox: {
      boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(
        0.5
      )} -${theme.spacing(0.5)} ${theme.custom.palette.shadow}`,
    },
  };
});

const SearchMultiSelect = forwardRef(
  (
    { id, searchPlaceholder, onSearch, setSearchPhrase, textFieldRef }: Props,
    ref: any
  ) => {
    const classes = useStyles();
    const [searchPhrase, setSearch] = useState("");

    const onChange = useCallback((e: any) => {
      setSearch(e.target.value);
    }, []);

    const prevent = useCallback((e: any) => e.stopPropagation(), []);

    const debouncedSetSearch = React.useMemo(
      () =>
        _debounce((input: string) => {
          onSearch && onSearch(input);
          setSearchPhrase(input);
        }, debounceTime),
      []
    );

    React.useEffect(() => {
      textFieldRef?.current?.focus();
      debouncedSetSearch(searchPhrase);
    }, [searchPhrase]);

    return (
      <div ref={ref}>
        <Box p={2} pt={1} mb={2} className={classes.searchBox}>
          <TextField
            id={`${id}SearchInput`}
            placeholder={searchPlaceholder}
            className={classes.textField}
            fullWidth
            variant="outlined"
            onClick={prevent}
            value={searchPhrase}
            onChange={onChange}
            onKeyDown={prevent}
            InputProps={{
              classes: {
                input: classes.searchInput,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            inputRef={textFieldRef}
          />
        </Box>
      </div>
    );
  }
);

export default memo(SearchMultiSelect, isEqual);
