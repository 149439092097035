import { lazy } from "react";
import ForceRefreshHandler from "localStorage/forceRefreshHandler";

const lazyImportHandler = (componentImport: () => any) =>
  process.env.JEST_WORKER_ID !== undefined
    ? lazy(async () => new Promise(() => {})) // don't load anything in tests
    : lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
          ForceRefreshHandler.getPageRefreshedState() || "false"
        );
        try {
          const component = await componentImport();
          ForceRefreshHandler.setPageRefreshedState(false);
          return component;
        } catch (error) {
          if (!pageHasAlreadyBeenForceRefreshed) {
            ForceRefreshHandler.setPageRefreshedState(true);
            return window.location.reload();
          }
          throw error;
        }
      });

export default lazyImportHandler;
