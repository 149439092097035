import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { ResponseType } from "types/response";

interface IPlacementTypeListResponse {
  id: number;
  machineSubcategory: number;
  name: string;
  description: string;
}

export default async function getPlacementTypes(machineSubcategoryId?: number) {
  const url = `${endpoints.placementTypes.default}?ps=999${
    machineSubcategoryId ? `&machine_subcategory=${machineSubcategoryId}` : ``
  }`;
  try {
    const response = await axiosNeuron.get(url);
    const data: ResponseType<IPlacementTypeListResponse> = {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        machineSubcategory: item.machine_subcategory,
        name: item.name,
        description: item.description,
        placement: item.placement,
        number: item.number,
        note: item.number,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
