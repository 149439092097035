import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import { makeStyles } from "@mui/styles";

import Switch from "components/switch";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/deviceDetail/channelsList/actions";
import { cellCompare } from "shared/helpers";
import { AppState } from "store";
import { createSelector } from "reselect";
import isEqual from "react-fast-compare";
import { OPlacementsList } from "store/deviceDetail/channelsList/types";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  };
});

type PropsAndState = {
  state: AppState;
  placement: number | null;
};

const selector = createSelector(
  ({ placement }: PropsAndState) => placement,
  ({ state }: PropsAndState) => state.deviceDetail.channelsList,
  (id, props) => {
    return (
      props.placements.find(
        (item: OPlacementsList) => id && Number(item.id) === id
      )?.is_enabled || false
    );
  }
);

const ResolveSwitch = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { placement, disabled },
    },
  } = props;

  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const is_enabled = useSelector(
    (state: AppState) => selector({ state, placement }),
    isEqual
  );

  const openModal = useCallback(() => {
    dispatch(actions.setEnabled(placement, !is_enabled));
  }, [dispatch, is_enabled, placement]);

  if (!placement) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <Switch
          name="resolveSwitch"
          checked={is_enabled}
          disabled={disabled}
          onChange={openModal}
          onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
            e.stopPropagation();
          }}
          labelOn={t("devices.detail.list.cell.enabled")}
          labelOff={t("devices.detail.list.cell.disabled")}
        />
      </div>
    </>
  );
};

export default memo(ResolveSwitch, cellCompare) as typeof ResolveSwitch;
