import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import ChooseProject from "components/chooseProject";
import moveDevice from "api/handlers/device/moveDevice";
import { refetchDevicesSilent } from "store/devicesList/actions";
import SingleActionModal from "components/singleActionModal";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";
interface Props {
  deviceId: number;
  closeModalMove: () => void;
  modalMoveTo: boolean;
  detail?: boolean;
}

const selector = createSelector(
  (state: AppState) => state.deviceDetail.detail,
  (detail: any) => {
    return detail.device || {};
  }
);

const ChooseProjectModal = ({
  deviceId,
  closeModalMove,
  modalMoveTo,
  detail,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const { project, serialUid } = useSelector(selector);

  const [projectId, setProjectId] = React.useState<number | null>(project);

  const { enqueueSnackbar } = useSnackbar();
  const [moveDeviceMutation] = useMutation(moveDevice, {
    onSuccess: () => {
      enqueueSnackbar(t("devices.list.actions.success.move"));
      dispatch(refetchDevicesSilent());
    },
    onError: () => {
      enqueueSnackbar(t("devices.list.actions.errors.move"));
    },
  });

  const onMoveToConfirm = useCallback(() => {
    try {
      closeModalMove();
      moveDeviceMutation({ id: deviceId!.toString(), project: projectId });
    } catch {
      closeModalMove();
    }
  }, [deviceId, projectId, closeModalMove, moveDeviceMutation]);
  return (
    <SingleActionModal
      modalTitle={t("devices.list.actions.moveToTitle", { name: serialUid })}
      open={modalMoveTo}
      finishButtonLabel={t("confirm")}
      handleFinish={onMoveToConfirm}
      closeModal={closeModalMove}
    >
      <ChooseProject
        deviceId={deviceId}
        projectId={projectId}
        closeModalMove={closeModalMove}
        setProjectId={setProjectId}
      />
    </SingleActionModal>
  );
};

export default ChooseProjectModal;
