import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 auto",
    width: 100,
    "& span": {
      padding: theme.spacing(1, 0),
    },
  },
  rect: {
    transition: "fill-opacity 0.2s linear",
  },
}));

type Props = {
  title?: string;
};

function Index({ title }: Props) {
  const classes = useStyles();
  const [activeLine, setActiveLine] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if ((activeLine + 1) % 8 === 0) {
        setActiveLine(0);
      } else {
        setActiveLine(activeLine + 1);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [activeLine]);

  return (
    <Box p={3}>
      <Box className={classes.root}>
        <svg
          id="ejyq7tc5jcqr1"
          viewBox="0 0 477 320"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
        >
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr2"
            width="36"
            height="70"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 0 250)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 0 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr3"
            width="36"
            height="116"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 63 204)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 1 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr4"
            width="36"
            height="180"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 126 140)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 2 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr5"
            width="36"
            height="244"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 189 76)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 3 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr6"
            width="36"
            height="180"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 252 140)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 4 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr7"
            width="36"
            height="70"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 315 250)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 5 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr8"
            width="36"
            height="116"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 378 204)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 6 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <rect
            className={classes.rect}
            id="ejyq7tc5jcqr9"
            width="36"
            height="204"
            rx="18"
            ry="18"
            transform="matrix(1 0 0 1 441 116)"
            fill="rgb(0,0,0)"
            fillOpacity={activeLine === 7 ? "1" : "0.24"}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </svg>
        {title && <span style={{ whiteSpace: "nowrap" }}>{title}</span>}
      </Box>
    </Box>
  );
}
export default Index;
