import React, { memo } from "react";
import Box from "@mui/material/Box";
import RadioComponent from "components/radio";

type Props = {
  id: string;
  label: string;
  handleRadioChange?: (event: React.ChangeEvent<{}>) => void;
  activeProjectId: number | null;
};

const ProjectRow = memo(
  ({ id, label, activeProjectId, handleRadioChange }: Props) => {
    return (
      <Box mb={-1} ml={0.75} key={id}>
        <RadioComponent
          activeValue={(activeProjectId && activeProjectId.toString()) || null}
          value={id}
          label={label}
          onChange={handleRadioChange}
        />
      </Box>
    );
  }
);

export default ProjectRow;
