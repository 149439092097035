import React, { memo } from "react";
import Tag from "components/tag";
import { useTranslation } from "react-i18next";
import { cellCompare } from "shared/helpers";
import { CellProps } from "react-table";

const ChannelName = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { number },
    },
  } = props;
  const { t } = useTranslation();

  return (
    <Tag color={false}>
      {t("devices.detail.list.cell.channel") + "\u00a0" + number}
    </Tag>
  );
};

export default memo(ChannelName, cellCompare) as typeof ChannelName;
