import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IMachineImage } from "types/machine";

export default async function getMachineImage(id: number, full?: boolean) {
  const url = full
    ? endpoints.machineImages.getFull(id)
    : endpoints.machineImages.getThumbnail(id);
  try {
    const { data } = await axiosNeuron.get(url);
    const result: IMachineImage = {
      id: data.id,
      version: data.version,
      type: data.type,
      image_data: data.image_data,
    };
    return result;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
