export enum EActions {
  setLoading = "setLoading",
}

export type TLangsState = {
  loading: boolean;
};

export interface TLangsActions {
  type: typeof EActions;
  payload: TLangsState;
}
