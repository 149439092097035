import React, { memo, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import MaterialModal from "@mui/material/Modal";
import Spinner from "components/spinner";
import Centered from "components/centered";

interface IModal {
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  children: JSX.Element;
  disableBackdrop?: boolean;
  loading?: boolean;
  className?: string;
  width?: number;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: (props: { width?: number }) => ({
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    backgroundColor: theme.custom.palette.surface,
    boxShadow: theme.custom.boxShadow[24],
    outline: "none",
    [`@media only screen and (min-width: ${theme.spacing(
      props.width || 96
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "60%",
      height: theme.spacing(70),
      borderRadius: theme.spacing(1),
      overflowY: "auto",
    },
  }),
}));

export const Modal = memo(
  ({
    onCancel,
    open,
    children,
    disableBackdrop = true,
    loading,
    className,
    ...rest
  }: IModal) => {
    const classes = useStyles(rest);

    const stopPropagation = useCallback((event: any) => {
      event.stopPropagation();
    }, []);

    return (
      <MaterialModal
        open={open}
        onClose={(
          event: React.MouseEvent<HTMLButtonElement>,
          reason: string
        ) => {
          if (onCancel && (!disableBackdrop || reason !== "backdropClick")) {
            onCancel(event);
          }
        }}
        className={classes.root}
        onClick={stopPropagation}
        {...rest}
      >
        <div className={`${classes.paper} ${className}`}>
          {loading ? (
            <Centered>
              <Spinner />
            </Centered>
          ) : (
            children
          )}
        </div>
      </MaterialModal>
    );
  }
);
