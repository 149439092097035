import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import MaterialModal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Button from "components/button/index";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(40),
    backgroundColor: theme.custom?.palette.surface,
    boxShadow: theme.custom?.boxShadow[24],
    outline: "none",
    borderRadius: theme.spacing(1),
    zIndex: 2,
  },
  content: {
    padding: theme.spacing(3),
    fontFamily: theme.custom?.typography.fontFamily,
    fontSize: theme.custom?.typography.fontSize["14"],
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.custom?.typography.fontWeight.bold,
    fontSize: theme.custom?.typography.fontSize["16"],
  },
  footer: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: theme.custom?.boxShadow.umbra,
  },
}));

type Props = {
  title: string;
  text: string;
  onConfirm: () => void;
  onCancel?: () => void;
  ToggleButton?: JSX.Element;
  confirmText?: string;
  noControl?: boolean;
  children?: React.ReactElement;
  disableConfirm?: boolean;
  descriptionClassName?: string;
};

const Modal: React.FC<Props> = ({
  onConfirm,
  onCancel,
  confirmText,
  title,
  text,
  ToggleButton,
  noControl,
  children,
  disableConfirm,
  descriptionClassName,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(noControl || false);
  const toggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    !noControl && setOpen(!open);
  };

  const doConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(false);
    onConfirm();
  };

  const stopPropagation = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      {!noControl &&
        ToggleButton &&
        React.createElement(ToggleButton.type, {
          ...ToggleButton.props,
          onClick: toggle,
        })}
      <MaterialModal
        open={open}
        className={classes.root}
        onClick={stopPropagation}
        onClose={toggle}
        slots={{ backdrop: Backdrop }}
        id="testConfirmPopup"
      >
        <div className={classes.paper}>
          <div className={classes.content}>
            <div className={classes.heading}>{title}</div>
            <div className={descriptionClassName}>{text}</div>
            <div>{children}</div>
          </div>
          <div className={classes.footer}>
            <Box mr={1}>
              <Button
                id="testConfirmPopupButtonCancel"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  if (onCancel) {
                    onCancel();
                  }
                  toggle(e);
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
            <Button
              id="testConfirmPopupButtonConfirm"
              onClick={doConfirm}
              disabled={!!disableConfirm}
            >
              {confirmText || t("confirm")}
            </Button>
          </div>
        </div>
      </MaterialModal>
    </>
  );
};

export default Modal;
