import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { EActions, DevicesDetailAction, DeviceDetailState } from "./types";
import { prepareActions } from "store/helpers";
import { AppState } from "store";

import getMachines from "api/handlers/machine/getMachines";
import getSensorTypes from "api/handlers/getSensorTypes";
import { getPlacements } from "api/handlers/placement/getPlacements";
import getConnectableMachines from "api/handlers/machine/getConnectableMachines";
import getChannelsById from "api/handlers/channel/getChannelsById";
import getRecordingPlans from "api/handlers/getRecordingPlans";
import patchDeviceConfig, {
  TDeviceCongif,
} from "api/handlers/device/patchDeviceConfig";
import patchChannel from "api/handlers/channel/patchChannel";
import i18n from "localization";
import { enqueueSnackbar } from "notistack";
import { fetchDevice } from "store/deviceDetail/actions";
import { loadISOGroup } from "store/iso_group/actions";
import omitBy from "lodash/omitBy";
import keys from "lodash/keys";
import { IChannelList } from "types/channels";
import { IPlacementList } from "types/placement";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

export const path = "deviceDetail/channelsList";

const actionsData = [
  [EActions.setInitialData, "initialData"],
  [EActions.setPlacement, "channelId", "placementId"],
  [EActions.setChannels, "channels"],
  [EActions.setData, "data"],
  [EActions.setDisconnected, "id"],
  [EActions.setReset],
  [EActions.setInitMachines, "initMachines"],
  [EActions.setMachine, "channelId", "machineId"],
  [EActions.placements, "placements"],
  [EActions.connectableMachines, "connectableMachines"],
  [EActions.connectableLoading, "machinesLoading"],
  [EActions.setLoading, "loading"],
  [EActions.setMachines, "machines"],
  [EActions.setSensorTypes, "sensorTypes"],
  [EActions.setSensorType, "channelId", "sensor_type"],
  [EActions.setEnabled, "placementId", "is_enabled"],
  [EActions.setISOGroup, "placementId", "iso_group"],
  [EActions.disconnectPlacement, "channelId"],
  [EActions.resetChanges],
  [EActions.setRecordingPlans, "recordingPlans"],
  [EActions.setRecordingPlan, "recordingPlan"],
  [EActions.setThreshold, "placementId", "running_threshold"],
  [EActions.setGain, "channelId", "gain"],
  [EActions.setRPM, "placementId", "rpm"],
  [EActions.setRPMAuto, "placementId", "is_rpm_automatically_detected"],
  [EActions.setBandAlerting, "placementId", "band_alerting"],
  [EActions.setPlacementRecordingPlan, "placementId", "recording_plan"],
];

const actions = prepareActions<DevicesDetailAction, EActions>(
  actionsData,
  path
);

export default actions;

export const fetchMachines =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      dispatch(actions.setLoading(true));
      const { initMachines } = getState().deviceDetail.channelsList;
      const data = await getMachines({ ids: initMachines });
      if (data?.results) {
        dispatch(actions.setMachines(data.results));
      }
    } catch (error) {
      dispatch(actions.setLoading(false));
    }
  };

const fetchInitialSensors = async () => {
  const sensors = await getSensorTypes();

  const emptyOption = {
    id: 0,
    name: i18n.t("devices.detail.list.cell.placement"),
  };
  sensors.unshift(emptyOption);
  return sensors;
};

const fetchInitialPlacements = async (initMachines: number[]) => {
  const data = await getPlacements({
    machineIds: initMachines,
  });
  return data.results.map((item: IPlacementList) =>
    omit(item, "type", "channel", "panelId", "qrCode", "gain")
  );
};

const fetchInitialMachines = async (initMachines: number[]) => {
  const data = await getMachines({
    ids: initMachines,
  });
  return data?.results || [];
};

export const fetchRecordingPlans =
  () => async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      const data = await getRecordingPlans();
      dispatch(actions.setRecordingPlans(data?.results));
    } catch (error) {
      dispatch(actions.setRecordingPlans([]));
    }
  };

export const fetchChannels =
  (noLoading = false) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      !noLoading && dispatch(actions.setLoading(true));
      const { device } = getState().deviceDetail.detail;
      const { initMachines } = getState().deviceDetail.channelsList;

      dispatch(fetchRecordingPlans());
      dispatch(loadISOGroup());

      const data = await getChannelsById(device.id);
      const sensors = await fetchInitialSensors();
      const placements = initMachines.length
        ? await fetchInitialPlacements(initMachines)
        : [];
      const machines = initMachines.length
        ? await fetchInitialMachines(initMachines)
        : [];
      const channels =
        data?.results.map((item: IChannelList) => {
          const placement =
            placements.find(
              ({ id }: IPlacementList) => id === item.placement
            ) || {};

          return omit(
            {
              ...item,
              machine: placement?.machine ?? null,
            },
            ["device", "state", "note"]
          );
        }) || [];
      dispatch(
        actions.setInitialData({
          sensors,
          placements,
          machines,
          channels,
        })
      );
    } catch (error) {
      dispatch(actions.setLoading(false));
    }
  };

export const fetchPlacements =
  (machineId: number) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { placements = [] }: DeviceDetailState =
        getState().deviceDetail.channelsList;
      let dataMachines: number[] = [];
      for (let i of placements) {
        if (i.machine) dataMachines = [...dataMachines, Number(i.machine)];
      }
      const response = await getPlacements({
        machineIds: [machineId],
      });

      if (response?.results) {
        dispatch(actions.placements(response.results));
      }
    } catch (error) {}
  };

export const fetchConnectableMachines =
  (deviceId: number, disconnected: number | null) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      dispatch(actions.connectableLoading(true));
      const data = await getConnectableMachines(deviceId, disconnected);
      if (data?.results) {
        dispatch(actions.connectableMachines(data?.results));
        disconnected && dispatch(actions.setDisconnected(null));
      }
      dispatch(actions.connectableLoading(false));
    } catch (error) {
      dispatch(actions.connectableLoading(false));
    }
  };

export const disconnectPlacement =
  (id: number, machineId?: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchChannel({ id, payload: { placement: null } });
      dispatch(actions.setPlacement(id, null));
      dispatch(actions.setMachine(id, machineId));
      dispatch(fetchChannels(true));

      if (!machineId) {
        enqueueSnackbar(i18n.t("devices.detail.actions.disconnect.success"));
      }
    } catch (error) {
      enqueueSnackbar(i18n.t("devices.detail.actions.disconnect.failed"));
    }
  };

export const setSensorType =
  (id: number, sensorType: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      const result = await patchChannel({
        id,
        payload: { sensor_type: sensorType === 0 ? null : sensorType },
      });
      if (result) {
        dispatch(fetchChannels(true));
        enqueueSnackbar(i18n.t("devices.detail.actions.sensor.success"));
      }
    } catch (err) {
      enqueueSnackbar(i18n.t("devices.detail.actions.sensor.failed"));
    }
  };

export const submitChanges =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      let {
        channels,
        placements,
        recordingPlan,
        initialChannels,
        initialPlacements,
      } = getState().deviceDetail.channelsList;
      const { device } = getState().deviceDetail.detail;

      const payload: TDeviceCongif = {};

      if (device.recordingPlan !== recordingPlan && recordingPlan) {
        payload.devices = [{ id: device.id, recording_plan: recordingPlan }];
      }

      for (let i of initialChannels) {
        const channel = omitBy(
          channels.find(({ id }: IChannelList) => id === i.id),
          (v, k) => keys(i).indexOf(k) === -1
        );

        const diffChannels = omitBy(
          channel,
          (v, k) => k === "machine" || i[k] === v
        );

        if (!isEmpty(diffChannels)) {
          payload.channels = [
            ...(payload.channels || []),
            { id: i.id as number, ...diffChannels },
          ];
        }
      }

      for (let i of initialPlacements) {
        const placement = omitBy(
          placements.find(({ id }: IPlacementList) => id === i.id),
          (v, k) => keys(i).indexOf(k) === -1
        );

        const diffplacements = omitBy(placement, (v, k) => i[k] === v);

        if (!isEmpty(diffplacements)) {
          payload.placements = [
            ...(payload.placements || []),
            { id: i.id as number, ...diffplacements },
          ];
        }
      }

      const res = await patchDeviceConfig(payload);
      if (res) {
        dispatch(fetchDevice(device.id, true, true));

        enqueueSnackbar(i18n.t("devices.detail.actions.settings.success"));
      }
    } catch (err) {
      enqueueSnackbar(i18n.t("devices.detail.actions.settings.fail"));
    }
  };
