import { createAsyncThunk } from "@reduxjs/toolkit";
import { addLabel } from "api/handlers/dataLabeling/addLabel";
import { updateLabelPosition } from "api/handlers/dataLabeling/updateLabelPosition";
import { removeRegion } from "api/handlers/dataLabeling/removeRegion";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import i18n from "localization";
import { AppState } from "store";
import { LabelGroupModalState, LabelModalState } from "./types";
import cloneDeep from "lodash/cloneDeep";

export const addLabelToEvent = createAsyncThunk(
  "events/addLabelToEvent",
  async (
    {
      label,
      group,
      start_datetime,
      end_datetime,
      timezoneOffset,
      machineId,
    }: {
      label: LabelModalState;
      group: LabelGroupModalState;
      start_datetime: number;
      end_datetime: number;
      timezoneOffset: any;
      machineId: number;
    },
    { dispatch }: { dispatch: any }
  ) => {
    try {
      await addLabel(
        { name: label.description, subcategory: label.subcategory },
        start_datetime,
        end_datetime,
        group.placements,
        timezoneOffset,
        group.eventId,
        machineId
      );
    } catch {
      enqueueSnackbar(
        i18n.t("eventModal.labels.update.fail.add", {
          variant: "error",
        })
      );
    }
  }
);

export const updateLabelOfEvent = createAsyncThunk(
  "events/updateLabelOfEvent",
  async (
    {
      id,
      group,
      timezoneOffset,
    }: {
      id: number;
      group: LabelGroupModalState;
      timezoneOffset: any;
    },
    { dispatch }: { dispatch: any }
  ) => {
    try {
      await updateLabelPosition(
        id,
        moment(group.start).unix(),
        moment(group.end).unix(),
        timezoneOffset,
        group.placements
      );
    } catch {
      enqueueSnackbar(
        i18n.t("eventModal.labels.update.fail.update", {
          variant: "error",
        })
      );
    }
  }
);

export const removeLabelOfEvent = createAsyncThunk(
  "events/removeLabelOfEvent",
  async (
    { id, timezoneOffset }: { id: number; timezoneOffset: any },
    { dispatch }: { dispatch: any }
  ) => {
    try {
      await removeRegion(id, timezoneOffset);
    } catch {
      enqueueSnackbar(
        i18n.t("eventModal.labels.update.fail.remove", {
          variant: "error",
        })
      );
    }
  }
);

export const updateLabels = createAsyncThunk(
  "events/updateLabels",
  async (
    {
      labelGroups,
      timezoneOffset,
      machineId,
    }: {
      labelGroups: LabelGroupModalState[];
      timezoneOffset: any;
      machineId: number;
    },
    { getState, dispatch }: { getState: any; dispatch: any }
  ) => {
    try {
      const initialLabels = (getState() as AppState).eventModal.initialLabels;

      labelGroups.map((group: LabelGroupModalState) => {
        group.labels.map(async (label: LabelModalState) => {
          if (!group.toDelete) {
            const start_datetime = moment(group.start).unix();
            const end_datetime = moment(group.end).unix();

            if (label.id === null) {
              dispatch(
                addLabelToEvent({
                  label,
                  group,
                  start_datetime,
                  end_datetime,
                  timezoneOffset,
                  machineId,
                })
              );
            } else {
              initialLabels.map(async (initialLabel: any) => {
                if (initialLabel.groupId === group.groupId) {
                  const groupPlacementsCloned = cloneDeep(group.placements);
                  const initialPlacementsCloned = cloneDeep(
                    initialLabel.placements
                  );
                  const placementsAreDirty =
                    JSON.stringify(groupPlacementsCloned.sort()) !==
                    JSON.stringify(initialPlacementsCloned.sort());
                  if (
                    (placementsAreDirty && label.id !== null) ||
                    ((initialLabel.start !== group.start ||
                      initialLabel.end !== group.end) &&
                      label.id !== null &&
                      group.toRemoveLabels.indexOf(label.id) === -1)
                  ) {
                    dispatch(
                      updateLabelOfEvent({
                        id: label.id,
                        group,
                        timezoneOffset,
                      })
                    );
                  }
                }
              });
            }
          }
        });

        group.toRemoveLabels.map(async (toRemove: any) => {
          dispatch(removeLabelOfEvent({ id: toRemove, timezoneOffset }));
        });
      });
    } catch {}
  }
);
