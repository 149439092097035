import Slider from "@mui/material/Slider";
import IMuiTheme from "types/styles";
import { withStyles } from "@mui/styles";

const sliderStyle = (theme: IMuiTheme) => ({
  root: {
    color: theme.custom.palette.primary["48"],
    width: "98%",
    [theme.breakpoints.down(700)]: {
      width: "95%",
    },
  },
  thumb: {
    backgroundColor: theme.custom.palette.primary["100"],
    borderColor: theme.custom.palette.primary["100"],
    width: "12px",
    height: "12px",

    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    "&.Mui-disabled": {
      backgroundColor: "grey",
      borderColor: "grey",
      width: "8px",
      height: "8px",
    },
  },
  active: {},
});
export const ThemedSlider = withStyles(sliderStyle)(Slider);
