import { EActions, ISoundsState, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import { DURATION, Local } from "components/dataLabeling/constants";
import defaultsDeep from "lodash/defaultsDeep";
import keys from "lodash/keys";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";

const initialState: ISoundsState = {
  draw: "wave",
  isDocumentEventActive: false,
  zoomOutOffset: 0,
  localZoom: 1,
  zoom: 1,
  loaded: false,
  loadingData: false,
  renderFFT: false,
  allLabels: null,
  sampleRate: 48000,
  year: undefined,
  yearData: null,
  date: undefined,
  labelsDates: undefined,
  dayData: null,
  time: undefined,
  timezoneOffset: Local,
  duration: DURATION,
  placements: undefined,
  zoomOutLoading: false,
  loadingCommonData: false,
  isPlaying: null,
  largeDownloadAllowed: false,
  labelsFilterData: null,
  isPreviousClicked: false,
  isNextClicked: false,
  checkedLabels: [],
  isChecked: false,
  isHiddenLabelShown: false,
  isCombinationOnly: false,
};

const reducers: TReducers = {
  [EActions.setCommonData]: ({ payload: { data } }, { placements }) => ({
    ...data,
    placements: data.placements
      ? Object.fromEntries(
          Object.entries(data.placements || {}).map(([k, v]) => [
            k,
            {
              ...v,
              ...((placements && placements[k]) || {}),
            },
          ])
        )
      : placements,
    loadingCommonData: false,
  }),
  [EActions.setPlaying]: ({ payload: { placement } }) => ({
    isPlaying: placement,
  }),
  [EActions.setBulkZoom]: ({ payload: { zoom } }, { placements }) => {
    const newState: any = {};
    for (let i of keys(placements)) {
      newState[i] = { ...placements?.[i], zoom, localZoom: zoom };
    }
    return {
      placements: { ...newState },
      zoom,
      localZoom: zoom,
    };
  },
  [EActions.changeTimezone]: ({ payload: { data } }, { placements }) => {
    const { buffers, ...rest } = data as any;

    return {
      ...rest,
      placements: defaultsDeep(buffers, placements),
    };
  },
  [EActions.setLoadingCommonData]: ({ payload: { loading } }) => ({
    loadingCommonData: loading,
  }),
  [EActions.setZoom]: ({ payload: { zoom, placement } }, { placements }) => ({
    placements: {
      ...placements,
      [placement]: {
        ...placements?.[placement],
        zoom,
        localZoom: zoom,
      },
    },
  }),
  [EActions.setFFT]: ({ payload: { renderFFT } }) => {
    return { renderFFT };
  },
  [EActions.setReset]: () => {
    let { largeDownloadAllowed, ...newInitialState } = initialState;
    return { ...newInitialState };
  },
  [EActions.setDraw]: ({ payload: { draw } }) => ({
    draw,
  }),
  [EActions.setLoadingBuffer]: (
    { payload: { loadingBuffer, placement } },
    { placements }
  ) => ({
    placement,
    placements: {
      ...placements,
      [placement]: {
        ...placements?.[placement],
        loadingBuffer,
      },
    },
  }),
  [EActions.setZoomOutLoading]: (
    { payload: { loading, placement } },
    { placements }
  ) => ({
    placements: {
      ...placements,
      [placement]: {
        ...placements?.[placement],
        zoomOutLoading: loading,
      },
    },
  }),
  [EActions.setBufferLoaded]: (
    { payload: { buffer, placement, rest } },
    { placements }
  ) => {
    return {
      placements: {
        ...placements,
        [placement]: {
          ...placements?.[placement],
          buffer: {
            ...buffer,
            amplifiedBuffer: buffer.buffer,
          },
          loadingBuffer: false,
          volume: 0,
          ...rest,
        },
      },
      bufferStart: buffer.bufferStart,
    };
  },
  [EActions.setLargeDownloadAllowed]: ({
    payload: { largeDownloadAllowed },
  }) => {
    return { largeDownloadAllowed };
  },
  [EActions.setVolume]: (
    { payload: { placement, volume } },
    { placements }
  ) => {
    const binBuffer = placements?.[placement]?.buffer.buffer;
    if (!binBuffer) {
      return {};
    }
    const ctx = getAudioContext(binBuffer.sampleRate);
    const ratio = 10 ** (volume / 10);
    const data = binBuffer.getChannelData(0).map((i: number) => i * ratio);
    const amplifiedBuffer = ctx.createBuffer(
      1,
      binBuffer.length,
      binBuffer.sampleRate
    );
    amplifiedBuffer.getChannelData(0).set(data, 0);
    return {
      placements: {
        ...placements,
        [placement]: {
          ...placements?.[placement],
          buffer: {
            ...placements?.[placement]?.buffer,
            amplifiedBuffer,
          },
          volume,
        },
      },
    };
  },
  [EActions.setLabelsFilterData]: (
    { payload: { labelsFilterData, placement } },
    { placements }
  ) => ({
    placements: {
      ...placements,
      [placement]: {
        ...placements?.[placement],
        labelsFilterData: labelsFilterData,
      },
    },
  }),
  [EActions.setIsPreviousClicked]: ({ payload: { isPreviousClicked } }) => ({
    isPreviousClicked,
    isNextClicked: false,
  }),
  [EActions.setIsNextClicked]: ({ payload: { isNextClicked } }) => ({
    isNextClicked,
    isPreviousClicked: false,
  }),
  [EActions.setCheckedLabels]: ({ payload: { checkedLabels } }) => ({
    checkedLabels,
  }),
  [EActions.setIsChecked]: ({ payload: { isChecked } }) => ({
    isChecked,
  }),
  [EActions.setShowHiddenLabels]: ({ payload: { isHiddenLabelShown } }) => ({
    isHiddenLabelShown,
  }),
  [EActions.setIsCombinationOnly]: ({ payload: { isCombinationOnly } }) => ({
    isCombinationOnly,
  }),
  [EActions.setDateTime]: ({ payload: { date, time } }) => ({
    date,
    time,
  }),
};

export default createReducer<ISoundsState>(path, initialState, reducers);
