import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export interface ICredentials {
  username: string;
  password: string;
}

export default async function getToken(credentials: ICredentials) {
  const url = endpoints.apiTokenAuth.default;
  try {
    const response = await axiosNeuron.post(url, credentials);
    return response.data.token;
  } catch (error: any) {
    throw error;
  }
}
