import { ERoleCodename } from "types/role";

export enum EActions {
  setAuth = "setAuth",
  setAuthFail = "setAuthFail",
  setExpiredToken = "setExpiredToken",
  setLoading = "setLoading",
  setGrafanaUrl = "setGrafanaUrl",
  closeGrafanaModal = "closeGrafanaModal",
}
export interface User {
  [x: string]: any;
  username: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  role: ERoleCodename;
  permissions: string[];
  id: number;
}

export interface AuthState {
  user?: User | null;
  errors: string[];
  loading: boolean;
  expiredToken: boolean;
  grafanaUrl?: string;
}

export interface AuthActionTypes {
  type: typeof EActions;
  payload: AuthState;
}
