import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function getPlacement(placementId: number) {
  const url = endpoints.placements.detail(placementId);
  try {
    const data: any = await axiosNeuron.get(url);
    if (data) {
      return data;
    }
    return;
  } catch {
    return;
  }
}
