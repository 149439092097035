import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { UsersSearch } from "components/users/search";
import useFilter from "dataHooks/projects/users/filterHook";

export const UsersFilter = memo(() => {
  const { q, onSearch } = useFilter();
  return (
    <Box my={2}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Box ml="auto" mb={2} mt={1} display="flex" alignItems="center">
          <UsersSearch searchValue={q} onSearch={onSearch} />
        </Box>
      </Grid>
    </Box>
  );
});

export default UsersFilter;
