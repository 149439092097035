import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  recommendations: string;
  created_for: string;
  project: number;
};

export default async function addReport(params: Params) {
  const url = endpoints.reports.default;
  try {
    const { data } = await axiosNeuron.post(url, params);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
