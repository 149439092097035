import React, { memo, useMemo } from "react";

import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { createSelector } from "reselect";
import isEqual from "react-fast-compare";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import { makeStyles } from "@mui/styles";

type Selector = {
  state: {
    [key: string]: any;
  };
  path: string;
};

const selector = createSelector(
  ({ state, path }: Selector) => state[path],
  (filter: any) => {
    return filter;
  }
);

const useStyles = makeStyles((theme: any) => ({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
  },
}));

const QueryLink = memo(
  React.forwardRef(({ to, path, ...props }: any, ref) => {
    const classes = useStyles();
    const { filters, q } = useSelector(
      (state: AppState) => (path ? selector({ state, path }) : state),
      isEqual
    );

    const queryTo = useMemo(() => {
      if (filters) {
        let search = "";
        if (!isEmpty(filters)) {
          let params = queryString.stringify(filters, {
            arrayFormat: "comma",
            arrayFormatSeparator: ",",
          });
          search = "?" + params;
        }

        return `${to}${search}`;
      }

      if (q) {
        const search = q ? `?q=${q}` : "";
        return `${to}${search}`;
      }

      return to;
    }, [filters, q, to]);

    return (
      <NavLink
        className={classes.link}
        to={queryTo}
        {...props}
        ref={ref}
        unstable_viewTransition
      />
    );
  })
);

export default QueryLink;
