import { useCallback, useRef, useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortingRule } from "react-table";
import { createSelector } from "reselect";

import { AppState } from "store";
import isEqual from "react-fast-compare";
import { IReportsState } from "store/projectDetail/reports/types";
import actions, { fetchReports } from "store/projectDetail/reports/actions";
import useCompareFetch from "shared/hooks/useCompareFetch";

const pageSize = 10;
const inputMaxLength = 50000;

const selector = createSelector(
  (state: AppState) => state.projectDetail.reports,
  (reports: IReportsState) => {
    return {
      pageIndex: reports.pageIndex,
      reports: reports.reportsData,
      isFetching: reports.isFetching,
      more: reports.more,
      modalOpened: reports.modalOpened,
      recommendations: reports.recommendations,
      createdFor: reports.createdFor,
      pickerError: reports.pickerError,
      recommendationLengthError: reports.recommendationLengthError,
    };
  }
);

const useDataReports = (id: number) => {
  const dispatch: any = useDispatch();
  const {
    reports,
    isFetching,
    pageIndex,
    modalOpened,
    recommendations,
    createdFor,
    pickerError,
    recommendationLengthError,
  } = useSelector(selector, isEqual);

  const showMoreClickedTimesRef = useRef(0);
  const [sortByState, setSortByState] = useState<SortingRule<number>[]>([
    { id: "created_for", desc: true },
  ]);

  const initialState = useMemo(
    () => ({
      pageIndex,
      pageSize,
      pageCount:
        reports && reports.results ? Math.ceil(reports.count / pageSize) : 0,
      sortBy: sortByState,
    }),
    [pageIndex, reports, sortByState]
  );

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (showMoreClickedTimesRef.current > 0) {
        dispatch(actions.setMore(showMoreClickedTimesRef.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  const onPageSort = useCallback((sortBy: SortingRule<number>[]) => {
    setSortByState(sortBy);
  }, []);

  useCompareFetch((params: any) => dispatch(fetchReports(params)), {
    page: !showMoreClickedTimesRef.current ? pageIndex + 1 : 1,
    pageSize: showMoreClickedTimesRef.current
      ? pageSize * (showMoreClickedTimesRef.current + 1)
      : pageSize,
    orderBy: sortByState,
    id,
  });

  useEffect(() => {
    dispatch(
      fetchReports({
        page: !showMoreClickedTimesRef.current ? pageIndex + 1 : 1,
        pageSize: showMoreClickedTimesRef.current
          ? pageSize * (showMoreClickedTimesRef.current + 1)
          : pageSize,
        orderBy: sortByState,
        id,
      })
    );
  }, [dispatch, id]);

  return {
    reports,
    isFetching,
    initialState,
    modalOpened,
    showMoreClickedTimesRef,
    onPageChange,
    onPageSort,
    sortByState,
    pageIndex,

    recommendations,
    createdFor,
    pickerError,

    inputMaxLength,
    recommendationLengthError,
  };
};

export default useDataReports;
