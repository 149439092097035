import { ERoleCodename } from "types/role";
import { IRole } from "types/role";
import { TFunction } from "i18next";

export const roles: IRole[] = [
  {
    order: 1,
    codename: ERoleCodename.nswAdmin,
  },
  {
    order: 2,
    codename: ERoleCodename.partnerAdmin,
  },
  {
    order: 3,
    codename: ERoleCodename.customerAdmin,
  },
  {
    order: 4,
    codename: ERoleCodename.customer,
  },
];

export const getTranslatedRole = (t: TFunction, codename: string) => {
  if (codename === ERoleCodename.nswAdmin) {
    return t("role.nswAdmin");
  }
  if (codename === ERoleCodename.partnerAdmin) {
    return t("role.partnerAdmin");
  }
  if (codename === ERoleCodename.customerAdmin) {
    return t("role.customerAdmin");
  }
  if (codename === ERoleCodename.customer) {
    return t("role.customer");
  }
  return "";
};
