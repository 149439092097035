import { IFaults } from "types/fault";
import { ResponseType } from "types/response";

export enum EActions {
  getFaultsRequest = "getFaultsRequest",
  getFaultsSuccess = "getFaultsSuccess",
  getFaultsFail = "getFaultsFail",

  setDiscard = "setDiscard",
  setFaults = "setFaults",
  setType = "setType",
  setSignalProcessing = "setSignalProcessing",
  setDescription = "setDescription",
  setRPM = "setRPM",
  setRPMAuto = "setRPMAuto",
  setBPFI = "setBPFI",
  setBPFO = "setBPFO",
  setBFS = "setBFS",
  setFTF = "setFTF",
  setThreshold = "setThreshold",
  setCoefficient = "setCoefficient",
  setNotification = "setNotification",
  setFrequencyEnvelopingStart = "setFrequencyEnvelopingStart",
  setFrequencyEnvelopingEnd = "setFrequencyEnvelopingEnd",
  removeFault = "removeFault",
  addFault = "addFault",
  updateSuccessfull = "updateSuccessfull",
  setIsSaving = "setIsSaving",
}

export interface IProjectState {
  loading: boolean;
  faults: ResponseType<IFaults>;
  initialFaults: ResponseType<IFaults[]> | undefined;

  editing: number | null;
  reloading: boolean;

  bfs?: number | null;
  bpfi?: number | null;
  bpfo?: number | null;
  ftf?: number | null;
  threshold?: number | null;
  type?: string | null;
  placement?: number;
  coefficient?: number | null;
  description?: string | null;
  rpm?: number | null;
  is_rpm_automatically_detected?: boolean;
  frequency_for_enveloping_end?: string;
  frequency_for_enveloping_start?: string;
  signal_processing?: string | null;
  band_alerting?: string | null;
  fault?: number;
  isSuccessfull?: boolean;
  isSaving?: boolean;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IProjectState;
}
