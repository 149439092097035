import { ITemporaryTokenState, EActions, TReducers } from "./types";

import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: ITemporaryTokenState = {
  filters: {},
  q: "",
  pageIndex: 0,
  temporaryTokenData: null,
  temporaryTokenFetchParams: null,
  isFetching: true,
  error: null,
  more: 0,
  readOnly: null,
  expiresAt: null,
  modalOpened: false,
  machines: [],
};

const reducers: TReducers = {
  [EActions.setAccessTokenFilter]: ({ payload: { filters } }) => ({
    filters,
    more: 0,
    pageIndex: 0,
  }),
  [EActions.setSearch]: ({ payload: { q } }) => ({
    q,
    more: 0,
    pageIndex: 0,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }, state) => ({
    pageIndex,
    more: 0,
    temporaryTokenData: {
      ...state.temporaryTokenData,
      results: [],
    },
  }),
  [EActions.fetchTemporaryTokenParams]: ({
    payload: { temporaryTokenFetchParams },
  }) => ({
    temporaryTokenFetchParams,
  }),
  [EActions.fetchAccessTokenRequest]: ({ payload: { silent } }, state) =>
    silent
      ? {
          isFetching: true,
        }
      : {
          temporaryTokenData: {
            ...state.temporaryTokenData,
            results: [],
          },
          isFetching: true,
        },
  [EActions.fetchAccessTokenFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchAccessTokenSuccess]: ({
    payload: { temporaryTokenData },
  }) => ({
    isFetching: false,
    error: null,
    temporaryTokenData,
  }),
  [EActions.setMore]: ({ payload: { more } }) => ({
    more,
  }),
  [EActions.setModal]: ({ payload: { modalOpened } }: any) => ({
    modalOpened,
  }),
  [EActions.setExpiresAt]: ({ payload: { expiresAt } }) => ({
    expiresAt,
  }),
  [EActions.setReadOnly]: ({ payload: { readOnly } }) => ({
    readOnly,
  }),
  [EActions.setMachines]: ({ payload: { machines } }) => ({
    machines,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};

export default createReducer<ITemporaryTokenState>(
  path,
  initialState,
  reducers
);
