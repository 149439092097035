import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => {
  return {
    heading: {
      display: "inline",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: theme.spacing(4),
      },
    },
  };
});

type Props = {
  children?: React.ReactNode;
};

const ScreenHeading: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return <div className={classes.heading}>{props.children}</div>;
};

export default ScreenHeading;
