import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "react-fast-compare";
import Paragraph from "components/typography/paragraph";
import { Box, RadioGroup } from "@mui/material";
import Radio from "components/radio";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/customModelCategory/actions";
import { AppState } from "store";
import CustomClassifierModelCategoryInfo from "./details";
import { IDataset } from "types/eventSounds";

const customModelCategory = [
  "single_output_classifier",
  "multi_output_classifier",
  "regressor",
  "anomaly_detector",
];

const CustomModelCategorySelect = memo(
  ({ datasetClasses }: { datasetClasses: IDataset[] }) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const tt = useCallback((i: string) => t(`groupedDataset.${i}`), [t]);
    const { modelCategory } = useSelector(
      (state: AppState) => state.customModelCategory
    );

    const handleModelCategory = (e: any) =>
      dispatch(actions.setModelCategory(e.target.value));

    return (
      <>
        <Paragraph>{tt("deployDialog.select.modelCategory")}</Paragraph>
        <Box m={2}>
          <RadioGroup value={modelCategory} onChange={handleModelCategory}>
            {customModelCategory.map((category: string) => {
              const label: any = (
                <Box>
                  <strong style={{ marginRight: 2 }}>
                    {t(`oneClick.select.modelCategory.label.${category}`)}
                  </strong>
                  <span style={{ fontSize: 12 }}>
                    -{" "}
                    {t(`oneClick.select.modelCategory.desciption.${category}`)}
                  </span>
                </Box>
              );
              return (
                <Box key={category}>
                  <Radio
                    activeValue={modelCategory}
                    value={category}
                    label={label}
                    disabled={
                      (category === "regressor" ||
                        category === "multi_output_classifier") &&
                      datasetClasses.length < 2
                    }
                  />
                  <Box ml={4}>
                    {modelCategory === category && (
                      <CustomClassifierModelCategoryInfo
                        modelCategory={modelCategory}
                        datasetClasses={
                          modelCategory === "regressor" ||
                          modelCategory === "anomaly_detector"
                            ? datasetClasses
                            : null
                        }
                      />
                    )}
                  </Box>
                </Box>
              );
            })}
          </RadioGroup>
        </Box>
      </>
    );
  },
  isEqual
);

export default CustomModelCategorySelect;
