import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  deployment_id: number;
};

export default async function undeployModel(machine: number, data: Params) {
  const url = endpoints.machines.undeployModel(machine);
  try {
    const response = await axiosNeuron.post(url, data);
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
