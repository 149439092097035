import { ICompanyList } from "types/company";
import { ResponseType } from "types/response";
import { SortingRule } from "react-table";

export enum EActions {
  setPage = "setPage",
  setLoading = "setLoading",
  setReloading = "setReloading",
  setData = "setData",
  setSearch = "setSearch",
  setSort = "setSort",
  setEditing = "setEditing",
  renameCompany = "renameCompany",
  setShowMore = "setShowMore",
}

export interface ICompaniesList {
  type: EActions;
  payload: {
    pageIndex: number;
    data: ResponseType<ICompanyList[]>;
    loading: boolean;
    reloading: boolean;
    q: string;
    editing: number | null;
    sort: SortingRule<number>[];
    showMore: number;
  };
}

export interface ICompaniesState {
  pageIndex: number;
  data: ResponseType<ICompaniesList[]> | undefined;
  loading: boolean;
  reloading: boolean;
  q: string;
  editing: number | null;
  sort: SortingRule<number>[];
  showMore: number;
}
