import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function genSensorTypes() {
  const url = endpoints.sensorTypes.default;
  let result = [];
  try {
    const { data } = await axiosNeuron.get(`${url}?ps=9999`);
    if (data.results) {
      result = data.results;
    }
    return result;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
