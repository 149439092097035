import React from "react";
import { makeStyles } from "@mui/styles";
import { CellProps } from "react-table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ActionsButton } from "./actionsButton";
import DateAndTime from "./dateAndTime";
import Role from "./role";
import Status from "./status";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  flexBox: {
    display: "flex",
    width: "100%",
  },
}));

const Combined = <T extends Object>({
  cell: {
    value: { id, expires_at, key, user, last_access, project, expiresAt, t },
  },
}: CellProps<T>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box className={classes.flexBox}>
            <span style={{ paddingRight: 10, position: "relative", top: 4 }}>
              {t("temporaryToken.list.table.expiration")}:{" "}
            </span>
            <DateAndTime cell={{ value: { date: expires_at } }} />
          </Box>
          <Box mt={2} className={classes.flexBox}>
            <span style={{ paddingRight: 10, position: "relative", top: 4 }}>
              {t("temporaryToken.list.table.lastAccess")}:{" "}
            </span>
            <DateAndTime cell={{ value: { date: last_access } }} />
          </Box>
          <Box mt={2} className={classes.flexBox}>
            <span style={{ paddingRight: 10, position: "relative", top: 4 }}>
              {t("temporaryToken.list.table.role.title")}:{" "}
            </span>
            <Role cell={{ value: { user } }} />
          </Box>
          <Box mt={2} className={classes.flexBox}>
            <span style={{ paddingRight: 10, position: "relative", top: 4 }}>
              {t("temporaryToken.list.table.status")}:{" "}
            </span>
            <Status cell={{ value: { expires_at } }} />
          </Box>
        </Box>
        <Grid item>
          <ActionsButton
            cell={{ value: { id, expires_at, expiresAt, key, project } }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Combined;
