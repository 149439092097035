import { useMemo, useCallback } from "react";
import Select from "components/select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/deviceDetail/channelsList/actions";
import { IRecordingPlan } from "types/recordingPlan";
import isEqual from "react-fast-compare";
import { Field, Form } from "react-final-form";
import { makeStyles } from "@mui/styles";
import { createSelector } from "reselect";
import { AppState } from "store";
import { OPlacementsList } from "store/deviceDetail/channelsList/types";

const { setPlacementRecordingPlan } = actions;

type PropsAndState = {
  state: AppState;
  placement: number | null;
};

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      height: theme.spacing(4),
      minWidth: theme.spacing(8),
      width: theme.spacing(18),
    },
    fullWidth: {
      height: theme.spacing(4),
      minWidth: theme.spacing(8),
    },
    select: {
      "& .MuiSelect-selectMenu": {
        minHeight: "auto",
      },
    },
    input: {
      fontSize: theme.custom.typography.fontSize["14"],
      letterSpacing: theme.custom.typography.letterSpacing["medium"],
      minHeight: "auto",
      height: "0.75rem",
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: 2.3,
    },
  };
});

const selector = createSelector(
  ({ placement }: PropsAndState) => placement,
  ({ state }: PropsAndState) => state.deviceDetail.channelsList,
  (id, props) => {
    return id
      ? props.placements.find(
          (item: OPlacementsList) => id && Number(item.id) === id
        )?.recording_plan
      : null;
  }
);

type TProps = {
  cell: {
    value: {
      placement: number;
      disabled: boolean;
      fullWidth?: boolean;
    };
  };
};

const planSelector = createSelector(
  (state: AppState) => state.deviceDetail.channelsList,
  ({ recordingPlans }) => recordingPlans
);

const RecordingPlanSelect = (props: TProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const { placement, disabled, fullWidth } = props.cell.value;
  const classes = useStyles();

  const recording_plan = useSelector(
    (state: AppState) => selector({ state, placement }),
    isEqual
  );

  const recordingPlans = useSelector(planSelector, isEqual).map(
    (plan: IRecordingPlan) => ({
      value: plan.codename,
      text: t(`recordingPlan.${plan.codename}`),
    })
  );

  recordingPlans.unshift({
    value: "",
    text: t("recordingPlan.unassigned"),
  });

  const selectedPlan = useMemo(
    () =>
      recordingPlans.find(({ value }: any) => value === (recording_plan || "")),
    [recordingPlans, recording_plan]
  );

  const handleChange = useCallback(
    (e: any) => {
      dispatch(
        setPlacementRecordingPlan(
          placement,
          e.target.value.length ? e.target.value : null
        )
      );
    },
    [placement, dispatch]
  );

  if (!recordingPlans) {
    return null;
  }

  return (
    <div
      className={fullWidth ? classes.fullWidth : classes.root}
      title={selectedPlan?.text}
    >
      <Form
        onSubmit={() => {}}
        initialValues={{ placement_type: selectedPlan?.value }}
        render={() => (
          <form>
            <Field
              name="placement_type"
              render={({ input, meta }) => (
                <Select
                  id={`placement_type_select_${placement}`}
                  className={classes.select}
                  name="placement_type"
                  label={t(`machine.detail.placementsList.table.recordingPlan`)}
                  input={input}
                  meta={meta}
                  inputProps={{
                    className: classes.input,
                  }}
                  selected={selectedPlan}
                  onChange={handleChange}
                  disabled={!placement || disabled}
                  options={recordingPlans}
                  size="small"
                  displayEmpty={true}
                />
              )}
            />
          </form>
        )}
      />
    </div>
  );
};

export default RecordingPlanSelect;
