import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ModalWrap } from "./components/reportsList/modal";
import { Recommendation } from "../modal/components/reportsList/recommendationField";
import { Box } from "@mui/material";
import Spinner from "components/spinner";
import actions from "store/projectDetail/reports/actions";
import { useDispatch } from "react-redux";

const { setRecommendations, setRecommendationLengthError } = actions;

interface IEditReportModal {
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  onSubmit: () => void;
  className?: string;

  recommendations: string;
  id: number;
  inputMaxLength: number;
  recommendationLengthError: string | null;
  recommendationValue?: string;
}

export const EditReport = memo(
  ({
    open,
    loading,
    onCancel,
    onSubmit,
    recommendations,
    id,
    inputMaxLength,
    recommendationLengthError,
    recommendationValue,
  }: IEditReportModal) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();

    useEffect(() => {
      dispatch(setRecommendations(recommendations));
      dispatch(setRecommendationLengthError(null));
    }, [recommendations]);

    const checkValue = recommendations === recommendationValue;

    return (
      <ModalWrap
        title={t("projects.detail.reportsTitle.edit")}
        onSubmit={onSubmit}
        open={open}
        onCancel={onCancel}
        cta={t("confirm")}
        submitDisabled={!!recommendationLengthError || checkValue}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Box mr={3} ml={3}>
            <Recommendation
              recommendations={recommendations}
              id={id}
              inputMaxLength={inputMaxLength}
              recommendationLengthError={recommendationLengthError}
            />
          </Box>
        )}
      </ModalWrap>
    );
  }
);
