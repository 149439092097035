import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import MultiSelect from "./multiSelect";
import actions, { fetchFailureTypes } from "store/eventSounds/actions";
import { IFailureTypes } from "store/eventSounds/types";

function FailureType() {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const { failureTypes, placementTypeValues, failureTypeValues } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const onChange = useCallback(
    (values: string[]) => {
      dispatch(actions.setFailureTypeValues(values));
    },
    [dispatch]
  );

  const data = failureTypes?.data?.map((item: IFailureTypes) => ({
    ...item,
    id: item.name,
    name: t(`failureType.${item.name}`),
  }));

  useEffect(() => {
    dispatch(fetchFailureTypes({ placement_type: placementTypeValues }));
  }, [dispatch, placementTypeValues]);

  return (
    <MultiSelect
      data={data}
      loading={failureTypes?.loading}
      selectedValues={failureTypeValues || []}
      onChange={onChange}
      label={t("eventSounds.dataSelection.filter.failureType")}
      placeholder={t("eventSounds.dataSelection.searchPlaceholder.failureType")}
      id="failure-type-select"
    />
  );
}

export default FailureType;
