import React, { PureComponent } from "react";
import ControlTemplate from "./ControlTemplate";
import Labels from "./Labels";
import LabelPlacements from "./LabelPlacements";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { withTranslation } from "react-i18next";

class LabelLine extends PureComponent {
  static defaultProps = {
    margin: 5,
    allLabels: [],
    labels: [],
    placement: "",
    labelPlacements: [],
    onChangeLabel: null,
    onRemoveAllLabels: null,
    onCopyLink: null,
    onSearch: null,
  };

  state = {
    labelPlacements: this.props.labelPlacements.length
      ? this.props.labelPlacements
      : [this.props.labelPlacements],
  };

  handleLabelPlacements = (checkedValues) => {
    this.setState({ ...this.state, labelPlacements: checkedValues });
  };

  render() {
    let {
      margin,
      placement,
      onChangeLabel,
      onRemoveAllLabels,
      onCopyLink,
      onSearch,
      t,
    } = this.props;
    let { labelPlacements } = this.state;
    let { labels, selected } = this.processLabels();
    return (
      <React.Fragment>
        <ControlTemplate
          margin={margin}
          label={t("taggingapp.labels.labels")}
          control={
            <Labels
              labels={labels}
              labelPlacements={labelPlacements}
              selected={selected}
              placement={placement}
              onChangeLabel={onChangeLabel}
              onRemoveAllLabels={onRemoveAllLabels}
              onCopyLink={onCopyLink}
              onSearch={onSearch}
            />
          }
        />
        <ControlTemplate
          margin={margin}
          label={t("taggingapp.labels.labelsChannels")}
          control={
            <LabelPlacements
              labelPlacements={labelPlacements}
              placement={placement}
              handleLabelPlacements={this.handleLabelPlacements}
            />
          }
        />
      </React.Fragment>
    );
  }

  processLabels() {
    let { allLabels, labels } = this.props;
    let selected = {};
    for (let label of labels) {
      selected[label.name] = true;
    }
    labels = uniqBy([...labels, ...allLabels], (item) => item.name);
    labels = orderBy(labels, ["name"], ["asc"]);
    return { labels, selected };
  }
}

export default withTranslation()(LabelLine);
