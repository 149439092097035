import { useState } from "react";
import { Box } from "@mui/material";
import ChunkCard from "./chunkCard";
import { CellProps } from "react-table";

const ChunkList = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { chunks },
    },
  } = props;
  const [audioChunks, setAudioChunks] = useState(chunks);

  const setIsPlaying = (id: number) => (value: boolean) =>
    setAudioChunks((currentAudioChunks: any) =>
      currentAudioChunks.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            isPlaying: value,
          };
        } else if (value) {
          return {
            ...item,
            isPlaying: false,
          };
        } else {
          return item;
        }
      })
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 4,
        paddingTop: 2,
        paddingBottom: 2,
        justifyContent: "center",
        paddingRight: 4,
      }}
    >
      {audioChunks.map((chunk: any) => {
        return (
          <ChunkCard key={chunk.id} chunk={chunk} setIsPlaying={setIsPlaying} />
        );
      })}
    </Box>
  );
};

export default ChunkList;
