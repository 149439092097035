import moment from "moment";
import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function addLabel(
  item: { name: string; subcategory: number },
  start: number,
  end: number,
  placements: number[],
  timezoneOffset: number,
  eventId: number | null = null,
  machineId?: number
) {
  const start_datetime = moment.unix(start).format("YYYY-MM-DD HH:mm:ss.SSS");
  const end_datetime = moment.unix(end).format("YYYY-MM-DD HH:mm:ss.SSS");
  const url = endpoints.labels.default;
  const labelData = {
    subcategory: item.subcategory,
    placements,
    machine: machineId,
    start_datetime,
    end_datetime,
    description: item.name,
    tz: timezoneOffset,
    event: eventId,
  };

  const { data } = await axiosNeuron.post(
    `${url}?tz=${timezoneOffset}`,
    labelData
  );
  return data;
}
