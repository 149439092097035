import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { useStyles } from "components/eventSounds/components/styles";
import { TextField, Typography, Box } from "@mui/material";
import { ModalWrap } from "components/eventSounds/components/modal";
import Spinner from "components/spinner";

type IProps = {
  datasetName: string;
  setDatasetName: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: () => void;
  loading: boolean;
};

const RenameModal = ({
  datasetName,
  setDatasetName,
  open,
  onCancel,
  onSubmit,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const styleModel = useStyles();

  return (
    <>
      <ModalWrap
        open={open}
        onCancel={onCancel}
        title={t("renameDataset.modal.title")}
        cta={t("save")}
        onSubmit={onSubmit}
        submitDisabled={false}
      >
        {loading ? (
          <Box display="flex" textAlign="center" justifyContent="center">
            <Spinner />
          </Box>
        ) : (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box ml={4} mt={0.5}>
                  <Box mb={4} mt={1}>
                    <Field
                      name="project"
                      id="savedDatasetName"
                      label={t("savedDataset.modal.renameDatasetName.label")}
                      render={({ id, label }) => (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          flexWrap="wrap"
                          gap={2}
                        >
                          <Typography style={{ minWidth: "8rem" }}>
                            {t("savedDataset.modal.datasetName.placeholder")}
                          </Typography>
                          <TextField
                            label={label}
                            value={datasetName}
                            id={id}
                            onChange={({ target }) =>
                              setDatasetName(target.value)
                            }
                            className={styleModel.inputWrapper}
                            variant="outlined"
                            size="small"
                            inputProps={{
                              className: styleModel.input,
                            }}
                            style={{ paddingRight: 10 }}
                            required
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
              </form>
            )}
          />
        )}
      </ModalWrap>
    </>
  );
};

export default RenameModal;
