import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IServiceList } from "types/serviceManager";

type TParams = {
  service?: string;
};

export default async function getServices({ service }: TParams) {
  const url = endpoints.serviceManager.default;

  const serviceURL = service ? `?service=${service}` : "";

  try {
    const response = await axiosNeuron.get(`${url}${serviceURL}`);

    const data = {
      ...response.data,
      results: response.data.results.map((item: IServiceList) => ({
        ...item,
        id: item.name,
      })),
    };

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
