import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import ButtonTabs from "components/buttonTab";
import isEqual from "react-fast-compare";
import GrafanaList from "./list";
import useData from "dataHooks/machines/grafana/detailGrafana";
import Spinner from "components/spinner";
import { DashboardTypes } from "enums/grafana";
import { useTranslation } from "react-i18next";
import Heading4 from "components/typography/heading/heading4";
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from "use-query-params";
import isNull from "lodash/isNull";
import keys from "lodash/keys";
import isString from "lodash/isString";
import pickBy from "lodash/pickBy";
import orderBy from "lodash/orderBy";
import { EventModal } from "components/events";
import { ELabelDateType, EModalTypes } from "store/eventModal/types";
import { actions } from "store/eventModal/slice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fromGrafana, fromPortal } from "shared/postMessages";
import getEvent from "api/handlers/event/getEvent";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import { IEventTypeList } from "types/event";
import getEventTypes from "api/handlers/event/getEventTypes";
import ConfirmPopup from "components/confirmPopup";
import { useMutation } from "react-query";
import removeEvent from "api/handlers/event/removeEvent";
import { singleEvents } from "components/machine/detail/events/list/cells/actionsButton";
import Button from "components/button";
import TimelineIcon from "@mui/icons-material/Timeline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSnackbar } from "notistack";
import {
  ANALYSIS_GET_PARAM_SEPARATOR,
  AnalysisTimeManagerData,
  prepareAnalysisUrl,
} from "components/analysisTimeManager/analysisTimeManager";
import { v4 as uuidv4 } from "uuid";
import { AppState } from "store";
import analysisTimeManagerActions from "store/machineDetail/analysisTimeManager/actions";
import appUrls from "shared/appUrls";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchMachineProductionModes } from "store/machineDetail/actions";
import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";
import ToggleButton from "@mui/lab/ToggleButton";
import patchMachine from "api/handlers/machine/patchMachine";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import { makeStyles } from "@mui/styles";
import AnalaysisTimeManagerModal from "./analysisTimeManagerModal";

const { setData: setAnalysisTimeManagerData } = analysisTimeManagerActions;

const useStyles = makeStyles((theme) => ({
  lastBtn: {
    "& > div": {
      display: "flex",
    },
  },
  managetime: {
    "& > div": {
      "& > button": {
        "& > span.MuiButton-label": {
          flexWrap: "wrap",
        },
      },
    },
  },
}));

const GrafanaDashboard = memo(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const canCreateEvents = usePermissions([
    EPermissions.CREATE_OWN_EVENT,
    EPermissions.CREATE_ANY_EVENT,
    EPermissions.CREATE_OWN_EVENT_PAIR,
    EPermissions.CREATE_ANY_EVENT_PAIR,
  ]);

  const canUpdateEvents = usePermissions([
    EPermissions.EDIT_OWN_EVENT_PAIR,
    EPermissions.EDIT_ANY_EVENT_PAIR,
    EPermissions.UPDATE_OWN_EVENT,
    EPermissions.UPDATE_ANY_EVENT,
  ]);

  const canEditMachine = usePermissions([
    EPermissions.UPDATE_OWN_MACHINE,
    EPermissions.UPDATE_ANY_MACHINE,
  ]);

  const {
    auth: { user },
  } = useSelector((state: AppState) => state);
  const isAdmin = user?.role === "nsw_admin";

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    to: NumberParam,
    from: NumberParam,
    analysisDates: withDefault(StringParam, ""),
    analysisPlacement: withDefault(StringParam, ""),
    analysisSamples: withDefault(StringParam, ""),
  });

  const { tab: tabUrl, from, to } = query;

  const [removeEventMutation] = useMutation(removeEvent, {
    onSuccess: () => {
      enqueueSnackbar(t("eventModal.actions.success.delete"));
    },
    onError: () => {
      enqueueSnackbar(t("eventModal.errors.delete"));
    },
  });

  const [eventModal, showEventModal] = useState<number | null>(null);
  const [manageTimesModalOpen, setManageTimesModalOpen] =
    useState<boolean>(false);

  const { data: analysisTimeManagerData } = useSelector(
    (state: AppState) => state.machineDetail.analysisTimeManager
  );

  const {
    productionModes,
    isFetchingProductionModes,
    machine: { productionMode: initialProductionMode },
  } = useSelector((state: AppState) => state.machineDetail.machine);

  const [productionMode, setProductionMode] = useState<string | null>(null);

  const [analysisTimeManagerDataChange, setAnalysisTimeManagerDataChange] =
    useState<AnalysisTimeManagerData | null | undefined>(undefined);

  React.useEffect(() => {
    setAnalysisTimeManagerDataChange(analysisTimeManagerData);
  }, [analysisTimeManagerData]);

  React.useEffect(() => {
    if (id) {
      const idNum = Number(id);
      !isNaN(idNum) && dispatch(fetchMachineProductionModes(idNum));
    }
  }, [dispatch, id]);

  const [patchMachineMutation] = useMutation(patchMachine);

  const [observedEvent, setObservedEvent] = useState<any>(null);
  const [observedEventToDelete, setObservedEventToDelete] = useState<any>(null);
  const [eventTypes, setEventTypes] = useState<any>(null);

  const openModal = useCallback((id?: string) => {
    if (id) {
      showEventModal(parseInt(id));
    }
  }, []);

  const closeModal = useCallback(() => {
    showEventModal(null);
  }, []);

  const saveEventTypes = async () => {
    const eventTypes = await getEventTypes();
    setEventTypes(eventTypes);
  };

  React.useEffect(() => {
    saveEventTypes();
  }, []);

  const { data, loading } = useData();

  const dashboardTab = useMemo(() => {
    if (tabUrl && DashboardTypes.hasOwnProperty(tabUrl)) {
      if (data[tabUrl as keyof typeof DashboardTypes]) {
        return DashboardTypes[tabUrl as keyof typeof DashboardTypes];
      }
    }
    return null;
  }, [tabUrl, data]);

  const [tab, setTab] = useState(dashboardTab);
  useEffect(() => {
    if (dashboardTab) {
      setTab(dashboardTab);
    }
  }, [dashboardTab]);

  React.useEffect(() => {
    setProductionMode(initialProductionMode);
  }, [loading, initialProductionMode]);

  const onChange = useCallback(
    (v: any) => {
      setQuery({ tab: v });
      setTab(DashboardTypes[v as keyof typeof DashboardTypes]);
    },
    [setQuery]
  );

  const decoratedUrl = useMemo(() => {
    if (tab) {
      if (from && to) {
        return data[tab] + `&from=${from}&to=${to}`;
      } else {
        return data[tab];
      }
    }
  }, [data, from, to, tab]);

  const TabData = useMemo(() => {
    const tabs = [];
    if (
      data?.[DashboardTypes.loudness] &&
      !isNull(data?.[DashboardTypes.loudness])
    ) {
      tabs.push({
        label: t("machine.detail.grafana.loudnessGraf"),
        value: DashboardTypes.loudness,
      });
    }
    if (
      data?.[DashboardTypes.anomaly] &&
      !isNull(data?.[DashboardTypes.anomaly])
    ) {
      tabs.push({
        label: t("machine.detail.grafana.anomalyGraf"),
        value: DashboardTypes.anomaly,
      });
    }
    if (
      data?.[DashboardTypes.combined] &&
      !isNull(data?.[DashboardTypes.combined])
    ) {
      tabs.push({
        label: t("machine.detail.grafana.combinedGraf"),
        value: DashboardTypes.combined,
      });
    }
    if (
      data?.[DashboardTypes.combined_pro] &&
      !isNull(data?.[DashboardTypes.combined_pro])
    ) {
      tabs.push({
        label: t("machine.detail.grafana.combinedProGraf"),
        value: DashboardTypes.combined_pro,
      });
    }
    return tabs;
  }, [data, t]);

  useEffect(() => {
    const availableData = keys(pickBy(data, isString));
    if (isNull(tab) && availableData.length) {
      if (
        availableData.indexOf("combined") !== -1 &&
        availableData.indexOf("combined_pro") !== -1
      ) {
        setQuery({ tab: isAdmin ? "combined_pro" : "combined" });
      } else {
        setQuery({ tab: orderBy(availableData, (a) => a, ["desc"])[0] });
      }
    }
  }, [setQuery, data, tab]);

  const readMessage = async (event: MessageEvent) => {
    if (event.data.key === fromGrafana.addForAnalysis) {
      const { date } = event.data;

      const dataToReturn = () => {
        if (analysisTimeManagerData !== null) {
          return {
            ...analysisTimeManagerData,
            dates: [
              ...analysisTimeManagerData.dates,
              {
                date: moment(date).toISOString(),
                uuid: uuidv4(),
              },
            ],
          };
        } else {
          return {
            placement: undefined,
            samples: undefined,
            dates: [
              {
                date: moment(date).toISOString(),
                uuid: uuidv4(),
              },
            ],
          };
        }
      };

      dispatch(setAnalysisTimeManagerData(dataToReturn()));
      enqueueSnackbar(t("analysisTimeManager.dateForAnalysisAdded"));
    }
    if (event.data.key === fromGrafana.editEvent) {
      const eventId = event.data.eventId;
      const eventData = await getEvent({ id: eventId });
      setObservedEvent({
        ...eventData,
        eventType: eventTypes.find(
          (type: IEventTypeList) => type.codename === eventData?.type
        ),
      });
    }
    if (event.data.key === fromGrafana.deleteEvent) {
      const eventId = event.data.eventId;
      const eventData = await getEvent({ id: eventId });
      setObservedEventToDelete(eventData);
    }
    if (event.data.key === fromGrafana.addAnnotation) {
      openModal(id);
      dispatch(
        actions.setTime({
          type: ELabelDateType.start,
          time: moment(new Date(event.data.dateFrom)).format(),
        })
      );
      if (event.data.dateTo) {
        dispatch(
          actions.setTime({
            type: ELabelDateType.end,
            time: moment(new Date(event.data.dateTo)).format(),
          })
        );
      }
    }
    if (event.data.key === fromGrafana.handshake) {
      const message = {
        key: fromPortal.handshake,
        permissions: {
          canCreateEvents,
          canUpdateEvents,
        },
      };
      sendMessageToGrafana(message);
    }
  };

  useEffect(() => {
    window.addEventListener("message", readMessage);
    return () => {
      window.removeEventListener("message", readMessage);
    };
  });

  const sendMessageToGrafana = (message: Object) => {
    (document?.getElementById("Iframe") as any)?.contentWindow.postMessage(
      message,
      "*"
    );
  };

  React.useEffect(() => {
    if (eventModal === null) {
      const message = {
        key: fromPortal.addEventClosed,
      };
      sendMessageToGrafana(message);
    }
  }, [eventModal]);

  const onRefreshGraphs = () => {
    const message = {
      key: fromPortal.refreshGraphs,
    };
    sendMessageToGrafana(message);
  };

  const deleteEvent = useCallback(
    async (eventId: number) => {
      try {
        await removeEventMutation({ id: eventId });
        setObservedEventToDelete(null);
        onRefreshGraphs();
      } catch (e) {
        console.error(e);
      }
    },
    [removeEventMutation]
  );

  React.useEffect(() => {
    setQuery(
      {
        analysisDates: analysisTimeManagerData
          ? prepareAnalysisUrl(analysisTimeManagerData)
          : undefined,
        analysisPlacement:
          analysisTimeManagerData && analysisTimeManagerData.placement
            ? analysisTimeManagerData.placement.toString()
            : undefined,
        analysisSamples:
          analysisTimeManagerData && analysisTimeManagerData.samples
            ? analysisTimeManagerData.samples.toString()
            : undefined,
      },
      "pushIn"
    );
  }, [analysisTimeManagerData]);

  React.useEffect(() => {
    if (manageTimesModalOpen) {
      setAnalysisTimeManagerDataChange(analysisTimeManagerData);
    }
  }, [manageTimesModalOpen]);

  React.useEffect(() => {
    if (query.analysisDates) {
      dispatch(
        setAnalysisTimeManagerData({
          placement: query.analysisPlacement
            ? parseInt(query.analysisPlacement)
            : undefined,
          samples: query.analysisSamples
            ? parseInt(query.analysisSamples)
            : undefined,
          dates: query.analysisDates
            .split(ANALYSIS_GET_PARAM_SEPARATOR)
            .map((item, i) => ({
              date: item,
              uuid: uuidv4(),
            })),
        })
      );
    }
  }, []);

  const analysisButtonsDisabled = !(
    analysisTimeManagerData && analysisTimeManagerData.dates.length > 0
  );

  const [productionModeToSet, setProductionModeToSet] = useState<string | null>(
    null
  );

  const changeProductionMode = (value: string) =>
    patchMachineMutation(
      {
        id: Number(id),
        payload: {
          production_mode: value,
        },
      },
      {
        onSuccess: (data: any) => {
          setProductionMode(data.production_mode);
          setProductionModeToSet(null);
        },
      }
    );

  const productionModeNames = productionModes.reduce(
    (acc: any, cur: any) => ({
      ...acc,
      [cur.codename]: cur.name,
    }),
    {}
  );

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {loading || isFetchingProductionModes ? (
          <Spinner />
        ) : (
          <>
            {!!productionModes.length && (
              <>
                <Box mt={4} mb={1}>
                  <h3 style={{ display: "inline", marginRight: "1rem" }}>
                    {t("machine.detail.grafana.productionModes")}
                  </h3>
                  <ToggleButtonGroup
                    exclusive
                    onChange={(e, value) => setProductionModeToSet(value)}
                    value={productionMode}
                  >
                    {productionModes.map((i: any) => (
                      <ToggleButton
                        value={i.codename}
                        disabled={!canEditMachine}
                      >
                        {i.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Box>
              </>
            )}
            {!TabData.length || !decoratedUrl || !tab ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                p={10}
              >
                <Heading4>{t("noData")}</Heading4>
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  mb={3}
                  width="100%"
                >
                  <Box my={2}>
                    <ButtonTabs
                      value={tab}
                      tabs={TabData}
                      onChange={onChange}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Button
                        color="primary"
                        disabled={analysisButtonsDisabled}
                        onClick={() =>
                          dispatch(setAnalysisTimeManagerData(null))
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Box>
                    <Box ml={1} className={classes.managetime}>
                      <Button
                        color="secondary"
                        startIcon={<TimelineIcon />}
                        disabled={analysisButtonsDisabled}
                        onClick={() => setManageTimesModalOpen(true)}
                        style={{
                          wordBreak: "break-word",
                          display: "flex",
                          flexWrap: "wrap",
                          lineHeight: "1.5rem",
                        }}
                      >
                        {t("analysisTimeManager.manageTime")}{" "}
                        {analysisTimeManagerData &&
                          analysisTimeManagerData?.dates?.length > 0 &&
                          `(${analysisTimeManagerData?.dates.length})`}
                      </Button>
                    </Box>
                    <Box ml={1}>
                      <Button
                        color="primary"
                        disabled={analysisButtonsDisabled}
                        onClick={() =>
                          history(appUrls.machines.detailAnalysis(id))
                        }
                        style={{
                          lineHeight: "1.5rem",
                        }}
                      >
                        {t("analysisTimeManager.goToAnalysis")}
                      </Button>
                    </Box>
                    <Box ml={1} className={classes.lastBtn}>
                      <Button
                        color="primary"
                        disabled={analysisButtonsDisabled}
                        onClick={() => {
                          window.open(
                            `${appUrls.machines.detailAnalysis(id)}${
                              window.location.search
                            }`
                          );
                        }}
                      >
                        <OpenInNewIcon />
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <GrafanaList data={decoratedUrl} />
              </>
            )}
          </>
        )}
      </Box>
      {eventModal && (
        <EventModal
          open={!!eventModal}
          machineId={eventModal}
          modalType={EModalTypes.create}
          onCancel={closeModal}
          onSuccessCreateEventCallback={onRefreshGraphs}
        />
      )}
      {observedEvent && (
        <EventModal
          open={true}
          alertSeverity={observedEvent.alert_severity}
          eventType={observedEvent.eventType}
          id={observedEvent.id}
          isSingle={singleEvents.indexOf(observedEvent.type) !== -1}
          machineId={observedEvent.machine}
          modalType={EModalTypes.edit}
          onCancel={() => setObservedEvent(null)}
          relatedId={observedEvent.related_event?.id}
          onSuccessUpdateEventCallback={onRefreshGraphs}
        />
      )}
      {observedEventToDelete && (
        <ConfirmPopup
          onConfirm={() => deleteEvent(observedEventToDelete.id)}
          title={t("eventModal.titles.delete")}
          text={
            !observedEventToDelete.related_event
              ? t("eventModal.titles.deleteText")
              : t("eventModal.titles.deleteTextPair", {
                  eventType: eventTypes.find(
                    (type: IEventTypeList) =>
                      type.codename === observedEventToDelete.related_event.type
                  )?.name,
                  timestamp: `${dateToDDMMYYYY(
                    observedEventToDelete.related_event.created_at
                  )} - ${dateToHHMM(
                    observedEventToDelete.related_event.created_at
                  )}`,
                })
          }
          confirmText={t("eventModal.actions.delete")}
          noControl={true}
          onCancel={() => setObservedEventToDelete(null)}
        />
      )}
      {!!productionModeToSet && (
        <ConfirmPopup
          onConfirm={() => changeProductionMode(productionModeToSet)}
          title={t("machine.detail.grafana.productionModeChange.title")}
          text={t("machine.detail.grafana.productionModeChange.text", {
            mode: productionModeNames[productionModeToSet],
          })}
          confirmText={t("machine.detail.grafana.productionModeChange.confirm")}
          noControl={true}
          onCancel={() => setProductionModeToSet(null)}
        />
      )}
      <AnalaysisTimeManagerModal
        open={manageTimesModalOpen}
        setModalOpen={setManageTimesModalOpen}
        analysisTimeManagerDataChange={analysisTimeManagerDataChange}
        setAnalysisTimeManagerDataChange={setAnalysisTimeManagerDataChange}
      />
    </>
  );
}, isEqual);

export default GrafanaDashboard;
