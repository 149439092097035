import { EActions, AuthActionTypes } from "store/auth/types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import authHandler from "localStorage/authHandler";
import { AppState } from "store";
import appUrls from "shared/appUrls";
import history from "shared/history";
import { queryCache } from "react-query";
import getUser from "api/handlers/user/getUser";
import { prepareActions } from "store/helpers";
import grafanaRedirect from "shared/grafanaRedirect";
import { deleteCookie } from "shared/cookies";

export const path = "auth";

const actionsData = [
  [EActions.setAuth, "user"],
  [EActions.setAuthFail, "errors"],
  [EActions.setLoading, "loading"],
  [EActions.setGrafanaUrl, "grafanaUrl"],
  [EActions.setExpiredToken, "expiredToken"],
  [EActions.closeGrafanaModal],
];

const actions = prepareActions<AuthActionTypes, EActions>(actionsData, path);

export default actions;

export const auth =
  () => async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    try {
      dispatch(actions.setLoading(true));
      const user = await getUser();
      if (user?.id) {
        const grafanaUrl = grafanaRedirect();
        if (grafanaUrl) {
          try {
            window.location.assign(grafanaUrl);
            return;
          } catch (err) {
            return;
          }
        }
        dispatch(actions.setAuth(user));
      }
    } catch (error) {
      dispatch(actions.setAuthFail(error));
    }
  };

export const setToken =
  (token: string, tokenType: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const grafanaUrl = getState().auth.grafanaUrl;
      dispatch(actions.setLoading(true));
      authHandler.setToken(token);
      authHandler.setTokenType(tokenType);

      const user = await getUser();
      if (user) {
        if (grafanaUrl) {
          try {
            window.location.assign(grafanaUrl);
            return;
          } catch (err) {
            return;
          }
        }
        dispatch(actions.setAuth(user));
      }
    } catch (error) {
      dispatch(actions.setAuthFail(error));
    }
  };

export const logout =
  (expiredToken: boolean) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { user } = getState().auth;

      if (user?.id) {
        dispatch({ type: "USER_LOGOUT" });
        history.push(appUrls.appRoot.index());
      }
      authHandler.clearToken();
      queryCache.clear();
      deleteCookie();
      dispatch(actions.setAuth(null));
      dispatch(actions.setExpiredToken(!!expiredToken));
    } catch (error) {}
  };
