import { Column } from "react-table";
import { TFunction } from "i18next";
import { IReportsList } from "types/report";
import Combined from "./cells/combinedCells";

export const createTinyColumns = (
  t: TFunction,
  inputMaxLength: number,
  recommendationLengthError: string | null,
  companyId: number,
  projectId: number,
  userIds: any,
  recommendationValue?: string
): Column<IReportsList>[] => [
  {
    id: "combined",
    Header: "",
    accessor: ({ id, name, recommendations, last_update_at }: any) => ({
      id,
      name,
      recommendations,
      recommendationValue,
      last_update_at,
      t,
      path: "projectDetail.reports",
      inputMaxLength,
      recommendationLengthError,
      companyId,
      projectId,
      userIds,
    }),
    Cell: Combined,
    custom: {
      hidePriority: 0,
    },
  },
];
