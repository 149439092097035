import { ICPState, EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: ICPState = {
  pageIndex: 0,
  CPData: null,
  isFetching: false,
  error: null,
  activeCompanies: [],
  activeProjects: [],
  originalActiveProjects: [],
  originalActiveCompanies: [],
};

const reducers: TReducers = {
  [EActions.setCPResetList]: () => ({
    activeCompanies: [],
    activeProjects: [],
    originalActiveProjects: [],
    originalActiveCompanies: [],
    CPData: null,
    pageIndex: 0,
  }),
  [EActions.setCPPage]: ({ payload: { pageIndex } }, state) => ({
    pageIndex,
  }),
  [EActions.fetchCPRequest]: () => ({
    isFetching: true,
  }),
  [EActions.setActiveCompanies]: ({ payload: { activeCompanies } }) => ({
    activeCompanies,
  }),
  [EActions.setActiveProjects]: ({ payload: { activeProjects } }) => ({
    activeProjects,
  }),
  [EActions.discardChanges]: () => ({
    activeCompanies: [],
    activeProjects: [],
  }),
  [EActions.setOriginalActiveCP]: ({
    payload: { originalActiveCompanies, originalActiveProjects },
  }) => ({
    activeCompanies: originalActiveCompanies,
    originalActiveCompanies,
    activeProjects: originalActiveProjects,
    originalActiveProjects,
  }),
  [EActions.fetchCPFail]: ({ payload: { error } }) => ({
    isFetching: false,
    error,
  }),
  [EActions.fetchCPSuccess]: ({ payload: { CPData } }, state) => ({
    isFetching: false,
    error: null,
    CPData: {
      ...CPData,
      results: [...(CPData?.results || [])],
    },
  }),
};

export default createReducer<ICPState>(path, initialState, reducers);
