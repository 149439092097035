import { SortingRule } from "react-table";

import { IUserList } from "types/user";
import { ResponseType } from "types/response";

export enum EActions {
  setUsersFilter = "setUsersFilter",
  setPage = "setPage",
  fetchUsersRequest = "fetchUsersRequest",
  fetchUsersFail = "fetchUsersFail",
  fetchUsersSuccess = "fetchUsersSuccess",
  setModal = "setModal",
  setMore = "setMore",
  setSort = "setSort",
  setResetList = "setResetList",
  fetchRolesRequest = "fetchRolesRequest",
  fetchRolesFail = "fetchRolesFail",
  fetchRolesSuccess = "fetchRolesSuccess",
  fetchCompaniesRequest = "fetchCompaniesRequest",
  fetchCompaniesFail = "fetchCompaniesFail",
  fetchCompaniesSuccess = "fetchCompaniesSuccess",
  fetchProjectsRequest = "fetchProjectsRequest",
  fetchProjectsFail = "fetchProjectsFail",
  fetchProjectsSuccess = "fetchProjectsSuccess",
  fetchStatusesRequest = "fetchStatusesRequest",
  fetchStatusesFail = "fetchStatusesFail",
  fetchStatusesSuccess = "fetchStatusesSuccess",
}

interface filterData {
  value: string;
  text: string;
}

interface filterType {
  data: filterData[];
  isFetching: boolean;
  error: null;
}

export interface IUsersState {
  filters?: { [type: string]: string[] };
  pageIndex: number;
  usersData?: ResponseType<IUserList> | null;
  usersFetchParams?: any | null;
  isFetching?: boolean;
  sort: SortingRule<number>[];
  error?: Error | null;
  modalOpened: boolean;
  more: number;
  roles: filterType;
  companies: filterType;
  projects: filterType;
  statuses: filterType;
}

export interface IUsersAction {
  type: typeof EActions;
  payload: IUsersState;
}

export type TReducers = {
  [key: string]: (p: IUsersAction, s: IUsersState) => void;
};

export type TUsersActionTypes = IUsersAction;

export interface IFetchUsersParams {
  page: number;
  pageSize: number;
  orderBy: SortingRule<number>[];
  filterObject?: { [key: string]: number[] | string[] };
  q?: string;
}
