import { memo, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

import Spinner from "components/spinner";
import Content from "components/layout/content";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import Search from "./filter";
import HorizontalLine from "components/horizontalLine";

import CreateUser from "screens/users/createUser";
import { createColumns } from "components/users/list/projectDetail/columns";
import { createTinyColumns } from "components/users/list/projectDetail/tinyColumns";
import useData from "dataHooks/projects/users/dataHook";
import { IUserList } from "types/user";
import urls from "shared/appUrls";
import AddUserModal from "components/projects/detail/modal/addUser";
import addUserActions from "store/addUserModal/actions";
import usersActions from "store/projectDetail/users/actions";
import { useDispatch } from "react-redux";
import useProjectsData from "dataHooks/projects/detailData";

const { setModal } = addUserActions;
const { setReset } = usersActions;

export const UsersTab = memo(() => {
  const history = useNavigate();
  const { id } = useParams<{ [key: string]: string | undefined }>();
  const { project } = useProjectsData(Number(id));
  const {
    users,
    modalOpened,
    isFetching,
    initialState,
    showMoreClickedTimesRef,
    onPageChange,
    onPageSort,
  } = useData(project.company);

  const dispatch: any = useDispatch();

  const handleRowClick = useCallback(
    (userId?: number) => {
      if (userId) {
        history(urls.users.detail(userId, "account", id));
        showMoreClickedTimesRef.current = 0;
      }
    },
    [history, id, showMoreClickedTimesRef]
  );

  const theme = useTheme();
  const { t } = useTranslation();
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(t, Number(id)),
    [id, t]
  );
  const columnsMemoized = useMemo(() => createColumns(t, Number(id)), [id, t]);
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  const dataItems = useMemo(() => {
    if (!users) {
      return [];
    }
    return users.results;
  }, [users]);

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, [dispatch]);

  const cancelModal = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch]);

  return (
    <>
      <Content>
        <Box mx={-1}>
          <Search />
          <HorizontalLine />
          {isFetching && !showMoreClickedTimesRef.current ? (
            <Spinner />
          ) : (
            <div id="testProjectDetailUsersTable">
              <Table<IUserList>
                showMoreClickedTimesRef={showMoreClickedTimesRef}
                isLoading={isFetching!}
                data={dataItems}
                rowsCount={users ? users.count : 0}
                columns={columns}
                initialState={initialState}
                onPageChange={onPageChange}
                onPageSort={onPageSort}
                RowComponent={Row}
                HeaderComponent={Header}
                RowComponentProps={{
                  onClick: (id?: number) => handleRowClick(id),
                }}
              />
            </div>
          )}
        </Box>
      </Content>
      {modalOpened === "add" && <AddUserModal onCancel={cancelModal} />}
      {modalOpened === "create" && (
        <CreateUser forcedOpen onClose={cancelModal} />
      )}
    </>
  );
});

export default UsersTab;
