import { memo, useCallback, useEffect } from "react";
import Menu from "./menu";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import LogoSmall from "components/logotypes/small";
import LogoHorizontal from "components/logotypes/horizontal";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { AppState } from "store";
import actions from "store/layout/actions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { pxToRem } from "shared/helpers";
import { Box } from "@mui/material";

type Props = {
  opened?: boolean;
};

const useStyles = makeStyles((theme: any) => {
  return {
    drawer: {
      position: "relative",
      width: ({ opened }: Props) =>
        opened ? theme.spacing(28) : theme.spacing(9),
    },
    drawerPaper: {
      backgroundColor: "transparent",
      borderRight: "none",
      overflow: "visible",
      width: ({ opened }: Props) =>
        opened ? theme.spacing(32) : theme.spacing(12),
      padding: theme.spacing(3),
    },
    opened: {
      padding: theme.spacing(3),
      width: theme.spacing(32),
    },
    sidebarInner: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.between("sm", "md")]: {
        margin: 0,
        textAlign: "center",
      },
    },
    toggleButton: {
      position: "relative",
      left: "100%",
      backgroundColor: theme.custom.palette.secondary,
      zIndex: 1000,
      width: pxToRem(40),
      height: pxToRem(40),
      boxShadow: theme.custom.boxShadow[1],
    },
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: pxToRem(42),
    },
  };
});

const Desktop = memo(() => {
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const menuOpened = useSelector(
    (state: AppState) => state.layout.menu,
    shallowEqual
  );
  const classes = useStyles({ opened: menuOpened });

  const toggleMenu = useCallback(
    () => dispatch(actions.toggleMenu()),
    [dispatch]
  );

  const controlButton = useCallback(() => {
    // if(e.innerWidth)
    if (menuOpened) {
      if (window.innerWidth <= theme.breakpoints.values.md) {
        dispatch(actions.toggleMenu(false));
      }
    }
  }, [menuOpened, dispatch, theme.breakpoints.values.md]);

  useEffect(() => {
    window.addEventListener("resize", controlButton);
    return () => {
      window.removeEventListener("resize", controlButton);
    };
  }, [controlButton]);

  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      <nav className={classes.drawer}>
        <Drawer
          variant="permanent"
          open
          classes={{
            paper:
              classes.drawerPaper + " " + (menuOpened ? classes.opened : ""),
          }}
        >
          <div className={classes.logo}>
            {!menuOpened ? <LogoSmall /> : <LogoHorizontal />}
          </div>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <IconButton onClick={toggleMenu} className={classes.toggleButton}>
              <ChevronRightIcon
                style={{
                  fontSize: 30,
                  transition: "transform 0.2s linear",
                  transitionDelay: "0.3s",
                  transform: `rotateZ(${menuOpened ? "-180deg" : "0deg"})`,
                }}
              />
            </IconButton>
          </Box>
          <Menu opened={menuOpened} innerStyle={classes.sidebarInner} />
        </Drawer>
      </nav>
    </Box>
  );
});

export default Desktop;
