import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import getProjects from "api/handlers/project/getProjects";
import getCompanies from "api/handlers/company/getCompanies";
import { IProjectList } from "types/project";
import { ICompanyList } from "types/company";
import { ResponseType } from "types/response";
import { ERoleCodename } from "types/role";
import { SortingRule } from "react-table";
import EStatusName from "enums/userStatus";

export interface UsersItemTypes {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  lastLogin: string | null;
  companies: number[];
  companiesNames: (string | null)[];
  projects: number[];
  projectsNames: (string | null)[];
  status: EStatusName;
  role: ERoleCodename | null;
  username?: string;
}

type TParams = {
  page?: number;
  pageSize?: number;
  orderBy?: SortingRule<number>[];
  filterObject?: { [key: string]: number[] | string[] | number };
  q?: string;
  ids?: number[];
};

export default async function getUsers(
  { page, pageSize, orderBy, filterObject, q, ids }: TParams,
  withCP?: boolean,
  assignByProject?: number
) {
  const orders = orderBy?.map((item: any) => item.id);
  const orderByExtended: SortingRule<string>[] = [];
  const params = {
    ps: pageSize,
    page,
    order_by_assigned_project:
      orders?.indexOf("assigned_project") !== -1 ? assignByProject : undefined,
    q: q?.length ? q : undefined,
    ...filterObject,
    id: ids,
  };

  const url = endpoints.users.default;

  if (orderBy?.length) {
    for (let rule of orderBy) {
      orderByExtended.push(rule);
      if (rule.id.includes("last_name")) {
        const firstNameSort = {
          id: "first_name",
          desc: rule.desc,
        };
        orderByExtended.push(firstNameSort);
      }
    }
  }

  const orderByString = orderByExtended.length
    ? `&order_by=${orderByExtended
        .map((item: any) => `${!item.desc ? "-" : ""}${item.id}`)
        .join("&order_by=")}`
    : "";

  try {
    const response = await axiosNeuron.get(`${url}?${orderByString}`, {
      params,
    });

    const { count } = response.data;
    const data: ResponseType<UsersItemTypes> = {
      count: count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        firstName: item.first_name,
        lastName: item.last_name,
        email: item.email,
        lastLogin: item.last_login ? new Date(item.last_login) : undefined,
        companies: item.companies,
        companiesNames: [],
        phone: item.phone,
        projects: item.projects,
        projectsNames: [],
        status: item.status,
        role: item.role,
      })),
    };

    if (withCP) {
      const userIds = data.results.map((user: any) => user.id);
      const [projects, companies] = await Promise.all([
        getProjects({ userIds }),
        getCompanies({ userIds }),
      ]);

      data.results.forEach((user: any) => {
        user.projectsNames = projects
          ? projects.results
              .filter((project: any) => user.projects.includes(project.id))
              .map((project: IProjectList) => project.projectName || null)
          : [];
        user.companiesNames = companies
          ? companies.results
              .filter((company: any) => user.companies.includes(company.id))
              .map((company: ICompanyList) => company.name)
          : [];
        return user;
      });
    }

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
