import { Action } from "redux";
import { EActions, IAddUserActions } from "./types";
import { prepareActions } from "store/helpers";
import { ThunkDispatch } from "redux-thunk";
import getUsers from "api/handlers/user/getUsers";
import patchUser from "api/handlers/user/patchUser";
import getProject from "api/handlers/project/getProject";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import uniq from "lodash/uniq";
import i18n from "localization";
import { refetchUsersSilent } from "store/projectDetail/users/actions";
import { IUserList } from "types/user";
import { ERoleCodename } from "types/role";

export const path = "addUserModal";

const actionData = [
  [EActions.setPage, "pageIndex"],
  [EActions.setData, "data"],
  [EActions.setLoading, "loading"],
  [EActions.setReloading, "reloading"],
  [EActions.setSort, "sort"],
  [EActions.setShowMore, "showMore"],
  [EActions.setSearch, "q"],
  [EActions.setReset],
  [EActions.setSelected, "selected"],
  [EActions.setModal, "modalOpened"],
];

const actions = prepareActions<IAddUserActions, EActions>(actionData, path);

export default actions;

export const fetchUsers =
  ({ pageIndex, pageSize, orderBy }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const user = getState().auth.user;
      dispatch(actions[EActions.setLoading](true));
      const { q } = getState().addUserModal;
      const users = await getUsers({
        page: pageIndex,
        pageSize,
        orderBy,
        filterObject:
          ERoleCodename.nswAdmin !== user.role ? { not_id: user.id } : {},
        q,
      });
      dispatch(actions[EActions.setData](users));
    } catch (error) {
      dispatch(actions[EActions.setLoading](false));
    }
  };

export const editProject =
  (id: number) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      dispatch(actions[EActions.setReloading](true));

      const { selected, data } = getState().addUserModal;
      const project = await getProject(id);

      if (project?.id) {
        await Promise.all(
          selected.map(async (userId: number) => {
            const currentProjects = data?.results.find(
              (user: IUserList) => user.id === userId
            ).projects;
            return await patchUser({
              userId,
              projects: uniq([...currentProjects, project.id]),
            });
          })
        );
      }

      dispatch(actions[EActions.setModal](false));
      dispatch(actions[EActions.setReset]());
      dispatch(refetchUsersSilent());
      enqueueSnackbar(i18n.t("projects.detail.modal.users.success"));
    } catch (error) {
      dispatch(actions[EActions.setReloading](false));
      enqueueSnackbar(i18n.t("projects.detail.modal.users.fail"));
    }
  };
