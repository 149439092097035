import { useEffect, useCallback } from "react";
import {
  fetchProject,
  renameProject,
} from "store/projectDetail/project/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";

import { createSelector } from "reselect";
const selector = createSelector(
  (state: AppState) => state.projectDetail.project,
  (projectDetail: any) => {
    return {
      project: projectDetail.project,
    };
  }
);

const useDataProjectsDetail = (id: number) => {
  const { project } = useSelector(selector, shallowEqual);

  const dispatch: any = useDispatch();

  const onNameChange = useCallback(
    (name: string) => {
      dispatch(renameProject(id, name));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(fetchProject(id));
  }, [dispatch, id]);

  return {
    project,
    onNameChange,
  };
};

export default useDataProjectsDetail;
