import { prepareActions } from "store/helpers";
import { EActions, INotifierActions } from "./types";

export const path = "notifier";

const actionsData = [
  [EActions.removeSnackbar, "key"],
  [EActions.closeSnackbar, "key"],
  [EActions.enqueueSnackbar, "message", "options"],
];

const actions = prepareActions<INotifierActions, EActions>(actionsData, path);
export default actions;
