import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function moveDevice({
  id,
  project,
}: {
  id: string;
  project: number | null;
}) {
  const url = endpoints.devices.move(id);
  try {
    const response = await axiosNeuron.post(url, { project });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
