import React from "react";
import LogoImage from "assets/images/logo.svg";
import { useTranslation } from "react-i18next";

const LogoHorizontal = () => {
  const { t } = useTranslation();
  return <img src={LogoImage} alt={t("app.name")} />;
};

export default LogoHorizontal;
