import React, { PureComponent } from "react";
import Grid from "@mui/material/Grid";

class ControlTemplate extends PureComponent {
  static defaultProps = {
    control: null,
    label: null,
    margin: 0,
  };

  render() {
    let { margin, label, control } = this.props;
    return (
      <div
        style={{
          marginLeft: margin,
          marginRight: margin,
          marginBottom: margin,
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item xs={4}>
            <div style={{ textAlign: "right", paddingRight: 10 }}>{label}</div>
          </Grid>
          <Grid item xs={8}>
            {control}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ControlTemplate;
