import { EActions, ICompaniesList, ICompaniesState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: ICompaniesState = {
  data: undefined,
  pageIndex: 0,
  loading: false,
  reloading: false,
  q: "",
  editing: null,
  sort: [],
  showMore: 0,
};

const reducers = {
  [EActions.setData]: ({ payload: { data } }: ICompaniesList) => ({
    data,
    loading: false,
    reloading: false,
    editing: null,
  }),
  [EActions.setLoading]: ({ payload: { loading } }: ICompaniesList) => ({
    loading,
  }),
  [EActions.setReloading]: ({ payload: { reloading } }: ICompaniesList) => ({
    reloading,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }: ICompaniesList) => ({
    pageIndex,
  }),
  [EActions.setSearch]: ({ payload: { q } }: ICompaniesList) => ({
    q,
  }),
  [EActions.setEditing]: ({ payload: { editing } }: ICompaniesList) => ({
    editing,
  }),
  [EActions.setSort]: ({ payload: { sort } }: ICompaniesList) => ({ sort }),
  [EActions.setShowMore]: ({ payload: { showMore } }: ICompaniesList) => ({
    showMore,
  }),
};
export default createReducer<ICompaniesState>(path, initialState, reducers);
