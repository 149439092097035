import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: IMuiTheme) => ({
  root: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  fullHeight: {
    height: "100%",
  },
  footer: {
    padding: theme.spacing(1),
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
  },
  modalWrapper: {
    [`@media only screen and (min-width: ${theme.spacing(
      102
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "70%",
      height: theme.spacing(50),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      130
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "60%",
      height: theme.spacing(50),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      160
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "40%",
      height: theme.spacing(50),
    },
  },
  modalContainer: {
    [`@media only screen and (min-width: ${theme.spacing(
      102
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "80%",
      height: "80%",
    },
  },

  inputWrapper: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  input: {
    fontSize: theme.custom.typography.fontSize["14"],
    letterSpacing: theme.custom.typography.letterSpacing["medium"],
  },
  fullWidth: {
    width: "100%",
  },
}));
