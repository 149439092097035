import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    color: theme.custom.palette.data,
    fontSize: theme.custom.typography.fontSize[24],
    fontWeight: theme.custom.typography.fontWeight["bold"],
    letterSpacing: theme.custom.typography.letterSpacing["medium"],
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  title?: string;
  children?: JSX.Element;
};

const FormModalHeader = (props: Props) => {
  const { title, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>{title}</div>
      <div>{children}</div>
    </div>
  );
};

export default FormModalHeader;
