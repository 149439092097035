export enum EActions {
  setModalOpen = "setModalOpen",
  reset = "reset",
  setExportStart = "setExportStart",
  setExportEnd = "setExportEnd",
  setStartDate = "setStartDate",
  setEndDate = "setEndDate",
}

export interface IEventsExporterState {
  modalOpen: boolean;
  exporting: boolean;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IEventsExporterState;
}
