import { ResponseType } from "types/response";
import { ICompanyList } from "types/company";
import { IProjectList } from "types/project";
import { IFacility } from "types/facility";

export enum EActions {
  fetchMachinesRequest = "fetchMachinesRequest",
  fetchMachinesSuccess = "fetchMachinesSuccess",
  fetchMachinesFail = "fetchMachinesFail",
  refetchMachinesRequest = "refetchMachinesRequest",
  refetchMachinesSuccess = "refetchMachinesSuccess",
  refetchMachinesFail = "refetchMachinesFail",
  fetchCardsRequest = "fetchCardsRequest",
  fetchCardsSuccess = "fetchCardsSuccess",
  fetchCardsFail = "fetchCardsFail",
  fetchLast30DaysRequest = "fetchLast30DaysRequest",
  fetchLast30DaysSuccess = "fetchLast30DaysSuccess",
  fetchLast30DaysFail = "fetchLast30DaysFail",
  fetchDeviceHealthRequest = "fetchDeviceHealthRequest",
  fetchDeviceHealthSuccess = "fetchDeviceHealthSuccess",
  fetchDeviceHealthFail = "fetchDeviceHealthFail",
  fetchDevicesRequest = "fetchDevicesRequest",
  fetchDevicesSuccess = "fetchDevicesSuccess",
  fetchDevicesFail = "fetchDevicesFail",
  setResetList = "setResetList",
}
interface IMachinesListData {
  companies: ResponseType<ICompanyList>;
  projects: ResponseType<ICompanyList>;
  machines: ResponseType<IProjectList>;
  facilities: ResponseType<IFacility>;
}

export interface IMachinesListState {
  data: IMachinesListData;
  isFetching?: boolean;
  error?: Error | null;
  utilization?: any;
  utilizationTotals?: any;
}

export interface IUsersAction {
  type: typeof EActions;
  payload: IMachinesListState;
}

export type TReducers = {
  [key: string]: (p: IUsersAction, s: IMachinesListState) => void;
};

export type TUsersActionTypes = IUsersAction;

export interface IFetchMachineParams {
  company: number[];
  facility: number[];
  page: number;
  pageSize: number;
  project: number[];
  q?: string;
  status: string;
  field?: string[];
  tab?: string | null;
  ids?: number[];
  shouldFetchUtilization?: boolean;
}
