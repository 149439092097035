import React from "react";
import { Column, CellProps } from "react-table";
import Avatar from "components/users/list/cells/avatar";
import TinyCompoundCell from "./tinyCompoundCell";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import NotifyCell from "components/machine/detail/general/users/cells/notifyCell";
import { getTranslatedRole } from "shared/roles";

interface AvatarProps {
  marginTop?: number;
  cell: {
    value: {
      name: string;
      image: string;
    };
  };
}

export const createTinyColumns = (t: TFunction): Column<IUserList>[] => [
  {
    Header: "",
    id: "compoundCell",
    accessor: (originalRow: any) => {
      return {
        name: [originalRow.firstName, originalRow.lastName].join(" "),
        role: getTranslatedRole(t, originalRow.role),
        label: originalRow.email,
        phone: originalRow.phone,
        status: originalRow.status,
      };
    },
    Cell: TinyCompoundCell,
    custom: {
      hidePriority: 0,
      margin: "1rem",
    },
  },
  {
    Header: "",
    id: "notify",
    accessor: (originalRow: any) => {
      return {
        id: originalRow.id,
      };
    },
    Cell: (props: CellProps<IUserList>) => {
      return <NotifyCell {...props} />;
    },
    custom: {
      hidePriority: 0,
      width: 72,
      verticalAlignTop: true,
      margin: "1rem",
    },
  },
];
