import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface IProps {
  id: number;
  recommendations?: string;
}

export default async function patchReport({ id, ...payload }: IProps) {
  const url = endpoints.reports.detail(id);

  try {
    const { data } = await axiosNeuron.patch(url, payload);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
