import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IEventParams {
  id: number;
}

export default async function getMachineProductionModes(params: IEventParams) {
  const url = endpoints.machines.productionModes(params.id);
  try {
    const { data } = await axiosNeuron.get(url);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
