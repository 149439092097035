export enum EActions {
  setFilter = "setFilter",
  setPage = "setPage",
  reset = "reset",
}

export interface Istate {
  filters?: {
    companyId?: number[] | null;
    projectId?: number[] | null;
    facilityId?: number[] | null;
    status?: string;
    q?: string;
  };
  currentPage?: number;
}

export interface IMachineFilterAction {
  type: typeof EActions;
  payload: Istate;
}

export type TReducers = {
  [key: string]: (p: IMachineFilterAction, s: Istate) => Istate;
};
