import React, { useMemo, useCallback } from "react";
import { handleUserCheck } from "store/projectDetail/users/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import Checkbox from "components/checkbox";
import { ERoleCodename } from "types/role";

interface Props {
  cell: {
    value: {
      projects: number[];
      id: number;
      userIds: number[];
    };
  };
}

const BelongsToProject = ({
  cell: {
    value: { id, userIds },
  },
}: Props) => {
  const dispatch: any = useDispatch();
  const { id: userId, role } = useSelector(
    (state: AppState) => state.auth?.user
  );

  const onChange = useCallback(
    (event: any) => {
      event.stopPropagation();
      const idExists = userIds.includes(id);

      if (idExists) {
        const updatedUserIds = userIds.filter((userId) => userId !== id);
        dispatch(handleUserCheck({ userIds: updatedUserIds }));
      } else {
        const updatedUserIds = [...userIds, id];
        dispatch(handleUserCheck({ userIds: updatedUserIds }));
      }
    },
    [dispatch, id, userIds]
  );

  const stopPropagation = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const isChecked = useMemo(() => {
    if (userIds.find((userId) => Number(userId) === Number(id))) return true;
    return false;
  }, [id, userIds]);

  const disabled = useMemo(() => {
    if (userId && userId === id && role !== ERoleCodename.nswAdmin) {
      return true;
    }
    return false;
  }, [id, role, userId]);

  return (
    <div onClick={stopPropagation}>
      <Checkbox disabled={disabled} onChange={onChange} checked={isChecked} />
    </div>
  );
};

export default BelongsToProject;
