import { Column } from "react-table";
import { TFunction } from "i18next";
import ChunkList from "./cells/chunk";
import Label from "./cells/label";
import { IGroupedEventSoundList } from "types/eventSounds";

export const createColumns = (
  t: TFunction,
  setIsPlaying: any,
  setPlayingAll: any
): Column<IGroupedEventSoundList>[] => [
  {
    id: "label",
    Header: t("").toString(),
    accessor: ({ label }) => {
      return {
        label,
      };
    },
    Cell: Label,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
      width: "16rem",
    },
  },
  {
    id: "chunkList",
    Header: t("").toString(),
    accessor: ({ chunks, label }) => {
      return {
        chunks,
        label,
        setIsPlaying,
        setPlayingAll,
      };
    },
    Cell: ChunkList,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
];
