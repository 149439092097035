import { useEffect } from "react";
import {
  fetchChannels,
  fetchConnectableMachines,
} from "store/deviceDetail/channelsList/actions";
import { AppState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import isEqual from "react-fast-compare";
import { IChannelList } from "types/channels";

const selector = createSelector(
  (state: AppState) => state.deviceDetail.channelsList,
  ({ disconnected, loading, controlObject, channels }) => {
    const data = channels.map((item: IChannelList) => {
      return {
        id: item.id,
        number: item.number,
        gain: item.gain,
        sensor_type: item.sensor_type,
        placement: item.placement,
        type: item.type,
        audioChunkTypes: item.audioChunkTypes,
      };
    });
    return {
      disconnected,
      loading,
      controlObject,
      data,
    };
  }
);

const useDataDevices = (deviceId: number) => {
  const { disconnected, data, loading, controlObject } = useSelector(
    selector,
    isEqual
  );
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (disconnected) {
      dispatch(fetchConnectableMachines(deviceId, disconnected));
    }
  }, [deviceId, disconnected, dispatch]);

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchChannels());
    }
  }, [dispatch, deviceId]);

  if (loading) {
    return { loading: true };
  }
  return {
    loading: false,
    channels: data,
    controlObject,
  };
};
export default useDataDevices;
