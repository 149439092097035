import React from "react";
import { Column } from "react-table";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import BelongsToProject from "components/users/list/cells/belongsToProject";
import { getTranslatedRole } from "shared/roles";
import CombinedCell from "./cells/combined";

export const createTinyColumns = (
  t: TFunction,
  userIds: number[]
): Column<IUserList>[] => [
  {
    id: "is_project",
    Header: "",
    accessor: ({ projects }) => ({
      projects,
      userIds,
    }),
    Cell: BelongsToProject,
    custom: {
      hidePriority: 0,
      margin: "0 0 0 1rem",
    },
  },
  {
    Header: "",
    id: "compoundCell",
    accessor: (originalRow) => {
      return {
        name: [originalRow.firstName, originalRow.lastName].join(" "),
        role: getTranslatedRole(t, originalRow.role),
        label: originalRow.email,
        status: originalRow.status,
      };
    },
    Cell: CombinedCell,
    custom: {
      hidePriority: 0,
    },
  },
];
