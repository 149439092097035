import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import dayjs from "dayjs";

type TParams = {
  machine: number;
  start?:
    | {
        type: string;
        created_at: string;
        note: string;
        meta?: {
          purpose: string;
        };
        placements?: number[];
      }
    | undefined;
  end?: {
    type: string;
    created_at: string;
    note: string;
  };
};

export default async function addEvent(event: TParams) {
  const url = endpoints.events.pair;

  if (event.end) {
    const date = dayjs(event.end.created_at);
    if (date.second() === 0 && date.millisecond() === 0) {
      event.end.created_at = date
        .add(-1, "millisecond")
        .format("YYYY-MM-DD HH:mm:ss.SSSZ");
    }
  }

  try {
    const response = await axiosNeuron.post(url, event);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
