import { Column } from "react-table";
import Avatar from "components/users/list/cells/avatar";
import LabelAndTag from "components/users/list/cells/labelAndTag";
import LabelEmail from "components/users/list/cells/labelEmail";
import { IUserList } from "types/user";
import { TFunction } from "i18next";
import NotifyCell from "components/machine/detail/general/users/cells/notifyCell";
import { getTranslatedRole } from "shared/roles";

export const createColumns = (t: TFunction): Column<IUserList>[] => [
  {
    Header: "",
    id: "image",
    disableSortBy: true,
    accessor: (originalRow: any) => {
      return {
        name: [originalRow.firstName, originalRow.lastName].join(" "),
        image: originalRow.image,
      };
    },
    Cell: Avatar,
    custom: {
      hidePriority: 0,
      width: 64,
    },
  },
  {
    id: "last_name",
    Header: t("users.list.table.name").toString(),
    accessor: (originalRow: any) => {
      return {
        name: [originalRow.firstName, originalRow.lastName].join(" "),
        role: getTranslatedRole(t, originalRow.role),
      };
    },
    Cell: LabelAndTag,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "email",
    Header: t("users.list.table.email").toString(),
    accessor: (originalRow: any) => {
      return { label: originalRow.email };
    },
    Cell: LabelEmail,
    custom: {
      hidePriority: 0,
    },
  },
  {
    id: "phone",
    Header: t("users.detail.phoneNumber").toString(),
    accessor: (originalRow: any) => {
      return { date: originalRow.phone };
    },
    Cell: LabelEmail,
    custom: {
      hidePriority: 0,
    },
  },
  {
    Header: t("users.list.table.notify").toString(),
    id: "notify",
    accessor: (originalRow: any) => {
      return {
        id: originalRow.id,
      };
    },
    Cell: NotifyCell,
    custom: {
      hidePriority: 0,
    },
  },
];
