import { memo } from "react";
import { useParams } from "react-router-dom";

import { AppState } from "store";
import { useSelector } from "react-redux";
import BasicInformation from "components/machine/detail/general/basicInformation";
import ConnectedDevices from "components/machine/detail/general/connectedDevices";
import Notifications from "components/machine/detail/general/notifications";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import isEqual from "react-fast-compare";
import AlertingDelay from "components/machine/detail/general//alertingDelay";
import SectionTimezone from "./timezone";
import UsersToNotify from "./usersToNotify";
import { Attachments } from "components/attachments";
import Divider from "components/typography/heading/divider";

const GeneralTab = memo(() => {
  const { id } = useParams<{ id: string | undefined }>();
  const { machine } = useSelector(
    (state: AppState) => state.machineDetail.machine,
    isEqual
  );

  const canEditMachine = usePermissions([
    EPermissions.UPDATE_ANY_MACHINE,
    EPermissions.UPDATE_OWN_MACHINE,
  ]);

  return (
    <>
      <BasicInformation machine={machine} />
      <Attachments machine={Number(id)} details={true} />
      <ConnectedDevices devices={machine.devices} />
      <Notifications
        disabled={!canEditMachine}
        isSmsNotifying={machine.isSmsNotifying}
        isEmailNotifying={machine.isEmailNotifying}
        isAssistedAlerting={machine.isAssistedAlerting}
        id={Number(id)}
      />
      <UsersToNotify />
      <AlertingDelay
        disabled={!canEditMachine}
        suppressNotificationsUntil={machine.suppressNotificationsUntil}
        id={Number(id)}
      />
      <SectionTimezone
        disabled={!canEditMachine}
        timeZone={machine.timeZone}
        id={Number(id)}
      />
      <Divider />
    </>
  );
});

export default GeneralTab;
