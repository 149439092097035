import { PlayerActionTypes, LabelState, LABELS_PROCESSING } from "./types";

const initialState: LabelState = {
  processing: false,
};

const reducer = (
  state = initialState,
  action: PlayerActionTypes
): LabelState => {
  switch (action.type) {
    case LABELS_PROCESSING: {
      return {
        processing: action.payload.processing,
      };
    }
    default:
      return state;
  }
};

export default reducer;
