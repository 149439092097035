import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TParams = {
  event: number;
  note: string;
  created_at: string;
};

export default async function addEventComment(comment: TParams) {
  const url = endpoints.events.comments;
  try {
    const response = await axiosNeuron.post(url, comment);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
