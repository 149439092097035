import React, { memo, useState, useCallback, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      color: theme.custom.palette.data,
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 2.5),
      },
    },
    buttonClass: {
      margin: 0,
    },
    paper: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 0, 1, 0),
      minWidth: 100,
    },
  };
});

type TKebabMenu = {
  onCloseMenu?: () => void;
  data: JSX.Element;
  id?: string;
  disabled?: boolean;
  buttonClass?: boolean;
};

export const KebabMenu = memo(
  ({ onCloseMenu, data, id, disabled, buttonClass }: TKebabMenu) => {
    const classes = useStyles();
    const [open, setMenuOpen] = useState<any>(null);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuOpen(event.currentTarget);
      },
      []
    );

    const handleClose = useCallback(
      (event?: any) => {
        event.stopPropagation();
        onCloseMenu && onCloseMenu();
        setMenuOpen(null);
      },
      [onCloseMenu]
    );

    useEffect(() => {
      const kebab = document.querySelector(`#${id}`);
      if (kebab) {
        kebab.addEventListener("hideKebab", handleClose);
      }
      return () => {
        if (kebab) {
          kebab.removeEventListener("hideKebab", handleClose);
        }
      };
    }, [handleClose, id]);

    const stopPropagation = useCallback((event: any) => {
      event.stopPropagation();
    }, []);

    return (
      <div id={id}>
        <IconButton
          disabled={disabled}
          onClick={handleClick}
          size="small"
          className={clsx(
            classes.button,
            buttonClass ? classes.buttonClass : null,
            "testKebabButton"
          )}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={open ? "simple-popover" : undefined}
          open={!!open}
          anchorEl={open}
          onClick={stopPropagation}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: classes.paper,
          }}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "unset",
            },
          }}
        >
          {data}
        </Popover>
      </div>
    );
  }
);
