import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface Payload {
  placement?: number | null;
  sensor_type?: number | null;
}

export default async function patchChannel({
  id,
  payload,
}: {
  id: number;
  payload: Payload;
}) {
  const url = endpoints.channels.detail(id);
  try {
    const response = await axiosNeuron.patch(url, payload);
    const { data } = response;
    return {
      id: data.id,
      placement: data.placement,
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
