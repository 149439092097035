import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { StepType } from "components/stepModal";
import Button from "components/button/index";

const useStyles = makeStyles((theme: any) => ({
  footerButtons: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
  },
}));

type Props = {
  steps: StepType[];
  activeStep: number;
  finishButtonDataTestId?: string;
  finishButtonLabel: string;

  handleNext?: () => void;
  onCancel: () => void;
  handleFinish?: () => void;
  isFormValid?: boolean;
};

const DesktopFooter = ({
  steps,
  activeStep,
  onCancel,
  handleNext,
  finishButtonDataTestId,
  finishButtonLabel,
  handleFinish,
  isFormValid,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lastStep = activeStep === steps.length - 1;
  return (
    <div className={classes.footerButtons}>
      <Button onClick={onCancel}>{t("Cancel")}</Button>
      <Button
        disabled={lastStep ? !handleFinish : !isFormValid}
        dataTestId={lastStep ? finishButtonDataTestId : "testButtonContinue"}
        onClick={lastStep ? handleFinish : handleNext}
      >
        {lastStep ? finishButtonLabel : t("Continue")}
      </Button>
    </div>
  );
};

export default DesktopFooter;
