import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";

export const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  root: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  fullHeight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  footer: {
    padding: theme.spacing(1),
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.24)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modalWrapper: {
    [`@media only screen and (min-width: ${theme.spacing(
      102
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "70%",
      height: theme.spacing(70),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      130
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "60%",
      height: theme.spacing(70),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      160
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "50%",
    },
  },
  modalContainer: {
    [`@media only screen and (min-width: ${theme.spacing(
      102
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "80%",
      height: "80%",
    },
  },
  inputWrapper: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  input: {
    fontSize: theme.custom.typography.fontSize["14"],
    letterSpacing: theme.custom.typography.letterSpacing["medium"],
    height: theme.spacing(3),
  },
  fullWidth: {
    width: "100%",
  },
  selectWrapper: {
    height: theme.spacing(4),
    minWidth: theme.spacing(8),
    color: "red",
    padding: 0,
  },
  select: {
    "& .MuiSelect-selectMenu": {
      minHeight: "auto",
    },
  },
  waveWrapper: {
    height: theme.typography.pxToRem(52),
    position: "relative",
    paddingTop: theme.typography.pxToRem(4),
  },
  container: {
    border: `1px solid grey`,
    width: 146.9,
    height: 200,
    position: "relative",
  },
  paper: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    minWidth: 100,
    margin: "2rem",
  },
  contentWrapper: {
    "& canvas": {
      width: "100%",
    },
  },
  selected: {
    borderColor: theme.custom.palette.success.regular,
    borderWidth: "3px",
    borderStyle: "solid",
    borderRadius: 5,
  },
  wrapper: {
    borderColor: "transparent",
    borderWidth: "3px",
    borderStyle: "solid",
  },
  actionButton: {
    fontSize: ".875rem",
    fontWeight: 400,
    textTransform: "unset",
    justifyContent: "unset",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
}));
