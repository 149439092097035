import React, { PureComponent } from "react";
import Ticks from "./Ticks";

class Axis extends PureComponent {
  static defaultProps = {
    height: 40,
    margin: 5,
    hideTimeLabel: false,
  };

  render() {
    const { height, margin, ...props } = this.props;
    return (
      <div
        className="wave-axis"
        style={{
          height,
          marginLeft: margin,
          marginRight: margin,
          overflow: "hidden",
        }}
      >
        <Ticks {...props} />
      </div>
    );
  }
}

export default Axis;
