import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IRole } from "types/role";
import { ResponseType } from "types/response";

export default async function getVisibleRoles() {
  try {
    const response = await axiosNeuron.get(endpoints.roles.visible);

    const data: ResponseType<IRole> = {
      ...response.data,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        groups: item.groups,
        name: item.name,
        codename: item.codename,
        description: item.description,
      })),
    };

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
