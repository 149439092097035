import React, { useCallback, useMemo, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";
import { KebabMenu } from "components/kebab";
import { Box, Button, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import undeployModel from "api/handlers/machine/undeployModel";
import recompute from "api/handlers/machine/recomputeResults";
import { ICustomModel } from "types/customModels";
import ConfirmPopup from "components/confirmPopup";
import DeployModal from "components/machine/detail/customModelOverview/deployModal";
import moment from "moment-timezone";
import Recompute from "components/machine/detail/model/recompute";
import appUrls from "shared/appUrls";

const CustomModelDetailBodyDeployments = ({
  modelDeployment,
  deployment,
}: any) => {
  const { t } = useTranslation();
  const tt = (i: string) => t(`machine.detail.model.${i}`);
  const classes = useStyles();
  const [isRedeploy, setIsRedeploy] = useState(false);
  const [machineId, setMachineId] = useState<number | null>(null);
  const [trainModel, setTrainModel] = useState<number | null>(null);
  const [undeployId, setUndeployId] = useState<number | null>(null);
  const [recomputeId, setRecomputeId] = useState<number | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickUndeploy = async () => {
    try {
      await undeployModel(Number(machineId), { deployment_id: undeployId! });
      enqueueSnackbar(tt("beingUndeployed"));
    } catch (e) {
      enqueueSnackbar(tt("errorUndeploying"));
    }
    setUndeployId(null);
    setMachineId(null);
  };

  const hideKebabMenu = useCallback((id: string) => {
    const hideKebab = new CustomEvent("hideKebab");
    const kebab = document.querySelector(
      "#kebab-custom-model-deployments-detail-" + id
    );
    kebab?.dispatchEvent(hideKebab);
  }, []);

  const handleClickRedeploy = (modelDeploy: ICustomModel, deploy: any) => {
    setIsRedeploy(true);
    setMachineId(Number(deploy.consumer.machine));
    setTrainModel(Number(modelDeploy.trained_model));
    setUndeployId(deploy && deploy?.id);
    setRecomputeId(Number(modelDeploy.trained_model));
    hideKebabMenu(modelDeploy.task_id.toString());
  };

  const handleClickRecompute = (modelDeploy: ICustomModel, deploy: any) => {
    setMachineId(Number(deploy.consumer.machine));
    setRecomputeId(Number(modelDeploy.trained_model));
    hideKebabMenu(modelDeploy.task_id.toString());
  };

  const handleCancelDeploy = () => {
    setIsRedeploy(false);
    setMachineId(null);
    setTrainModel(null);
    setUndeployId(null);
  };

  const handleRecompute = async (from: Date, to: Date, environment: string) => {
    try {
      await recompute(machineId as number, {
        trained_model_id: recomputeId!,
        from,
        to,
        environment,
      });
      enqueueSnackbar(tt("scheduledRecomputing"));
    } catch (e) {
      enqueueSnackbar(tt("errorRecomputing"));
    }
    setRecomputeId(null);
    setMachineId?.(null);
  };

  const generateItems = useMemo(() => {
    return (modelDeployment: ICustomModel, deploy: any) => (
      <>
        <MenuItem onClick={() => handleClickRedeploy(modelDeployment, deploy)}>
          {tt("redeploy")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setUndeployId(deploy && deploy?.id);
            setMachineId(Number(deploy.machine));
            hideKebabMenu(modelDeployment.task_id.toString());
          }}
        >
          {tt("undeploy")}
        </MenuItem>
        <MenuItem onClick={() => handleClickRecompute(modelDeployment, deploy)}>
          {tt("recompute")}
        </MenuItem>
      </>
    );
  }, [t]);

  return (
    <>
      <thead className={classes.break} />
      <TableBody className={classes.tableContent}>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            <Button
              size="small"
              style={{ padding: "unset" }}
              className={classes.actionButton}
              onClick={() =>
                window.open(
                  appUrls.machines.detail(deployment.consumer?.machine),
                  "_blank"
                )
              }
            >
              {deployment.consumer?.machine}
            </Button>
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            <Button
              size="small"
              style={{ padding: "unset" }}
              className={classes.actionButton}
              onClick={() =>
                window.open(
                  appUrls.machines.detail(deployment.consumer?.machine),
                  "_blank"
                )
              }
            >
              {deployment.consumer?.machineName}
            </Button>
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {deployment.consumer?.placementList.map((placement: any) => (
                <span key={placement.id}>
                  {placement.name} {placement.id}
                </span>
              ))}
            </Box>
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deployment.environment}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {t(`serviceManager.location.${deployment.deployment_type}`)}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deployment.id}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {moment(deployment.created_at).format("YYYY-MM-DD HH:mm")}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deployment.lastActivity
              ? moment(deployment.lastActivity).format("YYYY-MM-DD HH:mm")
              : null}
          </TableCell>
          <TableCell
            align="right"
            className={`${classes.td}`}
            sx={{
              paddingRight: "0",
            }}
          >
            <KebabMenu
              id={
                "kebab-custom-model-deployments-detail-" +
                modelDeployment.task_id
              }
              data={generateItems(modelDeployment, deployment)}
            />
          </TableCell>
        </TableRow>
      </TableBody>
      {recomputeId && machineId && (
        <Recompute
          machineId={machineId!}
          modelId={recomputeId!}
          onCancel={() => {
            setRecomputeId(null);
            setMachineId?.(null);
          }}
          onConfirm={handleRecompute}
        />
      )}
      {isRedeploy && (
        <DeployModal
          machineId={Number(machineId)}
          onCancel={handleCancelDeploy}
          open={isRedeploy}
          trainModel={trainModel!}
          title={"redeployDialog.title"}
          subtitle={"redeployDialog.subtitle"}
          cta={"redeploy"}
          isRedeploy={isRedeploy}
          undeployId={undeployId!}
        />
      )}
      {undeployId && !isRedeploy && (
        <ConfirmPopup
          onConfirm={handleClickUndeploy}
          title={tt("undeployDialog.title")}
          text={tt("undeployDialog.text")}
          confirmText={tt("undeploy")}
          noControl={true}
          onCancel={() => {
            setUndeployId(null);
            setMachineId(null);
          }}
        />
      )}
    </>
  );
};

export default CustomModelDetailBodyDeployments;
