import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { TFacilityPayload } from "types/facility";
interface IUpdateFacilityParams extends TFacilityPayload {
  id: number;
  users_to_notify?: number[];
}

export default async function updateFacility({
  id,
  ...payload
}: IUpdateFacilityParams) {
  const url = endpoints.facilities.detail(id);

  try {
    const response = await axiosNeuron.patch(url, payload);
    return response.data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
