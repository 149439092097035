import React, { PureComponent } from "react";

class Progress extends PureComponent {
  static defaultProps = {
    color: "#f4dde2",
    opacity: 0.2,
    width: 0,
  };

  render() {
    let { width, color, opacity, onMouseMove, title } = this.props;
    return (
      <div
        onMouseMove={onMouseMove}
        title={title}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width,
          background: color,
          opacity,
          zIndex: 100,
          borderRight: "solid 1px black",
        }}
      />
    );
  }
}

export default Progress;
