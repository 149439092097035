import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import moment from "moment";
import { getNormalizeTime } from "shared/helpers";

type TData = {
  data: { start: string; end: string }[];
};

export async function getAvailableRanges(
  machine: number,
  start_time: number,
  end_time: number,
  channel: number,
  timezoneOffset: number
) {
  const url = endpoints.availability.range;
  try {
    const { data }: TData = await axiosNeuron.get(
      `${url}?machine=${encodeURIComponent(
        machine
      )}&start_time=${encodeURIComponent(
        moment.unix(start_time).format("YYYY-MM-DD HH:mm:ss.SSS")
      )}&end_time=${encodeURIComponent(
        moment.unix(end_time).format("YYYY-MM-DD HH:mm:ss.SSS")
      )}&placement=${encodeURIComponent(channel)}&tz=${encodeURIComponent(
        timezoneOffset
      )}`
    );

    const result = Object.values(data).map((item: any) => {
      return {
        ...item,
        start: getNormalizeTime(item.start),
        end: getNormalizeTime(item.end),
      };
    });

    return result;
  } catch {
    return [];
  }
}
