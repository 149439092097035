import React from "react";
import { makeStyles } from "@mui/styles";
import Tag from "components/tag";
import clsx from "clsx";
import { CellProps } from "react-table";

const useStyles = makeStyles((theme: any) => {
  return {
    nameRole: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      fontSize: theme.custom.typography.fontSize[16],
      fontWeight: theme.custom.typography.fontWeight.bold,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // width: 150,
    },
    name: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
  };
});

const LabelAndTag = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { name, role },
    },
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.nameRole}>
      <span className={clsx(classes.name, "cellUserName")}>{name}</span>
      {role && (
        <Tag className="cellUserTag" color={/^NSW/.test(role) && "primary"}>
          {role.toUpperCase()}
        </Tag>
      )}
    </div>
  );
};

export default LabelAndTag;
