import { INotifierState, EActions, INotifierActions } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: INotifierState = {
  notifications: [],
};

const reducers = {
  [EActions.enqueueSnackbar]: (
    { payload: { message, options } }: INotifierActions,
    state: INotifierState
  ) => ({
    notifications: [
      ...state.notifications,
      {
        key: new Date().getTime() + Math.random(),
        message,
        options: {
          key: new Date().getTime() + Math.random(),
          ...options,
        },
      },
    ],
  }),
  [EActions.removeSnackbar]: (
    { payload: { key } }: INotifierActions,
    state: INotifierState
  ) => {
    return {
      notifications: state.notifications.filter((notification: any) => {
        return notification.options.key !== key;
      }),
    };
  },
  [EActions.closeSnackbar]: (
    state: INotifierState,
    { payload: { key } }: INotifierActions
  ) => ({
    ...state,
    notifications: state.notifications.map((notification: any) =>
      notification.option.key === key
        ? { ...notification, dismissed: true }
        : { ...notification }
    ),
  }),
};

export default createReducer<INotifierState>(path, initialState, reducers);
